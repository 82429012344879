define("boondmanager/pods/components/bm-field/startdate-opportunity/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * BM field Availability component
   *
   * Component attributes:
   *
   *   * {string}             name          REQUIRED field name
   *   * {Availability}       availability  REQUIRED Availability object
   *   * {boolean}            automatic     OPTIONAL used only with 'resource' profile
   *   * {string}             class         OPTIONAL additional classes applied to the main DOM element
   *   * {boolean}            disabled      OPTIONAL component state
   *   * {string}             size          OPTIONAL component Bootstrap size
   *   * {function}           onChange      OPTIONAL callback call on <option> selection
   *
   * see AvailabilityInitializer to learn about Availability object format.
   *
   * Example:
   *
   * ```handlebars
   * <div>
   *     {{bm-field/availability
   *         name="profile-availability"
   *         size=size
   *         disabled=disabled
   *         availability=availability
   *         onChange=(action (mut availability))
   *     }}
   * </div>
   * ```
   *
   * @class  BmFieldAvailabilityComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * Component default __class__ attribute
     *
     * @property classNames
     * @type {Array.<string>}
     * @default  ['bm-field-availability']
     */
    classNames: ['bm-field-startdate-opportunity'],
    /**
     * The size of the element. Available options: 'lg' and 'sm'.
     * @type {String}
     * @default ''
     * @public
     */
    size: '',
    /**
     * Component disabled state
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,
    /**
     * Format of date displayed in date input
     *
     * @type {String}
     * @default  'L'
     */
    displayedDateFormat: 'DD/MM/YY',
    /**
     * Is availability automatic (ie. availability.date defined by backend when profile is saved) ?
     *
     * @type {Boolean}
     * @default  false
     */
    automatic: false,
    date: Ember.computed('value', function () {
      var value = this.get('value');
      return value !== 'immediate' ? (0, _moment.default)(value) : null;
    }),
    /**
     * Profile-dependent availability types
     *
     * @property availabilityTypes
     * @type {IdValue[]}
     * @readOnly
     */
    availabilityTypes: Ember.computed('i18n._locale', function () {
      var availabilityTypes = [];
      availabilityTypes = availabilityTypes.concat(this.get('settings').bm.component.bmFieldAvailability.types.resource);
      return availabilityTypes;
    }).readOnly(),
    /**
     * Type currently selected
     *
     * @property selectedType
     * @type {String|Number}
     */
    selectedType: Ember.computed('value', function () {
      return this.get('value') === 'immediate' ? 'immediate' : 'date';
    }),
    /**
     * Date previously defined when selectedType was 'date'
     *
     * @property previousSelectedDate
     * @type {Moment}
     */
    /**
     * 'didReceiveAttrs' Component hook
     *
     * Check `name` and `availability` attributes
     *
     * @method didReceiveAttrs
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert("bm-field-startdate-opportunity#".concat(this.get('elementId'), " must have a \"name\" attribute!"), !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert("bm-field-startdate-opportunity#".concat(this.get('elementId'), " \"name\" attribute must be a string!"), Ember.typeOf(name) === 'string'));
    },
    actions: {
      /**
       * Update selected type
       *
       * @event  updateSelectedType
       */
      updateSelectedType: function updateSelectedType(type) {
        if (type === 'immediate') {
          return this.get('onChange')(type);
        }
        var date = _moment.default.isMoment(this.get('date')) ? this.get('date') : (0, _moment.default)();
        this.get('onChange')(date.format('YYYY-MM-DD'));
      },
      /**
       * update selected date (when selected type === 'date')
       *
       * @event  updateSelectedDate
       */
      updateSelectedDate: function updateSelectedDate(date) {
        this.get('onChange')((0, _moment.default)(date).format('YYYY-MM-DD'));
      }
    }
  });
});