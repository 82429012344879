define("boondmanager/models/contract", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes", "boondmanager/helpers/no-end-date", "moment", "boondmanager/utils/financial"], function (_exports, _base, _emberData, _emberDataCopyable, _attributes, _noEndDate, _moment, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RENEWAL_PROBATION_IN_PROGRESS = _exports.RENEWAL_PROBATION_BROKEN = _exports.PROBATION_IN_PROGRESS = _exports.PROBATION_BROKEN = _exports.END_OF_CONTRACT_MUTUAL_AGREEMENT = _exports.CONTRACT_TYPE_PERMANENT = _exports.CONTRACT_FULL_TIME = _exports.CONTRACT_CATEGORY_ENGINEER = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var END_OF_CONTRACT_MUTUAL_AGREEMENT = _exports.END_OF_CONTRACT_MUTUAL_AGREEMENT = 0;
  var CONTRACT_FULL_TIME = _exports.CONTRACT_FULL_TIME = 0;
  var CONTRACT_TYPE_PERMANENT = _exports.CONTRACT_TYPE_PERMANENT = 0;
  var CONTRACT_CATEGORY_ENGINEER = _exports.CONTRACT_CATEGORY_ENGINEER = 0;
  var PROBATION_IN_PROGRESS = _exports.PROBATION_IN_PROGRESS = 0;
  var PROBATION_BROKEN = _exports.PROBATION_BROKEN = 2;
  var RENEWAL_PROBATION_IN_PROGRESS = _exports.RENEWAL_PROBATION_IN_PROGRESS = 3;
  var RENEWAL_PROBATION_BROKEN = _exports.RENEWAL_PROBATION_BROKEN = 5;

  /**
   * Contract Ember model
   */
  var Contract = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'dependsOn', 'resource', 'parentContract']
    }),
    advantageTypes: (0, _attributes.fragmentArray)('advantagetype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    chargeFactor: _emberData.default.attr('number'),
    classification: _emberData.default.attr('string', {
      defaultValue: '-1'
    }),
    // TAB_CONTRAT.CTR_CLASSIFICATION
    informationComments: _emberData.default.attr('string'),
    // TAB_CONTRAT.CTR_COMMENTAIRES
    contractAverageDailyCost: _emberData.default.attr('number'),
    contractAverageDailyProductionCost: _emberData.default.attr('number'),
    currency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_DEVISEAGENCE
    dailyExpenses: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_FRSJOUR
    employeeType: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_CATEGORIE
    endDate: _emberData.default.attr('date'),
    // TAB_CONTRAT.CTR_FIN
    exceptionalScales: (0, _attributes.fragmentArray)('exceptionalscale', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    exchangeRate: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_CHANGEAGENCE
    expensesDetails: (0, _attributes.fragmentArray)('expensedetail', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    files: _emberData.default.hasMany(),
    forceContractAverageDailyProductionCost: _emberData.default.attr('boolean'),
    forceHourlySalary: _emberData.default.attr('boolean'),
    hourlySalary: _emberData.default.attr('number'),
    monthlyExpenses: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_FRSMENSUEL
    monthlySalary: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_SALAIREMENSUEL
    numberOfWorkingDays: _emberData.default.attr('number'),
    numberOfHoursPerWeek: _emberData.default.attr('number'),
    probationState: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    probationEndDate: _emberData.default.attr('date'),
    renewalProbationEndDate: _emberData.default.attr('date'),
    startDate: _emberData.default.attr('date'),
    // TAB_CONTRAT.CTR_DEBUT
    workingTimeType: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_TPSTRAVAIL
    endReason: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    // TAB_CONTRAT.CTR_MOTIF_FIN_CONTRAT
    creationDate: _emberData.default.attr('moment'),
    // TAB_CONTRAT.CTR_CREATEDAT,
    updateDate: _emberData.default.attr('moment'),
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    agency: _emberData.default.belongsTo(),
    parentContract: _emberData.default.belongsTo('contract', {
      inverse: 'childContract'
    }),
    childContract: _emberData.default.belongsTo('contract', {
      inverse: 'parentContract'
    }),
    resource: _emberData.default.belongsTo(),
    dependsOn: _emberData.default.belongsTo('profile', {
      inverse: null
    }),
    // si c'est un candidat, l'inverse est 'contract', si c'est une ressource, l'inverse est 'contracts'

    reportingClass: Ember.computed('id', function () {
      var promise = Ember.RSVP.Promise.resolve('contract');
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    lengthInDays: Ember.computed('startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate') || (0, _moment.default)();
      return _moment.default.duration(endDate.diff(startDate)).asDays();
    }),
    reportingTooltip: Ember.computed('startDate', 'endDate', 'occupationRate', function () {
      var i18n = this.get('i18n');
      var startLabel = i18n.t('models:contract.attributes.startDate.name');
      var endLabel = i18n.t('models:contract.attributes.endDate.abbr');
      var endDate = this.get('endDate') ? this.get('endDate').format('DD/MM/YY') : '';
      return "<dl class=\"periodInfoTooltip\">\n\t\t\t<dt>\n\t\t\t".concat(startLabel, " - ").concat(endLabel, "\n\t\t\t</dt>\n\t\t\t<dd>").concat(this.get('startDate').format('DD/MM/YY'), " - ").concat(endDate, "</dd>\n\t\t\t</dl>");
    }),
    /**
     * Tests if the contract depends on a candidate
     * @return bool
     */
    dependsOnCandidate: Ember.computed('dependsOn.modelName', function () {
      return this.get('dependsOn.modelName') === 'candidate';
    }),
    /**
     * Tests if the contract depends on a candidate
     * @return bool
     */
    dependsOnResource: Ember.computed('dependsOn.modelName', function () {
      return this.get('dependsOn.modelName') === 'resource';
    }),
    dependsOnExternal: Ember.computed('dependsOn.isExternal', function () {
      return this.get('dependsOn.isExternal');
    }),
    /**
     * Tests if a contract has a parent (related) contract
     * @return bool
     */
    isAmendment: Ember.computed('parentContract', function () {
      var parentId = this.get('parentContract.id');
      return typeof parentId !== 'undefined' && parentId !== '0';
    }),
    hasAmendment: Ember.computed('childContract.id', function () {
      return this.get('childContract.id');
    }),
    /**
     * Formats the contract summary in the form CTRXX DD-MM-YYYY - DD-MM-YYYY
     * @return String
     */
    summary: Ember.computed(function () {
      return this.get('internalReference') + ' - ' + this.get('startDate').format('DD/MM/YY') + ' - ' + (typeof this.get('endDate') !== 'undefined' && this.get('endDate') !== '' ? this.get('endDate').format('DD/MM/YY') : '...');
    }),
    annualSalary: Ember.computed('monthlySalary', {
      get: function get() {
        return this.get('monthlySalary') * 12;
      },
      set: function set(key, value) {
        this.set('monthlySalary', (0, _financial.roundBigDecimals)(value / 12));
        return value;
      }
    }),
    salaryEvolution: Ember.computed('monthlySalary', function () {
      var actualSalary = this.get(this.get('agency.contractsSalaryType') + 'Salary');
      var parentSalary = this.get('parentContract.' + this.get('agency.contractsSalaryType') + 'Salary');
      return Math.round((actualSalary - parentSalary) / parentSalary * 10000) / 100;
    }),
    hourlySalaryCalculated: Ember.computed('numberOfHoursPerWeek', 'forceHourlySalary', 'monthlySalary', 'hourlySalary', {
      get: function get() {
        if (!this.get('forceHourlySalary')) {
          return this.get('numberOfHoursPerWeek') ? this.get('monthlySalary') * 12 / 52 / this.get('numberOfHoursPerWeek') : 0;
        } else {
          return this.get('hourlySalary');
        }
      },
      set: function set(key, value) {
        var hourlySalary = Number(value);
        if (Number.isNaN(hourlySalary)) {
          hourlySalary = 0;
        }
        var exchangeRateAgency = this.get('exchangeRateAgency');
        var exchangeRate = this.get('exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(exchangeRate);
        }
        this.set('hourlySalary', (0, _financial.applyExchangeRates)(hourlySalary, exchangeRates));
        return value;
      }
    }),
    endDateValue: Ember.computed('endDate', function () {
      return _noEndDate.default.compute([this.get('endDate')]) ? '...' : this.get('endDate').format('L');
    }),
    formatNumberForTooltip: function formatNumberForTooltip(number) {
      number = Math.round(number * 100) / 100;
      var partsNumber = number.toString().split('.');
      partsNumber[0] = partsNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return partsNumber.join('.');
    },
    tooltip: Ember.computed('contractAverageDailyCost', 'monthlySalary', 'dailyExpenses', 'monthlyExpenses', 'currency', function () {
      var i18n = this.get('i18n');
      var currencySign = this.get('settingsService').customer.currency.findBy('id', this.get('currency')).symbol;
      var change = Number(this.get('exchangeRate'));
      var agencyChange = Number(this.get('exchangeRateAgency'));
      if (typeof this.get('monthlySalary') === 'undefined') return '';
      return '<dl class="candidate-contract bm-dl">' + '<dt>' + i18n.t('contracts:view.contractAverageDailyCost') + '&nbsp;:</dt>' + '<dd>' + this.formatNumberForTooltip(Number(this.get('contractAverageDailyCost')) / agencyChange / change) + currencySign + i18n.t('common:grossDay') + '</dd>' + '<dt>' + i18n.t('models:contract.attributes.monthlySalary.name') + '&nbsp;:</dt>' + '<dd>' + this.formatNumberForTooltip(Number(this.get('monthlySalary')) / agencyChange / change) + currencySign + i18n.t('common:grossMonth') + '</dd>' + '<dt>' + i18n.t('contracts:view.annualSalary') + '&nbsp;:</dt>' + '<dd>' + this.formatNumberForTooltip(Number(this.get('monthlySalary')) * 12 / agencyChange / change) + currencySign + i18n.t('common:grossYear') + '</dd>' + '<dt>' + i18n.t('contracts:view.dailyContractExpenses') + '&nbsp;:</dt>' + '<dd>' + this.formatNumberForTooltip(Number(this.get('dailyExpenses')) / agencyChange / change) + currencySign + i18n.t('contracts:view.netDays') + '</dd>' + '<dt>' + i18n.t('contracts:view.monthlyContractExpenses') + '&nbsp;:</dt>' + '<dd>' + this.formatNumberForTooltip(Number(this.get('monthlyExpenses')) / agencyChange / change) + currencySign + i18n.t('contracts:view.netMonth') + '</dd>' + '</dl>';
    }),
    phoneOptionsForESignature: Ember.computed('dependsOn.phoneOptionsForESignature', function () {
      return this.get('dependsOn.phoneOptionsForESignature');
    }).readOnly(),
    emailOptionsForESignature: Ember.computed('dependsOn.emailOptionsForESignature', function () {
      return this.get('dependsOn.emailOptionsForESignature');
    }).readOnly(),
    isBroken: Ember.computed('probationState', function () {
      return [PROBATION_BROKEN, RENEWAL_PROBATION_BROKEN].includes(this.get('probationState'));
    }).readOnly()
  });

  /**
   * Prefix handling
   */
  Contract.reopenClass({
    prefix: 'CTR'
  });
  Contract.reopen({
    prefix: Ember.computed(function () {
      return Contract.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Contract;
});