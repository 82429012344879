define("boondmanager/pods/components/bm-field/saved-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NT8bWUhH",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,8],null,[[\"name\",\"noUnderline\",\"options\",\"selected\",\"multiple\",\"dropdownClass\",\"triggerComponent\",\"afterOptionsComponent\",\"customMatcher\",\"onchange\",\"onopen\",\"registerAPI\"],[\"savedsearch\",true,[32,0,[\"savedSearchList\"]],[32,0,[\"selectedSavedSearchId\"]],false,\"bmc-field-power-select_options-hover-action\",[30,[36,7],[\"bm-field/power-select/saved-search/trigger\"],[[\"icon\"],[\"bmi-star\"]]],[30,[36,7],[\"bm-field/power-select/saved-search/footer\"],[[\"save\",\"reset\"],[[32,0,[\"openNewSavedSearchModal\"]],[32,0,[\"resetSavedSearchFilters\"]]]]],[35,6],[32,0,[\"onSavedSearchSelection\"]],[32,0,[\"onPowerSelectOpened\"]],[32,0,[\"registerSavedSearchSelect\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"bm-ember-basic-dropdown-content-editable\"],[12],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,3],[[32,1,[\"share\",\"numberOfSharings\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t \"],[1,[30,[36,2],[\"bmi-shared\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,5],[[32,0,[\"hasRightsToEditSharedEntity\"]],[30,[36,4],[[32,1,[\"createdBy\",\"id\"]],[32,0,[\"currentUser\",\"id\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon bm-edit-option\"],[24,\"data-name\",\"button-edit\"],[4,[38,0],[\"mouseup\",[32,0,[\"stopPropagation\"]]],null],[4,[38,0],[\"click\",[30,[36,1],[[32,0,[\"openEditSavedSearchModalWithItem\"]],[32,1]],null]],null]],[[\"@iconOnly\"],[\"bmi-pencil\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"fn\",\"icon\",\"if\",\"eq\",\"or\",\"savedsearchMatcher\",\"component\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/saved-search/template.hbs"
  });
});