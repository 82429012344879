define("boondmanager/models/appcelebrationscustomer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    managerCanShowBirthday: _emberData.default.attr('boolean'),
    managerCanShowSeniority: _emberData.default.attr('boolean'),
    managerCanShowArrival: _emberData.default.attr('boolean'),
    intranetCanShowBirthday: _emberData.default.attr('boolean'),
    intranetCanShowSeniority: _emberData.default.attr('boolean'),
    intranetCanShowArrival: _emberData.default.attr('boolean'),
    intranetPerimeter: _emberData.default.attr('string'),
    excludedResourceStates: _emberData.default.attr('array'),
    excludedResourceTypes: _emberData.default.attr('array')
  });
});