define("boondmanager/models/advantage", ["exports", "boondmanager/models/base", "ember-data-copyable", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberDataCopyable, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var Advantage = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['resource', 'agency', 'contract', 'project', 'delivery']
    }),
    advantageType: (0, _attributes.fragment)('advantagetype'),
    currency: _emberData.default.attr('number'),
    currencyAgency: _emberData.default.attr('number'),
    date: _emberData.default.attr('date'),
    exchangeRate: _emberData.default.attr('number'),
    exchangeRateAgency: _emberData.default.attr('number'),
    informationComments: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    returnDate: _emberData.default.attr('date'),
    participationAmount: _emberData.default.attr('number'),
    agencyAmount: _emberData.default.attr('number'),
    employeeAmount: _emberData.default.attr('number'),
    costPaid: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    costCharged: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    creationDate: _emberData.default.attr('moment'),
    // TAB_AVANTAGE.AVG_CREATEDAT,
    updateDate: _emberData.default.attr('moment'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    costChargedCalculated: Ember.computed('advantageType.{category,agencyQuota}', 'quantity', 'employeeAmount', 'agencyAmount', 'participationAmount', function () {
      var _this = this;
      var employeeAmount = this.get('employeeAmount');
      var agencyAmount = this.get('agencyAmount');
      var participationAmount = this.get('participationAmount');
      var promise;
      if (this.get('advantageType.category') === 'variableSalaryBasis') {
        if (this.get('isNew')) {
          promise = this.get('contract').then(function (contract) {
            if (!contract) {
              return _this.get('delivery').then(function (delivery) {
                return delivery.get('contract').then(function (contract) {
                  var monthSalary = contract ? contract.get('monthlySalary') : 0;
                  employeeAmount = _this.get('advantageType.employeeQuota') * monthSalary / 100;
                  agencyAmount = _this.get('advantageType.agencyQuota') * monthSalary / 100;
                  participationAmount = _this.get('advantageType.participationQuota') * monthSalary / 100;
                  return _this.get('quantity') * (employeeAmount + _this._calculateAgencyAmount(agencyAmount, employeeAmount) - participationAmount);
                });
              });
            } else {
              employeeAmount = _this.get('advantageType.employeeQuota') * contract.get('monthlySalary') / 100;
              agencyAmount = _this.get('advantageType.agencyQuota') * contract.get('monthlySalary') / 100;
              participationAmount = _this.get('advantageType.participationQuota') * contract.get('monthlySalary') / 100;
              return _this.get('quantity') * (employeeAmount + _this._calculateAgencyAmount(agencyAmount, employeeAmount) - participationAmount);
            }
          });
        }
      }
      if (typeof promise === 'undefined') {
        promise = Ember.RSVP.resolve(this.get('quantity') * (employeeAmount + this._calculateAgencyAmount(agencyAmount, employeeAmount) - participationAmount));
      }
      return ObjProxy.create({
        promise: promise
      });
    }),
    /**
     * Calculate agency amount depending on advantage type and employee amount
     * @param agencyAmount
     * @param employeeAmount
     * @returns {*}
     * @private
     */
    _calculateAgencyAmount: function _calculateAgencyAmount(agencyAmount, employeeAmount) {
      switch (this.get('advantageType.category')) {
        case 'fixedAmount':
          agencyAmount = employeeAmount * (this.get('advantageType.agencyQuota') - 1);
          break;
        case 'package':
          break;
      }
      return agencyAmount;
    },
    costPaidCalculated: Ember.computed('advantageType.{category,employeeQuota}', 'quantity', 'employeeAmount', 'agencyAmount', 'participationAmount', function () {
      var _this2 = this;
      var promise = Ember.RSVP.resolve(this.get('quantity') * this.get('employeeAmount'));
      if (this.get('advantageType.category') === 'variableSalaryBasis') {
        if (this.get('isNew')) {
          promise = this.get('contract').then(function (contract) {
            if (!contract) {
              return _this2.get('delivery').then(function (delivery) {
                return delivery.get('contract').then(function (contract) {
                  return contract && contract.get('monthlySalary') && _this2.get('advantageType.employeeQuota') ? _this2.get('quantity') * _this2.get('advantageType.employeeQuota') * contract.get('monthlySalary') / 100 : 0;
                });
              });
            } else {
              return contract.get('monthlySalary') && _this2.get('advantageType.employeeQuota') ? _this2.get('quantity') * _this2.get('advantageType.employeeQuota') * contract.get('monthlySalary') / 100 : 0;
            }
          });
        }
      }
      return ObjProxy.create({
        promise: promise
      });
    }),
    resource: _emberData.default.belongsTo(),
    agency: _emberData.default.belongsTo(),
    contract: _emberData.default.belongsTo(),
    delivery: _emberData.default.belongsTo(),
    project: _emberData.default.belongsTo(),
    //right
    canReadAdvantage: _emberData.default.attr('boolean'),
    canWriteAdvantage: _emberData.default.attr('boolean'),
    // Droit d'écriture ( par procuration de suppresion)

    isContractual: Ember.computed('contract', 'delivery', function () {
      return this.get('contract.id') || this.get('delivery.id');
    }),
    isLoan: Ember.computed('advantageType', function () {
      return this.get('advantageType.category') === 'loan';
    }),
    isFixedAmount: Ember.computed('advantageType', function () {
      return this.get('advantageType.category') === 'fixedAmount';
    }),
    isVariableSalaryBasis: Ember.computed('advantageType', function () {
      return this.get('advantageType.category') === 'variableSalaryBasis';
    }),
    isPackage: Ember.computed('advantageType', function () {
      return this.get('advantageType.category') === 'package';
    })
  });
  Advantage.reopenClass({
    prefix: 'AVG'
  });
  Advantage.reopen({
    prefix: Ember.computed(function () {
      return Advantage.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Advantage;
});