define("boondmanager/initializers/title-token-in-route", ["exports", "boondmanager/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  // see https://github.com/kimroen/ember-cli-document-title/issues/8
  function initialize( /* application */
  ) {
    Ember.Route.reopen({
      getI18nNamespace: function getI18nNamespace(module, isAdmin, isApp) {
        if (typeof this.get('i18nNamespace') !== 'undefined') {
          return this.get('i18nNamespace');
        } else if (typeof this.i18nNamespace !== 'undefined') {
          return this.i18nNamespace;
        } else if (module === 'administrator') {
          // cas particulier sinon on a une erreur dans les sous section de l'admin
          return 'administrator';
        } else {
          var i18n = this.get('i18n');
          var ns;
          switch (true) {
            case isAdmin:
              ns = 'admin'.concat(module);
              break;
            case isApp || !isNaN(module):
              ns = module === 'appentity' ? i18n.namespacize(module) : module;
              break;
            default:
              ns = i18n.namespacize(module);
              break;
          }
          return ns;
        }
      },
      // set a Ember.computed is useless here because titleToken (see https://github.com/kimroen/ember-cli-document-title/pull/21)
      titleToken: function titleToken(model) {
        var titleToken;
        var i18n = this.get('i18n');
        var routeNameParts = this.get('routeName').split('.');
        var isApp = false;
        var isAdmin = false;

        // if first element is 'private', remove it :)
        if (routeNameParts.length && routeNameParts[0] === 'private') {
          routeNameParts.shift();
          if (routeNameParts[0] === 'apps' && routeNameParts.length > 1 && routeNameParts[1] !== 'index') {
            isApp = true;
            routeNameParts.shift();
          } else if (routeNameParts[0] === 'administrator' && routeNameParts.length > 1) {
            isAdmin = true;
            routeNameParts.shift();
            if ((routeNameParts[0] === 'developer' || routeNameParts[0] === 'appsmanagement' || routeNameParts[0] === 'usersmanagement') && routeNameParts.length > 1) {
              routeNameParts.shift();
            }
            if (routeNameParts[0] === 'marketplace' && routeNameParts.length > 1 && routeNameParts[1] === 'settings') {
              routeNameParts.shift();
            }
          }
        }
        var _routeNameParts = _slicedToArray(routeNameParts, 3),
          module = _routeNameParts[0],
          submodule = _routeNameParts[1],
          tab = _routeNameParts[2];
        if (tab !== 'index') {
          if (typeof tab !== 'undefined') {
            // we are in a tab
            var i18nKeyEndPoints = ['abbr', 'title'];
            //@Todo GRI
            var i18nKeys = ["".concat(submodule, ".").concat(tab), "".concat(module, ".").concat(tab), "".concat(tab), "common.".concat(tab)];
            var i18nKeyFound;

            // on préviligie toutes les clés abbrégées tout d'abord, puis celle non-abbrégées.
            i18nKeyEndPoints.find(function (endPoint) {
              return i18nKeys.find(function (i18nKey) {
                var completeI18nKey = "".concat(i18nKey, ".").concat(endPoint);
                if (i18n.exists(completeI18nKey)) {
                  i18nKeyFound = completeI18nKey;
                  return true;
                }
              });
            });
            var attributeForTitle = this.get('attributeForTitle');
            if (isApp) {
              titleToken = i18n.t('title', {
                ns: module
              });
              return titleToken;
            } else if (attributeForTitle && Ember.get(model, attributeForTitle)) {
              titleToken = Ember.get(model, attributeForTitle);
            } else if (typeof i18nKeyFound === 'undefined') {
              _logger.default.info("['title-token-in-route' initializer] ".concat(tab, " i18n not found in"), i18nKeys);
            } else {
              titleToken = i18n.t(i18nKeyFound);
            }
          } else if (submodule === 'app' && module === 'apps' || submodule === 'index' && module === 'appentities' || typeof submodule !== 'undefined' && ['index'].indexOf(submodule) === -1) {
            // we are in a item
            if (typeof model !== 'undefined') {
              // use Ember.get because model could not be an Ember.Object instance (it could be a simple JS Object)
              var _attributeForTitle = this.get('attributeForTitle') || 'entityName';
              model = model.hasOwnProperty(submodule) ? Ember.get(model, submodule) : model;
              var ns = this.getI18nNamespace(submodule, isAdmin, isApp);
              if (Ember.get(model, 'isNew')) {
                titleToken = this.get('i18n').t('actions.new', {
                  ns: ns
                });
                return titleToken;
              }
              titleToken = Ember.get(model, _attributeForTitle) || Ember.get(model, 'entityName') || Ember.get(model, 'entityName') || Ember.get(model, 'name') || Ember.get(model, 'getReference');
            }
          } else if (typeof module !== 'undefined') {
            var _ns = this.getI18nNamespace(module, isAdmin, isApp);
            return i18n.t(['title_plural', 'title'], {
              ns: _ns
            });
          } else if (isApp) {
            titleToken = i18n.t('apps:title');
          }
        }
        return titleToken;
      },
      // TODO REMOVE THIS CODE WHEN PR73 WILL BE MERGED !
      // https://github.com/kimroen/ember-cli-document-title/pull/73/files
      actions: {
        collectTitleTokens: function collectTitleTokens(tokens) {
          var _this = this;
          var titleToken = Ember.get(this, 'titleToken');
          if (typeof titleToken === 'function') {
            titleToken = titleToken.call(this, Ember.get(this, 'currentModel'));
          }
          if (Ember.isArray(titleToken)) {
            tokens.unshift.apply(tokens, titleToken);
          } else if (titleToken) {
            tokens.unshift(titleToken);
          }

          // If `title` exists, it signals the end of the
          // token-collection, and the title is decided right here.
          var title = Ember.get(this, 'title');
          if (title) {
            // Wrap in promise in case some tokens are asynchronous.
            var completion = Promise.resolve().then(function () {
              if (typeof title === 'function') {
                // Wait for all tokens to resolve. It resolves immediately if all tokens are plain values (not promises).
                return Promise.all(tokens).then(function (resolvedTokens) {
                  return title.call(_this, resolvedTokens);
                });
              } else {
                // Tokens aren't even considered... a string
                // title just sledgehammer overwrites any children tokens.
                return title;
              }
            }).then(function (finalTitle) {
              var router = _this._router || _this.router;
              // Stubbable fn that sets document.title
              router.setTitle(finalTitle);
            });

            // Tell FastBoot about our async code
            var fastboot = lookupFastBoot(this);
            if (fastboot && fastboot.isFastBoot) {
              fastboot.deferRendering(completion);
            }
          } else {
            // Continue bubbling.
            return true;
          }
        }
      }
    });
  }

  // TODO REMOVE THIS CODE WHEN PR73 WILL BE MERGED !
  // https://github.com/kimroen/ember-cli-document-title/pull/73/files
  function lookupFastBoot(context) {
    var container = Ember.getOwner ? Ember.getOwner(context) : context.container;
    return container.lookup('service:fastboot');
  }
  var _default = _exports.default = {
    name: 'title-token-in-route',
    initialize: initialize
  };
});