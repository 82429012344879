define("boondmanager/models/batchmarkersfragment", ["exports", "ember-copy", "boondmanager/utils/logger", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "boondmanager/models/project"], function (_exports, _emberCopy, _logger, _emberData, _emberDataModelFragments, _attributes, _fragment, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    // originalID (front) <=> id (back)
    // cf serializers/fragment.js
    originalID: _emberData.default.attr('string'),
    distributionRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    timesProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    timesProductionInWorkUnits: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    turnoverProgressDistributedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    turnoverSignedDistributedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // FRAGMENTS
    markers: (0, _attributes.fragmentArray)('markerfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    resources: (0, _attributes.fragmentArray)('resourcefragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // owner = project
    owner: _emberDataModelFragments.default.fragmentOwner(),
    // COMPUTEDS
    balance: Ember.computed('durationForecast', function () {
      return this.get('durationForecast') - this.get('timesProduction');
    }),
    balanceInWorkUnits: Ember.computed('durationForecastInWorkUnits', 'timesProductionInWorkUnits', function () {
      return this.get('durationForecastInWorkUnits') - this.get('timesProductionInWorkUnits');
    }),
    difference: Ember.computed('balance', 'remainsToBeDone', function () {
      return this.get('balance') - this.get('remainsToBeDone');
    }),
    differenceInWorkUnits: Ember.computed('balanceInWorkUnits', 'remainsToBeDoneInWorkUnits', function () {
      return this.get('balanceInWorkUnits') - this.get('remainsToBeDoneInWorkUnits');
    }),
    durationForecast: Ember.computed('markers.@each.durationForecast', function () {
      var markers = this.get('markers');
      return markers.reduce(function (total, markers) {
        return total + Number(markers.get('durationForecast'));
      }, 0);
    }),
    durationForecastInWorkUnits: Ember.computed('markers.@each.durationForecastInWorkUnits', function () {
      var markers = this.get('markers');
      return markers.reduce(function (total, markers) {
        return total + Number(markers.get('durationForecastInWorkUnits'));
      }, 0);
    }),
    remainsToBeDone: Ember.computed('markers.@each.remainsToBeDone', function () {
      var markers = this.get('markers');
      return markers.reduce(function (total, markers) {
        return total + Number(markers.get('remainsToBeDone'));
      }, 0);
    }),
    remainsToBeDoneInWorkUnits: Ember.computed('markers.@each.remainsToBeDoneInWorkUnits', function () {
      var markers = this.get('markers');
      return markers.reduce(function (total, markers) {
        return total + Number(markers.get('remainsToBeDoneInWorkUnits'));
      }, 0);
    }),
    progressRate: Ember.computed('timesProduction', 'remainsToBeDone', function () {
      var _this$getProperties = this.getProperties('timesProduction', 'remainsToBeDone'),
        timesProduction = _this$getProperties.timesProduction,
        remainsToBeDone = _this$getProperties.remainsToBeDone;
      return (0, _project.progressRate)(timesProduction, remainsToBeDone);
    }),
    hasMoreThanOneMarker: Ember.computed('markers.length', function () {
      return this.get('markers.length') > 1;
    }),
    /**
     * Markers grouped by `resource`
     *
     * This CP groups `markers` by `resource` and adds resource's `timesProduction`
     * value if the marker's resource is found in the `resources` array. The
     * `timesProduction` props is used in `marker.progressRate` calculation.
     *
     * @property markersGroupedByResource
     * @type {Marker[]}
     * @readOnly
     */
    markersGroupedByResource: Ember.computed('markers.[]', 'resources.@each.{timesProduction,timesProductionInWorkUnits}', function () {
      var _this$getProperties2 = this.getProperties('markers', 'resources'),
        markers = _this$getProperties2.markers,
        resources = _this$getProperties2.resources;
      var markersGroupedByResource = Ember.A();
      var map = {};
      markers.forEach(function (marker) {
        var resourceID = marker.get('resource.originalID') || null;
        var groupName = resourceID === null ? 'indifferent' : resourceID;
        if (typeof map[groupName] === 'undefined') {
          // we create and we keep its index to futur retrieve it
          var markerCopy = (0, _emberCopy.copy)(marker);

          //We put the resource originalID back in the fragment
          if (marker.get('resource') !== null) {
            markerCopy.set('resource.originalID', resourceID);
          }

          // add a `timesProduction` here to calcul marker's progressRate
          var findedResource = resources.findBy('originalID', resourceID);
          if (findedResource) {
            markerCopy.set('resource.timesProduction', findedResource.get('timesProduction'));
            markerCopy.set('resource.timesProductionInWorkUnits', findedResource.get('timesProductionInWorkUnits'));
          } else if (resourceID) {
            markerCopy.set('resource.timesProduction', 0);
            markerCopy.set('resource.timesProductionInWorkUnits', 0);
          }

          //A cause du owner non recopié, on doit setter ces valeurs car la computed ne fonctionne plus
          markerCopy.set('durationForecastInWorkUnits', marker.get('durationForecastInWorkUnits'));
          markerCopy.set('remainsToBeDoneInWorkUnits', marker.get('remainsToBeDoneInWorkUnits'));
          map[groupName] = markersGroupedByResource.push(markerCopy) - 1;
        } else {
          var prevMarker = markersGroupedByResource[map[groupName]];
          var _prevMarker$getProper = prevMarker.getProperties('durationForecast', 'remainsToBeDone', 'durationForecastInWorkUnits', 'remainsToBeDoneInWorkUnits'),
            durationForecast = _prevMarker$getProper.durationForecast,
            remainsToBeDone = _prevMarker$getProper.remainsToBeDone,
            durationForecastInWorkUnits = _prevMarker$getProper.durationForecastInWorkUnits,
            remainsToBeDoneInWorkUnits = _prevMarker$getProper.remainsToBeDoneInWorkUnits;
          prevMarker.setProperties({
            durationForecast: durationForecast + marker.get('durationForecast'),
            remainsToBeDone: remainsToBeDone + marker.get('remainsToBeDone'),
            durationForecastInWorkUnits: durationForecastInWorkUnits + marker.get('durationForecastInWorkUnits'),
            remainsToBeDoneInWorkUnits: remainsToBeDoneInWorkUnits + marker.get('remainsToBeDoneInWorkUnits')
          });
        }
      });
      return markersGroupedByResource;
    }).readOnly(),
    /**
     * Resources not in `markers` array = unplanned resource that has a `timesProduction` for this batch
     *
     * @todo  rename this CP to 'unplannedResources'???
     *
     * @property resourcesNotInMarkers
     * @type {resourcefragment[]}
     * @readOnly
     */
    resourcesNotInMarkers: Ember.computed('markers.[]', 'resources.[]', function () {
      var _this$getProperties3 = this.getProperties('markers', 'resources'),
        markers = _this$getProperties3.markers,
        resources = _this$getProperties3.resources;
      var resourcesMarkersIDs = markers.mapBy('resource.originalID').uniq();
      var resourcesNotInMarkers = resources.filter(function (resource) {
        return resourcesMarkersIDs.indexOf(resource.get('originalID')) === -1;
      });
      return resourcesNotInMarkers;
    }).readOnly(),
    // METHODS
    // - Manager markers
    addMarker: function addMarker(marker) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var markers = this.get('markers');
      switch (_typeof(marker)) {
        case 'object':
          if ((0, _fragment.isFragment)(marker)) {
            markers.addFragment(makeAcopy ? (0, _emberCopy.copy)(marker) : marker);
          } else {
            markers.createFragment(marker);
          }
          break;
        default:
          markers.createFragment();
      }
    },
    delMarker: function delMarker(marker) {
      if ((0, _fragment.isFragment)(marker)) {
        return this.get('markers').removeFragment(marker);
      } else {
        _logger.default.warn("BatchMarkers#".concat(this.get('originalID'), ":delMarker(marker) - marker is not a fragment !"), marker);
      }
    }
  });
});