define("boondmanager/models/app", ["exports", "ember-data-copyable", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "boondmanager/models/fieldtemplatefragment"], function (_exports, _emberDataCopyable, _base, _emberData, _attributes, _fieldtemplatefragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['vendor']
    }),
    i18n: Ember.inject.service(),
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    key: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    price: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    version: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    testimoniesUrl: _emberData.default.attr('string'),
    termsOfUseUrl: _emberData.default.attr('string'),
    isInstalled: _emberData.default.attr('boolean'),
    isPublic: _emberData.default.attr('boolean'),
    hasConfigurationPage: _emberData.default.attr('boolean'),
    isValidated: _emberData.default.attr('boolean'),
    isBoondManagerApp: _emberData.default.attr('boolean'),
    isRefreshTokenValidated: _emberData.default.attr('boolean'),
    tokenSecurity: _emberData.default.attr('string'),
    buttonsSetting: (0, _attributes.fragmentArray)('buttonssettingfragment'),
    iFramesSetting: (0, _attributes.fragmentArray)('iframessettingfragment'),
    triggersSetting: (0, _attributes.fragmentArray)('triggerssettingfragment'),
    hostsAllowed: (0, _attributes.array)('string'),
    customersAllowed: (0, _attributes.array)('string'),
    apisAllowed: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    visibility: _emberData.default.attr(),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    expirationDate: _emberData.default.attr('moment'),
    token: _emberData.default.attr('string'),
    signedRequest: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    integration: _emberData.default.attr('string'),
    vendor: _emberData.default.belongsTo(),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    module: _emberData.default.attr('string'),
    tab: _emberData.default.attr('string'),
    allowGuestsValidation: _emberData.default.attr('boolean'),
    allowManagersValidation: _emberData.default.attr('boolean'),
    allowAccessToMyProfile: _emberData.default.attr('boolean'),
    allowEntitiesAction: _emberData.default.attr('string'),
    actionTypeOf: _emberData.default.attr('number'),
    sharingMailObject: _emberData.default.attr('string'),
    sharingMailBody: _emberData.default.attr('string'),
    validatedMailObject: _emberData.default.attr('string'),
    validatedMailBody: _emberData.default.attr('string'),
    defaultColumnsForParent: _emberData.default.attr(),
    defaultColumnsForSearch: _emberData.default.attr(),
    sections: (0, _attributes.fragmentArray)('sectiontemplatefragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    icon: Ember.computed('integration,id,code', function () {
      //(if this.model.isNative (concat 'bmi-app-' this.model.code) 'bmi-app')
      switch (this.get('integration')) {
        default:
          return 'bmi-app';
        case 'module':
          return this.get('id') ? 'bmi-app-' + this.get('code') : 'bmi-appmodule';
        case 'moduleNoCode':
          return 'bmi-appmodulenocode';
        case 'sectionNoCode':
          return 'bmi-appsectionnocode';
      }
    }),
    isIFrame: Ember.computed('integration', function () {
      return this.get('integration') === 'iFrame';
    }).readOnly(),
    isNative: Ember.computed('integration', function () {
      return this.get('integration') === 'module';
    }).readOnly(),
    isModuleNoCode: Ember.computed('integration', function () {
      return this.get('integration') === 'moduleNoCode';
    }).readOnly(),
    isSectionNoCode: Ember.computed('integration', function () {
      return this.get('integration') === 'sectionNoCode';
    }).readOnly(),
    appRoute: Ember.computed('code', function () {
      return "private.apps.".concat(this.get('code'));
    }).readOnly(),
    appComponent: Ember.computed('code', function () {
      return "octane/bm-app/component/".concat(this.get('code'));
    }),
    isIFrameCompatible: Ember.computed('url', 'integration', function () {
      return this.get('integration') === 'iFrame' && this.get('url');
    }),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }),
    isVisibilityForActivatedManagers: Ember.computed('visibility', function () {
      return this.get('visibility') === 'allowedManagers' || this.get('visibility') === 'allowedManagersAndResources';
    }),
    stateField: Ember.computed('sections.@each.fields', function () {
      var fieldFound = null;
      this.get('sections').find(function (section) {
        return section.get('fields').find(function (field) {
          if (field.get('typeOf') === 'state') {
            fieldFound = field;
            return true;
          }
          return false;
        });
      });
      return fieldFound;
    }),
    typeOfField: Ember.computed('sections.@each.fields', function () {
      var fieldFound = null;
      this.get('sections').find(function (section) {
        return section.get('fields').find(function (field) {
          if (field.get('typeOf') === 'typeOf') {
            fieldFound = field;
            return true;
          }
          return false;
        });
      });
      return fieldFound;
    }),
    mainManagerField: Ember.computed('sections.@each.fields', function () {
      var fieldFound = null;
      this.get('sections').find(function (section) {
        return section.get('fields').find(function (field) {
          if (field.get('typeOf') === 'mainManager') {
            fieldFound = field;
            return true;
          }
          return false;
        });
      });
      return fieldFound;
    }),
    agencyField: Ember.computed('sections.@each.fields', function () {
      var fieldFound = null;
      this.get('sections').find(function (section) {
        return section.get('fields').find(function (field) {
          if (field.get('typeOf') === 'agency') {
            fieldFound = field;
            return true;
          }
          return false;
        });
      });
      return fieldFound;
    }),
    poleField: Ember.computed('sections.@each.fields', function () {
      var fieldFound = null;
      this.get('sections').find(function (section) {
        return section.get('fields').find(function (field) {
          if (field.get('typeOf') === 'pole') {
            fieldFound = field;
            return true;
          }
          return false;
        });
      });
      return fieldFound;
    }),
    guestsField: Ember.computed('sections.@each.fields', function () {
      var fieldFound = null;
      this.get('sections').find(function (section) {
        return section.get('fields').find(function (field) {
          if (field.get('typeOf') === 'guests') {
            fieldFound = field;
            return true;
          }
          return false;
        });
      });
      return fieldFound;
    }),
    visibilityField: Ember.computed('sections.@each.fields', function () {
      var fieldFound = null;
      this.get('sections').find(function (section) {
        return section.get('fields').find(function (field) {
          if (field.get('typeOf') === 'visibility') {
            fieldFound = field;
            return true;
          }
          return false;
        });
      });
      return fieldFound;
    }),
    sharingEmailingOptions: Ember.computed('i18n._locale', 'sections', function () {
      var _this = this;
      var options = {
        plugins: 'mailchimp',
        toolbar: 'mailchimp_insertMergeTag',
        mailchimp: {
          btnText: this.get('i18n').t('adminappsdeveloped:view.emailing.mergeTagButton'),
          btnTooltip: this.get('i18n').t('adminappsdeveloped:view.emailing.mergeTagTooltip'),
          mergeTags: [{
            text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.label'),
            submenus: [{
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.allRecipients'),
              value: 'ALL_RECIPIENTS'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.shareManagerFirstName'),
              value: 'MANAGER_FIRSTNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.shareManagerLastName'),
              value: 'MANAGER_LASTNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.shareManagerPhone1'),
              value: 'MANAGER_PHONE'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.shareManagerEmail1'),
              value: 'MANAGER_EMAIL'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.recipientFirstName'),
              value: 'RECIPIENT_FIRSTNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.recipientLastName'),
              value: 'RECIPIENT_LASTNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.url'),
              value: 'APPENTITY_URL'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.appName'),
              value: 'APPENTITY_APPNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.reference'),
              value: 'APPENTITY_REF'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.internalReference'),
              value: 'APPENTITY_REFERENCE'
            }, {
              text: this.get('i18n').t('models:appentity.attributes.creationDate.name'),
              value: 'APPENTITY_CREATIONDATE'
            }, {
              text: this.get('i18n').t('models:appentity.attributes.updateDate.name'),
              value: 'APPENTITY_UPDATEDATE'
            }, {
              text: this.get('i18n').t('models:appentity.attributes.globalValidation.name'),
              value: 'APPENTITY_GLOBALVALIDATION'
            }, {
              text: this.get('i18n').t('models:appentity.attributes.guestsValidation.name'),
              value: 'APPENTITY_GUESTSVALIDATION'
            }, {
              text: this.get('i18n').t('models:appentity.attributes.guestValidator.name'),
              value: 'APPENTITY_GUESTVALIDATOR'
            }, {
              text: this.get('i18n').t('models:appentity.attributes.managersValidation.name'),
              value: 'APPENTITY_MANAGERSVALIDATION'
            }, {
              text: this.get('i18n').t('models:appentity.attributes.managerValidator.name'),
              value: 'APPENTITY_MANAGERVALIDATOR'
            }, {
              text: this.get('i18n').t('models:appentity.attributes.dependsOn.name'),
              value: 'APPENTITY_DEPENDSON'
            }]
          }]
        }
      };
      if (this.get('sections').length) {
        options.mailchimp.mergeTags.pushObject({
          text: this.get('i18n').t('adminappsdeveloped:view.emailing.fields.label'),
          submenus: []
        });
        this.get('sections').forEach(function (section) {
          section.get('fields').forEach(function (field) {
            switch (field.get('typeOf')) {
              case _fieldtemplatefragment.FIELD_MAINMANAGER:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:appentity.attributes.mainManager.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID')
                });
                break;
              case _fieldtemplatefragment.FIELD_AGENCY:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:appentity.attributes.agency.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID')
                });
                break;
              case _fieldtemplatefragment.FIELD_POLE:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:appentity.attributes.pole.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID')
                });
                break;
              case _fieldtemplatefragment.FIELD_GUESTS:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:appentity.attributes.guests.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID')
                });
                break;
              case _fieldtemplatefragment.FIELD_VISIBILITE:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:appentity.attributes.visibility.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID')
                });
                break;
              case _fieldtemplatefragment.FIELD_STATE:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:appentity.attributes.state.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID')
                });
                break;
              case _fieldtemplatefragment.FIELD_TYPEOF:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:appentity.attributes.typeOf.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID')
                });
                break;
              case _fieldtemplatefragment.FIELD_PERIOD:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:fieldresponse.attributes.field.attributes.startDate.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID') + '_STARTDATE'
                });
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:fieldresponse.attributes.field.attributes.endDate.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID') + '_ENDDATE'
                });
                break;
              case _fieldtemplatefragment.FIELD_PERIODTIME:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:fieldresponse.attributes.field.attributes.startDate.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID') + '_STARTDATE'
                });
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: _this.get('i18n').t('models:fieldresponse.attributes.field.attributes.endDate.name'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID') + '_ENDDATE'
                });
                break;
              case _fieldtemplatefragment.FIELD_ALERT:
              case _fieldtemplatefragment.FIELD_IFRAME:
              case _fieldtemplatefragment.FIELD_CURRENCY:
              case _fieldtemplatefragment.FIELD_FILES:
                break;
              default:
                options.mailchimp.mergeTags[1].submenus.pushObject({
                  text: field.get('title'),
                  value: 'APPENTITY_FIELD_' + field.get('originalID')
                });
                break;
            }
          });
        });
      }
      return options;
    }),
    validatedEmailingOptions: Ember.computed('i18n._locale', 'model.sections', function () {
      return {
        plugins: 'mailchimp',
        toolbar: 'mailchimp_insertMergeTag',
        mailchimp: {
          btnText: this.get('i18n').t('adminappsdeveloped:view.emailing.mergeTagButton'),
          btnTooltip: this.get('i18n').t('adminappsdeveloped:view.emailing.mergeTagTooltip'),
          mergeTags: [{
            text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.label'),
            submenus: [{
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.recipientFirstName'),
              value: 'RECIPIENT_FIRSTNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.recipientLastName'),
              value: 'RECIPIENT_LASTNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.validatorFirstName'),
              value: 'VALIDATOR_FIRSTNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.validatorLastName'),
              value: 'VALIDATOR_LASTNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.url'),
              value: 'APPENTITY_URL'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.appName'),
              value: 'APPENTITY_APPNAME'
            }, {
              text: this.get('i18n').t('adminappsdeveloped:view.emailing.global.reference'),
              value: 'APPENTITY_REF'
            }]
          }]
        }
      };
    })
  });
  App.reopenClass({
    prefix: 'APP'
  });
  App.reopen({
    prefix: Ember.computed(function () {
      return App.prefix;
    }).readOnly()
  });
  var _default = _exports.default = App;
});