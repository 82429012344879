define("boondmanager/models/invoice", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable", "boondmanager/utils/financial"], function (_exports, _base, _emberData, _attributes, _emberDataCopyable, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WARNING_INVOICE_HAS_NOT_MERGEABLE_FILES = _exports.WARNING_INVOICE_DATE_MODIFIED = _exports.STATE_UNPAID = _exports.STATE_PROFORMA = _exports.STATE_PAID = _exports.STATE_CREATION = _exports.INVOICE_TASK_STATE_VALIDATED = _exports.INVOICE_TASK_STATE_PENDING = _exports.INVOICE_FORMAT_NATIVE = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var STATE_PROFORMA = _exports.STATE_PROFORMA = 10;
  var STATE_CREATION = _exports.STATE_CREATION = 0;
  var STATE_UNPAID = _exports.STATE_UNPAID = 2;
  var STATE_PAID = _exports.STATE_PAID = 3;
  var INVOICE_TASK_STATE_PENDING = _exports.INVOICE_TASK_STATE_PENDING = 'pending';
  var INVOICE_TASK_STATE_VALIDATED = _exports.INVOICE_TASK_STATE_VALIDATED = 'validated';
  var INVOICE_FORMAT_NATIVE = _exports.INVOICE_FORMAT_NATIVE = 'native';
  var WARNING_INVOICE_HAS_NOT_MERGEABLE_FILES = _exports.WARNING_INVOICE_HAS_NOT_MERGEABLE_FILES = 'invoiceHasNotMergeableFiles';
  var WARNING_INVOICE_DATE_MODIFIED = _exports.WARNING_INVOICE_DATE_MODIFIED = 'invoiceDateModified';
  var Invoice = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['order', 'schedule']
    }),
    // Attributes
    activityDetails: _emberData.default.attr(),
    creationDate: _emberData.default.attr('date'),
    // TAB_FACTURATION.FACT_DATE
    updateDate: _emberData.default.attr('moment'),
    creditNote: _emberData.default.attr('boolean'),
    // TAB_FACTURATION.FACT_AVOIR
    currency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_DEVISE
    currencyName: _emberData.default.attr('string'),
    // TAB_FACTURATION.FACT_DEVISE_LABEL
    currencySymbol: _emberData.default.attr('string'),
    // TAB_FACTURATION.FACT_DEVISE_SYMBOLE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_DEVISEAGENCE
    date: _emberData.default.attr('date'),
    discountRate: _emberData.default.attr('number'),
    endDate: _emberData.default.attr('date'),
    exchangeRate: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_CHANGEAGENCE
    expectedPaymentDate: _emberData.default.attr('date'),
    // TAB_FACTURATION.FACT_DATEREGLEMENTATTENDUE
    isCreditNote: _emberData.default.attr('boolean'),
    paymentMethod: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_TYPEPAYMENT
    performedPaymentDate: _emberData.default.attr('date'),
    reference: _emberData.default.attr('string'),
    // TAB_FACTURATION.FACT_REF
    informationComments: _emberData.default.attr('string'),
    // TAB_FACTURATION.FACT_COMMENTAIRE
    showCommentsOnPDF: _emberData.default.attr('boolean'),
    startDate: _emberData.default.attr('date'),
    state: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_ETAT
    turnoverInvoicedExcludingTax: _emberData.default.attr('number'),
    turnoverInvoicedIncludingTax: _emberData.default.attr('number'),
    totalPayableIncludingTax: _emberData.default.attr('number'),
    totalExcludingTax: _emberData.default.attr('number'),
    totalIncludingTax: _emberData.default.attr('number'),
    closed: _emberData.default.attr('boolean'),
    locked: _emberData.default.attr('boolean'),
    hasAttachment: _emberData.default.attr('boolean'),
    /** ATTRIBUTS DE BACKUP */
    orderLegals: _emberData.default.attr('string'),
    orderPaymentTerm: _emberData.default.attr('number'),
    bankDetailDescription: _emberData.default.attr('string'),
    bankDetailBic: _emberData.default.attr('string'),
    bankDetailIban: _emberData.default.attr('string'),
    agencyName: _emberData.default.attr('string'),
    agencyVatNumber: _emberData.default.attr('string'),
    agencyRegistrationNumber: _emberData.default.attr('string'),
    agencyAddress: _emberData.default.attr('string'),
    agencyPostcode: _emberData.default.attr('string'),
    agencyTown: _emberData.default.attr('string'),
    agencyCountry: _emberData.default.attr('string'),
    agencySubDivision: _emberData.default.attr('string'),
    agencyApeCode: _emberData.default.attr('string'),
    agencyEmail: _emberData.default.attr('string'),
    agencyPhone: _emberData.default.attr('string'),
    agencyRegisteredOffice: _emberData.default.attr('string'),
    agencyLegalStatus: _emberData.default.attr('string'),
    factorName: _emberData.default.attr('string'),
    factorSiren: _emberData.default.attr('string'),
    factorNic: _emberData.default.attr('string'),
    factorLegalStatus: _emberData.default.attr('string'),
    factorRegisteredOffice: _emberData.default.attr('string'),
    factorVatNumber: _emberData.default.attr('string'),
    factorAddress: _emberData.default.attr('string'),
    factorPostcode: _emberData.default.attr('string'),
    factorTown: _emberData.default.attr('string'),
    factorCountry: _emberData.default.attr('string'),
    factorSubDivision: _emberData.default.attr('string'),
    factorApeCode: _emberData.default.attr('string'),
    billingDetailName: _emberData.default.attr('string'),
    billingDetailContact: _emberData.default.attr('string'),
    billingDetailAddress1: _emberData.default.attr('string'),
    billingDetailAddress2: _emberData.default.attr('string'),
    billingDetailAddress3: _emberData.default.attr('string'),
    billingDetailPostcode: _emberData.default.attr('string'),
    billingDetailTown: _emberData.default.attr('string'),
    billingDetailCountry: _emberData.default.attr('string'),
    billingDetailSubDivision: _emberData.default.attr('string'),
    billingDetailVatNumber: _emberData.default.attr('string'),
    billingDetailRegistrationNumber: _emberData.default.attr('string'),
    billingDetailNumber: _emberData.default.attr('string'),
    billingDetailSendingMode: _emberData.default.attr('number'),
    billingDetailSendingModeParams: _emberData.default.attr(),
    headOfficeName: _emberData.default.attr('string'),
    headOfficeContact: _emberData.default.attr('string'),
    headOfficeAddress1: _emberData.default.attr('string'),
    headOfficeAddress2: _emberData.default.attr('string'),
    headOfficeAddress3: _emberData.default.attr('string'),
    headOfficePostcode: _emberData.default.attr('string'),
    headOfficeTown: _emberData.default.attr('string'),
    headOfficeCountry: _emberData.default.attr('string'),
    headOfficeSubDivision: _emberData.default.attr('string'),
    typeOf: _emberData.default.attr('string'),
    invoiceRecords: (0, _attributes.fragmentArray)('invoicerecord'),
    invoicePayments: (0, _attributes.fragmentArray)('invoicepayment'),
    //right
    canReadInvoice: _emberData.default.attr('boolean'),
    canWriteInvoice: _emberData.default.attr('boolean'),
    // Droit d'écriture ( par procuration de suppresion)

    // Relationships
    order: _emberData.default.belongsTo(),
    schedule: _emberData.default.belongsTo('schedule', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    files: _emberData.default.hasMany('document'),
    //computed
    canReadBilling: Ember.computed.alias('canReadInvoice'),
    canWriteBilling: Ember.computed.alias('canWriteInvoice'),
    isProForma: Ember.computed.equal('state', STATE_PROFORMA),
    isCreation: Ember.computed.equal('state', STATE_CREATION),
    isPaid: Ember.computed.equal('state', STATE_PAID),
    entityName: Ember.computed('reference', function () {
      var reference = this.get('reference');
      if (Ember.isBlank(reference)) {
        return this.get('internalReference');
      } else {
        return reference;
      }
    }).readOnly(),
    emailsOptions: Ember.computed('order.billingDetail.emails.[]', function () {
      var options = [];
      var emails = [];
      var distinct = function distinct(value, index, self) {
        return self.indexOf(value) === index && value;
      };
      if (this.get('order') && this.get('order.billingDetail') && this.get('order.billingDetail.emails')) {
        emails = this.get('order.billingDetail.emails');
      }
      var distinctEmails = emails.filter(distinct);
      distinctEmails.forEach(function (email) {
        options.push({
          id: email,
          value: email
        });
      });
      return options;
    }).readOnly(),
    currencySymbolValue: Ember.computed('currencySymbol', 'i18n._locale', 'currency', function () {
      var symbol;
      if (typeof this.get('currencySymbol') !== 'undefined') {
        symbol = this.get('currencySymbol');
      } else {
        var currency = this.get('settingsService').customer.currency;
        var currentCurrency = currency.findBy('id', this.get('currency'));
        symbol = currentCurrency.symbol;
      }
      return symbol;
    }).readOnly(),
    hasBillingDetail: Ember.computed('billingDetailName', 'billingDetailContact', 'billingDetailAddress1', 'billingDetailAddress2', 'billingDetailAddress3', 'billingDetailPostcode', 'billingDetailTown', 'billingDetailVatNumber', 'billingDetailRegistrationNumber', 'billingDetailNumber', 'billingDetailSendingMode', 'billingDetailSendingModeParams', function () {
      return this.get('billingDetailName') || this.get('billingDetailContact') || this.get('billingDetailAddress1') || this.get('billingDetailAddress2') || this.get('billingDetailAddress3') || this.get('billingDetailTown') || this.get('billingDetailPostcode') || this.get('billingDetailVatNumber') || this.get('billingDetailRegistrationNumber') || this.get('billingDetailNumber') || this.get('billingDetailSendingMode') > -1 || this.get('billingDetailSendingModeParams');
    }).readOnly(),
    hasHeadOffice: Ember.computed('headOfficeName', 'headOfficeContact', 'headOfficeAddress1', 'headOfficeAddress2', 'headOfficeAddress3', 'headOfficePostcode', 'headOfficeTown', function () {
      return this.get('headOfficeName') || this.get('headOfficeContact') || this.get('headOfficeAddress1') || this.get('headOfficeAddress2') || this.get('headOfficeAddress3') || this.get('headOfficePostcode') || this.get('headOfficeTown');
    }).readOnly(),
    billingDetail: Ember.computed('billingDetailName', 'billingDetailContact', 'billingDetailAddress1', 'billingDetailAddress2', 'billingDetailAddress3', 'billingDetailPostcode', 'billingDetailTown', 'billingDetailCountry', 'billingDetailSubDivision', 'billingDetailVatNumber', 'billingDetailRegistrationNumber', 'billingDetailNumber', 'billingDetailSendingMode', 'billingDetailSendingModeParams', 'order.billingDetail.{emails.[],phone1,cc.[],bcc.[]}', function () {
      var _this = this;
      var promise = this.get('order.billingDetail').then(function (billingDetail) {
        return {
          name: _this.get('billingDetailName'),
          contact: _this.get('billingDetailContact'),
          address1: _this.get('billingDetailAddress1'),
          address2: _this.get('billingDetailAddress2'),
          address3: _this.get('billingDetailAddress3'),
          postcode: _this.get('billingDetailPostcode'),
          town: _this.get('billingDetailTown'),
          country: _this.get('billingDetailCountry'),
          subDivision: _this.get('billingDetailSubDivision'),
          vatNumber: _this.get('billingDetailVatNumber'),
          registrationNumber: _this.get('billingDetailRegistrationNumber'),
          number: _this.get('billingDetailNumber'),
          sendingMode: _this.get('billingDetailSendingMode'),
          sendingModeParams: _this.get('billingDetailSendingModeParams'),
          emails: billingDetail ? billingDetail.get('emails') : '',
          phone1: billingDetail ? billingDetail.get('phone1') : '',
          cc: billingDetail ? billingDetail.get('cc') : [],
          bcc: billingDetail ? billingDetail.get('bcc') : []
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    totalTurnoverIncludingTax: Ember.computed('invoiceRecords.{[],@each.turnoverIncludingTax}', '{locked,totalIncludingTax}', function () {
      if (this.get('locked')) return this.get('totalIncludingTax');
      return this.get('invoiceRecords').reduce(function (sum, item) {
        return sum = (0, _financial.add)(sum, item.turnoverIncludingTax);
      }, 0);
    }).readOnly(),
    totalInvoicedIncludingTax: Ember.computed('totalTurnoverIncludingTax', '{discountRate,turnoverInvoicedIncludingTax}', function () {
      return (0, _financial.roundAtPrecision)(this.get('locked') ? this.get('turnoverInvoicedIncludingTax') : this.get('totalTurnoverIncludingTax') * (1 - this.get('discountRate') / 100), 2);
    }).readOnly(),
    totalPaidIncludingTax: Ember.computed('invoicePayments.{[],@each.amountIncludingTax}', function () {
      var total = 0;
      this.get('invoicePayments').forEach(function (p) {
        total = (0, _financial.add)(total, p.get('amountIncludingTax'));
      });
      return (0, _financial.roundAtPrecision)(total, 2);
    }).readOnly(),
    amountPayableIncludingTax: Ember.computed('totalInvoicedIncludingTax', 'totalPaidIncludingTax', 'isCreditNote', function () {
      if (this.isCreditNote) {
        return (0, _financial.roundAtPrecision)((0, _financial.add)(this.get('totalInvoicedIncludingTax'), this.get('totalPaidIncludingTax')), 2);
      } else {
        return (0, _financial.roundAtPrecision)((0, _financial.sub)(this.get('totalInvoicedIncludingTax'), this.get('totalPaidIncludingTax')), 2);
      }
    }).readOnly()
  });
  Invoice.reopenClass({
    prefix: 'FACT'
  });
  Invoice.reopen({
    prefix: Ember.computed(function () {
      return Invoice.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Invoice;
});