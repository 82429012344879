define("boondmanager/routes/kanban", ["exports", "boondmanager/routes/search", "boondmanager/utils/search"], function (_exports, _search, _search2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * Kanban route use in all our Kanban views
   *
   * @class KanbanRoute
   * @extends Ember.SearchRoute
   * @namespace Ember
   * @module Routes
   */
  var _default = _exports.default = _search.default.extend({
    /**
     * view mode (`list` or `kanban`)
     * @property viewMode
     * @type string
     */
    viewMode: _search2.VIEW_MODE_LIST,
    /**
     * Clé du dictionnaire qui contient les états pour le modèle courant
     *
     * @property stateKey
     * @type String
     * @default null
     */
    stateKey: null,
    /**
     * Clé de paramètre pour l'état
     *
     * @property stateParamKey
     * @type String
     * @default null
     */
    stateParamKey: null,
    /**
     * {
     *     meta: {
     *         totals: {
     *             rows: 100, // somme des totaux
     *         },
     *         loaded: {
     *             rows: 0, // Total des cartes chargées - permettra de savoir si il n'y a plus de pages
     *         },
     *         pending: {
     *             cols: 0, // Total des requêtes en attente, utiliser un observer directement sur 'date.{$i}.query' ne fonctionne pas
     *         }
     *     },
     *     data: [
     *     		{
     *              id: 1, // id de l'état
     *              total: 100, // total de ce sous état
     *              totalPages: 4, // total de pages de ce sous état
     *              page: 1, // Page active de ce sous état
     *              totalOffset: 0, // Lors d'un déplacement, permet d'ajuster le total car "solr" ne fait pas immédiatement la syncrho avec MySQL
     *              query: promise // chargement en cours
     *              data: [], // donnees chargees
     *     		}
     *     ]
     * }
     *
     */
    kanbanModel: null,
    queryParams: {
      viewMode: {
        refreshModel: true,
        type: 'string'
      }
    },
    /** METHODS **/
    buildEmptyKanbanModel: function buildEmptyKanbanModel(kanbanStates) {
      var kanbanModel = Ember.Object.extend().create({
        meta: Ember.Object.extend().create({
          totals: Ember.Object.extend().create({
            rows: 0
          }),
          loaded: Ember.Object.extend().create({
            rows: 0
          }),
          pending: Ember.Object.extend().create({
            cols: 0
          })
        }),
        data: Ember.A([])
      });
      for (var i = 0; i < kanbanStates.length; i++) {
        kanbanModel.get('data').pushObject(Ember.Object.extend().create({
          id: kanbanStates[i],
          total: 0,
          totalPages: 0,
          page: 1,
          totalOffset: 0,
          query: null,
          data: Ember.A([])
        }));
      }
      return kanbanModel;
    },
    // Load "Kanban" cards model
    loadKanbanModel: function loadKanbanModel(params, kanbanStates) {
      var model = params.page > 1 && this.get('kanbanModel') ||
      // on prend le model précédent s'il existe et si on ne charge pas la première page (sinon reset)
      (params.page = 1) && this.buildEmptyKanbanModel(kanbanStates); // on construit un nouveau model sinon, et on initialise la page 1 et on force le reset de la page

      // 3. lancement des recherches pour chacun des états
      for (var i = 0; i < model.data.length; i++) {
        this.appendKanbanQueryToModel(params, model, i);
      }

      // 4. sauvegarde du model pour le prochain chargement pour ensuite ajouter les données au fur et a  mesure
      this.set('kanbanModel', model);

      // 5. renvoie du model (mais pas sous la forme d'une promesse pour ne pas bloquer le chargement
      return model;
    },
    /*
     * loadKanbanData sample fro candidates
     * must be overrided by view query
     * return query promise
     */
    loadColumnKanbanData: function loadColumnKanbanData(params, idState) {
      var paramsState = _objectSpread({}, params);
      paramsState['candidateStates'] = idState;
      return this.get('store').query('candidate', paramsState);
    },
    appendKanbanQueryToModel: function appendKanbanQueryToModel(params, model, i) {
      var _this = this;
      var clearModel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      // Do query only if we've not load all column pages or it's page 1
      if (model.get("data.".concat(i, ".data")).length < model.get("data.".concat(i, ".total")) + model.get("data.".concat(i, ".totalOffset")) || model.get("data.".concat(i, ".page")) === 1) {
        var modelParams = _objectSpread({}, params);
        var kanbanColumnPages = this._getKanbanColumnPages();
        var tmp_model;
        if (kanbanColumnPages && kanbanColumnPages[model.data[i].id]) {
          tmp_model = kanbanColumnPages[model.data[i].id];
        } else if (model.get("data.".concat(i, ".page"))) {
          tmp_model = model.get("data.".concat(i, ".page"));
        } else {
          tmp_model = 1;
        }
        // On vérifie si une page a été enregistrée (en cas de refresh), sinon on récupère la page actuelle, sinon on commence à la page 1
        model.set("data.".concat(i, ".page"), tmp_model);
        if (model.get("data.".concat(i, ".totalPages")) > 0 && model.get("data.".concat(i, ".page")) > model.get("data.".concat(i, ".totalPages"))) {
          model.set("data.".concat(i, ".page"), model.get("data.".concat(i, ".totalPages")));
        }
        modelParams.page = model.get("data.".concat(i, ".page"));
        var kanbanDataQuery = this.loadColumnKanbanData(modelParams, model.data[i].id);
        var withTotalOffsetCorrection = true;
        model.set("data.".concat(i, ".query"), kanbanDataQuery);
        model.set('meta.pending.cols', model.get('meta.pending.cols') + 1);
        model.get("data.".concat(i, ".query")).then(function (result) {
          if (clearModel) {
            // On vide les données afin de n'afficher qu'une seule page à la fois
            model.get("data.".concat(i, ".data")).clear();
          }
          _this.addResultsToModel(model, i, result, withTotalOffsetCorrection);
          _this.calculateTotalItems(model);
        }).finally(function () {
          model.set('meta.pending.cols', model.get('meta.pending.cols') - 1);
        });
      }
    },
    /*
     * Add Query Results to Model
     * withTotalOffsetCorrection: default false. True = if solr not sync with MySQL, and result not same as filter
     *                  1. Put result on right model
     *                  2. Add offset to Total, because card moved between 2 colonnes, so total is ajusted
     */
    addResultsToModel: function addResultsToModel(model, index, results) {
      var withTotalOffsetCorrection = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var resultDatas = results.toArray();
      var idxModel,
        totalOffset = 0;
      resultDatas.forEach(function (result) {
        if (result.state !== undefined && result.state !== model.get("data.".concat(index, ".id"))) {
          var idxNewState = model.get('data').findIndex(function (item) {
            return item.id === result.state;
          });
          idxModel = idxNewState;
        } else {
          idxModel = index;
        }
        // Check if model is already present do not add it again (ex: model dragged and then get next page)
        var isModelAlreadyPresent = model.get("data.".concat(idxModel, ".data")).findBy('id', result.id);
        if (!isModelAlreadyPresent) {
          model.get("data.".concat(idxModel, ".data")).pushObject(result);
          // If result is not in the right column, move it
          if (idxModel !== index) {
            // if withTotalOffsetCorrection : Correct the column destination's Total
            if (withTotalOffsetCorrection) {
              model.set("data.".concat(idxModel, ".totalOffset"), model.get("data.".concat(idxModel, ".totalOffset")) + 1);
            }
            // Correct the column source's Total
            totalOffset = totalOffset - 1;
          }
        }
      });

      // Problème Synchro Sol / MySQL : totals.row positif mais data vide (sync entre le calcul du total et la récupération des datas)
      if (resultDatas.length) {
        model.set("data.".concat(index, ".totalOffset"), totalOffset);
        model.set("data.".concat(index, ".total"), results.meta.totals.rows);
        model.set("data.".concat(index, ".totalPages"), (0, _search2.calculateNbPages)(model.get("data.".concat(index, ".total")) + model.get("data.".concat(index, ".totalOffset")), this._getControllerMaxResults()));
      }
    },
    // Calculate "meta.totals.rows"
    calculateTotalItems: function calculateTotalItems(model) {
      model.set('meta.totals.rows', 0);
      for (var j = 0; j < model.get('data').length; j++) {
        model.set('meta.totals.rows', model.get('meta.totals.rows') + model.get("data.".concat(j, ".total")));
      }
    },
    /*
     * Load next card into model
     */
    loadNextCard: function loadNextCard(queryParams, idState) {
      var _this2 = this;
      var kanbanModel = this.get('kanbanModel');
      var idxState = kanbanModel.get('data').findIndex(function (item) {
        return item.id === idState;
      });
      queryParams.maxResults = 1;
      // Loading 2 next cards because of Solr synch with MySQL : next one could be the dropped card
      queryParams.page = kanbanModel.get("data.".concat(idxState, ".data")).length + 2;
      var kanbanDataQuery = this.loadColumnKanbanData(queryParams, kanbanModel.data[idxState].id);
      kanbanModel.set("data.".concat(idxState, ".query"), kanbanDataQuery);
      kanbanModel.get("data.".concat(idxState, ".query")).then(function (result) {
        var withOffset = false;
        _this2.addResultsToModel(kanbanModel, idxState, result, withOffset);
        _this2.calculateTotalItems(kanbanModel);
      });
    },
    /**
     * Définition de la liste des états à afficher (dictionnaire ∩ params) ou uniquement params si defined, sinon dictionnaire
     * @param params
     * @return {*}
     */
    getKanbanColumnIds: function getKanbanColumnIds(params) {
      var _this3 = this;
      if (this.stateKey && this.stateParamKey) {
        return this.get('settings').customer.state[this.stateKey].filter(function (state) {
          return state.isEnabled && (!(params[_this3.stateParamKey] && params[_this3.stateParamKey].length) || params[_this3.stateParamKey].includes(state.id));
        }).mapBy('id');
      } else {
        return Ember.A([]);
      }
    },
    /** EMBER LIFECYCLE **/resetController: function resetController(controller) {
      controller.send('resetKanbanColumnPages');
      this._super.apply(this, arguments);
    },
    /**
     * Max results sur les recherches par défaut
     * @return {*}
     * @private
     */
    _getControllerMaxResults: function _getControllerMaxResults() {
      return this.controller ? this.controller.get('maxResults') : _search2.DEFAULT_ROWS_BY_PAGE;
    },
    /**
     * Colonnes sur la recherche Kanban
     * @return {*}
     * @private
     */
    _getKanbanColumnPages: function _getKanbanColumnPages() {
      return this.controller ? this.controller.get('kanbanColumnPages') : [];
    },
    /** ACTIONS **/
    actions: {
      /*
       * Charger une page précise pour une colonne kanban
       */
      loadKanbanModelColumnPage: function loadKanbanModelColumnPage(queryParams, idState, page) {
        var kanbanModel = this.get('kanbanModel');
        var idxModel = kanbanModel.get('data').findIndex(function (item) {
          return item.id === idState;
        });
        kanbanModel.set("data.".concat(idxModel, ".page"), page);
        queryParams.maxResults = this._getControllerMaxResults();
        this.appendKanbanQueryToModel(queryParams, kanbanModel, idxModel, true);
      },
      /*
       * Fonction qui permet de déplacer directement dans le modèle une card d'une colonne à une autre
       * Ceci permet de gérer le déplacement coté front et éviter qu'on ai un décalage des données (synchro Solr/MySQL)
       */
      moveModelState: function moveModelState(queryParams, idModel, oldState, newState) {
        var kanbanModel = this.get('kanbanModel');
        var idxOldState = kanbanModel.get('data').findIndex(function (item) {
          return item.id === oldState;
        });
        var idxNewState = kanbanModel.get('data').findIndex(function (item) {
          return item.id === newState;
        });
        kanbanModel.get("data.".concat(idxOldState, ".data")).forEach(function (result) {
          if (result.id === idModel) {
            // On retire la card de la colonne de départ
            kanbanModel.get("data.".concat(idxOldState, ".data")).removeObject(result);
            kanbanModel.set("data.".concat(idxOldState, ".totalOffset"), kanbanModel.get("data.".concat(idxOldState, ".totalOffset")) - 1);
            // On ajoute la card à la colonne d'arrivée, au début (tri par date de MAJ)
            kanbanModel.get("data.".concat(idxNewState, ".data")).unshiftObject(result);
            kanbanModel.set("data.".concat(idxNewState, ".totalOffset"), kanbanModel.get("data.".concat(idxNewState, ".totalOffset")) + 1);
          }
        });
        this.loadNextCard(queryParams, oldState);
      }
    }
  });
});