define("boondmanager/models/absencequota", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    period: _emberData.default.attr('string'),
    workUnitType: (0, _attributes.fragment)('workunittype'),
    quotaAcquired: _emberData.default.attr('number'),
    useBeingAcquired: _emberData.default.attr('string', {
      defaultValue: 'inactive'
    }),
    monthlyIncrement: _emberData.default.attr('number'),
    autoDeferral: _emberData.default.attr('boolean')
  });
});