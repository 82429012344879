define("boondmanager/pods/components/bm-field/simple-colorpicker/state/selected-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Gl2b2j+K",
    "block": "{\"symbols\":[],\"statements\":[[8,\"octane/bm-sticker\",[],[[\"@sticker\"],[[30,[36,1],[\"bm-state-color-\",[35,0,[\"selected\",\"id\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"select\",\"concat\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/simple-colorpicker/state/selected-item/template.hbs"
  });
});