define("boondmanager/models/appsmicrosoftresource", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsMicrosoftResource = _base.default.extend({
    providerEmail: _emberData.default.attr('string'),
    oauthRedirection: _emberData.default.attr('string')
  });
  AppsMicrosoftResource.reopenClass({
    prefix: 'COMP'
  });
  AppsMicrosoftResource.reopen({
    prefix: Ember.computed(function () {
      return AppsMicrosoftResource.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsMicrosoftResource;
});