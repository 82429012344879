define("boondmanager/pods/components/bm-field/power-select/button-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K9OkXvSP",
    "block": "{\"symbols\":[\"@class\",\"@icon\",\"@select\",\"&default\"],\"statements\":[[10,\"button\"],[15,0,[32,1]],[14,4,\"button\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,1],[[32,2],[30,[36,0],[[32,2]],null]],null]],[2,\"\\n\\t\\t\"],[18,4,[[32,3,[\"selected\"]],[32,3]]],[2,\"\\n\\t\\t\"],[1,[30,[36,0],[\"bmi-caret-down\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/button-trigger/template.hbs"
  });
});