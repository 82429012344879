define("boondmanager/models/dashboard", ["exports", "ember-data", "ember-data-copyable", "boondmanager/models/base", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataCopyable, _base, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Dashboard = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['dependsOn']
    }),
    columns: _emberData.default.attr('number'),
    isDefault: _emberData.default.attr('boolean'),
    gadgets: (0, _attributes.fragmentArray)('gadgetfragment'),
    dependsOn: _emberData.default.belongsTo('base', {
      inverse: null
    })
  });
  Dashboard.reopenClass({
    prefix: 'DSHBRD'
  });
  Dashboard.reopen({
    prefix: Ember.computed(function () {
      return Dashboard.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Dashboard;
});