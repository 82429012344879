define("boondmanager/models/appsepaproject", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "boondmanager/models/project"], function (_exports, _base, _emberData, _emberDataCopyable, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsSepaProject = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['company']
    }),
    // ATTRIBUTES
    /**
     * Generic Project Type
     *
     * -1 = Inactivité de type "Absence"
     * -2 = Inactivité de type "Interne"
     *  1 = AT/Régie Forfaitée
     *  2 = Forfait
     *  3 = Recrutement
     *  4 = Produit
     *
     * @type {Number}
     */
    mode: _emberData.default.attr('number'),
    reference: _emberData.default.attr('string'),
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    // RELATIONSHIPS
    agency: _emberData.default.belongsTo('appsepaagency'),
    company: _emberData.default.belongsTo('appsepacompany'),
    intermediaryCompany: _emberData.default.belongsTo('appsepacompany'),
    opportunity: _emberData.default.belongsTo(),
    entityName: Ember.computed('reference', function () {
      return this.get('getReference');
    }),
    labelColor: Ember.computed('mode', function () {
      if (this.get('isTA')) return 'regie';else if (this.get('isPackage')) return 'forfait';else if (this.get('isRecruitment')) return 'recrutement';else return 'produit';
    }),
    isTA: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_TA;
    }),
    isPackage: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_PACKAGE;
    }),
    isRecruitment: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_RECRUITMENT;
    }),
    isProduct: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_PRODUCT;
    }),
    billingDetailCompany: Ember.computed('company', 'intermediaryCompany', function () {
      return this.get('intermediaryCompany.id') ? this.get('intermediaryCompany') : this.get('company');
    }).readOnly()
  });
  AppsSepaProject.reopenClass({
    prefix: 'PRJ'
  });
  AppsSepaProject.reopen({
    prefix: Ember.computed(function () {
      return AppsSepaProject.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsSepaProject;
});