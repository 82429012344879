define("boondmanager/transforms/notationarray", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return Ember.A([]);
      }
      var data = serialized.map(function (value) {
        value.NOTES = value.NOTATION_NOTES.split('#').map(function (v) {
          return v.split('|');
        });
        return value;
      });
      return Ember.A(data);
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        deserialized.map(function (value) {
          value.NOTATION_NOTES = value.NOTES.map(function (v) {
            return v.join('|');
          }).join('#');
          return value;
        });
        return deserialized;
      }
    }
  });
});