define("boondmanager/serializers/fragment", ["exports", "ember-data/serializers/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Common fragment serializer
   *
   * This serializer apply to all fragment. We use it here to rename `id` attribute
   * from Back to `originalID` for the Front (because `id` is not allowed as as
   * `DS.attr`)
   *
   * This serializer is registered in initializers/fragment-serializer.js
   *
   * @class FragmentSerializer
   * @extends DS.JSONSerializer
   * @namespace  Ember
   * @module  Models
   */
  var _default = _exports.default = _json.default.extend({
    keyForAttribute: function keyForAttribute(key /*, method*/) {
      if (key === 'originalID') {
        return 'id';
      }
      return this._super.apply(this, arguments);
    }
  });
});