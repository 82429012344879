define("boondmanager/models/apppostproductionproject", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable", "ember-data-model-fragments/attributes", "boondmanager/models/project"], function (_exports, _emberData, _base, _emberDataCopyable, _attributes, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var AppsPostProductionProject = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['contact', 'company', 'deliveries', 'purchases']
    }),
    mode: _emberData.default.attr('number'),
    reference: _emberData.default.attr('string'),
    productionComments: _emberData.default.attr('string'),
    productionTerm: _emberData.default.attr('date'),
    currency: _emberData.default.attr('number'),
    currencyAgency: _emberData.default.attr('number'),
    exchangeRate: _emberData.default.attr('number'),
    exchangeRateAgency: _emberData.default.attr('number'),
    workUnitRate: _emberData.default.attr('number'),
    turnoverProductionExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // TAB_CONTRAT.CTR_SALAIREMENSUEL
    turnoverInvoicedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfOrders: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    canGenerateInvoices: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    timesReportsState: _emberData.default.attr({
      defaultValue: false
    }),
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    additionalTurnoverAndCosts: (0, _attributes.fragmentArray)('additionalturnoverandcostsfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    schedules: _emberData.default.hasMany('schedule'),
    contact: _emberData.default.belongsTo(),
    company: _emberData.default.belongsTo(),
    deliveries: _emberData.default.hasMany('apppostproductiondelivery'),
    purchases: _emberData.default.hasMany('purchase'),
    projectValue: undefined,
    isRecruitment: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_RECRUITMENT;
    }),
    isProduct: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_PRODUCT;
    }),
    isActivityTypeInWorkUnit: Ember.computed('typeOf', 'i18n._locale', function () {
      var _this = this;
      var typeOf = this.get('settingsService').customer.typeOf.project.find(function (item) {
        return item.id === _this.get('typeOf');
      });
      return typeOf && typeOf.activityType === _project.ACTIVITY_WORKUNIT;
    }).readOnly(),
    missingSignedTimesheets: Ember.computed('deliveries.@each.missingSignedTimesheets', function () {
      var promise = this.get('deliveries').then(function (deliveries) {
        var deliveryStates = deliveries.map(function (delivery) {
          return delivery.get('missingSignedTimesheets');
        });
        return Ember.RSVP.all(deliveryStates).then(function (statesResolved) {
          var missingSignedTimesheets = statesResolved.some(function (deliveryState) {
            return deliveryState;
          });
          return {
            value: missingSignedTimesheets
          };
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    missingOptionalSignedTimesheets: Ember.computed('deliveries.@each.missingOptionalSignedTimesheets', function () {
      var promise = this.get('deliveries').then(function (deliveries) {
        var deliveryStates = deliveries.map(function (delivery) {
          return delivery.get('missingOptionalSignedTimesheets');
        });
        return Ember.RSVP.all(deliveryStates).then(function (statesResolved) {
          var missingOptionalSignedTimesheets = statesResolved.some(function (deliveryState) {
            return deliveryState;
          });
          return {
            value: missingOptionalSignedTimesheets
          };
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    signatureRequestedTimesheets: Ember.computed('deliveries.@each.signatureRequestedTimesheets', function () {
      var promise = this.get('deliveries').then(function (deliveries) {
        var deliveryStates = deliveries.map(function (delivery) {
          return delivery.get('signatureRequestedTimesheets');
        });
        return Ember.RSVP.all(deliveryStates).then(function (statesResolved) {
          var signatureRequestedTimesheets = statesResolved.some(function (deliveryState) {
            return deliveryState;
          });
          return {
            value: signatureRequestedTimesheets
          };
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    blockInvoiceGeneration: Ember.computed('deliveries.@each.hasBlockGenerationConfig', 'missingSignedTimesheets', function () {
      var promise = Ember.RSVP.all([this.get('deliveries'), this.get('missingSignedTimesheets')]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          deliveries = _ref2[0],
          missingSignedTimesheets = _ref2[1];
        var deliveriesState = deliveries.map(function (delivery) {
          return delivery.get('hasBlockGenerationConfig');
        });
        return Ember.RSVP.all(deliveriesState).then(function (statesResolved) {
          var hasBlockGenerationConfig = statesResolved.some(function (hasBlockGenerationConfig) {
            return hasBlockGenerationConfig;
          });
          return {
            value: hasBlockGenerationConfig && missingSignedTimesheets.value
          };
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    projectId: Ember.computed('id', function () {
      var tabId = this.get('id').split('_');
      return tabId[0];
    })
  });
  AppsPostProductionProject.reopenClass({
    prefix: 'PRJ'
  });
  AppsPostProductionProject.reopen({
    prefix: Ember.computed(function () {
      return AppsPostProductionProject.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsPostProductionProject;
});