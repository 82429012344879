define("boondmanager/pods/components/bm-field/language/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/logger"], function (_exports, _component, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * bm-field-language component.
   *
   * To be used in languages loop:
   * {{#each model.languages key="@index" as |language index|}}
   *    {{bm-field/language language=language index=index}}
   * {{/each}}
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-language'],
    classNameBindings: ['errorClass'],
    errorClass: Ember.computed('errors', function () {
      return this.get('errors') ? 'bm-error has-error' : '';
    }),
    /**
     * Fetch languages list and prepend 'To define'
     *
     * @property languages
     * @type {Object[]}
     */
    languages: Ember.computed('i18n._locale', function () {
      var values = this.get('settings').customer.languageSpoken;
      if (Ember.isArray(values)) {
        values.unshift({
          id: '',
          value: this.get('i18n').t('common:toDefine')
        });
      } else {
        _logger.default.info('setting.languageSpoken does not contains values', values);
        values = [];
      }
      return values;
    }),
    /**
     * Whether to show the text input
     *
     * @property specifyLanguage
     * @type {Boolean}
     */
    specifyLanguage: Ember.computed('i18n._locale', 'language.language', function () {
      var values = this.get('settings').customer.languageSpoken;
      if (Ember.isArray(values)) {
        var languageIds = values.map(function (lang) {
          return lang.id;
        });
        return languageIds.indexOf(this.get('language.language')) === -1;
      } else {
        _logger.default.info('setting.languageSpoken does not contains values', values);
        return false;
      }
    }),
    languageSelected: Ember.computed('i18n._locale', 'language.language', function () {
      if (this.get('specifyLanguage')) {
        return this.get('i18n').t('common:toDefine');
      } else {
        return this.get('language.language');
      }
    }),
    levelSelected: Ember.computed('i18n._locale', 'language.language', function () {
      var _this = this;
      var languageLevel = this.get('settings').customer.languageLevel.find(function (l) {
        return l.id === _this.get('language.level');
      });
      if (languageLevel) {
        return languageLevel.value;
      }
      return this.get('language.level');
    }),
    actions: {
      deleteLanguage: function deleteLanguage(index) {
        this.get('deleteLanguage')(index);
      }
    }
  });
});