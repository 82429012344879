define("boondmanager/models/apporganizationcharts-setting", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ERROR_CODE_ORGCHART_SETTING_NOTEXISTS = _exports.DEFAULT_COLORS = void 0;
  var ERROR_CODE_ORGCHART_SETTING_NOTEXISTS = _exports.ERROR_CODE_ORGCHART_SETTING_NOTEXISTS = '11001';
  var DEFAULT_COLORS = _exports.DEFAULT_COLORS = [{
    id: 'web-orange ',
    value: '#fea500ff'
  },
  //orange-yellow
  {
    id: 'eucalyptus',
    value: '#44d7b6ff'
  }, {
    id: 'pink-flamingo',
    value: '#f636ffff'
  }, {
    id: 'coral-pink',
    value: '#ff6078ff'
  },
  //Wild Watermelon
  {
    id: 'lochmara',
    value: '#047ad4ff'
  }, {
    id: 'blaze-orange',
    value: '#fa6400ff'
  }, {
    id: 'atlantis',
    value: '#89d52cff'
  }, {
    id: 'amethyst',
    value: '#a12cd5ff'
  }, {
    id: 'wild-strawberry',
    value: '#ff3695ff'
  }, {
    id: 'shadow-blue',
    value: '#778ca2ff'
  },
  //blue-grey
  {
    id: 'alizarin-crimson',
    value: '#d9321fff'
  }, {
    id: 'pine',
    value: '#49ab81ff'
  }, {
    id: 'purple-heart',
    value: '#622cd5ff'
  }, {
    id: 'dark-sky-blue',
    value: '#4ebde4ff'
  },
  //Picton Blue
  {
    id: 'pickled-bluewood',
    value: '#33495dff'
  }];
  var _default = _exports.default = _emberData.default.Model.extend({
    colors: (0, _attributes.fragmentArray)('idvalue-fragment'),
    orgchart: _emberData.default.belongsTo('apporganizationchartsOrgchart')
  });
});