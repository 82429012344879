define("boondmanager/models/appmarkersmarker", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "moment"], function (_exports, _base, _emberData, _emberDataCopyable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsMarkersMarker = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['resource', 'agency', 'batch', 'project']
    }),
    date: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)();
      }
    }),
    durationForecast: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    remainsToBeDone: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    progressRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    batch: _emberData.default.belongsTo(),
    resource: _emberData.default.belongsTo(),
    project: _emberData.default.belongsTo()
  });
  AppsMarkersMarker.reopenClass({
    prefix: 'MARK'
  });
  AppsMarkersMarker.reopen({
    prefix: Ember.computed(function () {
      return AppsMarkersMarker.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsMarkersMarker;
});