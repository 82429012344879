define("boondmanager/models/formquestionfragment", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.QUESTION_TYPEOF_SINGLECHOICE = _exports.QUESTION_TYPEOF_MULTIPLECHOICE = _exports.QUESTION_STATE_ENABLED = _exports.QUESTION_STATE_ARCHIVED = void 0;
  var QUESTION_STATE_ARCHIVED = _exports.QUESTION_STATE_ARCHIVED = false;
  var QUESTION_STATE_ENABLED = _exports.QUESTION_STATE_ENABLED = true;
  var QUESTION_TYPEOF_SINGLECHOICE = _exports.QUESTION_TYPEOF_SINGLECHOICE = 'singleChoice';
  var QUESTION_TYPEOF_MULTIPLECHOICE = _exports.QUESTION_TYPEOF_MULTIPLECHOICE = 'multipleChoice';
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    typeOf: _emberData.default.attr('string'),
    required: _emberData.default.attr('boolean'),
    state: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    // réponse saisie par l'utilisateur
    response: _emberData.default.attr(),
    // choix des réponses côté administration
    choices: (0, _attributes.fragmentArray)('formquestionchoicefragment'),
    /**
     *
     * @property errors
     * @type {array}
     */
    errors: [],
    /**
     *
     * @property originalQuestion
     * @type {object}
     */
    originalQuestion: null,
    /**
     *
     * @property edition
     * @type {boolean}
     */
    edition: false,
    /*** COMPUTED ***/

    title: Ember.computed('typeOf', 'required', 'state', 'i18n._locale', function () {
      var _this = this;
      var i18n = this.get('i18n');
      var option = i18n.t('forms.templates.template.question.typesOf', {
        returnObjects: true
      }).find(function (item) {
        return item.id === _this.get('typeOf');
      });
      var title = typeof option !== 'undefined' ? option.value : '';
      if (!this.get('required')) {
        title += " <i>(".concat(i18n.t('forms:view.question.optional').toLowerCase(), ")</i>");
      }
      if (this.get('state') == QUESTION_STATE_ARCHIVED) {
        title += " - ".concat(i18n.t('forms:view.archived'));
      }
      return title;
    }).readOnly(),
    choicesList: Ember.computed('choices.[]', function () {
      return this.get('choices').toArray();
    }).readOnly(),
    canConfirm: Ember.computed('description', 'choices.{[],@each.description}', function () {
      var optionsWithDescription = this.get('choices').filter(function (choice) {
        return choice.get('description').length > 0;
      });
      var optionsIsNeeded = [QUESTION_TYPEOF_SINGLECHOICE, QUESTION_TYPEOF_MULTIPLECHOICE].includes(this.get('typeOf'));
      return this.get('description').length && (!optionsIsNeeded || optionsIsNeeded && optionsWithDescription);
    }).readOnly()
  });
});