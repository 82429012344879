define("boondmanager/models/thread", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "moment"], function (_exports, _base, _emberData, _emberDataCopyable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var Thread = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['dependsOn']
    }),
    moment: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    text: _emberData.default.attr('string'),
    numberOfAnswers: _emberData.default.attr('number'),
    share: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    mentions: _emberData.default.hasMany('resource'),
    dependsOn: _emberData.default.belongsTo('base'),
    parentThread: _emberData.default.belongsTo('thread', {
      inverse: null
    }),
    viewMoreMentions: false,
    edition: false,
    answers: Ember.A([]),
    textWithMentionForComment: Ember.computed('text', 'mentions', function () {
      var text = this.get('text');
      var replacements = this.get('mentionsReplacement');
      replacements.forEach(function (replacement) {
        var matches = _toConsumableArray(text.matchAll(replacement.needle));
        for (var i = 0; i < matches.length; i++) {
          text = text.replace(replacement.needle, "<a data-action=\"mentionClickThread\" data-mention-id=\"".concat(replacement.id, "\" class=\"bmb-text\">@").concat(replacement.value, "</a>"));
        }
      });
      return Ember.String.htmlSafe(text);
    }),
    textWithMentionForShare: Ember.computed('mentions', 'seeMoreMentions', function () {
      var mentions = this.get('mentions');
      var seeMoreMentions = this.get('seeMoreMentions');
      var text = '';
      var i = 0;
      mentions.forEach(function (mention) {
        if (!seeMoreMentions) {
          i++;
        }
        if (i > 3) return;
        if (mention.firstName && mention.lastName) text += "<a data-action=\"mentionClickThread\" data-mention-id=\"".concat(mention.id, "\" class=\"bmb-text\">@").concat(mention.completeName, "</a>  ");else text += "<span class=\"bmb-text\">@".concat(mention.completeName, "</span>  ");
      });
      return Ember.String.htmlSafe(text);
    }).readOnly(),
    textWithMentionForEdit: Ember.computed('text', 'mentions', function () {
      var text = this.get('text');
      var replacements = this.get('mentionsReplacement');
      replacements.forEach(function (replacement) {
        var matches = _toConsumableArray(text.matchAll(replacement.needle));
        for (var i = 0; i < matches.length; i++) {
          text = text.replace(replacement.needle, "<span class=\"mceNonEditable mentionEditThread\" data-id=\"COMP".concat(replacement.id, "\">@").concat(replacement.value, "</span>"));
        }
      });
      return Ember.String.htmlSafe(text);
    }),
    seeMoreMentions: Ember.computed('mentions', 'viewMoreMentions', function () {
      var mentions = this.get('mentions');
      return mentions.length > 3 && this.get('viewMoreMentions');
    }).readOnly(),
    creationDateFromNow: Ember.computed('creationDate', function () {
      return (0, _moment.default)(this.get('creationDate'), 'YYYY-MM-DD hh:mm:ss').fromNow();
    }),
    isEditable: Ember.computed('currentUser.id', 'createdBy.id', function () {
      return this.get('currentUser.id') === this.get('createdBy.id');
    }),
    /**
     * Construction du tableau de remplacement des valeurs de mentions contenues dans le texte avec les valeurs réelles
     * @returns {*[]}
     * @private
     */
    mentionsReplacement: Ember.computed('mentions', 'text', function () {
      var mentions = this.get('mentions');
      var replacements = [];
      var text = this.get('text');
      if (text) {
        var mentionRegex = new RegExp('@COMP([1-9][0-9]*)', 'g');
        var mentionsFound = _toConsumableArray(text.matchAll(mentionRegex));
        var distincIds = [];
        mentionsFound.forEach(function (mentionFound) {
          var id = mentionFound[1];
          if (!distincIds.includes(id)) {
            distincIds.push(id);
            var mention = mentions.filter(function (mention) {
              return mention.id === id;
            });
            if (mention[0]) {
              replacements.push({
                needle: new RegExp("@COMP".concat(id, "\\b"), 'gm'),
                id: id,
                value: mention[0].completeName
              });
            }
          }
        });
      }
      return replacements;
    }).readOnly(),
    isDirty: Ember.computed('currentValue', 'textWithMentionForEdit', function () {
      return this.get('textWithMentionForEdit').toString() !== this.get('currentValue');
    }).readOnly(),
    isInEdition: Ember.computed('edition', 'isNew', function () {
      return this.get('edition') || this.get('isNew');
    }).readOnly(),
    currentValue: Ember.computed('id', {
      get: function get() {
        return this.get('textWithMentionForEdit').toString() || '';
      },
      set: function set(_, value) {
        return value;
      }
    })
  });
  Thread.reopenClass({
    prefix: 'THR'
  });
  Thread.reopen({
    prefix: Ember.computed(function () {
      return Thread.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Thread;
});