define("boondmanager/serializers/workplacesdefaultweek", ["exports", "boondmanager/serializers/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    // FRONT -> BACK
    serialize: function serialize() {
      // serializer au niveau de la config pour supprimer les temps pointés vide
      var json = this._super.apply(this, arguments);
      if (json.hasOwnProperty('days')) {
        json.days = json.days.filter(function (day) {
          return typeof day !== 'undefined';
        });
      }
      return json;
    } /*snapshot, options*/
  });
});