define("boondmanager/models/appsurveysurveytype", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    barometer: _emberData.default.attr('string'),
    question: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    allowComments: _emberData.default.attr('boolean'),
    required: Ember.computed('barometer', function () {
      return this.get('barometer') !== 'none';
    })
  });
});