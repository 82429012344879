define("boondmanager/models/role", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROLE_TYPE_TEMPLATE = _exports.ROLE_TYPE_MANUALLY = _exports.ROLE_TYPE_MANAGER = _exports.ROLE_TYPE_INTRANET = _exports.ROLE_TYPE_ACCOUNT_TEMPLATE = void 0;
  var ROLE_TYPE_MANUALLY = _exports.ROLE_TYPE_MANUALLY = 'manually';
  var ROLE_TYPE_TEMPLATE = _exports.ROLE_TYPE_TEMPLATE = 'template';
  var ROLE_TYPE_ACCOUNT_TEMPLATE = _exports.ROLE_TYPE_ACCOUNT_TEMPLATE = 'accountTemplate';
  var ROLE_TYPE_INTRANET = _exports.ROLE_TYPE_INTRANET = 'intranet';
  var ROLE_TYPE_MANAGER = _exports.ROLE_TYPE_MANAGER = 'manager';
  var Role = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['apps', 'otherPoles', 'otherAgencies', 'advancedAppCalendar', 'advancedAppMail', 'advancedAppEmailing', 'advancedAppViewer', 'dashboards']
    }),
    name: _emberData.default.attr('string'),
    numberOfAccounts: _emberData.default.attr('number'),
    numberOfActiveAccounts: _emberData.default.attr('number'),
    isSecondaryAgenciesAllowed: _emberData.default.attr('boolean'),
    isSecondaryPolesAllowed: _emberData.default.attr('boolean'),
    advancedRights: _emberData.default.attr(),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    logInOnlyFromThirdParty: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    manageAllowedDevices: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    warningForNewDevice: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    apps: _emberData.default.hasMany(),
    otherAgencies: _emberData.default.hasMany('agency', {
      inverse: null
    }),
    otherPoles: _emberData.default.hasMany('pole', {
      inverse: null
    }),
    advancedAppCalendar: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppMail: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppViewer: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppEmailing: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    dashboards: _emberData.default.hasMany('dashboard', {
      inverse: null
    }),
    typeOf: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return 'manager';
      }
    }),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }).readOnly(),
    isTypeOfIntranet: Ember.computed('typeOf', function () {
      return this.get('typeOf') === ROLE_TYPE_INTRANET;
    })
  });
  Role.reopenClass({
    prefix: 'ROLE'
  });
  Role.reopen({
    prefix: Ember.computed(function () {
      return Role.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Role;
});