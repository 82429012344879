define("boondmanager/utils/import-data/mapper", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TYPE_SERIALIZED = _exports.TYPE_MODEL = _exports.TYPE_INTEGER = _exports.TYPE_FLOAT = _exports.TYPE_ENUM = _exports.TYPE_DOUBLE_SERIALIZED = _exports.TYPE_DATE = _exports.TYPE_BASIC = _exports.TYPE_AVAILABILITY = _exports.CSVMapping = void 0;
  _exports.buildAvailabilityMapper = buildAvailabilityMapper;
  _exports.buildBasicListMapper = buildBasicListMapper;
  _exports.buildBasicMapping = buildBasicMapping;
  _exports.buildCSVMapping = buildCSVMapping;
  _exports.buildDateMapper = buildDateMapper;
  _exports.buildDoubleSerializedMapper = buildDoubleSerializedMapper;
  _exports.buildEnumMapper = buildEnumMapper;
  _exports.buildFloatMapper = buildFloatMapper;
  _exports.buildIntegerMapper = buildIntegerMapper;
  _exports.buildMappingFromI18nObject = buildMappingFromI18nObject;
  _exports.buildModelMapper = buildModelMapper;
  _exports.buildSerializedMapper = buildSerializedMapper;
  _exports.default = void 0;
  _exports.flattenMapping = flattenMapping;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var TYPE_BASIC = _exports.TYPE_BASIC = 'basic';
  var TYPE_ENUM = _exports.TYPE_ENUM = 'enum';
  var TYPE_MODEL = _exports.TYPE_MODEL = 'model';
  var TYPE_SERIALIZED = _exports.TYPE_SERIALIZED = 'serialized';
  var TYPE_DATE = _exports.TYPE_DATE = 'date';
  var TYPE_FLOAT = _exports.TYPE_FLOAT = 'float';
  var TYPE_INTEGER = _exports.TYPE_INTEGER = 'integer';
  var TYPE_AVAILABILITY = _exports.TYPE_AVAILABILITY = 'availability';
  var TYPE_DOUBLE_SERIALIZED = _exports.TYPE_DOUBLE_SERIALIZED = 'doubleSerialized';

  /**
   *
   * @param {array} result
   * @param keyAttr
   * @param valueAttr
   */
  function buildMappingFromI18nObject(result) {
    var keyAttr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
    var valueAttr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'value';
    var map = {};
    if (_typeof(result) === 'object') {
      for (var i = 0; i < result.length; i++) {
        var entry = result[i];
        if (typeof entry.option !== 'undefined') {
          map = Ember.assign(map, buildMappingFromI18nObject(entry.option, keyAttr, valueAttr));
        } else {
          var key = entry[keyAttr];
          map[key] = entry[valueAttr];
        }
      }
    } // else path incorrect, on a une chaine qui correspond au path

    return map;
  }
  var BasicMapping = Ember.Object.extend({
    type: TYPE_BASIC,
    /* CSVMapping */
    parent: null,
    isValid: function isValid() {
      return true;
    },
    scan: function scan(value) {
      return this.isValid(value);
    },
    decode: function decode(value) {
      return value;
    },
    encode: function encode(key) {
      return key;
    },
    addManualMapping: function addManualMapping() {},
    getUnknownValues: function getUnknownValues() {
      return Ember.A([]);
    },
    reset: function reset() {},
    init: function init() {}
  });
  var EnumMapping = BasicMapping.extend({
    type: TYPE_ENUM,
    options: null,
    /*
    {
    	0: "Mr",
    	1: "Mme"
    }
    */
    originalKeysToValueMap: null,
    /*
    {
    	"Licorne": 1
    }
    */
    manualValueToKeyMap: null,
    /*["amour", "orgue"]*/
    unknownValues: null,
    /*
    {
    	0: "Mr",
    	1: "Mme"
    }
    */
    toValueMap: null,
    /*
    {
    	"Mr": 0,
    	"0": 0,
    	"Mme": 1,
    	"1": 1
    }
    */
    toKeysMap: null,
    isValid: function isValid(value) {
      var toKeysMap = this.get('toKeysMap');
      var test = typeof toKeysMap[value] !== 'undefined';
      if (!test) {
        this.get('unknownValues').push(value);
        this.notifyPropertyChange('unknownValues');
      }
      return test;
    },
    decode: function decode(value) {
      if (this.isValid(value)) {
        return this.get('toValueMap')[this.encode(value)];
      } else {
        return value;
      }
    },
    encode: function encode(key) {
      return this.get('toKeysMap')[key];
    },
    addManualMapping: function addManualMapping(fromValue, toKey) {
      this.get('manualValueToKeyMap')[fromValue] = toKey;
      this.notifyPropertyChange('manualValueToKeyMap');

      // si la valeur est inconnue, on l'enleve
      var unknownValues = this.getUnknownValues();
      var i = unknownValues.indexOf(fromValue);
      if (i >= 0) {
        unknownValues.splice(i, 1);
        this.set('unknownValues', unknownValues);
        this.notifyPropertyChange('unknownValues');
      }
      this.mergeMapping();
    },
    getUnknownValues: function getUnknownValues() {
      // On appel pas optimize qui réécris unknownValues et créer une erreur avec octane
      return Object.keys(this.get('unknownValues').reduce(function (uniq, value) {
        if (!uniq[value]) uniq[value] = true;
        return uniq;
      }, {}));
    },
    mergeMapping: function mergeMapping() {
      var originalKeysToValueMap = this.get('originalKeysToValueMap');
      var toKeysMap = this.get('toKeysMap');
      var toValueMap = this.get('toValueMap');
      var manualValueToKeyMap = this.get('manualValueToKeyMap');
      for (var key in originalKeysToValueMap) {
        var value = originalKeysToValueMap[key];
        toKeysMap[value] = key;
        toKeysMap[key] = key;
        toValueMap[key] = value;
      }
      for (var _value in manualValueToKeyMap) {
        var _key = manualValueToKeyMap[_value];
        toKeysMap[_value] = _key;
      }
      this.notifyPropertyChange('toValueMap');
      this.notifyPropertyChange('toKeysMap');
    },
    reset: function reset() {
      this.set('manualValueToKeyMap', {});
      this.set('toKeysMap', {});
      this.set('toValueMap', {});
      this.set('unknownValues', Ember.A([]));
      this.mergeMapping();
    },
    optimize: function optimize() {
      // on enleve les doublons
      this.set('unknownValues', Object.keys(this.get('unknownValues').reduce(function (uniq, value) {
        if (!uniq[value]) uniq[value] = true;
        return uniq;
      }, {})));
    },
    init: function init() {
      this.set('originalKeysToValueMap', buildMappingFromI18nObject(this.get('options')));
      this.reset();
    }
  });
  var ModelMapper = EnumMapping.extend({
    type: TYPE_MODEL,
    store: null,
    model: null,
    init: function init() {
      this.reset();
    },
    reset: function reset() {
      this.set('storeCache', {});
      this._super.apply(this, arguments);
    },
    saveCache: function saveCache(key, model) {
      this.get('storeCache')[key] = model;
      this.notifyPropertyChange('storeCache');
    },
    getCache: function getCache(key) {
      return this.get('storeCache')[key];
    },
    exists: function exists(key) {
      return typeof this.get('storeCache')[key] !== 'undefined';
    },
    isValid: function isValid(value) {
      if (!isNaN(parseInt(value, 10))) {
        var modelClass = this.get('model');
        var keyCache = 'isValid|' + value;
        if (this.exists(keyCache)) {
          return this.getCache(keyCache);
        } else {
          var p = this.get('store').findRecord(modelClass, value, {
            reload: false,
            backgroundReload: false
          }).then(function () {
            return true;
          }).catch(function () {
            return false;
          });
          this.saveCache(keyCache, p);
          return p;
        }
      } else {
        return false;
      }
    },
    decode: function decode(value) {
      var modelClass = this.get('model');
      if (this.isValid(value)) {
        var keyCache = 'decode|' + value;
        if (this.exists(keyCache)) {
          return this.getCache(keyCache);
        } else {
          var p = this.get('store').findRecord(modelClass, value, {
            reload: false,
            backgroundReload: false
          }).then(function (entity) {
            return entity.get('attributeForSaveNotifications');
          }).catch(function () {
            return value;
          });
          this.saveCache(keyCache, p);
          return p;
        }
      }
      return value;
    },
    encode: function encode(value) {
      return this.isValid(value) ? value : '';
    },
    mergeMapping: function mergeMapping() {}
  });
  var serializedMapper = EnumMapping.extend({
    type: TYPE_SERIALIZED,
    splitChar: '|',
    isValid: function isValid(value) {
      var _this = this;
      var splitChar = this.get('splitChar');
      var valid = true;
      value.replace(/\s*,\s*/g, splitChar).split(splitChar).map(function (item) {
        if (!_this._super(item.trim())) {
          valid = false;
        }
      });
      return valid;
    },
    decode: function decode(value) {
      var _this2 = this;
      var splitChar = this.get('splitChar');
      return value.replace(/\s*,\s*/g, splitChar).split(splitChar).map(function (key) {
        if (_this2.isValid(key)) {
          return _this2.get('toValueMap')[_this2.encode(key)];
        } else {
          return key;
        }
      }).join(', ');
    },
    encode: function encode(string) {
      var _this3 = this;
      if (this.isValid(string)) {
        var splitChar = this.get('splitChar');
        return string.replace(/\s*,\s*/g, splitChar).split(splitChar).map(function (value) {
          return _this3.get('toKeysMap')[value.trim()];
        }).join(splitChar);
      }
      return '';
    }
  });
  var dateMapper = BasicMapping.extend({
    type: TYPE_DATE,
    format: 'DD/MM/YYYY',
    addHour: false,
    isValid: function isValid(value) {
      return value && (0, _moment.default)(value, this.get('format'), true).isValid();
    },
    encode: function encode(key) {
      if (!this.isValid(key)) {
        return '';
      }
      var date = (0, _moment.default)(key, this.get('format'), true);
      if (this.get('format').includes('HH:mm:ss')) {
        return date.format('YYYY-MM-DD HH:mm:ss');
      }
      return date.format('YYYY-MM-DD') + (this.get('addHour') ? ' 00:00:00' : '');
    }
  });
  var basicListMapper = BasicMapping.extend({
    type: TYPE_SERIALIZED,
    splitChar: '|',
    isValid: function isValid(value) {
      var _this4 = this;
      var splitChar = this.get('splitChar');
      var valid = true;
      value.replace(/\s*,\s*/g, splitChar).split(splitChar).map(function (item) {
        if (!_this4._super(item.trim())) {
          valid = false;
        }
      });
      return valid;
    },
    encode: function encode(string) {
      if (this.isValid(string)) {
        var splitChar = this.get('splitChar');
        return string.replace(/\s*,\s*/g, splitChar).split(splitChar).map(function (value) {
          return value.trim();
        }).join(splitChar);
      }
      return '';
    }
  });
  var availabilityMapper = EnumMapping.extend({
    type: TYPE_AVAILABILITY,
    format: 'DD/MM/YYYY',
    isValid: function isValid(value) {
      return (0, _moment.default)(value, this.get('format'), true).isValid() || this._super.apply(this, arguments);
    },
    decode: function decode(value) {
      var format = this.get('format');
      var date = (0, _moment.default)(value, format, true);
      if (date.isValid()) {
        return date.format(format);
      }
      return this._super.apply(this, arguments);
    },
    encode: function encode(key) {
      var format = this.get('format');
      var date = (0, _moment.default)(key, format, true);
      if (date.isValid()) {
        return date.format('YYYY-MM-DD');
      }
      return this._super.apply(this, arguments);
    }
  });
  var integerMapper = BasicMapping.extend({
    type: TYPE_INTEGER,
    isValid: function isValid(value) {
      var number = Number(value.split(' ').join(''));
      return !isNaN(number);
    },
    encode: function encode(key) {
      var number = parseInt(key.split(' ').join(''), 10);
      return isNaN(number) ? '' : number;
    }
  });
  var floatMapper = BasicMapping.extend({
    type: TYPE_FLOAT,
    isValid: function isValid(value) {
      var number = Number(value.replace(',', '.').split(' ').join(''));
      return !isNaN(number);
    },
    encode: function encode(key) {
      var number = Number(key.replace(',', '.').split(' ').join(''));
      return isNaN(number) ? '' : number;
    }
  });
  var doubleSerializedMapper = EnumMapping.extend({
    type: TYPE_DOUBLE_SERIALIZED,
    init: function init() {
      var options = this.get('options');
      for (var i = 0; i < options.length; i++) {
        var property = options[i]['property'];
        var mapping = options[i]['mapper'];
        this.set(property, mapping); // accessible par nom (ex: 'tool' )
        this.set('property' + i, mapping); // accessible par propertyX (ex: property0)
      }
    },
    isValid: function isValid(value) {
      var _this5 = this;
      var splitChar1 = this.get('splitChar1');
      var splitChar2 = this.get('splitChar2');
      var valid = true;
      value.replace(/\s*,\s*/g, splitChar2).replace(/\s*:\s*/g, splitChar1).split(splitChar2).map(function (item) {
        var itemParts = item.split(splitChar1);
        if (itemParts.length !== 2) {
          valid = false;
        } else {
          itemParts.map(function (val, i) {
            var mapper = _this5.get('property' + i);
            if (!mapper.isValid(val)) {
              valid = false;
            }
          });
        }
      });
      return valid;
    },
    decode: function decode(value) {
      var _this6 = this;
      var splitChar1 = this.get('splitChar1');
      var splitChar2 = this.get('splitChar2');
      return value.replace(/\s*,\s*/g, splitChar2).replace(/\s*:\s*/g, splitChar1).split(splitChar2).map(function (item) {
        return item.split(splitChar1).map(function (val, i) {
          var mapper = _this6.get('property' + i);
          return mapper.decode(val.trim());
        }).join(': ');
      }).join(', ');
    },
    encode: function encode(string) {
      var _this7 = this;
      if (this.isValid(string)) {
        var splitChar1 = this.get('splitChar1');
        var splitChar2 = this.get('splitChar2');
        return String(string).replace(/\s*,\s*/g, splitChar2).replace(/\s*:\s*/g, splitChar1).split(splitChar2).map(function (item) {
          return item.split(splitChar1).map(function (val, i) {
            var mapper = _this7.get('property' + i);
            return mapper.encode(val.trim());
          }).join(splitChar1);
        }).join(splitChar2);
      }
      return '';
    },
    addManualMapping: function addManualMapping(fromValue, toKey, name) {
      var mapper = this.get(name);
      if (mapper.addManualMapping) {
        mapper.addManualMapping(fromValue, toKey);
      }
    },
    getUnknownValues: function getUnknownValues(name) {
      var prop = this.get(name);
      if (prop && prop.getUnknownValues) {
        return prop.getUnknownValues();
      } else {
        return Ember.A([]);
      }
    }
  });
  function buildEnumMapper(options) {
    return EnumMapping.create({
      options: options
    });
  }
  function buildBasicListMapper() {
    return basicListMapper.create();
  }
  function buildDateMapper() {
    var format = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'YYYY-MM-DD';
    var addHour = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return dateMapper.create({
      format: format,
      addHour: addHour
    });
  }
  function buildAvailabilityMapper() {
    var format = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'YYYY-MM-DD';
    var options = arguments.length > 1 ? arguments[1] : undefined;
    return availabilityMapper.create({
      format: format,
      options: options
    });
  }
  function buildFloatMapper() {
    return floatMapper.create();
  }
  function buildIntegerMapper() {
    return integerMapper.create();
  }
  function buildSerializedMapper(options) {
    var splitChar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '|';
    return serializedMapper.create({
      options: options,
      splitChar: splitChar
    });
  }
  function buildDoubleSerializedMapper(options) {
    var splitChar1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '|';
    var splitChar2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '#';
    return doubleSerializedMapper.create({
      options: options,
      splitChar1: splitChar1,
      splitChar2: splitChar2
    });
  }
  function buildModelMapper(model, store) {
    return ModelMapper.create({
      model: model,
      store: store
    });
  }
  function buildCSVMapping() {
    return CSVMapping.create({});
  }
  var CSVMapping = _exports.CSVMapping = Ember.Object.extend({
    /*
     * liste des colonnes mappées
     * {
    		"Civilite": {
    			index: 0,
    			from: "Civilite",
    			to: 'civility'
    		},
    		"Salaire désiré min.": {
    			index: 1,
    			from: "Salaire désiré min.",
    			to: 'desiredSalary.min'
    		}
    		"Test champ": {
    			index: 2,
    			from: "Test champ",
    			to: null
    		}
    	}
     */
    columns: null,
    attributes: null,
    mappers: null,
    init: function init() {
      this.reset();
      this.resetMappers();
    },
    reset: function reset() {
      this.set('columns', {});
      this.set('attributes', {});
    },
    resetMappers: function resetMappers() {
      this.set('mappers', {});
    },
    setMappers: function setMappers(attribute, mapper) {
      Ember.set(this.get('mappers'), attribute, mapper);
    },
    setColumnToMapping: function setColumnToMapping(fromCSVName, toModelPath, index) {
      var columns = this.get('columns');

      // si une autre colonne est mappé au meme attribut, on la désactive (une seule colonne peut être mappé à un attribut)
      for (var col in columns) {
        if (columns[col].to === toModelPath) {
          Ember.set(columns[col], 'to', null);
          this.notifyPropertyChange('columns');
          this.updateAttributeMapping(col);
          break;
        }
      }

      // on met à jour la colonne
      columns[fromCSVName] = {
        index: index,
        from: fromCSVName,
        to: toModelPath
      };
      this.updateAttributeMapping(fromCSVName);
      this.notifyPropertyChange('columns');
    },
    getMappedColumns: function getMappedColumns() {
      var attributes = this.get('attributes');
      var mappedColumns = [];
      for (var col in attributes) {
        mappedColumns.push({
          from: col,
          to: attributes[col].key
        });
      }
      return mappedColumns;
    },
    isColumnMapped: function isColumnMapped(col) {
      var attributes = this.get('attributes');
      return typeof attributes[col] !== 'undefined';
    },
    updateAttributeMapping: function updateAttributeMapping(col) {
      var columns = this.get('columns');
      var attributes = this.get('attributes');
      var mappers = this.get('mappers');
      var attributeKey = columns[col].to;

      // si la propriété n'existe pas ou si elle ne correspond plus à la bonne colonne, on reset
      if (!attributes[col] || attributes[col].key !== attributeKey) {
        var mapper = mappers[attributeKey];
        // si on a pas de mapper, on en met un basic en place
        if (!mapper) {
          mapper = buildBasicMapping();
        }
        mapper.reset();
        mapper.set('parent', this);
        mapper.set('column', col);
        if (attributeKey) {
          attributes[col] = {
            key: attributeKey,
            mapper: mapper
          };
        } else {
          delete attributes[col];
        }
      }
      this.notifyPropertyChange('attributes');
    },
    scan: function scan(col, value) {
      var attributes = this.get('attributes');
      if (attributes[col]) {
        var mapper = attributes[col].mapper;
        mapper.scan(value);
      }
    },
    isValid: function isValid(col, value) {
      var attributes = this.get('attributes');
      if (attributes[col]) {
        var mapper = attributes[col].mapper;
        return mapper.isValid(value);
      } else {
        return true;
      }
    },
    encode: function encode(col, value) {
      var attributes = this.get('attributes');
      if (attributes[col]) {
        var mapper = attributes[col].mapper;
        return mapper.encode(value);
      } else {
        return value;
      }
    },
    decode: function decode(col, value) {
      var attributes = this.get('attributes');
      if (attributes[col]) {
        var mapper = attributes[col].mapper;
        return mapper.decode(value);
      } else {
        return value;
      }
    },
    /**
     * liste des attributs non reconnus et a corriger.
     * ex:
     *  [
     *    {
     *      "CSVName": "Civilité",                 // référence à la colonne avec les erreurs
     *      "options": [{"id": 1, "value": "M"}],  // clé pour avoir la liste des bonnes valeurs
     *      "unknownValues": [ "M.", "Mme." ],     // liste des valeurs à corriger
     *      "mapper": BasicMapping                 // référence au mapper pour pouvoir le mettre à jour
     *    }
     *  ]
     * @return {array}
     */
    getUnknownValues: function getUnknownValues() {
      var columns = this.get('columns');
      var mappers = this.get('mappers');
      var list = Ember.A([]);

      // si une autre colonne est mappé au meme attribut, on la désactive (une seule colonne peut être mappé à un attribut)
      for (var col in columns) {
        var toKey = columns[col].to;
        var mapper = toKey && mappers[toKey];
        if (mapper) {
          if (mapper.get('type') === TYPE_DOUBLE_SERIALIZED) {
            // traitement à part des doubleSerialized (il y'a les 2 champs
            for (var i = 0; i < 2; i++) {
              // traitement generique, on accede aux propriété via les indexes (ex: property0) plutot que via les noms (tools)
              var subMapper = mapper.get('property' + i);
              var unknownValues = subMapper.getUnknownValues();
              if (unknownValues.length) {
                list.push({
                  CSVName: col,
                  options: subMapper.get('options'),
                  unknownValues: unknownValues,
                  mapper: subMapper
                });
              }
            }
          } else {
            var _unknownValues = mapper.getUnknownValues();
            if (_unknownValues.length) {
              list.push({
                CSVName: col,
                options: mapper.get('options'),
                unknownValues: _unknownValues,
                mapper: mapper
              });
            }
          }
        }
      }
      return list;
    },
    /**
     * liste des attributs non reconnus et a corriger.
     * ex:
     *  [
     *    {
     *      "CSVName": "Civilité",                 // référence à la colonne avec les erreurs
     *      "options": [{"id": 1, "value": "M"}],  // clé pour avoir la liste des bonnes valeurs
     *      "unknownValues": [ "M.", "Mme." ],     // liste des valeurs à corriger
     *      "mapper": BasicMapping                 // référence au mapper pour pouvoir le mettre à jour
     *    }
     *  ]
     * @return {array}
     */
    unknownValues: Ember.computed('attributes.@each.mapper.unknownValues', function () {
      return this.getUnknownValues();
    })
  });

  /*
   * aplati un objet pour qu'il n'ai qu'un niveau.
   * ex :
   * {
   *   "candidate": {
   *     "firstName": "Prénom"
   *   }
   * }
   *
   * deviendra
   *
   * {
   *   "candidate.firstName": "Prenom"
   * }
   */
  function flattenMapping(list) {
    var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var newList = {};
    for (var key in list) {
      var fullKey = prefix ? prefix + '.' + key : key;
      var elt = list[key];
      if (typeof elt === 'string') {
        newList[fullKey] = elt;
      } else if (_typeof(elt) === 'object') {
        newList = Ember.assign(newList, flattenMapping(elt, fullKey));
      }
    }
    return newList;
  }
  function buildBasicMapping() {
    return BasicMapping.create();
  }
  var _default = _exports.default = {
    buildBasicMapping: buildBasicMapping,
    buildBasicListMapper: buildBasicListMapper,
    buildEnumMapper: buildEnumMapper,
    buildDateMapper: buildDateMapper,
    buildAvailabilityMapper: buildAvailabilityMapper,
    buildSerializedMapper: buildSerializedMapper,
    buildDoubleSerializedMapper: buildDoubleSerializedMapper,
    buildMappingFromI18nObject: buildMappingFromI18nObject,
    buildModelMapper: buildModelMapper,
    buildFloatMapper: buildFloatMapper,
    buildIntegerMapper: buildIntegerMapper,
    flattenMapping: flattenMapping
  };
});