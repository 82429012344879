define("boondmanager/models/absencesperiod", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // "id" is special in Ember-data and it can not be used like an attribute in
    // models and fragments
    //id           : DS.attr('string'),
    originalID: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    duration: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    workUnitType: (0, _attributes.fragment)('workunittype'),
    label: Ember.computed('duration', 'workUnitType.name', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      var _this$getProperties = this.getProperties('duration', 'startDate', 'endDate'),
        duration = _this$getProperties.duration,
        startDate = _this$getProperties.startDate,
        endDate = _this$getProperties.endDate;
      var workUnitTypeName = this.get('workUnitType.name');
      var abbrNbDay = i18n.t('common:dateAndTime.frequency.daily_abbr', {
        count: duration
      });
      var period = "".concat(startDate.format('DD/MM/YY'), " - ").concat(endDate.format('DD/MM/YY'));
      return Ember.String.htmlSafe([workUnitTypeName, abbrNbDay, period].join(' - '));
    }).readOnly()
  });
});