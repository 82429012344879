define("boondmanager/pods/components/bm-field/money/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/logger"], function (_exports, _component, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Composant Input Money
   *
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * Default component __class__ attribut
     *
     * @property classNames
     * @type Array
     * @default  ['bmc-field-money']
     */
    classNames: ['bmc-field-money'],
    classNameBindings: ['showForce:bmc-field-lock', 'isLocked:bmc-field-locked', 'disabled:disabled'],
    /**
     * Default precision
     * @property precision
     * @type int
     * @default 3
     */
    precision: 2,
    minPrecision: true,
    debug: false,
    showToggler: true,
    showForce: false,
    isLocked: Ember.computed('showForce', 'forceValue', 'showToggler', 'disabled', function () {
      return this.get('showForce') && (this.get('disabled') || !this.get('forceValue'));
    }).readOnly(),
    displayToggler: Ember.computed('showToggler', 'forceValue', function () {
      return this.get('showToggler') && this.get('forceValue');
    }).readOnly(),
    /**
     * Change rate
     *
     * @property change
     * @type Number
     * @default 1
     */
    change: Ember.computed({
      get: function get() {
        return 1;
      } /*key*/,
      set: function set(key, value) {
        var ret = value;
        if (ret === 0) {
          _logger.default.warn(this.consoleFormat("SET change (".concat(ret, ")!")));
          ret = 1;
        }
        if (Number.isNaN(Number(ret))) {
          _logger.default.error(this.consoleFormat("SET change (".concat(ret, ") is not a number!")));
          ret = 1;
        }
        return ret;
      }
    }),
    consoleFormat: function consoleFormat(string) {
      return "[bmc-field-money#".concat(this.get('elementId'), " (").concat(this.get('name'), ")] - ").concat(string);
    },
    /**
     * Agency change rate
     *
     * @property agencyChange
     * @type Number
     * @default 1
     */
    agencyChange: Ember.computed({
      get: function get() {
        return 1;
      } /*key*/,
      set: function set(key, value) {
        var ret = value;
        if (ret === 0) {
          _logger.default.log(this.consoleFormat("SET agencyChange (".concat(ret, ")!")));
          ret = 1;
        }
        if (Number.isNaN(Number(ret))) {
          _logger.default.error(this.consoleFormat("SET agencyChange (".concat(ret, ") is not a number!")));
          ret = 1;
        }
        return ret;
      }
    }),
    disabled: false,
    forceValue: true,
    forceFallbackValue: 0,
    minExcluded: null,
    maxExcluded: null,
    /**
     * Displayed Money value in <input>
     * @type {string}
     */
    moneyInput: Ember.computed('value', 'change', 'agencyChange', {
      get: function get() {
        var change = Number(this.get('change'));
        var agencyChange = Number(this.get('agencyChange'));
        var value = this.get('value');
        var moneyInput;
        if (typeof value === 'undefined' || value === null) {
          if (this.get('forceZero')) {
            moneyInput = 0;
          } else {
            moneyInput = undefined;
          }
        } else {
          moneyInput = Number(value);
          if (Number.isNaN(moneyInput)) {
            _logger.default.error(this.consoleFormat("value (".concat(value, ") is not a number!")));
            moneyInput = undefined;
          }
        }
        if (typeof moneyInput === 'number' && change && agencyChange) {
          moneyInput = moneyInput / agencyChange / change;
        }
        return moneyInput;
      },
      set: function set(key, value) {
        this._onChange(value);
        return value;
      }
    }),
    currencySymbolValue: Ember.computed('showCurrency', 'sign', 'currencySymbol', 'i18n._locale', function () {
      if (!this.get('showCurrency')) return '';
      var symbol;
      if (typeof this.get('currencySymbol') !== 'undefined') {
        symbol = this.get('currencySymbol');
      } else {
        var currency = this.get('settings').customer.currency;
        var currentCurrency = currency.findBy('id', this.get('sign'));
        if (typeof currentCurrency !== 'undefined') symbol = currentCurrency.symbol;
      }
      return symbol;
    }).readOnly(),
    showCurrency: Ember.computed('sign', 'currencySymbol', function () {
      return typeof this.get('sign') !== 'undefined' || typeof this.get('currencySymbol') !== 'undefined';
    }).readOnly(),
    _onChange: function _onChange(value) {
      var onChange = this.get('onChange');
      if (typeof onChange === 'function') {
        if (this.get('forceZero') || value !== null && value !== '') {
          var modelValue = Number(value) * this.get('change') * this.get('agencyChange');
          onChange(modelValue);
        } else {
          onChange(null);
        }
      }
    },
    click: function click(event) {
      if (!this.get('bubbles')) {
        event.stopPropagation();
      }
      return true;
    },
    actions: {
      toggleForce: function toggleForce() {
        var forceValue = this.get('forceValue');
        if (forceValue) {
          var onLock = this.get('onLock');
          if (typeof onLock === 'function') {
            onLock(this.get('moneyInput'));
          }
        } else {
          var onUnlock = this.get('onUnlock');
          if (typeof onUnlock === 'function') {
            onUnlock(this.get('moneyInput'));
          }
        }
        var onLockChange = this.get('onLockChange');
        if (typeof onLockChange === 'function') {
          onLockChange(!forceValue, this.get('moneyInput'));
        }
        this.toggleProperty('forceValue');
      }
    }
  });
});