define("boondmanager/models/appsepacustomer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    debitPaymentMethod: _emberData.default.attr('array'),
    transferPaymentMethod: _emberData.default.attr('array'),
    updateInvoiceAfterExtractFileInState: _emberData.default.attr('number'),
    updateCreditNoteAfterExtractFileInState: _emberData.default.attr('number'),
    updatePaymentAfterExtractFileInState: _emberData.default.attr('number'),
    paidExpensesReportAfterExtractFile: _emberData.default.attr('boolean'),
    paymentSalariesDate: _emberData.default.attr(),
    contractsExportLastDate: _emberData.default.attr('moment'),
    invoicesExportLastDate: _emberData.default.attr('moment'),
    creditNotesExportLastDate: _emberData.default.attr('moment'),
    paymentsExportLastDate: _emberData.default.attr('moment'),
    contractsExportLastResource: _emberData.default.belongsTo('resource'),
    invoicesExportLastResource: _emberData.default.belongsTo('resource'),
    creditNotesExportLastResource: _emberData.default.belongsTo('resource'),
    paymentsExportLastResource: _emberData.default.belongsTo('resource'),
    agencies: _emberData.default.attr()
  });
});