define("boondmanager/models/appemailingcustomer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    transactionalEmailsServerLogin: _emberData.default.attr('string'),
    transactionalEmailsServerPassword: _emberData.default.attr('string'),
    transactionalEmailsServerUrl: _emberData.default.attr('string'),
    transactionalEmailsServerPort: _emberData.default.attr('number'),
    transactionalEmailsServerProtocol: _emberData.default.attr('string'),
    transactionalEmailsMandrillApiKey: _emberData.default.attr('string'),
    transactionalEmailsSendingType: _emberData.default.attr('string'),
    signature: _emberData.default.attr('string'),
    plainSignature: _emberData.default.attr('string'),
    transactionalEmailsPushDefaultContactActionTypeOf: _emberData.default.attr(),
    transactionalEmailsPushDefaultResourceActionTypeOf: _emberData.default.attr(),
    transactionalEmailsPushDefaultCandidateActionTypeOf: _emberData.default.attr(),
    transactionalEmailsMessageDefaultContactActionTypeOf: _emberData.default.attr(),
    transactionalEmailsMessageDefaultResourceActionTypeOf: _emberData.default.attr(),
    transactionalEmailsMessageDefaultCandidateActionTypeOf: _emberData.default.attr(),
    transactionalEmailsMessageDefaultInvoiceActionTypeOf: _emberData.default.attr(),
    //nécessaire pour désactiver la configuration par défaut et personnalisé des composants de la vue
    isCustomizedSignature: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    })
  });
});