define("boondmanager/models/alonemarkerfragment", ["exports", "ember-data", "boondmanager/models/markerfragment"], function (_exports, _emberData, _markerfragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _markerfragment.default.extend({
    progressRate: _emberData.default.attr('number', {
      defaultValue: 0
    })
  });
});