define("boondmanager/pods/components/bm-field/schedule/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-schedule'],
    idKeyname: 'originalID',
    showSelect: false,
    required: false,
    // computed sur les 2 ids possibles, car la computed sur 'selected' uniquement ne sert à rien et la computed sur un attribut dynamique risque d'etre source d'erreur
    selectedItem: Ember.computed('selected.{id,originalID}', 'idKeyname', function () {
      return Ember.get(this.get('selected'), this.get('idKeyname'));
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');

      // check onChange
      (false && !(!Ember.isBlank(onChange)) && Ember.assert('bm-field-schedule "onChange" must have a attribute!', !Ember.isBlank(onChange)));
      (false && !(Ember.typeOf(onChange) === 'function') && Ember.assert('bm-field-schedule "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function'));
    },
    actions: {
      toggleSelect: function toggleSelect() {
        if (!this.get('disabled')) {
          this.toggleProperty('showSelect');
          var onUnlock = this.get('onUnlock');
          if (this.get('showSelect') && typeof onUnlock === 'function') {
            onUnlock();
          }
        }
      },
      onItemChange: function onItemChange(selected) {
        this.set('showSelect', true);
        if (this.get('errors') && selected) {
          this.set('errors', []);
        }
        this.get('onChange')(selected);
      }
    }
  });
});