define("boondmanager/pods/components/bm-field/email/multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1S+AB+r9",
    "block": "{\"symbols\":[\"email\"],\"statements\":[[6,[37,2],null,[[\"name\",\"customSearch\",\"multiple\",\"useObjects\",\"selected\",\"disabled\",\"onchange\",\"placeholder\",\"searchMessage\",\"searchPlaceholder\",\"loadingMessage\",\"displayNbSelected\",\"allowClear\",\"maxItems\",\"disableCheckAll\",\"renderInPlace\"],[[32,0,[\"name\"]],[30,[36,1],[[32,0],\"searchEmails\"],null],[32,0,[\"multiple\"]],true,[32,0,[\"selected\"]],[32,0,[\"disabled\"]],[30,[36,1],[[32,0],\"onchange\"],null],[30,[36,0],[\"common:none\"],null],\"\",[30,[36,0],[\"components:bmFieldSearchItems.searchMessage\"],[[\"context\"],[\"emails\"]]],[30,[36,0],[\"components:bmFieldSearchItems.loadingMessage\"],null],[32,0,[\"displayNbSelected\"]],true,[32,0,[\"maxItems\"]],[32,0,[\"disableCheckAll\"]],[32,0,[\"renderInPlace\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]],[1,[30,[36,4],null,[[\"errors\"],[[35,3]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"bm-field/power-select\",\"errors\",\"bm-field/error\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/email/multiple/template.hbs"
  });
});