define("boondmanager/models/appsaaseditordelivery", ["exports", "ember-data", "boondmanager/models/delivery"], function (_exports, _emberData, _delivery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppSaasEditorDelivery = _delivery.default.extend({
    numberOfAccounts: _emberData.default.attr('number'),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'delivery'
  });
  AppSaasEditorDelivery.reopenClass({
    prefix: 'MIS'
  });
  AppSaasEditorDelivery.reopen({
    prefix: Ember.computed(function () {
      return AppSaasEditorDelivery.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppSaasEditorDelivery;
});