define("boondmanager/models/projectfragment", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/models/project"], function (_exports, _emberData, _emberDataModelFragments, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    entityName: Ember.computed('reference', 'originalID', function () {
      if (this.get('reference')) return this.get('reference');
      return this.get('originalID') ? "".concat(_project.default.prefix).concat(this.get('originalID')) : '';
    })
  });
});