define("boondmanager/models/apphouraccountshouraccount", ["exports", "ember-data", "ember-data-model-fragments/attributes", "boondmanager/models/apphouraccountshouraccountfragment"], function (_exports, _emberData, _attributes, _apphouraccountshouraccountfragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WARNING_REST_WEEK = _exports.WARNING_REST_MINUTES = _exports.WARNING_REST_DAY = _exports.WARNING_PARTIAL_CONTRACT_DAY = _exports.WARNING_OUT_OF_CONTRACT = _exports.WARNING_HALF_DAY_OVERLOADED = _exports.WARNING_EMPTY_CONTRACT_DAY = _exports.WARNING_DAY_INCONSISTENCY = _exports.WARNING_ABSENCE_HALF_DAY_EMPTY = _exports.WARNING_ABSENCE_DAY_OVERLOADED = _exports.HOURACCOUNT_TYPE_TIMESREPORT = _exports.DEFAULT_MORNING_START_HOUR = _exports.DEFAULT_MORNING_END_HOUR = _exports.DEFAULT_AFTERNOON_START_HOUR = _exports.DEFAULT_AFTERNOON_END_HOUR = void 0;
  var HOURACCOUNT_TYPE_TIMESREPORT = _exports.HOURACCOUNT_TYPE_TIMESREPORT = 'timesreport';

  /**
   * Constantes des warnings
   * @type {string}
   */
  var WARNING_HALF_DAY_OVERLOADED = _exports.WARNING_HALF_DAY_OVERLOADED = 'absenceWarningHalfDay';
  var WARNING_ABSENCE_HALF_DAY_EMPTY = _exports.WARNING_ABSENCE_HALF_DAY_EMPTY = 'absenceWarningEmpty';
  var WARNING_ABSENCE_DAY_OVERLOADED = _exports.WARNING_ABSENCE_DAY_OVERLOADED = 'absenceWarningDay';
  var WARNING_DAY_INCONSISTENCY = _exports.WARNING_DAY_INCONSISTENCY = 'warningDayInconsistency';
  var WARNING_OUT_OF_CONTRACT = _exports.WARNING_OUT_OF_CONTRACT = 'warningOutOfContract';
  var WARNING_PARTIAL_CONTRACT_DAY = _exports.WARNING_PARTIAL_CONTRACT_DAY = 'warningPartialContractDay';
  var WARNING_EMPTY_CONTRACT_DAY = _exports.WARNING_EMPTY_CONTRACT_DAY = 'warningEmptyContractDay';
  var WARNING_REST_DAY = _exports.WARNING_REST_DAY = 'warningRestDay';
  var WARNING_REST_WEEK = _exports.WARNING_REST_WEEK = 'warningRestWeek';
  var WARNING_REST_MINUTES = _exports.WARNING_REST_MINUTES = 'warningRestMinutes';

  /**
   * Constantes des heures par défaut en fonction du moment de la journée
   * @type {string}
   */
  var DEFAULT_MORNING_START_HOUR = _exports.DEFAULT_MORNING_START_HOUR = '09:00';
  var DEFAULT_MORNING_END_HOUR = _exports.DEFAULT_MORNING_END_HOUR = '12:00';
  var DEFAULT_AFTERNOON_START_HOUR = _exports.DEFAULT_AFTERNOON_START_HOUR = '14:00';
  var DEFAULT_AFTERNOON_END_HOUR = _exports.DEFAULT_AFTERNOON_END_HOUR = '18:00';

  /**
   * Fake HourAccount object
   *
   * Cet objet va permettre de créer des entrées dans le tableau des décomptes horaires
   * pour des lignes qui n'ont pas de valeurs, ainsi l'objet timesreport ne sera pas dirty
   * mais pour autant le template fonctionnera quand même avec les valeurs et computed du fragment d'origine
   *
   * @class FakeHour
   * @namespace BoondManager.AppHourAccountsHourAccount
   * @extends Ember.Object
   * @private
   */
  var FakeHour = Ember.Object.extend({
    /**
     * Date
     *
     * @property date
     * @type {Moment}
     */

    formatedDate: Ember.computed('date', function () {
      return this.get('date').format('YYYY-MM-DD');
    }).readOnly(),
    /**
     * Comment
     *
     * @property comment
     * @type {string}
     * @default  ''
     */
    comment: Ember.computed('', {
      get: function get() {
        return '';
      },
      set: function set(_, value) {
        return value;
      }
    }),
    /**
     * Disabled
     *
     * @property disabled
     * @type {boolean}
     * @default  false
     */
    disabled: Ember.computed('', {
      get: function get() {
        return false;
      },
      set: function set(_, value) {
        return value;
      }
    }),
    /**
     * Warning code
     *
     * @property warning
     * @type {boolean}
     * @default ''
     */
    warning: Ember.computed('', {
      get: function get() {
        return false;
      },
      set: function set(_, value) {
        return value;
      }
    }),
    /**
     * Total day
     *
     * @property totalDay
     * @type {string}
     * @default  ''
     */
    totalDay: Ember.computed('date', 'morningStartHour', 'morningEndHour', 'afternoonStartHour', 'afternoonEndHour', function () {
      return (0, _apphouraccountshouraccountfragment.computeTotalDay)(this, 'h');
    }).readOnly(),
    /**
     * Break
     *
     * @property break
     * @type {string}
     * @default  ''
     */
    break: Ember.computed('date', 'morningEndHour', 'afternoonStartHour', function () {
      return (0, _apphouraccountshouraccountfragment.computeBreak)(this, 'h');
    }).readOnly(),
    breakMinutes: Ember.computed('date', 'morningEndHour', 'afternoonStartHour', function () {
      return (0, _apphouraccountshouraccountfragment.computeBreakMinutes)(this);
    }).readOnly()
  });
  var _default = _exports.default = _emberData.default.Model.extend({
    originalID: _emberData.default.attr('string'),
    term: _emberData.default.attr('date'),
    validated: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    type: _emberData.default.attr('string', {
      defaultValue: HOURACCOUNT_TYPE_TIMESREPORT
    }),
    restBetweenDays: _emberData.default.attr('number'),
    restForWeek: _emberData.default.attr('number'),
    restMinutes: _emberData.default.attr('number'),
    restHours: _emberData.default.attr('number'),
    /** RELATIONSHIPS */
    resource: _emberData.default.belongsTo('apphouraccountsresource', {
      inverse: null
    }),
    /**
     * dependsOn
     * @type {TimesReport}
     */
    dependsOn: _emberData.default.belongsTo('base'),
    /** FRAGMENTS */
    hours: (0, _attributes.fragmentArray)('apphouraccountshouraccountfragment'),
    validatedValue: Ember.computed('validated', function () {
      return this.get('validated') ? 'validated' : 'waiting';
    }).readOnly(),
    /**
     * Création et récupération d'une fausse ligne de décompte horaire
     * @param date
     * @return {FakeHour}
     */
    getFakeHour: function getFakeHour(date) {
      return FakeHour.create({
        date: date.clone()
      });
    },
    /**
     * Mise à jour d'un décompte horaire.
     * Création de celui-ci s'il n'existe pas ou suppression de celui-ci si toutes les valeurs sont vides.
     * @param account
     * @param field
     * @param value
     * @return {Fragment}
     */
    updateHour: function updateHour(account, field, value) {
      var hourAccount = this.findHour(account.date) || this.createHour(account.date);
      switch (field) {
        case 'morningStartHour':
        case 'morningEndHour':
        case 'afternoonStartHour':
        case 'afternoonEndHour':
          if (value) {
            hourAccount.set(field, value.format('HH:mm'));
          } else {
            hourAccount.set(field, null);
          }
          break;
        default:
          hourAccount.set(field, value);
          break;
      }

      // si le décomptes est vide (horaires nulles), alors on le supprime du modèle
      if (!hourAccount.get('morningStartHour') && !hourAccount.get('morningEndHour') && !hourAccount.get('afternoonStartHour') && !hourAccount.get('afternoonEndHour') && !hourAccount.get('comment')) {
        this.get('hours').removeFragment(hourAccount);
        return this.getFakeHour(account.date);
      }
      return hourAccount;
    },
    /**
     * Récupération d'un décompte horaire via sa date
     * @param date
     * @return {MF.Fragment|undefined}
     */
    findHour: function findHour(date) {
      return this.get('hours').findBy('formatedDate', date.format('YYYY-MM-DD'));
    },
    /**
     * Création d'un décompte horaire avec date
     * @param date
     * @return {MF.Fragment}
     */
    createHour: function createHour(date) {
      return this.get('hours').createFragment({
        date: date
      });
    }
  });
});