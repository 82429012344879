define("boondmanager/authenticators/boondmanager", ["exports", "jquery", "boondmanager/utils/logger", "ember-simple-auth/authenticators/base", "boondmanager/config/environment"], function (_exports, _jquery, _logger, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    storage: Ember.inject.service('storage'),
    tokenEndpoint: _environment.default.apiURL + '/api/application',
    restore: function restore(data) {
      var _this = this;
      _logger.default.debug("".concat(this.get('_debugContainerKey'), " - restore"), data, this.tokenEndpoint);
      var ret;
      if (typeof data.meta.csrf === 'undefined' || data.meta.csrf === '') {
        _logger.default.log("".concat(this.get('_debugContainerKey'), " - restore - missing or bad data.csrf"));
      } else {
        ret = new Ember.RSVP.Promise(function (resolve, reject) {
          // get status
          _jquery.default.ajax({
            url: _this.tokenEndpoint + '/status',
            type: 'GET',
            headers: {
              'x-csrf-boondmanager': data.meta.csrf,
              'x-front-version': _environment.default.version,
              'x-front-boondmanager': 'ember'
            },
            xhrFields: {
              withCredentials: true
            },
            dataType: 'json'
          }).then(function (response) {
            Ember.run(function () {
              if (response.meta && response.meta.isLogged) {
                _logger.default.debug('DONE - SUCCESS (isLogged):', response);
                resolve(data);
              } else {
                _logger.default.debug('DONE - REJECT:', response);
                reject(response);
              }
            });
          }, function (xhr, status, error) {
            Ember.run(function () {
              _logger.default.debug('FAIL - REJECT:', xhr, status, error);
              reject(xhr.responseText);
            });
          });
        }, "".concat(this.get('_debugContainerKey'), " - restore - GET status"));
      }
      return ret;
    },
    authenticate: function authenticate(credentials) {
      var _this2 = this;
      var device = this.get('storage').get('deviceSHA');
      if (!device) {
        var oldDevicesListJSON = this.get('storage').get('devices');
        var oldDevicesList = oldDevicesListJSON ? JSON.parse(oldDevicesListJSON) : {};
        var opn = Object.keys(oldDevicesList);
        if (opn.length > 0) device = oldDevicesList[opn[0]];
      }
      if (device) {
        credentials.device = device;
      }
      _logger.default.debug("".concat(this.get('_debugContainerKey'), " - authenticate"), credentials, this.tokenEndpoint);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: _this2.tokenEndpoint,
          type: 'POST',
          data: credentials,
          headers: {
            'x-front-version': _environment.default.version,
            'x-front-boondmanager': 'ember'
          },
          xhrFields: {
            withCredentials: true
          },
          dataType: 'json'
        }).then(function (response) {
          Ember.run(function () {
            if (response.meta && response.meta.isLogged && response.meta.csrf) {
              _logger.default.debug('DONE - SUCCESS:', response);
              if (response.data.device) {
                _this2.get('storage').set('deviceSHA', response.data.device.sha);
                // on néttoie les anciennes données
                _this2.get('storage').clear('devices');
              }
              resolve(response);
            } else {
              _logger.default.debug('DONE - REJECT:', response);
              reject(response);
            }
          });
        }, function (xhr, status, error) {
          Ember.run(function () {
            _logger.default.debug('FAIL - REJECT:', xhr, status, error);
            reject(JSON.parse(xhr.responseText));
          });
        });
      }, "".concat(this.get('_debugContainerKey'), " - authenticate"));
    },
    invalidate: function invalidate(authenticator) {
      var deleteSession = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      _logger.default.debug("".concat(this.get('_debugContainerKey'), " - invalidate"), this.tokenEndpoint);
      if (!deleteSession) {
        return Ember.RSVP.Promise.resolve(true);
      }
      return _jquery.default.ajax({
        url: this.tokenEndpoint,
        type: 'DELETE',
        headers: {
          'x-front-version': _environment.default.version,
          'x-front-boondmanager': 'ember'
        },
        xhrFields: {
          withCredentials: true
        },
        dataType: 'json'
      });
    }
  });
});