define("boondmanager/models/workplacesdefaultweek", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    settingsService: Ember.inject.service('settings'),
    days: (0, _attributes.fragmentArray)('workplacesdefaultweekday'),
    workplaceType: (0, _attributes.fragment)('workplacetype'),
    /**
     * Cette computed est en place uniquement pour forcer le rafraichissement dans la modale de la valeur sélectionnée
     * dans le power-select. Sans cette computed, la vue n'est pas informée du changement de valeur et ne se met pas à jour
     */
    workplaceTypeValue: Ember.computed('workplaceType.reference', function () {
      return this.get('workplaceType');
    }).readOnly(),
    orderedDays: Ember.computed('days', function () {
      var _this = this;
      var weekDays = this.get('settingsService').bm.timesreport.workplaceSettingsModal.days;
      var allDays = this.get('days');
      var days = Ember.A([]);
      weekDays.forEach(function (weekDay) {
        var currentDay = allDays.findBy('dayOfWeek', weekDay.id);
        if (currentDay) {
          days.pushObject(currentDay);
        } else {
          var _currentDay = _this.get('completeWeek').findBy('dayOfWeek', weekDay.id);
          var newDay = allDays.createFragment({
            dayOfWeek: weekDay.id,
            duration: '',
            typeOf: _currentDay.typeOf
          });
          days.pushObject(newDay);
        }
      });
      return days;
    }).readOnly(),
    fillState: Ember.computed({
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    completeWeek: Ember.computed({
      get: function get() {
        return Ember.A([]);
      },
      set: function set(key, value) {
        return value;
      }
    })
  });
});