define("boondmanager/models/scheduleallowedaccounts", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    level: _emberData.default.attr('number', {
      defaultValue: 10
    }),
    amountExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    priceExcludingTaxPerAllowedAccount: Ember.computed('level', 'amountExcludingTax', function () {
      return this.get('level') ? this.get('amountExcludingTax') / this.get('level') : 0;
    })
  });
});