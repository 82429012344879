define("boondmanager/models/apphouraccountscustomer", ["exports", "ember-data", "ember-data-model-fragments/attributes", "boondmanager/models/apphouraccountsresource"], function (_exports, _emberData, _attributes, _apphouraccountsresource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    restBetweenDays: _emberData.default.attr('number'),
    restForWeek: _emberData.default.attr('number'),
    restMinutes: _emberData.default.attr('number'),
    restHours: _emberData.default.attr('number'),
    contractTypes: _emberData.default.attr(),
    daySettings: (0, _attributes.fragmentArray)('apphouraccountsdaysettingsfragment'),
    /**
     * Création et récupération d'une fausse ligne de configuration
     * @param day
     * @return {FakeSetting}
     */
    getFakeDaySetting: function getFakeDaySetting(day) {
      return _apphouraccountsresource.FakeSetting.create({
        day: day
      });
    },
    /**
     * Mise à jour d'un décompte horaire.
     * Création de celui-ci s'il n'existe pas ou suppression de celui-ci si toutes les valeurs sont vides.
     * @param day
     * @param field
     * @param value
     */
    updateDaySetting: function updateDaySetting(day, field, value) {
      var daySetting = this.findDaySetting(day.day) || this.createDaySetting(day.day);
      if (value) daySetting.set(field, value.format('HH:mm'));else daySetting.set(field, null);
      if (!daySetting.get('morningStartHour') && !daySetting.get('morningEndHour') && !daySetting.get('afternoonStartHour') && !daySetting.get('afternoonEndHour')) {
        this.get('daySettings').removeFragment(daySetting);
        return this.getFakeDaySetting(day.day);
      }
      return daySetting;
    },
    /**
     * Création d'une configuration horaire pour un jour donné
     * @param day
     * @return {MF.Fragment}
     */
    createDaySetting: function createDaySetting(day) {
      return this.get('daySettings').createFragment({
        day: day
      });
    },
    findDaySetting: function findDaySetting(day) {
      return this.get('daySettings').findBy('day', day);
    }
  });
});