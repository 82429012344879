define("boondmanager/pods/components/bm-field/perimeter/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/cachecomputed", "boondmanager/utils/logger", "boondmanager/utils/string", "ember-inflector"], function (_exports, _component, _cachecomputed, _logger, _string, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildPerimeterValue = buildPerimeterValue;
  _exports.perimeterValueSeparator = _exports.defaultPerimeterTypes = _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

  /**
   * Default perimeter types (plural strings)
   *
   *  * 'agencies'
   *  * 'businessUnits'
   *  * 'managers'
   *  * 'poles'
   *
   * @type {String[]}
   */
  var defaultPerimeterTypes = _exports.defaultPerimeterTypes = ['agencies', 'businessUnits', 'poles', 'managers'];
  var perimeterValueSeparator = _exports.perimeterValueSeparator = '_';
  function buildPerimeterValue(id, type) {
    return "".concat(type).concat(perimeterValueSeparator).concat(id);
  }

  /**
   * BM field Perimeter component
   *
   * Component attributes:
   *
   *   * {string}             name          REQUIRED field name
   *   * {string}             module        REQUIRED perimeter's module name (plural form ie. 'candidates', 'resources', etc)
   *   * {string}             class         OPTIONAL additional classes applied to the main DOM element
   *   * {boolean}            disabled      OPTIONAL component state
   *   * {string}             size          OPTIONAL component Bootstrap size
   *   * {Array|string}       selected      OPTIONAL selected
   *   * {function}           onChange      OPTIONAL callback call on <option> selection
   *
   * Example:
   *
   * ```handlebars
   * <div>
   *   <label class="control-label">{{t 'components:bmFieldPerimeter.perimeter'}}</label>
   *       {{bm-field/perimeter
   *           name="perimeter"
   *           class="col-8"
   *           size=size
   *           multiple=false
   *           module="candidates"
   *           selected=selectedPerimeters
   *           placeholder=(t 'common:indifferent')
   *           onChange=(action 'updatePerimeterFilter')
   *           debug=true
   *       }}
   * </div>
   * ```
   *
   * @class  BmFieldPerimeterComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-perimeter'],
    attributeBindings: ['dataName:data-name'],
    rights: Ember.inject.service('rights'),
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    debug: false,
    log: function log() {
      if (this.get('debug')) {
        _logger.default.debug.apply(_logger.default, ["[".concat(this.get('name'), "]")].concat(Array.prototype.slice.call(arguments)));
      }
    },
    /**
     * Service perimeters
     *
     * @property perimeters
     * @type {Ember.Service}
     */
    perimeters: Ember.inject.service(),
    /**
     * Perimeter types
     *
     * @property perimeterTypes
     * @type {String[]}
     * @default defaultPerimeterTypes
     */
    perimeterTypes: Ember.computed({
      get: function get() {
        return defaultPerimeterTypes;
      },
      set: function set(key, value) {
        var newValue;
        if (Ember.isArray(value)) {
          newValue = value;
        } else if (Ember.typeOf(value) === 'string') {
          newValue = value.split(/(?:\s+)|(?:\s*,\s*)/);
        } else {
          newValue = defaultPerimeterTypes;
        }
        return newValue;
      }
    }),
    /**
     *
     * @property perimeter
     * @type {Promise}
     */
    perimeter: Ember.computed('perimeters', 'module', 'rights.globalLastUpdate', function () {
      var moduleName = Ember.String.camelize(this.get('module'));
      return this.get('perimeters').getModule(moduleName);
    }),
    /**
     * Selected perimeters
     *
     * If multiple, selected perimeters is an array of strings (ie. perimeters ID).
     * Else it's a string.
     *
     * @property selectedPerimeters
     * @type {String|string[]}
     */
    selectedPerimeters: Ember.computed('options', 'selected', 'multiple', function () {
      var _this = this;
      var selected = this.get('selected');
      if (!selected) {
        return undefined;
      }
      selected = Ember.RSVP.Promise.resolve(selected);
      return selected.then(function (selected) {
        return _this.get('options').then(function (optionsList) {
          var perimeterTypes = _this.get('perimeterTypes');
          var options = [];
          if (perimeterTypes.length > 1) {
            var _loop = function _loop() {
              var perimeterType = optionsList[i].id;
              optionsList[i].options.forEach(function (item) {
                return options[buildPerimeterValue(item.get('id'), perimeterType)] = item;
              });
            };
            for (var i = 0; i < optionsList.length; i++) {
              _loop();
            }
          } else {
            var perimeterType = perimeterTypes[0];
            optionsList.forEach(function (item) {
              return options[buildPerimeterValue(Ember.get(item, 'id'), perimeterType)] = item;
            });
          }
          var selectedPerimeters = [];
          if (_this.get('multiple')) {
            if (Ember.isArray(selected)) {
              selectedPerimeters = selected.map(function (selectedValue) {
                if (typeof options[selectedValue] !== 'undefined') {
                  return options[selectedValue];
                }
              });
            } else if (_typeof(selected) === 'object') {
              defaultPerimeterTypes.forEach(function (perimeterType) {
                selected[perimeterType].forEach(function (perimeter) {
                  selectedPerimeters.push(buildPerimeterValue(perimeter.id, perimeterType));
                });
              });
            } else if (typeof selected === 'string' && typeof options[selected] !== 'undefined') {
              selectedPerimeters.push(options[selected]);
            }

            //removes undefined values from perimeters
            return selectedPerimeters.filter(function (e) {
              return e;
            });
          } else {
            // if it's an array, take first value
            if (Ember.isArray(selected) && selected.length && typeof options[selected[0]] !== 'undefined') {
              selectedPerimeters = options[selected[0]];
            } else if (typeof selected === 'string' && typeof options[selected] !== 'undefined') {
              selectedPerimeters = options[selected];
            }
            return selectedPerimeters;
          }
        });
      });
    }),
    /**
     * Options Hash Map
     *
     * (defined in init())
     *
     * {
     *   optionPerimeterType + '_' + option.get('id') : option,
     *   ...
     * }
     *
     * @type {Object}
     * @private
     */

    options: Ember.computed('perimeter', function () {
      var _this2 = this;
      this.log('options CP');
      var promise = this.get('perimeter').then(function (perimeter) {
        var i18n = _this2.get('i18n');
        var perimeterTypes = _this2.get('perimeterTypes');
        var output = [];
        if (!_this2.get('multiple') && _this2.get('allowEmpty')) {
          output.push({
            id: '',
            name: i18n.t('common:indifferent')
          });
        }
        perimeterTypes.forEach(function (perimeterType) {
          var items = perimeter[perimeterType];
          if (items && items.length) {
            //On filtre les agences et les poles archivés
            var filteredItems = items.filter(function (item) {
              if (item.get('modelName') === 'agency' || item.get('modelName') === 'pole') {
                return item.get('state');
              }
              return true;
            });
            if (!filteredItems || !filteredItems.length) {
              return false;
            }
            var groupName;
            switch (perimeterType) {
              case 'managers':
                groupName = i18n.t('components:bmFieldPerimeters.managers');
                break;
              case 'poles':
                groupName = i18n.t('components:bmFieldPerimeters.poles');
                break;
              case 'businessUnits':
                groupName = i18n.t('components:bmFieldPerimeters.businessUnits');
                break;
              case 'agencies':
                groupName = i18n.t('components:bmFieldPerimeters.agencies');
                break;
            }
            if (perimeterTypes.length > 1) {
              output.push({
                id: perimeterType,
                groupName: groupName,
                options: Ember.A(filteredItems).toArray()
              });
            } else {
              output = output.concat(filteredItems);
            }
            filteredItems.forEach(function (item) {
              _this2.get('_optionsHashMap')[buildPerimeterValue(item.get('id'), perimeterType)] = item;
            });
          }
        });
        return output;
      });
      return ArrProxy.create({
        promise: promise
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this._optionsHashMap = {};
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      var module = this.get('module');
      var onChange = this.get('onChange');
      this.log("didReceiveAttrs hook - (name, module) = (".concat(name, ", ").concat(module, ")"));

      // check name & module
      (false && !(!Ember.isBlank(name)) && Ember.assert('bm-field-perimeter must have a "name" attribute!', !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert('bm-field-perimeter "name" attribute must be a string!', Ember.typeOf(name) === 'string'));
      (false && !(!Ember.isBlank(module)) && Ember.assert('bm-field-perimeter must have a "module" attribute!', !Ember.isBlank(module)));
      (false && !(Ember.typeOf(module) === 'string') && Ember.assert('bm-field-perimeter "module" attribute must be a string!', Ember.typeOf(module) === 'string'));
      (false && !(!Ember.isBlank(onChange)) && Ember.assert('bm-field-perimeter "onChange" must have a attribute!', !Ember.isBlank(onChange)));
      (false && !(Ember.typeOf(onChange) === 'function') && Ember.assert('bm-field-perimeter "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function'));
    },
    perimeterMatcher: function perimeterMatcher(item, term) {
      // Posibilité d'évolution : faire une recherche par regex
      if (item.id) {
        var search = (0, _string.removeDiacritics)(term.toLowerCase());
        var value;
        if (item.get('firstName') || item.get('lastName')) {
          value = (0, _string.removeDiacritics)("".concat(item.get('firstName'), " ").concat(item.get('lastName'))).toLowerCase();
        } else {
          value = (0, _string.removeDiacritics)(item.get('name')).toLowerCase();
        }
        return value.indexOf(search);
      }
    },
    hasValue: (0, _cachecomputed.default)('selected', function () {
      return !this.isBlankValue();
    }),
    isBlankValue: function isBlankValue() {
      return typeof this.get('selected') === 'undefined' || Array.isArray(this.get('selected')) && this.get('selected').length === 0;
    },
    actions: {
      onChangePerimeter: function onChangePerimeter(value) {
        this.log('onChangePerimeter action - value =', value);
        var bubbleValue;
        if (this.get('multiple')) {
          bubbleValue = [];
          value.forEach(function (item) {
            if (item) {
              if (typeof item === 'string') {
                bubbleValue.push(item);
              } else {
                /*
                	`modelName` is a DS.Model static property. So we can get it
                	from item's model class, ie. `Agency`, `Resource`, etc
                	item.constructor = item's model class :)
                 */
                var type;
                switch (item.constructor.modelName) {
                  case 'resource':
                    type = 'managers';
                    break;
                  case 'businessunit':
                    type = 'businessUnits';
                    break;
                  default:
                    type = (0, _emberInflector.pluralize)(item.constructor.modelName);
                }
                bubbleValue.push(buildPerimeterValue(item.get('id'), type));
              }
            }
          });
          this.log('onChangePerimeter action - bubbleValue = ', bubbleValue);
          bubbleValue = Ember.A(bubbleValue);
        } else {
          var type = value.constructor.modelName === 'resource' ? 'managers' : (0, _emberInflector.pluralize)(value.constructor.modelName);
          bubbleValue = buildPerimeterValue(Ember.get(value, 'id'), type);
        }
        this.get('onChange')(bubbleValue);
      }
    }
  });
});