define("boondmanager/models/assignment", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    agencies: _emberData.default.hasMany('agency'),
    otherAgencies: _emberData.default.hasMany('agency'),
    hrManagers: _emberData.default.hasMany('resource'),
    influencers: _emberData.default.hasMany('resource'),
    mainManagers: _emberData.default.hasMany('resource'),
    expectedValidators: _emberData.default.hasMany('resource'),
    poles: _emberData.default.hasMany('pole'),
    otherPoles: _emberData.default.hasMany('pole'),
    roles: _emberData.default.hasMany('role')
  });
});