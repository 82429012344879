define("boondmanager/models/purchase", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _emberDataCopyable, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PURCHASE_MISCELLANEOUS = _exports.PURCHASE_EXTERNAL_DELIVERY = _exports.PAYMENT_PLANNED = _exports.PAYMENT_CONFIRMED = _exports.AUTO_CREATE_PAYMENTS_AS_PLANNED = _exports.AUTO_CREATE_PAYMENTS_AS_CONFIRMED = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var AUTO_CREATE_PAYMENTS_AS_PLANNED = _exports.AUTO_CREATE_PAYMENTS_AS_PLANNED = 0;
  var AUTO_CREATE_PAYMENTS_AS_CONFIRMED = _exports.AUTO_CREATE_PAYMENTS_AS_CONFIRMED = 1;
  var PAYMENT_PLANNED = _exports.PAYMENT_PLANNED = 9;
  var PAYMENT_CONFIRMED = _exports.PAYMENT_CONFIRMED = 1;
  var PURCHASE_MISCELLANEOUS = _exports.PURCHASE_MISCELLANEOUS = 0;
  var PURCHASE_EXTERNAL_DELIVERY = _exports.PURCHASE_EXTERNAL_DELIVERY = 1;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var Purchase = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'billingDetail', 'company', 'contact', 'delivery', 'mainManager', 'pole', 'project']
    }),
    amountExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // TAB_ACHAT.ACHAT_MONTANTHT
    amountIncludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    createPayments: _emberData.default.attr('number'),
    currency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_DEVISEAGENCE
    date: _emberData.default.attr('date'),
    // TAB_ACHAT.ACHAT_DATE
    deltaExcludingTax: _emberData.default.attr('number'),
    deltaIncludingTax: _emberData.default.attr('number'),
    endDate: _emberData.default.attr('date'),
    // TAB_ACHAT.ACHAT_FIN
    engagedPaymentsAmountExcludingTax: _emberData.default.attr('number'),
    engagedPaymentsAmountIncludingTax: _emberData.default.attr('number'),
    exchangeRate: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_CHANGEAGENCE
    informationComments: _emberData.default.attr('string'),
    // TAB_ACHAT.ACHAT_COMMENTAIRE
    number: _emberData.default.attr('string'),
    // TAB_ACHAT.ACHAT_REFFOURNISSEUR
    numberOfCorrelatedOrders: _emberData.default.attr('number'),
    paymentMethod: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_TYPEPAYMENT
    paymentTerm: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_CONDREGLEMENT
    quantity: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_QUANTITE
    reference: _emberData.default.attr('string'),
    // TAB_ACHAT.ACHAT_REF
    reinvoiceRate: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_TAUXREFACTURATION
    reinvoiceAmountExcludingTax: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_TARIFFACTURE
    showInformationCommentsOnPDF: _emberData.default.attr('boolean'),
    // TAB_ACHAT.ACHAT_SHOWCOMMENTAIRE
    startDate: _emberData.default.attr('date'),
    // TAB_ACHAT.ACHAT_DEBUT
    state: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_ETAT
    statePayments: _emberData.default.attr(),
    subscription: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_TYPE
    taxRate: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH, 'AT_TAUXTVA
    taxRates: _emberData.default.attr(),
    title: _emberData.default.attr('string'),
    // TAB_ACHAT.ACHAT_TITLE
    toReinvoice: _emberData.default.attr('boolean'),
    // TAB_ACHAT.ACHAT_REFACTURE
    totalAmountExcludingTax: _emberData.default.attr('number'),
    totalAmountIncludingTax: _emberData.default.attr('number'),
    totalPaymentsAmountExcludingTax: _emberData.default.attr('number'),
    totalPaymentsAmountIncludingTax: _emberData.default.attr('number'),
    typeOf: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_CATEGORY
    creationDate: _emberData.default.attr('moment'),
    // TAB_ACHAT.ACHAT_CREATEDAT,
    updateDate: _emberData.default.attr('moment'),
    //right
    canReadPurchase: _emberData.default.attr('boolean'),
    canWritePurchase: _emberData.default.attr('boolean'),
    // Droit d'écriture ( par procuration de suppresion)

    agency: _emberData.default.belongsTo(),
    billingDetail: _emberData.default.belongsTo('detail'),
    company: _emberData.default.belongsTo(),
    contact: _emberData.default.belongsTo(),
    delivery: _emberData.default.belongsTo('delivery', {
      inverse: 'purchase'
    }),
    mainManager: _emberData.default.belongsTo('resource'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    pole: _emberData.default.belongsTo(),
    project: _emberData.default.belongsTo({
      inverse: 'purchases'
    }),
    additionalTurnoverAndCosts: (0, _attributes.fragment)('purchaseatcfragment'),
    files: _emberData.default.hasMany('document'),
    subscriptionType: Ember.computed('subscription', 'i18n._locale', function () {
      var typeID = this.get('subscription');

      // in case of changing language in another tab, dictionnary is not fully loaded so
      // we need to test if type of variable is an object
      var subscriptionType = this.get('settingsService').customer.typeOf.subscription;
      if (_typeof(subscriptionType) === 'object') {
        subscriptionType = subscriptionType.find(function (item) {
          return item.id === typeID;
        });
      } else {
        subscriptionType = null;
      }
      return subscriptionType ? subscriptionType : {
        id: typeID,
        value: this.get('i18n').t('common:unknown.name')
      };
    }),
    isUnitary: Ember.computed('subscription', function () {
      return this.get('subscription') === 0;
    }),
    purchaseType: Ember.computed('typeOf', 'i18n._locale', function () {
      var typeID = this.get('typeOf');

      // in case of changing language in another tab, dictionnary is not fully loaded so
      // we need to test if type of variable is an object
      var purchaseType = this.get('settingsService').customer.typeOf.purchase;
      if (_typeof(purchaseType) === 'object') {
        purchaseType = purchaseType.find(function (item) {
          return item.id === typeID;
        });
      } else {
        purchaseType = null;
      }
      return purchaseType ? purchaseType : {
        id: typeID,
        value: this.get('i18n').t('common:unknown.name')
      };
    }),
    subscriptionPeriods: Ember.computed('subscription', 'startDate', 'endDate', function () {
      var subscription = this.get('subscription');
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var monthes = 0;
      if (endDate && startDate && endDate.isAfter(startDate)) {
        monthes = endDate.month() - startDate.month() + 1 + 12 * (endDate.year() - startDate.year());
      }
      switch (subscription) {
        case 0:
          {
            // Unitaire
            return 0;
          }
        case 1:
          {
            // Mensuel
            return monthes;
          }
        case 2:
          {
            // Trimestriel
            return Math.ceil(monthes / 3);
          }
        case 3:
          {
            // Semestriel
            return Math.ceil(monthes / 6);
          }
        case 4:
          {
            // Annuel
            return Math.ceil(monthes / 12);
          }
      }
    }),
    entityName: Ember.computed('title', function () {
      var title = this.get('title');
      if (Ember.isBlank(title)) {
        return this.get('internalReference');
      } else {
        return title;
      }
    }),
    provider: Ember.computed('contact', 'company', {
      get: function get() {
        var providerPromises = {
          contact: this.get('contact'),
          company: this.get('company')
        };
        var promise = new Ember.RSVP.hash(providerPromises, "get provider contact and company").then(function (_ref) {
          var contact = _ref.contact,
            company = _ref.company;
          return {
            id: contact ? contact.get('id') : null,
            entityName: contact ? contact.get('entityName') : null,
            // for bm-field-crm. CAUTION: contact could be null
            completeName: contact ? contact.get('completeName') : null,
            // for bm-client. CAUTION: contact could be null
            company: company
          };
        });
        return ObjProxy.create({
          promise: promise
        });
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isTaxRatesMultiples: Ember.computed('taxRates', 'agency.purchasesAllowMultiplesTaxRates', function () {
      return this.get('taxRates').length > 1 || this.get('agency.purchasesAllowMultiplesTaxRates');
    }).readOnly(),
    taxRatesSelected: Ember.computed('isTaxRatesMultiples', 'taxRates', function () {
      if (this.get('isTaxRatesMultiples')) return this.get('taxRates');else return this.get('taxRates.0');
    })
  });
  Purchase.reopenClass({
    prefix: 'ACH'
  });
  Purchase.reopen({
    prefix: Ember.computed(function () {
      return Purchase.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Purchase;
});