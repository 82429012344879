define("boondmanager/controllers/account-role", ["exports", "boondmanager/controllers/entity"], function (_exports, _entity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Contrôleur parent des modules d'administration des rôles/comptes manager
   *
   * @class  AccountRoleController
   * @extends Ember.Controller
   * @namespace  Ember
   * @module  Controllers
   */
  var _default = _exports.default = _entity.default.extend({
    /** PROPERTIES **/
    appsVisibility: Object.freeze([]),
    settingDisplay: '',
    toggleRights: false,
    isTemplate: false,
    selectedOtherAgencies: Ember.computed('model.otherAgencies.{[],@each.id}', function () {
      return this.get('model.otherAgencies').then(function (agencies) {
        return agencies.map(function (agency) {
          return agency.get('id');
        });
      });
    }).readOnly(),
    selectedOtherPoles: Ember.computed('model.otherPoles.{[],@each.id}', function () {
      return this.get('model.otherPoles').then(function (poles) {
        return poles.map(function (pole) {
          return pole.get('id');
        });
      });
    }).readOnly(),
    totalMainModules: Ember.computed('toggleRights', 'model.isFulfilled', 'i18n._locale', function () {
      return this.getTotalEnabledModules('main');
    }).readOnly(),
    totalMainIntranetModules: Ember.computed('toggleRights', 'model.isFulfilled', 'i18n._locale', function () {
      return this.getTotalEnabledModules('mainIntranet');
    }).readOnly(),
    totalAdministrationModules: Ember.computed('toggleRights', 'model.isFulfilled', 'i18n._locale', function () {
      return this.getTotalEnabledModules('administration');
    }).readOnly(),
    totalPlanningAndReportingModules: Ember.computed('toggleRights', 'model.isFulfilled', 'i18n._locale', function () {
      return this.getTotalEnabledModules('planningAndReporting');
    }).readOnly(),
    totalMiscellaneousModules: Ember.computed('toggleRights', 'model.isFulfilled', 'i18n._locale', function () {
      return this.getTotalEnabledModules('miscellaneous');
    }).readOnly(),
    totalAppsModules: Ember.computed('toggleRights', 'i18n._locale', 'administrator.apps.isFulfilled', 'model.{apps.isFulfilled,isFulfilled}', function () {
      var _this = this;
      var total = 0;
      var visibility = ['allManagers', 'allManagersAndResources'];
      if (this.model.isTypeOfIntranet) {
        visibility = ['allowedManagersAndResources', 'allManagersAndResources'];
      }
      this.get('administrator.apps').forEach(function (app) {
        var enabledByVisibility = visibility.includes(app.get('visibility'));
        if (typeof _this.get('model.apps').find(function (modelApp) {
          return modelApp.id === app.id;
        }) !== 'undefined' || !_this.isTemplate && enabledByVisibility) total++;
      });
      return total;
    }).readOnly(),
    getTotalEnabledModules: function getTotalEnabledModules(category) {
      var _this2 = this;
      var total = 0;
      this.get('settings').bm.component.bmRightsFilters.modules[category].forEach(function (module) {
        if (_this2.get('model.advancedRights.' + module.id + '.isEnabled')) total++;
        if (module.children) {
          module.children.forEach(function (child) {
            var childEnabled = _this2.get('model.advancedRights.' + child.id + '.isEnabled');
            if (child.id === 'planningAbsences') childEnabled = childEnabled && _this2.get('administrator.modules.activityExpenses');
            if (childEnabled) total++;
          });
        }
      });
      return total;
    },
    /** ACTIONS **/
    actions: {
      changeSettingDisplay: function changeSettingDisplay(settingDisplay) {
        this.set('settingDisplay', settingDisplay);
      },
      changeOtherAgencies: function changeOtherAgencies(values) {
        var otherAgencies = [];
        this.get('availableOtherAgencies').forEach(function (agency) {
          if (values.includes(agency.get('id'))) {
            otherAgencies.push(agency);
          }
        });
        this.send('changeRelationship', this.get('model'), 'otherAgencies', otherAgencies);
      },
      changeOtherPoles: function changeOtherPoles(values) {
        var otherPoles = [];
        this.get('availableOtherPoles').forEach(function (pole) {
          if (values.includes(pole.get('id'))) {
            otherPoles.push(pole);
          }
        });
        this.send('changeRelationship', this.get('model'), 'otherPoles', otherPoles);
      },
      updateAdvancedRights: function updateAdvancedRights(params) {
        if (params.typeOf === 'right' && params.attribute === 'isEnabled') {
          this.toggleProperty('toggleRights');
        }
      }
    }
  });
});