define("boondmanager/pods/components/bm-field/roletemplate/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/search"], function (_exports, _component, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component BM Field Positioning.
   *
   * Display a searchable field for positionings
   *
   * Component attributes:
   *
   *   * {string}             name            REQUIRED field name
   *   * {boolean}            onlyFullVisible OPTIONAL filter the positioning list with item having a dependsOn and a contact and a company on opportunity. default: false
   *   * {function}           onChange        OPTIONAL callback call on period change
   *
   *
   * @class  BmFieldPositioning
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-roletemplate'],
    store: Ember.inject.service(),
    disabled: false,
    allowClear: true,
    withRoles: false,
    _getTemplates: function _getTemplates() {
      var promises = {
        roleTemplates: this.get('store').query('roletemplate', Ember.assign({
          endpoint: 'templates',
          sort: 'name'
        }, {
          maxResults: _search.MAX_ROWS_BY_PAGE
        })),
        roles: Promise.resolve(true)
      };
      if (this.get('withRoles')) {
        promises.roles = this.get('store').query('role', Ember.assign({
          sort: 'name',
          rolesTypes: ['manager'] // On masque les filtres intranet
        }, {
          maxResults: _search.MAX_ROWS_BY_PAGE
        }));
      }
      return Ember.RSVP.hash(promises);
    },
    templates: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.templates = [];
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._getTemplates().then(function (templates) {
        var list = [];
        if (_this.get('withRoles') && templates.roles.toArray().length) {
          list.push({
            id: 'roles',
            name: _this.get('i18n').t('components:bmFieldRoleTemplate.yourRoles'),
            option: templates.roles.toArray()
          });
        }
        if (templates.roleTemplates.toArray().length) {
          list.push({
            id: 'roleTemplates',
            name: _this.get('i18n').t('components:bmFieldRoleTemplate.roleTemplates'),
            option: templates.roleTemplates.toArray()
          });
        }
        if (list.length > 0) {
          _this.set('selected', list[0].option[0]);
        }
        _this.set('templates', list);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-positioning "onChange" must have a attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-positioning "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
    },
    actions: {
      onchange: function onchange(value) {
        this.get('onChange')(value);
      }
    }
  });
});