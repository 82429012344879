define("boondmanager/pods/components/bm-field/row-advantage/contract/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-row-advantage_contract'],
    _advantageTypes: Ember.computed('advantageTypes', 'i18n._locale', function () {
      var _this = this;
      var agencyAdvantageTypes = this.get('advantageTypes');
      if (agencyAdvantageTypes) {
        var self = this;
        var advantageTypes = agencyAdvantageTypes.filter(function (advantageType) {
          return advantageType.frequency !== 'punctual' && advantageType.state;
        }).map(function (advantageType) {
          return {
            id: advantageType.get('reference'),
            // + '_' + agencyId,
            value: advantageType.get('name'),
            advantageType: advantageType.copy()
          };
        });

        // test if current selected value is in list
        // if not it means that current value is archived and must be added
        var inList = advantageTypes.find(function (option) {
          return option.id === _this.get('advantage.reference');
        });
        if (typeof inList === 'undefined') {
          advantageTypes.pushObject({
            id: this.get('advantage.reference'),
            value: this.get('advantage.name'),
            advantageType: this.get('advantage').copy()
          });
        }
        return advantageTypes.sort(function (a, b) {
          return "".concat(a.value).localeCompare(b.value, self.get('i18n.locale'), {
            ignorePunctuation: true,
            sensitivity: 'base'
          });
        });
      }
      return [];
    }),
    actions: {
      changeAdvantage: function changeAdvantage(id) {
        var at = this.get('_advantageTypes').find(function (as) {
          return as.id === id;
        });
        var advantage = this.get('model.advantageTypes').objectAt(this.get('index'));
        advantage.set('reference', at.advantageType.get('reference'));
        advantage.set('name', at.advantageType.get('name'));
        advantage.set('frequency', at.advantageType.get('frequency'));
        advantage.set('category', at.advantageType.get('category'));
        advantage.set('participationQuota', at.advantageType.get('participationQuota'));
        advantage.set('agencyQuota', at.advantageType.get('agencyQuota'));
        advantage.set('employeeQuota', at.advantageType.get('employeeQuota'));
        advantage.set('agency', at.agency);
        this.get('model.advantageTypes').replaceContent(this.get('index'), 1, [advantage]);
      },
      deleteAdvantage: function deleteAdvantage(index) {
        this.get('deleteAdvantage')(index);
      }
    }
  });
});