define("boondmanager/pods/components/bm-field/input/component", ["exports", "jquery", "boondmanager/utils/cachecomputed", "boondmanager/app", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _jquery, _cachecomputed, _app, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    classNames: ['bm-input'],
    disabled: false,
    required: false,
    focused: false,
    bubbles: true,
    autocomplete: 'off',
    isCheckbox: Ember.computed.equal('type', 'checkbox'),
    hasValue: (0, _cachecomputed.default)('value', 'checked', 'type', function () {
      return !this.isBlankValue();
    }),
    inputId: Ember.computed('classNames.[]', 'id', function () {
      return this.id ? this.id : this.classNames[0] + Ember.guidFor(this);
    }),
    inputVanilla: Ember.computed('inputId', function () {
      return document.getElementById(this.get('inputId'));
    }).readOnly(),
    $input: Ember.computed('inputId', function () {
      return (0, _jquery.default)(document.getElementById(this.get('inputId')));
    }).readOnly(),
    inputClass: Ember.computed('focused', 'hasValue', 'classNames.[]', function () {
      var focused = this.focused,
        hasValue = this.hasValue;
      var inputClass = this.get('classNames').slice();
      if (focused) {
        inputClass.push(_component.bmFocusClass);
      }
      if (hasValue) {
        inputClass.push(_component.bmHasValueClass);
      }
      return Ember.String.htmlSafe(inputClass.join(' '));
    }).readOnly(),
    isBlankValue: function isBlankValue() {
      return this.get('type') === 'checkbox' ? !this.get('checked') : Ember.isBlank(this.get('value'));
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.isBlankValue) {
        var hasValueNeverDefined = typeof this._hasValue === 'undefined';

        // this._hasValue is auto defined in 'hasValue' cacheCP
        if (this.isBlankValue()) {
          if (hasValueNeverDefined || this._hasValue) {
            this.set('hasValue', false);
            this.$input.trigger(_app.bmCustomEvents.hasNoValue.name);
          }
        } else {
          if (hasValueNeverDefined || !this._hasValue) {
            this.set('hasValue', true);
            this.$input.trigger(_app.bmCustomEvents.hasValue.name);
          }
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var inputId = this.get('inputId');

      // autofocus on insert
      if (this.get('autofocus')) {
        document.getElementById(inputId).focus();
      }

      // set live content sizing if option activated
      if (this.get('fitContent') && !this.get('isCheckbox')) {
        this.resizeLiveInput = this.resizeLiveInput.bind(this);
        this.get('inputVanilla').addEventListener('input', this.resizeLiveInput);
        this.resizeLiveInput();
      }
    },
    resizeLiveInput: function resizeLiveInput() {
      var input = this.get('inputVanilla');
      var length = this.computeMaxLengthForInput(input.value);
      input.style.width = "".concat(length, "ch");
    } /* event */,
    /**
     * Compute max length to set to input for content fit on live resize
     * @param value
     * @returns {number|number}
     */
    computeMaxLengthForInput: function computeMaxLengthForInput(value) {
      var maxFitLength = this.get('maxFitLength');
      var currentInputLength = value.toString().length;
      var length = !currentInputLength ? 1 : currentInputLength;
      if (maxFitLength) {
        length = length > maxFitLength ? maxFitLength : length;
      }
      return length;
    },
    actions: {
      click: function click() {
        if (!this.get('bubbles')) {
          event.stopPropagation();
        }
        return true;
      },
      focusIn: function focusIn() {
        if (!this.disabled && !this.focusOnlyOnKey) {
          this.set('focused', true);
          this.$input.trigger(_app.bmCustomEvents.focusIn.name);
          var onFocusIn = this.get('focus-in');
          if (typeof onFocusIn === 'function') {
            onFocusIn.apply(void 0, arguments);
          }
        }
      },
      focusOut: function focusOut() {
        this.set('focused', false);
        this.$input.trigger(_app.bmCustomEvents.focusOut.name);
        var onFocusOut = this.get('focus-out');
        if (typeof onFocusOut === 'function') {
          onFocusOut.apply(void 0, arguments);
        }
      }
    }
  });
});