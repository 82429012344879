define("boondmanager/models/signature", ["exports", "ember-data", "ember-data-copyable", "boondmanager/models/base"], function (_exports, _emberData, _emberDataCopyable, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend(_emberDataCopyable.default, {
    // id: DS.attr('string'),
    state: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    remindDate: _emberData.default.attr('moment'),
    date: _emberData.default.attr('moment'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    function: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    mailValidatorSignature: _emberData.default.attr('string'),
    // Relationships
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    remindedBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    document: _emberData.default.belongsTo()
  });
});