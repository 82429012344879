define("boondmanager/pods/components/bm-field/tinymce/form-description/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "teJDFC4U",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,1]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"textarea\",[[16,3,[32,0,[\"name\"]]],[16,0,[32,0,[\"inputClass\"]]],[16,\"onchange\",[30,[36,0],[[32,0],\"onValueChanged\"],null]],[16,\"placeholder\",[32,0,[\"placeholder\"]]]],[[\"@value\",\"@id\"],[[32,0,[\"value\"]],[32,0,[\"getElementId\"]]]],null],[2,\"\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"bmc-field-template-tinymce-disabled\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-tiny-mce-text\",[],[[\"@value\"],[[32,0,[\"value\"]]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"disabled\",\"not\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/tinymce/form-description/template.hbs"
  });
});