define("boondmanager/models/action", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultActionTypes = _exports.default = _exports.categoriesOrder = _exports.categoriesLabels = _exports.TODO_RESOURCE = _exports.TODO_PROJECT = _exports.TODO_ORDER = _exports.TODO_OPPORTUNITY = _exports.TODO_INVOICE = _exports.TODO_CONTACT = _exports.TODO_CANDIDATE = _exports.RTQ_RESOURCE = _exports.RTQ_OPPORTUNITY = _exports.RTQ_CONTACT = _exports.RTQ_CANDIDATE = _exports.RDV_RESOURCE = _exports.RDV_PROJECT = _exports.RDV_CONTACT = _exports.RDV_CANDIDATE = _exports.PROJECT_MANAGEMENT = _exports.NOTE_RESOURCE = _exports.NOTE_PROJECT = _exports.NOTE_ORDER = _exports.NOTE_OPPORTUNITY = _exports.NOTE_INVOICE = _exports.NOTE_CONTACT = _exports.NOTE_CANDIDATE = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  var RTQ_CANDIDATE = _exports.RTQ_CANDIDATE = 0;
  var RTQ_CONTACT = _exports.RTQ_CONTACT = 10;
  var RTQ_RESOURCE = _exports.RTQ_RESOURCE = 8;
  var RTQ_OPPORTUNITY = _exports.RTQ_OPPORTUNITY = 52;
  var RDV_CANDIDATE = _exports.RDV_CANDIDATE = 12;
  var RDV_CONTACT = _exports.RDV_CONTACT = 11;
  var RDV_RESOURCE = _exports.RDV_RESOURCE = 9;
  var RDV_PROJECT = _exports.RDV_PROJECT = 32;
  var PROJECT_MANAGEMENT = _exports.PROJECT_MANAGEMENT = 90;
  var NOTE_CONTACT = _exports.NOTE_CONTACT = 2;
  var NOTE_RESOURCE = _exports.NOTE_RESOURCE = 4;
  var NOTE_CANDIDATE = _exports.NOTE_CANDIDATE = 13;
  var NOTE_ORDER = _exports.NOTE_ORDER = 20;
  var NOTE_PROJECT = _exports.NOTE_PROJECT = 30;
  var NOTE_OPPORTUNITY = _exports.NOTE_OPPORTUNITY = 50;
  var NOTE_INVOICE = _exports.NOTE_INVOICE = 70;
  var TODO_CANDIDATE = _exports.TODO_CANDIDATE = 1;
  var TODO_CONTACT = _exports.TODO_CONTACT = 3;
  var TODO_RESOURCE = _exports.TODO_RESOURCE = 5;
  var TODO_PROJECT = _exports.TODO_PROJECT = 31;
  var TODO_OPPORTUNITY = _exports.TODO_OPPORTUNITY = 51;
  var TODO_ORDER = _exports.TODO_ORDER = 21;
  var TODO_INVOICE = _exports.TODO_INVOICE = 71;
  var categoriesOrder = _exports.categoriesOrder = ['candidate', 'resource', 'contact', 'opportunity', 'project', 'order', 'invoice'];
  var categoriesLabels = _exports.categoriesLabels = {
    candidate: 'models:candidate.name',
    contact: 'models:contact.name',
    resource: 'models:employee.name',
    project: 'models:project.name',
    opportunity: 'models:opportunity.name',
    order: 'models:order.name',
    invoice: 'models:invoice.name',
    contactAndOpportunity: 'models:contactAndOpportunity.name'
  };
  var defaultActionTypes = _exports.defaultActionTypes = {
    candidate: [TODO_CANDIDATE],
    contact: [TODO_CONTACT],
    resource: [TODO_RESOURCE],
    project: [TODO_PROJECT],
    opportunity: [TODO_OPPORTUNITY],
    order: [TODO_ORDER],
    invoice: [TODO_INVOICE]
  };
  var Action = _base.default.extend(_emberDataCopyable.default, {
    i18n: Ember.inject.service(),
    moment: Ember.inject.service(),
    copyableOptions: Object.freeze({
      copyByReference: ['dependsOn', 'mainManager', 'company', 'positioning', 'delivery']
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get('categories').forEach(function (cat) {
        var catName = Ember.String.capitalize(cat);
        Ember.defineProperty(_this, "is".concat(catName, "Action"), Ember.computed.equal('category', cat));
      });
    },
    startDate: _emberData.default.attr('moment'),
    endDate: _emberData.default.attr('moment'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    text: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    startTimezone: _emberData.default.attr('string'),
    endTimezone: _emberData.default.attr('string'),
    synchronizeWithAdvancedAppCalendar: _emberData.default.attr('boolean'),
    relationType: _emberData.default.attr('string'),
    relatedActions: _emberData.default.hasMany('action', {
      inverse: null
    }),
    canShowDependsOn: _emberData.default.attr('boolean'),
    guests: _emberData.default.attr('array'),
    numberOfFiles: _emberData.default.attr('number'),
    canReadAction: _emberData.default.attr('boolean'),
    canWriteAction: _emberData.default.attr('boolean'),
    canDeleteAction: _emberData.default.attr('boolean'),
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    dependsOn: _emberData.default.belongsTo('base'),
    files: _emberData.default.hasMany('document'),
    file: _emberData.default.belongsTo(),
    mainManager: _emberData.default.belongsTo('resource'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    company: _emberData.default.belongsTo(),
    positioning: _emberData.default.belongsTo({
      inverse: null,
      defaultValue: null
    }),
    delivery: _emberData.default.belongsTo({
      inverse: null,
      defaultValue: null
    }),
    categories: Ember.computed('i18n._locale', function () {
      var excludeKeys = ['sort', 'forceMultiCreation'];
      return this.get('settingsService').customer.hasOwnProperty('action') ? Object.keys(this.get('settingsService').customer.action).filter(function (key) {
        return excludeKeys.indexOf(key) === -1;
      }) : [];
    }),
    category: Ember.computed('dependsOn.modelName', function () {
      var _this2 = this;
      var category;
      if (this.get('dependsOn.modelName')) {
        category = this.get('dependsOn.modelName');
        if (category === 'appadvancedcandidatescandidate') category = 'candidate';
      } else {
        var typeOf = this.get('typeOf');
        category = this.get('categories').find(function (category) {
          return _this2.get('settingsService').customer.action[category].find(function (item) {
            return item.id === typeOf;
          }) !== undefined;
        });
      }
      return category;
    }),
    // redefine typeOfArr and typeOfValue because 'action' is a particular case...
    typeOfArr: Ember.computed('category', 'i18n._locale', function () {
      var category = this.get('category');
      if (this.get('settingsService').customer.action.hasOwnProperty(category)) {
        return this.get('settingsService').customer.action[category];
      }
      return [];
    }),
    typeOfValue: Ember.computed('typeOf', 'typeOfArr', function () {
      var id = this.get('typeOf');
      var typeOfValue = null;
      var typeOfArr = this.get('typeOfArr');
      if (typeOfArr.length) {
        var typeOfObj = typeOfArr.find(function (item) {
          return item.id === id;
        });
        if (typeOfObj) typeOfValue = typeOfObj.value;
      }
      return typeOfValue;
    }),
    dependsOnThumbnail: Ember.computed('category', 'dependsOn.{thumbnail,company.thumbnail,project.company.thumbnail,order.project.company.thumbnail}', function () {
      var cat = this.get('category');
      if (typeof cat !== 'undefined') {
        switch (cat) {
          case 'candidate':
          case 'resource':
          case 'contact':
            return this.get('dependsOn.thumbnail');
          case 'project':
          case 'opportunity':
            return this.get('dependsOn.company.thumbnail');
          case 'order':
            return this.get('dependsOn.project.company.thumbnail');
          case 'invoice':
            return this.get('dependsOn.order.project.company.thumbnail');
        }
      }
      return '';
    }),
    dependsOnThumbnailUrl: Ember.computed('category', 'dependsOn.{thumbnailUrl,company.thumbnailUrl,project.company.thumbnailUrl,order.project.company.thumbnailUrl}', function () {
      var cat = this.get('category');
      if (typeof cat !== 'undefined') {
        switch (cat) {
          case 'candidate':
          case 'resource':
          case 'contact':
            return this.get('dependsOn.thumbnailUrl');
          case 'project':
          case 'opportunity':
            return this.get('dependsOn.company.thumbnailUrl');
          case 'order':
            return this.get('dependsOn.project.company.thumbnailUrl');
          case 'invoice':
            return this.get('dependsOn.order.project.company.thumbnailUrl');
        }
      }
      return '';
    }),
    dependsOnEmail1: Ember.computed('category', 'dependsOn.{email1,order.project.contact.email1,project.contact.email1,contact.email1}', function () {
      var cat = this.get('category');
      if (typeof cat !== 'undefined') {
        switch (cat) {
          case 'candidate':
          case 'resource':
          case 'contact':
            return this.get('dependsOn.email1');
          case 'invoice':
            return this.get('dependsOn.order.project.contact.email1');
          case 'order':
            return this.get('dependsOn.project.contact.email1');
          case 'opportunity':
          case 'project':
          case 'purchase':
            return this.get('dependsOn.contact.email1');
        }
      }
      return '';
    }),
    dependsOnPhone1: Ember.computed('category', 'dependsOn.{phone1,order.project.contact.phone1,project.contact.phone1,contact.phone1}', function () {
      var cat = this.get('category');
      if (typeof cat !== 'undefined') {
        switch (cat) {
          case 'candidate':
          case 'resource':
          case 'contact':
            return this.get('dependsOn.phone1');
          case 'invoice':
            return this.get('dependsOn.order.project.contact.phone1');
          case 'order':
            return this.get('dependsOn.project.contact.phone1');
          case 'opportunity':
          case 'project':
            return this.get('dependsOn.contact.phone1');
        }
      }
      return '';
    }),
    dependsOnPhone2: Ember.computed('category', 'dependsOn', function () {
      var cat = this.get('category');
      if (typeof cat !== 'undefined') {
        switch (cat) {
          case 'candidate':
          case 'resource':
          case 'contact':
            return this.get('dependsOn.phone2');
          case 'invoice':
            return this.get('dependsOn.order.project.contact.phone2');
          case 'order':
            return this.get('dependsOn.project.contact.phone2');
          case 'opportunity':
          case 'project':
            return this.get('dependsOn.contact.phone2');
        }
      }
      return '';
    }),
    dependsOnPathToActions: Ember.computed('category', function () {
      var cat = this.get('category');
      if (typeof cat !== 'undefined') {
        switch (cat) {
          case 'candidate':
            return 'private.candidates.candidate.actions';
          case 'resource':
            return 'private.resources.resource.actions';
          case 'contact':
            return 'private.contacts.contact.actions';
          case 'project':
            return 'private.projects.project.actions';
          case 'opportunity':
            return 'private.opportunities.opportunity.actions';
          case 'invoice':
            return 'private.invoices.invoice.actions';
          case 'order':
            return 'private.orders.order.actions';
        }
      }
      return 'private.index';
    }),
    dependsOnQueryParamsToActions: Ember.computed('category', function () {
      var types = [];
      switch (this.get('category')) {
        case 'resource':
          types.push(this.get('settingsService').customer.action.resource.map(function (action) {
            return action.id;
          }));
          types.push(this.get('settingsService').customer.action.candidate.map(function (action) {
            return action.id;
          }));
          break;
        case 'order':
          types.push(this.get('settingsService').customer.action.order.map(function (action) {
            return action.id;
          }));
          types.push(this.get('settingsService').customer.action.invoice.map(function (action) {
            return action.id;
          }));
          break;
        default:
          types.push(this.get('settingsService').customer.action[this.get('category')].map(function (action) {
            return action.id;
          }));
          break;
      }
      return types.flat();
    }),
    dependsOnPath: Ember.computed('category', function () {
      var cat = this.get('category');
      if (typeof cat !== 'undefined') {
        switch (cat) {
          case 'candidate':
            return 'private.candidates.candidate.overview';
          case 'resource':
            return 'private.resources.resource.overview';
          case 'contact':
            return 'private.contacts.contact.overview';
          case 'project':
            return 'private.projects.project.information';
          case 'opportunity':
            return 'private.opportunities.opportunity.information';
          case 'invoice':
            return 'private.invoices.invoice.information';
          case 'order':
            return 'private.orders.order.information';
        }
      }
      return 'private.dashboard';
    }),
    dependsOnLabel: Ember.computed('dependsOn', 'category', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      if (this.get('isResourceAction')) {
        return i18n.t('models:employee.name');
      }
      if (this.get('isCandidateAction')) {
        return i18n.t('models:candidate.name');
      }
      if (this.get('isOpportunityAction')) {
        return i18n.t('models:opportunity.name');
      }
      if (this.get('isContactAction')) {
        return i18n.t('models:contact.name');
      }
      if (this.get('isProjectAction')) {
        return i18n.t('models:project.name');
      }
      if (this.get('isOrderAction')) {
        return i18n.t('models:order.name');
      }
      if (this.get('isInvoiceAction')) {
        return i18n.t('models:invoice.name');
      }
      return '';
    }).readOnly(),
    isClientPresentation: Ember.computed('category', 'typeOf', function () {
      switch (this.get('category')) {
        case 'resource':
          return this.get('typeOf') === RTQ_RESOURCE;
        case 'candidate':
          return this.get('typeOf') === RTQ_CANDIDATE;
        case 'opportunity':
          return this.get('typeOf') === RTQ_OPPORTUNITY;
        case 'contact':
          return this.get('typeOf') === RTQ_CONTACT;
      }
      return false;
    }),
    isMonitoringDelivery: Ember.computed('category', 'typeOf', function () {
      switch (this.get('category')) {
        case 'resource':
          return this.get('typeOf') === RDV_RESOURCE;
        case 'project':
          return this.get('typeOf') === RDV_PROJECT;
        case 'contact':
          return this.get('typeOf') === RDV_CONTACT;
      }
      return false;
    }),
    parent: Ember.computed('relatedActions', function () {
      var promise = this.get('relatedActions').then(function (relatedActions) {
        return relatedActions.findBy('relationType', 'parent');
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    children: Ember.computed('relatedActions', function () {
      var promise = this.get('relatedActions').then(function (relatedActions) {
        return relatedActions.filterBy('relationType', 'child');
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    actionListTitle: Ember.computed('parent', 'children', 'i18n._locale', function () {
      var _this3 = this;
      // obligé de passer par une computed plutôt que directement dans le template car via le template
      // les retours à la ligne du code ajoute des espaces insécables indésirables
      var promise = this.get('parent').then(function (parent) {
        var i18n = _this3.get('i18n');
        var title = '';
        var commonMasked = "<span class=\"bm-text-masked\">".concat(i18n.t('common:hidden'), "</span>");
        if (parent) {
          if (parent.get('isCandidateAction')) {
            title += "<strong>".concat(i18n.t('common:typography.colon', {
              value: i18n.t('models:candidate.name')
            }), "</strong>&nbsp;").concat(parent.get('dependsOn.completeName') || commonMasked);
          } else if (parent.get('isResourceAction')) {
            title += "<strong>".concat(i18n.t('common:typography.colon', {
              value: i18n.t('models:employee.name')
            }), "</strong>&nbsp;").concat(parent.get('dependsOn.completeName') || commonMasked);
          } else if (parent.get('isContactAction')) {
            title += "<strong>".concat(i18n.t('common:typography.colon', {
              value: i18n.t('models:contact.name')
            }), "</strong>&nbsp;").concat(parent.get('dependsOn.completeName') || commonMasked, ",&nbsp;");
            title += "<strong>".concat(i18n.t('common:typography.colon', {
              value: i18n.t('models:company.name')
            }), "</strong>&nbsp;").concat(parent.get('company.name') || commonMasked);
          } else if (parent.get('isOpportunityAction')) {
            title += "<strong>".concat(i18n.t('common:typography.colon', {
              value: i18n.t('models:opportunity.name')
            }), "</strong>&nbsp;").concat(parent.get('dependsOn.title') || commonMasked);
          } else if (parent.get('isProjectAction')) {
            title += "<strong>".concat(i18n.t('common:typography.colon', {
              value: i18n.t('models:project.name')
            }), "</strong>&nbsp;").concat(parent.get('dependsOn.reference') || commonMasked);
          } else if (parent.get('isOrderAction')) {
            title += "<strong>".concat(i18n.t('common:typography.colon', {
              value: i18n.t('models:order.name')
            }), "</strong>&nbsp;").concat(parent.get('dependsOn.number') || commonMasked);
          } else if (parent.get('isInvoiceAction')) {
            title += "<strong>".concat(i18n.t('common:typography.colon', {
              value: i18n.t('models:invoice.name')
            }), "</strong>&nbsp;").concat(parent.get('dependsOn.reference') || commonMasked);
          }
        }
        return _this3.get('children').then(function (children) {
          children.forEach(function (child, index) {
            if (child.get('isCandidateAction')) {
              if (index > 0 || parent) title += ',&nbsp;';
              title += "<strong>".concat(i18n.t('common:typography.colon', {
                value: i18n.t('models:candidate.name')
              }), "</strong>&nbsp;").concat(child.get('dependsOn.completeName') || commonMasked);
            } else if (child.get('isResourceAction')) {
              if (index > 0 || parent) title += ',&nbsp;';
              title += "<strong>".concat(i18n.t('common:typography.colon', {
                value: i18n.t('models:employee.name')
              }), "</strong>&nbsp;").concat(child.get('dependsOn.completeName') || commonMasked);
            } else if (child.get('isContactAction')) {
              if (index > 0 || parent) title += ',&nbsp;';
              title += "<strong>".concat(i18n.t('common:typography.colon', {
                value: i18n.t('models:contact.name')
              }), "</strong>&nbsp;").concat(child.get('dependsOn.completeName') || commonMasked, ",&nbsp;");
              title += "<strong>".concat(i18n.t('common:typography.colon', {
                value: i18n.t('models:company.name')
              }), "</strong>&nbsp;").concat(child.get('company.name') || commonMasked);
            } else if (child.get('isOpportunityAction')) {
              if (index > 0 || parent) title += ',&nbsp;';
              title += "<strong>".concat(i18n.t('common:typography.colon', {
                value: i18n.t('models:opportunity.name')
              }), "</strong>&nbsp;").concat(child.get('dependsOn.title') || commonMasked);
            } else if (child.get('isProjectAction')) {
              if (index > 0 || parent) title += ',&nbsp;';
              title += "<strong>".concat(i18n.t('common:typography.colon', {
                value: i18n.t('models:project.name')
              }), "</strong>&nbsp;").concat(child.get('dependsOn.reference') || commonMasked);
            } else if (child.get('isOrderAction')) {
              if (index > 0 || parent) title += ',&nbsp;';
              title += "<strong>".concat(i18n.t('common:typography.colon', {
                value: i18n.t('models:order.name')
              }), "</strong>&nbsp;").concat(child.get('dependsOn.number') || commonMasked);
            } else if (child.get('isInvoiceAction')) {
              if (index > 0 || parent) title += ',&nbsp;';
              title += "<strong>".concat(i18n.t('common:typography.colon', {
                value: i18n.t('models:invoice.name')
              }), "</strong>&nbsp;").concat(child.get('dependsOn.reference') || commonMasked);
            }
          });
          return {
            value: Ember.String.htmlSafe(title)
          };
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    startInFuture: Ember.computed('startDate', function () {
      return this.get('startDate').isAfter(this.get('moment').moment());
    }).readOnly()
  });
  Action.reopenClass({
    prefix: 'ACT'
  });
  Action.reopen({
    prefix: Ember.computed(function () {
      return Action.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Action;
});