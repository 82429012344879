define("boondmanager/models/apphouraccountsdaysettingsfragment", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/models/apphouraccountshouraccountfragment", "moment"], function (_exports, _emberData, _emberDataModelFragments, _apphouraccountshouraccountfragment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    day: _emberData.default.attr('number'),
    morningStartHour: _emberData.default.attr('string', {
      defaultValue: null
    }),
    morningEndHour: _emberData.default.attr('string', {
      defaultValue: null
    }),
    afternoonStartHour: _emberData.default.attr('string', {
      defaultValue: null
    }),
    afternoonEndHour: _emberData.default.attr('string', {
      defaultValue: null
    }),
    totalDay: Ember.computed('day', 'morningStartHour', 'morningEndHour', 'afternoonStartHour', 'afternoonEndHour', function () {
      return (0, _apphouraccountshouraccountfragment.computeTotalDay)(this, 'h');
    }).readOnly(),
    break: Ember.computed('day', 'morningEndHour', 'afternoonStartHour', function () {
      return (0, _apphouraccountshouraccountfragment.computeBreak)(this, 'h');
    }).readOnly(),
    dayType: Ember.computed('day', function () {
      return ![0, 6].includes(this.get('day')) ? 'normal' : 'weekend';
    }).readOnly(),
    dayTitle: Ember.computed('day', 'i18n_locale', function () {
      var testDate = (0, _moment.default)();
      return testDate.day(this.get('day')).format('dddd');
    }).readOnly()
  });
});