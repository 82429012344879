define("boondmanager/routes/tab", ["exports", "boondmanager/routes/entity"], function (_exports, _entity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Routeur parent des onglets
   *
   * @class TabRoute
   * @extends Ember.Route
   * @namespace Ember
   * @module Routes
   */
  var _default = _exports.default = _entity.default.extend({
    loadRights: false //On empêche de charger les droits une deuxième fois qui ont déjà été chargés avec entity
  });
});