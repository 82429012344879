define("boondmanager/models/gadgetfragment", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/classes/dashboard/dashboard", "boondmanager/models/gadget"], function (_exports, _emberData, _emberDataModelFragments, _dashboard, _gadget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    /** SERVICES **/
    settingsService: Ember.inject.service('settings'),
    /*** ATTRIBUTES ***/
    originalID: _emberData.default.attr('string'),
    gadgetType: _emberData.default.attr('string'),
    widgetType: _emberData.default.attr('string'),
    module: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    col: _emberData.default.attr('number'),
    row: _emberData.default.attr('number'),
    canReadGadget: _emberData.default.attr('boolean'),
    canWriteGadget: _emberData.default.attr('boolean'),
    params: _emberData.default.attr(),
    charts: _emberData.default.attr(),
    /*** COMPUTED ***/
    gadgetModule: Ember.computed('gadgetType', 'widgetType', 'module', function () {
      return (0, _dashboard.getGadgetModule)(this.get('gadgetType'), this.get('widgetType'), this.get('module'));
    }).readOnly(),
    route: Ember.computed('gadgetModule', function () {
      return (0, _dashboard.getRoute)(this.get('gadgetModule'));
    }).readOnly(),
    routeParams: Ember.computed('widgetType', 'params', function () {
      return (0, _dashboard.getRouteParams)(this.get('widgetType'), this.get('params'));
    }).readOnly(),
    titleForType: Ember.computed('gadgetType', function () {
      var title = '';
      switch (this.get('gadgetType')) {
        case _gadget.GADGET_TYPE_WIDGET:
          title = this.get('i18n').t('dashboard:view.gadgetType.widget');
          break;
        case _gadget.GADGET_TYPE_METRIC:
          title = this.get('i18n').t('dashboard:view.gadgetType.metric');
          break;
        case _gadget.GADGET_TYPE_COLLECTION:
          title = this.get('i18n').t('dashboard:view.gadgetType.collection');
          break;
      }
      return title;
    }).readOnly(),
    iconForType: Ember.computed('gadgetType', function () {
      var icon = '';
      switch (this.get('gadgetType')) {
        case _gadget.GADGET_TYPE_WIDGET:
          icon = 'bmi-gadget-widget';
          break;
        case _gadget.GADGET_TYPE_METRIC:
          icon = 'bmi-gadget-metric';
          break;
        case _gadget.GADGET_TYPE_COLLECTION:
          icon = 'bmi-gadget-collection';
          break;
      }
      return icon;
    }).readOnly(),
    classForType: Ember.computed('gadgetType', function () {
      var klass = '';
      switch (this.get('gadgetType')) {
        case _gadget.GADGET_TYPE_WIDGET:
        case _gadget.GADGET_TYPE_COLLECTION:
          klass = ' bmc-card_dashboard-widget';
          break;
      }
      return klass;
    }).readOnly(),
    canEditGadget: Ember.computed('canReadGadget', 'originalID', 'gadgetType', 'module', function () {
      return this.get('canReadGadget') && this.get('originalID') || this.changedAttributes().gadgetType || this.changedAttributes().module;
    }).readOnly(),
    defaultTitle: Ember.computed('title', 'gadgetType', 'widgetType', 'module', 'i18n._locale', {
      get: function get() {
        if (this.get('title')) return this.get('title');
        switch (this.get('gadgetType')) {
          case _gadget.GADGET_TYPE_WIDGET:
            {
              var types = this.i18n.t('models:gadget.attributes.widgets.values', {
                returnObjects: true
              });
              var title = '';
              if (types) {
                var foundValue = types.findBy('id', this.get('widgetType'));
                if (foundValue) title = foundValue.value;
              }
              return title;
            }
          case _gadget.GADGET_TYPE_COLLECTION:
            return this.get('settingsService').bm.dashboard.collections.modules.findBy('id', this.get('module')).value;
          case _gadget.GADGET_TYPE_METRIC:
            return this.get('settingsService').bm.dashboard.metrics.modules.findBy('id', this.get('module')).value;
        }
      },
      set: function set(_, value) {
        return value;
      }
    })
  });
});