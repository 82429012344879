define("boondmanager/pods/components/bm-field/subdivisions/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * SubDivisions field component.
   *
   * * Depend on:
   *   - Boondmanager sub divisions list in i18n files
   *   - FieldSelect component (in template)
   *
   * Component attributes:
   *
   *   * {string}             name           REQUIRED field name
   *   * {string}             class          OPTIONAL additional classes applied to the main DOM element
   *   * {boolean}            country 			 REQUIRED country selected
   *   * {boolean}            disabled       OPTIONAL component state
   *   * {string}             size           OPTIONAL component Bootstrap size
   *   * {Array}              selection      OPTIONAL selected <option>s ID
   *   * {function}           onChange       OPTIONAL callback call on <option> selection
   *
   * Example:
   *
   * ```handlebars
   * {{bm-field/subdivision
   *     name="subDivision"
   *     size=size
   *     disabled=disabled
   *     selection=model.subDivision
   *     onChange=(action "changeSubDivision")
   * }}
   * ```
   *
   *
   * @class  BmFieldSubDivisionsComponent
   * @extends Ember.Component
   * @namespace Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-subdivisions'],
    attributeBindings: ['dataName:data-name'],
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    /**
     * <select>'s <option>s list.
     *
     * @property options
     * @type {Array.<OptionHash>}
     * @default []
     * @public
     */
    options: Ember.computed('i18n._locale', 'country', function () {
      var subDivisions = this.get('i18n').subDivisionsForCountry(this.get('country'));
      return Ember.isArray(subDivisions) ? subDivisions.map(function (subDivision) {
        return {
          id: subDivision.id,
          value: subDivision.value
        };
      }) : [];
    }),
    selectedSubDivision: Ember.computed('selection', function () {
      var _this = this;
      return this.get('options').filter(function (opt) {
        return opt.id === _this.get('selection');
      }).length > 0 ? this.get('selection') : undefined;
    }),
    /**
     * 'didReceiveAttrs' Component hook
     *
     * Check __name__ component attribute
     *
     * @method didReceiveAttrs
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert('bmc-field-countries must have a "name" attribute!', !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert('bmc-field-countries "name" attribute must be a string!', Ember.typeOf(name) === 'string'));
    }
  });
});