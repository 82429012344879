define("boondmanager/models/appemailingpositioning", ["exports", "boondmanager/models/positioning", "ember-data"], function (_exports, _positioning, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsEMailingPositioning = _positioning.default.extend({
    allowedStates: _emberData.default.attr(''),
    resumesOptions: Ember.computed.alias('dependsOn.resumesOptions').readOnly(),
    emailsOptions: Ember.computed.alias('dependsOn.emailsOptions').readOnly(),
    entityName: Ember.computed.alias('getReference').readOnly(),
    canReadRecord: Ember.computed.alias('canReadPositioning').readOnly(),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'positioning',
    recordRouteName: Ember.computed('', function () {
      return 'private.positionings.positioning';
    }).readOnly(),
    //dependsOnCandidate: Ember.computed.equal('dependsOn.modelName', 'candidate'),
    dependsOnCandidate: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'appemailingcandidate';
    }).readOnly(),
    //dependsOnResource : Ember.computed.equal('dependsOn.modelName', 'resource'),
    dependsOnResource: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'appemailingresource';
    }).readOnly()
  });
  AppsEMailingPositioning.reopenClass({
    prefix: 'POS'
  });
  AppsEMailingPositioning.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingPositioning.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingPositioning;
});