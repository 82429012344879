define("boondmanager/models/appextractpayrollresource", ["exports", "ember-data", "ember-data-model-fragments/attributes", "boondmanager/utils/logger", "ember-copy", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _attributes, _logger, _emberCopy, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _emberData.default.Model.extend({
    periodToExtractForExpenses: _emberData.default.attr('string'),
    periodToExtractForAdvantages: _emberData.default.attr('string'),
    periodToExtractForAbsences: _emberData.default.attr('string'),
    columnsToExtract: (0, _attributes.fragmentArray)('appextractpayrollcolumntoextract'),
    rulesForAdvantages: (0, _attributes.fragmentArray)('appextractpayrollruleforadvantage'),
    addRule: function addRule(rule) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var rules = this.get('rulesForAdvantages');
      switch (_typeof(rule)) {
        case 'object':
          if ((0, _fragment.isFragment)(rule)) {
            rules.addFragment(makeAcopy ? (0, _emberCopy.copy)(rule) : rule);
          } else {
            rules.createFragment(rule);
          }
          break;
        default:
          rules.createFragment();
      }
    },
    delRule: function delRule(rule) {
      if ((0, _fragment.isFragment)(rule)) {
        this.get('rulesForAdvantages').removeFragment(rule);
      } else {
        _logger.default.warn("".concat(this.get('id'), ":delRule() - rule is not a fragment !"), rule);
      }
    }
  });
});