define("boondmanager/models/schedule", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FREQUENCY_SEMIANNUAL = _exports.FREQUENCY_QUARTERLY = _exports.FREQUENCY_MONTHLY = _exports.FREQUENCY_CUSTOMIZED = _exports.FREQUENCY_ANNUAL = _exports.FREQUENCY_ADDITIONAL_TURNOVER = _exports.DATE_LASTDAYOFMONTH = _exports.AMOUNT_SAMEAMOUNTFOREACHSCHEDULE = _exports.AMOUNT_ORDERTURNOVERDIVIDEDBYTHENUMBEROFSCHEDULES = _exports.AMOUNT_DIFFERENTAMOUNTFOREACHSCHEDULE = _exports.AMOUNT_APERCENTOFTHEORDERTURNOVERPERSCHEDULE = void 0;
  var FREQUENCY_CUSTOMIZED = _exports.FREQUENCY_CUSTOMIZED = 'customized';
  var FREQUENCY_MONTHLY = _exports.FREQUENCY_MONTHLY = 'monthly';
  var FREQUENCY_QUARTERLY = _exports.FREQUENCY_QUARTERLY = 'quarterly';
  var FREQUENCY_SEMIANNUAL = _exports.FREQUENCY_SEMIANNUAL = 'semiAnnual';
  var FREQUENCY_ANNUAL = _exports.FREQUENCY_ANNUAL = 'annual';
  var FREQUENCY_ADDITIONAL_TURNOVER = _exports.FREQUENCY_ADDITIONAL_TURNOVER = 'additionalTurnover';
  var DATE_LASTDAYOFMONTH = _exports.DATE_LASTDAYOFMONTH = 'lastDayOfMonth';
  var AMOUNT_APERCENTOFTHEORDERTURNOVERPERSCHEDULE = _exports.AMOUNT_APERCENTOFTHEORDERTURNOVERPERSCHEDULE = 'aPercentOfTheOrderTurnoverPerSchedule';
  var AMOUNT_DIFFERENTAMOUNTFOREACHSCHEDULE = _exports.AMOUNT_DIFFERENTAMOUNTFOREACHSCHEDULE = 'differentAmountForEachSchedule';
  var AMOUNT_ORDERTURNOVERDIVIDEDBYTHENUMBEROFSCHEDULES = _exports.AMOUNT_ORDERTURNOVERDIVIDEDBYTHENUMBEROFSCHEDULES = 'orderTurnoverDividedByTheNumberOfSchedules';
  var AMOUNT_SAMEAMOUNTFOREACHSCHEDULE = _exports.AMOUNT_SAMEAMOUNTFOREACHSCHEDULE = 'sameAmountForEachSchedule';
  var Schedule = _base.default.extend({
    date: _emberData.default.attr('date'),
    deltaInvoicedExcludingTax: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    turnoverInvoicedExcludingTax: _emberData.default.attr('number'),
    turnoverTermOfPaymentExcludingTax: _emberData.default.attr('number'),
    numberOfInvoices: _emberData.default.attr('number'),
    order: _emberData.default.belongsTo(),
    invoice: _emberData.default.belongsTo(),
    negativeDelta: Ember.computed('deltaInvoicedExcludingTax', function () {
      return this.get('deltaInvoicedExcludingTax') < 0;
    }),
    canDelete: true,
    edition: false,
    originalSchedule: undefined,
    index: undefined
  });
  Schedule.reopenClass({
    prefix: 'ECH'
  });
  Schedule.reopen({
    prefix: Ember.computed(function () {
      return Schedule.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Schedule;
});