define("boondmanager/pods/components/bm-field/subscription-regulate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eaD7lD3P",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,12],null,[[\"noTitle\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,11],null,[[\"name\",\"value\",\"currencySymbol\",\"change\",\"agencyChange\",\"forceZero\",\"disabled\",\"onChange\"],[[30,[36,10],[\"regulateAmount[\",[35,1],\"]\"],null],[35,3,[\"amountExcludingTax\"]],[35,9],[35,8],[35,7],[35,6],[35,5],[30,[36,2],[[32,0],[30,[36,4],[[35,3,[\"amountExcludingTax\"]]],null]],null]]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@noTitle\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/input\",[[24,\"maxlength\",\"100\"],[16,3,[30,[36,10],[\"regulateDescription[\",[32,0,[\"index\"]],\"]\"],null]],[16,\"data-name\",[30,[36,10],[\"regulateDescription[\",[32,0,[\"index\"]],\"]\"],null]]],[[\"@disabled\",\"@value\"],[[32,0,[\"disabled\"]],[34,3,[\"description\"]]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\"],[6,[37,13],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[24,\"data-name\",\"button-delete-subscription-regulate\"],[16,\"data-original-title\",[30,[36,0],[\"common.delete\"],null]],[16,\"aria-label\",[30,[36,0],[\"common.delete\"],null]]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-delete\",[30,[36,2],[[32,0],\"deleteRegulate\",[35,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"index\",\"action\",\"regulate\",\"mut\",\"disabled\",\"forceZero\",\"agencyChange\",\"change\",\"currencySymbol\",\"concat\",\"bm-field/money\",\"bm-field\",\"unless\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/subscription-regulate/template.hbs"
  });
});