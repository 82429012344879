define("boondmanager/pods/components/bm-field/headoffice/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-headoffice'],
    classNameBindings: ['showLock:bmc-field-lock', 'isLocked:bmc-field-locked'],
    attributeBindings: ['dataName:data-name'],
    store: Ember.inject.service(),
    allowClear: false,
    showSelect: false,
    showLock: false,
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    isLocked: Ember.computed('showLock', 'disabled', 'showSelect', function () {
      return !this.get('showLock') && !this.get('disabled') || !this.get('showSelect');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');

      // check onChange
      (false && !(!Ember.isBlank(onChange)) && Ember.assert('bm-field-headoffice "onChange" must have a attribute!', !Ember.isBlank(onChange)));
      (false && !(Ember.typeOf(onChange) === 'function') && Ember.assert('bm-field-headoffice "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function'));
    },
    actions: {
      toggleSelect: function toggleSelect() {
        if (!this.get('disabled')) {
          var onUnlock = this.get('onUnlock');
          this.toggleProperty('showSelect');
          if (this.get('showSelect') && typeof onUnlock === 'function') {
            onUnlock();
          }
        }
      },
      onchange: function onchange(value) {
        this.set('showSelect', true);
        this.get('onChange')(value);
      }
    }
  });
});