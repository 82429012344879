define("boondmanager/models/appcelebrationsemployee", ["exports", "boondmanager/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Celebration = _resource.default.extend({
    showBirthdayCelebrationDate: true,
    showArrivalCelebrationDate: true,
    showSeniorityCelebrationDate: true
  });
  var _default = _exports.default = Celebration;
});