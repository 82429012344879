define("boondmanager/models/groupment", ["exports", "ember-data", "boondmanager/models/basedelivery", "ember-data-copyable", "boondmanager/utils/logger", "ember-copy", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _basedelivery, _emberDataCopyable, _logger, _emberCopy, _attributes, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var Groupment = _basedelivery.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['project']
    }),
    // ATTRIBUTES
    averageDailyPriceExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    averageDailyCost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    endDate: _emberData.default.attr('date'),
    numberOfDaysFree: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfDaysInvoicedOrQuantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    startDate: _emberData.default.attr('date'),
    title: _emberData.default.attr('string'),
    loadDistribution: _emberData.default.attr('string'),
    informationComments: _emberData.default.attr('string'),
    exceptionalCalendarsProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    exceptionalTimesProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    expensesProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    expensesSimulated: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    turnoverProductionExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    costsProductionExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    turnoverSimulatedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    costsSimulatedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    marginSimulatedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    profitabilitySimulated: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    regularTimesProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    regularTimesProductionInWorkUnits: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    regularTimesSimulated: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    canReadGroupment: _emberData.default.attr('boolean'),
    canWriteGroupment: _emberData.default.attr('boolean'),
    canShowExpensesSimulated: _emberData.default.attr('boolean'),
    canShowExpensesProduction: _emberData.default.attr('boolean'),
    canShowTurnoverProductionExcludingTax: _emberData.default.attr('boolean'),
    canShowCostsProductionExcludingTax: _emberData.default.attr('boolean'),
    canShowTurnoverSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowCostsSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowProfitabilitySimulated: _emberData.default.attr('boolean'),
    canShowMarginSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowAverageDailyPriceExcludingTax: _emberData.default.attr('boolean'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    // RELATIONSHIPS
    project: _emberData.default.belongsTo({
      inverse: null
    }),
    // deliveries ou deliveryRecruitment
    files: _emberData.default.hasMany(),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    // FRAGMENTS
    deliveries: (0, _attributes.fragmentArray)('groupmentdeliveryfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    averageWorkUnitPriceExcludingTax: Ember.computed('averageDailyPriceExcludingTax', 'project.workUnitRate', {
      get: function get() {
        return this.get('project.workUnitRate') ? this.get('averageDailyPriceExcludingTax') / this.get('project.workUnitRate') : this.get('averageDailyPriceExcludingTax');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model groupment - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('averageDailyPriceExcludingTax', value * this.get('project.workUnitRate'));
        }
        return value;
      }
    }),
    numberOfWorkUnitsInvoicedOrQuantity: Ember.computed('numberOfDaysInvoicedOrQuantity', 'project.workUnitRate', {
      get: function get() {
        return this.get('numberOfDaysInvoicedOrQuantity') * this.get('project.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model groupment - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('numberOfDaysInvoicedOrQuantity', this.get('project.workUnitRate') ? value / this.get('project.workUnitRate') : value);
        }
        return value;
      }
    }),
    numberOfWorkUnitsFree: Ember.computed('numberOfDaysFree', 'project.workUnitRate', {
      get: function get() {
        return this.get('numberOfDaysFree') * this.get('project.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model groupment - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('numberOfDaysFree', this.get('project.workUnitRate') ? value / this.get('project.workUnitRate') : value);
        }
        return value;
      }
    }),
    isProfitabilityCalculatingBasedOnMarginRate: Ember.computed(function () {
      var profitability = this.get('settingsService').customer.profitabilityMethodOfCalculating;
      return profitability === 'marginRate';
    }).readOnly(),
    turnoverSimulatedExcludingTaxCalculated: Ember.computed('averageDailyPriceExcludingTax', 'numberOfDaysInvoicedOrQuantity', 'scheduleRemains', 'deliveries.@each.id', function () {
      var turnover = this.get('scheduleRemains') * this.get('averageDailyPriceExcludingTax');
      this.get('deliveries').forEach(function (groupmentDelivery) {
        if (groupmentDelivery.delivery.additionalTurnoverExcludingTax) {
          turnover += groupmentDelivery.delivery.additionalTurnoverExcludingTax;
        }
        if (groupmentDelivery.delivery.averageDailyPriceExcludingTax) {
          turnover += groupmentDelivery.delivery.averageDailyPriceExcludingTax * groupmentDelivery.schedule;
        }
      });
      return turnover;
    }),
    regularTimesSimulatedInWorkUnits: Ember.computed('regularTimesSimulated', 'project.workUnitRate', function () {
      return this.get('regularTimesSimulated') * this.get('project.workUnitRate');
    }),
    scheduleRemains: Ember.computed('numberOfDaysInvoicedOrQuantity', 'numberOfDaysFree', function () {
      return this.get('numberOfDaysInvoicedOrQuantity') + this.get('numberOfDaysFree');
    }),
    scheduleRemainsInWorkUnits: Ember.computed('numberOfDaysInvoicedOrQuantity', 'numberOfDaysFree', 'project.workUnitRate', function () {
      return (this.get('numberOfDaysInvoicedOrQuantity') + this.get('numberOfDaysFree')) * this.get('project.workUnitRate');
    }),
    roundedScheduleRemains: Ember.computed('scheduleRemains', function () {
      return Math.round(this.get('scheduleRemains') * 100) / 100;
    }),
    costsSimulatedExcludingTaxCalculated: Ember.computed('averageDailyCost', 'numberOfDaysInvoicedOrQuantity', 'deliveries.@each.{schedule,id}', function () {
      var costs = 0;
      var schedule = 0;
      this.get('deliveries').forEach(function (groupmentDelivery) {
        schedule += groupmentDelivery.schedule;
        if (groupmentDelivery.delivery.averageDailyCost) {
          costs += groupmentDelivery.schedule * groupmentDelivery.delivery.averageDailyCost;
        }
        if (groupmentDelivery.delivery.additionalCostsExcludingTax) {
          costs += groupmentDelivery.delivery.additionalCostsExcludingTax;
        }
      });
      if (schedule < this.get('numberOfDaysInvoicedOrQuantity')) {
        costs += (this.get('numberOfDaysInvoicedOrQuantity') - schedule) * this.get('averageDailyCost');
      }
      return costs;
    }),
    marginSimulatedExcludingTaxCalculated: Ember.computed('turnoverSimulatedExcludingTaxCalculated', 'costsSimulatedExcludingTaxCalculated', function () {
      return this.get('turnoverSimulatedExcludingTaxCalculated') - this.get('costsSimulatedExcludingTaxCalculated');
    }),
    profitabilitySimulatedCalculated: Ember.computed('marginSimulatedExcludingTaxCalculated', 'turnoverSimulatedExcludingTaxCalculated', 'costsSimulatedExcludingTaxCalculated', function () {
      var value;
      var margin = this.get('marginSimulatedExcludingTaxCalculated');
      if (this.get('isProfitabilityCalculatingBasedOnMarginRate')) {
        var cost = this.get('costsSimulatedExcludingTaxCalculated');
        value = Math.round(100000 * cost) ? Math.round(10000 * margin / cost) / 100 : 0;
      } else {
        var turnover = this.get('turnoverSimulatedExcludingTaxCalculated');
        value = Math.round(100000 * turnover) ? Math.round(10000 * (margin / turnover)) / 100 : 0;
      }
      return value;
    }),
    entityName: Ember.computed('title', function () {
      var title = this.get('title');
      if (Ember.isBlank(title)) {
        return this.get('internalReference');
      } else {
        return title;
      }
    }),
    // METHODS
    addGroupmentDelivery: function addGroupmentDelivery(groupmentDelivery) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var deliveries = this.get('deliveries');
      switch (_typeof(groupmentDelivery)) {
        case 'object':
          if ((0, _fragment.isFragment)(groupmentDelivery)) {
            deliveries.addFragment(makeAcopy ? (0, _emberCopy.copy)(groupmentDelivery) : groupmentDelivery);
          } else {
            deliveries.createFragment(groupmentDelivery);
          }
          break;
        default:
          deliveries.createFragment();
      }
    },
    delGroupmentDelivery: function delGroupmentDelivery(groupmentDelivery) {
      if ((0, _fragment.isFragment)(groupmentDelivery)) {
        this.get('deliveries').removeFragment(groupmentDelivery);
      } else {
        _logger.default.warn("Groupment#".concat(this.get('id'), ":delGroupmentDelivery() - delivery is not a fragment !"), groupmentDelivery);
      }
    },
    isProportional: Ember.computed('loadDistribution', function () {
      return this.get('loadDistribution') === 'proportional';
    }),
    isWeighted: Ember.computed('loadDistribution', function () {
      return this.get('loadDistribution') === 'weighted';
    }),
    isManual: Ember.computed('loadDistribution', function () {
      return this.get('loadDistribution') === 'manual';
    })
  });
  Groupment.reopenClass({
    prefix: 'GRP'
  });
  Groupment.reopen({
    prefix: Ember.computed(function () {
      return Groupment.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Groupment;
});