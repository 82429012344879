define("boondmanager/models/appsurveysatisfactionindicator", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    surveyType: _emberData.default.belongsTo('appsurveysurveytype'),
    term: _emberData.default.attr('date'),
    evaluation: _emberData.default.attr('string'),
    value: _emberData.default.attr('number')
  });
});