define("boondmanager/models/appextractpayrollruleforadvantage", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    months: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    advantageType: (0, _attributes.fragment)('advantagetype'),
    workplaceTypes: (0, _attributes.fragmentArray)('workplacetype'),
    formula: _emberData.default.attr('string'),
    advantageTypeSelected: Ember.computed('advantageType.{reference,agency.id}', function () {
      var advantageType = this.get('advantageType');
      return "".concat(advantageType.get('agency.id'), "_").concat(advantageType.get('reference'));
    }).readOnly(),
    workplaceTypesList: Ember.computed({
      get: function get() {
        return null;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    oldAgencySelected: Ember.computed({
      get: function get() {
        var advantageType = this.get('advantageType');
        return advantageType.get('agency.id');
      },
      set: function set(key, value) {
        return value;
      }
    }),
    workplaceTypesOptions: Ember.computed('workplaceTypesList', 'advantageTypeSelected', function () {
      var options = [];
      var workplaceTypesList = this.get('workplaceTypesList');
      var advantageTypeSelected = this.get('advantageTypeSelected');
      var selectedOptions = this.get('workplaceTypesSelected');
      var key = advantageTypeSelected.split('_');
      var agencyId = key[0];
      if (workplaceTypesList && workplaceTypesList.hasOwnProperty(agencyId)) {
        workplaceTypesList[agencyId].forEach(function (workplaceType) {
          options.push({
            id: agencyId + '_' + workplaceType.reference,
            value: workplaceType.name
          });
        });
      }
      // Ajoute les options normalement non visible (ex lieu de travail supprimé)
      var _iterator = _createForOfIteratorHelper(selectedOptions),
        _step;
      try {
        var _loop = function _loop() {
          var opt = _step.value;
          if (!options.find(function (element) {
            return element.id === opt;
          })) {
            options.push({
              id: opt,
              value: opt
            });
          }
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return options;
    }).readOnly(),
    workplaceTypesSelected: Ember.computed({
      get: function get() {
        var selected = [];
        this.get('workplaceTypes').forEach(function (workplaceType) {
          selected.push("".concat(workplaceType.get('agency.id'), "_").concat(workplaceType.get('reference')));
        });
        return selected;
      },
      set: function set(key, value) {
        return value;
      }
    })
  });
});