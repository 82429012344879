define("boondmanager/models/additionalturnoverandcost", ["exports", "boondmanager/models/base", "ember-data-copyable", "ember-data", "boondmanager/utils/logger", "boondmanager/utils/financial"], function (_exports, _base, _emberDataCopyable, _emberData, _logger, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend(_emberDataCopyable.default, {
    // ATTRIBUTES
    costsExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // defaltValue() function will only be evaluated when the value is `undefned`
    // and the first time the attribute is retrieved
    date: _emberData.default.attr('date'),
    purchase: _emberData.default.attr(),
    state: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    turnoverExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    dependsOn: _emberData.default.belongsTo('base'),
    // CPs
    originalTurnoverExcludingTax: Ember.computed('turnoverExcludingTax', 'owner.{exchangeRateAgency,exchangeRate}', {
      get: function get() {
        var turnoverExcludingTax = this.get('turnoverExcludingTax');
        var exchangeRateAgency = this.get('owner.exchangeRateAgency');
        var exchangeRate = this.get('owner.exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(1 / exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(1 / exchangeRate);
        }
        return (0, _financial.applyExchangeRates)(turnoverExcludingTax, exchangeRates);
      },
      set: function set(_, newValue /*, oldValue*/) {
        var originalTurnoverExcludingTax = Number(newValue);
        if (Number.isNaN(originalTurnoverExcludingTax)) {
          _logger.default.warn('Additional Turnover and Costs Fragment - SET bad originalTurnoverExcludingTax (NaN - so we set it to 0)', newValue);
          originalTurnoverExcludingTax = 0;
        }
        var exchangeRateAgency = this.get('owner.exchangeRateAgency');
        var exchangeRate = this.get('owner.exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(exchangeRate);
        }
        this.set('turnoverExcludingTax', (0, _financial.applyExchangeRates)(originalTurnoverExcludingTax, exchangeRates));
        return originalTurnoverExcludingTax;
      }
    }),
    originalCostsExcludingTax: Ember.computed('costsExcludingTax', 'owner.{exchangeRateAgency,exchangeRate}', {
      get: function get() {
        var costsExcludingTax = this.get('costsExcludingTax');
        var exchangeRateAgency = this.get('owner.exchangeRateAgency');
        var exchangeRate = this.get('owner.exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(1 / exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(1 / exchangeRate);
        }
        return (0, _financial.applyExchangeRates)(costsExcludingTax, exchangeRates);
      },
      set: function set(_, newValue /*, oldValue*/) {
        var originalCostsExcludingTax = Number(newValue);
        if (Number.isNaN(originalCostsExcludingTax)) {
          _logger.default.warn('Additional Turnover and Costs Fragment - SET bad originalCostsExcludingTax (NaN - so we set it to 0)', newValue);
          originalCostsExcludingTax = 0;
        }
        var exchangeRateAgency = this.get('owner.exchangeRateAgency');
        var exchangeRate = this.get('owner.exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(exchangeRate);
        }
        this.set('costsExcludingTax', (0, _financial.applyExchangeRates)(originalCostsExcludingTax, exchangeRates));
        return originalCostsExcludingTax;
      }
    })
  });
});