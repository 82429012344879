define("boondmanager/pods/components/octane/bm-field/emailing/template/activity-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KuPdFyld",
    "block": "{\"symbols\":[\"&attrs\",\"@disabled\",\"@value\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-activity-content\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[1,[30,[36,1],null,[[\"value\",\"onChange\",\"options\",\"disabled\"],[[32,3],[32,0,[\"onValueChanged\"]],[32,0,[\"options\"]],[32,2]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"bm-field/tinymce\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/emailing/template/activity-content/template.hbs"
  });
});