define("boondmanager/models/notificationssettingsfragment", ["exports", "ember-data-model-fragments", "ember-data-model-fragments/attributes", "ember-data"], function (_exports, _emberDataModelFragments, _attributes, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    type: _emberData.default.attr('string'),
    email: (0, _attributes.fragment)('notificationschannelspropertiesfragment'),
    internal: (0, _attributes.fragment)('notificationschannelspropertiesfragment'),
    mobile: (0, _attributes.fragment)('notificationschannelspropertiesfragment'),
    section: '' // Pour le groupement par "catégorie" coté sur le template
  });
});