define("boondmanager/serializers/marker", ["exports", "boondmanager/serializers/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    // BACK -> FRONT
    normalize: function normalize() {
      var normalized = this._super.apply(this, arguments);
      if (normalized.data.attributes.resource === null) {
        normalized.data.attributes.resource = {
          originalID: null
        };
      }
      return normalized;
    } /*modelName, resourceHash*/,
    // FRONT -> BACK
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (json.resource && json.resource.id === null) {
        json.resource = null;
      }
      return json;
    } /*snapshot, options*/
  });
});