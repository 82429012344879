define("boondmanager/models/opportunity", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "ember-copy", "boondmanager/utils/logger", "boondmanager/models/additionalturnoverandcostsfragment", "boondmanager/models/project"], function (_exports, _base, _emberData, _emberDataCopyable, _attributes, _fragment, _emberCopy, _logger, _additionalturnoverandcostsfragment, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_WON = _exports.STATE_WAITING = _exports.STATE_REJECTED = _exports.OPPORTUNITY_DURATION_UNSPECIFIED = _exports.AT_REGULARISATION = _exports.AT_PURCHASE = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var STATE_WAITING = _exports.STATE_WAITING = 0;
  var STATE_REJECTED = _exports.STATE_REJECTED = 2;
  var STATE_WON = _exports.STATE_WON = 1;
  var OPPORTUNITY_DURATION_UNSPECIFIED = _exports.OPPORTUNITY_DURATION_UNSPECIFIED = 255;
  var AT_PURCHASE = _exports.AT_PURCHASE = 0;
  var AT_REGULARISATION = _exports.AT_REGULARISATION = 1;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var Opportunity = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'company', 'contact', 'mainManager', 'hrManager', 'pole', 'projects', 'stateReason']
    }),
    /*** ATTRIBUTES ***********************************************************/
    activityAreas: _emberData.default.attr(),
    // array - TAB_AO.AO_APPLICATIONS
    costsPositioningsExcludingTax: _emberData.default.attr('number'),
    creationDate: _emberData.default.attr('moment'),
    // TAB_AO.AO_DEPOT
    criteria: _emberData.default.attr('string'),
    // TAB_AO.AO_CRITERES
    answerDate: _emberData.default.attr('date'),
    // TAB_AO.AO_DATELIMITE
    closingDate: _emberData.default.attr('date'),
    // TAB_AO.AO_DATECLOSING
    currency: _emberData.default.attr('number'),
    // TAB_AO.AO_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_AO.AO_DEVISEAGENCE
    description: _emberData.default.attr('string'),
    // TAB_AO.AO_DESCRIPTION
    duration: _emberData.default.attr('number', {
      defaultValue: 255
    }),
    // TAB_AO.AO_DUREE
    estimatesExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // TAB_AO.AO_BUDGET
    exchangeRate: _emberData.default.attr('number'),
    // TAB_AO.AO_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_AO.AO_CHANGEAGENCE
    workUnitRate: _emberData.default.attr('number'),
    expertiseArea: _emberData.default.attr('string'),
    // TAB_AO.AO_INTERVENTION
    isVisible: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    // TAB_AO.AO_VISIBILITE
    marginPositioningsExcludingTax: _emberData.default.attr('number'),
    mode: _emberData.default.attr('number'),
    // TAB_AO.AO_TYPE
    numberOfActivePositionings: _emberData.default.attr('number'),
    place: _emberData.default.attr('string'),
    // TAB_AO.AO_LIEU
    numberOfProjects: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    //NB_PROJETS, does not necessarily correlate with the number of positionings
    profitabilityPositioningsExcludingTax: _emberData.default.attr('number'),
    // 100 * marginPositioningsExcludingTax / positioningsTurnoverExcludingTax
    reference: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    // TAB_AO.AO_REF
    startDate: _emberData.default.attr(),
    state: _emberData.default.attr('number'),
    // TAB_AO.AO_ETAT
    stateReason: (0, _attributes.fragment)('statereason'),
    title: _emberData.default.attr('string'),
    // TAB_AO.AO_TITLE
    tools: _emberData.default.attr(),
    // array - TAB_AO.AO_OUTILS
    turnoverEstimatedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // TAB_AO.AO_CA
    turnoverPositioningsExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    turnoverWeightedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    updateDate: _emberData.default.attr('string'),
    // TAB_AO.AO_DATEUPDATE
    validateSimulation: _emberData.default.attr('boolean'),
    // TAB_AO.AO_CORRELATIONPOS
    weighting: _emberData.default.attr('number', {
      defaultValue: 100
    }),
    // TAB_AO.AO_PROBA
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    additionalTurnoverAndCosts: (0, _attributes.fragmentArray)('additionalturnoverandcostsfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    origin: (0, _attributes.fragment)('origin', {
      defaultValue: function defaultValue() {
        return {
          detail: '',
          typeOf: -1
        };
      }
    }),
    positioningSuggestFilters: (0, _attributes.fragmentArray)('suggestfilterfragment'),
    //right
    canReadOpportunity: _emberData.default.attr('boolean'),
    canWriteOpportunity: _emberData.default.attr('boolean'),
    // Droit d'écriture ( par procuration de suppresion)
    canReadContact: _emberData.default.attr('boolean'),
    // Droit de lecture contact
    canReadCompany: _emberData.default.attr('boolean'),
    canShowContact: _emberData.default.attr('boolean'),
    // Droit d'affichage
    canShowCompany: _emberData.default.attr('boolean'),
    // Droit d'affichage

    /*** RELATIONSHIPS ********************************************************/
    agency: _emberData.default.belongsTo(),
    company: _emberData.default.belongsTo(),
    contact: _emberData.default.belongsTo(),
    files: _emberData.default.hasMany(),
    mainManager: _emberData.default.belongsTo('resource'),
    hrManager: _emberData.default.belongsTo('resource'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    pole: _emberData.default.belongsTo(),
    projects: _emberData.default.hasMany(),
    // Opportunity Actions (previous & next)
    previousAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    nextAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    /**
     * Rechargement de la dropzone pour la demande de signature électronique
     */
    reloadFiles: false,
    /*** CP *******************************************************************/
    /**
     * Translated `duration` value from `duration` attribut ID
     *
     * @uses duration
     *
     * @property durationValue
     * @type {*}
     */
    durationValue: Ember.computed('duration', 'i18n._locale', function () {
      var id = this.get('duration');
      var item = this.get('settingsService').customer.duration.find(function (item) {
        return item.id === id;
      });
      return item ? item.value : this.get('i18n').t('common:indifferent');
    }),
    placeValue: Ember.computed('place', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      var id = this.get('place');
      var place;
      if (typeof id !== 'undefined') {
        this.get('settingsService').customer.mobilityArea.find(function (item) {
          var placeToSearch = item.option.find(function (subItem) {
            return subItem.id === id;
          });
          if (typeof placeToSearch !== 'undefined') {
            place = placeToSearch;
          }
        });
      }
      return place ? place.value : i18n.t('common:indifferent');
    }),
    /**
     * Sum of all additional turnovers (manual sum instead of Ember.computed.sum to allow undefined filtering)
     *
     * @property additionalTurnoverTotal
     * @type {Number}
     */
    additionalTurnoverTotal: Ember.computed('additionalTurnoverAndCosts.@each.turnoverExcludingTax', function () {
      var additionalTurnoverAndCosts = this.get('additionalTurnoverAndCosts').mapBy('turnoverExcludingTax').filter(function (item) {
        return item;
      });
      if (additionalTurnoverAndCosts.length) {
        return additionalTurnoverAndCosts.reduce(function (prevItem, item) {
          return prevItem + item;
        });
      }
      return additionalTurnoverAndCosts;
    }),
    /**
     * Sum of all additional costs (manual sum instead of Ember.computed.sum to allow undefined filtering)
     *
     * @property additionalCostsTotal
     * @type {Number}
     */
    additionalCostsTotal: Ember.computed('additionalTurnoverAndCosts.@each.costsExcludingTax', function () {
      var additionalTurnoverAndCosts = this.get('additionalTurnoverAndCosts').mapBy('costsExcludingTax').filter(function (item) {
        return item;
      });
      if (additionalTurnoverAndCosts.length) {
        return additionalTurnoverAndCosts.reduce(function (prevItem, item) {
          return prevItem + item;
        });
      }
      return additionalTurnoverAndCosts;
    }),
    entityName: Ember.computed('title', function () {
      var name = this.get('title');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }),
    startImmediate: Ember.computed('startDate', function () {
      return this.get('startDate') === 'immediate';
    }),
    stateReasonLabel: Ember.computed('state', function () {
      var _this = this;
      var currentState = this.get('settingsService').customer.state.opportunity.find(function (item) {
        return item.id === _this.get('state');
      });
      var foundState = currentState.reason.find(function (item) {
        return item.id === _this.get('stateReason.typeOf');
      });
      return foundState ? foundState.value : '';
    }),
    isTA: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_TA;
    }),
    isPackage: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_PACKAGE;
    }),
    isRecruitment: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_RECRUITMENT;
    }),
    isProduct: Ember.computed('mode', function () {
      return this.get('mode') === _project.TYPE_PRODUCT;
    }),
    isActivityTypeInWorkUnit: Ember.computed('typeOf', 'i18n._locale', function () {
      var _this2 = this;
      var typeOf = this.get('settingsService').customer.typeOf.project.find(function (item) {
        return item.id === _this2.get('typeOf');
      });
      return typeOf && typeOf.activityType === _project.ACTIVITY_WORKUNIT;
    }).readOnly(),
    isWon: Ember.computed('state', function () {
      return this.get('state') === 1;
    }),
    provider: Ember.computed('contact', 'company', function () {
      var providerPromises = {
        contact: this.get('contact'),
        company: this.get('company')
      };
      var promise = new Ember.RSVP.hash(providerPromises, "get provider contact and company").then(function (_ref) {
        var contact = _ref.contact,
          company = _ref.company;
        return {
          id: contact ? contact.get('id') : null,
          entityName: contact ? contact.get('entityName') : null,
          // for bm-field-crm. CAUTION: contact could be null
          completeName: contact ? contact.get('completeName') : null,
          // for bm-client. CAUTION: contact could be null
          company: company
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    toolsLabels: Ember.computed('tools', 'i18n._locale', function () {
      var itemsIDs = this.get('tools');
      var tools = this.get('settingsService').customer.tool;
      var result = itemsIDs ? itemsIDs.map(function (toolID) {
        var tool = tools.findBy('id', toolID);
        return tool ? tool.value : toolID;
      }) : [];
      return result;
    }),
    activityAreaLabels: Ember.computed('activityAreas', 'i18n._locale', function () {
      var activityIDs = this.get('activityAreas');
      var activityAreasLabels = this.get('settingsService').customer.activityArea;

      // ce n'est certainement pas la manière la plus rapide de le faire mais le code reste clair :)
      var activities = activityIDs ? activityAreasLabels.map(function (category) {
        // not need first level (= category) so flatten to second level (= category.option)
        // => return an array of activity objects' arrays
        if (category.option && category.option.length) {
          return category.option;
        } else {
          return [];
        }
      }).reduce(function (prev, curr) {
        return prev.concat(curr);
      }) : [];
      return activityIDs.map(function (activityID) {
        var activity = activities.findBy('id', activityID);
        return activity ? activity.value : activityID;
      });
    }),
    // METHODS
    // - Manage additionalTurnoverAndCosts fragments
    addTurnoverAndCosts: function addTurnoverAndCosts(turnoverAndCosts) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var additionalTurnoverAndCosts = this.get('additionalTurnoverAndCosts');
      switch (_typeof(turnoverAndCosts)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(turnoverAndCosts)) {
              additionalTurnoverAndCosts.addFragment(makeAcopy ? (0, _emberCopy.copy)(turnoverAndCosts) : turnoverAndCosts);
            } else {
              additionalTurnoverAndCosts.createFragment(turnoverAndCosts);
            }
            break;
          }
        default:
          {
            // get last additional turnover and cost typeof if exists
            var defaultTypeOf = _additionalturnoverandcostsfragment.INDIFFERENT_TYPEOF_ADDITIONAL_TURNOVER;
            if (additionalTurnoverAndCosts && additionalTurnoverAndCosts.lastObject) {
              defaultTypeOf = additionalTurnoverAndCosts.lastObject.typeOf;
            }
            additionalTurnoverAndCosts.createFragment({
              typeOf: defaultTypeOf
            });
          }
      }
    },
    delTurnoverAndCosts: function delTurnoverAndCosts(turnoverAndCosts) {
      if ((0, _fragment.isFragment)(turnoverAndCosts)) {
        this.get('additionalTurnoverAndCosts').removeFragment(turnoverAndCosts);
      } else {
        _logger.default.warn("Project#".concat(this.get('id'), ":delTurnoverAndCosts() - turnoverAndCosts is not a fragment !"), turnoverAndCosts);
      }
    }
  });
  Opportunity.reopenClass({
    prefix: 'AO'
  });
  Opportunity.reopen({
    prefix: Ember.computed(function () {
      return Opportunity.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Opportunity;
});