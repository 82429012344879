define("boondmanager/pods/components/bm-field/power-select/group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZeCqrZB5",
    "block": "{\"symbols\":[\"@group\",\"&default\"],\"statements\":[[10,\"li\"],[14,0,\"ember-power-select-group\"],[15,\"aria-disabled\",[30,[36,0],[[32,1,[\"disabled\"]],\"true\"],null]],[14,\"role\",\"option\"],[12],[2,\"\\n  \"],[11,\"span\"],[16,0,[32,0,[\"groupClass\"]]],[24,\"role\",\"checkbox\"],[16,\"aria-checked\",[30,[36,0],[[32,0,[\"isGroupSelected\"]],\"true\"],null]],[4,[38,1],[\"click\",[32,0,[\"toggleOptions\"]]],null],[12],[2,\"\\n  \\t\"],[1,[32,1,[\"groupName\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/group/template.hbs"
  });
});