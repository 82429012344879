define("boondmanager/models/inactivity", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable", "moment"], function (_exports, _base, _emberData, _attributes, _emberDataCopyable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var Inactivity = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['resource', 'contract']
    }),
    // ATTRIBUTES
    averageDailyContractCost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    averageDailyCost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    averageDailyPriceExcludingTax: _emberData.default.attr('number'),
    averageHourlyPriceExcludingTax: _emberData.default.attr('number'),
    conditions: _emberData.default.attr('string'),
    costsProductionExcludingTax: _emberData.default.attr('number'),
    costsSimulatedExcludingTax: _emberData.default.attr('number'),
    dailyExpenses: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    endDate: _emberData.default.attr('date'),
    expensesProduction: _emberData.default.attr('number'),
    expensesSimulated: _emberData.default.attr('number'),
    inactivityType: _emberData.default.attr('string'),
    informationComments: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    monthlyExpenses: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfDaysInvoicedOrQuantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfWorkingDays: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    occupationRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    regularTimesProduction: _emberData.default.attr('number'),
    regularTimesSimulated: _emberData.default.attr('number'),
    startDate: _emberData.default.attr('date'),
    state: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    turnoverProductionExcludingTax: _emberData.default.attr('number'),
    weeklyWorkingHours: _emberData.default.attr('number'),
    canReadInactivity: _emberData.default.attr('boolean'),
    canWriteInactivity: _emberData.default.attr('boolean'),
    currency: _emberData.default.attr('number'),
    currencyAgency: _emberData.default.attr('number'),
    exchangeRate: _emberData.default.attr('number'),
    exchangeRateAgency: _emberData.default.attr('number'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    canShowCurrency: _emberData.default.attr('boolean'),
    canShowCurrencyAgency: _emberData.default.attr('boolean'),
    canShowExchangeRate: _emberData.default.attr('boolean'),
    canShowExchangeRateAgency: _emberData.default.attr('boolean'),
    canShowCostsSimulatedExcludingTax: _emberData.default.attr('boolean'),
    // FRAGMENTS
    expensesDetails: (0, _attributes.fragmentArray)('expensedetail', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // RELATIONSHIPS
    contract: _emberData.default.belongsTo(),
    resource: _emberData.default.belongsTo(),
    files: _emberData.default.hasMany('file'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    reportingClass: Ember.computed('inactivityType', function () {
      var promise = Ember.RSVP.Promise.resolve(this.get('inactivityType'));
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    lengthInDays: Ember.computed('startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate') || (0, _moment.default)();
      return _moment.default.duration(endDate.diff(startDate)).asDays();
    }),
    entityName: Ember.computed('title', 'id', function () {
      if (this.get('title')) {
        return this.get('title');
      } else {
        return this.get('prefix') + this.get('id');
      }
    }),
    reportingTooltip: Ember.computed('startDate', 'endDate', 'occupationRate', function () {
      var i18n = this.get('i18n');
      var startLabel = i18n.t('models:inactivity.attributes.startDate.name');
      var endLabel = i18n.t('models:inactivity.attributes.endDate.abbr');
      var occupationRate = i18n.t('models:delivery.attributes.occupationRate.abbr');
      return "\n\t\t\t<dl class=\"periodInfoTooltip\">\n\t\t\t\t<dt>".concat(startLabel, " - ").concat(endLabel, " </dt>\n\t\t\t\t<dd>").concat(this.get('startDate').format('DD/MM/YY'), " - ").concat(this.get('endDate').format('DD/MM/YY'), "</dd>\n\t\t\t\t<dt>").concat(occupationRate, "</dt>\n\t\t\t\t<dd>").concat(Math.round(this.get('occupationRate')), "%</dd>\n\t\t\t</dl>");
    }),
    averageDailyCostCalculated: Ember.computed('averageDailyContractCost', 'dailyExpensesCalculated', 'monthlyExpensesCalculated', 'numberOfWorkingDays', function () {
      var contractCost = this.get('averageDailyContractCost');
      var dailyExpensesCalculated = this.get('dailyExpensesCalculated');
      var monthlyExpensesCalculated = this.get('monthlyExpensesCalculated');
      var numberOfWorkingDays = this.get('numberOfWorkingDays');
      return numberOfWorkingDays ? contractCost + dailyExpensesCalculated + monthlyExpensesCalculated * 12 / numberOfWorkingDays : 0;
    }),
    costsSimulatedExcludingTaxCalculated: Ember.computed('averageDailyCostCalculated', 'numberOfDaysInvoicedOrQuantity', 'dailyExpensesCalculated', 'monthlyExpensesCalculated', 'numberOfWorkingDays', function () {
      var averageDailyCost = this.get('averageDailyCostCalculated');
      var numberOfDaysInvoicedOrQuantity = this.get('numberOfDaysInvoicedOrQuantity');
      return averageDailyCost * numberOfDaysInvoicedOrQuantity;
    }),
    dailyExpensesCalculated: Ember.computed('expensesDetails.[]', 'dailyExpenses', 'expensesDetails.@each.{periodicity,netAmount}', function () {
      if (this.get('expensesDetails') && this.get('expensesDetails').length) {
        return this.get('expensesDetails').reduce(function (daily, expenseDetail) {
          return expenseDetail.periodicity === 'daily' ? daily + parseFloat(expenseDetail.netAmount) : daily;
        }, 0);
      } else {
        return this.get('dailyExpenses');
      }
    }),
    monthlyExpensesCalculated: Ember.computed('expensesDetails.[]', 'monthlyExpenses', 'expensesDetails.@each.{periodicity,netAmount}', function () {
      if (this.get('expensesDetails') && this.get('expensesDetails').length) {
        return this.get('expensesDetails').reduce(function (monthly, expenseDetail) {
          return expenseDetail.periodicity === 'monthly' ? monthly + parseFloat(expenseDetail.netAmount) : monthly;
        }, 0);
      } else {
        return this.get('monthlyExpenses');
      }
    })
  });
  Inactivity.reopenClass({
    prefix: 'INA'
  });
  Inactivity.reopen({
    prefix: Ember.computed(function () {
      return Inactivity.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Inactivity;
});