define("boondmanager/pods/components/bm-field/power-select/vertical-collection-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8GR62b7j",
    "block": "{\"symbols\":[\"opt\",\"index\",\"&default\"],\"statements\":[[6,[37,7],[[35,0,[\"loading\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--loading-message\"],[14,\"role\",\"option\"],[12],[1,[34,6]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],[[35,8]],[[\"estimateHeight\",\"firstReached\",\"lastReached\"],[38,[30,[36,4],[[32,0],\"firstReached\"],null],[30,[36,4],[[32,0],\"lastReached\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[11,\"li\"],[24,0,\"ember-power-select-option\"],[16,\"aria-selected\",[31,[[30,[36,1],[[32,1],[35,0,[\"selected\"]]],null]]]],[16,\"aria-disabled\",[32,1,[\"disabled\"]]],[16,\"aria-current\",[31,[[30,[36,2],[[32,1],[35,0,[\"highlighted\"]]],null]]]],[16,\"data-option-index\",[31,[[34,3],[32,2]]]],[24,\"role\",\"option\"],[4,[38,5],[\"click\",[30,[36,4],[[32,0],\"onClick\",[32,1],[35,0]],null]],null],[12],[2,\"\\n\\t\\t\"],[18,3,[[32,1],[35,0]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"select\",\"ember-power-select-is-selected\",\"eq\",\"groupIndex\",\"action\",\"on\",\"loadingMessage\",\"if\",\"options\",\"vertical-collection\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/vertical-collection-options/template.hbs"
  });
});