define("boondmanager/pods/components/bm-field/tinymce/thread/component", ["exports", "boondmanager/pods/components/bm-field/tinymce/component", "jquery", "boondmanager/config/environment"], function (_exports, _component, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component BM Field Textarea for Mentions
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-tinymce-one-line', 'bmc-field-tinymce-thread'],
    height: '35',
    store: Ember.inject.service(),
    mentionsAvailables: null,
    instanceDestroyed: false,
    maxResults: 10,
    /**
     * Basic options to instanciate tinymce when needed
     */
    options: Ember.computed('elementId', function () {
      var _this = this;
      var self = this;
      return {
        toolbar: false,
        autoresize_bottom_margin: 0,
        body_class: 'mce-content-body-oneline mce-content-body-thread',
        height: this.get('height'),
        valid_elements: 'span[class|data-id],br,#p,#div',
        entity_encoding: 'raw',
        plugins: 'autoresize noneditable',
        noneditable_noneditable_class: 'mceNonEditable',
        auto_focus: this.get('getElementId'),
        newline_behavior: 'default',
        setup: function setup(editor) {
          editor.on('focusIn', function () {
            self.bmFocusIn();
          });
          editor.on('KeyDown', function (e) {
            // Ajoute le div englobant si nouveau contenu.
            // ne peut être fait dans init , sinon on perd le placeholder
            if (editor.getContent() === '' && e.code !== 'Backspace' && e.code !== 'Delete') {
              editor.setContent('<div></div>');
            }
            if (['Escape'].includes(e.key)) {
              var event = new CustomEvent('tinymceEscapeKeyDown');
              window.dispatchEvent(event);
            }
          });
          editor.on('SkinLoaded', function () {
            // Si, lors d'une connection lente, l'utilisateur a supprimé tout le contenu avant que le tinymce soit chargé (caractère par caractère)
            // alors la balise par défaut qui apparait lors d'un contenu vide n'est pas dans le DOM. On l'ajoute donc manuellement afin d'éviter un bug
            // d'affichage qui rend le champ minuscule en hauteur (cf BM-14554)
            if (editor.getContent() === '') {
              editor.setContent('<div><br data-mce-bogus="1"></div>');
            } else {
              // Hack pour prévenir le cas d'une connection lente où l'utilisateur aurait saisi du contenu alors que le skin de
              // tinymce n'est pas encore chargé. Ainsi, le contenu est envoyé malgré tout au composant/controller afin de
              // déclencher l'activation du bouton envoyer par exemple
              _this.send('onValueChanged', editor.getContent());
            }
          });
          editor.on('keyUp', function () {
            if (!self.get('instanceDestroyed')) _this.send('onValueChanged', editor.getContent());
          });
          editor.on('Change', function () {
            if (!self.get('instanceDestroyed')) _this.send('onValueChanged', editor.getContent());
          });
          editor.on('focus', function () {
            (0, _jquery.default)("#".concat(_this.get('elementId'))).find('.tox-tinymce').addClass('bm-focus');
            // create custom event on focus
            var event = new CustomEvent('tinymceFocus');
            window.dispatchEvent(event);
            // récupération du dernier élément DOM du contenu du textarea
            editor.selection.select(editor.getBody(), true);
            editor.selection.collapse(false);
          });
          editor.on('blur', function () {
            // create custom event on focus-out
            (0, _jquery.default)('.tox-tinymce').removeClass('bm-focus');
          });
          var onAction = function onAction(autocompleteApi, rng, value) {
            editor.selection.setRng(rng);
            // ajout d'un espace en fin de sélection d'une mention
            editor.insertContent(value + ' ');
            autocompleteApi.hide();
          };
          var getMatchedChars = function getMatchedChars(pattern) {
            var mentions = [];
            if (self.mentionsAvailables) {
              mentions = self.mentionsAvailables.map(function (mention) {
                return {
                  firstName: mention.firstName.toLowerCase(),
                  lastName: mention.lastName.toLowerCase(),
                  text: mention.get('completeName'),
                  thumbnail: mention.thumbnail,
                  reference: 'COMP' + mention.id
                };
              });
            }
            return mentions.filter(function (char) {
              return char.firstName.indexOf(pattern.toLowerCase()) == 0 || char.lastName.indexOf(pattern.toLowerCase()) == 0;
            });
          };

          /* An autocompleter that allows you to insert special characters */
          editor.ui.registry.addAutocompleter('specialchars', {
            ch: '@',
            minChars: 0,
            columns: 1,
            onAction: onAction,
            fetch: function fetch(pattern) {
              return new window.tinymce.util.Promise(function (resolve) {
                var results = getMatchedChars(pattern).map(function (char) {
                  var thumbnail = {
                    type: 'cardimage',
                    src: _environment.default.rootURL + 'img/bmi-user-circle.svg',
                    alt: '',
                    classes: ['bmc-comment_profile-cardimage']
                  };
                  if (char.thumbnail) {
                    thumbnail = {
                      type: 'cardimage',
                      src: _environment.default.apiURL + '/api/thumbnails/' + char.thumbnail,
                      alt: char.text,
                      classes: ['bmc-comment_profile-cardimage']
                    };
                  }
                  return {
                    type: 'cardmenuitem',
                    label: char.value,
                    value: "<span class=\"mceNonEditable mentionEditThread\" data-id=\"".concat(char.reference, "\">@").concat(char.text, "</span>"),
                    items: [{
                      type: 'cardcontainer',
                      direction: 'vertical',
                      align: 'left',
                      valign: 'middle',
                      items: [{
                        type: 'cardtext',
                        text: char.text,
                        classes: ['bmc-comment_profile-cardtext']
                      }, thumbnail]
                    }]
                  };
                });
                // la propriété maxResults de autocompleter de tinymce n'a aucun effet (bug), donc ici on passe par
                // un array slice à la main pour palier à ce bug
                resolve(results.slice(0, self.maxResults));
              });
            }
          });
        }
      };
    }).readOnly(),
    didRender: function didRender() {
      this._setEditorMode();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._setEditorMode();

      // permet d'éviter de conserver un ancien contenu lorsque les paramètres passés au composant ont changé
      var value = typeof this.get('value') !== 'undefined' ? this.get('value') : '';
      var editor = this._getEditor();
      if (editor && value !== this.get('oldValue')) {
        editor.setContent(value);
        this.set('oldValue', value);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.set('instanceDestroyed', true);
    },
    /**
     * Récupération de l'éditeur tinyMCE courant
     * @return {Editor|undefined}
     * @private
     */
    _getEditor: function _getEditor() {
      return typeof window.tinymce !== 'undefined' ? window.tinymce.get(this.get('getElementId')) : undefined;
    },
    /**
     * Mise à jour du mode de l'éditeur (disabled ou non)
     * @private
     */
    _setEditorMode: function _setEditorMode() {
      // mise à jour de l'état du tinyMCE dans le cas où on le passerait disabled depuis l'extérieur (envoi de la mise à jour
      // ou création d'une nouvelle réponse / thread)
      var disabled = typeof this.get('disabled') !== 'undefined' ? this.get('disabled') : false;
      var editor = this._getEditor();
      if (editor) {
        if (disabled) editor.mode.set('readonly');else editor.mode.set('design');
      }
    },
    actions: {
      onValueChanged: function onValueChanged(value) {
        this.set('value', value);
        this.set('oldValue', value);
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange(value);
        }
      }
    }
  });
});