define("boondmanager/models/currentuser", ["exports", "boondmanager/models/resource", "ember-data"], function (_exports, _resource, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CurrentUser = _resource.default.extend({
    customer: _emberData.default.belongsTo()
  });
  CurrentUser.reopenClass({
    prefix: 'USER'
  });
  CurrentUser.reopen({
    prefix: Ember.computed(function () {
      return CurrentUser.prefix;
    }).readOnly()
  });
  var _default = _exports.default = CurrentUser;
});