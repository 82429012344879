define("boondmanager/models/appsaaseditorscorecardfragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    reference: _emberData.default.attr('string'),
    typeOf: _emberData.default.attr('string'),
    currentUser: Ember.inject.service('current-user'),
    settingsService: Ember.inject.service('settings'),
    unit: Ember.computed('typeOf', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      switch (this.get('typeOf')) {
        case 'month':
          return i18n.t('saaseditor:reporting.months.title_plural');
        case 'money':
          {
            var currency = this.get('currentUser.agency.currency');
            return this.get('settingsService').customer.currency.findBy('id', currency)['symbol'];
          }
        case 'percentage':
          return '%';
        default:
          return '';
      }
    })
  });
});