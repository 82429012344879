define("boondmanager/models/paymentfragment", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes", "boondmanager/models/payment"], function (_exports, _emberData, _emberDataModelFragments, _attributes, _payment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Payment fragment in a provider invoice
   * Allow the creation of a payment from the provider invoice entity
   */
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    state: _emberData.default.attr('number'),
    amountExcludingTax: _emberData.default.attr('number'),
    number: _emberData.default.attr('string'),
    expectedDate: _emberData.default.attr('date'),
    purchase: (0, _attributes.fragment)('purchasefragment'),
    canReadPayment: _emberData.default.attr('boolean'),
    internalReference: Ember.computed('originalID', function () {
      return !this.get('isNew') ? "".concat(_payment.default.prefix).concat(this.get('originalID')) : '';
    }).readOnly(),
    entityName: Ember.computed('number', function () {
      var number = this.get('number');
      if (Ember.isBlank(number)) {
        return this.get('internalReference');
      } else {
        return number;
      }
    })
  });
});