define("boondmanager/pods/components/octane/bm-field/parent-company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UsEcLNi7",
    "block": "{\"symbols\":[\"@company\",\"@disabled\",\"@onChange\",\"@name\",\"@currentCompanyId\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-parent-company\"],[17,6],[4,[38,6],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"noParentCompany\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],null,[[\"noTitle\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,\"data-name\",\"button-addParentCompany\"],[24,0,\"bmb-link\"],[16,\"aria-label\",[30,[36,3],[\"components:bmFieldParentCompany.addParentCompany\"],null]]],[[\"@onClick\"],[[32,0,[\"showParentCompany\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,5],[\"bmi-add\"],null]],[2,\" \"],[1,[30,[36,3],[\"components:bmFieldParentCompany.addParentCompany\"],null]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,4],null,[[\"inlineContent\",\"name\"],[true,[30,[36,3],[\"models:company.attributes.parentCompany.name\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"stateClass\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"octane/bm-sticker\",[[16,\"data-name\",[30,[36,0],[\"sticker-\",[32,1,[\"stateClass\"]]],null]]],[[\"@smallSticker\",\"@sticker\"],[true,[32,1,[\"stateClass\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\"],[1,[30,[36,2],null,[[\"selected\",\"currentCompanyId\",\"name\",\"class\",\"onChange\",\"disabled\",\"allowClear\",\"showLock\"],[[32,1],[32,5],[32,4],\"parent-company\",[32,3],[32,2],true,true]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"bm-field/company\",\"t\",\"bm-field\",\"icon\",\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/parent-company/template.hbs"
  });
});