define("boondmanager/models/recipient", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Recipient - used in share and groups
   * Either typeOf or resource (or group in share) is defined
   */
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    group: (0, _attributes.fragment)('recipient-group'),
    originalID: _emberData.default.attr('string'),
    resource: (0, _attributes.fragment)('recipient-resource'),
    agency: (0, _attributes.fragment)('recipient-agency'),
    typeOf: _emberData.default.attr('number'),
    value: Ember.computed('resource', 'typeOf', function () {
      return this.get('typeOf') ? this.get('typeOf') : this.get('resource');
    })
  });
});