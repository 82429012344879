define("boondmanager/models/appadvancedcandidatescandidate", ["exports", "ember-data", "boondmanager/models/candidate"], function (_exports, _emberData, _candidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppAdvancedCandidatesCandidate = _candidate.default.extend({
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'candidate',
    wishes: _emberData.default.attr('string'),
    applicationReason: _emberData.default.attr('string'),
    employmentSearch: _emberData.default.attr('string'),
    actions: _emberData.default.hasMany({
      inverse: 'dependsOn'
    }),
    lastInterview: _emberData.default.belongsTo('action', {
      inverse: 'dependsOn'
    })
  });
  var _default = _exports.default = AppAdvancedCandidatesCandidate;
});