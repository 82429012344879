define("boondmanager/pods/components/bm-field/rate-per-kilometer-type/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-rate-per-kilometer-type'],
    attributeBindings: ['dataName:data-name'],
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    actions: {
      customOnChange: function customOnChange(selected) {
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange(selected);
        }
      }
    }
  });
});