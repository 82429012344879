define("boondmanager/pods/components/bm-field/roletemplate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MOxjOTzU",
    "block": "{\"symbols\":[\"template\"],\"statements\":[[6,[37,10],null,[[\"name\",\"options\",\"selected\",\"disabled\",\"onchange\",\"renderInPlace\",\"required\",\"useObjects\",\"placeholder\",\"allowClear\",\"valueKeyname\",\"idKeyname\"],[[35,9],[35,8],[35,7],[35,6],[30,[36,5],[[32,0],\"onchange\"],null],[35,4],[35,3],true,[30,[36,2],[\"common:none\"],null],[35,1],\"name\",\"internalReference\"]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"nameTranslated\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"nameTranslated\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[1,[30,[36,12],null,[[\"errors\"],[[35,11]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"allowClear\",\"t\",\"required\",\"renderInPlace\",\"action\",\"disabled\",\"selected\",\"templates\",\"name\",\"bm-field/power-select\",\"errors\",\"bm-field/error\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/roletemplate/template.hbs"
  });
});