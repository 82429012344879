define("boondmanager/models/appsepainvoice", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_UNPAID = _exports.STATE_PROFORMA = _exports.STATE_PAID = _exports.STATE_CREATION = void 0;
  var STATE_PROFORMA = _exports.STATE_PROFORMA = 10;
  var STATE_CREATION = _exports.STATE_CREATION = 0;
  var STATE_UNPAID = _exports.STATE_UNPAID = 2;
  var STATE_PAID = _exports.STATE_PAID = 3;
  var AppsSepaInvoice = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['order']
    }),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'invoice',
    // Attributes
    currency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_DEVISEAGENCE
    exchangeRate: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_CHANGEAGENCE
    expectedPaymentDate: _emberData.default.attr('date'),
    // TAB_FACTURATION.FACT_DATEREGLEMENTATTENDUE
    isCreditNote: _emberData.default.attr('boolean'),
    paymentMethod: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_TYPEPAYMENT
    reference: _emberData.default.attr('string'),
    // TAB_FACTURATION.FACT_REF
    state: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_ETAT
    turnoverInvoicedExcludingTax: _emberData.default.attr('number'),
    turnoverInvoicedIncludingTax: _emberData.default.attr('number'),
    closed: _emberData.default.attr('boolean'),
    // Relationships
    order: _emberData.default.belongsTo('appsepaorder'),
    entityName: Ember.computed('reference', function () {
      var reference = this.get('reference');
      if (Ember.isBlank(reference)) {
        return this.get('internalReference');
      } else {
        return reference;
      }
    })
  });
  AppsSepaInvoice.reopenClass({
    prefix: 'FACT'
  });
  AppsSepaInvoice.reopen({
    prefix: Ember.computed(function () {
      return AppsSepaInvoice.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsSepaInvoice;
});