define("boondmanager/models/appsepacontract", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable"], function (_exports, _emberData, _base, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsSepaContract = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'dependsOn', 'expensesReports', 'agencyBankDetail']
    }),
    sepaTerm: _emberData.default.attr('date'),
    currency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_DEVISEAGENCE
    endDate: _emberData.default.attr('date'),
    // TAB_CONTRAT.CTR_FIN
    exchangeRate: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_CHANGEAGENCE
    monthlySalary: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_SALAIREMENSUEL
    expensesToPay: _emberData.default.attr('number'),
    advantagesToPay: _emberData.default.attr('number'),
    expensesAlreadyAdvanced: _emberData.default.attr('number'),
    bankDetail: _emberData.default.attr(),
    transferSalary: _emberData.default.attr('number'),
    transferExpenses: _emberData.default.attr('number'),
    transferAdvantages: _emberData.default.attr('number'),
    startDate: _emberData.default.attr('date'),
    // TAB_CONTRAT.CTR_DEBUT
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    agency: _emberData.default.belongsTo('appsepaagency'),
    dependsOn: _emberData.default.belongsTo('profile', {
      inverse: null
    }),
    expensesReports: _emberData.default.hasMany('expensesreport'),
    agencyBankDetail: _emberData.default.belongsTo('bankdetail', {
      inverse: null
    }),
    parentContract: _emberData.default.belongsTo('contract', {
      inverse: null
    }),
    contractId: Ember.computed('id', function () {
      var tabId = this.get('id').split('_');
      return tabId[0];
    }),
    internalReference: Ember.computed('contractId', function () {
      return !this.get('isNew') ? "".concat(this.get('prefix')).concat(this.get('contractId')) : '';
    }),
    /**
     * Tests if a contract has a parent (related) contract
     * @return bool
     */
    isAmendment: Ember.computed('parentContract', function () {
      var parentId = this.get('parentContract.id');
      return typeof parentId !== 'undefined' && parentId !== '0';
    })
  });
  AppsSepaContract.reopenClass({
    prefix: 'CTR'
  });
  AppsSepaContract.reopen({
    prefix: Ember.computed(function () {
      return AppsSepaContract.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsSepaContract;
});