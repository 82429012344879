define("boondmanager/models/appaccountingpayrollinvoice", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_UNPAID = _exports.STATE_PROFORMA = _exports.STATE_PAID = _exports.STATE_CREATION = void 0;
  var STATE_PROFORMA = _exports.STATE_PROFORMA = 10;
  var STATE_CREATION = _exports.STATE_CREATION = 0;
  var STATE_UNPAID = _exports.STATE_UNPAID = 2;
  var STATE_PAID = _exports.STATE_PAID = 3;
  var AppAccountingPayrollInvoice = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['order', 'schedule']
    }),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'invoice',
    // Attributes
    creationDate: _emberData.default.attr('date'),
    // TAB_FACTURATION.FACT_DATE
    creditNote: _emberData.default.attr('boolean'),
    // TAB_FACTURATION.FACT_AVOIR
    currency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_DEVISEAGENCE
    date: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    exchangeRate: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_CHANGEAGENCE
    expectedPaymentDate: _emberData.default.attr('date'),
    // TAB_FACTURATION.FACT_DATEREGLEMENTATTENDUE
    isCreditNote: _emberData.default.attr('boolean'),
    paymentMethod: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_TYPEPAYMENT
    performedPaymentDate: _emberData.default.attr('date'),
    reference: _emberData.default.attr('string'),
    // TAB_FACTURATION.FACT_REF
    startDate: _emberData.default.attr('date'),
    state: _emberData.default.attr('number'),
    // TAB_FACTURATION.FACT_ETAT
    turnoverInvoicedExcludingTax: _emberData.default.attr('number'),
    turnoverInvoicedIncludingTax: _emberData.default.attr('number'),
    // Relationships
    order: _emberData.default.belongsTo(),
    schedule: _emberData.default.belongsTo(),
    isProForma: Ember.computed.equal('state', STATE_PROFORMA),
    isCreation: Ember.computed.equal('state', STATE_CREATION),
    isPaid: Ember.computed.equal('state', STATE_PAID),
    entityName: Ember.computed('reference', function () {
      var reference = this.get('reference');
      if (Ember.isBlank(reference)) {
        return this.get('internalReference');
      } else {
        return reference;
      }
    })
  });
  AppAccountingPayrollInvoice.reopenClass({
    prefix: 'FACT'
  });
  AppAccountingPayrollInvoice.reopen({
    prefix: Ember.computed(function () {
      return AppAccountingPayrollInvoice.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppAccountingPayrollInvoice;
});