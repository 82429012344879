define("boondmanager/models/appdoctemplatestemplate", ["exports", "boondmanager/models/file", "ember-data"], function (_exports, _file, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPE_TD = _exports.TYPE_RESOURCE_TD = _exports.TYPE_PURCHASE = _exports.TYPE_ORDER = _exports.TYPE_OPPORTUNITY = _exports.TYPE_DELIVERY_ORDER = _exports.TYPE_DELIVERY = _exports.TYPE_CONTRACT = _exports.TYPE_CANDIDATE_TD = _exports.TYPE_ADMINISTRATIVE = void 0;
  var TYPE_TD = _exports.TYPE_TD = 'technical-data';
  var TYPE_RESOURCE_TD = _exports.TYPE_RESOURCE_TD = 'resource';
  var TYPE_CANDIDATE_TD = _exports.TYPE_CANDIDATE_TD = 'candidate';
  var TYPE_CONTRACT = _exports.TYPE_CONTRACT = 'contract';
  var TYPE_ADMINISTRATIVE = _exports.TYPE_ADMINISTRATIVE = 'administrative';
  var TYPE_OPPORTUNITY = _exports.TYPE_OPPORTUNITY = 'opportunity';
  var TYPE_DELIVERY = _exports.TYPE_DELIVERY = 'delivery';
  var TYPE_DELIVERY_ORDER = _exports.TYPE_DELIVERY_ORDER = 'deliveryOrder';
  var TYPE_ORDER = _exports.TYPE_ORDER = 'order';
  var TYPE_PURCHASE = _exports.TYPE_PURCHASE = 'purchase';
  var Doc = _file.default.extend({
    name: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    type: _emberData.default.attr('string')
  });
  Doc.reopenClass({
    prefix: 'DOC'
  });
  Doc.reopen({
    prefix: Ember.computed(function () {
      return Doc.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Doc;
});