define("boondmanager/serializers/weekendandbankholidays", ["exports", "boondmanager/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data && payload.data.length) {
        payload.data.forEach(function (item) {
          item.id = '';
          item.type = 'weekendandbankholiday';
        });
      }
      return this._super(store, primaryModelClass, payload.data, id, requestType);
    }
  });
});