define("boondmanager/pods/components/bm-field/account/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-account'],
    excludeAccounts: null,
    userSubscriptions: 'active',
    displayNbSelected: true,
    addMissingSelected: false,
    maxResults: 30,
    context: 'account',
    useInit: false,
    _getItems: function _getItems(input) {
      var _this = this;
      var params = {
        keywords: input,
        maxResults: this.get('maxResults')
      };
      if (this.get('userSubscriptions') !== null) {
        params.userSubscriptions = this.get('userSubscriptions');
      }
      return this.get('store').query('account', params).then(function (accounts) {
        return accounts.filter(function (account) {
          return !Ember.isArray(_this.get('excludeAccounts')) || !_this.get('excludeAccounts').includes(account.id);
        });
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      var initialSelect = this.get('initialSelect');
      if (!Ember.isBlank(initialSelect)) {
        var selected = Ember.A([]);
        if (this.get('multiple')) {
          initialSelect = Ember.isArray(initialSelect) ? initialSelect : [initialSelect];
          var missingManagers = [];
          var selectedHasChanged = false;

          // initialisation de la nouvelle valeur de selected avec les items de base déjà sélectionnés
          // MBE: Attention aux promesses. Je ne touche pas pour ne pas casser le fonctionnement.
          this.get('selected').forEach(function (selectedItem) {
            if (_typeof(selectedItem) === 'object') {
              selected.pushObject(selectedItem);
            }
          });
          var selectedIds = selected.map(function (selectedItem) {
            return selectedItem.get('id');
          });
          var initialSelectedIds = initialSelect.map(function (initial) {
            return _typeof(initial) === 'object' ? initial.id : initial;
          });

          // ajout des items présélectionnés et qui ne sont pas présent dans le selected
          var notInSelected = initialSelectedIds.filter(function (initialSelectedItem) {
            return !selectedIds.includes(initialSelectedItem);
          });
          notInSelected.forEach(function (itemId) {
            var managerFound = _this2.store.peekRecord('account', itemId);
            if (managerFound) {
              selected.pushObject(managerFound);
              selectedHasChanged = true;
            } else {
              missingManagers.push(itemId);
            }
          });
          if (missingManagers.length) {
            var input = missingManagers.reduce(function (previous, current /*, idx*/) {
              return previous + " COMP".concat(current);
            }, '');
            Ember.ArrayProxy.extend(Ember.PromiseProxyMixin).create({
              promise: this._getItems(input)
            }).then(function (resources) {
              var found = resources.map(function (resource) {
                return resource.id;
              });

              // ajout des items présélectionnés et qui n'étaient pas présent dans le store via le peekRecord
              var areMissing = resources.filter(function (resource) {
                return missingManagers.includes(resource.get('id'));
              });
              areMissing.forEach(function (missing) {
                selected.pushObject(missing);
                selectedHasChanged = true;
              });

              // ajout des items présélectionnés et qui n'existent plus dans la BDD
              if (_this2.get('addMissingSelected')) {
                var notFound = missingManagers.filter(function (item) {
                  return !found.includes(item);
                });
                notFound.forEach(function (id) {
                  selected.pushObject(_this2.store.createRecord('account', {
                    id: id,
                    firstName: '',
                    lastName: 'COMP' + id
                  }));
                  selectedHasChanged = true;
                });
              }
              if (selectedHasChanged) {
                _this2.set('selected', selected);
                _this2.send('customOnChange', selected);
              }
            });
          } else {
            if (selectedHasChanged) {
              this.set('selected', selected);
              this.send('customOnChange', selected);
            }
          }
        } else {
          initialSelect = Ember.isArray(initialSelect) && !Ember.isBlank(initialSelect[0]) ? initialSelect[0] : initialSelect;
          Ember.RSVP.resolve(this.get('selected')).then(function (selectedArgs) {
            Ember.RSVP.resolve(_this2.get('initialSelect')).then(function (initialSelect) {
              selected.pushObject(selectedArgs);
              if (_typeof(initialSelect) === 'object') {
                _this2.set('selected', initialSelect);
              } else {
                var managerFound = _this2.store.peekRecord('account', initialSelect);
                if (managerFound) {
                  _this2.set('selected', managerFound);
                } else {
                  _this2.get('store').findRecord('account', initialSelect).then(function (account) {
                    return _this2.set('selected', account);
                  });
                }
              }
            });
          });
        }
      }
    }
  });
});