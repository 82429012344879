define("boondmanager/pods/components/bm-field/email/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //TODO doc
  //TODO DDAU
  var _default = _exports.default = _component.default.extend({
    currentUser: Ember.inject.service('current-user'),
    mailtoProvider: Ember.inject.service('mailto-provider'),
    openProtocol: Ember.inject.service('open-protocol'),
    classNames: ['bmc-field-email', 'bm-input-group'],
    classNameBindings: ['required:required', 'disabled:disabled', 'value:bm-input-group-valid'],
    attributeBindings: ['required', 'data-name'],
    routerService: Ember.inject.service('router'),
    forceNativeMail: false,
    icon: 'bmi-email',
    inputId: Ember.computed('id', function () {
      var id = this.get('id');
      //CAUTION: can not return id because it's already the attribut id of this component <div> container
      return id ? "input-".concat(id) : "".concat(this.get('name'), "-").concat(this.get('elementId'));
    }),
    isEmailing: Ember.computed('onClickSendMail', 'forceNativeMail', function () {
      return typeof this.get('onClickSendMail') === 'function' && !this.get('forceNativeMail');
    }).readOnly(),
    /**
     * 'didReceiveAttrs' Component hook
     *
     * Check __name__ and __options__ component attributes
     *
     * @method didReceiveAttrs
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      this._super.apply(this, arguments);

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert('bmc-field-email must have a "name" attribute!', !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert('bmc-field-email "name" attribute must be a string!', Ember.typeOf(name) === 'string'));
    },
    actions: {
      openEmail: function openEmail(email) {
        var onClickSendMail = this.get('onClickSendMail');
        if (typeof onClickSendMail === 'function') onClickSendMail(email);else this.get('openProtocol').mailTo(email);
      },
      mailTo: function mailTo(email) {
        this.get('openProtocol').mailTo(email);
      },
      onEnter: function onEnter() {
        var onEnter = this.get('enter');
        if (typeof onEnter === 'function') {
          onEnter();
        }
        return false;
      },
      searchEmail: function searchEmail() {
        var _this = this;
        this.get('currentUser').then(function (currentUser) {
          currentUser.get('advancedAppMail').then(function (app) {
            var url = _this.get('routerService').urlFor('private.apps.app', app.get('id'), {
              queryParams: {
                fn: 'search-emails',
                currentUrl: _this.get('routerService.currentURL'),
                keywords: _this.get('value')
              }
            });
            window.open(url);
          });
        });
      } /*url*/
    }
  });
});