define("boondmanager/models/right", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    actions: _emberData.default.attr(),
    apis: _emberData.default.attr(),
    attributes: _emberData.default.attr(),
    meta: _emberData.default.attr()
  });
});