define("boondmanager/models/exceptionaltime", ["exports", "ember-copy", "ember-data", "ember-data-model-fragments", "moment", "ember-data-model-fragments/attributes", "boondmanager/utils/activity", "boondmanager/models/timesreport", "boondmanager/models/workunittype"], function (_exports, _emberCopy, _emberData, _emberDataModelFragments, _moment, _attributes, _activity, _timesreport, _workunittype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ACTIVITY_TYPES = void 0;
  var ACTIVITY_TYPES = _exports.ACTIVITY_TYPES = [_workunittype.WUT_PRODUCTION, _workunittype.WUT_ABSENCE, _workunittype.WUT_INTERNAL, _workunittype.WUT_EXCEPTIONAL_TIME, _workunittype.WUT_EXCEPTIONAL_CALENDAR];
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);

      /**
       * On Init, create some boolean CPs based on ACTIVITY_TYPES
       *   * isProduction
       *   * isAbsence
       *   * isInternal
       *   * ...
       */
      ACTIVITY_TYPES.forEach(function (activityType) {
        var activityTypeName = Ember.String.capitalize(activityType);
        Ember.defineProperty(_this, "is".concat(activityTypeName), Ember.computed.equal('workUnitType.activityType', activityType));
      });
    },
    batch: _emberData.default.attr({
      defaultValue: null
    }),
    delivery: _emberData.default.attr({
      defaultValue: null
    }),
    description: _emberData.default.attr('string'),
    endDate: _emberData.default.attr('moment'),
    project: _emberData.default.attr({
      defaultValue: null
    }),
    recovering: _emberData.default.attr('boolean'),
    startDate: _emberData.default.attr('moment'),
    workUnitType: (0, _attributes.fragment)('workunittype'),
    // owner = timesreport
    owner: _emberDataModelFragments.default.fragmentOwner(),
    // Some differences with regularTime
    selectedActivity: Ember.computed('project', 'delivery', 'batch', {
      get: function get() {
        var _this2 = this;
        var promise = this.get('owner.productionActivities').then(function (productionActivities) {
          var searchedSelectedID = (0, _timesreport.getActivityIDFromRegularOrExceptionalTime)(_this2);
          var selectedActivity;
          if (typeof searchedSelectedID === 'undefined' || searchedSelectedID === _workunittype.WUT_INTERNAL) {
            selectedActivity = productionActivities[0];
          } else {
            selectedActivity = productionActivities.find(function (activity) {
              return activity.get('id') === searchedSelectedID;
            });
          }
          return selectedActivity;
        });
        return ObjProxy.create({
          promise: promise
        });
      },
      set: function set(_, newActivity, oldActivity) {
        var _this3 = this;
        var promise = this.get('owner.productionActivities').then(function (productionActivities) {
          var selectedActivity;
          if (typeof newActivity === 'string') {
            selectedActivity = productionActivities.find(function (activity) {
              return activity.get('id') === newActivity;
            });
          } else if (newActivity instanceof _activity.default) {
            // search in productionActivities to be sûr it a production activity and keep the same link to owner.productionActivities obj
            selectedActivity = productionActivities.find(function (activity) {
              return activity.get('id') === newActivity.get('id');
            });
          }
          if (typeof selectedActivity === 'undefined') {
            selectedActivity = oldActivity;
          } else {
            // update project, delivery & batch
            var _selectedActivity$get = selectedActivity.getProperties('project', 'delivery', 'batch'),
              project = _selectedActivity$get.project,
              delivery = _selectedActivity$get.delivery,
              batch = _selectedActivity$get.batch;
            _this3.setProperties({
              project: {
                id: project.get('id'),
                reference: project.get('getReference') // used in totalsByActivity CP
              },

              delivery: {
                id: delivery.get('id')
              },
              batch: batch ? {
                id: batch.get('id')
              } : null
            });
          }
          return selectedActivity;
        });
        return ObjProxy.create({
          promise: promise
        });
      }
    }),
    selectedWorkUnitType: Ember.computed('workUnitType', {
      get: function get() {
        var _this4 = this;
        var promise = this.get('owner.exceptionalWorkUnitTypes').then(function (exceptionalWorkUnitTypes) {
          var searchedWutRef = _this4.get('workUnitType.reference');
          var selectedWUT;
          var selectedWUTDelete = {
            activityType: _workunittype.WUT_EXCEPTIONAL_TIME,
            reference: searchedWutRef,
            name: searchedWutRef
          };
          if (typeof searchedWutRef === 'undefined') {
            selectedWUT = exceptionalWorkUnitTypes[0];
          } else {
            selectedWUT = exceptionalWorkUnitTypes.find(function (wut) {
              return wut.reference === searchedWutRef;
            });
          }
          return selectedWUT ? selectedWUT : selectedWUTDelete;
        });
        return ObjProxy.create({
          promise: promise
        });
      },
      set: function set(key, newWUT /*, oldWUT*/) {
        var propertiesToSet = {
          workUnitType: (0, _emberCopy.copy)(newWUT)
        };
        var oldActivityType = this.get('oldActivityType') ? this.get('oldActivityType') : newWUT.activityType;
        this.set('oldActivityType', newWUT.activityType);
        if (this.get('isExceptionalTime') && newWUT.activityType !== _workunittype.WUT_EXCEPTIONAL_TIME) {
          // heures sup -> astreintes => on reset les dates limites
          propertiesToSet.startDate = (0, _moment.default)(this.get('owner.term')).tz('Europe/Paris', true).date(1);
          propertiesToSet.endDate = (0, _moment.default)(this.get('owner.lastTermDay')).tz('Europe/Paris', true).add(1, 'second').subtract(1, 'day');
        } else if (newWUT.activityType === _workunittype.WUT_EXCEPTIONAL_TIME && oldActivityType !== _workunittype.WUT_EXCEPTIONAL_TIME) {
          // set endDate = startDate to be sure it's the same day
          propertiesToSet.startDate = this.get('startDate').clone().set('hour', 0);
          propertiesToSet.endDate = propertiesToSet.startDate.clone();
        }
        this.setProperties(propertiesToSet);
        return newWUT;
      }
    }),
    isOutOfActivityPeriod: Ember.computed('selectedActivity.isFulfilled', 'startDate', 'endDate', function () {
      var _this$getProperties = this.getProperties('selectedActivity', 'startDate', 'endDate'),
        selectedActivity = _this$getProperties.selectedActivity,
        startDate = _this$getProperties.startDate,
        endDate = _this$getProperties.endDate;
      if (selectedActivity && selectedActivity.get('isFulfilled') && startDate && endDate) {
        var currentDate = (0, _moment.default)(this.get('owner.term')).tz('Europe/Paris', true);
        var isOutOfActivityPeriod = !(startDate.isBetween(selectedActivity.get('startDate'), selectedActivity.get('endDate'), 'day', '[]') && endDate.isBetween(selectedActivity.get('startDate'), selectedActivity.get('endDate'), 'day', '[]') && startDate.isSame(currentDate, 'month') && endDate.isSame(currentDate, 'month'));
        return isOutOfActivityPeriod;
      } else {
        return false;
      }
    }).readOnly(),
    isBeforeActivityPeriod: Ember.computed('selectedActivity.isFulfilled', 'startDate', function () {
      var _this$getProperties2 = this.getProperties('selectedActivity', 'startDate'),
        selectedActivity = _this$getProperties2.selectedActivity,
        startDate = _this$getProperties2.startDate;
      if (selectedActivity && selectedActivity.get('isFulfilled') && startDate) {
        var currentDate = (0, _moment.default)(this.get('owner.term')).tz('Europe/Paris', true);
        return !(startDate.isBetween(selectedActivity.get('startDate'), selectedActivity.get('endDate'), 'day', '[]') && startDate.isSame(currentDate, 'month'));
      } else {
        return false;
      }
    }).readOnly(),
    isAfterActivityPeriod: Ember.computed('selectedActivity.isFulfilled', 'endDate', function () {
      var _this$getProperties3 = this.getProperties('selectedActivity', 'endDate'),
        selectedActivity = _this$getProperties3.selectedActivity,
        endDate = _this$getProperties3.endDate;
      if (selectedActivity && selectedActivity.get('isFulfilled') && endDate) {
        var currentDate = (0, _moment.default)(this.get('owner.term')).tz('Europe/Paris', true);
        return !(endDate.isBetween(selectedActivity.get('startDate'), selectedActivity.get('endDate'), 'day', '[]') && endDate.isSame(currentDate, 'month'));
      } else {
        return false;
      }
    }).readOnly(),
    isExceptionalCalendar: Ember.computed('selectedActivity.{isFulfilled,activityType}', function () {
      var exceptional = false;
      if (this.get('selectedActivity.isFulfilled')) {
        exceptional = this.get('selectedActivity.activityType') === _workunittype.WUT_EXCEPTIONAL_CALENDAR;
      }
      return exceptional;
    }).readOnly(),
    duration: Ember.computed('startDate', 'endDate', 'workUnitType', function () {
      var _this$getProperties4 = this.getProperties('startDate', 'endDate', 'isExceptionalCalendar'),
        startDate = _this$getProperties4.startDate,
        endDate = _this$getProperties4.endDate,
        isExceptionalCalendar = _this$getProperties4.isExceptionalCalendar;
      if (startDate && endDate) {
        var durationUtcOffset = endDate.utcOffset() - startDate.utcOffset(); //minutes
        var duration = _moment.default.duration(endDate.diff(startDate)).add(_moment.default.duration(durationUtcOffset, 'minutes'));
        if (isExceptionalCalendar && endDate.hour() === 0) {
          // add + 1 day because we have to include endDate day in diff and endDate is defined as begining of the day (00:00).
          // ex: if startDate = 2007-08-09T00:00:00 and endDate = 2007-08-10T00:00:00 so diff = 1 day but we need 2 ;)
          return duration.add(_moment.default.duration(1, 'days'));
        } else {
          return duration;
        }
      }
    }).readOnly(),
    durationDays: Ember.computed('duration', function () {
      var duration = this.get('duration');
      return duration ? duration.days() : undefined;
    }).readOnly(),
    creation: Ember.computed('originalID', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    edition: Ember.computed('originalID', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    isReadonly: Ember.computed('edition', 'creation', function () {
      return !this.get('edition') && !this.get('creation');
    }).readOnly(),
    /**
     * Start edition mode, save current values
     */
    startEdition: function startEdition() {
      this.set('edition', true);
      this.set('originalValues', {
        startDate: this.get('startDate').clone(),
        endDate: this.get('endDate').clone(),
        selectedActivity: this.get('selectedActivity.id'),
        selectedWorkUnitType: this.get('selectedWorkUnitType.reference'),
        description: this.get('description'),
        recovering: this.get('recovering')
      });
    },
    /**
     * Confirm edition
     */
    confirmEdition: function confirmEdition() {
      // clear original values and do nothing, if edition is confirmed, current values are now the last setted values
      this.set('originalValues', undefined);
      this.set('creation', false);
      this.set('edition', false);
    },
    /**
     * Cancel edition, restore previous values
     * @param productionActivities
     * @param exceptionalWorkUnitTypes
     */
    cancelEdition: function cancelEdition(productionActivities, exceptionalWorkUnitTypes) {
      // restore previews values
      this.set('edition', false);
      var originalValues = this.get('originalValues');
      var selectedWut = exceptionalWorkUnitTypes.findBy('reference', originalValues.selectedWorkUnitType);
      var selectedActivity = productionActivities.findBy('id', originalValues.selectedActivity);
      this.set('selectedWorkUnitType', selectedWut);
      this.set('selectedActivity', selectedActivity);
      this.set('description', originalValues.description);
      this.set('recovering', originalValues.recovering);
      this.set('startDate', originalValues.startDate);
      this.set('endDate', originalValues.endDate);
      this.set('originalValues', undefined);
    }
  });
});