define("boondmanager/models/tool", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    tool: _emberData.default.attr('string'),
    level: _emberData.default.attr('string')
  });
});