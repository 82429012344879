define("boondmanager/pods/components/octane/bm-search-filters/fields/product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ajBg6MZ3",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[6,[37,3],[[32,0,[\"currentUser\",\"hasModuleProductsEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-12 col-xl-6\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"name\"],[[30,[36,1],[\"products:title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],null,[[\"name\",\"selected\",\"onChange\",\"multiple\",\"showSelect\"],[\"product\",[32,1,[\"bmFieldValue\"]],[32,0,[\"update\"]],true,true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"bm-field/product\",\"t\",\"bm-field\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/product/template.hbs"
  });
});