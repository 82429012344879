define("boondmanager/models/positioningsuggestfragment", ["exports", "ember-data-model-fragments", "ember-data"], function (_exports, _emberDataModelFragments, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    defaultPositioningSuggestCandidatesPerimeterDynamic: _emberData.default.attr('array'),
    defaultPositioningSuggestCandidatesPerimeterManagers: _emberData.default.attr('array'),
    defaultPositioningSuggestCandidatesPerimeterAgencies: _emberData.default.attr('array'),
    defaultPositioningSuggestCandidatesPerimeterBusinessUnits: _emberData.default.attr('array'),
    defaultPositioningSuggestCandidatesPerimeterPoles: _emberData.default.attr('array'),
    defaultPositioningSuggestResourcesPerimeterDynamic: _emberData.default.attr('array'),
    defaultPositioningSuggestResourcesPerimeterManagers: _emberData.default.attr('array'),
    defaultPositioningSuggestResourcesPerimeterAgencies: _emberData.default.attr('array'),
    defaultPositioningSuggestResourcesPerimeterBusinessUnits: _emberData.default.attr('array'),
    defaultPositioningSuggestResourcesPerimeterPoles: _emberData.default.attr('array'),
    defaultPositioningSuggestExcludedCandidatesTypes: _emberData.default.attr('array'),
    defaultPositioningSuggestExcludedResourcesTypes: _emberData.default.attr('array'),
    defaultPositioningSuggestExcludedCandidatesStates: _emberData.default.attr('array'),
    defaultPositioningSuggestExcludedResourcesStates: _emberData.default.attr('array'),
    defaultPositioningSuggestKeywordsFromOpportunity: _emberData.default.attr('array'),
    defaultPositioningSuggestFiltersFromOpportunity: _emberData.default.attr('array')
  });
});