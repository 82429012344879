define("boondmanager/pods/components/bm-field/positioning/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component BM Field Positioning.
   *
   * Display a searchable field for positionings
   *
   * Component attributes:
   *
   *   * {string}             name            REQUIRED field name
   *   * {boolean}            onlyFullVisible OPTIONAL filter the positioning list with item having a dependsOn and a contact and a company on opportunity. default: false
   *   * {function}           onChange        OPTIONAL callback call on period change
   *
   *
   * @class  BmFieldPositioning
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-positioning'],
    store: Ember.inject.service(),
    /**
     * filter the positioning (if `true`, remove positioning with insuficients rights)
     * @type {boolean}
     * @default false
     */
    onlyFullVisible: false,
    disabled: false,
    allowClear: true,
    parent: null,
    _getPositionings: function _getPositionings(input) {
      var positionings = [];
      var params1 = {
        keywords: input
      };
      positionings.push(this.get('store').query('positioning', params1));
      var params2 = {
        keywords: input,
        positioningType: 'products'
      };
      positionings.push(this.get('store').query('positioning', params2));
      return Ember.RSVP.all(positionings);
    },
    positionings: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.positionings = [];
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._getPositionings(this.get('parent.internalReference')).then(function (positionings) {
        var list = [];
        positionings.forEach(function (items) {
          if (_this.get('onlyFullVisible')) {
            items = items.filter(function (item) {
              var isParentCorrect = true;
              if (_this.get('parent')) {
                switch (_this.get('parent.modelName')) {
                  case 'resource':
                    isParentCorrect = item.get('dependsOn.modelName') === 'resource' && item.get('dependsOn.id') === _this.get('parent.id');
                    break;
                  case 'candidate':
                    isParentCorrect = item.get('dependsOn.modelName') === 'candidate' && item.get('dependsOn.id') === _this.get('parent.id');
                    break;
                  case 'opportunity':
                    isParentCorrect = item.get('opportunity.id') === _this.get('parent.id');
                    break;
                  case 'contact':
                    isParentCorrect = item.get('opportunity.contact.id') === _this.get('parent.id');
                    break;
                  default:
                    isParentCorrect = false;
                    break;
                }
              }
              return isParentCorrect;
            });
          }
          list = list.concat(items);
        });
        if (list.length === 1) {
          _this.set('selected', list[0]);
        }
        _this.set('positionings', list);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-positioning "onChange" must have a attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-positioning "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
    },
    actions: {
      onchange: function onchange(value) {
        this.get('onChange')(value);
      }
    }
  });
});