define("boondmanager/models/purchasefragment", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    // TAB_ACHAT.ACHAT_TITLE
    reference: _emberData.default.attr('string'),
    // TAB_ACHAT.ACHAT_REF
    currency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_DEVISEAGENCE
    exchangeRate: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_CHANGEAGENCE
    project: (0, _attributes.fragment)('projectfragment', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    delivery: (0, _attributes.fragment)('deliveryfragment', {
      defaultValue: function defaultValue() {
        return {};
      }
    })
  });
});