define("boondmanager/pods/components/bm-field/customized/wemailing-signature/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/cachecomputed"], function (_exports, _component, _cachecomputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-customized-wemailing'],
    classNameBindings: ['disabled:disabled'],
    canCustomized: true,
    locked: true,
    edition: Ember.computed('disabled', 'locked', function () {
      return !this.get('disabled') && !this.get('locked');
    }).readOnly(),
    customizedEdition: Ember.computed('isCustomizedSignature', 'edition', function () {
      return this.get('edition') && this.get('isCustomizedSignature');
    }).readOnly(),
    hasValue: (0, _cachecomputed.default)('value', function () {
      return !this.isBlankValue();
    }),
    isBlankValue: function isBlankValue() {
      return typeof this.get('isCustomizedSignature') === 'undefined' || this.get('isCustomizedSignature') === null;
    },
    actions: {
      toggleLock: function toggleLock() {
        if (!this.get('disabled')) {
          this.toggleProperty('locked');
        }
      },
      updateCustomize: function updateCustomize(isCustomized) {
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange('isCustomizedSignature', isCustomized);
        }
      },
      updateSignature: function updateSignature(value) {
        if (this.get('isCustomizedSignature')) {
          var onChange = this.get('onChange');
          if (typeof onChange === 'function') {
            onChange('signature', value);
          }
        }
      }
    }
  });
});