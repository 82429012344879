define("boondmanager/models/appsurveysatisfactionfragment", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ember-data-model-fragments"], function (_exports, _emberData, _attributes, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    evaluation: _emberData.default.attr('string'),
    surveyType: (0, _attributes.fragment)('appsurveysurveytypefragment')
  });
});