define("boondmanager/models/fieldtemplatefragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT_POLE = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT_MAIMANAGER = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT_HRMANAGER = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT_AGENCY = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT = _exports.SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER_POLE = _exports.SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER_AGENCY = _exports.SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER = _exports.OWNER_MANAGERS = _exports.OWNER_GUESTS = _exports.NON_OWNER_CAN_READ_WHEN_VALIDATED = _exports.NON_OWNER_CAN_READ_NEVER = _exports.NON_OWNER_CAN_READ_ALWAYS = _exports.FIELD_VISIBILITE = _exports.FIELD_TYPEOF = _exports.FIELD_TOOLS = _exports.FIELD_TEXTAREA = _exports.FIELD_TEXT = _exports.FIELD_STATE = _exports.FIELD_SELECT = _exports.FIELD_POLE = _exports.FIELD_PHONE = _exports.FIELD_PERIODTIME = _exports.FIELD_PERIOD = _exports.FIELD_PARENT = _exports.FIELD_NATIONALITY = _exports.FIELD_MONEY = _exports.FIELD_MOBILITYAREA = _exports.FIELD_MAINMANAGER = _exports.FIELD_INT = _exports.FIELD_IFRAME = _exports.FIELD_HTML = _exports.FIELD_GUESTS = _exports.FIELD_FLOAT = _exports.FIELD_FILES = _exports.FIELD_EXPERTISEAREA = _exports.FIELD_EMAIL = _exports.FIELD_DATETIME = _exports.FIELD_DATE = _exports.FIELD_CURRENCY = _exports.FIELD_COUNTRY = _exports.FIELD_BOOLEAN = _exports.FIELD_BAROMETER = _exports.FIELD_ALERT = _exports.FIELD_AGENCY = _exports.FIELD_ADDRESS = _exports.FIELD_ACTIVITYAREA = _exports.FIELDS = _exports.ALERT_WARNING = _exports.ALERT_INFO = _exports.ALERT_DANGER = void 0;
  var FIELD_BAROMETER = _exports.FIELD_BAROMETER = 'barometer';
  var FIELD_SELECT = _exports.FIELD_SELECT = 'select';
  var FIELD_TEXTAREA = _exports.FIELD_TEXTAREA = 'textarea';
  var FIELD_HTML = _exports.FIELD_HTML = 'html';
  var FIELD_TEXT = _exports.FIELD_TEXT = 'text';
  var FIELD_CURRENCY = _exports.FIELD_CURRENCY = 'currency';
  var FIELD_FILES = _exports.FIELD_FILES = 'files';
  var FIELD_FLOAT = _exports.FIELD_FLOAT = 'float';
  var FIELD_INT = _exports.FIELD_INT = 'int';
  var FIELD_BOOLEAN = _exports.FIELD_BOOLEAN = 'bool';
  var FIELD_PARENT = _exports.FIELD_PARENT = 'parent';
  var FIELD_MAINMANAGER = _exports.FIELD_MAINMANAGER = 'mainManager';
  var FIELD_POLE = _exports.FIELD_POLE = 'pole';
  var FIELD_AGENCY = _exports.FIELD_AGENCY = 'agency';
  var FIELD_GUESTS = _exports.FIELD_GUESTS = 'guests';
  var FIELD_VISIBILITE = _exports.FIELD_VISIBILITE = 'visibility';
  var FIELD_MONEY = _exports.FIELD_MONEY = 'money';
  var FIELD_STATE = _exports.FIELD_STATE = 'state';
  var FIELD_TYPEOF = _exports.FIELD_TYPEOF = 'typeOf';
  var FIELD_TOOLS = _exports.FIELD_TOOLS = 'tools';
  var FIELD_MOBILITYAREA = _exports.FIELD_MOBILITYAREA = 'mobilityAreas';
  var FIELD_ACTIVITYAREA = _exports.FIELD_ACTIVITYAREA = 'activityAreas';
  var FIELD_EXPERTISEAREA = _exports.FIELD_EXPERTISEAREA = 'expertiseAreas';
  var FIELD_ALERT = _exports.FIELD_ALERT = 'alert';
  var FIELD_ADDRESS = _exports.FIELD_ADDRESS = 'address';
  var FIELD_EMAIL = _exports.FIELD_EMAIL = 'email';
  var FIELD_PHONE = _exports.FIELD_PHONE = 'phone';
  var FIELD_DATE = _exports.FIELD_DATE = 'date';
  var FIELD_DATETIME = _exports.FIELD_DATETIME = 'datetime';
  var FIELD_PERIOD = _exports.FIELD_PERIOD = 'period';
  var FIELD_PERIODTIME = _exports.FIELD_PERIODTIME = 'periodtime';
  var FIELD_NATIONALITY = _exports.FIELD_NATIONALITY = 'nationality';
  var FIELD_COUNTRY = _exports.FIELD_COUNTRY = 'country';
  var FIELD_IFRAME = _exports.FIELD_IFRAME = 'iFrame';
  var FIELDS = _exports.FIELDS = [FIELD_BAROMETER, FIELD_SELECT, FIELD_TEXTAREA, FIELD_HTML, FIELD_TEXT, FIELD_CURRENCY, FIELD_FILES, FIELD_FLOAT, FIELD_INT, FIELD_BOOLEAN, FIELD_PARENT, FIELD_MAINMANAGER, FIELD_POLE, FIELD_AGENCY, FIELD_GUESTS, FIELD_VISIBILITE, FIELD_MONEY, FIELD_STATE, FIELD_TYPEOF, FIELD_TOOLS, FIELD_MOBILITYAREA, FIELD_ACTIVITYAREA, FIELD_EXPERTISEAREA, FIELD_ALERT, FIELD_ADDRESS, FIELD_EMAIL, FIELD_PHONE, FIELD_DATE, FIELD_DATETIME, FIELD_PERIOD, FIELD_PERIODTIME, FIELD_NATIONALITY, FIELD_COUNTRY, FIELD_IFRAME];
  var NON_OWNER_CAN_READ_NEVER = _exports.NON_OWNER_CAN_READ_NEVER = 'never';
  var NON_OWNER_CAN_READ_ALWAYS = _exports.NON_OWNER_CAN_READ_ALWAYS = 'always';
  var NON_OWNER_CAN_READ_WHEN_VALIDATED = _exports.NON_OWNER_CAN_READ_WHEN_VALIDATED = 'whenValidated';
  var OWNER_GUESTS = _exports.OWNER_GUESTS = 'guests';
  var OWNER_MANAGERS = _exports.OWNER_MANAGERS = 'managers';
  var ALERT_INFO = _exports.ALERT_INFO = 'info';
  var ALERT_WARNING = _exports.ALERT_WARNING = 'warning';
  var ALERT_DANGER = _exports.ALERT_DANGER = 'danger';
  var SPECIFIC_DEFAULT_VALUE_FROM_PARENT = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT = 'fromParent';
  var SPECIFIC_DEFAULT_VALUE_FROM_PARENT_MAIMANAGER = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT_MAIMANAGER = 'fromParentMainManager';
  var SPECIFIC_DEFAULT_VALUE_FROM_PARENT_HRMANAGER = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT_HRMANAGER = 'fromParentHrManager';
  var SPECIFIC_DEFAULT_VALUE_FROM_PARENT_AGENCY = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT_AGENCY = 'fromParentAgency';
  var SPECIFIC_DEFAULT_VALUE_FROM_PARENT_POLE = _exports.SPECIFIC_DEFAULT_VALUE_FROM_PARENT_POLE = 'fromParentPole';
  var SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER = _exports.SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER = 'fromCurrentUser';
  var SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER_AGENCY = _exports.SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER_AGENCY = 'fromCurrentUserAgency';
  var SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER_POLE = _exports.SPECIFIC_DEFAULT_VALUE_FROM_CURRENTUSER_POLE = 'fromCurrentUserPole';
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);

      /**
       * On Init, create some boolean CPs based on LEVELS
       *   * isManager
       *   * isResource
       *   * isAdministrator
       *   * ...
       */
      FIELDS.forEach(function (typeOf) {
        var typeOfName = Ember.String.capitalize(typeOf);
        Ember.defineProperty(_this, "is".concat(typeOfName), Ember.computed.equal('typeOf', typeOf));
      });
    },
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    col: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    row: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    span: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    options: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    suffix: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    settings: _emberData.default.attr(),
    typeOf: _emberData.default.attr('string'),
    placeholder: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    name: Ember.computed('originalID', function () {
      return 'field_' + this.get('originalID');
    }),
    rightsAttribute: Ember.computed('originalID,typeOf', function () {
      switch (this.get('typeOf')) {
        case FIELD_MAINMANAGER:
        case FIELD_AGENCY:
        case FIELD_POLE:
        case FIELD_CURRENCY:
        case FIELD_GUESTS:
        case FIELD_VISIBILITE:
        case FIELD_TYPEOF:
        case FIELD_STATE:
        case FIELD_FILES:
          return this.get('typeOf');
        default:
          return 'fields/' + this.get('originalID');
      }
    }),
    typeOfValue: Ember.computed('typeOf', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      var typeOf = this.get('typeOf');
      var typeOfValue = i18n.t('models:fieldtemplate.attributes.typeOf.values', {
        returnObjects: true
      }).find(function (item) {
        // CAUTION, the searchVal can have a string format instead of a numeric one
        return typeof item['id'] !== 'undefined' && item['id'] === typeOf;
      });
      return typeOfValue ? typeOfValue['value'] : '';
    }),
    hasTitle: Ember.computed('typeOf', function () {
      return ![FIELD_MAINMANAGER, FIELD_POLE, FIELD_AGENCY, FIELD_GUESTS, FIELD_TOOLS, FIELD_MOBILITYAREA, FIELD_ACTIVITYAREA, FIELD_EXPERTISEAREA, FIELD_STATE, FIELD_TYPEOF, FIELD_VISIBILITE, FIELD_PERIOD, FIELD_PERIODTIME, FIELD_ALERT].includes(this.get('typeOf'));
    }),
    fullOptions: Ember.computed('options.[]', 'settings.optionsIsEnabled.[]', function () {
      var _this2 = this;
      return this.get('options').map(function (option) {
        var isEnabled = _this2.get('settings.optionsIsEnabled').find(function (item) {
          return item.id === option.id;
        });
        if (isEnabled) {
          option['isEnabled'] = isEnabled.isEnabled;
        }
        return option;
      });
    })
  });
});