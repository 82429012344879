define("boondmanager/models/advantagetype", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPE_PUNCTUAL = void 0;
  var TYPE_PUNCTUAL = _exports.TYPE_PUNCTUAL = 'punctual';

  /**
   * Contract advantage fragment
   */
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    agencyQuota: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    agencyAmount: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    category: _emberData.default.attr('string'),
    employeeQuota: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    employeeAmount: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    frequency: _emberData.default.attr('string'),
    participationQuota: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    reference: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    name: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    default: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean'),
    /**
     * Agency Object
     *
     * agency.id            : string
     * agency.name          : string
     *
     * @type {Object}
     */
    agency: _emberData.default.attr(),
    advantageLabels: {},
    quotaLabel: Ember.computed('category', 'i18n._locale', function () {
      this.computeAdvantageLabels();
      var frequency = this.get('i18n').t('components:bmFieldRowAdvantage.frequency', {
        context: this.get('frequency')
      });
      return "".concat(this.get('advantageLabels.employeeRowAdvantage'), "/").concat(frequency, " ").concat(this.get('i18n').t('components:bmFieldRowAdvantageContract.advantage.paid'));
    }),
    agencyLabel: Ember.computed('category', 'i18n._locale', function () {
      this.computeAdvantageLabels();
      return this.get('advantageLabels.agency');
    }),
    titleLabel: Ember.computed('category', 'i18n._locale', function () {
      this.computeAdvantageLabels();
      return this.get('advantageLabels.title');
    }),
    employeeLabel: Ember.computed('category', 'i18n._locale', function () {
      this.computeAdvantageLabels();
      return this.get('advantageLabels.employee');
    }),
    participationLabel: Ember.computed('category', 'i18n._locale', function () {
      this.computeAdvantageLabels();
      return this.get('advantageLabels.participation');
    }),
    legendLabel: Ember.computed('category', 'i18n._locale', function () {
      this.computeAdvantageLabels();
      return this.get('advantageLabels.legend');
    }),
    computeAdvantageLabels: function computeAdvantageLabels() {
      switch (this.get('category')) {
        case 'fixedAmount':
          this.set('advantageLabels', {
            employeeRowAdvantage: this.get('i18n').t('components:bmFieldRowAdvantage.fixedAmount.employeeRowAdvantage'),
            participation: '',
            agency: this.get('i18n').t('components:bmFieldRowAdvantage.fixedAmount.agency'),
            title: this.get('i18n').t('components:bmFieldRowAdvantage.fixedAmount.title'),
            employee: this.get('i18n').t('components:bmFieldRowAdvantage.fixedAmount.employee'),
            legend: ''
          });
          break;
        case 'loan':
          this.set('advantageLabels', {
            employeeRowAdvantage: this.get('i18n').t('components:bmFieldRowAdvantage.loan.employeeRowAdvantage'),
            title: this.get('i18n').t('components:bmFieldRowAdvantage.loan.title')
          });
          break;
        case 'package':
          this.set('advantageLabels', {
            employeeRowAdvantage: this.get('i18n').t('components:bmFieldRowAdvantage.package.employeeRowAdvantage'),
            participation: this.get('i18n').t('components:bmFieldRowAdvantage.package.participation'),
            agency: this.get('i18n').t('components:bmFieldRowAdvantage.package.agency'),
            title: this.get('i18n').t('components:bmFieldRowAdvantage.package.title'),
            employee: this.get('i18n').t('components:bmFieldRowAdvantage.package.employee')
          });
          break;
        case 'variableSalaryBasis':
          this.set('advantageLabels', {
            employeeRowAdvantage: this.get('i18n').t('components:bmFieldRowAdvantage.variableSalaryBasis.employeeRowAdvantage'),
            participation: this.get('i18n').t('components:bmFieldRowAdvantage.variableSalaryBasis.participation'),
            agency: this.get('i18n').t('components:bmFieldRowAdvantage.variableSalaryBasis.agency'),
            title: this.get('i18n').t('components:bmFieldRowAdvantage.variableSalaryBasis.title'),
            employee: this.get('i18n').t('components:bmFieldRowAdvantage.variableSalaryBasis.employee'),
            legend: this.get('i18n').t('components:bmFieldRowAdvantage.variableSalaryBasis.legend')
          });
          break;
      }
    }
  });
});