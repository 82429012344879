define("boondmanager/models/form", ["exports", "boondmanager/models/base", "ember-data-copyable", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberDataCopyable, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FORM_STATE_VALIDATED = _exports.FORM_STATE_PENDING = void 0;
  var FORM_STATE_PENDING = _exports.FORM_STATE_PENDING = 'pending';
  var FORM_STATE_VALIDATED = _exports.FORM_STATE_VALIDATED = 'validated';
  var Form = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['dependsOn', 'recipient', 'template']
    }),
    mailToRecipient: _emberData.default.attr(),
    mailObject: _emberData.default.attr('string'),
    mailBody: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('date'),
    validateDate: _emberData.default.attr('date'),
    validated: _emberData.default.attr('boolean'),
    canReadForm: _emberData.default.attr('boolean'),
    dependsOnCanShow: _emberData.default.attr('boolean'),
    questions: (0, _attributes.fragmentArray)('formquestionfragment'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    validator: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    dependsOn: _emberData.default.belongsTo('base', {
      inverse: null
    }),
    recipient: _emberData.default.belongsTo('base', {
      inverse: null
    }),
    template: _emberData.default.belongsTo('formtemplate', {
      inverse: null
    }),
    /**
     * A true si la case à cocher "Je suis le destinataire du formulaire" est cochée
     * La sélection du destinataire et des champs liés au mail envoyé sont masqués
     *
     * @property senderIsRecipient
     * @type {boolean}
     */
    senderIsRecipient: false
  });
  Form.reopenClass({
    prefix: 'FRM'
  });
  Form.reopen({
    prefix: Ember.computed(function () {
      return Form.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Form;
});