define("boondmanager/models/target", ["exports", "ember-data", "ember-data-copyable", "boondmanager/models/base"], function (_exports, _emberData, _emberDataCopyable, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allowSettingTargetForScorecard = allowSettingTargetForScorecard;
  _exports.default = void 0;
  _exports.getDictionaryLabelFromCategoryAndReference = getDictionaryLabelFromCategoryAndReference;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function allowSettingTargetForScorecard(category, reference) {
    var allow = true;
    switch (category) {
      case 'activityExpensesSynthesis':
        switch (reference) {
          case 'durationOfProductionAndExceptionalUsedTimeAndCalendar':
            allow = false;
            break;
        }
        break;
    }
    return allow;
  }
  function getDictionaryLabelFromCategoryAndReference(settings, category, reference) {
    var dictionaryLabels = [];
    switch (category) {
      case 'commercialSynthesis':
        switch (reference) {
          case 'numberOfActionsOnContactsPerTypes':
            dictionaryLabels = settings.customer.action.contact.filter(function (dict) {
              return dict.value !== '';
            });
            break;
          case 'numberOfActionsOnOpportunitiesPerTypes':
            dictionaryLabels = settings.customer.action.opportunity.filter(function (dict) {
              return dict.value !== '';
            });
            break;
          case 'numberOfActionsOnProjectsPerTypes':
            dictionaryLabels = settings.customer.action.project.filter(function (dict) {
              return dict.value !== '' && !dict.collaborative;
            });
            break;
          case 'numberOfOpportunitiesPerStates':
          case 'turnoverWeightedExcludingTaxPerStates':
            dictionaryLabels = settings.customer.state.opportunity.filter(function (dict) {
              return dict.value !== '';
            });
            break;
          case 'numberOfActionsCreatedOnContactsPerTypes':
            dictionaryLabels = settings.customer.action.contact.filter(function (dict) {
              return dict.value !== '';
            });
            break;
          case 'numberOfDeliveriesInProgressPerType':
          case 'numberOfDeliveriesStartedPerType':
          case 'numberOfDeliveriesStoppedPerType':
            dictionaryLabels = settings.customer.typeOf.delivery;
            break;
        }
        break;
      case 'humanResourcesSynthesis':
        switch (reference) {
          case 'numberOfActionsOnResourcesPerTypes':
            dictionaryLabels = settings.customer.action.resource.filter(function (dict) {
              return dict.value !== '';
            });
            break;
        }
        break;
      case 'recruitmentSynthesis':
        switch (reference) {
          case 'numberOfActionsOnCandidatesPerTypes':
            dictionaryLabels = settings.customer.action.candidate.filter(function (dict) {
              return dict.value !== '';
            });
            break;
          case 'numberOfCandidatesCreatedPerStates':
            dictionaryLabels = settings.customer.state.candidate.filter(function (dict) {
              return dict.value !== '';
            });
            break;
          case 'numberOfCandidatesCreatedPerSources':
            dictionaryLabels = settings.customer.source;
            break;
        }
        break;
      case 'activityExpensesSynthesis':
        switch (reference) {
          case 'durationOfAllUsedTimePerActivity':
            dictionaryLabels = settings.customer.typeOf.activity;
            break;
          case 'costsOfAllUsedTimeExcludingTaxPerActivity':
            dictionaryLabels = settings.customer.typeOf.activity;
            break;
        }
        break;
      case 'billingSynthesis':
        switch (reference) {
          case 'turnoverInvoicedExcludingTaxFollowingInvoicesDatePerStates':
          case 'turnoverInvoicedExcludingTaxFollowingInvoicesPeriodPerStates':
            dictionaryLabels = settings.customer.state.invoice.filter(function (dict) {
              return dict.value !== '' && dict.id !== 10;
            });
            break;
        }
        break;
      case 'globalSynthesis':
        switch (reference) {
          case 'costsOfPurchasesExcludingTaxPerStates':
            dictionaryLabels = settings.customer.typeOf.purchase;
            break;
          case 'costsOfPurchasesExcludingTaxPerStatesFollowingChargedPeriod':
            dictionaryLabels = settings.customer.typeOf.purchase;
            break;
        }
        break;
    }
    return dictionaryLabels;
  }
  var Target = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['resource']
    }),
    category: _emberData.default.attr('string'),
    operator: _emberData.default.attr('string'),
    periodNumber: _emberData.default.attr(),
    periodType: _emberData.default.attr('string'),
    periodYear: _emberData.default.attr(),
    scorecard: _emberData.default.attr(),
    value: _emberData.default.attr('number'),
    resource: _emberData.default.belongsTo(),
    dictionaryLabel: Ember.computed('category', 'scorecard.{reference,dictionaryId}', 'i18n._locale', function () {
      var category = this.get('category');
      var reference = this.get('scorecard.reference');
      var dictionaryId = this.get('scorecard.dictionaryId');
      var value = '';
      if (dictionaryId >= 0) {
        var dictionaryLabels = getDictionaryLabelFromCategoryAndReference(this.get('settingsService'), category, reference);
        if (_typeof(dictionaryLabels) === 'object') {
          value = dictionaryLabels.find(function (item) {
            return item.id === dictionaryId;
          });
          value = value ? value.value : '';
        }
      }
      return value;
    }),
    period: Ember.computed('periodType', 'periodNumber', 'periodYear', 'i18n._locale', function () {
      var periodType = this.get('periodType');
      var periodNumber = this.get('periodNumber');
      var periodYear = this.get('periodYear');
      if (periodYear === 'allDates') {
        periodYear = '';
      }
      if (periodType === 'annual') {
        return periodYear;
      } else {
        if (periodNumber === 'allDates') {
          return '';
        }
        var periodicity = this.get('settingsService').bm.settings.targets.periodicity.abbr;
        var period = periodicity.find(function (periodicity) {
          return periodicity.id === periodType;
        });
        return period.value + "".concat(periodNumber, " ").concat(periodYear);
      }
    })
  });
  Target.reopenClass({
    prefix: 'TGT'
  });
  Target.reopen({
    prefix: Ember.computed(function () {
      return Target.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Target;
});