define("boondmanager/pods/components/bm-field/period/navigate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4JqwRl6E",
    "block": "{\"symbols\":[\"@onPrevious\",\"@onNow\",\"@onNext\"],\"statements\":[[8,\"octane/bm-btn\",[[24,0,\"bmb-circle bmb-circle-icon-large\"],[24,\"data-name\",\"button-on-previous\"],[24,\"data-placement\",\"top\"],[16,\"data-original-title\",[30,[36,0],[\"common:actions.periodNavigator.previous\"],null]],[16,\"aria-label\",[30,[36,0],[\"common:actions.periodNavigator.previous\"],null]]],[[\"@onClick\",\"@iconOnly\",\"@disabled\"],[[32,1],\"bmi-caret-left-thin\",[32,0,[\"previousDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[30,[36,0],[\"components:bmFieldPeriodNavigate.actions.previous\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle bmb-rectangle-shadow\"],[24,\"data-name\",\"button-on-now\"],[16,\"aria-label\",[30,[36,0],[\"components:bmFieldPeriodNavigate.current\"],null]]],[[\"@onClick\",\"@disabled\"],[[32,2],[32,0,[\"nowDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[30,[36,0],[\"components:bmFieldPeriodNavigate.current\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-circle bmb-circle-icon-large\"],[24,\"data-name\",\"button-on-next\"],[24,\"data-placement\",\"top\"],[16,\"data-original-title\",[30,[36,0],[\"components:bmFieldPeriodNavigate.actions.next\"],null]],[16,\"aria-label\",[30,[36,0],[\"common:actions.periodNavigator.next\"],null]]],[[\"@onClick\",\"@iconOnly\",\"@disabled\"],[[32,3],\"bmi-caret-right-thin\",[32,0,[\"nextDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[30,[36,0],[\"components:bmFieldPeriodNavigate.actions.next\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/period/navigate/template.hbs"
  });
});