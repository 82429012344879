define("boondmanager/models/batch", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Batch = _base.default.extend(_emberDataCopyable.default, {
    title: _emberData.default.attr('string')
  });
  Batch.reopenClass({
    prefix: 'LOT'
  });
  Batch.reopen({
    prefix: Ember.computed(function () {
      return Batch.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Batch;
});