define("boondmanager/models/flag", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Flag = _base.default.extend({
    name: _emberData.default.attr('string'),
    canReadFlag: _emberData.default.attr('boolean'),
    canWriteFlag: _emberData.default.attr('boolean'),
    mainManager: _emberData.default.belongsTo('resource') //simplifie la gestion des flag.get('mainManager') dans component
  });

  Flag.reopenClass({
    prefix: 'FLAG'
  });
  Flag.reopen({
    prefix: Ember.computed(function () {
      return Flag.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Flag;
});