define("boondmanager/models/exceptionalrule", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    reference: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    //   BackEnd can return a null value for 'priceExcludingTaxOrPriceRate' or 'grossCostOrSalaryRate'.
    //   IF  we consider null is same as 0, we could use DS.attr('number')
    //   BUT if null has not the same signification than 0, we can't... Perhaps we could make a new transform
    priceExcludingTaxOrPriceRate: _emberData.default.attr(),
    grossCostOrSalaryRate: _emberData.default.attr(),
    isPriceExcludingTaxOrPriceRateOverriden: _emberData.default.attr('boolean', {
      default: false
    }),
    isGrossCostOrSalaryRateOverriden: _emberData.default.attr('boolean', {
      default: false
    }),
    state: _emberData.default.attr('boolean')
  });
});