define("boondmanager/models/baseactivity", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.REJECT_CORRECTION_FOR_PREVIOUS_VALIDATOR = _exports.ACTIVITY_WAITING_FOR_VALIDATION = _exports.ACTIVITY_VALIDATED = _exports.ACTIVITY_SAVED_AND_NO_VALIDATION = _exports.ACTIVITY_REJECTED = _exports.ACTIVITY_PENDING_CREATION = void 0;
  var ACTIVITY_SAVED_AND_NO_VALIDATION = _exports.ACTIVITY_SAVED_AND_NO_VALIDATION = 'savedAndNoValidation';
  var ACTIVITY_WAITING_FOR_VALIDATION = _exports.ACTIVITY_WAITING_FOR_VALIDATION = 'waitingForValidation';
  var ACTIVITY_PENDING_CREATION = _exports.ACTIVITY_PENDING_CREATION = 'pendingCreation';
  var ACTIVITY_VALIDATED = _exports.ACTIVITY_VALIDATED = 'validated';
  var ACTIVITY_REJECTED = _exports.ACTIVITY_REJECTED = 'rejected';
  var REJECT_CORRECTION_FOR_PREVIOUS_VALIDATOR = _exports.REJECT_CORRECTION_FOR_PREVIOUS_VALIDATOR = 'correctionForPreviousValidator';

  /**
   * Base model
   *
   * Base model for all our other models. This allows using polymorphic relationships
   * and define common methods and/or CP.
   *
   * @class BaseModel
   * @extends Model
   * @namespace DS
   * @module Models
   */
  var BaseActivity = _base.default.extend({
    /**
     * State absence :
     *
     *  possible values :
     *    - "savedAndNoValidation"
     *    - "waitingForValidation"
     *    - "validated"
     *    - "rejected"
     *
     * @property state
     * @type {string}
     */
    state: _emberData.default.attr('string'),
    // TAB_LISTEABSENCES.LISTEABSENCES_ETAT

    agency: _emberData.default.belongsTo(),
    resource: _emberData.default.belongsTo(),
    files: _emberData.default.hasMany('document'),
    validations: _emberData.default.hasMany('validation', {
      inverse: 'dependsOn'
    }),
    validationWorkflow: _emberData.default.hasMany('resource', {
      inverse: null
    }),
    // To allow big textareas on validation/reject
    reason: _emberData.default.attr('string'),
    rejectTypeOf: _emberData.default.attr('string'),
    expectedValidator: _emberData.default.attr('number')
  });
  var _default = _exports.default = BaseActivity;
});