define("boondmanager/models/base", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_REASON_PROFILES = _exports.STATE_REASON_INDIFFERENT_KEY = _exports.PAYMENT_METHOD_CASH = _exports.MODEL_SCHEDULE = _exports.MODEL_RESOURCE = _exports.MODEL_PURCHASE = _exports.MODEL_PROVIDER_INVOICE = _exports.MODEL_PROJECT = _exports.MODEL_PRODUCT = _exports.MODEL_POSITIONING = _exports.MODEL_PAYMENT = _exports.MODEL_ORDER = _exports.MODEL_OPPORTUNITY = _exports.MODEL_INVOICE = _exports.MODEL_INACTIVITY = _exports.MODEL_DELIVERY = _exports.MODEL_CONTRACT = _exports.MODEL_CONTACT = _exports.MODEL_COMPANY = _exports.MODEL_CANDIDATE = _exports.MODEL_BILLING_PROJECTS_BALANCE = _exports.MODEL_BILLING_MONTHLY_BALANCE = _exports.MODEL_BILLING_DELIVERIES_PURCHASE_BALANCE = _exports.MODEL_APPENTITY = _exports.MODEL_ACTION = void 0;
  var STATE_REASON_PROFILES = _exports.STATE_REASON_PROFILES = ['candidate', 'resource', 'opportunity', 'project', 'positioning'];
  var MODEL_ACTION = _exports.MODEL_ACTION = 'action';
  var MODEL_BILLING_DELIVERIES_PURCHASE_BALANCE = _exports.MODEL_BILLING_DELIVERIES_PURCHASE_BALANCE = 'billingdeliveriespurchasebalance';
  var MODEL_BILLING_MONTHLY_BALANCE = _exports.MODEL_BILLING_MONTHLY_BALANCE = 'billingmonthlybalance';
  var MODEL_BILLING_PROJECTS_BALANCE = _exports.MODEL_BILLING_PROJECTS_BALANCE = 'billingprojectsbalance';
  var MODEL_CANDIDATE = _exports.MODEL_CANDIDATE = 'candidate';
  var MODEL_COMPANY = _exports.MODEL_COMPANY = 'company';
  var MODEL_CONTACT = _exports.MODEL_CONTACT = 'contact';
  var MODEL_CONTRACT = _exports.MODEL_CONTRACT = 'contract';
  var MODEL_DELIVERY = _exports.MODEL_DELIVERY = 'delivery';
  var MODEL_INVOICE = _exports.MODEL_INVOICE = 'invoice';
  var MODEL_INACTIVITY = _exports.MODEL_INACTIVITY = 'inactivity';
  var MODEL_OPPORTUNITY = _exports.MODEL_OPPORTUNITY = 'opportunity';
  var MODEL_POSITIONING = _exports.MODEL_POSITIONING = 'positioning';
  var MODEL_PRODUCT = _exports.MODEL_PRODUCT = 'product';
  var MODEL_PROJECT = _exports.MODEL_PROJECT = 'project';
  var MODEL_ORDER = _exports.MODEL_ORDER = 'order';
  var MODEL_PROVIDER_INVOICE = _exports.MODEL_PROVIDER_INVOICE = 'providerinvoice';
  var MODEL_PAYMENT = _exports.MODEL_PAYMENT = 'payment';
  var MODEL_PURCHASE = _exports.MODEL_PURCHASE = 'purchase';
  var MODEL_RESOURCE = _exports.MODEL_RESOURCE = 'resource';
  var MODEL_SCHEDULE = _exports.MODEL_SCHEDULE = 'schedule';
  var MODEL_APPENTITY = _exports.MODEL_APPENTITY = 'appentity';
  var STATE_REASON_INDIFFERENT_KEY = _exports.STATE_REASON_INDIFFERENT_KEY = 0;
  var PAYMENT_METHOD_CASH = _exports.PAYMENT_METHOD_CASH = 10;
  /**
   * Base model
   *
   * Base model for all our other models. This allows using polymorphic relationships
   * and define common methods and/or CP.
   *
   * @class BaseModel
   * @extends Model
   * @namespace DS
   * @module Models
   */
  var Base = _emberData.default.Model.extend({
    threads: Ember.A([]),
    settingsService: Ember.inject.service('settings'),
    /**
     * internalReference
     *
     * @property internalReference
     * @type {string}
     */
    internalReference: Ember.computed('id', function () {
      return !this.get('isNew') ? "".concat(this.get('prefix')).concat(this.get('id')) : '';
    }).readOnly(),
    /**
     * getReference
     *
     * Some models can have a custom `reference` defined by user. If so, we
     * return it. Else we return our `internalReference`
     *
     * @property getReference
     * @type {string}
     */
    getReference: Ember.computed('internalReference', 'title', 'reference', 'number', 'name', function () {
      var id = [];
      switch (this.get('modelName')) {
        case 'project':
        case 'appsepaproject':
        case 'appadvancedprojectsproject':
        case 'apppostproductionproject':
        case 'appresourceplannerproject':
        case 'product':
        case 'invoice':
        case 'appaccountingpayrollinvoice':
        case 'appdataclosinginvoice':
        case 'appemailinginvoice':
        case 'appsepainvoice':
        case 'purchase':
        case 'appsepapurchase':
        case 'apppostproductionpurchase':
          return this.get('reference') || this.get('internalReference');
        case 'time':
          id = this.get('id').split('_');
          if (id.length === 2 && id[0] === 'exceptional') {
            return 'TPSEXP' + id[1];
          }
          return this.get('internalReference');
        default:
          return this.get('title') || this.get('name') || this.get('number') || this.get('reference') || this.get('internalReference');
      }
    }).readOnly(),
    /**
     * Attribute for notification in save mixin
     *
     * @returns {String}
     */
    attributeForSaveNotifications: Ember.computed('internalReference', 'title', 'reference', 'number', 'name', 'completeName', 'getReference', function () {
      switch (this.get('modelName')) {
        case 'absencesreport':
        case 'action':
        case 'advantage':
        case 'contract':
        case 'appsepacontract':
        case 'expensesreport':
        case 'payment':
        case 'positioning':
        case 'timesreport':
          return this.get('internalReference');
        case 'candidate':
        case 'contact':
        case 'resource':
        case 'account':
        case 'appadvancedcandidatescandidate':
          return this.get('completeName') || this.get('internalReference');
        case 'company':
        case 'appaccountingpayrollcompany':
        case 'appaccountingpayrollresource':
        case 'appsepacompany':
        case 'product':
        case 'pole':
        case 'businessunit':
        case 'agency':
        case 'app':
        case 'role':
          return this.get('name') || this.get('internalReference');
        case 'groupment':
        case 'opportunity':
        case 'purchase':
        case 'appsepapurchase':
        case 'target':
          return this.get('title') || this.get('internalReference');
        case 'invoice':
        case 'project':
        case 'appadvancedprojectsproject':
          return this.get('reference') || this.get('internalReference');
        case 'order':
        case 'appsepaorder':
          return this.get('number') || this.get('internalReference');
        default:
          return this.get('getReference');
      }
    }).readOnly(),
    /**
     * Array of `typeOf` objects (idValueObj)
     *
     * @property typeOfArr
     * @type {*}
     */
    typeOfArr: Ember.computed('i18n._locale', function () {
      var i18n = this.get('i18n');
      var modelName = this.get('modelName');
      var typeOfName = modelName;

      // particulare cases
      switch (modelName) {
        case 'opportunity':
        case 'appsepaproject':
          typeOfName = 'project';
          break;
        case 'account':
        case 'appintranetaccountsresource':
        case 'appadvancedcandidatescandidate':
        case 'candidate':
          typeOfName = 'resource';
          break;
      }
      var typeOfArr = [];
      if (modelName === 'candidate' || modelName === 'appadvancedcandidatescandidate') {
        typeOfArr = [{
          id: -1,
          value: i18n.t('common:undefined')
        }];
      }
      var key = "setting.typeOf.".concat(typeOfName);
      if (this.get('settingsService').exists(key)) {
        typeOfArr = typeOfArr.concat(this.get('settingsService').fromKey(key));
      }
      return typeOfArr;
    }).readOnly(),
    typeOfObj: Ember.computed('typeOfArr.[]', 'typeOf', function () {
      var id = this.get('typeOf');
      var typeOfArr = this.get('typeOfArr');
      var typeOfObj;
      if (typeOfArr.length) {
        typeOfObj = typeOfArr.find(function (item) {
          return item.id === id;
        });
      }
      return typeOfObj;
    }).readOnly(),
    /**
     * Translated `typeOf` value from `typeOf` attribut ID
     *
     * @uses typeOf
     *
     * @property typeOfValue
     * @type {*}
     */
    typeOfValue: Ember.computed('typeOf', 'typeOfArr', function () {
      var typeOfObj = this.get('typeOfObj');
      return typeOfObj ? typeOfObj.value : this.get('i18n').t('common:unknown.title');
    }).readOnly(),
    stateClass: Ember.computed('state', 'modelName', 'stateClassModelName', 'i18n._locale', function () {
      var modelName = this.get('stateClassModelName') || this.get('modelName');
      var settings = [];
      if (this.get('settingsService').customer.state) {
        settings = this.get('settingsService').customer.state[modelName];
      }
      var value = '';
      if (settings) {
        var foundState = settings.findBy('id', this.get('state'));
        if (foundState) {
          value = "bm-state-color-".concat(foundState.color);
        }
      }
      return value;
    }).readOnly()
  });
  Base.reopenClass({
    prefix: ''
  });
  Base.reopen({
    prefix: Ember.computed(function () {
      return Base.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Base;
});