define("boondmanager/serializers/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Classe définissant le sérialiseur JSON API par défaut.
   *
   * * @example
   *     App.ApplicationSerializer = DS.JSONAPISerializer.extend({});
   *
   * @class ApplicationSerializer
   * @extends DS.JSONAPISerializer
   * @namespace  Ember
   * @module  Models
   */
  var _default = _exports.default = _emberData.default.JSONAPISerializer.extend({
    /* BoondManager API do not follow Ember JSONAPI conventions: attribute and
    relationship names should be dash-cased. BoondManager API send CamelCased
    attribute names. So we redefine keyForAttribute() method
    */
    keyForAttribute: function keyForAttribute(attr) {
      return attr;
    },
    keyForRelationship: function keyForRelationship(key /*, typeClass, method*/) {
      //console.log('keyForRelationship',...arguments);
      //console.log('keyForRelationship:', key)
      return key;
    },
    /* crée l'attribut _metadata contenant une copie des metas */normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var documentHash = this._super(store, primaryModelClass, payload, id, requestType);
      if (documentHash.data && documentHash.data.attributes && payload.meta && requestType !== 'deleteRecord') {
        documentHash.data.attributes._metadata = Ember.assign({}, payload.meta);
      }
      return documentHash;
    },
    // type reste au singulier
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return (0, _emberInflector.singularize)(modelName);
    },
    /**
     * Set to only send to backend dirty attributes
     * @param snapshot
     * @param json
     * @param key
     * @param attributes
     */
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      // Use sendAttrs to send only wanted attributes instead of all
      if (snapshot.record.get('isNew') || !snapshot.adapterOptions || !snapshot.adapterOptions.sendAttrs || Ember.isArray(snapshot.adapterOptions.sendAttrs) && snapshot.adapterOptions.sendAttrs.indexOf(key) !== -1) {
        this._super(snapshot, json, key, attributes);
      }
    }
  });
});