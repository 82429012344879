define("boondmanager/models/apppostproductionpurchase", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable"], function (_exports, _emberData, _base, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SUBSCRIPTION_UNITARY = void 0;
  var SUBSCRIPTION_UNITARY = _exports.SUBSCRIPTION_UNITARY = 0;
  var AppsPostProductionPurchase = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['orders']
    }),
    title: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    subscription: _emberData.default.attr('number'),
    numberOfCorrelatedOrders: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    reinvoiceAmountExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    orders: _emberData.default.hasMany('order'),
    reinvoiceAmountForMonth: Ember.computed('startDate', 'endDate', 'subscription', 'reinvoiceAmountExcludingTax', function () {
      if (this.get('subscription') === SUBSCRIPTION_UNITARY) {
        return this.get('reinvoiceAmountExcludingTax');
      }
      var startDate = this.get('startDate').startOf('month');
      var endDate = this.get('endDate').endOf('month');
      var nbMonths = Math.ceil(endDate.diff(startDate, 'months', true));
      return this.get('reinvoiceAmountExcludingTax') / nbMonths;
    })
  });
  AppsPostProductionPurchase.reopenClass({
    prefix: 'ACH'
  });
  AppsPostProductionPurchase.reopen({
    prefix: Ember.computed(function () {
      return AppsPostProductionPurchase.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsPostProductionPurchase;
});