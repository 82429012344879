define("boondmanager/pods/components/bm-field/power-select/checkall/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  /**
   * CheckAll trigger component
   *
   * Component attributes:
   *
   *   * {string}        idKeyname          REQUIRED important to check, when user filter results in options, which are already checked or not
   *                                        if you write a trigger component that extends this one, pass the bm-field/power-select idKeyname parameter value
   *                                        or set it to 'id' which is the default value for bm-field/power-select
   *   * {boolean}       searchEnabled      If true, it means that user can filter results in option list, default to undefined
   *   * {function}      onClearSearch      Function/action executed when user click on clear search button
   */
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['bm-field-power-select-checkall'],
    autofocus: true,
    idKeyname: 'id',
    // Lifecycle hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('autofocus')) {
        this.focusInput();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('searchEnabled')) {
        Ember.run.scheduleOnce('actions', this, this.get('select').actions.search, '');
      }
    },
    getAutofocus: Ember.computed('autofocus', 'initiallyOpened', function () {
      // ici, l'autofocus est déjà géré par power-select. Le seul cas où cela ne fonctionne pas
      // c'est lorsque l'on construit la vue avec le power-select déjà ouvert par défaut, auquel
      // cas il faut forcer l'autofocus pour que le focus se fasse bien sur le champ de recherche
      // sinon, si on force l'autofocus malgré tout, cela provoque un double focus et on perd la liste
      // cf https://wishgroupe.atlassian.net/browse/BM-4324
      return this.get('initiallyOpened') ? this.get('autofocus') : undefined;
    }).readOnly(),
    displayCheckOptions: Ember.computed('select.resultsCount', function () {
      return this.get('select.resultsCount') > 0;
    }).readOnly(),
    // Actions
    stateCheckAll: Ember.computed('select.selected.length', function () {
      var select = this.get('select');
      var nbOptions = 0;
      select.options.forEach(function (option) {
        if (typeof option.options !== 'undefined') {
          nbOptions += option.options.length;
        } else {
          nbOptions++;
        }
      });
      return select.selected.length === nbOptions;
    }),
    getInput: function getInput() {
      var query = ".ember-power-select-search-input[aria-controls=\"".concat(this.get('listboxId'), "\"]");
      return document.querySelector(query);
    },
    // Methods
    focusInput: function focusInput() {
      this.input = this.getInput();
      if (this.input) {
        Ember.run.later(this.input, 'focus', 0);
      }
    },
    _getDisplayedOptions: function _getDisplayedOptions(select) {
      return select.searchText ? select.results : select.options;
    },
    /**
     * Flat options
     * @param opts
     * @returns {Array}
     * @private
     */
    _flatOptions: function _flatOptions(opts) {
      var flat = [];
      for (var i = 0; i < opts.length; i++) {
        if (opts[i].options) {
          Array.prototype.push.apply(flat, this._flatOptions(opts[i].options));
        } else {
          flat.push(opts[i]);
        }
      }
      return flat;
    },
    focused: false,
    bmHasValue: function bmHasValue(event) {
      event.stopPropagation();
    },
    bmHasNoValue: function bmHasNoValue(event) {
      event.stopPropagation();
    },
    bmFocusOut: function bmFocusOut(event) {
      event.stopPropagation();
    },
    bmFocusIn: function bmFocusIn(event) {
      event.stopPropagation();
    },
    actions: {
      checkAll: function checkAll() {
        var select = this.get('select');
        var displayed = this._getDisplayedOptions(select);
        var idKeyname = this.get('idKeyname');
        var options = [];
        var itemExists;
        function recursiveSelect(opts) {
          var _loop = function _loop(i) {
            if (opts[i].options) {
              recursiveSelect(opts[i].options);
            } else {
              itemExists = options.find(function (item) {
                if (item.hasOwnProperty('modelName')) {
                  return item.modelName === opts[i].modelName && item[idKeyname] === opts[i][idKeyname];
                } else {
                  if (_typeof(item) === 'object') {
                    return item[idKeyname] === opts[i][idKeyname];
                  } else {
                    return item === opts[i];
                  }
                }
              });
              if (!itemExists) {
                options.push(opts[i]);
              }
            }
          };
          for (var i = 0; i < opts.length; i++) {
            _loop(i);
          }
        }
        recursiveSelect(displayed);
        if (typeof select.selected !== 'undefined') {
          recursiveSelect(select.selected);
        }
        select.actions.select(options);
      },
      uncheckAll: function uncheckAll() {
        var select = this.get('select');
        var selected = select.selected;
        var displayed = this._getDisplayedOptions(select);
        var idKeyname = this.get('idKeyname');
        var options = [];
        var itemExists;
        function recursiveDeselect(opts, exclude) {
          var _loop2 = function _loop2(i) {
            if (opts[i].options) {
              recursiveDeselect(opts[i].options, exclude);
            } else {
              itemExists = false;
              if (typeof exclude !== 'undefined') {
                itemExists = exclude.find(function (item) {
                  if (item.hasOwnProperty('modelName')) {
                    return item.modelName === opts[i].modelName && item[idKeyname] === opts[i][idKeyname];
                  } else {
                    if (_typeof(item) === 'object') {
                      return item[idKeyname] === opts[i][idKeyname];
                    } else {
                      return item === opts[i];
                    }
                  }
                });
              }
              if (!itemExists) {
                options.push(opts[i]);
              }
            }
          };
          for (var i = 0; i < opts.length; i++) {
            _loop2(i);
          }
        }
        if (selected && selected.length) {
          recursiveDeselect(selected, this._flatOptions(displayed));
        }
        select.actions.select(options);
      },
      onKeydown: function onKeydown(e) {
        var onKeydown = this.get('onKeydown');
        if (onKeydown(e) === false) {
          return false;
        }
        if (e.keyCode === 13) {
          var select = this.get('select');
          select.actions.close(e);
        }
      },
      clearSearch: function clearSearch() {
        this.input = this.getInput();
        if (this.input) {
          this.input.value = '';
        }
        this.get('select').actions.search('');
        var onClearSearch = this.get('onClearSearch');
        if (typeof onClearSearch === 'function') {
          onClearSearch.apply(this);
        }
      },
      clear: function clear() {
        this.get('select').actions.select(null);
        this.get('select').actions.close();
      },
      onFocus: function onFocus() {
        if (typeof this.get('onFocus') == 'function') {
          this.get('onFocus').apply(void 0, arguments);
        }
        this.set('focused', true);
      },
      onBlur: function onBlur() {
        if (typeof this.get('onBlur') == 'function') {
          this.get('onBlur').apply(void 0, arguments);
        }
        this.set('focused', false);
      }
    }
  });
});