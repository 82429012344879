define("boondmanager/pods/components/bm-field/state-editable/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    stateReasonLabel: Ember.computed('model.{state,stateReason.typeOf}', 'states', function () {
      var _this = this;
      if (this.get('model.stateReason')) {
        var promise = Ember.RSVP.Promise.resolve(this.get('states')).then(function (states) {
          if (states) {
            var currentState = _this.get('states').find(function (item) {
              return item.id === _this.get('model.state');
            });
            var foundState = currentState.reason.find(function (item) {
              return item.id === _this.get('model.stateReason.typeOf');
            });
            return foundState ? foundState.value : '';
          }
        });
        return ObjProxy.create({
          promise: promise
        });
      }
    }).readOnly(),
    actions: {
      onUpdateState: function onUpdateState(value) {
        var onUpdateState = this.get('onUpdateState');
        if (typeof onUpdateState === 'function') {
          onUpdateState(value);
        }
      },
      onOpenStateReasonModal: function onOpenStateReasonModal() {
        var onOpenStateReasonModal = this.get('onOpenStateReasonModal');
        if (typeof onOpenStateReasonModal === 'function') {
          onOpenStateReasonModal();
        }
      }
    }
  });
});