define("boondmanager/models/appdigitalworkplacecategorydocumentfragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DocFragment = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    row: _emberData.default.attr('number'),
    profileTypesOf: _emberData.default.attr('array'),
    agencies: _emberData.default.attr('array'),
    file: _emberData.default.attr('string'),
    sendNotification: _emberData.default.attr('boolean'),
    settingsService: Ember.inject.service('settings'),
    originalDocument: null,
    agenciesLabel: Ember.computed('agencies.[]', 'i18n._locale', function () {
      var agencies = this.get('agencies');
      if (agencies.length === 1) {
        var agency = agencies[0];
        return agency.get ? agency.get('name') : agency.name;
      }
      return this.get('i18n').t('appdigitalworkplacecategories:agencies.count', {
        count: agencies.length
      });
    }).readOnly(),
    profileTypesOfLabel: Ember.computed('profileTypesOf.[]', 'i18n._locale', function () {
      var profileTypesOf = this.get('profileTypesOf');
      var settings = this.get('settingsService');
      if (profileTypesOf.length === 1) {
        // Si le type n'existe plu sdans le dico on retourne la clé
        var resourceType = settings.customer.typeOf.resource.findBy('id', profileTypesOf[0]) || {
          value: profileTypesOf[0]
        };
        return resourceType.value;
      }
      return this.get('i18n').t('appdigitalworkplacecategories:profileTypesOf.count', {
        count: profileTypesOf.length
      });
    }).readOnly()
  });
  var _default = _exports.default = DocFragment;
});