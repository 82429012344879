define("boondmanager/models/appbackupdatabasecustomer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    downloadCenterAnonymousAccess: _emberData.default.attr('boolean'),
    emailToWarn: _emberData.default.attr('string'),
    frequency: _emberData.default.attr(),
    allowManualBackup: _emberData.default.attr('boolean'),
    lastBackupDate: _emberData.default.attr('string'),
    backupState: _emberData.default.attr('string'),
    dataToBackup: _emberData.default.attr('string'),
    mainManager: _emberData.default.belongsTo('resource')
  });
});