define("boondmanager/models/reference", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    startMonth: _emberData.default.attr('number'),
    startYear: _emberData.default.attr('number'),
    endMonth: _emberData.default.attr('number'),
    endYear: _emberData.default.attr('number'),
    skills: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    /** PROPERTIES **/
    edition: false,
    /* COMPUTED */
    row: Ember.computed('originalID', {
      get: function get() {
        return this.get('originalID');
      },
      set: function set(_, value) {
        return value;
      }
    }),
    startDate: Ember.computed('startMonth', 'startYear', function () {
      var _this$get;
      var _startDateLabel = '';
      if (this.get('startMonth')) {
        _startDateLabel = this.getMonthLabel(this.get('startMonth')) + ' ';
      }
      return _startDateLabel + ((_this$get = this.get('startYear')) !== null && _this$get !== void 0 ? _this$get : '');
    }),
    endDate: Ember.computed('endMonth', 'endYear', function () {
      var _this$get2;
      var _endDateLabel = this.get('endMonth') ? this.getMonthLabel(this.get('endMonth')) + ' ' : '';
      _endDateLabel += !this.get('endYear') && this.get('startYear') ? this.get('i18n').t('common:dateAndTime.today') : '';
      return _endDateLabel + ((_this$get2 = this.get('endYear')) !== null && _this$get2 !== void 0 ? _this$get2 : '');
    }),
    getMonthLabel: function getMonthLabel(monthNumber) {
      var i18n = this.get('i18n');
      var labels = [i18n.t('common:dateAndTime.months.january'), i18n.t('common:dateAndTime.months.february'), i18n.t('common:dateAndTime.months.march'), i18n.t('common:dateAndTime.months.april'), i18n.t('common:dateAndTime.months.may'), i18n.t('common:dateAndTime.months.june'), i18n.t('common:dateAndTime.months.july'), i18n.t('common:dateAndTime.months.august'), i18n.t('common:dateAndTime.months.september'), i18n.t('common:dateAndTime.months.october'), i18n.t('common:dateAndTime.months.november'), i18n.t('common:dateAndTime.months.december')];
      return labels[parseInt(monthNumber) - 1];
    },
    editReference: function editReference() {
      if (!this.get('edition')) {
        this.set('originalReference', {
          title: this.get('title'),
          company: this.get('company'),
          location: this.get('location'),
          startMonth: this.get('startMonth'),
          startYear: this.get('startYear'),
          endMonth: this.get('endMonth'),
          endYear: this.get('endYear'),
          skills: this.get('skills'),
          description: this.get('description')
        });
        this.set('edition', true);
      }
    },
    cancelEditReference: function cancelEditReference() {
      var originalReference = this.get('originalReference');
      this.set('title', originalReference.title);
      this.set('company', originalReference.company);
      this.set('location', originalReference.location);
      this.set('startMonth', originalReference.startMonth);
      this.set('startYear', originalReference.startYear);
      this.set('endMonth', originalReference.endMonth);
      this.set('endYear', originalReference.endYear);
      this.set('skills', originalReference.skills);
      this.set('description', originalReference.description);
      this.set('edition', false);
    },
    confirmEditReference: function confirmEditReference() {
      this.set('edition', false);
      this.set('originalReference', undefined);
    }
  });
});