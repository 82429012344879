define("boondmanager/models/widget", ["exports", "ember-data", "boondmanager/utils/logger", "numeral", "boondmanager/utils/financial", "boondmanager/models/basegadget", "boondmanager/models/appdigitalworkplacenews"], function (_exports, _emberData, _logger, _numeral, _financial, _basegadget, _appdigitalworkplacenews) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  _numeral.default.register('format', 'day', {
    regexps: {
      format: /(d)/,
      unformat: /(d)/
    },
    format: function format(value, _format, roundingFunction) {
      var space = _numeral.default._.includes(_format, ' d') ? ' ' : '',
        output;

      // check for space before customFormat
      _format = _format.replace(/\s?\d/, '');
      output = _numeral.default._.numberToFormat(value, _format, roundingFunction);
      if (_numeral.default._.includes(output, ')')) {
        output = output.split('');
        output.splice(-1, 0, space + 'd');
        output = output.join('');
      } else {
        output = output + space + 'd';
      }
      return output;
    },
    unformat: function unformat(string) {
      return _numeral.default._.stringToNumber(string);
    }
  });
  _numeral.default.register('format', 'hour', {
    regexps: {
      format: /(h)/,
      unformat: /(h)/
    },
    format: function format(value, _format2, roundingFunction) {
      var space = _numeral.default._.includes(_format2, ' h') ? ' ' : '',
        output;

      // check for space before customFormat
      _format2 = _format2.replace(/\s?h/, '');
      output = _numeral.default._.numberToFormat(value, _format2, roundingFunction);
      if (_numeral.default._.includes(output, ')')) {
        output = output.split('');
        output.splice(-1, 0, space + 'h');
        output = output.join('');
      } else {
        output = output + space + 'h';
      }
      return output;
    },
    unformat: function unformat(string) {
      return _numeral.default._.stringToNumber(string);
    }
  });
  var _default = _exports.default = _basegadget.default.extend({
    typeOf: _emberData.default.attr('string'),
    graph: _emberData.default.attr('string'),
    target: _emberData.default.attr(),
    period: _emberData.default.attr('string'),
    periodDynamic: _emberData.default.attr('string'),
    periodDynamicParameters: _emberData.default.attr('array'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    values: _emberData.default.attr(),
    groupedScorecards: _emberData.default.attr(),
    height: _emberData.default.attr(),
    settingsService: Ember.inject.service('settings'),
    currentUser: Ember.inject.service('current-user'),
    i18n: Ember.inject.service('i18n'),
    nbCategoryDocuments: Ember.computed('values.[]', function () {
      var _values$nbDocuments;
      var values = this.get('values');
      return (_values$nbDocuments = values.nbDocuments) !== null && _values$nbDocuments !== void 0 ? _values$nbDocuments : 0;
    }),
    news: Ember.computed('values.[]', function () {
      var _values$news;
      var values = this.get('values');
      var newsList = (_values$news = values.news) !== null && _values$news !== void 0 ? _values$news : [];
      var _iterator = _createForOfIteratorHelper(newsList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var news = _step.value;
          news.createdAgoLabel = (0, _appdigitalworkplacenews.createdAgoLabel)(news.creationDate);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return newsList;
    }),
    chartTitle: Ember.computed('graph', 'typeOf', 'target', 'i18n._locale', function () {
      var target = this.get('target');
      var scorecard;
      var title;
      switch (this.get('typeOf')) {
        case 'funnel':
        case 'bar':
        case 'pie':
          return this.getGraphName(this.get('graph'));
        case 'solidgauge':
        case 'gauge':
          scorecard = this.get('settingsService').reporting.category[target.scorecard.category]['scorecards'][target.scorecard.reference];
          title = scorecard.abbr || scorecard.title;
          if (scorecard.i18nKey) {
            title += ' ' + scorecard.i18nKey.findBy('id', target.scorecard.dictionaryId).value;
          }
          return title;
        case 'digitalWorkplace':
          return this.get('i18n').t('models:gadget.attributes.widgets.values', {
            returnObjects: true
          }).findBy('id', 'digitalWorkplace').value;
        default:
          _logger.default.error('chart type undefined');
      }
    }),
    chartOptions: Ember.computed('typeOf', 'graph', 'target', 'period', 'periodDynamic', 'periodDynamicParameters', 'startDate', 'endDate', 'i18n._locale', function () {
      switch (this.get('typeOf')) {
        case 'funnel':
          return this.funnelOption();
        case 'bar':
          return this.barOption();
        case 'pie':
          return this.pieOption();
        case 'solidgauge':
          return this.solidgaugeOption();
        case 'gauge':
          return this.gaugeOption();
        default:
          _logger.default.error('chart type undefined');
      }
    }),
    chartData: Ember.computed('values.[]', 'i18n._locale', function () {
      switch (this.get('typeOf')) {
        case 'funnel':
          return this.funnelData();
        case 'bar':
          return this.barData();
        case 'pie':
          return this.pieData();
        case 'solidgauge':
        case 'gauge':
          return this.gaugeData();
        default:
          _logger.default.error('chart type undefined');
      }
    }),
    funnelOption: function funnelOption() {
      var chart = this.getChartOption();
      chart.marginRight = 150;
      return {
        title: this.getSubTitleOption(),
        subtitle: null,
        credits: this.getCreditsOption(),
        navigation: this.getNavigationOption(),
        chart: chart,
        lang: this.getLangOption(),
        tooltip: {
          formatter: this.getTooltipFormatter()
        },
        plotOptions: {
          funnel: {
            dataLabels: {
              crop: false,
              overflow: 'allow'
            }
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: this.getDataFormatter(),
              padding: 0,
              softConnector: true
            },
            center: ['40%', '50%'],
            neckWidth: '30%',
            neckHeight: '25%',
            width: '80%'
          }
        },
        legend: {
          enabled: false
        },
        responsive: this.getResponsiveOption()
      };
    },
    funnelData: function funnelData() {
      var _this = this;
      var graph = this.get('graph');
      var values = this.get('values');
      var groupedScorecards = this.get('groupedScorecards');
      var i18n = this.get('i18n');
      var data = [];
      var groupedValues = [];
      var exchangeRate = this.get('currentUser.agency.exchangeRate');
      var exchangeRates = [];
      if (exchangeRate !== 1) {
        exchangeRates.push(1 / exchangeRate);
      }
      values.forEach(function (value) {
        value.value = parseFloat(value.value);
        if (value.scorecard.typeOf === 'money') {
          value.value = (0, _financial.applyExchangeRates)(value.value, exchangeRates);
        }
        var title = i18n.exists("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".abbr")) ? i18n.t("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".abbr")) : i18n.t("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".title"));
        var typeOf = 'normal';
        var addValue = true;
        var groupedIndexScorecard = -1;
        switch (graph) {
          case 'salesFunnel':
            groupedIndexScorecard = groupedScorecards.meeting.findIndex(function (tmpScorecard) {
              return tmpScorecard.category === value.scorecard.category && tmpScorecard.reference === value.scorecard.reference && tmpScorecard.dictionaryId === value.scorecard.dictionaryId;
            });
            if (groupedIndexScorecard !== -1) {
              typeOf = 'meeting';
              var groupedIndexValue = groupedValues.findIndex(function (tmpGroup) {
                return tmpGroup.typeOf === typeOf;
              });
              if (groupedIndexValue !== -1) {
                addValue = false;
                groupedValues[groupedIndexValue].value += value.value;
              } else {
                var contactActions = _this.get('settingsService').customer.action.contact;
                var allTitle = [];
                var dictionaryIds = groupedScorecards.meeting.map(function (scorecard) {
                  return scorecard.dictionaryId;
                });
                contactActions.forEach(function (candidateAction) {
                  if (dictionaryIds.indexOf(candidateAction.id) > -1) {
                    allTitle.push(candidateAction.value);
                  }
                });
                title = allTitle.map(function (titlePart) {
                  return "".concat(i18n.t('components:bmHighcharts.nbTitle'), " ").concat(titlePart);
                }).join(', ');
              }
            }
            groupedIndexScorecard = groupedScorecards.clientPresentation.findIndex(function (tmpScorecard) {
              return tmpScorecard.category === value.scorecard.category && tmpScorecard.reference === value.scorecard.reference && tmpScorecard.dictionaryId === value.scorecard.dictionaryId;
            });
            if (groupedIndexScorecard !== -1) {
              typeOf = 'clientPresentation';
              var _groupedIndexValue = groupedValues.findIndex(function (tmpGroup) {
                return tmpGroup.typeOf === typeOf;
              });
              if (_groupedIndexValue !== -1) {
                addValue = false;
                groupedValues[_groupedIndexValue].value += value.value;
              } else {
                title = i18n.t('components:bmHighcharts.groupedScorecards.clientPresentation');
              }
            }
            break;
          case 'recruitmentsFunnel':
            groupedIndexScorecard = groupedScorecards.interview.findIndex(function (tmpScorecard) {
              return tmpScorecard.category === value.scorecard.category && tmpScorecard.reference === value.scorecard.reference && tmpScorecard.dictionaryId === value.scorecard.dictionaryId;
            });
            if (groupedIndexScorecard !== -1) {
              typeOf = 'interview';
              var _groupedIndexValue2 = groupedValues.findIndex(function (tmpGroup) {
                return tmpGroup.typeOf === typeOf;
              });
              if (_groupedIndexValue2 !== -1) {
                addValue = false;
                groupedValues[_groupedIndexValue2].value += value.value;
              } else {
                var candidateActions = _this.get('settingsService').customer.action.candidate;
                var _allTitle = [];
                var _dictionaryIds = groupedScorecards.interview.map(function (scorecard) {
                  return scorecard.dictionaryId;
                });
                candidateActions.forEach(function (candidateAction) {
                  if (_dictionaryIds.indexOf(candidateAction.id) > -1) {
                    _allTitle.push(candidateAction.value);
                  }
                });
                title = _allTitle.map(function (titlePart) {
                  return "".concat(i18n.t('components:bmHighcharts.nbTitle'), " ").concat(titlePart);
                }).join(', ');
              }
            }
            break;
        }
        if (addValue) {
          groupedValues.push({
            typeOf: typeOf,
            title: title,
            value: value.value
          });
        }
      });
      groupedValues.forEach(function (value) {
        data.push([value.title, value.value]);
      });
      return [{
        name: this.getGraphName(this.get('graph')),
        data: data
      }];
    },
    barOption: function barOption() {
      return {
        title: this.getSubTitleOption(),
        subtitle: null,
        credits: this.getCreditsOption(),
        navigation: this.getNavigationOption(),
        chart: this.getChartOption(),
        lang: this.getLangOption(),
        xAxis: {
          categories: ['']
        },
        yAxis: {
          title: {
            text: this.getYAxisTitleOption(),
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        tooltip: {
          formatter: this.getTooltipFormatter()
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              color: '#000000',
              style: {
                textOutline: 'none'
              },
              formatter: this.getDataFormatter()
            }
          }
        },
        legend: {
          layout: 'horizontal',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          borderWidth: 1,
          backgroundColor: '#FFFFFF',
          shadow: true
        },
        responsive: this.getResponsiveOption()
      };
    },
    barData: function barData() {
      var values = this.get('values');
      var i18n = this.get('i18n');
      var data = [];
      var exchangeRate = this.get('currentUser.agency.exchangeRate');
      var exchangeRates = [];
      if (exchangeRate !== 1) {
        exchangeRates.push(1 / exchangeRate);
      }
      values.forEach(function (value) {
        value.value = parseFloat(value.value);
        if (value.scorecard.typeOf === 'money') {
          value.value = (0, _financial.applyExchangeRates)(value.value, exchangeRates);
        }
        data.push({
          name: i18n.exists("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".abbr")) ? i18n.t("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".abbr")) : i18n.t("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".title")),
          data: [value.value]
        });
      });
      return data;
    },
    pieOption: function pieOption() {
      var chart = this.getChartOption();
      chart.plotBackgroundColor = null;
      chart.plotBorderWidth = null;
      chart.plotShadow = false;
      return {
        legend: {
          useHTML: true
        },
        title: this.getSubTitleOption(),
        subtitle: null,
        credits: this.getCreditsOption(),
        navigation: this.getNavigationOption(),
        chart: chart,
        lang: this.getLangOption(),
        tooltip: {
          formatter: this.getTooltipFormatter()
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              formatter: this.getDataFormatter(),
              style: {
                color: 'black'
              },
              padding: 0
            }
          }
        },
        responsive: this.getResponsiveOption()
      };
    },
    pieData: function pieData() {
      var _this2 = this;
      var graph = this.get('graph');
      var values = this.get('values');
      var i18n = this.get('i18n');
      var data = [];
      var exchangeRate = this.get('currentUser.agency.exchangeRate');
      var exchangeRates = [];
      if (exchangeRate !== 1) {
        exchangeRates.push(1 / exchangeRate);
      }
      values.forEach(function (value) {
        value.value = parseFloat(value.value);
        if (value.scorecard.typeOf === 'money') {
          value.value = (0, _financial.applyExchangeRates)(value.value, exchangeRates);
        }
        var title = i18n.exists("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".abbr")) ? i18n.t("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".abbr")) : i18n.t("reporting:categories.".concat(value.scorecard.category, ".scorecards.").concat(value.scorecard.reference, ".title"));
        if (typeof value.scorecard.dictionaryId !== 'undefined') {
          var dict = {};
          switch (graph) {
            case 'opportunitiesDistribution':
              dict = _this2.get('settingsService').customer.state.opportunity;
              break;
            case 'candidatesDistribution':
              dict = _this2.get('settingsService').customer.state.candidate;
              break;
          }
          if (typeof dict === 'string') title = '';else {
            var stateFound = dict.find(function (state) {
              return state.id === value.scorecard.dictionaryId;
            });
            title = stateFound ? stateFound.value : "".concat(i18n.t('common:state'), "_").concat(value.scorecard.dictionaryId);
          }
        }
        data.push({
          name: title,
          y: value.value
        });
      });
      return [{
        name: this.getGraphName(this.get('graph')),
        colorByPoint: true,
        data: data
      }];
    },
    gaugeOption: function gaugeOption() {
      var target = this.get('target');
      var chart = this.getChartOption();
      chart.plotBackgroundColor = null;
      chart.plotBackgroundImage = null;
      chart.plotBorderWidth = 0;
      chart.plotShadow = false;
      var yAxis = {
        min: 0,
        max: target.value * 2,
        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',
        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#666',
        labels: {
          step: 2,
          rotation: 'auto',
          formatter: function formatter() {
            //Fonction qui permet de définir l'unité de l'axe (en k, M ...)
            var numericSymbols = this.chart.options.lang.numericSymbols,
              i = numericSymbols && numericSymbols.length,
              value = this.value,
              ret;
            if (this.value > 1000) {
              while (i-- && typeof ret === 'undefined') {
                var multi = Math.pow(1000, i + 1);
                if (value * 10 % multi !== value * 10) {
                  ret = Highcharts.numberFormat(value / multi, -1, this.chart.options.lang.decimalPoint, this.chart.options.lang.thousandsSep) + numericSymbols[i];
                }
              }
            } else {
              ret = this.value;
            }
            return ret;
          }
        },
        title: null,
        plotBands: [{
          from: 0,
          to: target.value,
          color: ['>', '>='].indexOf(target.operator) > -1 ? '#DF5353' : '#55BF3B'
        }, {
          from: target.value,
          to: target.value * 2,
          color: ['<', '<='].indexOf(target.operator) > -1 ? '#DF5353' : '#55BF3B'
        }]
      };
      this.get('values').forEach(function (value) {
        if (value.value > yAxis.max) {
          yAxis.max = value.value;
          yAxis.plotBands[1].to = yAxis.max;
        }
      });
      return {
        title: this.getSubTitleOption(),
        subtitle: null,
        credits: this.getCreditsOption(),
        navigation: this.getNavigationOption(),
        chart: chart,
        lang: this.getLangOption(),
        pane: {
          center: ['50%', '50%'],
          size: '90%',
          startAngle: -150,
          endAngle: 150,
          background: [{
            backgroundColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, '#FFF'], [1, '#333']]
            },
            borderWidth: 0,
            outerRadius: '108%'
          }, {
            backgroundColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, '#333'], [1, '#FFF']]
            },
            borderWidth: 1,
            outerRadius: '106%'
          }, {
            // default background
          }, {
            backgroundColor: '#DDD',
            borderWidth: 0,
            outerRadius: '104%',
            innerRadius: '102%'
          }]
        },
        yAxis: yAxis,
        tooltip: {
          enabled: false
        },
        responsive: this.getResponsiveOption()
      };
    },
    solidgaugeOption: function solidgaugeOption() {
      var target = this.get('target');
      var chart = this.getChartOption();
      chart.plotBackgroundColor = null;
      chart.plotBackgroundImage = null;
      chart.plotBorderWidth = 0;
      chart.plotShadow = false;
      var yAxis = {
        min: 0,
        max: target.value,
        lineWidth: 0,
        minorTickInterval: null,
        tickPixelInterval: null,
        tickWidth: 0,
        title: null,
        labels: {
          y: 16
        },
        stops: [[0.1, '#DF5353'],
        // red
        [0.5, '#DDDF0D'],
        // yellow
        [0.9, '#55BF3B'] // green
        ]
      };

      return {
        title: this.getSubTitleOption(),
        subtitle: null,
        credits: this.getCreditsOption(),
        navigation: this.getNavigationOption(),
        chart: chart,
        lang: this.getLangOption(),
        pane: {
          center: ['50%', '85%'],
          size: '140%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        yAxis: yAxis,
        tooltip: {
          enabled: false
        },
        responsive: this.getResponsiveOption()
      };
    },
    gaugeData: function gaugeData() {
      var values = this.get('values');
      var data = [];
      var chartTitle = this.get('chartTitle');
      var dataFormatter = this.getDataFormatter();
      var exchangeRate = this.get('currentUser.agency.exchangeRate');
      var exchangeRates = [];
      if (exchangeRate !== 1) {
        exchangeRates.push(1 / exchangeRate);
      }
      values.forEach(function (value) {
        if (value.scorecard.typeOf === 'day|hour|day') {
          var tabValues = value.value ? value.value.split('|') : 0;
          value.value = 0;
          if (tabValues.length === 3) {
            value.value += parseFloat(tabValues[0]) + parseFloat(tabValues[1]) / 24 + parseFloat(tabValues[2]);
          }
        } else {
          value.value = parseFloat(value.value);
          if (value.scorecard.typeOf === 'money') {
            value.value = (0, _financial.applyExchangeRates)(value.value, exchangeRates);
          }
        }
        data.push({
          name: chartTitle,
          data: [Math.round(value.value)],
          dataLabels: {
            formatter: dataFormatter
          }
        });
      });
      return data;
    },
    getDataFormatter: function getDataFormatter() {
      return function () {
        var value = this.y;
        _numeral.default.localeData().delimiters = this.series.chart.options.lang.boondManagerDataDelimiters;
        switch (this.series.chart.options.lang.boondmanagerDataTypeOf) {
          case 'percentage':
            value = value / 100;
            break;
          case 'money':
          case 'hour':
          case 'day':
          case 'day|hour|day':
            // force currency symbol :/
            // cf https://github.com/adamwdraper/Numeral-js/issues/165
            _numeral.default.localeData().currency = {
              symbol: this.series.chart.options.lang.boondManagerDataSymbol
            };
            break;
        }
        var n = !(Math.abs(value) < 1e-6) ? (0, _numeral.default)(value) : (0, _numeral.default)(0);
        var output = n.format(this.series.chart.options.lang.boondManagerDataFormat);
        switch (this.series.chart.options.chart.type) {
          case 'funnel':
          case 'pie':
            return '<strong>' + this.point.name + this.series.chart.options.lang.boondManagerDataPointNameSeparator + output + '</strong>';
          default:
            return output;
        }
      };
    },
    getTooltipFormatter: function getTooltipFormatter() {
      return function () {
        var n = !(Math.abs(this.y) < 1e-6) ? (0, _numeral.default)(this.y) : (0, _numeral.default)(0);
        _numeral.default.localeData().delimiters = this.series.chart.options.lang.boondManagerDataDelimiters;
        switch (this.series.chart.options.lang.boondmanagerDataTypeOf) {
          case 'money':
          case 'hour':
          case 'day':
          case 'day|hour|day':
            // force currency symbol :/
            // cf https://github.com/adamwdraper/Numeral-js/issues/165
            _numeral.default.localeData().currency = {
              symbol: this.series.chart.options.lang.boondManagerDataSymbol
            };
            break;
        }
        var output = n.format(this.series.chart.options.lang.boondManagerDataFormat);
        switch (this.series.chart.options.chart.type) {
          case 'bar':
            return '<span style="font-size: 10px">' + this.series.name + '</span>' + this.series.chart.options.lang.boondManagerDataPointNameSeparator + '<strong>' + output + '</strong>';
          case 'funnel':
          case 'pie':
            return '<span style="font-size: 10px">' + this.point.name + '</span>' + this.series.chart.options.lang.boondManagerDataPointNameSeparator + '<strong>' + output + '</strong>';
          default:
            return '<strong>' + output + '</strong>';
        }
      };
    },
    getResponsiveOption: function getResponsiveOption() {
      return {
        rules: [{
          condition: {
            maxWidth: 320
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      };
    },
    getYAxisTitleOption: function getYAxisTitleOption() {
      var typeOf = this.get('values').length ? this.get('values')[0].scorecard.typeOf : null;
      switch (typeOf) {
        case 'money':
          {
            var currency = this.get('currentUser.agency.currency');
            return this.get('settingsService').customer.currency.findBy('id', currency).symbol;
          }
        case 'percentage':
          return '%';
        case 'hour':
          return this.get('i18n').t('common:numeral.day.sign');
        case 'day':
          return this.get('i18n').t('common:numeral.hour.sign');
        default:
          return '';
      }
    },
    getSubTitleOption: function getSubTitleOption() {
      return {
        text: this.getPeriodName(),
        style: {
          fontSize: '11px'
        }
      };
    },
    getCreditsOption: function getCreditsOption() {
      return {
        enabled: false
      };
    },
    getNavigationOption: function getNavigationOption() {
      return {
        buttonOptions: {
          y: -7
        }
      };
    },
    getChartOption: function getChartOption() {
      return {
        className: this.get('typeOf'),
        type: this.get('typeOf')
      };
    },
    getLangOption: function getLangOption() {
      var i18n = this.get('i18n');
      var dataFormat = 'common:numeral.number.default';
      var symbol = this.get('settingsService').customer.currency.findBy('id', this.get('currentUser.agency.currency')).symbol;
      var typeOf = this.get('values').length ? this.get('values')[0].scorecard.typeOf : null;
      switch (typeOf) {
        case 'money':
          dataFormat = 'common:numeral.currency.rounded';
          break;
        case 'percentage':
          dataFormat = 'common:numeral.percentage.rounded';
          break;
        case 'hour':
          dataFormat = 'common:numeral.hour.rounded';
          symbol = i18n.t('common:numeral.hour.sign');
          break;
        case 'day':
          dataFormat = 'common:numeral.day.rounded';
          symbol = i18n.t('common:numeral.day.sign');
          break;
        case 'day|hour|day':
          dataFormat = 'common:numeral.day.rounded';
          symbol = i18n.t('common:numeral.day.sign');
          break;
      }
      return {
        decimalPoint: i18n.t('common:numeral.delimiters.decimal'),
        thousandsSep: i18n.t('common:numeral.delimiters.thousands'),
        numericSymbols: Highcharts.getOptions().lang.numericSymbols,
        boondmanagerDataTypeOf: typeOf,
        boondManagerDataSymbol: symbol,
        boondManagerDataFormat: i18n.t(dataFormat),
        boondManagerDataPointNameSeparator: i18n.t('components:bmHighcharts.pointNameSeparator'),
        boondManagerDataDelimiters: {
          decimal: i18n.t('common:numeral.delimiters.decimal'),
          //GRI common:number
          thousands: i18n.t('common:numeral.delimiters.thousands') //GRI common:number
        }
      };
    },
    getPeriodName: function getPeriodName() {
      if (!this.get('period')) return '';
      if (this.get('period') === 'periodDynamic' && !this.get('periodDynamic')) return '';
      var periods = !this.get('target') ? this.get('settingsService').bm.settings.intranet.dashboard.period.options : this.get('settingsService').bm.settings.targets.periodicity.options;
      var searchPeriod;
      if (this.get('target')) {
        searchPeriod = this.get('target.periodType');
      } else {
        searchPeriod = this.get('period') === 'periodDynamic' ? this.get('periodDynamic') : this.get('period');
        var periodDynamicParameters = this.get('periodDynamicParameters');
        switch (searchPeriod) {
          case 'lastCustomPeriod':
          case 'nextCustomPeriod':
            return this.get('i18n').t("components:filterPeriod.".concat(searchPeriod, "Labels.").concat(periodDynamicParameters[1]), {
              count: periodDynamicParameters[0]
            });
        }
      }
      var period = periods.find(function (periodicity) {
        return periodicity.id === searchPeriod;
      });
      if (typeof period === 'undefined') {
        if (this.get('startDate') && this.get('endDate')) {
          return this.get('startDate').format('DD/MM/YY') + ' - ' + this.get('endDate').format('DD/MM/YY');
        } else {
          return '';
        }
      } else {
        return period.value + (this.get('target') && this.get('target.date') ? ' - ' + this.get('target.date') : '');
      }
    },
    /**
     * Nom du graph en fonction de son id
     * @param graph
     * @returns {string}
     */
    getGraphName: function getGraphName(graph) {
      var titles = this.get('i18n').t('models:gadget.attributes.widgets.values', {
        returnObjects: true
      });
      var title = '';
      if (titles) {
        var valueFound = titles.findBy('id', graph);
        if (valueFound) title = valueFound.value;
      }
      return title;
    }
  });
});