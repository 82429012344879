define("boondmanager/models/sharedentityfragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    allManagers: _emberData.default.attr('boolean', {
      default: false
    }),
    allAgenciesTypesOf: _emberData.default.attr('array'),
    resources: _emberData.default.attr('array'),
    agenciesTypesOf: _emberData.default.attr('array'),
    numberOfSharings: _emberData.default.attr('number', {
      default: 0
    })
  });
});