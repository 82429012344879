define("boondmanager/models/appadvancedcustomerscompany", ["exports", "ember-data", "boondmanager/models/customer"], function (_exports, _emberData, _customer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppAdvancedCustomersCompany = _customer.default.extend({
    reportingModules: _emberData.default.attr(),
    activeResources: _emberData.default.hasMany('resource'),
    owners: _emberData.default.hasMany('resource'),
    publicApps: _emberData.default.hasMany('app'),
    developpedApps: _emberData.default.hasMany('app'),
    managersWithAdminAccess: _emberData.default.hasMany('appadvancedcustomersresource')
  });
  AppAdvancedCustomersCompany.reopenClass({
    prefix: 'CSOC'
  });
  var _default = _exports.default = AppAdvancedCustomersCompany;
});