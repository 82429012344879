define("boondmanager/services/apm-metrics", ["exports", "boondmanager/config/environment", "@elastic/apm-rum", "boondmanager/utils/logger"], function (_exports, _environment, _apmRum, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    apm: null,
    currentTransaction: null,
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    spans: {},
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */init: function init() {
      this._super.apply(this, arguments);
      var endURLRegex = new RegExp(_environment.default.rootURL + '$');
      var backOrigin = _environment.default.apiURL.replace(endURLRegex, '');
      var apmConfig = {
        serviceName: 'BoondManager FrontEnd',
        serverUrl: _environment.default.apm.serverUrl,
        serviceVersion: _environment.default.version,
        active: _environment.default.apm.enabled,
        distributedTracingOrigins: [backOrigin],
        pageLoadTransactionName: 'bootstrap',
        environment: _environment.default.environment,
        instrument: false,
        breakdownMetrics: true
        //disableInstrumentations: ['route-change']
      };

      //apmConfig.serverUrl = 'http://elk.wishgroupe.com:8200';

      var apm = (0, _apmRum.init)(apmConfig);
      //apm.setInitialPageLoadName('boostrap');

      /*
      apm.observe('transaction:start', function(transaction) {
      	console.log('APM observer transaction:start', transaction);
      });
      apm.observe('transaction:end', function(transaction) {
      	console.log('APM observer transaction:end', transaction);
      });
      */
      this.set('apm', apm);

      //this.attachErrors();
    },
    setUserContext: function setUserContext(context) {
      this.get('apm').setUserContext(context);
    },
    getCurrentTransaction: function getCurrentTransaction() {
      var currentTransaction = this.get('apm').getCurrentTransaction();
      if (!currentTransaction) {
        currentTransaction = this.get('currentTransaction');
      }
      return currentTransaction;
    },
    getTraceId: function getTraceId() {
      //const apm = this.get('apm');
      var transaction = this.getCurrentTransaction();
      return transaction ? transaction.type !== 'page-load' && transaction.traceId : '';
    },
    getDistributedTracingID: function getDistributedTracingID(span) {
      return ['00', span.traceId, span.id, '00'].join('-');
    },
    routeLoaded: 0,
    loadRoute: function loadRoute(path) {
      this.set('routeLoaded', this.get('routeLoaded') + 1);
      var transaction = null;
      if (this.get('routeLoaded') === 1) {
        // premiere route
        this.get('apm').setInitialPageLoadName(path);
        transaction = this.get('apm').startTransaction(path, 'page-load', {
          managed: true
        });
        this.set('currentTransaction', transaction);
      } else {
        //console.info('APM loadRoute', this.get('routeLoaded'));
        transaction = this.get('apm').startTransaction(path, 'route-change', {});
        this.set('currentTransaction', transaction);
      }

      // on ajoute un label pour avoir le customer, pas la peine de bloquer le process, ce traitement peut être async
      if (this.get('session.isAuthenticated')) {
        var hasCustomerInterface = !!this.get('session.data.authenticated.meta.customer');
        transaction.addLabels({
          login: this.get('session.data.authenticated.meta.login')
        });
        if (hasCustomerInterface) {
          this.get('currentUser').then(function (currentUser) {
            transaction.addLabels({
              customer: currentUser.get('agency.code')
            });
          });
        }
      }
    },
    loadedRoute: function loadedRoute() {
      //console.info('APM loadedRoute', this.get('routeLoaded'));
      var transaction = this.get('currentTransaction');
      if (transaction) {
        transaction.end();
        if (this.get('routeLoaded') === 1) {
          //transaction.captureBreakdown();
        }
      }
    },
    mark: function mark(key) {
      this.get('apm').getCurrentTransaction().mark(key);
    },
    startSpan: function startSpan(name, type) {
      var sync = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var currentTransaction = this.getCurrentTransaction();
      var span = null;
      if (currentTransaction) {
        if (currentTransaction.ended) {
          _logger.default.debug('APM current transaction ended : startSpan cannot be executed.');
        } else {
          span = currentTransaction.startSpan(name, type, {
            sync: sync
          });
          var key = name + '_' + type;
          var spans = this.get('spans');
          spans[key] = span;
          return span;
        }
      } else {
        /* eslint-disable no-console */
        _logger.default.debug('APM error, no current transaction', name, type);
        /* eslint-enable no-console */
      }
    },
    getSpan: function getSpan(name, type) {
      var key = name + '_' + type;
      var spans = this.get('spans');
      var span = spans[key];
      if (!span) {
        /* eslint-disable no-console */
        console.debug('APM error, no span', name, type);
        /* eslint-enable no-console */
      }

      return span;
    },
    attachErrors: function attachErrors() {
      var _oldOnError = Ember.onerror;
      var apm = this.get('apm');
      Ember.onerror = function EmberOnError(error) {
        //if (error instanceof Error && error.message === 'coucou') return;
        try {
          apm.captureError(error);
        } catch (e) {
          _logger.default.error(e);
          if (typeof _oldOnError === 'function') {
            _oldOnError.call(this, e);
          }
        }
        if (typeof _oldOnError === 'function') {
          _oldOnError.call(this, error);
        }
        if (_environment.default.environment !== 'production') {
          throw error;
        }
      };

      /* eslint-disable ember/new-module-imports */
      /*
      Ember.RSVP.on('error', function(error) {
      	if (error instanceof Error) {
      		apm.captureError(error);
      	}
      	else {
      		apm.captureError(new Error(error));
      	}
      	Logger.error(error);
      });
      */
      /* eslint-ensable ember/new-module-imports */
    }
  });
});