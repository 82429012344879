define("boondmanager/transforms/availability", ["exports", "ember-data", "boondmanager/initializers/availability"], function (_exports, _emberData, _availability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Transform.extend({
    /**
     * Candidate or Resource availability
     *
     * @param {Number|String} serialized value from backend
     *                         * -1     : availability not defined
     *                         * [0..9] : cf api/application/dictionaryz - setting.availability
     *                         * string : date ISO
     *
     * @return {Availability} Candidate or Resource availability
     */
    deserialize: function deserialize(serialized) {
      /* obligé de passer par owner (= instance d'Application) puis de créer
         une instance d'Availability à l'aide de lookup car le Factory
         Availability fait appel à une injection (i18n) et que cela n'est
         possible/supporté que ssi ce Factory est dans un Container, d'où
         notre initializer "availability" qui ajoute le Factory "Availability"
         dans "Application".
      	   cf http://stackoverflow.com/questions/34970881/inject-a-service-into-an-ember-object-not-an-ember-controller
         cf https://fr.slideshare.net/mixonic/containers-di
      */
      var owner = Ember.getOwner(this);
      var availability = owner.lookup('availability:main');
      availability.set('raw', serialized);
      return availability;
    },
    // to backend
    serialize: function serialize(deserialized) {
      if (deserialized instanceof _availability.Availability) {
        var rawValue = deserialized.get('raw');
        return rawValue === 'undefined' ? _availability.NOT_DEFINED : rawValue;
      }
      return _availability.NOT_DEFINED;
    }
  });
});