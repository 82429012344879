define("boondmanager/pods/components/bm-field/radio/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/cachecomputed"], function (_exports, _component, _cachecomputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-radio'],
    classNameBindings: ['multipleInline:bmc-field-radio_multiple-inline'],
    attributeBindings: ['disabled:disabled', 'onChange'],
    isBlankValue: function isBlankValue() {
      return typeof this.get('checked') === 'undefined' || this.get('checked') === null || this.get('checked') === false;
    },
    hasValue: (0, _cachecomputed.default)('value', function () {
      return !this.isBlankValue();
    })
  });
});