define("boondmanager/models/appadvancedcustomersresource", ["exports", "boondmanager/models/resource", "ember-data"], function (_exports, _resource, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsAdvancedCustomersResource = _resource.default.extend({
    modules: _emberData.default.attr(),
    listModules: Ember.computed('modules', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      var modules = this.get('modules');
      modules = modules.map(function (module) {
        return i18n.t('advmodcustomers:view.tabs.modules.' + module);
      });
      return modules.join(', ');
    })
  });
  AppsAdvancedCustomersResource.reopenClass({
    prefix: 'COMP'
  });
  var _default = _exports.default = AppsAdvancedCustomersResource;
});