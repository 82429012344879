define("boondmanager/pods/components/bm-field/content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['bmc-field_content'],
    attributeBindings: ['name:data-name']
  });
});