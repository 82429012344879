define("boondmanager/pods/components/octane/bm-search-filters/fields/contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YtDjB+pr",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-12 col-xl-6\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"name\"],[[30,[36,1],[\"contacts:title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,0],null,[[\"name\",\"selected\",\"onChange\",\"multiple\",\"showSelect\",\"isLocked\",\"showLock\"],[\"contact\",[32,1,[\"bmFieldValue\"]],[32,0,[\"update\"]],true,true,false,false]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"bm-field/contact\",\"t\",\"bm-field\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/contact/template.hbs"
  });
});