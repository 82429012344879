define("boondmanager/pods/components/octane/bm-search-filters/fields/keywords/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ViRULl7L",
    "block": "{\"symbols\":[\"@filter\",\"@keywordsNoSection\"],\"statements\":[[6,[37,5],null,[[\"class\",\"inlineContent\",\"name\",\"help\",\"helpCustomClass\"],[[30,[36,1],[\"bmc-search-field_keywords\",[30,[36,0],[[32,1,[\"keywordsType\",\"options\"]],\" bmc-search-field_keywords-options\"],null],[30,[36,0],[[32,2],\" bmc-field_nosection\"],null],[30,[36,0],[[32,1,[\"keywords\",\"value\"]],\" bm-has-value\"],null]],null],true,[32,0,[\"keywordsName\"]],[30,[36,0],[[32,1,[\"keywordsTooltip\"]],[30,[36,3],[[32,1,[\"keywordsTooltip\"]]],null],false],null],\"keyword-tooltip\"]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"keywordsType\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,4],null,[[\"name\",\"selected\",\"onchange\",\"options\",\"allowClear\",\"placeholder\",\"preventHasValue\"],[\"keywordsTypes\",[30,[36,2],[\"bmi-search\"],null],[32,0,[\"onKeywordsTypeChange\"]],[32,1,[\"keywordsType\",\"options\"]],true,[30,[36,3],[\"common:indifferent\"],null],true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,2],[[30,[36,1],[\"bmi-search \",[30,[36,0],[[32,1,[\"keywords\",\"value\"]],\"highlight\"],null]],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[8,\"octane/bm-field/input\",[[24,3,\"keywords\"],[24,\"data-name\",\"keywords\"]],[[\"@disabled\",\"@keyUp\",\"@value\",\"@autofocus\"],[[32,0,[\"disabled\"]],[32,0,[\"onKeywordsChange\"]],[32,0,[\"modalValue\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"icon\",\"t\",\"bm-field/power-select\",\"bm-field\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/keywords/template.hbs"
  });
});