define("boondmanager/pods/components/octane/bm-pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FXMDEn1M",
    "block": "{\"symbols\":[\"p\",\"&attrs\"],\"statements\":[[11,\"nav\"],[16,0,[31,[\"bmc-pagination\",[30,[36,2],[[32,0,[\"isHidden\"]],\" hidden\"],null]]]],[17,2],[12],[2,\"\\n\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-pagination-wrapper  \",[30,[36,2],[[30,[36,3],[[32,0,[\"count\"]],1],null],\"bm-hidden\",\"\"],null]]]],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,\"data-name\",\"button-pagination-previous\"],[24,0,\"bmb-link bmc-pagination-nav\"],[16,\"aria-label\",[30,[36,4],[\"components:bmPagination.prev\"],null]]],[[\"@disabled\",\"@onClick\"],[[30,[36,2],[[32,0,[\"isFirstDisabled\"]],true],null],[32,0,[\"previous\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],[\"components:bmPagination.prev\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,5],null,[[\"class\",\"noTitle\"],[\"bmc-field_nosection\",true]],[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"name\",\"verticalPosition\",\"options\",\"selected\",\"renderInPlace\",\"onchange\"],[\"bmc-pagination-select\",\"above\",[32,0,[\"pages\"]],[32,0,[\"current\"]],true,[32,0,[\"onPageChange\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[32,1,[\"value\"]]],[2,\" / \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,\"data-name\",\"button-pagination-next\"],[24,0,\"bmb-link bmc-pagination-nav\"],[16,\"aria-label\",[30,[36,4],[\"components:bmPagination.next\"],null]]],[[\"@disabled\",\"@onClick\"],[[30,[36,2],[[32,0,[\"isLastDisabled\"]],true],null],[32,0,[\"next\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,4],[\"components:bmPagination.next\"],null]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"count\",\"bm-field/power-select\",\"if\",\"lte\",\"t\",\"bm-field\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-pagination/template.hbs"
  });
});