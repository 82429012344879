define("boondmanager/pods/components/bm-field/simple-colorpicker/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/cachecomputed", "ember-basic-dropdown/utils/calculate-position"], function (_exports, _component, _cachecomputed, _calculatePosition2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Switch field component.
   *
   * Depend on:
   *   - [ember-toggle](https://github.com/knownasilya/ember-toggle)
   *
   * Component attributes:
   *
   *   * {string}             name          REQUIRED field name
   *   * {boolean}            value         OPTIONAL switch state. Default: false;
   *   * {string}             class         OPTIONAL additional classes applied to the main DOM element
   *   * {function}           onChange      OPTIONAL callback call on switch change state
   *   * {string}             onLabel       OPTIONAL Text of the left side of the switch
   *   * {string}             offLabel      OPTIONAL Text of the right side of the switch
   *   * {boolean}            inline        OPTIONAL is the component inline
   *
   * Example:
   *
   * ```handlebars
   * {{bm-field/simple-colorpicker name="colorpicker" value=getSwitchValue onChange=(action 'onSwitchChange')}}
   * ```
   *
   *
   * @class  FieldSimpleColorpickerComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * Component default __class__ attribute
     *
     * @property classNames
     * @type {Array.<string>}
     * @default  ['bmc-field-simple-colorpicker']
     */
    classNames: ['bmc-field-simple-colorpicker'],
    attributeBindings: ['dataName:data-name'],
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    /**
     * 'didReceiveAttrs' Component hook
     *
     * Check __name__ component attribute
     *
     * @method didReceiveAttrs
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      var onChange = this.get('onChange');
      this._super.apply(this, arguments);

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert("".concat(this, " must have a \"name\" attribute!"), !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert("".concat(this, " \"name\" attribute must be a string!"), Ember.typeOf(name) === 'string')); // check onChange
      (false && !(Ember.typeOf(onChange) === 'function') && Ember.assert("".concat(this, " must have an \"onChange\" function attribute!"), Ember.typeOf(onChange) === 'function'));
    },
    _calculatePosition: function _calculatePosition(trigger, content, destination, options) {
      var offsetTop = 3;
      //const offsetRight = -60;

      // change positioning for power-select depending on space available on screen and options height
      var rect = trigger.getBoundingClientRect();
      var contentHeight = content.clientHeight;
      var positionBottomYBase = rect.y + window.scrollY;
      var positionBottomY = positionBottomYBase + rect.height + offsetTop;
      var visibleHeightLeft = document.documentElement.clientHeight - (contentHeight + rect.y + rect.height);
      var viewPortBottomLeft = window.document.body.clientHeight - (positionBottomY + contentHeight);
      var viewPortTopLeft = positionBottomY - contentHeight;
      /**
       * Si la hauteur de page (scroll compris) est suffisante pour pouvoir afficher l'intégralité
       * des options en dessous du select, on affiche les options vers le bas
       * Si la hauteur de page (scroll compris) est insuffisante pour pouvoir afficher l'intégralité
       * des options en dessous du select mais que l'espace au dessus est insuffisant aussi, on affiche les options vers le bas
       * Dans le cas contraire, on affiche les options vers le haut
       */
      if (viewPortBottomLeft < 0 && viewPortTopLeft >= 0 || viewPortTopLeft >= 0 && visibleHeightLeft < 0) {
        // recompute position because default is below and here we need to display it above
        options.verticalPosition = 'above';
      }
      var originalValue = (0, _calculatePosition2.default)(trigger, content, destination, options);
      // in case of remote search box, originalValue.style is undefined, we force set it
      if (typeof originalValue.style === 'undefined') {
        originalValue.style = [];
      }
      originalValue.style['top'] += 3;
      originalValue.style['top'] += 3;
      return originalValue;
    },
    hasValue: (0, _cachecomputed.default)('value', function () {
      return !this.isBlankValue();
    }),
    isBlankValue: function isBlankValue() {
      return typeof this.get('selected') === 'undefined' || this.get('selected') === null;
    },
    actions: {
      onChange: function onChange(selected) {
        this.get('onChange')(selected);
      }
    }
  });
});