define("boondmanager/models/appextractbitemplate", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable"], function (_exports, _emberData, _base, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsExtractBiTemplate = _base.default.extend(_emberDataCopyable.default, {
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    sqlSelect: _emberData.default.attr('string'),
    sqlFrom: _emberData.default.attr('string'),
    sqlWhere: _emberData.default.attr('string'),
    sqlOrder: _emberData.default.attr('string'),
    sqlId: _emberData.default.attr('string'),
    updateDate: _emberData.default.attr('moment'),
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    sql: Ember.computed('sqlSelect', 'sqlId', 'sqlFrom', 'sqlOrder', 'sqlWhere', function () {
      if (this.get('sqlSelect') && this.get('sqlFrom') && this.get('sqlId')) {
        var query = "SELECT ".concat(this.get('sqlSelect'), " FROM ").concat(this.get('sqlFrom'));
        if (this.get('sqlWhere')) query += " WHERE ".concat(this.get('sqlWhere'));
        if (this.get('sqlId')) query += " GROUP BY ".concat(this.get('sqlId'));
        if (this.get('sqlOrder')) query += " ORDER BY ".concat(this.get('sqlOrder'));
        return query;
      }
      return '';
    })
  });
  var _default = _exports.default = AppsExtractBiTemplate;
});