define("boondmanager/models/additionalturnoverandcostsfragment", ["exports", "boondmanager/utils/logger", "ember-data", "ember-data-model-fragments", "moment", "boondmanager/utils/financial"], function (_exports, _logger, _emberData, _emberDataModelFragments, _moment, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INDIFFERENT_TYPEOF_ADDITIONAL_TURNOVER = void 0;
  var INDIFFERENT_TYPEOF_ADDITIONAL_TURNOVER = _exports.INDIFFERENT_TYPEOF_ADDITIONAL_TURNOVER = -1;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    costsExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // defaltValue() function will only be evaluated when the value is `undefned`
    // and the first time the attribute is retrieved
    date: _emberData.default.attr('moment', {
      defaultValue: function defaultValue() {
        // set defaultValue to the last additionalTurnoverAndCosts date or now (ie. `moment()`)
        var defaultDate = (0, _moment.default)();

        // CAUTION we can not use `newAdditionalTurnoverAndCost.get('owner.additionalTurnoverAndCosts.lastObject')`
        //         because this lastObject is already this one we're creating! And if we get its `date`, we evaluated
        //         again and again this defaultValue() function and generate a 'Max call stack size exceeded' :/
        // #FIXME 'Max call stack size exceeded' when modifying (first?) fragment
        /*const additionalTurnoverAndCosts = newAdditionalTurnoverAndCost.get('owner.additionalTurnoverAndCosts');
        	if (additionalTurnoverAndCosts) {
        	const nb = additionalTurnoverAndCosts.get('length');
        		// test > 1 because the `additionalTurnoverAndCosts` fragment we are building here is already in
        	// `owner.additionalTurnoverAndCosts` array
        	if (nb > 1) {
        		defaultDate = additionalTurnoverAndCosts.objectAt(nb-2).get('date').clone();
        	}
        }*/

        return defaultDate;
      } /*newAdditionalTurnoverAndCost*/
    }),
    purchase: _emberData.default.attr(),
    state: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    turnoverExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    typeOf: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    dependsOn: _emberData.default.belongsTo('base'),
    // owner = project
    owner: _emberDataModelFragments.default.fragmentOwner(),
    // CPs
    originalTurnoverExcludingTax: Ember.computed('turnoverExcludingTax', 'owner.{exchangeRateAgency,exchangeRate}', {
      get: function get() {
        var turnoverExcludingTax = this.get('turnoverExcludingTax');
        var exchangeRateAgency = this.get('owner.exchangeRateAgency');
        var exchangeRate = this.get('owner.exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(1 / exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(1 / exchangeRate);
        }
        return (0, _financial.applyExchangeRates)(turnoverExcludingTax, exchangeRates);
      },
      set: function set(_, newValue /*, oldValue*/) {
        var originalTurnoverExcludingTax = Number(newValue);
        if (Number.isNaN(originalTurnoverExcludingTax)) {
          _logger.default.warn('Additional Turnover and Costs Fragment - SET bad originalTurnoverExcludingTax (NaN - so we set it to 0)', newValue);
          originalTurnoverExcludingTax = 0;
        }
        var exchangeRateAgency = this.get('owner.exchangeRateAgency');
        var exchangeRate = this.get('owner.exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(exchangeRate);
        }
        this.set('turnoverExcludingTax', (0, _financial.applyExchangeRates)(originalTurnoverExcludingTax, exchangeRates));
        return originalTurnoverExcludingTax;
      }
    }),
    originalCostsExcludingTax: Ember.computed('costsExcludingTax', 'owner.{exchangeRateAgency,exchangeRate}', {
      get: function get() {
        var costsExcludingTax = this.get('costsExcludingTax');
        var exchangeRateAgency = this.get('owner.exchangeRateAgency');
        var exchangeRate = this.get('owner.exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(1 / exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(1 / exchangeRate);
        }
        return (0, _financial.applyExchangeRates)(costsExcludingTax, exchangeRates);
      },
      set: function set(_, newValue /*, oldValue*/) {
        var originalCostsExcludingTax = Number(newValue);
        if (Number.isNaN(originalCostsExcludingTax)) {
          _logger.default.warn('Additional Turnover and Costs Fragment - SET bad originalCostsExcludingTax (NaN - so we set it to 0)', newValue);
          originalCostsExcludingTax = 0;
        }
        var exchangeRateAgency = this.get('owner.exchangeRateAgency');
        var exchangeRate = this.get('owner.exchangeRate');
        var exchangeRates = [];
        if (exchangeRateAgency !== 1) {
          exchangeRates.push(exchangeRateAgency);
        }
        if (exchangeRate !== 1) {
          exchangeRates.push(exchangeRate);
        }
        this.set('costsExcludingTax', (0, _financial.applyExchangeRates)(originalCostsExcludingTax, exchangeRates));
        return originalCostsExcludingTax;
      }
    })
  });
});