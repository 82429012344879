define("boondmanager/models/socialnetwork", ["exports", "ember-data-model-fragments", "ember-data"], function (_exports, _emberDataModelFragments, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.socialNetworksList = _exports.default = void 0;
  var socialNetworksList = _exports.socialNetworksList = Ember.A([{
    id: -1,
    network: 'other',
    title: '',
    url: '',
    pattern: null
  }, {
    id: 0,
    network: 'viadeo',
    title: 'Viadeo',
    url: 'https://viadeo.com/fr/profile/',
    pattern: /^(http(s)?:\/\/)?([a-zA-Z]+\.)?viadeo.com\//
  }, {
    id: 1,
    network: 'linkedin',
    title: 'LinkedIn',
    url: 'https://linkedin.com/',
    pattern: /^(http(s)?:\/\/)?([a-zA-Z]+\.)?linkedin.com\//
  }, {
    id: 2,
    network: 'facebook',
    title: 'Facebook',
    url: 'https://facebook.com/',
    pattern: /^(http(s)?:\/\/)?([a-zA-Z]+\.)?facebook.com\//
  }, {
    id: 3,
    network: 'twitter',
    title: 'Twitter',
    url: 'https://twitter.com/',
    pattern: /^(http(s)?:\/\/)?([a-zA-Z]+\.)?twitter.com\//
  }, {
    id: 4,
    network: 'github',
    title: 'Github',
    url: 'https://github.com/',
    pattern: /^(http(s)?:\/\/)?([a-zA-Z]+\.)?github.com\//
  }, {
    id: 5,
    network: 'bitbucket',
    title: 'Bitbucket',
    url: 'https://bitbucket.org/',
    pattern: /^(http(s)?:\/\/)?([a-zA-Z]+\.)?bitbucket.org\//
  }, {
    id: 6,
    network: 'instagram',
    title: 'Instagram',
    url: 'https://instagram.com/',
    pattern: /^(http(s)?:\/\/)?([a-zA-Z]+\.)?instagram.com\//
  }, {
    id: 7,
    network: 'youtube',
    title: 'Youtube',
    url: 'https://youtube.com/',
    pattern: /^(http(s)?:\/\/)?([a-zA-Z]+\.)?youtube.com\//
  }]);
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    network: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    title: Ember.computed('network', function () {
      var network = socialNetworksList.findBy('network', this.get('network'));
      return network.title;
    }).readOnly(),
    guessedNetwork: Ember.computed('url', function () {
      var _this = this;
      var network = 'other';
      socialNetworksList.forEach(function (socialNetwork) {
        var regExp = new RegExp(socialNetwork.pattern);
        if (regExp.test(_this.get('url'))) {
          network = socialNetwork.network;
        }
      });
      return network;
    })
  });
});