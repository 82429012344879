define("boondmanager/pods/components/bm-field/translation/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * bm-field-language component.
   *
   * To be used in languages loop:
   * {{#each model.languages key="@index" as |language index|}}
   *    {{bm-field/language language=language index=index}}
   * {{/each}}
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-translation'],
    classNameBindings: ['errorClass'],
    errorClass: Ember.computed('errors', function () {
      return this.get('errors') ? 'bm-error has-error' : '';
    }),
    languages: Ember.computed('i18n._locale', function () {
      var countries = this.get('i18n').countryList;
      var languages = this.get('i18n').languages;
      return Ember.isArray(countries) && Ember.isArray(languages) ? languages.map(function (language) {
        var countryToFind = '';
        switch (language.id) {
          case 'fr':
            countryToFind = countries.find(function (country) {
              return country.iso === 'FR';
            });
            break;
          case 'en':
            countryToFind = countries.find(function (country) {
              return country.iso === 'GB';
            });
            break;
        }
        return {
          id: language.id,
          value: language.value,
          icon: countryToFind ? "flag flag-".concat(countryToFind.iso.toLowerCase()) : ''
        };
      }) : [];
    }),
    actions: {
      deleteTranslation: function deleteTranslation(index) {
        this.get('deleteTranslation')(index);
      }
    }
  });
});