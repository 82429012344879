define("boondmanager/serializers/configuration", ["exports", "boondmanager/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    attrs: {
      solrSyncEnabled: {
        key: 'BMSOLR.ENABLED'
      },
      solrSearchEnabled: {
        key: 'BMSOLR.SEARCH_ENABLED'
      },
      biSyncEnabled: {
        key: 'KAFKA.ENABLED'
      },
      biSearchEnabled: {
        key: 'TRINO.ENABLED'
      },
      biTrinoValidation: {
        key: 'TRINO.VALIDATION'
      },
      redisClearCacheEnabled: {
        key: 'REDIS.BTN_CLEAR_CACHE_ENABLED'
      },
      apiRoutesDisabled: {
        key: 'SECURITY.BLOCKED_ROUTES'
      }
    }
  });
});