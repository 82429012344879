define("boondmanager/models/customer", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _emberDataCopyable, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Customer = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['company', 'agency', 'mainManager', 'pole']
    }),
    defaultLevelInterval: {
      min: 1,
      max: 100
    },
    defaultLevels: [{
      level: 4,
      amount: 100
    }, {
      level: 5,
      amount: 120
    }, {
      level: 6,
      amount: 138
    }, {
      level: 7,
      amount: 154
    }, {
      level: 8,
      amount: 168
    }, {
      level: 9,
      amount: 185
    }, {
      level: 10,
      amount: 200
    }, {
      level: 12,
      amount: 234
    }, {
      level: 15,
      amount: 275
    }, {
      level: 17,
      amount: 289
    }, {
      level: 20,
      amount: 325
    }, {
      level: 30,
      amount: 360
    }, {
      level: 40,
      amount: 400
    }, {
      level: 50,
      amount: 475
    }, {
      level: 60,
      amount: 540
    }, {
      level: 70,
      amount: 630
    }, {
      level: 80,
      amount: 700
    }, {
      level: 90,
      amount: 787
    }, {
      level: 100,
      amount: 850
    }, {
      level: 110,
      amount: 935
    }, {
      level: 120,
      amount: 990
    }, {
      level: 130,
      amount: 1055
    }, {
      level: 140,
      amount: 1120
    }, {
      level: 150,
      amount: 1185
    }, {
      level: 160,
      amount: 1250
    }, {
      level: 170,
      amount: 1315
    }, {
      level: 180,
      amount: 1380
    }, {
      level: 190,
      amount: 1445
    }, {
      level: 200,
      amount: 1510
    }, {
      level: 210,
      amount: 1575
    }, {
      level: 220,
      amount: 1640
    }, {
      level: 230,
      amount: 1705
    }, {
      level: 240,
      amount: 1770
    }, {
      level: 250,
      amount: 1835
    }, {
      level: 260,
      amount: 1900
    }, {
      level: 270,
      amount: 1965
    }, {
      level: 280,
      amount: 2030
    }, {
      level: 290,
      amount: 2095
    }, {
      level: 300,
      amount: 2160
    }, {
      level: 310,
      amount: 2225
    }, {
      level: 320,
      amount: 2290
    }, {
      level: 330,
      amount: 2355
    }, {
      level: 340,
      amount: 2420
    }, {
      level: 350,
      amount: 2485
    }, {
      level: 360,
      amount: 2550
    }, {
      level: 370,
      amount: 2615
    }, {
      level: 380,
      amount: 2680
    }, {
      level: 390,
      amount: 2745
    }, {
      level: 400,
      amount: 2810
    }, {
      level: 410,
      amount: 2875
    }, {
      level: 420,
      amount: 2940
    }, {
      level: 430,
      amount: 3005
    }, {
      level: 440,
      amount: 3070
    }, {
      level: 450,
      amount: 3135
    }, {
      level: 460,
      amount: 3200
    }, {
      level: 470,
      amount: 3265
    }, {
      level: 480,
      amount: 3330
    }, {
      level: 490,
      amount: 3395
    }, {
      level: 500,
      amount: 3460
    }, {
      level: 510,
      amount: 3525
    }, {
      level: 520,
      amount: 3590
    }, {
      level: 530,
      amount: 3655
    }, {
      level: 540,
      amount: 3720
    }, {
      level: 550,
      amount: 3785
    }, {
      level: 560,
      amount: 3850
    }, {
      level: 570,
      amount: 3915
    }, {
      level: 580,
      amount: 3980
    }, {
      level: 590,
      amount: 4045
    }, {
      level: 600,
      amount: 4110
    }, {
      level: 610,
      amount: 4175
    }, {
      level: 620,
      amount: 4240
    }, {
      level: 630,
      amount: 4305
    }, {
      level: 640,
      amount: 4370
    }, {
      level: 650,
      amount: 4435
    }, {
      level: 660,
      amount: 4500
    }, {
      level: 670,
      amount: 4565
    }, {
      level: 680,
      amount: 4630
    }, {
      level: 690,
      amount: 4695
    }, {
      level: 700,
      amount: 4760
    }, {
      level: 710,
      amount: 4825
    }, {
      level: 720,
      amount: 4890
    }, {
      level: 730,
      amount: 4955
    }, {
      level: 740,
      amount: 5020
    }, {
      level: 750,
      amount: 5085
    }, {
      level: 760,
      amount: 5150
    }, {
      level: 770,
      amount: 5215
    }, {
      level: 780,
      amount: 5280
    }, {
      level: 790,
      amount: 5345
    }, {
      level: 800,
      amount: 5410
    }, {
      level: 810,
      amount: 5475
    }, {
      level: 820,
      amount: 5540
    }, {
      level: 830,
      amount: 5605
    }, {
      level: 840,
      amount: 5670
    }, {
      level: 850,
      amount: 5735
    }, {
      level: 860,
      amount: 5800
    }, {
      level: 870,
      amount: 5865
    }, {
      level: 880,
      amount: 5930
    }, {
      level: 890,
      amount: 5995
    }, {
      level: 900,
      amount: 6060
    }, {
      level: 910,
      amount: 6125
    }, {
      level: 920,
      amount: 6190
    }, {
      level: 930,
      amount: 6255
    }, {
      level: 940,
      amount: 6320
    }, {
      level: 950,
      amount: 6385
    }, {
      level: 960,
      amount: 6450
    }, {
      level: 970,
      amount: 6515
    }, {
      level: 980,
      amount: 6580
    }, {
      level: 990,
      amount: 6645
    }, {
      level: 1000,
      amount: 6710
    }, {
      level: 1010,
      amount: 6775
    }, {
      level: 1020,
      amount: 6840
    }, {
      level: 1030,
      amount: 6905
    }, {
      level: 1040,
      amount: 6970
    }, {
      level: 1050,
      amount: 7035
    }, {
      level: 1060,
      amount: 7100
    }, {
      level: 1070,
      amount: 7165
    }, {
      level: 1080,
      amount: 7230
    }, {
      level: 1090,
      amount: 7295
    }, {
      level: 1100,
      amount: 7360
    }, {
      level: 1110,
      amount: 7425
    }, {
      level: 1120,
      amount: 7490
    }, {
      level: 1130,
      amount: 7555
    }, {
      level: 1140,
      amount: 7620
    }, {
      level: 1150,
      amount: 7685
    }, {
      level: 1160,
      amount: 7750
    }, {
      level: 1170,
      amount: 7815
    }, {
      level: 1180,
      amount: 7880
    }, {
      level: 1190,
      amount: 7945
    }, {
      level: 1200,
      amount: 8010
    }, {
      level: 1210,
      amount: 8075
    }, {
      level: 1220,
      amount: 8140
    }, {
      level: 1230,
      amount: 8205
    }, {
      level: 1240,
      amount: 8270
    }, {
      level: 1250,
      amount: 8335
    }, {
      level: 1260,
      amount: 8400
    }, {
      level: 1270,
      amount: 8465
    }, {
      level: 1280,
      amount: 8530
    }, {
      level: 1290,
      amount: 8595
    }, {
      level: 1300,
      amount: 8660
    }, {
      level: 1310,
      amount: 8725
    }, {
      level: 1320,
      amount: 8790
    }, {
      level: 1330,
      amount: 8855
    }, {
      level: 1340,
      amount: 8920
    }, {
      level: 1350,
      amount: 8985
    }, {
      level: 1360,
      amount: 9050
    }, {
      level: 1370,
      amount: 9115
    }, {
      level: 1380,
      amount: 9180
    }, {
      level: 1390,
      amount: 9245
    }, {
      level: 1400,
      amount: 9310
    }, {
      level: 1410,
      amount: 9375
    }, {
      level: 1420,
      amount: 9440
    }, {
      level: 1430,
      amount: 9505
    }, {
      level: 1440,
      amount: 9570
    }, {
      level: 1450,
      amount: 9635
    }, {
      level: 1460,
      amount: 9700
    }, {
      level: 1470,
      amount: 9765
    }, {
      level: 1480,
      amount: 9830
    }, {
      level: 1490,
      amount: 9895
    }, {
      level: 1500,
      amount: 9960
    }, {
      level: 1510,
      amount: 10025
    }, {
      level: 1520,
      amount: 10090
    }, {
      level: 1530,
      amount: 10155
    }, {
      level: 1540,
      amount: 10220
    }, {
      level: 1550,
      amount: 10285
    }, {
      level: 1560,
      amount: 10350
    }, {
      level: 1570,
      amount: 10415
    }, {
      level: 1580,
      amount: 10480
    }, {
      level: 1590,
      amount: 10545
    }, {
      level: 1600,
      amount: 10610
    }, {
      level: 1610,
      amount: 10675
    }, {
      level: 1620,
      amount: 10740
    }, {
      level: 1630,
      amount: 10805
    }, {
      level: 1640,
      amount: 10870
    }, {
      level: 1650,
      amount: 10935
    }, {
      level: 1660,
      amount: 11000
    }, {
      level: 1670,
      amount: 11065
    }, {
      level: 1680,
      amount: 11130
    }, {
      level: 1690,
      amount: 11195
    }, {
      level: 1700,
      amount: 11260
    }, {
      level: 1710,
      amount: 11325
    }, {
      level: 1720,
      amount: 11390
    }, {
      level: 1730,
      amount: 11455
    }, {
      level: 1740,
      amount: 11520
    }, {
      level: 1750,
      amount: 11585
    }, {
      level: 1760,
      amount: 11650
    }, {
      level: 1770,
      amount: 11715
    }, {
      level: 1780,
      amount: 11780
    }, {
      level: 1790,
      amount: 11845
    }, {
      level: 1800,
      amount: 11910
    }, {
      level: 1810,
      amount: 11975
    }, {
      level: 1820,
      amount: 12040
    }, {
      level: 1830,
      amount: 12105
    }, {
      level: 1840,
      amount: 12170
    }, {
      level: 1850,
      amount: 12235
    }, {
      level: 1860,
      amount: 12300
    }, {
      level: 1870,
      amount: 12365
    }, {
      level: 1880,
      amount: 12430
    }, {
      level: 1890,
      amount: 12495
    }, {
      level: 1900,
      amount: 12560
    }, {
      level: 1910,
      amount: 12625
    }, {
      level: 1920,
      amount: 12690
    }, {
      level: 1930,
      amount: 12755
    }, {
      level: 1940,
      amount: 12820
    }, {
      level: 1950,
      amount: 12885
    }, {
      level: 1960,
      amount: 12950
    }, {
      level: 1970,
      amount: 13015
    }, {
      level: 1980,
      amount: 13080
    }, {
      level: 1990,
      amount: 13145
    }, {
      level: 2000,
      amount: 13210
    }, {
      level: 2010,
      amount: 13275
    }, {
      level: 2020,
      amount: 13340
    }, {
      level: 2030,
      amount: 13405
    }, {
      level: 2040,
      amount: 13470
    }, {
      level: 2050,
      amount: 13535
    }, {
      level: 2060,
      amount: 13600
    }, {
      level: 2070,
      amount: 13665
    }, {
      level: 2080,
      amount: 13730
    }, {
      level: 2090,
      amount: 13795
    }, {
      level: 2100,
      amount: 13860
    }, {
      level: 2110,
      amount: 13925
    }, {
      level: 2120,
      amount: 13990
    }, {
      level: 2130,
      amount: 14055
    }, {
      level: 2140,
      amount: 14120
    }, {
      level: 2150,
      amount: 14185
    }, {
      level: 2160,
      amount: 14250
    }, {
      level: 2170,
      amount: 14315
    }, {
      level: 2180,
      amount: 14380
    }, {
      level: 2190,
      amount: 14445
    }, {
      level: 2200,
      amount: 14510
    }, {
      level: 2210,
      amount: 14575
    }, {
      level: 2220,
      amount: 14640
    }, {
      level: 2230,
      amount: 14705
    }, {
      level: 2240,
      amount: 14770
    }, {
      level: 2250,
      amount: 14835
    }, {
      level: 2260,
      amount: 14900
    }, {
      level: 2270,
      amount: 14965
    }, {
      level: 2280,
      amount: 15030
    }, {
      level: 2290,
      amount: 15095
    }, {
      level: 2300,
      amount: 15160
    }, {
      level: 2310,
      amount: 15225
    }, {
      level: 2320,
      amount: 15290
    }, {
      level: 2330,
      amount: 15355
    }, {
      level: 2340,
      amount: 15420
    }, {
      level: 2350,
      amount: 15485
    }, {
      level: 2360,
      amount: 15550
    }, {
      level: 2370,
      amount: 15615
    }, {
      level: 2380,
      amount: 15680
    }, {
      level: 2390,
      amount: 15745
    }, {
      level: 2400,
      amount: 15810
    }, {
      level: 2410,
      amount: 15875
    }, {
      level: 2420,
      amount: 15940
    }, {
      level: 2430,
      amount: 16005
    }, {
      level: 2440,
      amount: 16070
    }, {
      level: 2450,
      amount: 16135
    }, {
      level: 2460,
      amount: 16200
    }, {
      level: 2470,
      amount: 16265
    }, {
      level: 2480,
      amount: 16330
    }, {
      level: 2490,
      amount: 16395
    }, {
      level: 2500,
      amount: 16460
    }, {
      level: 2510,
      amount: 16525
    }, {
      level: 2520,
      amount: 16590
    }, {
      level: 2530,
      amount: 16655
    }, {
      level: 2540,
      amount: 16720
    }, {
      level: 2550,
      amount: 16785
    }, {
      level: 2560,
      amount: 16850
    }, {
      level: 2570,
      amount: 16915
    }, {
      level: 2580,
      amount: 16980
    }, {
      level: 2590,
      amount: 17045
    }, {
      level: 2600,
      amount: 17110
    }, {
      level: 2610,
      amount: 17175
    }, {
      level: 2620,
      amount: 17240
    }, {
      level: 2630,
      amount: 17305
    }, {
      level: 2640,
      amount: 17370
    }, {
      level: 2650,
      amount: 17435
    }, {
      level: 2660,
      amount: 17500
    }, {
      level: 2670,
      amount: 17565
    }, {
      level: 2680,
      amount: 17630
    }, {
      level: 2690,
      amount: 17695
    }, {
      level: 2700,
      amount: 17760
    }, {
      level: 2710,
      amount: 17825
    }, {
      level: 2720,
      amount: 17890
    }, {
      level: 2730,
      amount: 17955
    }, {
      level: 2740,
      amount: 18020
    }, {
      level: 2750,
      amount: 18085
    }, {
      level: 2760,
      amount: 18150
    }, {
      level: 2770,
      amount: 18215
    }, {
      level: 2780,
      amount: 18280
    }, {
      level: 2790,
      amount: 18345
    }, {
      level: 2800,
      amount: 18410
    }, {
      level: 2810,
      amount: 18475
    }, {
      level: 2820,
      amount: 18540
    }, {
      level: 2830,
      amount: 18605
    }, {
      level: 2840,
      amount: 18670
    }, {
      level: 2850,
      amount: 18735
    }, {
      level: 2860,
      amount: 18800
    }, {
      level: 2870,
      amount: 18865
    }, {
      level: 2880,
      amount: 18930
    }, {
      level: 2890,
      amount: 18995
    }, {
      level: 2900,
      amount: 19060
    }, {
      level: 2910,
      amount: 19125
    }, {
      level: 2920,
      amount: 19190
    }, {
      level: 2930,
      amount: 19255
    }, {
      level: 2940,
      amount: 19320
    }, {
      level: 2950,
      amount: 19385
    }, {
      level: 2960,
      amount: 19450
    }, {
      level: 2970,
      amount: 19515
    }, {
      level: 2980,
      amount: 19580
    }, {
      level: 2990,
      amount: 19645
    }, {
      level: 3000,
      amount: 19695
    }],
    // ATTRIBUTES
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    pricingType: _emberData.default.attr('string'),
    automaticSubscription: _emberData.default.attr('boolean'),
    numberOfAllowedResourcesPerManager: _emberData.default.attr('number'),
    numberOfAllowedManagers: _emberData.default.attr('number'),
    numberOfAllowedResources: _emberData.default.attr('number'),
    numberOfAllowedAccounts: _emberData.default.attr('number'),
    numberOfEsignatureAllowed: _emberData.default.attr('number'),
    numberOfActivatedManagers: _emberData.default.attr('number'),
    numberOfActivatedResources: _emberData.default.attr('number'),
    numberOfActivatedAccounts: _emberData.default.attr('number'),
    numberOfEsignatureUsed: _emberData.default.attr('number'),
    priceExcludingTaxPerAllowedManager: _emberData.default.attr('number'),
    priceExcludingTaxPerAllowedResource: _emberData.default.attr('number'),
    priceExcludingTaxPerAllowedAccount: _emberData.default.attr('number'),
    priceRateForAppsNoCode: _emberData.default.attr('number'),
    priceRateForAdvancedBi: _emberData.default.attr('number'),
    schedulesAllowedAccounts: (0, _attributes.fragmentArray)('scheduleallowedaccounts'),
    regulates: (0, _attributes.fragmentArray)('subscriptionregulates'),
    quantityOfStorageMax: _emberData.default.attr('number'),
    quantityOfStorage: _emberData.default.attr('number'),
    modules: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstAccountFirstName: _emberData.default.attr('string'),
    firstAccountLastName: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    dataType: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    groupLogo: _emberData.default.attr('string'),
    clientKey: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    //rights
    canConnectCustomer: _emberData.default.attr('boolean'),
    canDeleteCustomer: _emberData.default.attr('boolean'),
    // RELATIONSHIPS
    company: _emberData.default.belongsTo(),
    sandbox: _emberData.default.belongsTo('customer', {
      inverse: null
    }),
    databaseServer: _emberData.default.belongsTo('databaseserver'),
    solrServer: _emberData.default.belongsTo('solrserver'),
    gedServer: _emberData.default.belongsTo('gedserver'),
    nodeServer: _emberData.default.belongsTo('nodeserver'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    agency: _emberData.default.belongsTo(),
    mainManager: _emberData.default.belongsTo('resource'),
    pole: _emberData.default.belongsTo(),
    stateClass: Ember.computed('state', function () {
      return 'bm-customer-state-' + this.get('state');
    }),
    stateLabel: Ember.computed('state', 'i18n._locale', function () {
      switch (this.get('state')) {
        case 'inactive':
          return this.get('i18n').t('common:offText');
        case 'active':
          return this.get('i18n').t('common:onText');
        case 'demonstration':
          return this.get('i18n').t('customers:view.subscriptionStates.demonstration');
        case 'partner':
          return this.get('i18n').t('customers:view.subscriptionStates.partner');
        case 'internal':
          return this.get('i18n').t('customers:view.subscriptionStates.internal');
      }
      return '';
    }),
    quantityOfBasicStorage: Ember.computed('numberOfAllowedManagers', 'numberOfAllowedAccounts', 'pricingType', function () {
      switch (this.get('pricingType')) {
        case 'account':
        case 'scheduleAccount':
          return this.get('numberOfAllowedAccounts') * 0.2;
        default:
          return this.get('numberOfAllowedManagers') * 3;
      }
    }).readOnly(),
    totalAmountExcludingTax: Ember.computed('pricingType', 'numberOfAllowedManagers', 'priceExcludingTaxPerAllowedManager', 'numberOfAllowedResources', 'priceExcludingTaxPerAllowedResource', 'numberOfAllowedAccounts', 'priceExcludingTaxPerAllowedAccount', 'priceRateForAppsNoCode', 'priceRateForAdvancedBi', 'regulates.@each.amountExcludingTax', function () {
      var total = 0;
      var totalAppsNoCode = 0;
      var totalAdvancedBi = 0;
      switch (this.get('pricingType')) {
        case 'custom':
          total = this.get('numberOfAllowedManagers') * this.get('priceExcludingTaxPerAllowedManager') + this.get('numberOfAllowedResources') * this.get('priceExcludingTaxPerAllowedResource');
          break;
        case 'manager':
          total = this.get('numberOfAllowedManagers') * this.get('priceExcludingTaxPerAllowedManager');
          break;
        case 'account':
          total = this.get('numberOfAllowedAccounts') * this.get('priceExcludingTaxPerAllowedAccount');
          break;
        case 'scheduleAccount':
          total = this.get('numberOfAllowedAccounts') * this.get('priceExcludingTaxPerAllowedAccount');
          break;
      }
      this.get('regulates').forEach(function (r) {
        total += r.amountExcludingTax;
      });
      var priceRateForAppsNoCode = this.get('priceRateForAppsNoCode');
      if (priceRateForAppsNoCode) {
        totalAppsNoCode = total * priceRateForAppsNoCode / 100;
      }
      var priceRateForAdvancedBi = this.get('priceRateForAdvancedBi');
      if (priceRateForAdvancedBi) {
        totalAdvancedBi = total * priceRateForAdvancedBi / 100;
      }
      return total + totalAppsNoCode + totalAdvancedBi;
    }).readOnly()
  });
  Customer.reopenClass({
    prefix: 'CUST'
  });
  Customer.reopen({
    prefix: Ember.computed(function () {
      return Customer.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Customer;
});