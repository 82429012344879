define("boondmanager/models/appaccountingpayrollexpensesreport", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExpensesReport = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['resource']
    }),
    /**************************************************************************/
    /** ATTRIBUTES ************************************************************/
    /**************************************************************************/
    currencyAgency: _emberData.default.attr('number'),
    exchangeRateAgency: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    /**
     * State
     * see STATES
     * @type {String}
     */
    state: _emberData.default.attr('string'),
    term: _emberData.default.attr('date'),
    amountExcludingTax: _emberData.default.attr('number'),
    amountIncludingTax: _emberData.default.attr('number'),
    // Relationships
    resource: _emberData.default.belongsTo()
  });
  ExpensesReport.reopenClass({
    prefix: 'EXP'
  });
  ExpensesReport.reopen({
    prefix: Ember.computed(function () {
      return ExpensesReport.prefix;
    }).readOnly()
  });
  var _default = _exports.default = ExpensesReport;
});