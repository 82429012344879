define("boondmanager/models/validation", ["exports", "boondmanager/models/base", "ember-data", "moment", "boondmanager/models/baseactivity"], function (_exports, _base, _emberData, _moment, _baseactivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validation = _base.default.extend({
    /*** ATTRIBUTES ***********************************************************/
    /**
     * Validation date
     *
     * @db TAB_VALIDATION.VAL_DATE
     * @property date
     * @type {Moment}
     * @default current date (ie. now)
     * @required
     */
    date: _emberData.default.attr('moment', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)();
      }
    }),
    /**
     * Validation state
     *
     * one of:
     *   * "waitingForValidation"
     *   * "validated"
     *   * "rejected"
     *
     * @db TAB_VALIDATION.VAL_ETAT
     * @property state
     * @type {string}
     * @default : "waitingForValidation"
     * @required
     */
    state: _emberData.default.attr('string', {
      defaultValue: _baseactivity.ACTIVITY_WAITING_FOR_VALIDATION
    }),
    /**
     * Validation reject reason
     *
     * @db TAB_VALIDATION.VAL_MOTIF
     * @property reason
     * @type {string}
     * @default  ""
     */
    reason: _emberData.default.attr('string'),
    /*** RELATIONSHIPS ********************************************************/
    validator: _emberData.default.belongsTo('resource'),
    /**
     * Real validator
     *
     * @property realValidator
     * @type {Resource}
     */
    realValidator: _emberData.default.belongsTo('resource'),
    /**
     * Expected validator
     *
     * @property expectedValidator
     * @type {Resource}
     * @required
     */
    expectedValidator: _emberData.default.belongsTo('resource'),
    /**
     * expectedValidatorsAllowedForValidate
     *
     * @property expectedValidatorsAllowedForValidate
     * @type Array
     */
    expectedValidatorsAllowedForValidate: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * expectedValidatorsAllowedForUnvalidate
     *
     * @property expectedValidatorsAllowedForUnvalidate
     * @type Array
     */
    expectedValidatorsAllowedForUnvalidate: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * expectedValidatorsAllowedForReject
     *
     * @property expectedValidatorsAllowedForReject
     * @type Array
     */
    expectedValidatorsAllowedForReject: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * (Times|Expenses|Absences) report on which this validation depends
     *
     * @todo  create a ActivityReport (or Report) which extends Base and use
     *        `DS.beslongsTo('activityreport')` instead of `DS.belongsTo('base')`
     *        to be more precise and avoid mistakes...
     *
     * @property dependsOn
     * @type {Base}
     * @required
     */
    dependsOn: _emberData.default.belongsTo('baseactivity', {
      polymorphic: true,
      inverse: 'validations'
    }),
    /*** CP *******************************************************************/
    isValidated: Ember.computed.equal('state', _baseactivity.ACTIVITY_VALIDATED).readOnly(),
    isPending: Ember.computed.equal('state', _baseactivity.ACTIVITY_WAITING_FOR_VALIDATION).readOnly(),
    isRejected: Ember.computed.equal('state', _baseactivity.ACTIVITY_REJECTED).readOnly(),
    /*** METHODS **************************************************************/validate: function validate() {
      this.set('state', _baseactivity.ACTIVITY_VALIDATED);
    },
    reject: function reject() {
      this.set('state', _baseactivity.ACTIVITY_REJECTED);
    },
    unvalidate: function unvalidate() {
      this.set('state', _baseactivity.ACTIVITY_WAITING_FOR_VALIDATION);
    },
    dependsOnTimesReport: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'timesreport';
    }),
    dependsOnExpensesReport: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'expensesreport';
    }),
    dependsOnAbsencesReport: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'absencesreport';
    }),
    dependsOnTypeLabel: Ember.computed('dependsOn', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      var modelName = this.get('dependsOn.modelName');
      switch (modelName) {
        case 'timesreport':
          return i18n.t('models:timesreport.name');
        case 'expensesreport':
          return i18n.t('models:expensesreport.name');
        case 'absencesreport':
          return i18n.t('models:absencesreport.name');
      }
    }),
    dependsOnLabel: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.label');
    }),
    dependsOnClass: Ember.computed('dependsOn', function () {
      return 'bm-validation-dependson-' + this.get('dependsOn.modelName');
    }),
    dependsOnIcon: Ember.computed('dependsOn', function () {
      return 'bmi-' + this.get('dependsOn.modelName');
    }),
    labelWithInternalReference: Ember.computed('dependsOn', function () {
      return this.get('expectedValidator.entityName') + ' - ' + this.get('internalReference');
    }).readOnly(),
    labelWithDependsOnReference: Ember.computed('dependsOn', 'i18n._locale', function () {
      return this.get('dependsOn.resource.completeName') + ' - ' + this.get('dependsOnTypeLabel') + ' ' + this.get('dependsOn.label') + ' - ' + this.get('i18n').t('validations:view.validator') + ' : ' + this.get('expectedValidator.completeName');
    }).readOnly(),
    dependsOnRoute: Ember.computed('dependsOn', function () {
      if (this.get('dependsOn.modelName') === 'timesreport') {
        return 'private.timesreports.timesreport';
      } else if (this.get('dependsOn.modelName') === 'expensesreport') {
        return 'private.expensesreports.expensesreport';
      } else if (this.get('dependsOn.modelName') === 'absencesreport') {
        return 'private.absencesreports.absencesreport';
      }
    }),
    label: Ember.computed('dependsOn', function () {
      return this.get('expectedValidator.entityName');
    }).readOnly()
  });
  Validation.reopenClass({
    prefix: 'VAL'
  });
  Validation.reopen({
    prefix: Ember.computed(function () {
      return Validation.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Validation;
});