define("boondmanager/pods/components/bm-field/row-additional-turnover/project/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/cachecomputed"], function (_exports, _component, _cachecomputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    /**
     * Attributs par défaut de la balise __class__ du composant.
     *
     * @property classNames
     * @type Array
     * @default  ['bmc-field-row-additional-turnover_project']
     */
    classNames: ['bmc-field-row-additional-turnover_project'],
    attributeBindings: ['dataName:data-name'],
    dataName: Ember.computed('name', function () {
      return 'row-additional-turnover-project-' + this.get('name');
    }),
    canSetTypeOfAdditional: Ember.computed('i18n._locale', function () {
      return this.get('settings').customer.additionalTurnoverTypes.state;
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      var model = this.get('model');
      var project = this.get('project');
      var deleteAdditional = this.get('deleteAdditional');
      var addAdditional = this.get('addAdditional');
      var addPurchase = this.get('addPurchase');
      var deletePurchase = this.get('deletePurchase');
      var save = this.get('save');

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert('bm-field/row-additional-turnover/project must have a "name" attribute!', !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert('bm-field/row-additional-turnover/project "name" attribute must be a string!', Ember.typeOf(name) === 'string')); // check deleteAdditional
      (false && !(!Ember.isBlank(deleteAdditional)) && Ember.assert('bm-field/row-additional-turnover/project must have a "deleteAdditional" attribute!', !Ember.isBlank(deleteAdditional)));
      (false && !(Ember.typeOf(deleteAdditional) === 'function') && Ember.assert('bm-field/row-additional-turnover/project "deleteAdditional" attribute must be a function!', Ember.typeOf(deleteAdditional) === 'function')); // check addAdditional
      (false && !(!Ember.isBlank(addAdditional)) && Ember.assert('bm-field/row-additional-turnover/project must have a "addAdditional" attribute!', !Ember.isBlank(addAdditional)));
      (false && !(Ember.typeOf(addAdditional) === 'function') && Ember.assert('bm-field/row-additional-turnover/project "addAdditional" attribute must be a function!', Ember.typeOf(addAdditional) === 'function')); // check addPurchase
      (false && !(!Ember.isBlank(addPurchase)) && Ember.assert('bm-field/row-additional-turnover/project must have a "addPurchase" attribute!', !Ember.isBlank(addPurchase)));
      (false && !(Ember.typeOf(addPurchase) === 'function') && Ember.assert('bm-field/row-additional-turnover/project "addPurchase" attribute must be a function!', Ember.typeOf(addPurchase) === 'function')); // check deletePurchase
      (false && !(!Ember.isBlank(deletePurchase)) && Ember.assert('bm-field/row-additional-turnover/project must have a "deletePurchase" attribute!', !Ember.isBlank(deletePurchase)));
      (false && !(Ember.typeOf(deletePurchase) === 'function') && Ember.assert('bm-field/row-additional-turnover/project "deletePurchase" attribute must be a function!', Ember.typeOf(deletePurchase) === 'function')); // check save
      (false && !(!Ember.isBlank(save)) && Ember.assert('bm-field/row-additional-turnover/project must have a "save" attribute!', !Ember.isBlank(save)));
      (false && !(Ember.typeOf(save) === 'function') && Ember.assert('bm-field/row-additional-turnover/project "save" attribute must be a function!', Ember.typeOf(save) === 'function')); // check project
      (false && !(!Ember.isBlank(project)) && Ember.assert('bm-field/row-additional-turnover/project must have a "project" attribute!', !Ember.isBlank(project))); // check model
      (false && !(!Ember.isBlank(model)) && Ember.assert('bm-field/row-additional-turnover/project must have a "model" attribute!', !Ember.isBlank(model)));
    },
    hasValue: (0, _cachecomputed.default)('item', function () {
      return !this.isBlankValue();
    }),
    isBlankValue: function isBlankValue() {
      return typeof this.get('sortedAdditionalTurnoverAndCosts') === 'undefined' || this.get('sortedAdditionalTurnoverAndCosts') === null || this.get('sortedAdditionalTurnoverAndCosts').length === 0;
    },
    actions: {
      deleteRow: function deleteRow(item) {
        if (item.get('purchase')) {
          this.get('deletePurchase')(item);
        } else {
          this.get('deleteAdditional')(item);
        }
        return false;
      },
      addAdditional: function addAdditional() {
        this.get('addAdditional')();
      },
      updateAdditionalTurnoverType: function updateAdditionalTurnoverType(item, value) {
        item.set('typeOf', value.id);
      },
      addPurchase: function addPurchase(item) {
        this.get('addPurchase')(item);
      },
      save: function save() {
        this.get('save')();
      }
    }
  });
});