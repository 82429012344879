define("boondmanager/pods/components/bm-field/row-advantage/delivery/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-row-advantage_delivery'],
    _advantageTypes: Ember.computed('advantageTypes', 'i18n._locale', function () {
      var self = this;
      var advantageTypes = this.get('advantageTypes').filter(function (advantageType) {
        return advantageType.frequency !== 'punctual' && advantageType.state;
      }).map(function (advantageType) {
        return {
          id: advantageType.get('reference') + '_' + self.get('model.dependsOn.agency.id'),
          value: advantageType.get('name'),
          advantageType: advantageType.copy(),
          agency: {
            id: self.get('model.dependsOn.agency.id'),
            name: self.get('model.dependsOn.agency.name')
          }
        };
      });

      //On ajoute les expensesTypes sélectionnées qui n'appartiennent plus à cette agence
      this.get('model.advantageTypes').forEach(function (advantage) {
        if (advantage.get('agency.id') !== self.get('model.dependsOn.agency.id')) {
          advantageTypes.pushObject({
            id: advantage.get('reference') + '_' + advantage.get('agency.id'),
            value: advantage.get('name') + ' - ' + advantage.get('agency.name'),
            advantageType: advantage.copy(),
            agency: {
              id: advantage.get('agency.id'),
              name: advantage.get('agency.name')
            }
          });
        }
      });

      // test if current selected value is in list
      // if not it means that current value is archived and must be added
      var advId = this.get('advantage.reference') + '_' + this.get('advantage.agency.id');
      var inList = advantageTypes.find(function (option) {
        return option.id === advId;
      });
      if (typeof inList === 'undefined') {
        advantageTypes.pushObject({
          id: advId,
          value: this.get('advantage.name'),
          advantageType: this.get('advantage').copy(),
          agency: {
            id: this.get('advantage.agency.id'),
            name: this.get('advantage.agency.name')
          }
        });
      }
      return advantageTypes.sort(function (a, b) {
        return "".concat(a.value).localeCompare(b.value, self.get('i18n.locale'), {
          ignorePunctuation: true,
          sensitivity: 'base'
        });
      });
    }),
    actions: {
      changeAdvantage: function changeAdvantage(id) {
        var at = this.get('_advantageTypes').find(function (as) {
          return as.id === id;
        });
        var advantage = this.get('model.advantageTypes').objectAt(this.get('index'));
        advantage.set('reference', at.advantageType.get('reference'));
        advantage.set('name', at.advantageType.get('name'));
        advantage.set('frequency', at.advantageType.get('frequency'));
        advantage.set('category', at.advantageType.get('category'));
        advantage.set('participationQuota', at.advantageType.get('participationQuota'));
        advantage.set('agencyQuota', at.advantageType.get('agencyQuota'));
        advantage.set('employeeQuota', at.advantageType.get('employeeQuota'));
        advantage.set('agency', at.agency);
        this.get('model.advantageTypes').replaceContent(this.get('index'), 1, [advantage]);
      },
      deleteAdvantage: function deleteAdvantage(index) {
        this.get('deleteAdvantage')(index);
      }
    }
  });
});