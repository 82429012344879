define("boondmanager/initializers/fragment-copy", ["exports", "ember-data-model-fragments/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'copy data-attributes to fragment component',
    initialize: function initialize() {
      _fragment.default.reopen({
        copy: function copy() {
          var attributes = this._super.apply(this, arguments);
          attributes.set('originalID', undefined);
          return attributes;
        }
      });
    }
  };
});