define("boondmanager/pods/components/bm-field/resource/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-resource'],
    searchPrefix: 'COMP',
    context: 'resource',
    excludeManager: false,
    excludeResource: false,
    displayNbSelected: true,
    _getItems: function _getItems(input) {
      var params = {
        keywords: input,
        type: 0,
        excludeManager: this.get('excludeManager'),
        excludeResource: this.get('excludeResource')
      };
      return this.get('store').query('resource', params);
    }
  });
});