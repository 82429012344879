define("boondmanager/pods/components/bm-field/positioning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zX7Gfz1c",
    "block": "{\"symbols\":[\"positioning\"],\"statements\":[[6,[37,11],null,[[\"name\",\"options\",\"selected\",\"disabled\",\"onchange\",\"renderInPlace\",\"required\",\"useObjects\",\"placeholder\",\"allowClear\"],[[35,10],[35,9],[35,8,[\"content\"]],[35,7],[30,[36,6],[[32,0],\"onchange\"],null],[35,5],[35,4],true,[30,[36,3],[\"components:bmFieldPositioning.select.default.placeholder\"],null],[35,2]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"dependsOn\",\"modelName\"]],\"product\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[32,1,[\"getReference\"]]],[2,\" - \"],[1,[32,1,[\"opportunity\",\"title\"]]],[2,\" - \"],[1,[32,1,[\"dependsOn\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[32,1,[\"getReference\"]]],[2,\" - \"],[1,[32,1,[\"opportunity\",\"title\"]]],[2,\" - \"],[1,[32,1,[\"dependsOn\",\"completeName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[1,[30,[36,13],null,[[\"errors\"],[[35,12]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"allowClear\",\"t\",\"required\",\"renderInPlace\",\"action\",\"disabled\",\"selected\",\"positionings\",\"name\",\"bm-field/power-select\",\"errors\",\"bm-field/error\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/positioning/template.hbs"
  });
});