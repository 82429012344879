define("boondmanager/models/task", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable"], function (_exports, _emberData, _base, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Task = void 0;
  var Task = _exports.Task = _base.default.extend(_emberDataCopyable.default, {
    originalID: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    row: _emberData.default.attr('number'),
    state: _emberData.default.attr('boolean'),
    children: _emberData.default.hasMany('task', {
      inverse: null
    }),
    validatedAt: _emberData.default.attr('date'),
    validatedBy: _emberData.default.attr(),
    dependsOn: _emberData.default.belongsTo('base'),
    todoList: _emberData.default.belongsTo('todolist'),
    originalTask: null,
    deployed: false,
    forceVisible: false // Sert à distinguer les tâches qui viennent d'être cochées et qui ne doivent pas etre masquable.
  });

  Task.reopenClass({
    prefix: 'TASK'
  });
  Task.reopen({
    prefix: Ember.computed(function () {
      return Task.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Task;
});