define("boondmanager/pods/components/bm-field/flags/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/cachecomputed", "boondmanager/utils/logger"], function (_exports, _component, _cachecomputed, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;
  var ArrPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

  /**
   * Composant Filtre Flags.
   *
   * ```handlebars
   * {{bmc-field/flags
   *     	name="flags"
   *      onChange=(action 'onFlagsChange')
   *  }}
   * ```
   *
   * @class  FilterFlagsComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    title: 'essai de title',
    /**
     * Attributs par défaut de la balise __class__ du composant.
     *
     * @property classNames
     * @type Array
     * @default  ['bmc-field-flags']
     */
    classNames: ['bmc-field-flags', 'bmc-field_nosection', 'bmc-field bmc-field_inline-content', 'bmc-field_no-title', 'bmc-field_no-title-on-hover'],
    classNameBindings: ['disabled:disabled', 'showFlagInput:showFlag'],
    changes: false,
    showFlagInput: Ember.computed('selected.[]', function () {
      return this.get('changes') || this.get('selected.length') !== 0;
    }),
    _getFlags: function _getFlags() {
      var _this = this;
      return this.get('store').query('flag', {
        middlepoint: 'application'
      }).then(function (flags) {
        return flags;
      }).catch(function (reason) {
        if (Ember.isArray(reason.errors)) {
          var name = _this.get('name');
          reason.errors.forEach(function (error) {
            _logger.default.warn("bm-field-flags - ".concat(name, " - ").concat(error.code, " ").concat(error.status, ": ").concat(error.text));
          });
        }
      });
    },
    flags: Ember.computed(function () {
      return ArrPromiseProxy.create({
        promise: this._getFlags()
      });
    }),
    modelName: Ember.computed('model.modelName', function () {
      switch (this.get('model.modelName')) {
        case 'appadvancedcandidatescandidate':
          return 'candidate';
        default:
          return this.get('model.modelName');
      }
    }),
    selectedFlags: Ember.computed('model.id,modelName', function () {
      var mainModelName = this.get('modelName');
      var modelId = this.get('model.id');
      if (mainModelName && modelId) {
        var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
        var adapterOptions = {
          id: modelId,
          endpoint: 'attached-flags'
        };
        if (mainModelName === 'appentity') {
          adapterOptions = {
            id: this.get('model.app.id'),
            appentity_id: modelId,
            endpoint: 'attached-flags'
          };
        }
        return proxy.create({
          promise: this.store.query(mainModelName, adapterOptions).then(function (attachedFlags) {
            return Ember.RSVP.all(attachedFlags.map(function (attachedFlag) {
              return attachedFlag.get('flag');
            }));
          }).catch(function () {
            //@ToDo : Catch error BM-3447
            //@ToDo : update rights service in after model OR aftermodel updates rights computed in controller
          })
        });
      }
    }),
    selected: Ember.computed('selectedFlags.[]', {
      get: function get() {
        if (this.get('selectedFlags')) {
          return this.get('selectedFlags').map(function (flag) {
            return flag.get('id');
          });
        } else {
          return [];
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),
    options: Ember.computed('flags.isFulfilled', function () {
      var flags = this.get('flags');
      var currentUserID = this.get('currentUser.id');
      var output = [];
      if (this.get('flags.isFulfilled')) {
        // nous allons conserver tous les IDs des flags pour pouvoir filtrer après-coup les selected
        var flagsIDs = [];
        var self = this;
        output = flags.toArray().sort(function (a, b) {
          var flagAcurrentUser = a.belongsTo('mainManager').id() === currentUserID;
          var flagBcurrentUser = b.belongsTo('mainManager').id() === currentUserID;
          if (flagAcurrentUser === flagBcurrentUser) {
            return "".concat(a.get('name')).localeCompare(b.get('name'), self.get('i18n.locale'), {
              ignorePunctuation: true,
              sensitivity: 'base'
            });
          } else {
            // if one is currentUser and not the other, currentUser goes first
            return flagBcurrentUser ? 1 : -1;
          }
        }).map(function (flag) {
          var id = flag.get('id');
          flagsIDs.push(id);

          // on va éviter de faire un `flag.get('mainManager').then()` si pas besoin
          if (flag.belongsTo('mainManager').id() === currentUserID) {
            // ici, onn a pas besoin de résoudre mainManager car on a pas besoin de son entityName
            return {
              id: id,
              value: flag.get('name')
            };
          } else {
            return flag.get('mainManager').then(function (mainManager) {
              return {
                id: id,
                value: "".concat(flag.get('name'), " - ").concat(mainManager.get('entityName'))
              };
            });
          }
        });
        output = output.concat(this.get('selectedFlags').filter(function (selectedFlag) {
          return flagsIDs.indexOf(selectedFlag.get('id')) === -1;
        }).map(function (selectedFlag) {
          var id = selectedFlag.get('id');
          if (selectedFlag.belongsTo('mainManager').id() === currentUserID) {
            return {
              id: id,
              value: selectedFlag.get('name')
            };
          } else {
            return selectedFlag.get('mainManager').then(function (mainManager) {
              return {
                id: id,
                value: "".concat(selectedFlag.get('name'), " - ").concat(mainManager.get('entityName'))
              };
            });
          }
        }));
      }
      return ArrPromiseProxy.create({
        promise: Ember.RSVP.all(output)
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      // check name & module
      (false && !(!Ember.isBlank(name)) && Ember.assert('bm-field-flags must have a "name" attribute!', !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert('bm-field-flags "name" attribute must be a string!', Ember.typeOf(name) === 'string'));
    },
    hasValue: (0, _cachecomputed.default)('value', function () {
      return !this.isBlankValue();
    }),
    isBlankValue: function isBlankValue() {
      return typeof this.get('selected') === 'undefined' || this.get('selected') === null || this.get('selected').length === 0;
    },
    actions: {
      toggleShow: function toggleShow() {
        if (this.get('selected.length') === 0) {
          this.toggleProperty('showFlagInput');
        }
      },
      updateSelectedFlags: function updateSelectedFlags(values) {
        var _this2 = this;
        this.set('changes', true);
        var adapter = this.get('store').adapterFor('application');
        var modelName = this.get('modelName');
        var modelID = this.get('model.id');
        var queries = [];
        // here we take a copy of array to avoid splice values from selected value in the power-select
        var removed = _toConsumableArray(this.get('selected'));
        var added = values.slice();
        var flagsAdded = [];
        var flagsRemoved = [];
        var i18n = this.get('i18n');
        var type, data;
        values.forEach(function (flagID) {
          var addedIdx = added.indexOf(flagID);
          var removedIdx = removed.indexOf(flagID);

          // this flagID is in new and old
          if (removedIdx > -1) {
            // so useless :) remove from added and removed arrays
            added.splice(addedIdx, 1);
            removed.splice(removedIdx, 1);
          }
        });
        if (added.length) {
          type = 'POST';
          data = {
            data: {
              type: 'attachedflag',
              relationships: {
                flag: {
                  data: {
                    type: 'flag'
                  }
                },
                dependsOn: {
                  data: {
                    id: modelID,
                    type: modelName
                  }
                }
              }
            }
          };
          added.forEach(function (addID) {
            data.data.relationships.flag.data.id = addID;
            var addFlag = _this2.get('options').find(function (optFlag) {
              return optFlag.id === addID;
            });
            var flagName = typeof addFlag === 'undefined' ? 'FLAG' + addID : escapeExpression(addFlag.value);
            queries.push(new Ember.RSVP.Promise(function (resolve, reject) {
              adapter.call('/attached-flags', type, data).then(function (response) {
                Ember.run(function () {
                  flagsAdded.push(addID);
                  resolve({
                    flagName: flagName,
                    data: response.data,
                    type: 'add'
                  });
                });
              }).catch(function () {
                Ember.run(function () {
                  reject({
                    flagName: flagName,
                    data: data,
                    type: 'add'
                  });
                  values = values.filter(function (flagId) {
                    return flagId !== addID;
                  }); //On supprime le flag des objets sélectionnés
                });
              });
            }, "".concat(type, " attach flag#").concat(addID, " to ").concat(modelName, "#").concat(modelID)));
          });
        }
        if (removed.length && added.length === 0) {
          type = 'DELETE';
          data = {};
          if (modelName === 'appentity') {
            data['appEntity'] = modelID;
          } else {
            data[modelName] = modelID;
          }
          removed.forEach(function (removedID) {
            var addFlag = _this2.get('options').find(function (optFlag) {
              return optFlag.id === removedID;
            });
            var flagName = typeof addFlag === 'undefined' ? 'FLAG' + removedID : escapeExpression(addFlag.value);
            data['flag'] = removedID;
            queries.push(new Ember.RSVP.Promise(function (resolve, reject) {
              adapter.call('/attached-flags', type, data).then(function (response) {
                flagsRemoved.push(removedID);
                resolve({
                  flagName: flagName,
                  data: response.data,
                  type: 'delete'
                });
              }, function (xhr /*, status, error*/) {
                var error404 = xhr.errors.find(function (error) {
                  return error.code === '404';
                });
                if (error404) {
                  flagsRemoved.push(removedID);
                  resolve({
                    flagName: flagName,
                    data: data,
                    type: 'delete'
                  });
                } else {
                  reject({
                    flagName: flagName,
                    data: data,
                    type: 'delete'
                  });
                  values.push(removedID); //On remet le flag dans les objets sélectionnés
                }
              });
            }, "".concat(type, " attach flag#").concat(removedID, " to ").concat(modelName, "#").concat(modelID)));
          });
        }
        var flagsNotDeleted = [];
        var flagsNotAdded = [];
        var notifyMsg = '';
        var notifyTitle = '';
        Ember.RSVP.allSettled(queries, "Update flags on ".concat(modelName, "#").concat(modelID)).then(function (result) {
          var selected = _toConsumableArray(_this2.get('selected'));
          flagsAdded.forEach(function (item) {
            if (!selected.includes(item)) {
              selected.push(item);
            }
          });
          selected = selected.filter(function (selectedValue) {
            return !flagsRemoved.includes(selectedValue);
          });
          _this2.set('selected', selected);
          flagsNotDeleted = result.reduce(function (flagsNotDeleted, promise) {
            if (promise.state === 'rejected' && promise.reason.type === 'delete') {
              flagsNotDeleted.push(promise.reason);
            }
            return flagsNotDeleted;
          }, []);
          flagsNotAdded = result.reduce(function (flagsNotAdded, promise) {
            if (promise.state === 'rejected' && promise.reason.type === 'add') {
              flagsNotAdded.push(promise.reason);
            }
            return flagsNotAdded;
          }, []);

          // display notification for deletion
          if (removed.length) {
            if (flagsNotDeleted.length) {
              notifyTitle = i18n.t('common:deleteFlagError', {
                count: flagsNotDeleted.length
              });
              notifyMsg = '<ul>';
              flagsNotDeleted.forEach(function (flagNotDeleted) {
                notifyMsg += "<li>".concat(flagNotDeleted.flagName, "</li>");
              });
              _this2.get('notify').error(notifyMsg, notifyTitle);
            } else {
              // all destroyed :D
              notifyMsg = i18n.t('common:deleteFlagSuccess', {
                count: removed.length
              });
              _this2.get('notify').success(notifyMsg, notifyTitle);
            }
          }

          // display notification for addition
          if (added.length) {
            if (flagsNotAdded.length) {
              notifyTitle = i18n.t('common:addFlagError', {
                count: flagsNotAdded.length
              });
              notifyMsg = '<ul>';
              flagsNotAdded.forEach(function (flagNotAdded) {
                notifyMsg += "<li>".concat(flagNotAdded.flagName, "</li>");
              });
              _this2.get('notify').error(notifyMsg, notifyTitle);
            } else {
              // all destroyed :D
              notifyMsg = i18n.t('common:addFlagSuccess', {
                count: added.length
              });
              _this2.get('notify').success(notifyMsg, notifyTitle);
            }
          }
        });
      }
    }
  });
});