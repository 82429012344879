define("boondmanager/services/open-protocol", ["exports", "boondmanager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PROTOCOL_PHONE = _exports.PROTOCOL_EMAIL = void 0;
  var PROTOCOL_PHONE = _exports.PROTOCOL_PHONE = 'phone';
  var PROTOCOL_EMAIL = _exports.PROTOCOL_EMAIL = 'email';
  var _default = _exports.default = Ember.Service.extend({
    /**
     * Set to true to bypass check dirty state onbeforeunload
     *
     * @var bypassUnload
     * @type boolean
     * @default false
     */
    bypassUnload: false,
    /**
     * Clean phone number string and validate
     * @param tel
     * @returns {*}
     */
    cleanTel: function cleanTel(tel) {
      if (tel) {
        if (tel.charAt(0) == '+') tel = '+' + tel.replace(new RegExp('[^(0-9)]', 'g'), '').replace('+', '');else tel = tel.replace(new RegExp('[^(0-9)]', 'g'), '').replace('+', '');
      }
      return tel;
    },
    /**
     * Call phone protocol on browser
     * @param tel
     */
    callTo: function callTo(tel) {
      this._replaceLocation(this.cleanTel(tel), PROTOCOL_PHONE);
    },
    /**
     * Call mail protocol on browser
     * @param email
     */
    mailTo: function mailTo(email) {
      this._replaceLocation(email, PROTOCOL_EMAIL);
    },
    /**
     * Call download protocol on browser
     * @param url
     */
    urlTo: function urlTo(url) {
      this._replaceLocation(url);
    },
    /**
     * Call download protocol on browser for internal URLs
     * @param url
     */
    urlToInternal: function urlToInternal(url) {
      url = url ? "".concat(_environment.default.apiURL, "/").concat(url) : '';
      this.urlTo(url);
    },
    /**
     *
     * @param value
     * @param protocol
     * @private
     */
    _replaceLocation: function _replaceLocation(value, protocol) {
      if (value) {
        var url = value;
        switch (protocol) {
          case PROTOCOL_EMAIL:
            url = "mailto:".concat(value);
            break;
          case PROTOCOL_PHONE:
            url = "tel:".concat(value);
            break;
        }
        this.set('bypassUnload', true);
        window.location.href = url;

        // ici on est obligé d'avoir un léger timeout avant de reset la variable de bypass
        // car Safari n'exécute pas l'event onbeforeunload instantanément
        Ember.run.later(this, function () {
          this.set('bypassUnload', false);
        }, 150);
      }
    }
  });
});