define("boondmanager/models/businessunit", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BusinessUnit = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['managers', 'excludedManagersInSearch']
    }),
    name: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    includedManagers: _emberData.default.hasMany('resource'),
    excludedManagersInSearch: _emberData.default.hasMany('resource'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }).readOnly()
  });
  BusinessUnit.reopenClass({
    prefix: 'BU'
  });
  BusinessUnit.reopen({
    prefix: Ember.computed(function () {
      return BusinessUnit.prefix;
    }).readOnly()
  });
  var _default = _exports.default = BusinessUnit;
});