define("boondmanager/services/jwt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    storage: Ember.inject.service(),
    router: Ember.inject.service(),
    receivedJWT: function receivedJWT(transition) {
      var router = this.get('router');
      var queryParams = transition.to.queryParams;
      var storage = this.get('storage');

      // si on a un token dans les qp, on l'enleve et on le stock dans notre service
      // Pourquoi le test `transition.isActive`? Car le service `storage`, lors de son
      // init (get) teste l'existence du localStorage. Si négatif, il effectue une
      // transition vers la page '600' annulant alors la 1ère transition. Si on ne
      // vérifie pas, on rentre dans le if et on relance la 1ère transition précédemment
      // annulée pour une bonne raison (redirection 600) et on rentre dans une boucle !
      if (transition.isActive && typeof queryParams.jwt !== 'undefined' && queryParams.jwt) {
        var token = queryParams.jwt;
        delete queryParams.jwt;
        storage.set('jwt', token);
        transition.abort();
        return new Ember.RSVP.Promise(function (resolve, reject) {
          router.replaceWith(transition.to.name, {
            queryParams: queryParams
          }).then(function () {
            return reject(token);
          });
        });
      }
      return Ember.RSVP.Promise.resolve(null);
    },
    tokenExistsInTransition: function tokenExistsInTransition(transition) {
      var queryParams = transition.to.queryParams;
      return typeof queryParams.jwt !== 'undefined' && !!queryParams.jwt;
    },
    hasJWT: function hasJWT() {
      var storage = this.get('storage');
      return storage.get('jwt') !== null && !!storage.get('jwt');
    },
    consumeJWT: function consumeJWT() {
      var token = this.get('storage').get('jwt');
      this.clear();
      return token;
    },
    setJWT: function setJWT(jwt) {
      this.get('storage').set('jwt', jwt);
    },
    clear: function clear() {
      this.get('storage').set('jwt', null);
    },
    parseJWT: function parseJWT(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }
  });
});