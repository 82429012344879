define("boondmanager/pods/components/bm-field/candidate/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field_candidate'],
    searchPrefix: 'CAND',
    context: 'candidate',
    displayNbSelected: true,
    _getItems: function _getItems(input) {
      var params = {
        keywords: input
      };
      return this.get('store').query('candidate', params);
    }
  });
});