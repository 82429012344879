define("boondmanager/models/workplacesdefaultweekday", ["exports", "boondmanager/utils/logger", "ember-data", "ember-data-model-fragments"], function (_exports, _logger, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number'),
    dayOfWeek: _emberData.default.attr('number'),
    typeOf: Ember.computed('dayOfWeek', {
      get: function get() {
        return '';
      },
      set: function set(key, value) {
        return value;
      }
    }),
    /**
     * workUnitRate
     *
     * @property workUnitRate
     * @return {Number}
     * @default  1
     */
    workUnitRate: Ember.computed({
      get: function get() {
        return 1;
      },
      set: function set(key, newValue, oldValue) {
        var workUnitRate = parseFloat(newValue);
        if (isNaN(workUnitRate)) {
          _logger.default.error('Model workplacesdefaultweekday - SET workUnitRate - newValue is NaN!', newValue);
          workUnitRate = oldValue;
        } else if (workUnitRate === 0) {
          _logger.default.error('Model workplacesdefaultweekday - SET workUnitRate - newValue is 0!', newValue);
          workUnitRate = oldValue;
        }
        return workUnitRate;
      }
    }),
    value: Ember.computed('duration', 'workUnitRate', {
      get: function get() {
        return this.get('duration') ? this.get('duration') * this.get('workUnitRate') : '';
      },
      set: function set(_, newValue, oldValue) {
        if (newValue === '') {
          this.set('duration', undefined);
          return '';
        }
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model workplacesdefaultweekday - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('duration', value / this.get('workUnitRate'));
        }
        return value;
      }
    })
  });
});