define("boondmanager/pods/components/bm-field/flags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2GnOgW/U",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,11,[\"isFulfilled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[16,\"data-name\",[30,[36,1],[\"button-toggle-\",[35,0]],null]]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-flag\",[30,[36,3],[[32,0],\"toggleShow\"],null]]],null],[2,\"\\n\"],[6,[37,5],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,9],null,[[\"name\",\"size\",\"options\",\"selected\",\"placeholder\",\"dropdownClass\",\"multiple\",\"displayNbSelected\",\"renderInPlace\",\"onchange\",\"disabled\"],[[35,0],[35,8],[35,7],[35,6],[30,[36,5],[[35,4],[35,4],[30,[36,2],[\"common:toSelect\"],null]],null],\"dropdown-flags\",true,false,[32,0,[\"renderInPlace\"]],[30,[36,3],[[32,0],\"updateSelectedFlags\"],null],[32,0,[\"disabled\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[16,\"data-name\",[30,[36,1],[\"button-toggle-\",[35,0]],null]],[16,\"aria-label\",[30,[36,2],[\"models:modules.attributes.flags.name\"],null]]],[[\"@iconOnly\",\"@disabled\"],[\"bmi-spinner fa-pulse\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,2],[\"flag:title_plural\"],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"concat\",\"t\",\"action\",\"placeholder\",\"if\",\"selected\",\"options\",\"size\",\"bm-field/power-select\",\"showFlagInput\",\"flags\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/flags/template.hbs"
  });
});