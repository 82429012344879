define("boondmanager/pods/components/bm-field/rate/component", ["exports", "jquery", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _jquery, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Composant Rate Input
   *
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * Attributs par défaut de la balise __class__ du composant.
     *
     * @property classNames
     * @type Array
     * @default  ['bm-field-rate']
     */
    classNames: ['bmc-field-rate'],
    classNameBindings: ['disabled'],
    name: 'rate',
    value: 0,
    disabled: false,
    addListener: function addListener() {
      var _this = this;
      var stars = (0, _jquery.default)('#' + this.elementId + ' .rank');
      var self = this;
      if (!this.get('disabled')) {
        var _loop = function _loop(i) {
          stars.eq(i).click(function () {
            var rank = i + 1;
            self.set('value', rank);
            self.updateClasses();
            var onChange = _this.get('onchange');
            if (typeof onChange === 'function') {
              onChange(rank);
            }
          });
        };
        for (var i = 0; i < stars.length; i++) {
          _loop(i);
        }
      }
      this.updateClasses();
    },
    didInsertElement: function didInsertElement() {
      this.addListener();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.addListener();
    },
    updateClasses: function updateClasses() {
      var stars = (0, _jquery.default)('#' + this.elementId + ' .rank');
      var val = this.get('value');
      for (var i = 0; i < stars.length; i++) {
        if (i < val) {
          stars.eq(i).addClass('on');
        } else {
          stars.eq(i).removeClass('on');
        }
      }
    }
  });
});