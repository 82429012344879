define("boondmanager/models/appextractpayrollcontract", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable"], function (_exports, _emberData, _base, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsExtractPayrollContract = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'dependsOn', 'timesReports', 'expensesReports', 'advantagesToPay']
    }),
    payrollComments: _emberData.default.attr('string'),
    payrollTerm: _emberData.default.attr('date'),
    currency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_DEVISEAGENCE
    endDate: _emberData.default.attr('date'),
    // TAB_CONTRAT.CTR_FIN
    exchangeRate: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_CHANGEAGENCE
    monthlySalary: _emberData.default.attr('number'),
    // TAB_CONTRAT.CTR_SALAIREMENSUEL
    productionTimes: _emberData.default.attr('number'),
    internalTimes: _emberData.default.attr('number'),
    absencesTimes: _emberData.default.attr('number'),
    expensesToPay: _emberData.default.attr('number'),
    expensesAlreadyAdvanced: _emberData.default.attr('number'),
    canGenerateAdvantages: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    startDate: _emberData.default.attr('date'),
    // TAB_CONTRAT.CTR_DEBUT
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    agency: _emberData.default.belongsTo(),
    dependsOn: _emberData.default.belongsTo('profile', {
      inverse: null
    }),
    timesReports: _emberData.default.hasMany('timesreport'),
    expensesReports: _emberData.default.hasMany('expensesreport'),
    advantagesToPay: _emberData.default.hasMany('advantage'),
    contractId: Ember.computed('id', function () {
      var tabId = this.get('id').split('_');
      return tabId[0];
    })
  });
  AppsExtractPayrollContract.reopenClass({
    prefix: 'CTR'
  });
  AppsExtractPayrollContract.reopen({
    prefix: Ember.computed(function () {
      return AppsExtractPayrollContract.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsExtractPayrollContract;
});