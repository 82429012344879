define("boondmanager/models/company", ["exports", "boondmanager/models/base", "boondmanager/config/environment", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes", "ember-data-copyable"], function (_exports, _base, _environment, _emberData, _emberDataModelFragments, _attributes, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var Company = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'influencers', 'mainManager', 'parentCompany', 'pole']
    }),
    canReadCompany: _emberData.default.attr('boolean'),
    canWriteCompany: _emberData.default.attr('boolean'),
    // ATTRIBUTES
    //TODO when an attribute is an ID, add 'ID' after its name. so we'll can add a CP to give the real value like 'titleID' -> real 'title'
    //TODO use model Fragment (cf https://github.com/lytics/ember-data-model-fragments) when use DS.attr() without type
    address: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_ADR
    apeCode: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_NAF
    country: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_PAYS
    subDivision: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_SUBDIVISION
    creationDate: _emberData.default.attr('moment'),
    // TAB_CRMSOCIETE.CSOC_DATE
    /**
     * List of departement IDs
     * @type {String[]}
     */
    departments: _emberDataModelFragments.default.array('string', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // TAB_CRMSOCIETE.CSOC_SERVICES
    expertiseArea: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_INTERVENTION
    fax: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_FAX
    informationComments: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_METIERS
    legalStatus: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_STATUT
    name: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_SOCIETE
    number: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_NUMERO
    phone1: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_TEL
    postcode: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_CP
    registeredOffice: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_RCS
    registrationNumber: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_SIREN TAB_CRMSOCIETE.CSOC_NIC
    staff: _emberData.default.attr('number'),
    // TAB_CRMSOCIETE.CSOC_EFFECTIF
    state: _emberData.default.attr('number'),
    // TAB_CRMSOCIETE.CSOC_TYPE
    town: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_VILLE
    updateDate: _emberData.default.attr('moment'),
    // TAB_CRMSOCIETE.CSOC_DATEUPDATE
    vatNumber: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_TVA
    website: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_WEB
    thumbnail: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_THUMBNAIL

    // Company Actions (previous & next)
    previousAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    nextAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    thumbnailUrl: Ember.computed('thumbnail', function () {
      if (this.get('thumbnail')) {
        return _environment.default.apiURL + '/api/thumbnails/' + this.get('thumbnail');
      } else {
        return '';
      }
    }),
    // FRAGMENTS
    billingDetails: (0, _attributes.fragmentArray)('billingdetail'),
    indicators: (0, _attributes.fragmentArray)('indicatorfragment'),
    origin: (0, _attributes.fragment)('source', {
      defaultValue: function defaultValue() {
        return {
          typeOf: -1,
          detail: ''
        };
      }
    }),
    socialNetworks: (0, _attributes.fragmentArray)('socialnetwork', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    exceptionalScales: (0, _attributes.fragmentArray)('exceptionalscale', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // RELATIONSHIPS
    agency: _emberData.default.belongsTo(),
    files: _emberData.default.hasMany('document'),
    influencers: _emberData.default.hasMany('resource', {
      inverse: 'influencerToCompany'
    }),
    mainManager: _emberData.default.belongsTo('resource'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    parentCompany: _emberData.default.belongsTo('company', {
      inverse: 'subsidiaries'
    }),
    subsidiaries: _emberData.default.hasMany('company', {
      inverse: 'parentCompany'
    }),
    pole: _emberData.default.belongsTo(),
    // CP
    influencersIDs: Ember.computed.mapBy('influencers', 'id'),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }),
    websiteLink: Ember.computed('website', function () {
      var website = this.get('website');
      if (website) {
        if (website.indexOf('http') === 0) {
          return website;
        } else return 'http://' + website;
      } else {
        return website;
      }
    }),
    exceptionalScalesAgencies: Ember.computed('exceptionalScales', function () {
      //https://medium.com/@jv.quilichini/comment-supprimer-les-doublons-dun-tableau-en-es6-c547a5b2bcf3
      var agencies = _toConsumableArray(new Set(this.get('exceptionalScales').map(function (scale) {
        return scale.get('agency.name');
      })));
      return agencies.join(', ');
    })
  });
  Company.reopenClass({
    prefix: 'CSOC'
  });
  Company.reopen({
    prefix: Ember.computed(function () {
      return Company.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Company;
});