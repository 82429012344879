define("boondmanager/pods/components/bm-field/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8II0clCI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"bm-input-group \",[30,[36,1],[[35,2],\"bm-focus\"],null],\" \",[30,[36,1],[[35,0],\"bm-has-value\"],null]]]],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bm-input-group-icon-left\"],[12],[1,[30,[36,3],[\"bmi-search\"],null]],[13],[2,\"\\n\\t\"],[8,\"octane/bm-field/input\",[],[[\"@value\",\"@focusIn\",\"@focusOut\",\"@keyUp\"],[[34,0],[30,[36,4],[[32,0],\"focusIn\"],null],[30,[36,4],[[32,0],\"focusOut\"],null],[30,[36,4],[[32,0],\"keyUp\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"if\",\"focused\",\"icon\",\"action\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/search/template.hbs"
  });
});