define("boondmanager/models/appdataclosinginvoice", ["exports", "boondmanager/models/invoice"], function (_exports, _invoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _invoice.default.extend({
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'invoice'
  });
});