define("boondmanager/pods/components/bm-field/search/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-search'],
    actions: {
      keyUp: function keyUp() {
        if (this.get('onkeyup')) {
          this.get('onkeyup').apply(void 0, arguments);
        }
      },
      focusIn: function focusIn() {
        if (!this.disabled) {
          this.set('focused', true);
        }
      },
      focusOut: function focusOut() {
        this.set('focused', false);
      }
    }
  });
});