define("boondmanager/models/appemailingshare", ["exports", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataCopyable, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['template']
    }),
    body: _emberData.default.attr('string'),
    object: _emberData.default.attr('string'),
    typeOf: _emberData.default.attr('string'),
    nextStateInvoice: _emberData.default.attr('number'),
    nextStateQuotation: _emberData.default.attr('number'),
    nextStateCandidate: _emberData.default.attr('number'),
    nextStateReasonCandidate: (0, _attributes.fragment)('statereason'),
    nextStateResource: _emberData.default.attr('number'),
    nextStateReasonResource: (0, _attributes.fragment)('statereason'),
    nextStateContact: _emberData.default.attr('number'),
    nextStatePositioning: _emberData.default.attr('number'),
    nextStateReasonPositioning: (0, _attributes.fragment)('statereason'),
    froms: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    recipients: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    to: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    cc: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    bcc: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    emails: _emberData.default.attr(),
    attachments: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    template: _emberData.default.belongsTo('appemailingtemplate')
  });
});