define("boondmanager/pods/components/bm-field/searchItem-abstract/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "b0E2dcWS",
    "block": "{\"symbols\":[\"resource\"],\"statements\":[[6,[37,7],null,[[\"customSearch\",\"useObjects\",\"selected\",\"onchange\",\"renderInPlace\",\"options\",\"searchMessage\",\"searchPlaceholder\",\"loadingMessage\",\"allowClear\",\"disabled\"],[[30,[36,5],[[32,0],\"searchItems\"],null],true,[35,6],[30,[36,5],[[32,0],\"customOnChange\"],null],[35,4],[35,3],\"\",\"\",[35,2],[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[1,[32,1,[\"entityName\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"disabled\",\"allowClear\",\"loadingMessage\",\"options\",\"renderInPlace\",\"action\",\"selected\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/searchItem-abstract/template.hbs"
  });
});