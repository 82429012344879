define("boondmanager/models/product", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "boondmanager/utils/financial"], function (_exports, _base, _emberData, _emberDataCopyable, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_ON_GOING = void 0;
  var STATE_ON_GOING = _exports.STATE_ON_GOING = 1;
  var Product = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'mainManager', 'pole']
    }),
    currency: _emberData.default.attr('number'),
    // TAB_PRODUIT.PRODUIT_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_PRODUIT.PRODUIT_DEVISEAGENCE
    description: _emberData.default.attr('string'),
    exchangeRate: _emberData.default.attr('number'),
    // TAB_PRODUIT.PRODUIT_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_PRODUIT.PRODUIT_CHANGEAGENCE
    name: _emberData.default.attr('string'),
    // TAB_PRODUIT.PRODUIT_NOM
    priceExcludingTax: _emberData.default.attr('number'),
    // TAB_PRODUIT.PRODUIT_TARIFHT
    reference: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    // TAB_PRODUIT.PRODUIT_REF
    state: _emberData.default.attr('number'),
    subscription: _emberData.default.attr('number'),
    // TAB_PRODUIT.PRODUIT_TYPE
    taxRate: _emberData.default.attr('number'),
    taxRates: _emberData.default.attr(),
    updateDate: _emberData.default.attr('moment'),
    canReadProduct: _emberData.default.attr('boolean'),
    canWriteProduct: _emberData.default.attr('boolean'),
    creationDate: _emberData.default.attr('moment'),
    // TAB_PRODUIT.PRODUIT_CREATEDAT,

    // Relationships
    agency: _emberData.default.belongsTo(),
    files: _emberData.default.hasMany('documents'),
    mainManager: _emberData.default.belongsTo('resource'),
    pole: _emberData.default.belongsTo(),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    /**************************************************************************/
    /* JSDoc - typedef ********************************************************/
    /**************************************************************************/
    /**
     * Datalist type from dicts or return in model.meta
     *
     * @typedef IdValueHash
     * @type {Object}
     * @property {string} id
     * @property {(string|Object)} value
     */
    /**************************************************************************/
    /* /JSDoc - typedef *******************************************************/
    /**************************************************************************/

    /**
     * Translated typeOf
     *
     * @property typeOfHash
     * @type {IdValueHash}
     *
     * If i18n.locale = "fr" and typeOf = 0, return:
     * ```
     *   {
     *     id    : 0,
     *     value : "Consultant interne"
     *   }
     * ```
     */
    typeOfHash: Ember.computed('typeOf', 'i18n._locale', function () {
      var typeID = this.get('typeOf');
      var type = this.get('settingsService').customer.typeOf.subscription.find(function (item) {
        return item.id === typeID;
      });
      return type ? type : {
        id: typeID,
        value: this.get('i18n').t('common:unknown.name')
      };
    }),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }),
    isUnitSubscription: Ember.computed.equal('subscription', 0).readOnly(),
    isMonthlySubscription: Ember.computed.equal('subscription', 1).readOnly(),
    isQuarterlySubscription: Ember.computed.equal('subscription', 2).readOnly(),
    isHalfYearlySubscription: Ember.computed.equal('subscription', 3).readOnly(),
    isAnnualSubscription: Ember.computed.equal('subscription', 4).readOnly(),
    labelColor: Ember.computed('subscription', function () {
      var subscription = this.get('subscription');
      switch (subscription) {
        case 0:
          return 'danger';
        case 1:
          return 'warning';
        case 2:
          return 'success';
        case 3:
          return 'info';
        default:
          return 'primary';
      }
    }),
    priceIncludingTax: Ember.computed('priceExcludingTax', 'taxRates', 'exchangeRate', 'exchangeRateAgency', function () {
      return _financial.default.calculateAmountIncludingTax(this.get('priceExcludingTax'), this.get('taxRates'), this.get('exchangeRate'), this.get('exchangeRateAgency'));
    }).readOnly(),
    isTaxRatesMultiples: Ember.computed('taxRates', 'agency.productsAllowMultiplesTaxRates', function () {
      return this.get('taxRates').length > 1 || this.get('agency.productsAllowMultiplesTaxRates');
    }).readOnly(),
    taxRatesSelected: Ember.computed('isTaxRatesMultiples', 'taxRates', function () {
      if (this.get('isTaxRatesMultiples')) return this.get('taxRates');else return this.get('taxRates.0');
    })
  });
  Product.reopenClass({
    prefix: 'PROD'
  });
  Product.reopen({
    prefix: Ember.computed(function () {
      return Product.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Product;
});