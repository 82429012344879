define("boondmanager/pods/components/bm-field/purchase/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field_purchase'],
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    searchPrefix: 'ACH',
    context: 'purchase',
    displayNbSelected: true,
    _getItems: function _getItems(input) {
      var params = {
        keywords: input
      };
      return this.get('store').query('purchase', params);
    }
  });
});