define("boondmanager/models/groupmentdeliveryfragment", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/utils/logger", "boondmanager/utils/financial"], function (_exports, _emberData, _emberDataModelFragments, _logger, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    delivery: _emberData.default.attr(),
    weighting: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    schedule: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // owner = groupment
    owner: _emberDataModelFragments.default.fragmentOwner(),
    scheduleInWorkUnits: Ember.computed('schedule', 'owner.project.workUnitRate', {
      get: function get() {
        return this.get('schedule') * this.get('owner.project.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model groupmentdeliveryfragment - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('schedule', this.get('owner.project.workUnitRate') ? (0, _financial.roundAtPrecision)(value / this.get('owner.project.workUnitRate'), 2) : value);
        }
        return value;
      }
    })
  });
});