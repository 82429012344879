define("boondmanager/pods/components/bm-field/transfer/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-transfert'],
    store: Ember.inject.service(),
    rights: Ember.inject.service(),
    /* Route rights */
    entityRights: Ember.computed('rights.lastUpdate', function () {
      return this.get('rights').getEntityRights('private.deliveries.delivery');
    }),
    transferOptions: Ember.computed('entityRights.attributes.forceTransferCreation.write', function () {
      return this.get('settings').bm.component.bmFieldTransfer.actionValue;
    }),
    canLaunchSearch: Ember.computed('input', function () {
      return this.get('input') && this.get('input').length >= 3;
    }),
    _getDeliveries: function _getDeliveries(input) {
      var params = {
        keywords: input,
        sort: 'id',
        order: 'asc'
      };
      if (this.get('delivery')) {
        params.delivery = this.get('delivery.id');
      }
      return this.get('store').query('deliverygroupment', params);
    },
    loadingMessage: Ember.computed('i18n._locale', 'input', function () {
      var i18n = this.get('i18n');
      return this.get('canLaunchSearch') ? i18n.t('components:bmFieldSearchItems.loadingMessage') : i18n.t('components:bmFieldSearchItems.searchMessage');
    }).readOnly(),
    noMatchesMessage: Ember.computed('i18n._locale', 'input', function () {
      var i18n = this.get('i18n');
      return this.get('canLaunchSearch') ? i18n.t('components:bmFieldSearchItems.noMatchesMessage') : i18n.t('components:bmFieldSearchItems.searchMessage');
    }),
    options: Ember.computed('input', function () {
      var input = this.get('input');
      var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      return proxy.create({
        promise: this._getDeliveries(input)
      });
    }),
    actionsList: Ember.computed('i18n._locale', function () {
      return [{
        id: 'force',
        value: 'Créer automatiquement un nouveau projet de cession'
      }, {
        id: 'select',
        value: 'Forcer le choix de la prestation cédante'
      }];
    }),
    createTransfer: false,
    actionSelected: 'force',
    showSelect: false,
    forceShowSelect: Ember.computed('actionSelected', function () {
      return this.get('actionSelected') === 'select';
    }),
    showGlobalSelect: Ember.computed('selected', 'selected.isFulfilled', 'forceShowSelect', function () {
      return this.get('forceShowSelect') || this.get('selected');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');

      // check onChange
      (false && !(!Ember.isBlank(onChange)) && Ember.assert('bm-field-transfer "onChange" must have a attribute!', !Ember.isBlank(onChange)));
      (false && !(Ember.typeOf(onChange) === 'function') && Ember.assert('bm-field-transfer "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function'));
    },
    actions: {
      toggleSelect: function toggleSelect() {
        if (!this.get('disabled')) {
          var onUnlock = this.get('onUnlock');
          this.toggleProperty('showSelect');
          if (this.get('showSelect') && typeof onUnlock === 'function') {
            onUnlock();
          }
        }
      },
      searchEntities: function searchEntities(input) {
        this.set('input', input);
        if (this.get('canLaunchSearch')) {
          return this.get('options');
        }
      },
      _onchange: function _onchange(value) {
        this.get('onChange')(value);
        if (!value) {
          this.set('actionSelected', 'force');
          this.set('createTransfer', true);
        } else if (value !== 'force') {
          this.set('selected', value);
        }
      },
      updateTransfer: function updateTransfer(value) {
        if (value) {
          this.send('_onchange', 'force');
        } else {
          this.send('_onchange', null);
        }
        this.set('createTransfer', value);
      },
      unset: function unset() {
        this.send('_onchange', null);
        this.set('createTransfer', false);
      }
    }
  });
});