define("boondmanager/controllers/activity", ["exports", "boondmanager/controllers/entity", "boondmanager/helpers/icon", "bootbox"], function (_exports, _entity, _icon, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;

  /**
   * Contrôleur parent des modules d'activités
   *
   * @class  ActivityController
   * @extends Ember.Controller
   * @namespace  Ember
   * @module  Controllers
   */
  var _default = _exports.default = _entity.default.extend({
    /*** SERVICES ***/
    currentUser: Ember.inject.service('current-user'),
    appTriggers: Ember.inject.service('app-triggers'),
    /** PROPERTIES **/
    /**
     * Alias du isSaving pour savoir si la fiche est en cours de validation
     *
     * @property isValidating
     * @type {boolean}
     */
    isValidating: Ember.computed.alias('isSaving'),
    /**
     * Validateur attendu
     *
     * @property expectedValidator
     * @type {Object}
     * @default null
     */
    expectedValidator: null,
    /**
     * popup semaphore visibility
     *
     * @property showInvoiceForm
     * @type boolean
     */
    showInvoiceForm: false,
    /**
     * selected order for the invoice
     *
     * @property selectedOrder
     * @type Order
     */
    selectedOrder: null,
    /**
     * display the modal to generate client's PDF
     *
     * @property showFormExternalPDF
     * @type boolean
     */
    showFormExternalPDF: false,
    /**
     * Les apps liées à la fiche remplissent les conditions pour pouvoir valider la fiche
     *
     * @property enableValidateFromApp
     * @type {boolean}
     */
    enableValidateFromApp: Ember.computed.alias('appTriggers.enableValidateFromApp'),
    /** COMPUTED **/
    validateValidatorsList: Ember.computed('model.meta.expectedValidatorsAllowedForValidate', 'currentUser.id', function () {
      var currentUserId = this.get('currentUser.id');
      var list = this.get('model.meta.expectedValidatorsAllowedForValidate');
      return !Ember.isArray(list) || list.length === 1 && list[0].id === currentUserId ? [] : list;
    }).readOnly(),
    validateValidatorsListSorted: Ember.computed('validateValidatorsList', function () {
      return this.sortedList(this.get('validateValidatorsList'));
    }).readOnly(),
    unvalidateValidatorsList: Ember.computed('model.meta.expectedValidatorsAllowedForUnValidate', 'currentUser.id', function () {
      var currentUserId = this.get('currentUser.id');
      var list = this.get('model.meta.expectedValidatorsAllowedForUnValidate');
      return !Ember.isArray(list) || list.length === 1 && list[0].id === currentUserId ? [] : list;
    }).readOnly(),
    unvalidateValidatorsListSorted: Ember.computed('unvalidateValidatorsList', function () {
      return this.sortedList(this.get('unvalidateValidatorsList'));
    }).readOnly(),
    rejectValidatorsList: Ember.computed('model.meta.expectedValidatorsAllowedForReject', 'currentUser.id', function () {
      var currentUserId = this.get('currentUser.id');
      var list = this.get('model.meta.expectedValidatorsAllowedForReject');
      return !Ember.isArray(list) || list.length === 1 && list[0].id === currentUserId ? [] : list;
    }).readOnly(),
    rejectValidatorsListSorted: Ember.computed('rejectValidatorsList', function () {
      return this.sortedList(this.get('rejectValidatorsList'));
    }).readOnly(),
    isLastValidator: Ember.computed('validateValidatorsList', 'unvalidateValidatorsList', 'rejectValidatorsList', 'model.{validationWorkflow,validations}', 'current.isManager', function () {
      var _this = this;
      var promise = this.get('model.validationWorkflow').then(function (validationWorkflow) {
        var nbValidators = validationWorkflow.length;
        var nbValidations = 0;
        return _this.get('model.validations').then(function (validations) {
          validations.forEach(function (validation) {
            if (validation.isValidated) {
              nbValidations++;
            }
          });
          var lastValidatorOnWorkflow = validationWorkflow.objectAt(validationWorkflow.length - 1);
          var isInLastPosition = lastValidatorOnWorkflow ? lastValidatorOnWorkflow.get('id') === _this.get('currentUser.id') : false;
          return nbValidations === nbValidators - 1 || _this.get('currentUser.isManager') && _this.get('validateValidatorsList.length') === 0 && isInLastPosition;
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    /** METHODS **/
    sortedList: function sortedList(list) {
      var revertedList = [];
      list.forEach(function (item) {
        revertedList.unshiftObject(item);
      });
      return revertedList;
    },
    onBeforeValidate: function onBeforeValidate() {
      return this.get('appTriggers').onBeforeValidate();
    },
    onAfterValidate: function onAfterValidate(validation, isNew) {
      var _this2 = this;
      if (isNew) {
        return this.get('appTriggers').onAfterValidate(validation).then(function () {
          return _this2.replaceRoute(_this2.get('router.currentRoute.name'), validation.get('id'));
        });
      } else {
        this.send('reloadRights');
        return this.get('appTriggers').onAfterValidate(validation).then(function () {
          return validation;
        });
      }
    },
    onAfterUnvalidate: function onAfterUnvalidate(validation) {
      this.send('reloadRights');
      return this.get('appTriggers').onAfterUnvalidate(validation).then(function () {
        return validation;
      });
    },
    onAfterReject: function onAfterReject(validation) {
      this.send('reloadRights');
      return this.get('appTriggers').onAfterReject(validation).then(function () {
        return validation;
      });
    },
    onAfterValidateError: function onAfterValidateError(validation) {
      return this.get('appTriggers').onAfterValidateError(validation);
    },
    onValidationError: function onValidationError(validation, error) {
      var _this3 = this;
      // in case of error on validate, rollback attribute to disable the save button
      // because the save method has already been called and was successful
      this.get('model').rollbackAttributes();
      this.onAfterValidateError(validation).then(function () {
        _this3.send('reloadRights');
        _this3.handleGenericValidateErrorOnRequest(error);
      }).catch(function (error) {
        _this3.catchValidationErrors(error);
      });
    },
    catchValidationErrors: function catchValidationErrors(error) {
      this.handleGenericValidateErrorOnRequest(error);
    },
    handleGenericValidateErrorOnRequest: function handleGenericValidateErrorOnRequest(error) {
      this.handleErrorOnRequest(error, this.get('i18n').t('common:validateError'), this.get('i18n').t('common:actions.validate'));
    },
    onAfterValidateSuccess: function onAfterValidateSuccess(validation) {
      return validation;
    },
    onAfterUnvalidateSuccess: function onAfterUnvalidateSuccess(validation) {
      return validation;
    },
    displayInvoiceForm: function displayInvoiceForm(orders) {
      var id = orders.get('firstObject').id;
      this.set('selectedOrder', id);
      this.set('showInvoiceForm', true);
    },
    createInvoice: function createInvoice(type, selectedOrder, orders) {
      this.set('showInvoiceForm', false);
      var order = orders.findBy('id', selectedOrder);
      this.router.transitionTo('private.invoices.invoice', 0, {
        queryParams: {
          order: order.get('id'),
          isCreditNote: false,
          term: this.get('model.term').format('YYYY-MM'),
          autoFillItemsWithTimesExpensesPurchases: type
        }
      });
    },
    /** ACTIONS **/
    actions: {
      closeValidationModal: function closeValidationModal() {
        this.set('showValidationForm', false);
        this.set('showValidationModalContent', false);
      },
      closeDeValidationModal: function closeDeValidationModal() {
        this.set('showDevalidationForm', false);
        this.set('showDevalidationModalContent', false);
      },
      closeRejectModal: function closeRejectModal() {
        this.set('showRejectionForm', false);
      },
      closeInvoiceFormModal: function closeInvoiceFormModal() {
        this.set('showInvoiceForm', false);
      },
      showFormExternalPDF: function showFormExternalPDF() {
        this.toggleProperty('showFormExternalPDF');
      },
      closeFormExternalPdfModal: function closeFormExternalPdfModal() {
        this.set('showFormExternalPDF', false);
      },
      onConfirmUnvalidate: function onConfirmUnvalidate(expectedValidator) {
        var _this4 = this;
        this.send('closeDeValidationModal');
        var i18n = this.get('i18n');
        var options = {
          adapterOptions: {
            endpoint: 'unvalidate'
          }
        };
        if (expectedValidator) {
          options.adapterOptions.queryParams = {
            expectedValidator: expectedValidator
          };
        }
        if (this.get('model').get('hasDirtyAttributes')) {
          this.get('model').save().then(function () {
            return _this4.get('model').save(options);
          }).then(function () {
            _this4.get('notify').success(i18n.t('common:unvalidateSuccess'), i18n.t('common:actions.unvalidate'));
            _this4.send('reloadRights');
          }).then(function () {
            return _this4.onAfterUnvalidate(_this4.get('model'));
          }).then(function () {
            return _this4.onAfterUnvalidateSuccess(_this4.get('model'));
          }).catch(function (error) {
            _this4.send('reloadRights');
            _this4.handleErrorOnRequest(error, i18n.t('common:unvalidateError'), i18n.t('common:actions.unvalidate'));
          });
        } else {
          this.get('model').save(options).then(function () {
            _this4.get('notify').success(i18n.t('common:unvalidateSuccess'), i18n.t('common:actions.unvalidate'));
            _this4.send('reloadRights');
          }).then(function () {
            return _this4.onAfterUnvalidate(_this4.get('model'));
          }).then(function () {
            return _this4.onAfterUnvalidateSuccess(_this4.get('model'));
          }).catch(function (error) {
            _this4.send('reloadRights');
            _this4.handleErrorOnRequest(error, i18n.t('common:unvalidateError'), i18n.t('common:actions.unvalidate'));
          });
        }
      },
      unvalidate: function unvalidate() {
        if (this.get('model.isSaving')) return null;
        this.set('showDevalidationForm', true);
        if (this.get('unvalidateValidatorsListSorted.length')) {
          this.set('expectedValidator', this.get('unvalidateValidatorsListSorted.0.id'));
        } else {
          this.set('expectedValidator', null);
        }
      },
      onConfirmValidate: function onConfirmValidate(expectedValidator) {
        var _this5 = this;
        this.send('closeValidationModal');
        var i18n = this.get('i18n');
        var options = {
          adapterOptions: {
            endpoint: 'validate'
          }
        };
        if (this.get('isValidating')) {
          return false;
        }
        if (expectedValidator) {
          options.adapterOptions.queryParams = {
            expectedValidator: expectedValidator
          };
        }
        if (this.get('model').get('hasDirtyAttributes')) {
          var isNewModel = this.get('model.isNew');
          this.onBeforeSave(this.get('model')).then(function () {
            return _this5.get('model').save();
          }).then(function () {
            return _this5.onAfterSave(_this5.get('model'));
          }).then(function () {
            return _this5.get('model').save(options).then(function () {
              _this5.get('notify').success(i18n.t('common:validateSuccess'), i18n.t('common:actions.validate'));
            }).then(function () {
              return _this5.onAfterValidate(_this5.get('model'), isNewModel);
            }).then(function () {
              return _this5.onAfterValidateSuccess(_this5.get('model'));
            }).catch(function (error) {
              _this5.onValidationError(_this5.get('model'), error);
            });
          }).catch(function (error) {
            return _this5.onValidationError(_this5.get('model'), error);
          });
        } else {
          this.onAfterSave(this.get('model')).then(function () {
            return _this5.get('model').save(options);
          }).then(function () {
            _this5.get('notify').success(i18n.t('common:validateSuccess'), i18n.t('common:actions.validate'));
          }).then(function () {
            return _this5.onAfterValidate(_this5.get('model'));
          }).then(function () {
            return _this5.onAfterValidateSuccess(_this5.get('model'));
          }).catch(function (error) {
            return _this5.onValidationError(_this5.get('model'), error);
          });
        }
      },
      validate: function validate() {
        var _this6 = this;
        if (this.get('isValidating')) {
          return false;
        }
        this.onBeforeValidate(this.get('model')).then(function () {
          _this6.set('showValidationForm', true);
          if (_this6.get('validateValidatorsListSorted.length')) {
            _this6.set('expectedValidator', _this6.get('validateValidatorsListSorted.0.id'));
          } else {
            _this6.set('expectedValidator', null);
          }
        }, function (error) {
          _this6.send('openUnableToValidateModal', error);
        });
      },
      openUnableToValidateModal: function openUnableToValidateModal(error) {
        var i18n = this.get('i18n');
        var validateMsg = "<p class=\"bmc-modal-header bmc-modal-header-center\">\n\t\t\t\t\t\t\t\t\t\t".concat((0, _icon.icon)(['bmi-cannot-validate-modal-icon']), "\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t<div class=\"bmc-modal-content\">\n\t\t\t\t\t\t\t\t\t\t<div class=\"bm-note bm-note-warning\">\n\t\t\t\t\t\t\t\t\t\t\t<p class=\"bmc-modal-center-bigger\">").concat(i18n.t('components:modals.unableToValidate.message'), "</p>\n\t\t\t\t\t\t\t\t\t\t\t<br />\n\t\t\t\t\t\t\t\t\t\t\t<p>").concat(error, "</p>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>");
        _bootbox.default.dialog({
          className: 'bmc-modal',
          title: i18n.t('components:modals.unableToValidate.title'),
          message: validateMsg,
          onEscape: true,
          closeButton: false,
          buttons: {
            confirm: {
              label: i18n.t('common:actions.ok'),
              className: 'bmb-rectangle bmb-confirm'
            }
          }
        });
      },
      onConfirmReject: function onConfirmReject(expectedValidator, rejectTypeOf, rejectReason) {
        var _this7 = this;
        this.send('closeRejectModal');
        var i18n = this.get('i18n');
        var isModelDirty = this.get('model').get('hasDirtyAttributes');
        this.set('model.reason', rejectReason);
        this.set('model.rejectTypeOf', rejectTypeOf);
        if (expectedValidator) {
          this.set('model.expectedValidator', expectedValidator);
        }
        if (isModelDirty) {
          this.get('model').save().then(function () {
            return _this7.get('model').save({
              adapterOptions: {
                endpoint: 'reject'
              }
            });
          }).then(function () {
            _this7.get('notify').success(i18n.t('common:rejectSuccess'), i18n.t('common:actions.reject'));
            _this7.send('reloadRights');
          }).then(function () {
            return _this7.onAfterReject(_this7.get('model'));
          }).catch(function (error) {
            _this7.send('reloadRights');
            _this7.handleErrorOnRequest(error, i18n.t('common:rejectError'), i18n.t('common:actions.reject'));
          });
        } else {
          this.get('model').save({
            adapterOptions: {
              endpoint: 'reject'
            }
          }).then(function () {
            _this7.get('notify').success(i18n.t('common:rejectSuccess'), i18n.t('common:actions.reject'));
            _this7.send('reloadRights');
          }).then(function () {
            return _this7.onAfterReject(_this7.get('model'));
          }).catch(function (error) {
            _this7.send('reloadRights');
            _this7.handleErrorOnRequest(error, i18n.t('common:rejectError'), i18n.t('common:actions.reject'));
          });
        }
      },
      reject: function reject() {
        this.set('showRejectionForm', true);
        if (this.get('rejectValidatorsListSorted.length')) {
          this.set('expectedValidator', this.get('rejectValidatorsListSorted.0.id'));
        } else {
          this.set('expectedValidator', null);
        }
      },
      deleteValidation: function deleteValidation(validation) {
        var _this8 = this;
        var i18n = this.get('i18n');
        var message = "<p class=\"bmc-modal-header bmc-modal-header-center\">\n\t\t\t\t\t\t\t\t\t\t\t\t".concat((0, _icon.icon)(['bmi-delete-modal-icon']), "\n\t\t\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t\t\t<div class=\"bmc-modal-content\">\n\t\t\t\t\t\t\t\t\t\t\t\t<p class=\"bmc-modal-title\">").concat(i18n.t('components:modals.delete.confirm'), "</p>\n\t\t\t\t\t\t\t\t\t\t\t</div>");
        _bootbox.default.dialog({
          message: message,
          className: 'bmc-modal',
          title: i18n.t('components:modals.delete.title'),
          buttons: {
            cancel: {
              label: i18n.t('common:actions.cancel'),
              className: 'bmb-rectangle bmb-rectangle-border-on-hover'
            },
            delete: {
              label: i18n.t('common:actions.confirm'),
              className: 'bmb-rectangle bmb-confirm',
              callback: function callback() {
                validation.get('expectedValidator').then(function (expectedValidator) {
                  var firstName = escapeExpression(expectedValidator.get('firstName'));
                  var lastName = escapeExpression(expectedValidator.get('lastName'));
                  validation.destroyRecord().then(function () {
                    _this8.get('notify').success(i18n.t('common:deleteSuccess'), i18n.t('components:validations.delete', {
                      validator: "".concat(firstName, " ").concat(lastName)
                    }));
                    _this8.get('model').reload();
                    _this8.send('reloadRights');
                  }, function (error) {
                    _this8.handleErrorOnRequest(error, i18n.t('common:deleteError'), i18n.t('components:validations.delete', {
                      validator: "".concat(firstName, " ").concat(lastName)
                    }));
                  });
                });
              }
            }
          }
        });
      }
    }
  });
});