define("boondmanager/models/deliveryfragment", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/models/delivery"], function (_exports, _emberData, _emberDataModelFragments, _delivery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    entityName: Ember.computed('title', 'originalID', function () {
      var title = this.get('title');
      if (Ember.isBlank(title)) {
        return this.get('originalID') ? "".concat(_delivery.default.prefix).concat(this.get('originalID')) : '';
      } else {
        return title;
      }
    })
  });
});