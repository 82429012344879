define("boondmanager/models/invoicerecordtype", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    reference: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    agency: _emberData.default.attr(),
    canDelete: _emberData.default.attr('boolean'),
    schedule: _emberData.default.attr('boolean'),
    reinvoicedPurchase: _emberData.default.attr('boolean'),
    product: _emberData.default.attr('boolean'),
    workUnitTypes: _emberData.default.attr(),
    expenseTypes: _emberData.default.attr(),
    categoriesSelected: Ember.computed('workUnitTypes.{[],@each.reference,@each.agency.id}', 'expenseTypes.{[],@each.reference,@each.agency.id}', 'schedule', 'product', 'reinvoicedPurchase', function () {
      var categories = [];
      this.get('workUnitTypes').forEach(function (wut) {
        categories.push('workUnitType_' + wut.reference + '_' + wut.agency.id);
      });
      this.get('expenseTypes').forEach(function (wut) {
        categories.push('expenseType_' + wut.reference + '_' + wut.agency.id);
      });
      if (this.get('schedule')) {
        categories.push('schedule');
      }
      if (this.get('reinvoicedPurchase')) {
        categories.push('reinvoicedPurchase');
      }
      if (this.get('product')) {
        categories.push('product');
      }
      return categories;
    })
  });
});