define("boondmanager/models/apppostproductiondelivery", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable", "boondmanager/models/document"], function (_exports, _emberData, _base, _emberDataCopyable, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var AppsPostProductionDelivery = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['orders', 'dependsOn', 'timesReports']
    }),
    title: _emberData.default.attr('string'),
    productionTimes: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    productionTimesInWorkUnits: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfCorrelatedOrders: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    averageDailyPriceExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    productionDetails: _emberData.default.attr({
      defaultValue: []
    }),
    workUnitRate: _emberData.default.attr('number'),
    dependsOn: _emberData.default.belongsTo('base', {
      inverse: null
    }),
    orders: _emberData.default.hasMany('order'),
    project: _emberData.default.belongsTo('project'),
    timesReports: _emberData.default.hasMany('timesreport'),
    files: _emberData.default.hasMany('document'),
    dependsOnProduct: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'product';
    }).readOnly(),
    missingSignedTimesheets: Ember.computed('orders.@each.attachSignedTimesheets', 'timesReports.[]', 'files.[]', 'productionTimes', function () {
      var _this = this;
      return Ember.RSVP.all([this.get('orders'), this.get('timesReports'), this.get('project'), this.get('files')]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 4),
          orders = _ref2[0],
          timesReports = _ref2[1],
          project = _ref2[2],
          files = _ref2[3];
        var monitoredOrders = orders.filter(function (order) {
          return order.get('attachSignedTimesheets');
        });
        if (Ember.isEmpty(monitoredOrders) || _this.get('productionTimes') <= 0) {
          return false;
        }
        var hasSignedtimesheets = timesReports.any(function (timesReport) {
          //MBE: Check supplémentaire car si on modifie uniquement la catégorie d'une PJ
          //le tableau files n'est pas mise à jour avec le retour du back sur postproduction
          var proofs = files.filter(function (proof) {
            return proof.get('category') === _document.PROOF_CATEGORY_SIGNED && proof.get('dependsOn.id') === timesReport.get('id') && proof.get('project.id') === project.get('id');
          });
          return proofs.length > 0;
        });
        return !hasSignedtimesheets;
      });
    }).readOnly(),
    missingOptionalSignedTimesheets: Ember.computed('orders.@each.requestTimesheetsSignature', 'timesReports.[]', 'files.[]', 'productionTimes', function () {
      var _this2 = this;
      return Ember.RSVP.all([this.get('orders'), this.get('timesReports'), this.get('project'), this.get('files')]).then(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 4),
          orders = _ref4[0],
          timesReports = _ref4[1],
          project = _ref4[2],
          files = _ref4[3];
        var monitoredOrders = orders.filter(function (order) {
          return order.get('requestTimesheetsSignature') && !order.get('attachSignedTimesheets');
        });
        if (Ember.isEmpty(monitoredOrders) || _this2.get('productionTimes') <= 0) {
          return false;
        }
        var hasSignedtimesheets = timesReports.any(function (timesReport) {
          //MBE: Check supplémentaire car si on modifie uniquement la catégorie d'une PJ
          //le tableau files n'est pas mise à jour avec le retour du back sur postproduction
          var proofs = files.filter(function (proof) {
            return proof.get('category') === _document.PROOF_CATEGORY_SIGNED && proof.get('dependsOn.id') === timesReport.get('id') && proof.get('project.id') === project.get('id');
          });
          return proofs.length > 0;
        });
        return !hasSignedtimesheets;
      });
    }).readOnly(),
    signatureRequestedTimesheets: Ember.computed('orders.@each.attachTimesheets', 'timesReports.[]', 'files.[]', 'productionTimes', function () {
      var _this3 = this;
      return Ember.RSVP.all([this.get('orders'), this.get('timesReports'), this.get('project'), this.get('files')]).then(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 4),
          orders = _ref6[0],
          timesReports = _ref6[1],
          project = _ref6[2],
          files = _ref6[3];
        var monitoredOrders = orders.filter(function (order) {
          return order.get('attachTimesheets');
        });
        if (Ember.isEmpty(monitoredOrders) || _this3.get('productionTimes') <= 0) {
          return false;
        }
        var hasSignatureRequestedTimesheets = timesReports.any(function (timesReport) {
          //MBE: Check supplémentaire car si on modifie uniquement la catégorie d'une PJ
          //le tableau files n'est pas mise à jour avec le retour du back sur postproduction
          var signatureRequestedProofs = files.filter(function (proof) {
            return proof.get('category') === _document.PROOF_CATEGORY_SIGNATURE_REQUESTED && proof.get('dependsOn.id') === timesReport.get('id') && proof.get('project.id') === project.get('id');
          });
          var signedProofs = files.filter(function (proof) {
            return proof.get('category') === _document.PROOF_CATEGORY_SIGNED && proof.get('dependsOn.id') === timesReport.get('id') && proof.get('project.id') === project.get('id');
          });
          return signatureRequestedProofs.length > 0 && signedProofs.length === 0;
        });
        return hasSignatureRequestedTimesheets;
      });
    }).readOnly(),
    hasBlockGenerationConfig: Ember.computed('orders.@each.attachSignedTimesheets', function () {
      return this.get('orders').then(function (orders) {
        return orders.isAny('attachSignedTimesheets', true);
      });
    }).readOnly(),
    averageWorkUnitPriceExcludingTax: Ember.computed('averageDailyPriceExcludingTax,workUnitRate', function () {
      return this.get('workUnitRate') ? this.get('averageDailyPriceExcludingTax') / this.get('workUnitRate') : 0;
    }).readOnly()
  });
  AppsPostProductionDelivery.reopenClass({
    prefix: 'MIS'
  });
  AppsPostProductionDelivery.reopen({
    prefix: Ember.computed(function () {
      return AppsPostProductionDelivery.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsPostProductionDelivery;
});