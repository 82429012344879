define("boondmanager/models/profile", ["exports", "boondmanager/models/base", "boondmanager/config/environment", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable", "boondmanager/utils/cachecomputed"], function (_exports, _base, _environment, _emberData, _attributes, _emberDataCopyable, _cachecomputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INTERNAL_CONSULTANT = _exports.EXTERNAL_CONSULTANT = _exports.CIVILITY_MRS = _exports.CIVILITY_MR = _exports.BACHELOR = void 0;
  var CIVILITY_MR = _exports.CIVILITY_MR = 0;
  var CIVILITY_MRS = _exports.CIVILITY_MRS = 1;
  var BACHELOR = _exports.BACHELOR = 0;
  var INTERNAL_CONSULTANT = _exports.INTERNAL_CONSULTANT = 0;
  var EXTERNAL_CONSULTANT = _exports.EXTERNAL_CONSULTANT = 1;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var _default = _exports.default = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'apps', 'hrManager', 'mainManager', 'pole', 'providerCompany', 'providerContact', 'stateReason']
    }),
    thumbnail: _emberData.default.attr('string'),
    thumbnailUrl: Ember.computed('thumbnail', function () {
      if (this.get('thumbnail')) {
        return _environment.default.apiURL + '/api/thumbnails/' + this.get('thumbnail');
      } else {
        return '';
      }
    }),
    /*** ATTRIBUTES ***********************************************************/
    /**
     * List of activity areas IDs
     * @type {Array.<String>}
     */
    activityAreas: _emberData.default.attr(),
    // TAB_DT.COMP_APPLICATIONS
    address: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_ADR
    administrativeComments: _emberData.default.attr('string'),
    // TAB_PROFIL.PARAM_COMMENTAIRE2
    availability: _emberData.default.attr('availability'),
    // TAB_PROFIL.PARAM_TYPEDISPO, TAB_PROFIL.PARAM_DATEDISPO
    realAvailability: _emberData.default.attr('availability'),
    // Real availability
    civility: _emberData.default.attr('number'),
    // TAB_PROFIL.PROFIL_CIVILITE
    country: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_PAYS
    subDivision: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_SUBDVISION
    creationDate: _emberData.default.attr('moment'),
    // TAB_PROFIL.PROFIL_DATE
    currency: _emberData.default.attr('number'),
    // TAB_PROFIL.PARAM_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_PROFIL.PARAM_DEVISEAGENCE
    dateOfBirth: _emberData.default.attr('date'),
    // TAB_PROFIL.PROFIL_DATENAISSANCE
    email1: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_EMAIL
    email2: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_EMAIL2
    email3: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_EMAIL3
    exchangeRate: _emberData.default.attr('number'),
    // TAB_PROFIL.PARAM_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_PROFIL.PARAM_CHANGEAGENCE
    experience: _emberData.default.attr('number'),
    // TAB_DT.DT_EXPERIENCE
    /**
     * List of expertise area IDs
     * @type {Array.<String>}
     */
    expertiseAreas: _emberData.default.attr(),
    // TAB_DT.COMP_INTERVENTIONS
    fax: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_FAX
    firstName: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_PRENOM
    healthCareNumber: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_NUMSECU
    informationComments: _emberData.default.attr('string'),
    // TAB_PROFIL.PARAM_COMMENTAIRE
    isVisible: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    // TAB_PROFIL.PROFIL_VISIBILITE
    lastName: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_NOM
    /**
     * List of mobility area IDs
     * @type {Array.<String>}
     */
    mobilityAreas: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // TAB_PROFIL.PARAM_MOBILITE
    nationality: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_NATIONALITE
    numberOfActivePositionings: _emberData.default.attr('number'),
    numberOfResumes: _emberData.default.attr('number'),
    phone1: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_TEL1
    phone2: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_TEL2
    phone3: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_TEL3
    placeOfBirth: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_LIEUNAISSANCE
    postcode: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_CP
    situation: _emberData.default.attr('number'),
    // TAB_PROFIL.PROFIL_SITUATION
    skills: _emberData.default.attr('string'),
    // TAB_DT.COMP_COMPETENCE
    state: _emberData.default.attr('number'),
    // TAB_PROFIL.PROFIL_ETAT
    stateReason: (0, _attributes.fragment)('statereason'),
    title: _emberData.default.attr('string'),
    // TAB_DT.DT_TITRE
    town: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_VILLE
    training: _emberData.default.attr('string'),
    // TAB_DT.DT_FORMATION
    typeOf: _emberData.default.attr('number'),
    updateDate: _emberData.default.attr('moment'),
    // TAB_PROFIL.PROFIL_DATEUPDATE

    /*** FRAGMENTS ************************************************************/
    diplomas: (0, _attributes.array)('string'),
    languages: (0, _attributes.fragmentArray)('language'),
    tools: (0, _attributes.fragmentArray)('tool'),
    references: (0, _attributes.fragmentArray)('reference'),
    socialNetworks: (0, _attributes.fragmentArray)('socialnetwork', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /*** RELATIONSHIPS ********************************************************/
    agency: _emberData.default.belongsTo(),
    apps: _emberData.default.hasMany(),
    files: _emberData.default.hasMany(),
    hrManager: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    mainManager: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    pole: _emberData.default.belongsTo(),
    providerCompany: _emberData.default.belongsTo('company', {
      inverse: null
    }),
    providerContact: _emberData.default.belongsTo('contact'),
    resumes: _emberData.default.hasMany('document'),
    advancedAppCalendar: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppMail: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppViewer: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppEmailing: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppCertification: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppTemplates: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    dashboards: _emberData.default.hasMany('dashboard'),
    // Profile Actions (previous & next)
    previousAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    nextAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    //HACK pour forcer le model à dirty
    availabilityRaw: _emberData.default.attr(),
    /*** CP *******************************************************************/
    hasResumes: (0, _cachecomputed.default)('numberOfResumes', function () {
      return this.get('numberOfResumes') > 0;
    }),
    /**
     * Rechargement de la dropzone pour la demande de signature électronique
     */
    reloadFiles: false,
    /**
     * Get comma separated mobility string
     *
     * @property mobility
     * @type {Array}
     */
    mobility: Ember.computed('mobilityAreas', 'i18n._locale', function () {
      var mobilityAreaIDs = this.get('mobilityAreas');
      var mobilityI18nAreas = this.get('settingsService').customer.mobilityArea;

      // ce n'est certainement pas la manière la plus rapide de le faire mais le code reste clair :)
      var mobility = mobilityAreaIDs ? mobilityI18nAreas.map(function (category) {
        // not need first level (= category) so flatten to second level (= category.option)
        // => return an array of mobility objects' arrays
        if (category.option && category.option.length) {
          return category.option;
        } else {
          return [];
        }
      }) : [];
      if (mobility.length) {
        return mobility.reduce(function (prev, curr) {
          // flatten array of arrays => return an array of mobility objects
          return prev.concat(curr);
        }).filter(function (mobility) {
          // filter to return only mobility objects with an ID from mobilityAreaIDs
          return mobilityAreaIDs.indexOf(mobility.id) !== -1;
        }).map(function (mobility) {
          return mobility.value !== '' ? mobility.value : mobility.id;
        } /* return only the value */);
      }

      return mobility;
    }),
    provider: Ember.computed('providerContact', 'providerCompany', function () {
      var providerPromises = {
        contact: this.get('providerContact'),
        company: this.get('providerCompany')
      };
      var promise = new Ember.RSVP.hash(providerPromises, "get provider contact and company").then(function (_ref) {
        var contact = _ref.contact,
          company = _ref.company;
        return {
          id: contact ? contact.get('id') : null,
          entityName: contact ? contact.get('entityName') : null,
          // for bm-field-crm. CAUTION: contact could be null
          completeName: contact ? contact.get('completeName') : null,
          // for bm-client. CAUTION: contact could be null
          company: company
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    entityName: Ember.computed('lastName', 'firstName', function () {
      var lastName = this.get('lastName') ? this.get('lastName').toUpperCase() : '';
      var firstName = this.get('firstName');
      if (Ember.isBlank(lastName) && Ember.isBlank(firstName)) {
        return this.get('internalReference');
      } else {
        return "".concat(lastName, " ").concat(firstName);
      }
    }),
    administrativeName: Ember.computed.alias('entityName'),
    completeName: Ember.computed('lastName', 'firstName', function () {
      var lastName = this.get('lastName') ? this.get('lastName').toUpperCase() : '';
      var firstName = this.get('firstName') || '';
      if (Ember.isBlank(lastName) && Ember.isBlank(firstName)) {
        return this.get('internalReference');
      } else {
        return "".concat(firstName, " ").concat(lastName);
      }
    }),
    shortName: Ember.computed('lastName', 'firstName', function () {
      var lastName = this.get('lastName') || '';
      var firstName = this.get('firstName') || '';
      if (Ember.isBlank(lastName) && Ember.isBlank(firstName)) {
        return this.get('internalReference');
      } else {
        return "".concat(firstName[0], ". ").concat(lastName);
      }
    }),
    emailsOptions: Ember.computed('email1', 'email2', 'email3', function () {
      var options = [];
      var emails = [];
      var distinct = function distinct(value, index, self) {
        return self.indexOf(value) === index && value;
      };
      emails.push(this.get('email1'));
      emails.push(this.get('email2'));
      emails.push(this.get('email3'));
      var distinctEmails = emails.filter(distinct);
      distinctEmails.forEach(function (email) {
        options.push({
          id: email,
          value: email
        });
      });
      return options;
    }),
    resumesOptions: Ember.computed('resumes.[]', function () {
      var resumes = [];
      this.get('resumes').toArray().reverse().forEach(function (resume) {
        resumes.push({
          id: resume.get('id'),
          value: resume.get('name')
        });
      });
      return resumes;
    }),
    phoneOptionsForESignature: Ember.computed('id', 'modelName', 'phone1', 'phone2', 'phone3', function () {
      var phones = [];
      if (this.get('phone1')) {
        var phone1 = this.get('phone1').replaceAll(' ', '_');
        phones.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(phone1),
          value: this.get('phone1')
        });
      }
      if (this.get('phone2')) {
        var phone2 = this.get('phone2').replaceAll(' ', '_');
        phones.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(phone2),
          value: this.get('phone2')
        });
      }
      if (this.get('phone3')) {
        var phone3 = this.get('phone3').replaceAll(' ', '_');
        phones.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(phone3),
          value: this.get('phone3')
        });
      }
      return phones;
    }).readOnly(),
    emailOptionsForESignature: Ember.computed('id', 'completeName', 'modelName', 'email1', 'email2', 'email3', function () {
      var emails = [];
      if (this.get('email1')) {
        emails.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(this.get('email1')),
          email: this.get('email1'),
          value: "".concat(this.get('completeName'), " - ").concat(this.get('email1')),
          entityId: "".concat(this.get('modelName'), "_").concat(this.get('id')),
          phoneOptionsForESignature: this.get('phoneOptionsForESignature')
        });
      }
      if (this.get('email2')) {
        emails.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(this.get('email2')),
          email: this.get('email2'),
          value: "".concat(this.get('completeName'), " - ").concat(this.get('email2')),
          entityId: "".concat(this.get('modelName'), "_").concat(this.get('id')),
          phoneOptionsForESignature: this.get('phoneOptionsForESignature')
        });
      }
      if (this.get('email3')) {
        emails.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(this.get('email3')),
          email: this.get('email3'),
          value: "".concat(this.get('completeName'), " - ").concat(this.get('email3')),
          entityId: "".concat(this.get('modelName'), "_").concat(this.get('id')),
          phoneOptionsForESignature: this.get('phoneOptionsForESignature')
        });
      }
      return emails;
    }).readOnly()
  });
});