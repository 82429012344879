define("boondmanager/models/appsaaseditorcustomer", ["exports", "ember-data", "ember-data-model-fragments/attributes", "boondmanager/utils/logger", "ember-copy", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _attributes, _logger, _emberCopy, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _emberData.default.Model.extend({
    products: (0, _attributes.fragmentArray)('appsaaseditorproductfragment'),
    addProduct: function addProduct(product) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var products = this.get('products');
      switch (_typeof(product)) {
        case 'object':
          if ((0, _fragment.isFragment)(product)) {
            products.addFragment(makeAcopy ? (0, _emberCopy.copy)(product) : product);
          } else {
            products.createFragment(product);
          }
          break;
        default:
          products.createFragment();
      }
    },
    delProduct: function delProduct(product) {
      if ((0, _fragment.isFragment)(product)) {
        this.get('products').removeFragment(product);
      } else {
        _logger.default.warn("".concat(this.get('id'), ":delProduct() - rule is not a fragment !"), product);
      }
    }
  });
});