define("boondmanager/models/appemailingabsencesreport", ["exports", "ember-data", "boondmanager/models/absencesreport"], function (_exports, _emberData, _absencesreport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsEMailingAbsencesReport = _absencesreport.default.extend({
    canReadActivity: _emberData.default.attr('boolean'),
    validator: _emberData.default.belongsTo('resource'),
    canReadRecord: Ember.computed.alias('canReadActivity').readOnly(),
    recordRouteName: Ember.computed('', function () {
      return 'private.absencesreports.absencesreport';
    }).readOnly(),
    emailsOptions: Ember.computed('validator.emailsOptions', function () {
      var options = [];
      var emails = [];
      var distinct = function distinct(value, index, self) {
        return self.indexOf(value) === index && value;
      };
      if (this.get('validator')) {
        emails.push(this.get('validator.email1'));
        emails.push(this.get('validator.email2'));
        emails.push(this.get('validator.email3'));
      }
      var distinctEmails = emails.filter(distinct);
      distinctEmails.forEach(function (email) {
        options.push({
          id: email,
          value: email
        });
      });
      return options;
    }).readOnly()
  });
  AppsEMailingAbsencesReport.reopenClass({
    prefix: 'ABS'
  });
  AppsEMailingAbsencesReport.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingAbsencesReport.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingAbsencesReport;
});