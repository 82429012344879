define("boondmanager/models/fieldresponsefragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    value: _emberData.default.attr(),
    field: _emberData.default.attr(),
    valueModel: Ember.computed('value', function () {
      if (Ember.isArray(this.get('value'))) {
        return this.get('value').map(function (item) {
          return item.id;
        });
      } else {
        return this.get('value.id');
      }
    }),
    valueModelLabel: Ember.computed('value', function () {
      if (Ember.isArray(this.get('value'))) {
        return this.get('value').map(function (item) {
          return item.value;
        }).join(', ');
      } else {
        return this.get('value.value');
      }
    })
  });
});