define("boondmanager/models/downloadcenterfolder", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    name: _emberData.default.attr('string'),
    urlVisitor: _emberData.default.attr('string'),
    isLocked: _emberData.default.attr('boolean'),
    isTemporary: _emberData.default.attr('boolean'),
    files: _emberData.default.hasMany('downloadcenterfile'),
    createVisitorAccess: function createVisitorAccess(perimeterManager) {
      var adapter = this.store.adapterFor(this.get('modelName'));
      return adapter.visitorAccess(this, perimeterManager, 'POST');
    },
    deleteVisitorAccess: function deleteVisitorAccess(perimeterManager) {
      var adapter = this.store.adapterFor(this.get('modelName'));
      return adapter.visitorAccess(this, perimeterManager, 'DELETE');
    }
  });
});