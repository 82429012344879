define("boondmanager/pods/components/bm-field/power-select/selecteditem-toollevel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KH1Cgr3W",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"selectedItem\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/selecteditem-toollevel/template.hbs"
  });
});