define("boondmanager/serializers/recipient", ["exports", "boondmanager/serializers/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    // FRONT -> BACK
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      var returnedJson = {};
      Object.keys(json).forEach(function (key) {
        if (Ember.typeOf(json[key]) !== 'null') {
          returnedJson[key] = json[key];
        }
      });
      return returnedJson;
    } /*snapshot, options*/
  });
});