define("boondmanager/models/appsurveycustomer", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    surveyTypes: (0, _attributes.fragmentArray)('appsurveysurveytypefragment')
  });
});