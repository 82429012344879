define("boondmanager/models/evaluation", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    settings: Ember.inject.service(),
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    date: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    manager: _emberData.default.attr(),
    notations: (0, _attributes.fragmentArray)('notation'),
    enabledNotations: Ember.computed('notations', 'i18n._locale', function () {
      var enabledCriterias = this.settings.customer.criteria.filter(function (e) {
        return e.isEnabled;
      });
      enabledCriterias = enabledCriterias.map(function (e) {
        return e.id;
      });
      var results = [];
      this.get('notations').forEach(function (notation) {
        if (enabledCriterias.includes(notation.criteria)) {
          results.push(notation);
        }
      });
      return results;
    })
  });
});