define("boondmanager/models/absencesquestion", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // "id" is special in Ember-data and it can not be used like an attribute in
    // models and fragments
    //id           : DS.attr('string'),

    question: _emberData.default.attr('string'),
    answer: _emberData.default.attr('boolean')
  });
});