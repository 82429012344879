define("boondmanager/models/sectiontemplatefragment", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    col: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    row: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    span: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    showUpdateDate: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    fields: (0, _attributes.fragmentArray)('fieldtemplatefragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    rightsAttribute: Ember.computed('originalID', function () {
      return 'sections/' + this.get('originalID');
    })
  });
});