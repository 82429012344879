define("boondmanager/models/metric", ["exports", "ember-data", "boondmanager/models/basegadget"], function (_exports, _emberData, _basegadget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _basegadget.default.extend({
    value: _emberData.default.attr()
  });
});