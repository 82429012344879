define("boondmanager/models/apphouraccountshouraccountfragment", ["exports", "ember-data-model-fragments", "ember-data", "boondmanager/utils/time", "moment"], function (_exports, _emberDataModelFragments, _emberData, _time, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeBreak = computeBreak;
  _exports.computeBreakMinutes = computeBreakMinutes;
  _exports.computeTotalDay = computeTotalDay;
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    morningStartHour: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    morningEndHour: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    afternoonStartHour: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    afternoonEndHour: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    comment: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    /**
     * Disabled
     *
     * @property disabled
     * @type {boolean}
     * @default false
     */
    disabled: Ember.computed('', {
      get: function get() {
        return false;
      },
      set: function set(_, value) {
        return value;
      }
    }),
    /**
     * Warning code
     *
     * @property warning
     * @type {boolean}
     * @default ''
     */
    warning: Ember.computed('', {
      get: function get() {
        return false;
      },
      set: function set(_, value) {
        return value;
      }
    }),
    formatedDate: Ember.computed('date', function () {
      return this.get('date').format('YYYY-MM-DD');
    }).readOnly(),
    totalDay: Ember.computed('date', 'morningStartHour', 'morningEndHour', 'afternoonStartHour', 'afternoonEndHour', function () {
      return computeTotalDay(this, 'h');
    }).readOnly(),
    break: Ember.computed('date', 'morningEndHour', 'afternoonStartHour', function () {
      return computeBreak(this, 'h');
    }).readOnly(),
    breakMinutes: Ember.computed('date', 'morningEndHour', 'afternoonStartHour', function () {
      return computeBreakMinutes(this);
    }).readOnly()
  });
  /**
   * Calcul du total d'heure sur la journée
   * @param model
   * @param separator
   * @param returnZero
   * @return {string}
   */
  function computeTotalDay(model, separator, returnZero) {
    separator = separator || ':';
    var totalMinutes = 0;

    // get times splitted
    var morningStart = (0, _time.getSplittedTime)(Ember.get(model, 'morningStartHour'));
    var morningEnd = (0, _time.getSplittedTime)(Ember.get(model, 'morningEndHour'));
    var afternoonStart = (0, _time.getSplittedTime)(Ember.get(model, 'afternoonStartHour'));
    var afternoonEnd = (0, _time.getSplittedTime)(Ember.get(model, 'afternoonEndHour'));

    // build times
    if (morningStart && morningEnd) {
      var startMorning = (0, _moment.default)().seconds(0).minutes(morningStart.minutes).hours(morningStart.hours);
      var endMorning = (0, _moment.default)().seconds(0).minutes(morningEnd.minutes).hours(morningEnd.hours);
      totalMinutes += endMorning.diff(startMorning, 'minutes');
    }
    if (afternoonStart && afternoonEnd) {
      var startAfter = (0, _moment.default)().seconds(0).minutes(afternoonStart.minutes).hours(afternoonStart.hours);
      var endAfter = (0, _moment.default)().seconds(0).minutes(afternoonEnd.minutes).hours(afternoonEnd.hours);
      totalMinutes += endAfter.diff(startAfter, 'minutes');
    }
    return (0, _time.convertMinutesToHours)(totalMinutes, separator, returnZero);
  }

  /**
   * Calcul du temps de pause
   * @param model
   * @param separator
   * @return {string}
   */
  function computeBreak(model, separator) {
    separator = separator || ':';
    return (0, _time.convertMinutesToHours)(computeBreakMinutes(model), separator);
  }

  /**
   * Calcul du temps de pause en minutes
   * @param model
   * @return {Number}
   */
  function computeBreakMinutes(model) {
    var totalMinutes = 0;

    // get times splitted
    var morningEnd = (0, _time.getSplittedTime)(Ember.get(model, 'morningEndHour'));
    var afternoonStart = (0, _time.getSplittedTime)(Ember.get(model, 'afternoonStartHour'));

    // build times
    if (morningEnd && afternoonStart) {
      var start = (0, _moment.default)().seconds(0).minutes(morningEnd.minutes).hours(morningEnd.hours);
      var end = (0, _moment.default)().seconds(0).minutes(afternoonStart.minutes).hours(afternoonStart.hours);
      totalMinutes += end.diff(start, 'minutes');
    }
    return totalMinutes;
  }
});