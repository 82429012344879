define("boondmanager/models/plannedtimeproject", ["exports", "boondmanager/utils/logger", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _logger, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    row: _emberData.default.attr('number'),
    startDate: _emberData.default.attr('date'),
    batch: _emberData.default.attr({
      defaultValue: null
    }),
    delivery: _emberData.default.attr({
      defaultValue: null
    }),
    opportunity: _emberData.default.attr({
      defaultValue: null
    }),
    resource: _emberData.default.attr({
      defaultValue: null
    }),
    workUnitType: (0, _attributes.fragment)('workunittype'),
    workUnitRate: _emberData.default.attr('number'),
    value: Ember.computed('duration', 'workUnitRate', {
      get: function get() {
        return this.get('duration') * this.get('workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model regulartime - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('duration', value / this.get('workUnitRate'));
        }
        return value;
      }
    })
  });
});