define("boondmanager/models/quotation", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable"], function (_exports, _base, _emberData, _attributes, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_REFUSED = _exports.STATE_CREATION = _exports.STATE_AGREEMENT_RECEIVED = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var STATE_CREATION = _exports.STATE_CREATION = 0;
  var STATE_REFUSED = _exports.STATE_REFUSED = 7;
  var STATE_AGREEMENT_RECEIVED = _exports.STATE_AGREEMENT_RECEIVED = 8;
  var Quotation = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['opportunity', 'mainManager', 'billingDetail', 'contact', 'company']
    }),
    // Attributes
    date: _emberData.default.attr('date'),
    validationDate: _emberData.default.attr('date'),
    number: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    state: _emberData.default.attr('number'),
    language: _emberData.default.attr('string'),
    paymentTerm: _emberData.default.attr('number'),
    informationComments: _emberData.default.attr('string'),
    turnoverInvoicedExcludingTax: _emberData.default.attr('number'),
    turnoverInvoicedIncludingTax: _emberData.default.attr('number'),
    legals: _emberData.default.attr('string'),
    discountRate: _emberData.default.attr('number'),
    showCompanyVATNumberOnPDF: _emberData.default.attr('boolean'),
    showCompanyRegistrationNumberOnPDF: _emberData.default.attr('boolean'),
    showCompanyNumberOnPDF: _emberData.default.attr('boolean'),
    showCommentsOnPDF: _emberData.default.attr('boolean'),
    showFooterOnPDF: _emberData.default.attr('boolean'),
    showOpportunityReferenceOnPDF: _emberData.default.attr('boolean'),
    currency: _emberData.default.attr('number'),
    // TAB_AO.AO_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_AO.AO_DEVISEAGENCE
    exchangeRate: _emberData.default.attr('number'),
    // TAB_AO.AO_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_AO.AO_CHANGEAGENCE

    schedules: (0, _attributes.fragmentArray)('quotationschedule', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    quotationRecords: (0, _attributes.fragmentArray)('quotationrecord'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    opportunity: _emberData.default.belongsTo({
      inverse: null
    }),
    contact: _emberData.default.belongsTo({
      inverse: null
    }),
    company: _emberData.default.belongsTo({
      inverse: null
    }),
    mainManager: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    billingDetail: _emberData.default.belongsTo('detail', {
      inverse: null
    }),
    canShowContact: _emberData.default.attr('boolean'),
    canShowCompany: _emberData.default.attr('boolean'),
    canReadQuotation: _emberData.default.attr('boolean'),
    entityName: Ember.computed('reference', function () {
      var reference = this.get('reference');
      if (Ember.isBlank(reference)) {
        return this.get('internalReference');
      } else {
        return reference;
      }
    }),
    provider: Ember.computed('contact', 'company', function () {
      var providerPromises = {
        contact: this.get('contact'),
        company: this.get('company')
      };
      var promise = new Ember.RSVP.hash(providerPromises, "get provider contact and company").then(function (_ref) {
        var contact = _ref.contact,
          company = _ref.company;
        return {
          id: contact ? contact.get('id') : null,
          entityName: contact ? contact.get('entityName') : null,
          // for bm-field-crm. CAUTION: contact could be null
          completeName: contact ? contact.get('completeName') : null,
          // for bm-client. CAUTION: contact could be null
          company: company
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly()
  });
  Quotation.reopenClass({
    prefix: 'QUOT'
  });
  Quotation.reopen({
    prefix: Ember.computed(function () {
      return Quotation.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Quotation;
});