define("boondmanager/models/delivery", ["exports", "boondmanager/models/basedelivery", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable", "ember-inflector", "boondmanager/utils/logger", "ember-data-model-fragments/fragment", "ember-copy", "moment", "boondmanager/models/additionalturnoverandcostsfragment"], function (_exports, _basedelivery, _emberData, _attributes, _emberDataCopyable, _emberInflector, _logger, _fragment, _emberCopy, _moment, _additionalturnoverandcostsfragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_SIGNED = _exports.STATE_FORECAST = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var STATE_SIGNED = _exports.STATE_SIGNED = 0;
  var STATE_FORECAST = _exports.STATE_FORECAST = 1;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var Delivery = _basedelivery.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['contract', 'project']
    }),
    // ATTRIBUTES
    /**
     * advantages array
     *
     * CAUTION : we cannot use fragment because 'advantage' model already exists.
     *
     * advantages[].reference          : number,
     * advantages[].name               : string,
     * advantages[].frequency          : string ("punctual" || "daily" || "monthly" || "quarterly" || "semiAnnual" || "annual"),
     * advantages[].category           : string ("fixedAmount" || "variableSalaryBasis" || "package" || "loan"),
     * advantages[].participationQuota : number,
     * advantages[].agencyQuota        : number,
     * advantages[].employeeQuota      : number,
     * advantages[].agency             : object
     *
     * advantages[].agency.id             : string,
     * advantages[].agency.name           : string,
     * advantages[].agency.advantageTypes : array of objects
     *
     * advantages[].agency.advantageTypes[].reference          : number,
     * advantages[].agency.advantageTypes[].name               : string,
     * advantages[].agency.advantageTypes[].frequency          : string ("punctual" || "daily" || "monthly" || "quarterly" || "semiAnnual" || "annual"),
     * advantages[].agency.advantageTypes[].category           : string ("fixedAmount" || "variableSalaryBasis" || "package" || "loan"),
     * advantages[].agency.advantageTypes[].participationQuota : number,
     * advantages[].agency.advantageTypes[].agencyQuota        : number,
     * advantages[].agency.advantageTypes[].employeeQuota      : number
     *
     * @type {Array}
     */
    advantages: _emberData.default.attr(),
    averageDailyContractCost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    averageDailyCost: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    forceAverageDailyPriceExcludingTax: _emberData.default.attr('boolean'),
    averageDailyPriceExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    averageHourlyPriceExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    forceAverageHourlyPriceExcludingTax: _emberData.default.attr('boolean'),
    conditions: _emberData.default.attr('string'),
    costsProductionExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    costsSimulatedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    dailyExpenses: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    endDate: _emberData.default.attr('date'),
    exceptionalCalendarsProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    exceptionalTimesProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    expensesProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    expensesSimulated: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    informationComments: _emberData.default.attr('string'),
    marginSimulatedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    monthlyExpenses: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfCorrelatedOrders: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfDaysFree: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfDaysInvoicedOrQuantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfWorkingDays: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    occupationRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    profitabilitySimulated: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    regularTimesProduction: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    regularTimesProductionInWorkUnits: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    regularTimesSimulated: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    additionalTurnoverExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    additionalCostsExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    startDate: _emberData.default.attr('date'),
    state: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    turnoverProductionExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    turnoverSimulatedExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    weeklyWorkingHours: _emberData.default.attr('number'),
    canReadDelivery: _emberData.default.attr('boolean'),
    canWriteDelivery: _emberData.default.attr('boolean'),
    canShowAdvantageTypes: _emberData.default.attr('boolean'),
    canShowDependsOnAgencyAdvantageTypes: _emberData.default.attr('boolean'),
    canShowNumberOfCorrelatedOrders: _emberData.default.attr('boolean'),
    canShowExpensesSimulated: _emberData.default.attr('boolean'),
    canShowExpensesProduction: _emberData.default.attr('boolean'),
    canShowTurnoverProductionExcludingTax: _emberData.default.attr('boolean'),
    canShowCostsProductionExcludingTax: _emberData.default.attr('boolean'),
    canShowTurnoverSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowCostsSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowProfitabilitySimulated: _emberData.default.attr('boolean'),
    canShowMarginSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowAverageDailyPriceExcludingTax: _emberData.default.attr('boolean'),
    creationDate: _emberData.default.attr('moment'),
    // TAB_MISSIONPROJET.MP_CREATEDAT
    updateDate: _emberData.default.attr('moment'),
    typeOf: _emberData.default.attr('number'),
    // FRAGMENTS
    exceptionalScales: (0, _attributes.fragmentArray)('exceptionalscale', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    advantageTypes: (0, _attributes.fragmentArray)('advantagetype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    expensesDetails: (0, _attributes.fragmentArray)('expensedetail', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    additionalTurnoverAndCosts: (0, _attributes.fragmentArray)('additionalturnoverandcostsfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // RELATIONSHIPS
    contract: _emberData.default.belongsTo(),
    /**
     * dependsOn
     * @type {Resource|Product}
     */
    dependsOn: _emberData.default.belongsTo('base'),
    files: _emberData.default.hasMany('file'),
    groupment: _emberData.default.belongsTo('groupment'),
    master: _emberData.default.belongsTo('delivery', {
      inverse: 'slave'
    }),
    project: _emberData.default.belongsTo({
      inverse: null
    }),
    // deliveries ou deliveryRecruitment
    purchase: _emberData.default.belongsTo('purchase', {
      inverse: 'delivery'
    }),
    slave: _emberData.default.belongsTo('delivery', {
      inverse: 'master'
    }),
    order: _emberData.default.belongsTo('order'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    currency: Ember.computed.alias('project.currency'),
    currencyAgency: Ember.computed.alias('project.currencyAgency'),
    exchangeRate: Ember.computed.alias('project.exchangeRate'),
    exchangeRateAgency: Ember.computed.alias('project.exchangeRateAgency'),
    agency: Ember.computed.alias('project.agency'),
    resourceAgency: Ember.computed.alias('dependsOn.agency'),
    company: Ember.computed.alias('project.company'),
    mainManager: Ember.computed.alias('project.mainManager'),
    averageWorkUnitPriceExcludingTax: Ember.computed('averageDailyPriceExcludingTax', 'project.workUnitRate', {
      get: function get() {
        return this.get('project.workUnitRate') ? this.get('averageDailyPriceExcludingTax') / this.get('project.workUnitRate') : this.get('averageDailyPriceExcludingTax');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model delivery - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('averageDailyPriceExcludingTax', value * this.get('project.workUnitRate'));
        }
        return value;
      }
    }),
    numberOfWorkUnitsInvoicedOrQuantity: Ember.computed('numberOfDaysInvoicedOrQuantity', 'project.workUnitRate', {
      get: function get() {
        return this.get('numberOfDaysInvoicedOrQuantity') * this.get('project.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model delivery - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('numberOfDaysInvoicedOrQuantity', this.get('project.workUnitRate') ? value / this.get('project.workUnitRate') : value);
        }
        return value;
      }
    }),
    numberOfWorkUnitsFree: Ember.computed('numberOfDaysFree', 'project.workUnitRate', {
      get: function get() {
        return this.get('numberOfDaysFree') * this.get('project.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model delivery - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('numberOfDaysFree', this.get('project.workUnitRate') ? value / this.get('project.workUnitRate') : value);
        }
        return value;
      }
    }),
    regularTimesSimulatedInWorkUnits: Ember.computed('regularTimesSimulated', 'project.workUnitRate', function () {
      return this.get('regularTimesSimulated') * this.get('project.workUnitRate');
    }),
    lengthInDays: Ember.computed('startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate') || (0, _moment.default)();
      return _moment.default.duration(endDate.diff(startDate)).asDays();
    }),
    isProfitabilityCalculatingBasedOnMarginRate: Ember.computed(function () {
      var profitability = this.get('settingsService').customer.profitabilityMethodOfCalculating;
      return profitability === 'marginRate';
    }).readOnly(),
    averageDailyCostCalculated: Ember.computed('dependsOn', 'contract.{isFulfilled,id}', 'averageDailyContractCost', 'advantagesDailyCost', 'dailyExpensesCalculated', 'monthlyExpensesCalculated', 'numberOfWorkingDays', function () {
      if (this.get('dependsOnProduct') || !this.get('contract.isFulfilled')) return 0;
      var contractCost = this.get('averageDailyContractCost');
      var advantagesDailyCost = this.get('advantagesDailyCost');
      var dailyExpensesCalculated = this.get('dailyExpensesCalculated');
      var monthlyExpensesCalculated = this.get('monthlyExpensesCalculated');
      var numberOfWorkingDays = this.get('numberOfWorkingDays');
      var baseSum = contractCost + dailyExpensesCalculated + monthlyExpensesCalculated * 12 / numberOfWorkingDays;
      return this.get('contract.id') ? baseSum + advantagesDailyCost : baseSum;
    }),
    additionalTurnoverTotal: Ember.computed('additionalTurnoverAndCosts.@each.turnoverExcludingTax', function () {
      return this.get('additionalTurnoverAndCosts').reduce(function (total, item) {
        return total + item.get('turnoverExcludingTax');
      }, 0);
    }),
    additionalCostsTotal: Ember.computed('additionalTurnoverAndCosts.@each.costsExcludingTax', function () {
      return this.get('additionalTurnoverAndCosts').reduce(function (total, item) {
        return total + item.get('costsExcludingTax');
      }, 0);
    }),
    turnoverSimulatedExcludingTaxCalculated: Ember.computed('averageDailyPriceExcludingTax', 'numberOfDaysInvoicedOrQuantity', 'additionalTurnoverTotal', function () {
      return this.get('averageDailyPriceExcludingTax') * this.get('numberOfDaysInvoicedOrQuantity') + this.get('additionalTurnoverTotal');
    }),
    costsSimulatedExcludingTaxCalculated: Ember.computed('averageDailyCostCalculated', 'numberOfDaysInvoicedOrQuantity', 'numberOfDaysFree', 'additionalCostsTotal', 'dailyExpensesCalculated', 'monthlyExpensesCalculated', 'numberOfWorkingDays', function () {
      var averageDailyCost = this.get('averageDailyCostCalculated');
      var numberOfDaysInvoicedOrQuantity = this.get('numberOfDaysInvoicedOrQuantity');
      var numberOfDaysFree = this.get('numberOfDaysFree');
      var additionalCostsTotal = this.get('additionalCostsTotal');
      return averageDailyCost * (numberOfDaysInvoicedOrQuantity + numberOfDaysFree) + additionalCostsTotal;
    }),
    marginSimulatedExcludingTaxCalculated: Ember.computed('turnoverSimulatedExcludingTaxCalculated', 'costsSimulatedExcludingTaxCalculated', function () {
      return this.get('turnoverSimulatedExcludingTaxCalculated') - this.get('costsSimulatedExcludingTaxCalculated');
    }),
    profitabilitySimulatedCalculated: Ember.computed('isProfitabilityCalculatingBasedOnMarginRate', 'marginSimulatedExcludingTaxCalculated', 'turnoverSimulatedExcludingTaxCalculated', 'costsSimulatedExcludingTaxCalculated', function () {
      var value;
      var margin = this.get('marginSimulatedExcludingTaxCalculated');
      if (this.get('isProfitabilityCalculatingBasedOnMarginRate')) {
        var cost = this.get('costsSimulatedExcludingTaxCalculated');
        value = Math.round(100000 * cost) ? Math.round(10000 * margin / cost) / 100 : 0;
      } else {
        var turnover = this.get('turnoverSimulatedExcludingTaxCalculated');
        value = Math.round(100000 * turnover) ? Math.round(10000 * (margin / turnover)) / 100 : 0;
      }
      return value;
    }),
    isSigned: Ember.computed('state', function () {
      return this.get('state') === 0;
    }),
    isForecast: Ember.computed('state', function () {
      return this.get('state') === 1;
    }),
    reportingClass: Ember.computed('state', function () {
      var _this = this;
      var internalProjects = this.get('settingsService').customer.typeOf.project.filterBy('isInternal', true);
      var promise = this.get('project').then(function (project) {
        var foundProjectTypeOf = internalProjects.filterBy('id', project.get('typeOf'));
        var klass = '';
        if (foundProjectTypeOf.length) {
          klass = _this.get('state') === 1 ? 'forecastDelivery' : 'internal';
        } else {
          klass = _this.get('state') === 1 ? 'forecastDelivery' : 'signedDelivery';
        }
        return klass;
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    reportingTooltip: Ember.computed('project', 'startDate', 'endDate', 'occupationRate', function () {
      var i18n = this.get('i18n');
      var project = this.get('project');
      var projectType = this.get('settingsService').customer.typeOf.project.find(function (item) {
        return item.id === project.get('typeOf');
      }).value;
      var opportunity = project.get('opportunity');
      var startLabel = i18n.t('models:delivery.attributes.startDate.name');
      var endLabel = i18n.t('models:delivery.attributes.endDate.abbr');
      var occupationRate = i18n.t('models:delivery.attributes.occupationRate.name');
      var customer = i18n.t('models:project.attributes.customer.abbr');
      return "\n\t\t\t<div class=\"titleTooltip\">\n\t\t\t\t<h5>".concat(project.get('reference'), "</h5>\n\t\t\t\t<div class=\"bm-text-secondary\">").concat(projectType, "</div>\n\t  \t\t</div>\n\t\t\t").concat(opportunity.get('id') ? "<span>&nbsp;-&nbsp;".concat(opportunity.get('title'), "</span>") : '', "\n\t\t\t<dl class=\"infoTooltip\">\n\t\t\t\t<dt>").concat(startLabel, " - ").concat(endLabel, " </dt>\n\t\t\t\t<dd>").concat(this.get('startDate').format('DD/MM/YY'), " - ").concat(this.get('endDate').format('DD/MM/YY'), "</dd>\n\t\t\t\t<dt>").concat(occupationRate, "</dt><dd>").concat(Math.round(this.get('occupationRate')), "%</dd>\n\t\t\t\t<dt>").concat(i18n.t('models:project.attributes.mainManager.abbr'), "</dt>\n\t\t\t\t<dd>").concat(project.get('mainManager.entityName'), "</dd>\n\t\t\t\t<dt>").concat(customer, "</dt>\n\t\t\t\t<dd>\n\t\t\t\t\t").concat(project.get('contact.entityName'), "<br>\n\t\t\t\t\t").concat(project.get('company.entityName'), "\n\t\t\t\t</dd>\n\t\t\t</dl>\n\t\t");
    }),
    dependsOnProduct: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'product';
    }),
    dependsOnResource: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'resource';
    }),
    dependsOnProfile: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'resource' || this.get('dependsOn.modelName') === 'candidate';
    }),
    dependsOnPlural: Ember.computed('dependsOn', function () {
      return (0, _emberInflector.pluralize)(this.get('dependsOn.modelName'));
    }),
    hasSlave: Ember.computed('slave.isFulfilled', function () {
      return this.get('slave.isFulfilled') && this.get('slave.id');
    }),
    dependsOnLinkTo: Ember.computed('dependsOn', function () {
      return "private.".concat(this.get('dependsOnPlural'), ".").concat(this.get('dependsOn.modelName'));
    }),
    dailyExpensesCalculated: Ember.computed('expensesDetails.[]', 'dailyExpenses', 'expensesDetails.@each.{periodicity,netAmount}', function () {
      if (this.get('expensesDetails') && this.get('expensesDetails').length) {
        return this.get('expensesDetails').reduce(function (daily, expenseDetail) {
          return expenseDetail.periodicity === 'daily' ? daily + parseFloat(expenseDetail.netAmount) : daily;
        }, 0);
      } else {
        return this.get('dailyExpenses');
      }
    }),
    monthlyExpensesCalculated: Ember.computed('expensesDetails.[]', 'monthlyExpenses', 'expensesDetails.@each.{periodicity,netAmount}', function () {
      if (this.get('expensesDetails') && this.get('expensesDetails').length) {
        return this.get('expensesDetails').reduce(function (monthly, expenseDetail) {
          return expenseDetail.periodicity === 'monthly' ? monthly + parseFloat(expenseDetail.netAmount) : monthly;
        }, 0);
      } else {
        return this.get('monthlyExpenses');
      }
    }),
    entityName: Ember.computed('title', function () {
      var title = this.get('title');
      if (Ember.isBlank(title)) {
        return this.get('internalReference');
      } else {
        return title;
      }
    }),
    fullEntityName: Ember.computed('title', 'internalReference', function () {
      var title = this.get('title');
      if (Ember.isBlank(title)) {
        return this.get('internalReference');
      } else {
        return this.get('internalReference') + ' - ' + this.get('title');
      }
    }),
    periodsLabel: Ember.computed('dependsOn.isFulfilled', 'subscriptionPeriods', 'i18n._locale', function () {
      var _this2 = this;
      var promise = this.get('dependsOn').then(function (product) {
        var subscription = product.get('subscription');
        var subscriptionPeriods = _this2.get('subscriptionPeriods.value');
        var i18n = _this2.get('i18n');
        var result;
        switch (subscription) {
          case 0:
            {
              // Unitaire
              result = '';
              break;
            }
          case 1:
            {
              // Mensuel
              result = i18n.t('common:periodsLabel.month', {
                count: subscriptionPeriods
              });
              break;
            }
          case 2:
            {
              // Trimestriel
              result = i18n.t('common:periodsLabel.quarter', {
                count: subscriptionPeriods
              });
              break;
            }
          case 3:
            {
              // Semestriel
              result = i18n.t('common:periodsLabel.semester', {
                count: subscriptionPeriods
              });
              break;
            }
          case 4:
            {
              // Annuel
              result = i18n.t('common:periodsLabel.year', {
                count: subscriptionPeriods
              });
              break;
            }
        }
        return {
          value: result
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    subscriptionPeriods: Ember.computed('dependsOn.isFulfilled', 'startDate', 'endDate', function () {
      var _this3 = this;
      var promise = this.get('dependsOn').then(function (product) {
        var subscription = product.get('subscription');
        var startDate = _this3.get('startDate');
        var endDate = _this3.get('endDate');
        var monthes = 0;
        if (endDate && startDate && endDate.isAfter(startDate)) {
          monthes = endDate.month() - startDate.month() + 1 + 12 * (endDate.year() - startDate.year());
        }
        var result;
        switch (subscription) {
          case 0:
            // Unitaire
            result = 0;
            break;
          case 1:
            // Mensuel
            result = monthes;
            break;
          case 2:
            // Trimestriel
            result = Math.ceil(monthes / 3);
            break;
          case 3:
            // Semestriel
            result = Math.ceil(monthes / 6);
            break;
          case 4:
            // Annuel
            result = Math.ceil(monthes / 12);
            break;
        }
        return {
          value: result
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    advantagesDailyCost: Ember.computed('dependsOn.isFulfilled', 'contract.isFulfilled', 'numberOfWorkingDays', 'advantageTypes.[]', 'advantageTypes.@each.{agencyQuota,agencyAmount,category,employeeQuota,employeeAmount,frequency,participationQuota}', function () {
      var _this4 = this;
      if (!this.get('dependsOn.isFulfilled')) return 0;
      if (!this.get('contract.isFulfilled')) return 0;
      var dependsOn = this.get('dependsOn');
      var contract = this.get('contract');

      /*
       * Advantage daily cost
       */
      var advantageAverageDailyCost = 0;
      this.get('advantageTypes').forEach(function (advantage) {
        var cost = 0;
        switch (advantage.get('category')) {
          case 'fixedAmount':
            cost = advantage.get('employeeQuota') * advantage.get('agencyQuota');
            break;
          case 'variableSalaryBasis':
            cost = dependsOn.get('isExternal') ? 0 : contract.get('monthlySalary') * (advantage.get('agencyQuota') + advantage.get('employeeQuota') - advantage.get('participationQuota')) / 100;
            break;
          case 'package':
            cost = advantage.get('employeeQuota') + advantage.get('agencyQuota') - advantage.get('participationQuota');
            break;
          default:
            break;
        }
        var numberOfWorkingDays = _this4.get('numberOfWorkingDays');
        switch (advantage.get('frequency')) {
          case 'daily':
            advantageAverageDailyCost += cost;
            break;
          case 'monthly':
            advantageAverageDailyCost += numberOfWorkingDays ? cost * 12 / numberOfWorkingDays : 0;
            break;
          case 'quarterly':
            advantageAverageDailyCost += numberOfWorkingDays ? cost * 4 / numberOfWorkingDays : 0;
            break;
          case 'semiAnnual':
            advantageAverageDailyCost += numberOfWorkingDays ? cost * 2 / numberOfWorkingDays : 0;
            break;
          case 'annual':
            advantageAverageDailyCost += numberOfWorkingDays ? cost / numberOfWorkingDays : 0;
            break;
        }
      });
      return advantageAverageDailyCost;
    }),
    // METHODS
    // - Manage additionalTurnoverAndCosts fragments
    addTurnoverAndCosts: function addTurnoverAndCosts(turnoverAndCosts) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var additionalTurnoverAndCosts = this.get('additionalTurnoverAndCosts');
      switch (_typeof(turnoverAndCosts)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(turnoverAndCosts)) {
              additionalTurnoverAndCosts.addFragment(makeAcopy ? (0, _emberCopy.copy)(turnoverAndCosts) : turnoverAndCosts);
            } else {
              additionalTurnoverAndCosts.createFragment(turnoverAndCosts);
            }
            break;
          }
        default:
          {
            // get last additional turnover and cost typeof if exists
            var defaultTypeOf = _additionalturnoverandcostsfragment.INDIFFERENT_TYPEOF_ADDITIONAL_TURNOVER;
            if (additionalTurnoverAndCosts && additionalTurnoverAndCosts.lastObject) {
              defaultTypeOf = additionalTurnoverAndCosts.lastObject.typeOf;
            }
            additionalTurnoverAndCosts.createFragment({
              typeOf: defaultTypeOf
            });
          }
      }
    },
    addNumberOfDays: Ember.computed('numberOfDaysInvoicedOrQuantity', 'numberOfDaysFree', function () {
      return this.get('numberOfDaysInvoicedOrQuantity') + this.get('numberOfDaysFree');
    }),
    addNumberOfWorkUnits: Ember.computed('numberOfWorkUnitsInvoicedOrQuantity', 'numberOfWorkUnitsFree', function () {
      return this.get('numberOfWorkUnitsInvoicedOrQuantity') + this.get('numberOfWorkUnitsFree');
    }),
    delTurnoverAndCosts: function delTurnoverAndCosts(turnoverAndCosts) {
      if ((0, _fragment.isFragment)(turnoverAndCosts)) {
        this.get('additionalTurnoverAndCosts').removeFragment(turnoverAndCosts);
      } else {
        _logger.default.warn("Project#".concat(this.get('id'), ":delTurnoverAndCosts() - turnoverAndCosts is not a fragment !"), turnoverAndCosts);
      }
    },
    roundedExpensesProduction: Ember.computed('expensesProduction', function () {
      return Math.round(this.get('expensesProduction') * 100) / 100;
    }),
    periodFromLabel: Ember.computed('mode', 'project', 'dependsOn.subscription', function () {
      return this.get('project.isProduct') && this.get('dependsOn.subscription') === 0 ? 'unit' : 'period';
    }),
    calculatedAverageHourlyPriceExcludingTax: Ember.computed('forceAverageHourlyPriceExcludingTax', 'averageDailyPriceExcludingTax', 'weeklyWorkingHours', 'averageHourlyPriceExcludingTax', function () {
      if (!this.get('forceAverageHourlyPriceExcludingTax')) {
        return this.get('weeklyWorkingHours') ? 5 * this.get('averageDailyPriceExcludingTax') / this.get('weeklyWorkingHours') : 0;
      }
      return this.get('averageHourlyPriceExcludingTax');
    })
  });
  Delivery.reopenClass({
    prefix: 'MIS'
  });
  Delivery.reopen({
    prefix: Ember.computed(function () {
      return Delivery.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Delivery;
});