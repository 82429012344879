define("boondmanager/models/childtaskfragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ChildTaskFragment = void 0;
  var ChildTaskFragment = _exports.ChildTaskFragment = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    edition: false
  });
  var _default = _exports.default = ChildTaskFragment;
});