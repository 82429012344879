define("boondmanager/models/alertvalue", ["exports", "ember-data", "boondmanager/models/contract", "boondmanager/models/alert"], function (_exports, _emberData, _contract, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    /*** SERVICES ***/
    settingsService: Ember.inject.service('settings'),
    /*** PROPERTIES ***/
    indicator: _emberData.default.attr(),
    canReadDependsOn: _emberData.default.attr('boolean'),
    dependsOn: _emberData.default.belongsTo('base'),
    /*** COMPUTED ***/
    dependsOnLabel: Ember.computed('id', 'indicator', 'canReadDependsOn', 'dependsOn.{isFulfilled,category,isContactAction,isInvoiceAction,isOpportunityAction,isOrderAction,isProjectAction,company.entityName,dependsOn.order.project.contact.completeName,dependsOn.order.project.company.entityName,dependsOn.contact.completeName,dependsOn.company.entityName,company.name,contact.completeName,dependsOnLabel,dependsOn.completeName,completeName,opportunity.title,opportunity.contact.completeName,opportunity.company.name,typeOf,startDate,enDate,term,resource.completeName,creationDate,probationState,probationEndDate,renewalProbationEndDate,description,internalReference,expirationDate,typeOfValue,endDateValue,title,reference,project.reference,project.contact.completeName,project.company.name,getReference,order.project.reference,order.number,order.project.company.name,number}', 'i18n._locale', function () {
      var _this = this;
      if (!this.get('dependsOn.isFulfilled') || !this.get('canReadDependsOn')) return '';
      var i18n = this.get('i18n');
      switch (this.get('indicator')) {
        case _alert.INDICATOR_ACTIONSUPCOMING:
        case _alert.INDICATOR_ACTIONSOFTHEDAY:
        case _alert.INDICATOR_ACTIONSLATE:
          {
            // dans le cas où l'on supprimerait une action depuis sa modale alors que l'utilisateur est sur la liste
            // des notifications depuis le dashboard, le modèle étant supprimé du store mais la page n'étant pas encore
            // rafraichit, cette computed sort une erreur de dictionnaire car ses propriétés sont vides et le label
            // ne peut être construit
            if (this.get('dependsOn.category')) {
              var additional = '';
              if (this.get('dependsOn.isContactAction')) {
                additional += Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.company.entityName')) ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.company.entityName')) : '';
              }
              if (this.get('dependsOn.isInvoiceAction')) {
                if (this.get('dependsOn.dependsOn.order.project.contact')) {
                  additional += ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.order.project.contact.completeName'));
                }
                if (this.get('dependsOn.dependsOn.order.project.company')) {
                  additional += ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.order.project.company.entityName'));
                }
              }
              if (this.get('dependsOn.isOrderAction')) {
                if (this.get('dependsOn.dependsOn.project.contact')) {
                  additional += ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.project.contact.completeName'));
                }
                if (this.get('dependsOn.dependsOn.project.company')) {
                  additional += ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.project.company.entityName'));
                }
              }
              if (this.get('dependsOn.isOpportunityAction') || this.get('dependsOn.isProjectAction')) {
                if (this.get('dependsOn.dependsOn.contact')) {
                  additional += ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.contact.completeName'));
                }
                if (this.get('dependsOn.dependsOn.company')) {
                  additional += ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.company.entityName'));
                }
              }
              return this.get('settingsService').customer.action[this.get('dependsOn.category')].find(function (item) {
                return item.id === _this.get('dependsOn.typeOf');
              }).value + ' - ' + this.get('dependsOn.dependsOnLabel') + ' - ' + this.get('dependsOn.dependsOn.entityName') + additional + ' - ' + this.get('dependsOn.startDate').format('DD/MM/YY') + ' ' + i18n.t('common:at') + ' ' + this.get('dependsOn.startDate').format('HH:mm');
            }
            return '';
          }
        case _alert.INDICATOR_TIMESREPORTSWITHNOVALIDATION:
        case _alert.INDICATOR_EXPENSESREPORTSWITHNOVALIDATION:
          return Ember.String.capitalize(this.get('dependsOn.term').format('MMMM YYYY')) + ' - ' + this.get('dependsOn.resource.completeName');
        case _alert.INDICATOR_ABSENCESREPORTSWITHNOVALIDATION:
          return this.get('dependsOn.creationDate').format('DD MMMM YYYY') + ' - ' + this.get('dependsOn.resource.completeName');
        case _alert.INDICATOR_CANDIDATESWITHNOACTION:
        case _alert.INDICATOR_CANDIDATESNOTUPDATED:
          return Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.completeName'));
        case _alert.INDICATOR_RESOURCESWITHNOACTION:
        case _alert.INDICATOR_RESOURCESWITHNOCONTRACTS:
        case _alert.INDICATOR_RESOURCESWITHNOMORECONTRACTS:
          return Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.completeName'));
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATEUPCOMING:
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATETODAY:
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATETERMINATED:
          {
            var label = Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.completeName'));
            var titleContract = this.get('settingsService').customer.typeOf.contract.find(function (contractOption) {
              return contractOption.id === _this.get('dependsOn.typeOf');
            });
            if (titleContract) {
              label += " - ".concat(titleContract.value);
            }
            if (this.get('dependsOn.probationState') === _contract.PROBATION_IN_PROGRESS && this.get('dependsOn.probationEndDate')) {
              var peLabel = i18n.t('models:contract.attributes.probationEndDate.abbr');
              label += " - ".concat(peLabel, ": ").concat(this.get('dependsOn.probationEndDate').format('L'));
            }
            if (this.get('dependsOn.probationState') === _contract.RENEWAL_PROBATION_IN_PROGRESS && this.get('dependsOn.renewalProbationEndDate')) {
              var _peLabel = i18n.t('models:contract.attributes.renewalProbationEndDate.abbr');
              label += " - ".concat(_peLabel, ": ").concat(this.get('dependsOn.renewalProbationEndDate').format('L'));
            }
            return label;
          }
        case _alert.INDICATOR_RESOURCESARCHIVEDWITHACTIVATEDINTRANET:
          return Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.completeName'));
        case _alert.INDICATOR_RESOURCESWITHFOLLOWEDDOCUMENTS:
          {
            var followedDocumentsTypes = this.get('settingsService').customer.typeOf.followedDocument;
            var followedDocumentType = null;
            if (typeof followedDocumentsTypes !== 'undefined') {
              followedDocumentType = followedDocumentsTypes.find(function (followedDocumentType) {
                return followedDocumentType.id === _this.get('dependsOn.typeOf');
              });
            }
            var _label = Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.completeName'));
            if (followedDocumentType) {
              _label += " - ".concat(followedDocumentType.value);
              if (this.get('dependsOn.description')) {
                var finalString = this.get('dependsOn.description');
                if (this.get('dependsOn.description').length > 50) {
                  var trimmedString = this.get('dependsOn.description').substr(0, 50);
                  var lastSpace = trimmedString.lastIndexOf(' ');
                  finalString = lastSpace === -1 ? trimmedString : trimmedString.substr(0, lastSpace);
                  finalString += i18n.t('common:ellipsis');
                }
                _label += ' (' + Ember.Handlebars.Utils.escapeExpression(finalString) + ')';
              }
            }
            if (this.get('dependsOn.internalReference')) {
              _label += ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.internalReference'));
            }
            if (this.get('dependsOn.expirationDate')) {
              _label += ' - ' + this.get('dependsOn.expirationDate').format('DD/MM/YYYY');
            }
            return _label;
          }
        case _alert.INDICATOR_RESOURCESWITHOVERLAPPEDCONTRACTS:
        case _alert.INDICATOR_RESOURCESWITHNOCONTRACTENDDATE:
        case _alert.INDICATOR_CONTRACTSENDEDUPCOMING:
        case _alert.INDICATOR_CONTRACTSENDEDTODAY:
        case _alert.INDICATOR_CONTRACTSENDED:
          return '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.internalReference')) + '/<strong>' + ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.typeOfValue')) + ' - ' + this.get('dependsOn.startDate').format('L') + ' - ' + this.get('dependsOn.endDateValue') + ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.completeName'));
        case _alert.INDICATOR_OPPORTUNITIESWITHNOACTION:
          return '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.title')) + '</strong>' + (this.get('dependsOn.contact.completeName') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.contact.completeName')) : '') + (this.get('dependsOn.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.company.name')) : '');
        case _alert.INDICATOR_POSITIONINGSNOTUPDATED:
          return Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOn.completeName')) + ' - ' + '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.opportunity.title')) + '</strong>' + (this.get('dependsOn.opportunity.contact.completeName') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.opportunity.contact.completeName')) : '') + (this.get('dependsOn.opportunity.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.opportunity.company.name')) : '');
        case _alert.INDICATOR_PROJECTSWITHNOACTION:
        case _alert.INDICATOR_PROJECTSWITHNOORDERNOTARCHIVED:
        case _alert.INDICATOR_PROJECTSWITHSIGNEDTURNOVERGREATERTHANORDEREDTURNOVER:
          return '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.reference')) + '</strong>' + (this.get('dependsOn.contact.completeName') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.contact.completeName')) : '') + (this.get('dependsOn.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.company.name')) : '');
        case _alert.INDICATOR_PROJECTSENDED:
        case _alert.INDICATOR_PROJECTSENDEDTODAY:
        case _alert.INDICATOR_PROJECTSENDEDUPCOMING:
        case _alert.INDICATOR_PROJECTSENDEDUPCOMINGORALREADYENDED:
          return '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.reference')) + '</strong>' + ' - ' + this.get('dependsOn.startDate').format('L') + ' - ' + this.get('dependsOn.endDate').format('L') + (this.get('dependsOn.contact.completeName') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.contact.completeName')) : '') + (this.get('dependsOn.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.company.name')) : '');
        case _alert.INDICATOR_DELIVERIESNOTENTIRELYCOVEREDBYCONTRACTS:
        case _alert.INDICATOR_DELIVERIESENDED:
        case _alert.INDICATOR_DELIVERIESENDEDTODAY:
        case _alert.INDICATOR_DELIVERIESENDEDUPCOMING:
          return '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.internalReference')) + '</strong>' + ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.dependsOnResource') ? this.get('dependsOn.dependsOn.completeName') : this.get('dependsOn.dependsOn.name')) + ' - ' + this.get('dependsOn.startDate').format('DD/MM/YY') + ' - ' + this.get('dependsOn.endDate').format('DD/MM/YY') + ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.project.reference')) + (this.get('dependsOn.project.contact.completeName') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.project.contact.completeName')) : '') + (this.get('dependsOn.project.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.project.company.name')) : '');
        case _alert.INDICATOR_INVOICESWITHNOACTION:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSPAST:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSISTODAY:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSUPCOMING:
          return '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.reference') ? this.get('dependsOn.reference') : this.get('dependsOn.getReference')) + '</strong>' + ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.order.project.reference')) + (this.get('dependsOn.order.project.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.order.project.company.name')) : '');
        case _alert.INDICATOR_ORDERSWITHNOBILLINGDETAIL:
        case _alert.INDICATOR_ORDERSWITHNODELIVERYCORRELATED:
          return '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.number')) + '</strong>' + ' - ' + this.get('dependsOn.project.reference') + (this.get('dependsOn.project.contact.completeName') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.project.contact.completeName')) : '') + (this.get('dependsOn.project.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.project.company.name')) : '');
        case _alert.INDICATOR_ORDERSWITHINVOICEDTURNOVERGREATERTHANORDEREDTURNOVER:
        case _alert.INDICATOR_ORDERSWITHSCHEDULESAMOUNTDIFFERENTFROMORDEREDTURNOVER:
          return '<strong>' + this.get('dependsOn.number') + '</strong>' + ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.project.reference')) + (this.get('dependsOn.project.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.project.company.name')) : '');
        case _alert.INDICATOR_SCHEDULESPASTWITHNOINVOICE:
          return this.get('dependsOn.date').format('L') + ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.title')) + ' - ' + '<strong>' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.order.number')) + '</strong>' + (this.get('dependsOn.order.project.company.name') ? ' - ' + Ember.Handlebars.Utils.escapeExpression(this.get('dependsOn.order.project.company.name')) : '');
        default:
          return this.get('id');
      }
    }),
    dependsOnOpenModalNative: Ember.computed('indicator', function () {
      return [_alert.INDICATOR_ACTIONSUPCOMING, _alert.INDICATOR_ACTIONSOFTHEDAY, _alert.INDICATOR_ACTIONSLATE].includes(this.get('indicator'));
    }),
    dependsOnRoute: Ember.computed('indicator', function () {
      switch (this.get('indicator')) {
        case _alert.INDICATOR_TIMESREPORTSWITHNOVALIDATION:
          return 'private.timesreports.timesreport';
        case _alert.INDICATOR_EXPENSESREPORTSWITHNOVALIDATION:
          return 'private.expensesreports.expensesreport';
        case _alert.INDICATOR_ABSENCESREPORTSWITHNOVALIDATION:
          return 'private.absencesreports.absencesreport';
        case _alert.INDICATOR_CANDIDATESWITHNOACTION:
        case _alert.INDICATOR_CANDIDATESNOTUPDATED:
          return 'private.candidates.candidate.overview';
        case _alert.INDICATOR_RESOURCESWITHNOACTION:
        case _alert.INDICATOR_RESOURCESARCHIVEDWITHACTIVATEDINTRANET:
        case _alert.INDICATOR_RESOURCESWITHNOCONTRACTS:
        case _alert.INDICATOR_RESOURCESWITHNOMORECONTRACTS:
          return 'private.resources.resource.overview';
        case _alert.INDICATOR_RESOURCESWITHFOLLOWEDDOCUMENTS:
          return 'private.followeddocuments.followeddocument';
        case _alert.INDICATOR_RESOURCESWITHOVERLAPPEDCONTRACTS:
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATEUPCOMING:
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATETODAY:
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATETERMINATED:
        case _alert.INDICATOR_CONTRACTSENDEDUPCOMING:
        case _alert.INDICATOR_CONTRACTSENDEDTODAY:
        case _alert.INDICATOR_CONTRACTSENDED:
        case _alert.INDICATOR_RESOURCESWITHNOCONTRACTENDDATE:
          return 'private.contracts.contract';
        case _alert.INDICATOR_OPPORTUNITIESWITHNOACTION:
          return 'private.opportunities.opportunity.information';
        case _alert.INDICATOR_POSITIONINGSNOTUPDATED:
          return 'private.positionings.positioning';
        case _alert.INDICATOR_PROJECTSWITHNOACTION:
        case _alert.INDICATOR_PROJECTSENDED:
        case _alert.INDICATOR_PROJECTSENDEDTODAY:
        case _alert.INDICATOR_PROJECTSENDEDUPCOMING:
        case _alert.INDICATOR_PROJECTSENDEDUPCOMINGORALREADYENDED:
        case _alert.INDICATOR_PROJECTSWITHNOORDERNOTARCHIVED:
        case _alert.INDICATOR_PROJECTSWITHSIGNEDTURNOVERGREATERTHANORDEREDTURNOVER:
          return 'private.projects.project.information';
        case _alert.INDICATOR_DELIVERIESNOTENTIRELYCOVEREDBYCONTRACTS:
        case _alert.INDICATOR_DELIVERIESENDED:
        case _alert.INDICATOR_DELIVERIESENDEDTODAY:
        case _alert.INDICATOR_DELIVERIESENDEDUPCOMING:
          return 'private.deliveries.delivery';
        case _alert.INDICATOR_INVOICESWITHNOACTION:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSPAST:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSISTODAY:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSUPCOMING:
          return 'private.invoices.invoice';
        case _alert.INDICATOR_ORDERSWITHNOBILLINGDETAIL:
        case _alert.INDICATOR_ORDERSWITHNODELIVERYCORRELATED:
        case _alert.INDICATOR_ORDERSWITHINVOICEDTURNOVERGREATERTHANORDEREDTURNOVER:
        case _alert.INDICATOR_ORDERSWITHSCHEDULESAMOUNTDIFFERENTFROMORDEREDTURNOVER:
        case _alert.INDICATOR_SCHEDULESPASTWITHNOINVOICE:
          return 'private.orders.order';
        default:
          return false;
      }
    }),
    dependsOnId: Ember.computed('indicator', 'dependsOn.isFulfilled', function () {
      if (!this.get('dependsOn.isFulfilled')) return '0'; // l'id doit etre different de `` sinon les link-to vont planter lors du chargement

      switch (this.get('indicator')) {
        case _alert.INDICATOR_SCHEDULESPASTWITHNOINVOICE:
          return this.get('dependsOn.order.id');
        default:
          return this.get('dependsOn.id');
      }
    }),
    dependsOnModelName: Ember.computed('indicator', 'dependsOn.isFulfilled', function () {
      if (!this.get('dependsOn.isFulfilled')) return '';
      return this.get('dependsOn.modelName');
    })
  });
});