define("boondmanager/pods/components/bm-field/searchItem-abstract/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/logger"], function (_exports, _component, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    store: Ember.inject.service(),
    classNameBindings: ['showLock:bmc-field-lock', 'isLocked:bmc-field-locked'],
    attributeBindings: ['dataName:data-name'],
    allowClear: false,
    showSelect: false,
    showLock: false,
    useInit: true,
    isLocked: Ember.computed('showLock', 'disabled', 'showSelect', function () {
      return !this.get('showLock') && !this.get('disabled') || !this.get('showSelect');
    }),
    searchPrefix: '',
    context: '',
    multiple: false,
    canLaunchSearch: Ember.computed('input', function () {
      return this.get('input') && this.get('input').length >= 3;
    }).readOnly(),
    searchPlaceholder: Ember.computed('i18n._locale', function () {
      return this.get('i18n').t('components:bmFieldSearchItems.searchMessage', {
        context: this.get('context')
      });
    }),
    loadingMessage: Ember.computed('i18n._locale', 'input', function () {
      var i18n = this.get('i18n');
      return this.get('canLaunchSearch') ? i18n.t('components:bmFieldSearchItems.loadingMessage') : i18n.t('components:bmFieldSearchItems.searchMessage');
    }).readOnly(),
    noMatchesMessage: Ember.computed('i18n._locale', 'input', function () {
      var i18n = this.get('i18n');
      return this.get('canLaunchSearch') ? i18n.t('components:bmFieldSearchItems.noMatchesMessage') : i18n.t('components:bmFieldSearchItems.searchMessage');
    }),
    items: function items() {
      var input = this.get('input');
      var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      return proxy.create({
        promise: this._getItems(input)
      });
    },
    _getItems: function _getItems() {
      _logger.default.error('must implements _getItems');
    } /*input*/,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      if (this.get('useInit')) {
        var initialSelect = this.get('initialSelect') && !Ember.isArray(this.get('initialSelect')) ? [this.get('initialSelect')] : this.get('initialSelect');
        if (initialSelect && initialSelect.length && this.get('searchPrefix')) {
          var input = initialSelect.map(function (item) {
            return _this.get('searchPrefix') + item;
          }).join(' ');
          var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
          proxy.create({
            promise: this._getItems(input)
          }).then(function (items) {
            if (_this.get('multiple')) {
              _this.set('selected', items);
            } else if (items.length === 1) {
              _this.set('selected', items.get('firstObject'));
            } else {
              _this.set('selected', null);
            }
          });
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-searchitems must have a "onChange" attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-searchitems "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
    },
    actions: {
      searchItems: function searchItems(input) {
        this.set('input', input);
        if (this.get('canLaunchSearch')) {
          return this.items();
        }
      },
      customOnChange: function customOnChange(newSelected) {
        this.set('showSelect', true);
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange(newSelected);
        }
      },
      toggleSelect: function toggleSelect() {
        if (!this.get('disabled')) {
          var OnLockFn = this.get('showSelect') ? this.get('onLock') : this.get('onUnlock');
          if (typeof OnLockFn === 'function') {
            OnLockFn();
          }
          this.toggleProperty('showSelect');
        }
      }
    }
  });
});