define("boondmanager/models/appemailingquotation", ["exports", "ember-data", "boondmanager/models/quotation"], function (_exports, _emberData, _quotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsEMailingQuotation = _quotation.default.extend({
    reference: _emberData.default.attr('string'),
    allowedStates: _emberData.default.attr(''),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'quotation',
    canReadRecord: Ember.computed.alias('canReadQuotation').readOnly(),
    emailsOptions: Ember.computed('billingDetail.emails.[]', function () {
      var options = [];
      var emails = [];
      var distinct = function distinct(value, index, self) {
        return self.indexOf(value) === index && value;
      };
      if (this.get('billingDetail') && this.get('billingDetail.emails')) {
        emails = this.get('billingDetail.emails');
      }
      var distinctEmails = emails.filter(distinct);
      distinctEmails.forEach(function (email) {
        options.push({
          id: email,
          value: email
        });
      });
      return options;
    }).readOnly(),
    recordRouteName: Ember.computed('', function () {
      return 'private.apps.wdevis.quotation';
    }).readOnly()
  });
  AppsEMailingQuotation.reopenClass({
    prefix: 'QUOT'
  });
  AppsEMailingQuotation.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingQuotation.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingQuotation;
});