define("boondmanager/pods/components/octane/bm-field/compare-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "39ISmFHb",
    "block": "{\"symbols\":[\"&attrs\",\"@selected\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field_compare-indicators\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,1],[[32,0,[\"onUpdateSelected\"]],[32,2]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[[24,\"data-name\",\"input-compare-indicators\"]],[[\"@options\",\"@selected\",\"@onChange\",\"@multiple\",\"@allowClear\",\"@forceDisplayBelow\",\"@showResultsOnFocus\",\"@autofocus\",\"@renderInPlace\",\"@selectAll\",\"@nbDisplayedItems\",\"@valueKeyName\"],[[32,0,[\"indicators\"]],[32,0,[\"selected\"]],[32,0,[\"onChange\"]],true,true,false,true,false,false,true,3,\"value\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/compare-indicators/template.hbs"
  });
});