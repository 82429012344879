define("boondmanager/services/current-user", ["exports", "boondmanager/utils/logger", "boondmanager/services/notifications"], function (_exports, _logger, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Time in milliseconds during which to cache the data
   *
   * @type {Number}
   * @constant
   * @private
   */
  //const cache = (config.cache && (config.cache.currentUser || config.cache.default)) || 3000; // default 5mn, now 3 sec
  /**
   * Current user Service
   *
   * Service to get current connected user information.
   *
   * Why use `Ember.ObjectProxy` & `Ember.PromiseProxyMixin` and not `Ember.Service`?
   *
   *   - [[FEATURE ember-runtime-proxy-mixin] #5156](https://github.com/emberjs/ember.js/pull/5156)
   *   - :'( cf [[FEATURE ember-runtime-proxy-mixin] Remove feature (make private). #5392](https://github.com/emberjs/ember.js/pull/5392)
   *                                                                  `-> but why ???
   *
   * Example:
   *
   * //controller.js
   * ```javascript
   * currentUser: Ember.inject.service('current-user')
   * ```
   *
   * //template.hbs
   * ```handlebars
   * <p>{{currentUser.resource.entityName}}:</p>
   * <ul>
   *   {{#each-in currentUser.rights as |moduleName rights|}}
   *   <li>{{moduleName}}:
   *     <ul>
   *       {{#each-in rights as |rightName value|}}
   *       <li>{{rightName}} : {{if value 'OK' 'NOK'}}</li>
   *       {{/each-in}}
   *     </ul>
   *   </li>
   *   {{/each-in}}
   * </ul>
   * ```
   *
   * @class CurrentUserService
   * @namespace  Ember.Service
   * @extends {Ember.ObjectProxy}
   * @uses Ember.PromiseProxyMixin
   * @public
   */
  var CurrentUserService = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin, {
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    /**
     * Last update time in milliseconds
     *
     * @type {Number}
     * @private
     */
    _lastupdate: 0,
    /**
     * @type {Promise}
     */
    boondAccount: null,
    /**
     * Last update time in milliseconds
     *
     * @type {Number}
     * @public
     * @readOnly
     */
    lastupdate: Ember.computed.alias('_lastupdate').readOnly(),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get('notifications').on(_notifications.SUBACTION_RELOAD_CURRENT_USER, function () {
        _this.reload();
      });
    },
    /**
     * Get current user
     *
     * @return {Promise} promise which resolves with the found current user object or `null`
     */
    _getCurrentUser: function _getCurrentUser(sendNotification) {
      var _this2 = this;
      Ember.setProperties(this, {
        isFulfilled: false,
        isRejected: false
      });
      _logger.default.log('current-user _getCurrentUser', this.get('_lastupdate'));
      return this.get('store').queryRecord('currentuser', {
        id: 'me'
      }).then(function (profile) {
        Ember.setProperties(_this2, {
          _lastupdate: new Date().getTime(),
          content: profile,
          isFulfilled: true
        });
        if (sendNotification) {
          _this2.notifications.currentUserReloaded(profile);
        }
        _logger.default.log('current-user _getCurrentUser loaded', _this2.get('_lastupdate'));
        return profile;
      }, function (reason) {
        Ember.setProperties(_this2, {
          reason: reason,
          isRejected: true
        });
        throw reason;
      }, 'Ember: CurrentUser PromiseProxy').catch(function (error) {
        _logger.default.log(error);
        throw error;
      });
    },
    /**
     * Reload current user
     */
    reload: function reload() {
      // on s'en fout de la valeur qu'on définie ici puisqu'elle est fixée dans le set() :)
      if (!this.get('isPending')) {
        _logger.default.log('current-user reload...');
        this.set('promise', true);
      } else {
        _logger.default.log('current-user reload aborted because a previous reload is pending!');
      }
    },
    /**
     * The promise whose fulfillment value is being proxied by this object.
     *
     * @property promise
     * @uses     _getCurrentUser
     * @public
     */
    promise: Ember.computed({
      get: function get() {
        _logger.default.log('current-user promise get');
        return this._getCurrentUser();
      },
      set: function set() {
        _logger.default.log('current-user promise set');
        return this._getCurrentUser(true);
      }
    })
  });

  /*
     Reopen Class to add `isServiceFactory` and set it to `true` so `CurrentUserService`
     which is an `Ember.ObjectProxy` will be seen as an `Ember.Service`.
  
     see https://github.com/emberjs/ember.js/issues/13249
     see fix example here: https://github.com/grownups/ember-cart/pull/1/files
   */
  CurrentUserService.reopenClass({
    isServiceFactory: true
  });
  var _default = _exports.default = CurrentUserService;
});