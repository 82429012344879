define("boondmanager/utils/sharedEntity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ALL_MANAGER_TYPES = void 0;
  _exports.inputToShareFragment = inputToShareFragment;
  _exports.shareFragmentToInput = shareFragmentToInput;
  var ALL_MANAGER_TYPES = _exports.ALL_MANAGER_TYPES = 'allManagerTypes';

  /**
   * Convertion des valeurs du fragment share vers un format valide pour le champs de sélection
   * @param {Object} share Share fragment
   * @returns {A}
   */
  function shareFragmentToInput(share) {
    var shares = Ember.A([]);
    if (share.allAgenciesTypesOf) {
      share.allAgenciesTypesOf.forEach(function (type) {
        shares.push({
          allAgenciesTypesOf: {
            id: type === ALL_MANAGER_TYPES ? type : parseInt(type)
          }
        });
      });
    }
    if (share.resources) {
      share.resources.forEach(function (resource) {
        shares.push({
          resource: resource
        });
      });
    }
    if (share.groups) {
      share.groups.forEach(function (group) {
        shares.push({
          group: group
        });
      });
    }
    if (share.agenciesTypesOf) {
      share.agenciesTypesOf.forEach(function (agencyTypesOf) {
        agencyTypesOf.typesOf.forEach(function (type) {
          var obj = {};
          obj["agency_".concat(agencyTypesOf.agency.id)] = {
            id: type === ALL_MANAGER_TYPES ? type : parseInt(type),
            agencyName: agencyTypesOf.agency.name
          };
          shares.push(obj);
        });
      });
    }
    return shares;
  }

  /**
   * Convertion des valeurs de l'input vers un fragment shared entity
   * @param {Array} input Array of selected shares
   * @returns {Object}
   */
  function inputToShareFragment(input) {
    var fragment = {
      allAgenciesTypesOf: Ember.A([]),
      resources: Ember.A([]),
      agenciesTypesOf: Ember.A([]),
      groups: Ember.A([])
    };
    input.forEach(function (obj) {
      var key = Object.keys(obj)[0];
      var match = null;
      switch (key) {
        case 'allAgenciesTypesOf':
          fragment.allAgenciesTypesOf.push(obj.allAgenciesTypesOf.id);
          break;
        case 'resource':
          fragment.resources.push({
            id: obj.resource.id
          });
          break;
        case 'group':
          fragment.groups.push({
            id: obj.group.id
          });
          break;
        default:
          match = key.match(/agency_([1-9][0-9]*)/);
          if (match) {
            var agencyId = match[1];
            var currentAgenciesTypes = fragment.agenciesTypesOf.find(function (el) {
              return el.agency.id == agencyId;
            });
            if (currentAgenciesTypes) {
              currentAgenciesTypes.typesOf.push(obj[key].id);
            } else {
              fragment.agenciesTypesOf.push({
                agency: {
                  id: agencyId
                },
                typesOf: [obj[key].id]
              });
            }
          }
          break;
      }
    });
    return fragment;
  }
});