define("boondmanager/models/appdigitalworkplacecategory", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _emberDataCopyable, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAX_NUMBER_OF_DOCUMENTS = _exports.MAX_NUMBER_OF_CATEGORIES = void 0;
  var MAX_NUMBER_OF_CATEGORIES = _exports.MAX_NUMBER_OF_CATEGORIES = 10;
  var MAX_NUMBER_OF_DOCUMENTS = _exports.MAX_NUMBER_OF_DOCUMENTS = 30;
  var MAX_DOCUMENTS_TO_DISPLAY_ON_FIRST_LOAD = 5;
  var Category = _base.default.extend(_emberDataCopyable.default, {
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    row: _emberData.default.attr('number'),
    nbCategoryDocuments: _emberData.default.attr('number'),
    creationDate: _emberData.default.attr('date'),
    updateDate: _emberData.default.attr('date'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    categoryDocuments: (0, _attributes.fragmentArray)('appdigitalworkplacecategorydocumentfragment'),
    files: _emberData.default.hasMany('appdigitalworkplacedocument'),
    displayAllDocuments: false,
    entityName: Ember.computed('title', function () {
      return this.get('title');
    }),
    displayedDocuments: Ember.computed('categoryDocuments.[]', 'displayAllDocuments', function () {
      var documents = this.get('categoryDocuments').sortBy('row');
      var categoryFiles = this.get('files').map(function (f) {
        return f.get('id');
      });

      /** https://wishgroupe.atlassian.net/browse/BF-15764 */
      documents = documents.filter(function (doc) {
        return categoryFiles.includes(doc.get('file'));
      });
      if (!this.get('displayAllDocuments')) {
        documents = documents.slice(0, MAX_DOCUMENTS_TO_DISPLAY_ON_FIRST_LOAD);
      }
      return documents;
    }),
    hasMoreDocumentToDisplay: Ember.computed('categoryDocuments.[]', 'displayedDocuments', function () {
      return !this.get('displayAllDocuments') && this.get('categoryDocuments').length > MAX_DOCUMENTS_TO_DISPLAY_ON_FIRST_LOAD;
    }),
    hasLessDocumentToDisplay: Ember.computed('categoryDocuments.[]', 'displayedDocuments', function () {
      return this.get('displayAllDocuments') && this.get('categoryDocuments').length > MAX_DOCUMENTS_TO_DISPLAY_ON_FIRST_LOAD;
    })
  });
  Category.reopenClass({
    prefix: 'DWCAT'
  });
  Category.reopen({
    prefix: Ember.computed(function () {
      return Category.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Category;
});