define("boondmanager/pods/components/bm-field/state-reason/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _component.default.extend({
    /**
     * Component CSS classes
     *
     * @property classNames
     * @type Array
     * @default  ['bmc-field-state-reason']
     */
    classNames: ['bmc-field-state-reason'],
    /** PROPERTIES **/
    /**
     * Afficher directement le champ commentaire ou juste un bmb-link (si commentaire vide)
     *
     * @property showComment
     * @type {boolean}
     * @default false
     */
    showComment: false,
    /**
     * Champs disabled
     *
     * @property disabled
     * @type {boolean}
     * @default false
     */
    disabled: false,
    didRender: function didRender() {
      var _this = this;
      if (this.get('reasons.length')) {
        var foundValue = this.get('reasons').find(function (item) {
          return item.id === _this.get('reason');
        });

        // pour info, le find renverra une valeur si this.get('reason') vaut undefined
        // d'où le deuxième test ajouté ici
        if (typeof foundValue === 'undefined' || typeof this.get('reason') === 'undefined') {
          this.send('updateValue', 'reason', this.get('reasons')[0].id);
        }
      }
    },
    /*** ACTIONS ***/
    actions: {
      /**
       * Conditionne l'affichage du commentaire
       */
      toggleComment: function toggleComment() {
        this.toggleProperty('showComment');
      },
      updateValue: function updateValue(field, value) {
        if (_typeof(value) === 'object' && typeof value.target !== 'undefined') {
          value = value.target.value;
        }
        this.set(field, value);
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange(field, value);
        }
      }
    }
  });
});