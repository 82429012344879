define("boondmanager/routes/base", ["exports", "boondmanager/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * Routeur parent princpal de l'application
   *
   * @class BaseRoute
   * @extends Ember.Route
   * @namespace Ember
   * @module Routes
   */
  var _default = _exports.default = Ember.Route.extend({
    /** SERVICES **/
    currentUser: Ember.inject.service('current-user'),
    rights: Ember.inject.service('rights'),
    pagingSearch: Ember.inject.service('paging-search'),
    i18n: Ember.inject.service(),
    notify: Ember.inject.service(),
    /** PROPERTIES **/
    /**
     * Activation/désactivation de la gestion des queryParams (reset)
     *
     * @property queryParamsManagement
     * @type boolean
     * @default false
     */
    queryParamsManagement: false,
    /**
     * To know if we force perimeters to its default value (= current user selected)
     *
     * @type {Boolean}
     */
    setDefaultPerimeter: true,
    /**
     *
     * Attribut à utiliser pour le titre avec l'initializer.
     *
     * @property attributeForTitle
     *
     * @type String
     */
    attributeForTitle: 'name',
    /** COMPUTED **/
    /**
     * Current Module name
     *
     * If we are not in an 'private.*' route, return 'undefined'
     *
     * @property currentModule
     * @type {String|undefined}
     */
    currentModule: Ember.computed('routeName', function () {
      var routeName = this.get('routeName');
      return this._getModuleFromPath(routeName);
    }).readOnly(),
    /**
     * To know if we are in a module index (search view) route
     *
     * If we are not in an 'private.*' route, return false
     *
     * @property isModuleIndex
     * @type {Boolean}
     */
    isModuleIndex: Ember.computed('currentModule', function () {
      var currentModule = this.get('currentModule');
      return currentModule && this.get('routeName') === "private.".concat(currentModule, ".index");
    }).readOnly(),
    /**
     * A map to know which modules are considered in the same module namespace
     *
     * Each key is a module name and its value is an array which contains all "same" modules.
     *
     * ex: 'crm', 'contacts' and 'companies' are in the same module namespace ('CRM')
     *
     * @property sameModulesMap
     * @type {Object}
     */
    sameModulesMap: Ember.computed(function () {
      var sameModules = [['crm', 'contacts', 'companies'], ['activityexpenses', 'expensesreports', 'timesreports', 'absencesreports'], ['purchases', 'payments']];

      // build map
      var sameModulesMap = {};
      sameModules.forEach(function (list) {
        list.forEach(function (module) {
          sameModulesMap[module] = list.slice();
        });
      });
      return sameModulesMap;
    }).readOnly(),
    /**
     *
     * True  = subModule
     * False = module
     *
     * Gestion différentes du title selon le cas.
     *
     * @property isSubModule
     * @type Boolean
     */
    isSubModule: Ember.computed('routeName', function () {
      var routeParts = this.routeName.split('.').filter(function (name) {
        return ['private', 'index'].indexOf(name) === -1;
      });
      return routeParts.length >= 2;
    }).readOnly(),
    isApp: Ember.computed('normalizedRouteName', function () {
      if (this.get('currentModule') === 'apps.index') return false;
      return /^apps\.(.+)/.test(this.get('currentModule'));
    }).readOnly(),
    isAdmin: Ember.computed('normalizedRouteName', function () {
      var routeParts = this.get('currentModule').split('.');
      return routeParts[0] === 'administrator';
    }),
    /**
     * Computed on routeName for get the name of module
     */
    routeSubmodule: Ember.computed('routeName', function () {
      return Ember.A(this.routeName.split('.')).get('lastObject');
    }).readOnly(),
    /**
     * Définition du titre de la page
     * @method setTitle
     * @param {Object} model Ember Model
     */
    setTitle: function setTitle(model) {
      this.set('title', this.titleToken(model));
    },
    /**
     * Check if a query params exists in this route
     *
     * @method isQueryParamExists
     * @param  {String}  qpName Query param name
     * @return {Boolean}
     */
    isQueryParamExists: function isQueryParamExists(qpName) {
      return this.get('_qp.propertyNames').indexOf(qpName) !== -1;
    },
    /**
     * Surcharge de la méthode de sérialisation des paramètres pour éviter deux sérialisations sur un même tableau
     * et donc casser le format
     *
     * @method serializeQueryParam
     * @param {Object} value
     * @param {String} urlKey
     * @param {String} defaultValueType
     * @private
     */
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      // FIX BMLABS-3360
      // cf https://github.com/emberjs/ember.js/issues/14174
      // à suivre : https://github.com/nathanhammond/query-string-spec
      if (defaultValueType === 'array') {
        if (Ember.isArray(value)) {
          return value.length ? JSON.stringify(value) : undefined;
        }
        // dans ce cas, on est déjà sur une valeur serialisée, on ne la re-traite pas
        return value && value !== '[]' ? value : undefined;
      } else {
        return this._super.apply(this, arguments);
      }
    },
    /**
     * Surcharge de la méthode de désérialisation des paramètres pour palier aux erreurs de la méthode parente lors de la
     * désérialisation de tableaux vides
     *
     * @method deserializeQueryParam
     * @param value
     * @param urlKey
     * @param defaultValueType
     * @return {string|undefined|*}
     */
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      try {
        var newValue = this._super.apply(this, arguments);
        // palier au fait que, dans le cas où on aurait "order=desc" dans l'URL de recherche et que l'on est redirigé vers
        // une URL de partage, alors order vaudrait NaN dans ce cas car le système de routeur d'Ember mémorise les paramètres
        // s'ils ne sont pas surchargés et que le typage (number) fait que desc=NaN
        return defaultValueType === 'number' && isNaN(newValue) ? undefined : newValue;
      } catch (error) {
        if (defaultValueType === 'array') {
          value = '[]';
          return this._super(value, urlKey, defaultValueType);
        }
      }
    },
    /**
     * Reset all queryParams (search filters)
     *
     * @method resetAllQueryParams
     * @param {Controller} controller Ember Controller instance
     */
    resetAllQueryParams: function resetAllQueryParams(controller) {
      var queryParams = controller.get('queryParams');
      var excludedQueryParams = ['viewMode', 'page', 'maxResults'];
      if (Ember.isArray(queryParams)) {
        queryParams.forEach(function (qp) {
          if (!excludedQueryParams.includes(qp)) {
            controller.set(qp, undefined);
          }
        });
      }
      if (this.queryParamsManagement) {
        this.set('setDefaultPerimeter', true);
      }
    },
    /**
     * To know if we go out of a module
     *
     * @param  {Transition} transition  where we go
     * @return {Boolean}
     */
    isExitingCurrentModule: function isExitingCurrentModule(transition) {
      var currentModule = this.get('currentModule');
      var targetModule = this._getModuleFromPath(transition.to.name);
      var sameModulesMap = this.get('sameModulesMap');
      var isExitingCurrentModule;
      if (sameModulesMap[currentModule]) {
        isExitingCurrentModule = sameModulesMap[currentModule].indexOf(targetModule) === -1;
      } else {
        isExitingCurrentModule = currentModule !== targetModule;
      }
      if (isExitingCurrentModule) {
        _logger.default.debug("[mixin querypamas-management-route] ".concat(this.get('routeName'), " - exit from ").concat(currentModule));
      }
      return isExitingCurrentModule;
    },
    /**
     * Get module from dotted route path
     *
     * @method _getModuleFromPath
     * @param  {String} path
     * @return {String|undefined} Module name (plural form) or undefined if we
     *                            are not in `private.*` route
     */
    _getModuleFromPath: function _getModuleFromPath(path) {
      var currentModule;
      if (typeof path !== 'undefined') {
        var parts = path.split('.');
        if (parts[0] === 'private' && parts.length > 1 && typeof parts[1] === 'string') {
          currentModule = parts[1];
          if (['apps', 'administrator'].includes(currentModule) && parts.length > 2 && typeof parts[2] === 'string') {
            currentModule += '.' + parts[2];
            if (parts.length > 3 && typeof parts[3] === 'string' && parts[3] !== 'index') {
              currentModule += '.' + parts[3];
            }
          }
        }
      }
      return currentModule;
    },
    /**
     * Nettoyage des queryParams du contrôleur d'arrivée de la transition pour ne pas avoir de valeurs
     * persistantes d'une précédente navigation où certains queryParams ne seraient pas redéfinis dans l'URL
     * @param currentQueryParams
     * @param routeName
     * @return {*}
     * @private
     */
    cleanControllerQueryParams: function cleanControllerQueryParams(currentQueryParams, routeName) {
      var controller = typeof this.get('controller') !== 'undefined' ? this.get('controller') : Ember.getOwner(this).lookup("controller:".concat(routeName));
      var excludedQueryParams = ['viewMode', 'page', 'maxResults', 'columns'];
      // récupération de la liste de tous les queryParams du contrôleur
      if (controller && controller.get('queryParams')) {
        var controllerQueryParams = controller.get('queryParams');
        // initialisation à undefined de toutes les valeurs qui ne sont pas défini dans la route pour
        // écraser leur valeur précédente dans le cas de navigations où les qp ne seraient pas redéfini (recherches partagées par exemple)
        controllerQueryParams.forEach(function (qp) {
          if (!currentQueryParams.hasOwnProperty(qp) && !excludedQueryParams.includes(qp)) {
            controller.set(qp, undefined);
          }
        });
      }
      return currentQueryParams;
    },
    /**
     * Méthode qui va permettre de récupérer le contexte depuis transition.to pour pouvoir correctement
     * rediriger en cas d'arrivée directe sur une URL contenant un modèle (principalement dans le cas où
     * on est sur un onglet qui contient également des queryParams et pour lequel une méthode defaultSearchValues
     * est définie dans le routeur)
     * @param transition
     * @return {String|null}
     * @private
     */
    _getContextFromTransitionTo: function _getContextFromTransitionTo(transition) {
      var transitionUrl = transition.intent.url;
      var idRegex = new RegExp(/[/]([0-9]+)[/]/);
      var idMatch = transitionUrl ? transitionUrl.match(idRegex) : null;
      var urlId = idMatch && idMatch.length ? idMatch[1] : null;
      var parentId = transition.to.parent.params.id || null;
      /*
      	La variable transition.intent.contexts n'existe que lorsque l'on est passé par un click sur un lien
      	auquel cas, transition.intents.url n'existe pas et le contexte est passé via cette propriété
      	ici donc, la récupération du contexte va passer par deux étapes :
      	- soit transition.intents.context existe, auquel cas on passera sa valeur au routeur pour la redirection
      	- soit trantition.intents.url existe, auquel cas on va récupérer l'id (s'il existe) de l'URL pour
      	  le passer en contexte lors de la redirection
       */
      if (transition.intent.contexts && transition.intent.contexts.length) {
        return transition.intent.contexts[0];
      } else if (urlId) {
        return urlId;
      }
      return parentId;
    },
    /** -------- EMBER ROUTER EVENTS -------- */beforeModel: function beforeModel(transition) {
      var _this = this;
      var currentRouteName = this.get('routeName');
      var transitionContexts = this._getContextFromTransitionTo(transition);
      var upstreamPromise = this._super.apply(this, arguments);
      this.set('notify.genericErrorModal.enabled', true);
      if (this.get('handleGenericErrors') === false) {
        this.set('notify.genericErrorModal.enabled', false);
      }
      if (this.queryParamsManagement) {
        _logger.default.debug.apply(_logger.default, ["[mixin queryparams-management-route] ".concat(this.get('routeName'), " - beforeModel")].concat(Array.prototype.slice.call(arguments)));

        // we must manage previous _super like a potential Promise
        // cf https://github.com/emberjs/ember.js/issues/10621
        // cf rwjblue'jsbin : http://jsbin.com/sunise/1/edit?js,console,output
        // if we need upstream return :
        //     return Ember.RSVP.resolve(upstreamPromise).then((upstreamReturn) => { ... return ...});
        // else :
        //     const myPromise = ...;
        //     return Ember.RSVP.all([upstreamPromise, myPromise]);
        if (this.get('setDefaultPerimeter') && this.isQueryParamExists('perimeterManagers') && transition.to.queryParams) {
          this.set('setDefaultPerimeter', false);

          // 1 - ne pas oublier d'annuler la transition en cours !!!
          transition.abort();
          // 2 - puis on remplace (on pourrait utiliser transitionTo mais là c'est plus propre car on ajoute pas une entrée
          // inutile dans l'historique).
          //
          // De plus, on est obligé de définir la route vers laquelle se rediriger car, ayant annulé la transition en
          // cours, la route courante est celle d'où l'on vient !
          // Si on indique par la route à transitionTo ou replaceWith, on aurait une redirection vers la route d'où on
          // vient :) En résumé, on bougerait pas (tentez l'expérience en commentant le 1er arg) !
          var allPromises = [upstreamPromise, this.get('currentUser')];
          if (typeof this.defaultSearchValues === 'function') {
            allPromises.push(this.defaultSearchValues());
          } else {
            allPromises.push(Ember.RSVP.Promise.resolve({}));
          }
          return Ember.RSVP.all(allPromises).then(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 3),
              /*upstreamPromiseReturn*/currentUser = _ref2[1],
              defaultSearchValues = _ref2[2];
            var newQueryParams = Ember.assign({}, transition.to.queryParams, {
              perimeterManagers: [currentUser.get('id')]
            }, defaultSearchValues);
            _logger.default.debug("[mixin querypamas-management-route] ".concat(_this.get('routeName'), " - replaceWith"), currentRouteName, newQueryParams);
            _this.cleanControllerQueryParams(newQueryParams, currentRouteName);
            if (transitionContexts) {
              return _this.get('router').replaceWith(currentRouteName, transitionContexts, {
                queryParams: newQueryParams
              });
            } else {
              return _this.get('router').replaceWith(currentRouteName, {
                queryParams: newQueryParams
              });
            }
          });
        } else {
          return upstreamPromise;
        }
      } else {
        // dans tous les cas, il faudra nettoyer dans le contrôleur tous les queryParams qui ne sont pas déclarés dans
        // la transition pour éviter d'avoir des reliquats de valeurs qui ne sont pas attendues
        this.cleanControllerQueryParams(transition.to.queryParams, currentRouteName);
        return upstreamPromise;
      }
    },
    /**
     * Setup du controller
     * @param controller
     */
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      if (controller.actions) {
        if (typeof controller.actions.uncheckAllCurrentSearch === 'function') controller.actions.uncheckAllCurrentSearch.apply(controller);
      }
    },
    /**
     * Reset du controller
     * @param controller
     * @param isExiting
     */
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        if (controller.actions) {
          if (typeof controller.actions.emptyNotDeleted === 'function') controller.actions.emptyNotDeleted.apply(controller);
          if (typeof controller.actions.emptyNotModified === 'function') controller.actions.emptyNotModified.apply(controller);
          if (typeof controller.actions.emptyNotDuplicated === 'function') controller.actions.emptyNotDuplicated.apply(controller);
        }
        this.resetAllQueryParams(controller);

        // afin de remettre la page et le maxResults à leur valeur d'origine en quittant un module
        // (que ce soit une vue de recherche ou un onglet avec vue liste)
        if (controller.hasOwnProperty('page')) controller.set('page', 1);
        if (controller.hasOwnProperty('maxResults')) controller.set('maxResults', 30);

        // on doit réinitialiser le titre, car il est fixé lors d'un update d'une fiche
        this.set('title', null);
      }
      this._super.apply(this, arguments);
    },
    actions: {
      /**
       * Placé ici et non dans le routeur kanban
       * Car il peut être invoqué depuis un controller "Kanban" via this.send(...)
       * mais si on clique sur une autre route ne possédant pas le routeur kanban,
       * comme il est évoqué en async, il y a une erreur dans la console, non présent dans la route de destination
       */
      resetKanbanModel: function resetKanbanModel() {
        if (this.get('kanbanModel')) {
          this.set('kanbanModel', null);
        }
      },
      /**
       * If language change, method update the page title.
       *
       * @method i18nLocaleChanged
       */
      i18nLocaleChanged: function i18nLocaleChanged() {
        if (this.controller) {
          var model = this.controller.model;
          this.setTitle(model);

          // KDE & MPI : revert part of BMLABS-6865 to fix BMLABS-4887
          /*if (transition && !transition.isAborted) {
          	transition.trigger(false, 'collectTitleTokens', []);
          }
          else {
          	this.send('collectTitleTokens', []);
          }*/
          this.send('collectTitleTokens', []);
        }
        this._super.apply(this, arguments);
        return false;
      } /*oldLng, newLng, transition*/,
      /**
       * Mise à jour du titre de la page
       * @param model
       */
      updateTitle: function updateTitle(model) {
        this.setTitle(model);
        this.send('collectTitleTokens', []);
      },
      /**
       * Rechargement des droits de la page courante
       */
      reloadRights: function reloadRights() {
        _logger.default.debug('reloading rights');
        this.rights.reloadRights();
      },
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        if (this.queryParamsManagement && this.isExitingCurrentModule(transition) && this.get('isModuleIndex')) {
          var currentModuleIndexRoute = Ember.getOwner(this).lookup("route:private.".concat(this.get('currentModule'), ".index"));
          currentModuleIndexRoute.set('setDefaultPerimeter', true);
        }
      },
      /**
       * To reset cbCheckAll if a queryParam change
       *
       */
      queryParamsDidChange: function queryParamsDidChange(changed, totalPresent, removed) {
        if (this.controller) {
          var totalChanged = Object.keys(changed).concat(Object.keys(removed));
          var qpIgnore = ['sort', 'order', 'page'];
          var resetSelected = totalChanged.any(function (item /*, index, enumerable*/) {
            return qpIgnore.indexOf(item) === -1;
          });
          if (resetSelected && typeof this.controller.get('actions.uncheckAllCurrentSearch') !== 'undefined') {
            this.controller.send('uncheckAllCurrentSearch');
          }
        }
        return this._super.apply(this, arguments);
      },
      loading: function loading(transition /*, originRoute */) {
        // cette action est appelée par EmberJS dès que le model de la route est lancé afin de signifier le début du chargement des données
        var controller = Ember.getOwner(this).lookup("controller:".concat(transition.to.name));
        if (controller) {
          controller.set('currentDataIsLoading', true);
          transition.promise.finally(function () {
            // une fois le chargement du modèle terminé, on notifie le contrôleur
            controller.set('currentDataIsLoading', false);
          });
        }
      }
    }
  });
});