define("boondmanager/models/appsepacompany", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable"], function (_exports, _base, _emberData, _attributes, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsSepaCompany = _base.default.extend(_emberDataCopyable.default, {
    // ATTRIBUTES
    name: _emberData.default.attr('string'),
    // TAB_CRMSOCIETE.CSOC_SOCIETE

    banksDetails: (0, _attributes.fragmentArray)('bankdetailfragment'),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    })
  });
  AppsSepaCompany.reopenClass({
    prefix: 'CSOC'
  });
  AppsSepaCompany.reopen({
    prefix: Ember.computed(function () {
      return AppsSepaCompany.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsSepaCompany;
});