define("boondmanager/pods/components/bm-field/power-select/role/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UgBLjMb9",
    "block": "{\"symbols\":[\"@select\",\"&default\"],\"statements\":[[10,\"span\"],[15,0,[30,[36,3],[\"ember-power-select-trigger-item\",[30,[36,2],[[32,1,[\"selected\"]],\" ember-power-select-trigger-item-selected\"],null]],null]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[32,1,[\"selected\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,0],[\"bmi-role\"],null]],[2,\" \"],[1,[30,[36,1],[\"components:bmFieldPowerSelectRoleTrigger.use\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,0],[\"bmi-role\"],null]],[2,\" \"],[18,2,[[32,1,[\"selected\"]],[32,1]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[1,[30,[36,0],[\"bmi-caret-down\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"t\",\"if\",\"concat\",\"not\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/role/trigger/template.hbs"
  });
});