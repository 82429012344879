define("boondmanager/pods/components/bm-field/account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1GPo/Qto",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[6,[37,16],null,[[\"name\",\"customSearch\",\"useObjects\",\"multiple\",\"selected\",\"onchange\",\"renderInPlace\",\"options\",\"placeholder\",\"searchMessage\",\"searchPlaceholder\",\"loadingMessage\",\"noMatchesMessage\",\"allowClear\",\"required\",\"disabled\",\"disableCheckAll\",\"displayNbSelected\"],[[35,15],[30,[36,13],[[32,0],\"searchItems\"],null],true,[35,14],[32,0,[\"selected\"]],[30,[36,13],[[32,0],\"customOnChange\"],null],[35,12],[35,11],[30,[36,1],[[35,10],[35,10],[30,[36,9],[\"common:none\"],null]],null],\"\",[35,8],[35,7],[35,6],[35,5],[35,4],[35,0],[35,3],[35,2]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,1],[[35,0],[32,1,[\"completeName\"]],[32,1,[\"entityName\"]]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\",\"displayNbSelected\",\"disableCheckAll\",\"required\",\"allowClear\",\"noMatchesMessage\",\"loadingMessage\",\"searchPlaceholder\",\"t\",\"placeholder\",\"options\",\"renderInPlace\",\"action\",\"multiple\",\"name\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/account/template.hbs"
  });
});