define("boondmanager/models/appentity", ["exports", "ember-data-copyable", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberDataCopyable, _base, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.APPENTITY_MANAGER_VALIDATED = _exports.APPENTITY_MANAGER_PENDING = _exports.APPENTITY_GUEST_VALIDATED = _exports.APPENTITY_GUEST_PENDING = void 0;
  var APPENTITY_GUEST_PENDING = _exports.APPENTITY_GUEST_PENDING = 'pendingGuest';
  var APPENTITY_GUEST_VALIDATED = _exports.APPENTITY_GUEST_VALIDATED = 'validatedGuest';
  var APPENTITY_MANAGER_PENDING = _exports.APPENTITY_MANAGER_PENDING = 'pendingMainManager';
  var APPENTITY_MANAGER_VALIDATED = _exports.APPENTITY_MANAGER_VALIDATED = 'validatedMainManager';
  var AppEntity = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['app', 'agency', 'pole', 'mainManager', 'guests', 'dependsOn', 'files']
    }),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    currency: _emberData.default.attr('number'),
    currencyAgency: _emberData.default.attr('number'),
    exchangeRate: _emberData.default.attr('number'),
    exchangeRateAgency: _emberData.default.attr('number'),
    state: _emberData.default.attr('number'),
    typeOf: _emberData.default.attr('number'),
    visibility: _emberData.default.attr('boolean'),
    globalValidation: _emberData.default.attr('boolean'),
    guestsValidation: _emberData.default.attr('boolean'),
    managersValidation: _emberData.default.attr('boolean'),
    guestsValidationDate: _emberData.default.attr('date'),
    managersValidationDate: _emberData.default.attr('date'),
    numberOfFiles: _emberData.default.attr('number'),
    app: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    files: _emberData.default.hasMany('document'),
    agency: _emberData.default.belongsTo(),
    mainManager: _emberData.default.belongsTo('resource'),
    guestValidator: _emberData.default.belongsTo('resource'),
    managerValidator: _emberData.default.belongsTo('resource'),
    pole: _emberData.default.belongsTo(),
    dependsOn: _emberData.default.belongsTo('base'),
    guests: _emberData.default.hasMany('resource', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    responses: (0, _attributes.fragmentArray)('fieldresponsefragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    canShowState: _emberData.default.attr('boolean'),
    canShowTypeOf: _emberData.default.attr('boolean'),
    canShowGuestsValidation: _emberData.default.attr('boolean'),
    canShowManagersValidation: _emberData.default.attr('boolean'),
    canShowVisibility: _emberData.default.attr('boolean'),
    canShowGlobalValidation: _emberData.default.attr('boolean'),
    canShowGuestsValidationDate: _emberData.default.attr('boolean'),
    canShowManagersValidationDate: _emberData.default.attr('boolean'),
    canShowGuestValidator: _emberData.default.attr('boolean'),
    canShowManagerValidator: _emberData.default.attr('boolean'),
    canShowCurrencyAgency: _emberData.default.attr('boolean'),
    canShowExchangeRateAgency: _emberData.default.attr('boolean'),
    canShowCurrency: _emberData.default.attr('boolean'),
    canShowExchangeRate: _emberData.default.attr('boolean'),
    canShowGuests: _emberData.default.attr('boolean'),
    canShowMainManager: _emberData.default.attr('boolean'),
    canShowPole: _emberData.default.attr('boolean'),
    canShowAgency: _emberData.default.attr('boolean'),
    canShowAction: _emberData.default.attr('boolean'),
    canShowFiles: _emberData.default.attr('boolean'),
    canShowCreatedBy: _emberData.default.attr('boolean'),
    canShowDependsOn: _emberData.default.attr('boolean'),
    canShowCreationDate: _emberData.default.attr('boolean'),
    canShowUpdateDate: _emberData.default.attr('boolean'),
    reference: Ember.computed('responses.@each.{value}', function () {
      var _this = this;
      var reference = this.get('internalReference');
      this.get('app.sections').forEach(function (section) {
        var field = section.fields.find(function (field) {
          return field.settings.asReference;
        });
        if (field) {
          var response = _this.get('responses').find(function (response) {
            return response.field.id === field.originalID;
          });
          if (response) {
            reference = response.value;
          }
        }
      });
      return reference;
    }),
    dependsOnTitle: Ember.computed('dependsOn', function () {
      switch (this.get('dependsOn.modelName')) {
        case 'resource':
        case 'candidate':
          return this.get('dependsOn.completeName');
        case 'contact':
          return this.get('dependsOn.completeName') + ' - ' + this.get('dependsOn.company.name');
        case 'company':
          return this.get('dependsOn.name');
        case 'product':
          return this.get('dependsOn.entityName') + (this.get('dependsOn.reference') ? ' - ' + this.get('dependsOn.reference') : '');
        case 'opportunity':
          return this.get('dependsOn.title') + (this.get('dependsOn.reference') ? ' - ' + this.get('dependsOn.reference') : '');
        case 'purchase':
          return this.get('dependsOn.title');
        case 'order':
        case 'invoice':
        case 'project':
          return this.get('dependsOn.reference');
        default:
          return this.get('dependsOn') ? this.get('dependsOn.internalReference') : '';
      }
    }),
    guestsLabel: Ember.computed('guests', function () {
      return this.get('guests').map(function (item) {
        return item.get('entityName');
      }).join(', ');
    })
  });
  AppEntity.reopenClass({
    prefix: 'APPENTITY'
  });
  AppEntity.reopen({
    prefix: Ember.computed(function () {
      return AppEntity.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppEntity;
});