define("boondmanager/pods/components/bm-field/power-select/before-options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'bmc-field-power-select_dropdown-header',
    autofocus: true,
    getAutofocus: Ember.computed('autofocus', 'initiallyOpened', function () {
      // ici, l'autofocus est déjà géré par power-select. Le seul cas où cela ne fonctionne pas
      // c'est lorsque l'on construit la vue avec le power-select déjà ouvert par défaut, auquel
      // cas il faut forcer l'autofocus pour que le focus se fasse bien sur le champ de recherche
      // sinon, si on force l'autofocus malgré tout, cela provoque un double focus et on perd la liste
      // cf https://wishgroupe.atlassian.net/browse/BM-4324
      return this.get('initiallyOpened') ? this.get('autofocus') : undefined;
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('checkboxes')) {
        this.set('classNames', ['bm-field-power-select-checkall bmc-field-power-select_dropdown-header']);
      }
    },
    // Lifecycle hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('autofocus')) {
        this.focusInput();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('searchEnabled')) {
        Ember.run.scheduleOnce('actions', this, this.get('select').actions.search, '');
      }
    },
    focused: false,
    bmHasValue: function bmHasValue(event) {
      event.stopPropagation();
    },
    bmHasNoValue: function bmHasNoValue(event) {
      event.stopPropagation();
    },
    bmFocusOut: function bmFocusOut(event) {
      event.stopPropagation();
    },
    bmFocusIn: function bmFocusIn(event) {
      event.stopPropagation();
    },
    // Actions
    actions: {
      onKeydown: function onKeydown(e) {
        var onKeydown = this.get('onKeydown');
        if (onKeydown(e) === false) {
          return false;
        }
        if (e.keyCode === 13) {
          var select = this.get('select');
          select.actions.close(e);
        }
      },
      clearSearch: function clearSearch() {
        this.input = this.getInput();
        if (this.input) {
          this.input.value = '';
        }
        this.get('select').actions.search('');
        var onClearSearch = this.get('onClearSearch');
        if (typeof onClearSearch === 'function') {
          onClearSearch.apply(this);
        }
      },
      clear: function clear() {
        this.get('select').actions.select(null);
        this.get('select').actions.close();
      },
      onFocus: function onFocus() {
        if (typeof this.get('onFocus') == 'function') {
          this.get('onFocus').apply(void 0, arguments);
        }
        this.set('focused', true);
      },
      onBlur: function onBlur() {
        if (typeof this.get('onBlur') == 'function') {
          this.get('onBlur').apply(void 0, arguments);
        }
        this.set('focused', false);
      }
    },
    getInput: function getInput() {
      var query = ".ember-power-select-search-input[aria-controls=\"".concat(this.get('listboxId'), "\"]");
      return document.querySelector(query);
    },
    // Methods
    focusInput: function focusInput() {
      this.input = this.getInput();
      if (this.input) {
        Ember.run.later(this.input, 'focus', 0);
      }
    }
  });
});