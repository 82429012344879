define("boondmanager/services/raven", ["exports", "ember-cli-sentry/services/raven", "boondmanager/utils/logger", "boondmanager/config/environment", "boondmanager/adapters/errors/version-error"], function (_exports, _raven, _logger, _environment, _versionError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _raven.default.extend({
    /**
     * Setup `raven-js` with the config options.
     * @param config
     */
    setup: function setup(config) {
      _logger.default.debug('RAVEN Service - setup', config.sentry);
      var _config$sentry$ravenO = config.sentry.ravenOptions,
        ravenOptions = _config$sentry$ravenO === void 0 ? {} : _config$sentry$ravenO;

      // CAUTION: we modifiy original `ravenOptions` object (ref) here,
      // like in src code of ember-cli-sentry/services/raven#setup.
      ravenOptions.shouldSendCallback = function (data) {
        _logger.default.debug('RAVEN Service - shouldSendCallback', data);
        return true;
      };
      return this._super.apply(this, arguments);
    },
    /**
     * Hook that allows error filtering in global
     * error catching methods.
     *
     * @method ignoreError
     * @param  {Error} error
     * @return {Boolean}
     */
    ignoreError: function ignoreError(error) {
      _logger.default.debug.apply(_logger.default, ['RAVEN Service - ignoreError'].concat(Array.prototype.slice.call(arguments)));
      if (error instanceof _versionError.default) {
        return true;
      }
      if (typeof error !== 'undefined' && _environment.default.environment !== 'development') {
        var ignoreStatusOrCode = _environment.default.sentry.ignoreStatusOrCode || [];

        // if we have an errors array, we take only the first
        var errorStatus = error.errors && error.errors.length && error.errors[0].status || error.status;
        var errorCode = error.errors && error.errors.length && error.errors[0].code || error.code;
        return ignoreStatusOrCode.length && (ignoreStatusOrCode.indexOf(errorStatus) !== -1 || ignoreStatusOrCode.indexOf(errorCode) !== -1);
      }
      return this._super.apply(this, arguments);
    }
  });
});