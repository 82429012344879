define("boondmanager/models/expense", ["exports", "boondmanager/utils/logger", "ember-data", "ember-data-model-fragments", "moment", "ember-data-model-fragments/attributes", "boondmanager/utils/activity", "boondmanager/utils/financial", "boondmanager/models/expensetype", "boondmanager/models/project", "boondmanager/models/delivery", "boondmanager/models/batch", "boondmanager/models/workunittype"], function (_exports, _logger, _emberData, _emberDataModelFragments, _moment, _attributes, _activity, _financial, _expensetype, _project, _delivery, _batch, _workunittype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getActivityIDFromExpense = getActivityIDFromExpense;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  // usefull Factories :)
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  /**
   * To get Activity ID from an Expense fragment
   *
   * Expense is a fragment so its properties `project`, `delivery` and
   * `batch` are not Ember `DS.Model` but "simple" POJO ! So they do not
   * have CP like `getReference` :/
   *
   * Activity ID is equal to Expense.activityType if its type is
   * not 'production', else it's project's internal + space + ref delivery's internal ref
   * + batch's internal ref (optional). But we can not use 'internalReference' CP :'( So
   * we build it...
   *
   * cf GET Activity.id CP.
   *
   * TODO: Expense should be real DS.Model with `project`, `delivery`, etc relationships!
   *
   * @param  {Expense} objExp
   * @return {String|undefined}
   */
  function getActivityIDFromExpense(objExp) {
    var type = objExp.get('activityType');
    var id;
    switch (type) {
      case _workunittype.WUT_INTERNAL:
      case _workunittype.WUT_PRODUCTION:
        {
          var _objExp$getProperties = objExp.getProperties('project', 'delivery', 'batch'),
            project = _objExp$getProperties.project,
            delivery = _objExp$getProperties.delivery,
            batch = _objExp$getProperties.batch;
          if (project && delivery) {
            id = "".concat(_project.default.prefix).concat(objExp.get('project.id'), " ").concat(_delivery.default.prefix).concat(objExp.get('delivery.id'));
            if (batch) {
              id += " ".concat(_batch.default.prefix).concat(objExp.get('batch.id'));
            }
          } else if (type == _workunittype.WUT_INTERNAL) {
            id = type;
          }
          break;
        }
      default:
        id = type;
    }
    return id;
  }

  /* ** ATTRIBUTES TYPE CASTING **
  
  	see: http://discuss.emberjs.com/t/ember-data-store-my-attr-number-as-a-string-why/11904
  
  	Ember-data doesn't auto-cast attributes when we use data in our app! Ex: if
  	you use a number attribut with an {{input}}, you'll get a string :'/
  
  	Ember-data cast attributes only when data is received from or send to the
  	backend...
  
  	So we have to manually cast them when we want to do some calculation for
  	example :'(
  
  	We use Number() constructor because it doesn't detect octals si of a string
  	like "010" is casting, we have the number 10 (Number("010") => 10 vs
  	parseInt("010") > 8 but if we use parseInt("010", 10) => 10, it's ok ;p)
  */

  /* Renvoyé par le BACK dans les *reports:
  
  	actualExpense = {
  		// ** PROPS REQUIRED ***************************************************
  		"id"                  : 4,
  		"title"               : "actualExpense-4",
  		"reinvoiced"          : false,
  		"startDate"           : "2016-10-08T09:09:54+0000",
  		"exchangeRate"        : 0.87,
  		"currency"            : 1,
  		"tax"                 : 0,
  		"category"            : "actual",
  		"number"              : 0
  
  		// ** OPTIONAL PROPS ***************************************************
  		// si présent et = true, c'est un frais km.
  		// La prop n'est pas renvoyée par le BACK qd = false (économie :) ).
  		// Par contre, la prop suivante, "numberOfKilometers" est tjrs renvoyée
  		// par le BACK, que ce soit un frais km ou pas... INUTILE...
  		"isKilometricExpense" : true,
  		"numberOfKilometers"  : 0,
  		"amountIncludingTax"  : 870,
  		"expenseType"         : {
  			"reference" : 2,
  			"taxRate"   : 5,
  			"name"      : "Hôtel"
  		},
  		// si qu'un ID, pourquoi la valeur est-elle un objet et non juste l'ID ?
  		"delivery"            : { id : ... },
  		"batch"               : { id : ... },
  		"project"             : { id : ... }
  	}
  
  	fixedExpense = {
  		// ** PROPS REQUIRED ***************************************************
  		"id"                  : 4,
  		"row"                 : 2,
  		"startDate"           : "2016-10-03T09:09:54+0000",
  		"category"            : "fixed"
  		"amountIncludingTax"  : 40,
  
  		// ** OPTIONAL PROPS ***************************************************
  		// si présent et = true, c'est un frais km.
  		// La prop n'est pas renvoyée par le BACK qd = false (économie :) ).
  		// Par contre, la prop suivante, "numberOfKilometers" est tjrs renvoyée
  		// par le BACK, que ce soit un frais km ou pas... INUTILE...
  		"isKilometricExpense" : {boolean},
  
  		// À n'utiliser que si "isKilometricExpense" = true
  		"numberOfKilometers"  : 0,
  
  		// si présent, ce n'est pas un frais km :D
  		"expenseType"         : {
  			"reference" : 1,
  			"taxRate"   : 12,
  			"name"      : "Restaurant"
  		},
  
  		// si qu'un ID, pourquoi la valeur est-elle un objet et non juste l'ID ?
  		"delivery"            : { id : ... },
  		"batch"               : { id : ... },
  		"project"             : { id : ... }
  	}
  
  	Nous pourrions donc définir sous Ember les fragments suivants :
  
  	expense = {
  		"id"                  : 4,
  		"category"            : "fixed"
  
  		"startDate"           : "2016-10-03T09:09:54+0000",
  		"amountIncludingTax"  : 40,
  
  		// ** OPTIONAL PROPS ***************************************************
  		// si présent et = true, c'est un frais km.
  		// La prop n'est pas renvoyée par le BACK qd = false (économie :) ).
  		// Par contre, la prop suivante, "numberOfKilometers" est tjrs renvoyée
  		// par le BACK, que ce soit un frais km ou pas... INUTILE...
  		"isKilometricExpense" : {boolean},
  
  		// À n'utiliser que si "isKilometricExpense" = true
  		"numberOfKilometers"  : 0,
  
  		// si présent, ce n'est pas un frais km :D
  		"expenseType"         : {
  			"reference" : 1,
  			"taxRate"   : 12,
  			"name"      : "Restaurant"
  		},
  
  		// si qu'un ID, pourquoi la valeur est-elle un objet et non juste l'ID ?
  		"delivery"            : { id : ... },
  		"batch"               : { id : ... },
  		"project"             : { id : ... }
  	}
  
  	actualExpense extend expense = {
  		"number"              : 0,
  		"title"               : "actualExpense-4",
  		"exchangeRate"        : 0.87,
  		"currency"            : 1,
  		"tax"                 : 0,
  		"reinvoiced"          : false
  	}
  
  	fixeExpense extend expense = {
  		"row"                 : 2
  	}
  
  	expenseType = {
  		"reference" : 1,
  		"taxRate"   : 12,
  		"name"      : "Restaurant"
  	}
  
   */
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    settingsService: Ember.inject.service('settings'),
    /**
     * @type {String} 'production'|'absence'|'internal'
     */
    activityType: _emberData.default.attr('string'),
    /**
     * Expense category
     *
     * @type {String}
     */
    category: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('date'),
    amountIncludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    exchangeRate: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    // TAB_PROFILE.FRAISREEL_CHANGE

    isKilometricExpense: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // TAB_TYPEFRAIS.TYPEFRS_REF, TAB_FRAISREEL_TYPEFRSREF, TAB_LIGNEFRAIS.LIGNEFRAIS_TYPEFRSREF, TB_FRAISDETAILS.FRSDETAILS_TYPEFRSREF
    /**
     * Number of kilometers (useful only if isKilometricExpense === true)
     * @type {Number}
     */
    numberOfKilometers: _emberData.default.attr('number'),
    // TAB_FRAISREEL.FRAISREEL_MONTANT, TAB_FRAIS.FRAIS_VALUE
    // default value because Back return `undefined` if `isKilometricExpense` BUT we need a default fragment
    // to change only its properties to keep rollback works!
    expenseType: (0, _attributes.fragment)('expensetype', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    /**************************************************************************/
    /* JSDoc - typedef ********************************************************/
    /**************************************************************************/
    /**
     * Expense Object definition with only one `id' property
     *
     * @typedef IdObj
     * @type {Object}
     * @property {string} id
     */
    /**************************************************************************/
    /* /JSDoc - typedef *******************************************************/
    /**************************************************************************/
    /**
     * @type {IdObj}
     */
    project: _emberData.default.attr({
      defaultValue: null
    }),
    /**
     * @type {IdObj}
     */
    delivery: _emberData.default.attr({
      defaultValue: null
    }),
    /**
     * @type {IdObj}
     */
    batch: _emberData.default.attr({
      defaultValue: null
    }),
    owner: _emberDataModelFragments.default.fragmentOwner(),
    // same as in exceptionaltime fragment
    selectedActivity: Ember.computed('project', 'delivery', 'batch', {
      get: function get() {
        var _this = this;
        var promise = this.get('owner.activities').then(function (activities) {
          var searchedSelectedID = getActivityIDFromExpense(_this);
          var selectedActivity;

          // if we do not have an activity yet (because we have no searchedSelectedID
          // get default activity
          if (typeof searchedSelectedID === 'undefined') {
            selectedActivity = (0, _activity.getDefaultActivity)(activities);
          } else {
            // if we have an searchedSelectedID, search the activity
            selectedActivity = activities.find(function (activity) {
              return activity.get('id') === searchedSelectedID;
            });
          }

          // if no default activity => error...
          if (typeof selectedActivity === 'undefined') {
            if (searchedSelectedID) {
              _logger.default.error("Model expense - GET selectedActivity CP - activity#".concat(searchedSelectedID, " not found in activities..."));
            } else {
              _logger.default.error('Model expense - GET selectedActivity CP - selectedActivity is undefined...');
            }
          }
          return selectedActivity;
        });
        return ObjProxy.create({
          promise: promise
        });
      },
      set: function set(_, newActivity, oldActivity) {
        var _this2 = this;
        var promise = this.get('owner.activities').then(function (activities) {
          var selectedActivity;
          if (typeof newActivity === 'string') {
            selectedActivity = activities.find(function (activity) {
              return activity.get('id') === newActivity;
            });
          } else if (newActivity instanceof _activity.Activity) {
            // search in activities to be sûr it a production activity and keep the same link to owner.activities obj
            selectedActivity = activities.find(function (activity) {
              return activity.get('id') === newActivity.get('id');
            });
          }
          if (typeof selectedActivity === 'undefined') {
            selectedActivity = oldActivity;
          } else {
            // update project, delivery & batch
            var _selectedActivity$get = selectedActivity.getProperties('type', 'project', 'delivery', 'batch'),
              type = _selectedActivity$get.type,
              project = _selectedActivity$get.project,
              delivery = _selectedActivity$get.delivery,
              batch = _selectedActivity$get.batch;
            _this2.setProperties({
              activityType: type,
              project: project ? {
                id: project.get('id'),
                reference: project.get('getReference')
              } : null,
              delivery: delivery ? {
                id: delivery.get('id')
              } : null,
              batch: batch ? {
                id: batch.get('id')
              } : null
            });
          }
          return selectedActivity;
        });
        return ObjProxy.create({
          promise: promise
        });
      }
    }),
    selectedExpenseType: Ember.computed('isKilometricExpense', 'expenseType', {
      get: function get() {
        var _this3 = this;
        var promise = this.get('owner.agency').then(function (agency) {
          var expenseTypes = agency.get('allExpenseTypes');
          var selectedExpenseType = expenseTypes.findBy('reference', _this3.get('expenseType.reference'));
          if (typeof selectedExpenseType === 'undefined') {
            // get first
            selectedExpenseType = expenseTypes.get('firstObject');
          }
          return selectedExpenseType;
        });
        return ObjProxy.create({
          promise: promise
        });
      },
      set: function set(_, newValue, oldValue) {
        var _this4 = this;
        // FIXME oldValue should be defined, we are doing something wrong
        if (typeof oldValue === 'undefined') {
          oldValue = this.get('expenseType');
        }
        var promise = this.get('owner.agency').then(function (agency) {
          return Ember.RSVP.Promise.resolve(oldValue).then(function (oldValue) {
            var expenseTypes = agency.get('allExpenseTypes');
            var selectedExpenseType;
            var oldValueReference = oldValue && oldValue.reference >= _expensetype.travelExpensesTypeReference ? oldValue.reference : null;
            var newValueReference = null;
            if (typeof newValue === 'number') {
              newValueReference = newValue;
              selectedExpenseType = expenseTypes.find(function (expenseType) {
                return expenseType.reference === newValue;
              });
            } else if (typeof newValue === 'string') {
              newValueReference = Number(newValue);
              var searchedRef = newValueReference;
              if (!isNaN(searchedRef)) {
                selectedExpenseType = expenseTypes.find(function (expenseType) {
                  return expenseType.reference === searchedRef;
                });
              }
            } else if (_typeof(newValue) === 'object') {
              newValueReference = newValue.reference;
              // search in expenseTypes to be sûr it an expenseType and keep the same link to owner.expenseTypes obj
              selectedExpenseType = expenseTypes.find(function (expenseType) {
                return expenseType.reference === newValueReference;
              });
            }
            if (typeof selectedExpenseType === 'undefined' && oldValue) {
              selectedExpenseType = oldValue;
            } else {
              var expenseType = _this4.get('expenseType');
              var propertiesToSet = {
                isKilometricExpense: false
              };

              // When expenseType is changed, we must keep the same value in the <input>
              // so 1 - we get the current value
              var currentValue = (oldValueReference === _expensetype.travelExpensesTypeReference ? _this4.get('numberOfKilometers') : _this4.get('originalAmountIncludingTax')) || _this4.get('originalTaxedAmount');
              // by default, the propName to set in this current value is:
              var field = 'originalAmountIncludingTax';
              if (selectedExpenseType.reference === _expensetype.travelExpensesTypeReference) {
                propertiesToSet.isKilometricExpense = true;
                // reset amountIncludingTax to 0
                propertiesToSet.amountIncludingTax = 0;
                // reset latestTaxedAmountValue to 0
                propertiesToSet.latestTaxedAmountValue = 0;

                // the propName to set in the current value if it's a travelExpenseType is:
                field = 'numberOfKilometers';
              } else {
                if ((oldValueReference === null || _this4.get('originalTaxedAmount') === 0) && newValueReference !== _expensetype.travelExpensesTypeReference) {
                  propertiesToSet.tax = selectedExpenseType.taxRate;
                }
              }

              //Keeping the <input> value
              propertiesToSet[field] = currentValue;
              expenseType.setProperties({
                name: selectedExpenseType.name,
                taxRate: selectedExpenseType.taxRate,
                reference: selectedExpenseType.reference,
                ocrcategory: selectedExpenseType.ocrcategory
              });
              // on prévient que la propriété à changé pour les computed('expenseType')
              _this4.notifyPropertyChange('expenseType');
              _this4.setProperties(propertiesToSet);
            }
            return selectedExpenseType;
          });
        });
        return ObjProxy.create({
          promise: promise
        });
      }
    }),
    agencyAmountIncludingTax: Ember.computed('isKilometricExpense', 'amountIncludingTax', 'owner.exchangeRateAgency', {
      get: function get() {
        var isKilometricExpense = this.get('isKilometricExpense');
        // see ATTRIBUTES TYPE CASTING at the beginning of this file
        var amountIncludingTax = Number(this.get('amountIncludingTax'));
        var exchangeRateAgency = Number(this.get('owner.exchangeRateAgency'));
        var exchangeRates = [];
        var agencyAmountIncludingTax = amountIncludingTax;

        // if (isKm OR (exchangeRateAgency === 1)) we return amountIncludingTax (no applyExchangeRates needed)
        if (!isKilometricExpense && exchangeRateAgency !== 1) {
          exchangeRates.push(1 / exchangeRateAgency);
          agencyAmountIncludingTax = (0, _financial.applyExchangeRates)(amountIncludingTax, exchangeRates);
        }
        return agencyAmountIncludingTax;
      },
      set: function set(key, value) {
        var agencyAmountIncludingTax = Number(value);
        var isKilometricExpense = this.get('isKilometricExpense');
        var exchangeRateAgency = Number(this.get('owner.exchangeRateAgency'));
        var exchangeRates = [];
        var amountIncludingTax = agencyAmountIncludingTax;

        // if (isKm OR (exchangeRateAgency === 1)) we return amountIncludingTax (no applyExchangeRates needed)
        if (!isKilometricExpense && exchangeRateAgency !== 1) {
          exchangeRates.push(exchangeRateAgency);
          amountIncludingTax = (0, _financial.applyExchangeRates)(agencyAmountIncludingTax, exchangeRates);
        }
        this.set('amountIncludingTax', amountIncludingTax);
        return agencyAmountIncludingTax;
      }
    }),
    originalAmountIncludingTax: Ember.computed('isKilometricExpense', 'amountIncludingTax', 'owner.exchangeRateAgency', 'exchangeRate', {
      get: function get() {
        var isKilometricExpense = this.get('isKilometricExpense');
        // see ATTRIBUTES TYPE CASTING at the beginning of this file
        var amountIncludingTax = Number(this.get('amountIncludingTax'));
        var exchangeRate = Number(this.get('exchangeRate'));
        var exchangeRateAgency = Number(this.get('owner.exchangeRateAgency'));
        var exchangeRates = [];
        var originalAmountIncludingTax = amountIncludingTax;
        if (!isKilometricExpense && (exchangeRateAgency !== 1 || exchangeRate !== 1)) {
          if (exchangeRateAgency !== 1) {
            exchangeRates.push(1 / exchangeRateAgency);
          }
          if (exchangeRate !== 1) {
            exchangeRates.push(1 / exchangeRate);
          }
          originalAmountIncludingTax = (0, _financial.applyExchangeRates)(amountIncludingTax, exchangeRates);
        }
        return originalAmountIncludingTax;
      },
      set: function set(key, value) {
        var originalAmountIncludingTax = Number(value);
        var isKilometricExpense = this.get('isKilometricExpense');
        // see ATTRIBUTES TYPE CASTING at the beginning of this file
        var exchangeRate = Number(this.get('exchangeRate'));
        var exchangeRateAgency = Number(this.get('owner.exchangeRateAgency'));
        var exchangeRates = [];
        var amountIncludingTax = originalAmountIncludingTax;
        if (!isKilometricExpense && (exchangeRateAgency !== 1 || exchangeRate !== 1)) {
          if (exchangeRateAgency !== 1) {
            exchangeRates.push(exchangeRateAgency);
          }
          if (exchangeRate !== 1) {
            exchangeRates.push(exchangeRate);
          }
          amountIncludingTax = (0, _financial.applyExchangeRates)(originalAmountIncludingTax, exchangeRates);
        }
        this.set('amountIncludingTax', amountIncludingTax);
        return originalAmountIncludingTax;
      }
    }),
    kilometricExpenseAmount: Ember.computed('isKilometricExpense', 'numberOfKilometers', 'owner.{ratePerKilometerType.amount,exchangeRateAgency}', function () {
      var isKilometricExpense = this.get('isKilometricExpense');
      // see ATTRIBUTES TYPE CASTING at the beginning of this file
      var exchangeRateAgency = Number(this.get('owner.exchangeRateAgency'));
      var ratePerKilometer = Number(this.get('owner.ratePerKilometerType.amount'));
      var numberOfKilometers = this.get('numberOfKilometers');
      var kilometricExpenseAmount = 0;
      if (isKilometricExpense) {
        if (isNaN(numberOfKilometers)) numberOfKilometers = 0;
        kilometricExpenseAmount = isNaN(ratePerKilometer) ? 0 : (0, _financial.applyExchangeRates)(numberOfKilometers * ratePerKilometer, [1 / exchangeRateAgency]);
      }
      return (0, _financial.roundBigDecimals)(kilometricExpenseAmount);
    }),
    isOutOfActivityPeriod: Ember.computed('selectedActivity.isFulfilled', 'startDate', function () {
      var _this$getProperties = this.getProperties('selectedActivity', 'startDate'),
        selectedActivity = _this$getProperties.selectedActivity,
        startDate = _this$getProperties.startDate;
      if (selectedActivity && selectedActivity.get('isFulfilled') && startDate) {
        var currentDate = (0, _moment.default)(this.get('owner.term'));
        var isOutOfActivityPeriod = !(startDate.isBetween(selectedActivity.get('startDate'), selectedActivity.get('endDate'), 'day', '[]') && startDate.isSame(currentDate, 'month'));
        return isOutOfActivityPeriod;
      } else {
        return false;
      }
    }).readOnly()
  });
});