define("boondmanager/models/formtemplate", ["exports", "boondmanager/models/base", "ember-data-copyable", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberDataCopyable, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FormTemplate = _base.default.extend(_emberDataCopyable.default, {
    title: _emberData.default.attr('string'),
    profile: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    mailTo: _emberData.default.attr('string'),
    mailObject: _emberData.default.attr('string'),
    mailBody: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    profileTypesOf: _emberData.default.attr('array'),
    numberOfQuestions: _emberData.default.attr('number'),
    questions: (0, _attributes.fragmentArray)('formquestionfragment'),
    agencies: _emberData.default.hasMany('agency', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    /*** COMPUTED ***/

    countConditions: Ember.computed('profileTypesOf', function () {
      var count = 0;
      if (this.get('profileTypesOf') && this.get('profileTypesOf').length) count++;
      return count ? count : '';
    }),
    hasSavedQuestions: Ember.computed('questions.[]', function () {
      var savedQuestions = this.get('questions').filter(function (question) {
        return question.originalID;
      });
      return savedQuestions && savedQuestions.length;
    }),
    questionsList: Ember.computed('questions.[]', function () {
      return this.get('questions').toArray();
    }).readOnly(),
    selectedMailTo: Ember.computed('mailTo', function () {
      return this.get('mailTo') ? this.get('mailTo') : undefined;
    }).readOnly()
  });
  FormTemplate.reopenClass({
    prefix: 'FRMTPL'
  });
  FormTemplate.reopen({
    prefix: Ember.computed(function () {
      return FormTemplate.prefix;
    }).readOnly()
  });
  var _default = _exports.default = FormTemplate;
});