define("boondmanager/models/gadget", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WIDGET_TYPE_TIMESREPORT = _exports.WIDGET_TYPE_SUMMARY = _exports.WIDGET_TYPE_SIGNED_TURNOVER_SIGNED_MARGIN = _exports.WIDGET_TYPE_SIGNED_PERIOD_TURNOVER_CHARGED = _exports.WIDGET_TYPE_SALES_FUNNEL = _exports.WIDGET_TYPE_RECRUITMENTS_FUNNEL = _exports.WIDGET_TYPE_PRODUCTION_TURNOVER_PRODUCTION_MARGIN = _exports.WIDGET_TYPE_PERIOD_TURNOVER_CHARGED_MARGIN_PERIOD_CHARGED = _exports.WIDGET_TYPE_OPPORTUNITIES_DISTRIBUTION = _exports.WIDGET_TYPE_MY_PERIODICALS_TARGETS = _exports.WIDGET_TYPE_EXPENSESREPORT = _exports.WIDGET_TYPE_DIGITAL_WORKPLACE = _exports.WIDGET_TYPE_CHARGED_VS_SIGNED_TURNOVER = _exports.WIDGET_TYPE_CHARGED_VS_PRODUCTION_VS_SIGNED_TURNOVER = _exports.WIDGET_TYPE_CHARGED_TURNOVER_CHARGED_MARGIN = _exports.WIDGET_TYPE_CHARGED_PERIOD_TURNOVER_PRODUCTION_TURNOVER_SIGNED_TURNOVER = _exports.WIDGET_TYPE_CANDIDATE_DISTRIBUTION = _exports.WIDGET_TYPE_ABSENCESREPORT = _exports.MODULE_TIMESREPORTS = _exports.MODULE_REPORTINGSYNTHESIS = _exports.MODULE_EXPENSESSSREPORTS = _exports.MODULE_ABSENCESSREPORTS = _exports.Gadget = _exports.GADGET_TYPE_WIDGET = _exports.GADGET_TYPE_METRIC = _exports.GADGET_TYPE_COLLECTION = _exports.ALLOWED_ELEMENTS_FOR_FILTERS = void 0;
  /**
   * Types d'éléments
   * @type {string}
   */
  var GADGET_TYPE_WIDGET = _exports.GADGET_TYPE_WIDGET = 'widget';
  var GADGET_TYPE_METRIC = _exports.GADGET_TYPE_METRIC = 'metric';
  var GADGET_TYPE_COLLECTION = _exports.GADGET_TYPE_COLLECTION = 'collection';

  /**
   * Types d'éléments de type 'widget'
   * @type {string}
   */
  var WIDGET_TYPE_TIMESREPORT = _exports.WIDGET_TYPE_TIMESREPORT = 'resourceTimesReports';
  var WIDGET_TYPE_EXPENSESREPORT = _exports.WIDGET_TYPE_EXPENSESREPORT = 'resourceExpensesReports';
  var WIDGET_TYPE_ABSENCESREPORT = _exports.WIDGET_TYPE_ABSENCESREPORT = 'resourceAbsencesReports';
  var WIDGET_TYPE_SUMMARY = _exports.WIDGET_TYPE_SUMMARY = 'summary';
  var WIDGET_TYPE_RECRUITMENTS_FUNNEL = _exports.WIDGET_TYPE_RECRUITMENTS_FUNNEL = 'recruitmentsFunnel';
  var WIDGET_TYPE_SALES_FUNNEL = _exports.WIDGET_TYPE_SALES_FUNNEL = 'salesFunnel';
  var WIDGET_TYPE_OPPORTUNITIES_DISTRIBUTION = _exports.WIDGET_TYPE_OPPORTUNITIES_DISTRIBUTION = 'opportunitiesDistribution';
  var WIDGET_TYPE_CANDIDATE_DISTRIBUTION = _exports.WIDGET_TYPE_CANDIDATE_DISTRIBUTION = 'candidatesDistribution';
  var WIDGET_TYPE_CHARGED_VS_SIGNED_TURNOVER = _exports.WIDGET_TYPE_CHARGED_VS_SIGNED_TURNOVER = 'chargedTurnoverSignedTurnover';
  var WIDGET_TYPE_CHARGED_VS_PRODUCTION_VS_SIGNED_TURNOVER = _exports.WIDGET_TYPE_CHARGED_VS_PRODUCTION_VS_SIGNED_TURNOVER = 'chargedTurnoverProductionTurnoverSignedTurnover';
  var WIDGET_TYPE_CHARGED_TURNOVER_CHARGED_MARGIN = _exports.WIDGET_TYPE_CHARGED_TURNOVER_CHARGED_MARGIN = 'chargedTurnoverChargedMargin';
  var WIDGET_TYPE_PRODUCTION_TURNOVER_PRODUCTION_MARGIN = _exports.WIDGET_TYPE_PRODUCTION_TURNOVER_PRODUCTION_MARGIN = 'productionTurnoverProductionMargin';
  var WIDGET_TYPE_SIGNED_TURNOVER_SIGNED_MARGIN = _exports.WIDGET_TYPE_SIGNED_TURNOVER_SIGNED_MARGIN = 'signedTurnoverSignedMargin';
  var WIDGET_TYPE_SIGNED_PERIOD_TURNOVER_CHARGED = _exports.WIDGET_TYPE_SIGNED_PERIOD_TURNOVER_CHARGED = 'signedPeriodTurnoverCharged';
  var WIDGET_TYPE_CHARGED_PERIOD_TURNOVER_PRODUCTION_TURNOVER_SIGNED_TURNOVER = _exports.WIDGET_TYPE_CHARGED_PERIOD_TURNOVER_PRODUCTION_TURNOVER_SIGNED_TURNOVER = 'chargedPeriodTurnoverProductionTurnoverSignedTurnover';
  var WIDGET_TYPE_PERIOD_TURNOVER_CHARGED_MARGIN_PERIOD_CHARGED = _exports.WIDGET_TYPE_PERIOD_TURNOVER_CHARGED_MARGIN_PERIOD_CHARGED = 'periodTurnoverChargedMarginPeriodCharged';
  var WIDGET_TYPE_DIGITAL_WORKPLACE = _exports.WIDGET_TYPE_DIGITAL_WORKPLACE = 'digitalWorkplace';
  var WIDGET_TYPE_MY_PERIODICALS_TARGETS = _exports.WIDGET_TYPE_MY_PERIODICALS_TARGETS = 'myPeriodicalsTargets';

  /**
   * Modules pour les redirections
   * @type {string}
   */
  var MODULE_REPORTINGSYNTHESIS = _exports.MODULE_REPORTINGSYNTHESIS = 'reportingSynthesis';
  var MODULE_TIMESREPORTS = _exports.MODULE_TIMESREPORTS = 'timesReports';
  var MODULE_ABSENCESSREPORTS = _exports.MODULE_ABSENCESSREPORTS = 'absencesReports';
  var MODULE_EXPENSESSSREPORTS = _exports.MODULE_EXPENSESSSREPORTS = 'expensesReports';

  /**
   * Liste des elements pour lesquels des filtres sont configurables
   * @type {(string)[]}
   */
  var ALLOWED_ELEMENTS_FOR_FILTERS = _exports.ALLOWED_ELEMENTS_FOR_FILTERS = [WIDGET_TYPE_RECRUITMENTS_FUNNEL, WIDGET_TYPE_SALES_FUNNEL, WIDGET_TYPE_CANDIDATE_DISTRIBUTION, WIDGET_TYPE_OPPORTUNITIES_DISTRIBUTION, WIDGET_TYPE_CHARGED_VS_SIGNED_TURNOVER, WIDGET_TYPE_CHARGED_VS_PRODUCTION_VS_SIGNED_TURNOVER, WIDGET_TYPE_SUMMARY, WIDGET_TYPE_CHARGED_TURNOVER_CHARGED_MARGIN, WIDGET_TYPE_PRODUCTION_TURNOVER_PRODUCTION_MARGIN, WIDGET_TYPE_SIGNED_TURNOVER_SIGNED_MARGIN, WIDGET_TYPE_SIGNED_PERIOD_TURNOVER_CHARGED, WIDGET_TYPE_CHARGED_PERIOD_TURNOVER_PRODUCTION_TURNOVER_SIGNED_TURNOVER, WIDGET_TYPE_PERIOD_TURNOVER_CHARGED_MARGIN_PERIOD_CHARGED];
  var Gadget = _exports.Gadget = _base.default.extend({
    /*** ATTRIBUTES ***/
    gadgetType: _emberData.default.attr('string'),
    widgetType: _emberData.default.attr('string'),
    module: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    canReadGadget: _emberData.default.attr('boolean'),
    canWriteGadget: _emberData.default.attr('boolean'),
    gadgetDependsOn: _emberData.default.belongsTo('basegadget'),
    defaultTitle: Ember.computed('title', 'gadgetType', 'widgetType', 'module', 'i18n._locale', {
      get: function get() {
        if (this.get('title')) return this.get('title');
        switch (this.get('gadgetType')) {
          case GADGET_TYPE_WIDGET:
            return this.get('gadgetDependsOn').get('chartTitle');
          case GADGET_TYPE_COLLECTION:
            return this.get('settingsService').bm.dashboard.collections.modules.findBy('id', this.get('module')).value;
          case GADGET_TYPE_METRIC:
            return this.get('settingsService').bm.dashboard.metrics.modules.findBy('id', this.get('module')).value;
        }
      },
      set: function set(_, value) {
        return value;
      }
    })
  });
  Gadget.reopenClass({
    prefix: 'GDGT'
  });
  Gadget.reopen({
    prefix: Ember.computed(function () {
      return Gadget.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Gadget;
});