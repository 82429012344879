define("boondmanager/models/appresourceplannerresource", ["exports", "boondmanager/models/resource", "boondmanager/models/project", "boondmanager/models/delivery", "boondmanager/models/batch", "boondmanager/utils/logger", "moment", "boondmanager/utils/financial", "ember-copy", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "ember-data", "ember-data-copyable", "boondmanager/utils/activity", "boondmanager/utils/bm"], function (_exports, _resource, _project, _delivery, _batch, _logger, _moment, _financial, _emberCopy, _fragment, _attributes, _emberData, _emberDataCopyable, _activity, _bm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getActivityIDFromPlannedTime = _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  /**
   * To get Activity ID from a plannedTime fragment
   *
   * plannedTime is a fragment so its properties `project`, `delivery` and
   * `batch` are not Ember `DS.Model` but "simple" POJO ! So they do not
   * have CP like `getReference` :/
   *
   * Activity ID is equal to plannedTime's workUnitType.activityType if its type is
   * not 'production', else it's project's internal + space + ref delivery's internal ref
   * + batch's internal ref (optional). But we can not use 'internalReference' CP :'( So
   * we build it...
   *
   * cf GET Activity.id CP.
   *
   * @param  {PlannedTime} objTime
   * @return {String|undefined}
   */
  var getActivityIDFromPlannedTime = _exports.getActivityIDFromPlannedTime = function getActivityIDFromPlannedTime(objTime) {
    var type = objTime.get('workUnitType.activityType');
    var id;
    switch (type) {
      case _activity.TYPE_PRODUCTION:
        {
          var _objTime$getPropertie = objTime.getProperties('project', 'delivery', 'batch'),
            project = _objTime$getPropertie.project,
            delivery = _objTime$getPropertie.delivery,
            batch = _objTime$getPropertie.batch;
          if (project && delivery) {
            id = "".concat(_project.default.prefix).concat(objTime.get('project.id'), " ").concat(_delivery.default.prefix).concat(objTime.get('delivery.id'));
            if (batch) {
              id += " ".concat(_batch.default.prefix).concat(objTime.get('batch.id'));
            }
          }
          break;
        }
      default:
        id = type;
    }
    return id;
  };

  /**
   * Fake Regular Time (FRT) object
   *
   * {{#crossLink "PlannedTime"}}plannedTime{{/crossLink}} are fragments. But we need
   * FRT objects to represent empty plannedTime in our
   * {{#crossLink "PlannedTimesRow/plannedtimes"}}`plannedtimes` array{{/crossLink}}
   * defined in each {{#crossLink "PlannedTimesRow"}}PlannedTimes row{{/crossLink}}
   *
   * @class FakePlannedTime
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var FakePlannedTime = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Flag to identify this object as a "fake"
     *
     * @property isFake
     * @type {Boolean}
     * @default  true
     * @readOnly
     */
    isFake: Ember.computed(function () {
      return true;
    }).readOnly(),
    /**
     * Start date
     *
     * @property startDate
     * @type {Moment}
     */

    /**
     * Value
     *
     * @property startDate
     * @type {Moment}
     * @default  ''
     * @readOnly
     */
    value: Ember.computed(function () {
      return '';
    }).readOnly(),
    /**
     * Return a copy of this FakePlannedTime (FRT) object
     *
     *     const FRTCopy = Ember.copy(originalFRT);
     *
     * @method copy
     * @return {FakePlannedTime} copy of this FRT object
     * @private
     */
    copy: function copy() {
      return FakePlannedTime.create({
        startDate: this.get('startDate').clone()
      });
    },
    /**
     * Return a POJO representation
     *
     * The POJO has 'isFake', 'startDate' and 'value' properties.
     *
     * CAUTION: `startDate` is not a moment clone! It's the **same** Moment
     *          object present in this FRT object! If you want a POJO as 'deep'
     *          copy, use `POJO = FRTObj.copy().toJSON()` to not share the same
     *          Moment instance beetwen `POJO.startDate` and `FRTObj.startDate`
     *
     * @return {Object}
     */
    toJSON: function toJSON() {
      return this.getProperties('isFake', 'startDate', 'value');
    }
  });

  /**
   * Regular Time Row (RTR)
   *
   * Independants props :
   *   * row
   *   * fillstate
   *
   * Interdependant props (init order) :
   *   1. term
   *   2. activities
   *   3. workUnitTypes
   *   3. selectedActivity ( -> activities & workUnitTypes)
   *   4. selectedWorkUnitType ( -> selectedActivity & workUnitTypes)
   *
   * @class PlannedTimesRow
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var PlannedTimesRow = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Term
     *
     * @property term
     * @type {Moment}
     */
    /**
     * Comment
     *
     * @property comment
     * @type {String}
     */

    /**
     * Row Number
     *
     * if < 0, it's a new row
     *
     * @property row
     * @type {Number}
     * @default -1
     */
    row: Ember.computed(function () {
      return -1;
    }),
    /**
     * Row's fill state
     *
     * To know if all plannedTimes of this row are filled. Used when user click
     * on "fill" button of the row
     *
     * @property fillState
     * @type {Boolean}
     * @default false
     */
    fillState: Ember.computed(function () {
      return false;
    }),
    /**
     * Activities
     *
     * List of all activities
     *
     * @property activities
     * @type {Activity[]}
     */

    /**
     * Work Unit Types (WUTs) Object
     *
     * `workUnitTypes` is a JS POJO with 3 properties :
     *   - 'absence'   : array of agency's absence workUnitTypes
     *   - 'internal'  : array of agency's internal workUnitTypes
     *   - 'opportunity'  : array of agency's opportunity workUnitTypes
     *   - 'production': array of agency's production workUnitTypes
     *
     * @property workUnitTypes
     * @type {Object}
     */

    /**
     * Selected Activity
     *
     * When selected activity is updated, the
     * {{#crossLink "PlannedTimesRow/selectedWorkUnitType}}selected WUT{{/crossLink}}
     * is reset to its default value. Each
     * {{#crossLink "PlannedTimesRow/plannedTimes}}plannedTimes{{/crossLink}}
     * item is then updated too.
     *
     * @see PlannedTimesRow.activities
     *
     * @property selectedActivity
     * @type {Activity}
     * @default if exists, first production activity, else first activity with first non empty WUT
     */
    selectedActivity: Ember.computed({
      get: function get() {
        // by default, send the first production activity, else first activity in list
        var defaultActivity = this.get('activities').findBy('type', _activity.TYPE_PRODUCTION);
        return defaultActivity ? defaultActivity : this.get('activities')[0];
      },
      set: function set(_, newActivity, oldActivity) {
        // here, newActivity could be an Activity object or a simple POJO with an `id` property
        var searchedID = Ember.get(newActivity, 'id');
        var selectedActivity = this.get('activities').find(function (activity) {
          return activity.get('id') === searchedID;
        }) || oldActivity;
        if (typeof selectedActivity === 'undefined') {
          _logger.default.warn('Model appresourceplannerresource - SET selectedActivity CP - newActivity not find in activities array!', newActivity, this.get('activities'));
        } else {
          var _selectedActivity$get = selectedActivity.getProperties('type', 'project', 'delivery', 'batch', 'opportunity'),
            type = _selectedActivity$get.type,
            project = _selectedActivity$get.project,
            delivery = _selectedActivity$get.delivery,
            batch = _selectedActivity$get.batch,
            opportunity = _selectedActivity$get.opportunity;
          var propertiesToSet = {};
          switch (type) {
            case _activity.TYPE_ABSENCE:
              propertiesToSet.project = null;
              propertiesToSet.delivery = null;
              propertiesToSet.batch = null;
              propertiesToSet.opportunity = null;
              break;
            case _activity.TYPE_INTERNAL:
              propertiesToSet.project = null;
              propertiesToSet.delivery = null;
              propertiesToSet.batch = null;
              propertiesToSet.opportunity = null;
              break;
            case _activity.TYPE_OPPORTUNITY:
              propertiesToSet.project = null;
              propertiesToSet.delivery = null;
              propertiesToSet.batch = null;
              propertiesToSet.opportunity = null;
              break;
            default:
              propertiesToSet.project = {
                id: Ember.get(project, 'id'),
                reference: Ember.get(project, 'getReference'),
                canReadProject: Ember.get(project, 'canReadProject')
              };
              propertiesToSet.delivery = {
                id: Ember.get(delivery, 'id')
              };
              propertiesToSet.batch = batch ? {
                id: Ember.get(batch, 'id')
              } : null;
              propertiesToSet.opportunity = opportunity ? {
                id: Ember.get(opportunity, 'id')
              } : null;
          }
          var selectedWorkUnitType = this.get("workUnitTypes.".concat(type))[0];
          this.get('plannedTimes').forEach(function (plannedTime) {
            if ((0, _fragment.isFragment)(plannedTime)) {
              propertiesToSet.workUnitType = (0, _emberCopy.copy)(selectedWorkUnitType);
              plannedTime.setProperties(propertiesToSet);
            }
          });
        }
        return selectedActivity;
      }
    }),
    /**
     * Selected Work Unit Type (WUT)
     *
     * Selected WUT is reset to its default value when
     * {{#crosslink "PlannedTimesRow/selectedActivity}}selected activity{{/crossLink}}
     * is changed. Each {{#crossLink "PlannedTimesRow/plannedTimes}}plannedTimes{{/crossLink}}
     * item is then updated too.
     *
     * @property selectedWorkUnitType
     * @type {Object}
     * @default  first WUT object of the selected activity WUTs
     */
    selectedWorkUnitType: Ember.computed('selectedActivity', {
      get: function get() {
        var workUnitType = this.get('selectedActivity.type');
        var selectedWorkUnitType = this.get("workUnitTypes.".concat(workUnitType))[0];
        return selectedWorkUnitType;
      },
      set: function set(key, newWUT) {
        this.get('plannedTimes').forEach(function (plannedTime) {
          if ((0, _fragment.isFragment)(plannedTime)) {
            plannedTime.set('workUnitType', (0, _emberCopy.copy)(newWUT, true));
          }
        });
        return newWUT;
      }
    }),
    workUnitTypesOptions: Ember.computed('selectedActivity', function () {
      var options = this.get('workUnitTypes')[this.get('selectedActivity.type')];
      // check for selected option which not exists in list to add it
      if (this.get('selectedWorkUnitType')) {
        var selectedWorkUnitTypeExists = options.findBy('reference', this.get('selectedWorkUnitType.reference'));
        if (!selectedWorkUnitTypeExists) {
          options.pushObject(this.get('selectedWorkUnitType'));
        }
      }
      return options;
    }).readOnly(),
    /**
     * Regular times by day (0-based).
     *
     * Array's length = number of days in the month (cf term)
     *
     * @property plannedTimes
     * @uses  _regularTimes
     * @type {(Fake)PlannedTime[]}
     * @readOnly
     */
    plannedTimes: Ember.computed('term', function () {
      var term = this.get('term');
      var daysInMonth = term.daysInMonth();
      var plannedTimes = Ember.A();
      for (var i = 0; i < daysInMonth; i++) {
        plannedTimes.push(FakePlannedTime.create({
          startDate: term.clone().date(i + 1)
        }));
      }
      return plannedTimes;
    }).readOnly(),
    // TODO code en commun avec la CP `plannedTimes` (cf le switch)
    getNormalizedPlannedTimePOJO: function getNormalizedPlannedTimePOJO(plannedTime) {
      var normalizedPOJO = {};
      if (plannedTime && ((0, _fragment.isFragment)(plannedTime) || typeof plannedTime.toJSON === 'function')) {
        normalizedPOJO = (0, _emberCopy.copy)(plannedTime).toJSON();
      }
      var _this$getProperties = this.getProperties('row', 'selectedActivity'),
        row = _this$getProperties.row,
        selectedActivity = _this$getProperties.selectedActivity;
      var _selectedActivity$get2 = selectedActivity.getProperties('type', 'project', 'delivery', 'batch', 'opportunity'),
        type = _selectedActivity$get2.type,
        project = _selectedActivity$get2.project,
        delivery = _selectedActivity$get2.delivery,
        batch = _selectedActivity$get2.batch,
        opportunity = _selectedActivity$get2.opportunity;
      normalizedPOJO.row = row;
      switch (type) {
        case _activity.TYPE_ABSENCE:
          normalizedPOJO.delivery = null;
          normalizedPOJO.batch = null;
          normalizedPOJO.project = null;
          normalizedPOJO.opportunity = null;
          break;
        case _activity.TYPE_INTERNAL:
          normalizedPOJO.delivery = null;
          normalizedPOJO.batch = null;
          normalizedPOJO.project = null;
          normalizedPOJO.opportunity = null;
          break;
        case _activity.TYPE_OPPORTUNITY:
          normalizedPOJO.project = null;
          normalizedPOJO.delivery = null;
          normalizedPOJO.batch = null;
          normalizedPOJO.opportunity = null;
          break;
        default:
          normalizedPOJO.project = {
            id: Ember.get(project, 'id'),
            reference: Ember.get(project, 'getReference') // used in totalsByActivity CP
          };

          normalizedPOJO.delivery = {
            id: Ember.get(delivery, 'id')
          };
          normalizedPOJO.batch = batch ? {
            id: Ember.get(batch, 'id')
          } : null;
          normalizedPOJO.opportunity = opportunity ? {
            id: Ember.get(opportunity, 'id')
          } : null;
      }
      normalizedPOJO.workUnitType = (0, _emberCopy.copy)(this.get('selectedWorkUnitType'));
      normalizedPOJO.duration = 0;
      if (typeof normalizedPOJO.startDate === 'undefined') {
        _logger.default.error('appresourceplannerresource model - normalizePlannedTime - startDate undefined', normalizedPOJO);
        normalizedPOJO.startDate = (0, _moment.default)();
      }
      if (typeof normalizedPOJO.value !== 'undefined') {
        delete normalizedPOJO.value;
      }
      return normalizedPOJO;
    },
    /**
     * Del a plannedTime = Replace a plannedTime with  a fakePlannedTime
     *
     * @method delPlannedTime
     * @param {plannedTime} plannedTime the plannedTime to replace
     */
    delPlannedTime: function delPlannedTime(plannedTime) {
      if ((0, _fragment.isFragment)(plannedTime)) {
        // Replace Ember fragment by JS object
        var dayIndex = plannedTime.get('startDate').date() - 1;
        var plannedTimes = this.get('plannedTimes');

        // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
        plannedTimes[dayIndex] = FakePlannedTime.create({
          startDate: plannedTime.get('startDate').clone()
        });
        // Tell plannedTimes that it has changed for template update
        plannedTimes.arrayContentDidChange(dayIndex);
      }
    },
    creation: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    edition: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    isReadonly: Ember.computed('edition', 'creation', function () {
      return !this.get('edition') && !this.get('creation');
    }).readOnly(),
    totalActivity: Ember.computed('plannedTimes.@each.{duration,value}', function () {
      return this.get('plannedTimes').reduce(function (totals, plannedTime) {
        var duration = parseFloat(plannedTime.get('duration')) || 0;
        totals.days += duration;
        totals.workUnits += (0, _financial.roundAtPrecision)(plannedTime.get('value'), 3);
        return totals;
      }, {
        days: 0,
        workUnits: 0
      });
    }).readOnly(),
    /**
     * Start edition on fixed expense row
     * @param plannedTimeRow
     * @private
     */
    startPlannedTimeRowEdition: function startPlannedTimeRowEdition() {
      // backup current values to restore them if user click on cancel button
      var plannedTimeRowriginalValues = {
        selectedActivity: this.get('selectedActivity.id'),
        selectedWorkUnitType: this.get('selectedWorkUnitType.reference'),
        plannedTimes: []
      };
      var plannedTimes = this.get('plannedTimes');
      if (plannedTimes) {
        plannedTimes.forEach(function (plannedTime, dayIndex) {
          plannedTimeRowriginalValues.plannedTimes.push({
            dayIndex: dayIndex,
            value: plannedTime.value
          });
        });
      }
      this.set('originalValues', plannedTimeRowriginalValues);
      this.set('edition', true);
    },
    /**
     * Confirm edition validation on fixed expense row
     * @param plannedTimeRow
     * @private
     */
    confirmPlannedTimeRowEdition: function confirmPlannedTimeRowEdition() {
      // clear original values and do nothing, if edition is confirmed, current values are now the last setted values
      this.set('originalValues', undefined);
      this.set('edition', false);
      this.set('creation', false);
    },
    /**
     * Update comment for planned times row
     * @param comment
     */
    updateComment: function updateComment(comment) {
      var plannedTimes = this.get('plannedTimes');
      plannedTimes.forEach(function (plannedTime) {
        if (plannedTime.get('duration')) {
          plannedTime.set('comment', comment);
        }
      });
      this.set('comment', comment);
    },
    /**
     * Return a copy of this PlannedTimesRow (RTR) object
     *
     *     const RTRCopy = Ember.copy(originalRTR);
     *
     * @method copy
     * @return {PlannedTimesRow} copy of this RTR object
     * @private
     */
    copy: function copy(deep) {
      var _this$getProperties2 = this.getProperties('term', 'row', 'fillState', 'Activities', 'WorkUnitTimes', 'selectedActivity', 'selectedWorkUnitType'),
        term = _this$getProperties2.term,
        row = _this$getProperties2.row,
        fillState = _this$getProperties2.fillState,
        Activities = _this$getProperties2.Activities,
        WorkUnitTimes = _this$getProperties2.WorkUnitTimes,
        selectedActivity = _this$getProperties2.selectedActivity,
        selectedWorkUnitType = _this$getProperties2.selectedWorkUnitType;
      return PlannedTimesRow.create({
        term: term.clone(),
        row: row,
        fillState: fillState,
        Activities: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(Activities, deep) : Activities,
        WorkUnitTimes: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(WorkUnitTimes, deep) : WorkUnitTimes,
        selectedActivity: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(selectedActivity, deep) : selectedActivity,
        selectedWorkUnitType: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(selectedWorkUnitType, deep) : selectedWorkUnitType
      });
    }
  });
  var AppResourcePlannerResource = _resource.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'resource', 'projects', 'deliveries']
    }),
    /** SERVICES */
    i18n: Ember.inject.service(),
    /** PROPERTIES */
    term: _emberData.default.attr('date'),
    validationDate: _emberData.default.attr('date'),
    signedTime: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    takenTimeToJ: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    plannedTimeFromJ: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    takenTimeInWorkUnitsToJ: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    plannedTimeInWorkUnitsFromJ: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    /** RELATIONSHIPS */
    projects: _emberData.default.hasMany('appresourceplannerproject', {
      inverse: null
    }),
    deliveries: _emberData.default.hasMany('delivery', {
      inverse: null
    }),
    batches: _emberData.default.hasMany('batch', {
      inverse: null
    }),
    validator: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    /**
     * List of planned times
     * @type {PlannedTime[]}
     */
    plannedTimes: (0, _attributes.fragmentArray)('plannedtimeresource'),
    /**
     * List of absences
     * @type {RegularTime[]}
     */
    absencesTimes: (0, _attributes.fragmentArray)('plannedtimeresource'),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'resource',
    restBudgetToTake: Ember.computed('signedTime', 'takenTimeToJ', function () {
      var value = this.get('signedTime') - this.get('takenTimeToJ');
      return isNaN(value) ? 0 : value;
    }),
    restBudgetToPlan: Ember.computed('restBudgetToTake', 'plannedTimeFromJ', function () {
      var value = this.get('restBudgetToTake') - this.get('plannedTimeFromJ');
      return isNaN(value) ? 0 : value;
    }).readOnly(),
    signedTimeInWorkUnits: Ember.computed('signedTime', 'currentWorkUnitRate', function () {
      var value = this.get('signedTime') * this.get('currentWorkUnitRate');
      return isNaN(value) ? 0 : value;
    }).readOnly(),
    restBudgetInWorkUnitsToTake: Ember.computed('signedTimeInWorkUnits', 'takenTimeInWorkUnitsToJ', function () {
      var value = this.get('signedTimeInWorkUnits') - this.get('takenTimeInWorkUnitsToJ');
      return isNaN(value) ? 0 : value;
    }).readOnly(),
    restBudgetInWorkUnitsToPlan: Ember.computed('restBudgetInWorkUnitsToTake', 'plannedTimeInWorkUnitsFromJ', function () {
      var value = this.get('restBudgetInWorkUnitsToTake') - this.get('plannedTimeInWorkUnitsFromJ');
      return isNaN(value) ? 0 : value;
    }).readOnly(),
    totalsByDay: Ember.computed('plannedTimes.@each.{startDate,value}', function () {
      var plannedTimes = this.get('plannedTimes');
      return plannedTimes.reduce(function (totals, plannedTime) {
        var dayNumber = (0, _moment.default)(plannedTime.get('startDate')).format('D');
        totals[dayNumber] = totals[dayNumber] || 0;
        totals[dayNumber] += (0, _financial.roundAtPrecision)(plannedTime.get('value') || 0, 3);
        return totals;
      }, {});
    }).readOnly(),
    totalDays: Ember.computed('plannedTimes.@each.duration', function () {
      var plannedTimes = this.get('plannedTimes');
      var totals = 0;
      plannedTimes.forEach(function (plannedTime) {
        totals += plannedTime.get('duration');
      });
      return (0, _financial.roundAtPrecision)(totals, 3);
    }),
    /**
     * Work Unit Rate
     *
     * @property workUnitRate
     * @observes resource.workUnitRate, agency.workUnitRate
     * @return {Number}
     */
    currentWorkUnitRate: Ember.computed('workUnitRate', 'agency.workUnitRate', function () {
      var workUnitRate;
      var resourceWorkUnitRate = this.get('workUnitRate');
      if (resourceWorkUnitRate && resourceWorkUnitRate !== _bm.NOT_USED) {
        workUnitRate = resourceWorkUnitRate;
      } else {
        workUnitRate = this.get('agency.workUnitRate');
      }
      return parseFloat(workUnitRate);
    }).readOnly(),
    totalsByPlannedActivity: Ember.computed('plannedTimes.@each.{value,workUnitType,project,delivery,batch,opportunity}', function () {
      var _this = this;
      var promise = this.get('companyByProjectID').then(function (companyByProjectID) {
        var plannedTotals = _this.get('plannedTimes').reduce(function (totals, plannedTime) {
          var reference = plannedTime.get('workUnitType.reference');
          var workUnitType = plannedTime.get('workUnitType.name');
          var activityType = plannedTime.get('workUnitType.activityType');
          var project = plannedTime.get('project');
          var index = project && project.id > 0 ? "".concat(project.id, " ").concat(reference) : reference;
          var duration = parseFloat(plannedTime.get('duration'));
          // on arrondi la valeur à une précision 3 telle qu'utilisée sur les templates
          var workUnits = (0, _financial.roundAtPrecision)(plannedTime.get('value'), 3);
          var projectName;
          if (project && project.id > 0) {
            if (project.reference === '') {
              projectName = "".concat(_project.default.prefix).concat(project.id);
            } else if (companyByProjectID[project.id]) {
              projectName = "".concat(project.reference, " - ").concat(companyByProjectID[project.id].get('name'));
            } else {
              projectName = "".concat(project.reference);
            }
          } else {
            projectName = '';
          }
          if (!totals[activityType].projects.has(index)) {
            totals[activityType].projects.set(index, {
              days: 0,
              workUnits: 0,
              type: workUnitType,
              name: projectName
            });
          }
          totals.days = (0, _financial.add)(totals.days, duration);
          totals.workUnits = (0, _financial.add)(totals.workUnits, workUnits);
          totals[activityType].days = (0, _financial.add)(totals[activityType].days, duration);
          totals[activityType].workUnits = (0, _financial.add)(totals[activityType].workUnits, workUnits);
          totals[activityType].projects.get(index).days = (0, _financial.add)(totals[activityType].projects.get(index).days, duration);
          totals[activityType].projects.get(index).workUnits = (0, _financial.add)(totals[activityType].projects.get(index).workUnits, workUnits);
          return totals;
        }, {
          days: 0,
          workUnits: 0,
          production: {
            days: 0,
            workUnits: 0,
            projects: new Map()
          },
          absence: {
            days: 0,
            workUnits: 0,
            projects: new Map()
          },
          internal: {
            days: 0,
            workUnits: 0,
            projects: new Map()
          },
          opportunity: {
            days: 0,
            workUnits: 0,
            projects: new Map()
          }
        });

        // calcul du workUnits dans un second temps pour minimiser l'erreur de précision
        plannedTotals.workUnits = plannedTotals.workUnits.toFixed(6);
        plannedTotals.days = plannedTotals.days.toFixed(6);
        [_activity.TYPE_PRODUCTION, _activity.TYPE_ABSENCE, _activity.TYPE_INTERNAL, _activity.TYPE_OPPORTUNITY].forEach(function (activityType) {
          plannedTotals[activityType].days = parseFloat(plannedTotals[activityType].days.toFixed(6));
          plannedTotals[activityType].projects.forEach(function (project) {
            project.days = parseFloat(project.days.toFixed(6));
          });
        });
        plannedTotals.production.projects = _this.sortMapByType(plannedTotals.production.projects);
        plannedTotals.absence.projects = _this.sortMapByType(plannedTotals.absence.projects);
        plannedTotals.internal.projects = _this.sortMapByType(plannedTotals.internal.projects);
        plannedTotals.opportunity.projects = _this.sortMapByType(plannedTotals.opportunity.projects);
        return Ember.assign({}, plannedTotals);
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**************************************************************************/
    /*** ACTIVITIES ***********************************************************/
    /**************************************************************************/
    /**
     * Absence activity
     *
     * @property absenceActivity
     * @return {Activity}
     * @readonly
     */
    absenceActivity: Ember.computed('term', function () {
      var owner = Ember.getOwner(this);
      var Activity = owner.factoryFor('bm:activity');
      var activityValue = {
        type: _activity.TYPE_ABSENCE,
        term: this.get('term')
      };
      return Activity.create(activityValue);
    }).readOnly(),
    /**
     * Internal activity
     *
     * @property internalActivity
     * @return {Activity}
     * @readonly
     */
    internalActivity: Ember.computed('term', function () {
      var owner = Ember.getOwner(this);
      var Activity = owner.factoryFor('bm:activity');
      var activityValue = {
        type: _activity.TYPE_INTERNAL,
        term: this.get('term')
      };
      return Activity.create(activityValue);
    }).readOnly(),
    /**
     * Opportunity activity
     *
     * @property opportunityActivity
     * @return {Activity}
     * @readonly
     */
    opportunityActivity: Ember.computed('term', function () {
      var owner = Ember.getOwner(this);
      var Activity = owner.factoryFor('bm:activity');
      var activityValue = {
        type: _activity.TYPE_OPPORTUNITY,
        term: this.get('term')
      };
      return Activity.create(activityValue);
    }).readOnly(),
    /**
     * Production activities
     *
     * @property productionActivities
     * @observes term, projects and workUnitTypes.production
     * @return {Promise}
     * @filfil {Activity[]}
     * @readonly
     */
    productionActivities: Ember.computed('term', 'projects', 'projects.@each.{batches,deliveries,opportunity}', function () {
      var _this2 = this;
      var term = this.get('term');
      // create a promise resolved when we get all projects' companies & batches & deliveries
      var promise = new Promise(function (resolve, reject) {
        var activities = [];
        _this2.get('projects').then(function (projects) {
          var owner = Ember.getOwner(_this2);
          var Activity = owner.factoryFor('bm:activity');

          // allProjectsPromises is an array of Promises
          var allProjectsPromises = projects.map(function (project) {
            var projectPromises = {
              company: project.get('company'),
              batches: project.get('batches'),
              deliveries: project.get('deliveries'),
              opportunity: project.get('opportunity')
            };
            return Ember.RSVP.hash(projectPromises, "project ".concat(project.get('getReference'), " - activities - get company, batches & deliveries")).then(function (_ref) {
              var company = _ref.company,
                batches = _ref.batches,
                deliveries = _ref.deliveries,
                opportunity = _ref.opportunity;
              // push activity id-value obj in `activities` array
              deliveries.forEach(function (delivery) {
                var activity = {
                  type: _activity.TYPE_PRODUCTION,
                  term: term,
                  project: project,
                  delivery: delivery,
                  opportunity: opportunity
                };
                if (company) {
                  activity.company = company;
                }
                if (batches && batches.length) {
                  batches.forEach(function (batch) {
                    activity.batch = batch;
                    activities.push(Activity.create(activity));
                  });
                } else {
                  activities.push(Activity.create(activity));
                }
              });

              // on ajoute l'activité qui n'existerait plus, par ex une saisie sur une presta sans lot alors que la presta actuelle possede des lots
              _this2.get('plannedTimes').filter(function (time) {
                return Ember.get(time, 'project.id') === Ember.get(project, 'id');
              }).forEach(function (time) {
                var activityExists = activities.find(function (item) {
                  return Ember.get(time, 'project.id') && item.type === _activity.TYPE_PRODUCTION && Ember.get(item, 'project.id') === Ember.get(time, 'project.id') && Ember.get(item, 'delivery.id') === Ember.get(time, 'delivery.id') && Ember.get(item, 'batch.id') === Ember.get(time, 'batch.id') && Ember.get(item, 'opportunity.id') === Ember.get(time, 'opportunity.id');
                });
                if (!activityExists) {
                  var deliveryID = Ember.get(time, 'delivery.id');
                  var delivery = null;
                  if (deliveryID) {
                    // récupération du model dans le store (création ou mise à jour)
                    delivery = _this2.store.push({
                      data: {
                        id: deliveryID,
                        type: 'delivery',
                        attributes: {
                          title: Ember.get(time, 'delivery.title'),
                          startDate: Ember.get(time, 'delivery.startDate'),
                          endDate: Ember.get(time, 'delivery.endDate')
                        }
                      }
                    });
                  }
                  var activity = {
                    type: _activity.TYPE_PRODUCTION,
                    term: term,
                    project: project,
                    delivery: delivery,
                    opportunity: opportunity
                  };
                  if (company) {
                    activity.company = company;
                  }
                  var batch = Ember.get(time, 'batch');
                  if (batch) {
                    activity.batch = batch;
                  }
                  activities.push(Activity.create(activity));
                }
              });
            });
          });
          Ember.RSVP.all(allProjectsPromises, "All ".concat(projects.length, " projects promises")).then(function () {
            resolve(activities);
          }, function (reason) {
            reject(reason);
          });
        }, function (reason) {
          reject(reason);
        });
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**
     * Activities
     *
     * Concatenation of *Activities
     *
     * @property activities
     * @observes productionActivities, absenceActivity, internalActivity, productionActivities
     * @return {Activity[]}
     * @readonly
     */
    activities: Ember.computed('productionActivities', 'absenceActivity', 'internalActivity', 'opportunityActivity', function () {
      var absence = this.get('absenceActivity');
      var internal = this.get('internalActivity');
      var opportunity = this.get('opportunityActivity');
      var promise = this.get('productionActivities').then(function (production) {
        var activities = [];
        activities.push(absence, internal, opportunity);
        return activities.concat(production);
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**************************************************************************/
    /** WORK UNIT TYPES *******************************************************/
    /**************************************************************************/
    /**
     * WUTs Object
     *
     * @todo Update docbloc when agency.workUnitTypes will be a WUT fragment!
     *
     * @typedef {Object} WorkUnitType
     * @property {Number} reference
     * @property {String} name
     * @property {String} activityType
     *
     * @typedef {Object} WorkUnitTypes
     * @property {WorkUnitType[]} absence
     * @property {WorkUnitType[]} internal
     * @property {WorkUnitType[]} production
     * @property {WorkUnitType[]} exceptionalCalendar
     * @property {WorkUnitType[]} exceptionalTime
     */

    /**
     * Work Unit Types by Activity Type
     *
     * Activity types are:
     *   * absence
     *   * internal
     *   * production
     *   * opportunity
     *
     * @property workUnitTypes
     * @observes i18n._locale
     * @type {WorkUnitTypes}
     */
    workUnitTypes: Ember.computed('i18n._locale', function () {
      var i18n = this.get('i18n');
      return {
        //activityType : workUnitTypes
        absence: [{
          reference: -1,
          activityType: _activity.TYPE_ABSENCE,
          name: this.get('settingsService').customer.typeOf.activity.find(function (activity) {
            return activity.id === 1;
          }).value
        }],
        internal: [{
          reference: -2,
          activityType: _activity.TYPE_INTERNAL,
          name: this.get('settingsService').customer.typeOf.activity.find(function (activity) {
            return activity.id === 2;
          }).value
        }],
        opportunity: [{
          reference: 0,
          activityType: _activity.TYPE_OPPORTUNITY,
          name: i18n.t('resourceplanner:opportunityActivity')
        }],
        production: [{
          reference: 1,
          activityType: _activity.TYPE_PRODUCTION,
          name: this.get('settingsService').customer.typeOf.activity.find(function (activity) {
            return activity.id === 0;
          }).value
        }]
      };
    }).readOnly(),
    /**************************************************************************/
    /** PLANNED TIMES *********************************************************/
    /**************************************************************************/
    /**
     * PlannedTimesRow POJO
     *
     * @todo Update docbloc when agency.workUnitTypes will be a WUT fragment!
     *
     * @typedef {Object} PlannedTimesRowPOJO
     * @property {Number}   row - row's no
     * @property {Activity} selectedActivity - selectedActivity (workUnitType have to be defined too)
     * @property {Object}   selectedWorkUnitType - workUnitType (selectedActivity have to be defined too) compatible with selectedActivity,
     */
    /**
     * Create new plannedTimes row.
     *
     * @method createPlannedTimesRow
     * @param {PlannedTimesRowPOJO} plannedTimesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {PlannedTimesRow} - the new PlannedTimes row
     */
    createPlannedTimesRow: function createPlannedTimesRow(plannedTimesRow) {
      var _this3 = this;
      var promise = this.get('activities').then(function (activities) {
        var workUnitTypes = _this3.get('workUnitTypes');
        var term = _this3.get('term');
        var workUnitRate = _this3.get('currentWorkUnitRate');
        // plannedTimesRow properties
        var rowNo = Ember.getWithDefault(plannedTimesRow, 'row', null);
        var comment = Ember.getWithDefault(plannedTimesRow, 'comment', '');
        var newPlannedTimesRow = {
          comment: comment,
          term: (0, _moment.default)(term),
          row: rowNo,
          selectedWorkUnitRate: workUnitRate,
          activities: activities,
          workUnitTypes: workUnitTypes
        };
        if (Object.keys(plannedTimesRow).length !== 0) {
          newPlannedTimesRow.selectedActivity = Ember.get(plannedTimesRow, 'selectedActivity');
          newPlannedTimesRow.selectedWorkUnitType = Ember.get(plannedTimesRow, 'selectedWorkUnitType');
        }
        return PlannedTimesRow.create(newPlannedTimesRow);
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Add new PlannedTimes row.
     *
     * Create a new Plannedtimes row and add it in `plannedTimesRows` array
     *
     * @uses createPlannedTimesRow
     * @uses plannedTimesRows
     *
     * @method addPlannedTimesRow
     * @param {PlannedTimesRowPOJO} addedPlannedTimesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {PlannedTimesRow} - The new PlannedTimes row.
     */
    addPlannedTimesRow: function addPlannedTimesRow(addedPlannedTimesRow) {
      var _this4 = this;
      var promise = this.get('plannedTimesRows').then(function (plannedTimesRows) {
        var nextRowPOJO = {};
        if (plannedTimesRows.get('length')) {
          var lastRow = plannedTimesRows.get('lastObject');
          nextRowPOJO = {
            selectedActivity: lastRow.get('selectedActivity'),
            selectedWorkUnitType: lastRow.get('selectedWorkUnitType')
          };
        }
        Ember.assign(nextRowPOJO, addedPlannedTimesRow);
        return _this4.createPlannedTimesRow(nextRowPOJO).then(function (newPlannedTimesRow) {
          // we must calculate the new row number now in the case 2 row has been added with the same action (project expenses)
          if (newPlannedTimesRow.get('row') === null) {
            var rowNumber = -1;
            if (plannedTimesRows.get('length')) {
              plannedTimesRows.forEach(function (row) {
                if (rowNumber >= row.get('row')) {
                  rowNumber = row.get('row') - 1;
                }
              });
            }
            newPlannedTimesRow.set('row', rowNumber);
          }
          newPlannedTimesRow.set('creation', true);
          plannedTimesRows.pushObject(newPlannedTimesRow);
          return newPlannedTimesRow;
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Delete a PlannedTimes row.
     *
     * @method delPlannedTimesRow
     * @param {PlannedTimesRow} row - PlannedTimes row to delete
     * @return {Promise}
     * @fulfil {PlannedTimesRow} - deleted PlannedTimes row
     */
    delPlannedTimesRow: function delPlannedTimesRow(row) {
      var _this5 = this;
      Ember.get(row, 'plannedTimes').forEach(function (plannedTime) {
        _this5.delPlannedTime(plannedTime, row);
      });
      return this.get('plannedTimesRows').then(function (plannedTimesRows) {
        return plannedTimesRows.removeObject(row);
      });
    },
    /**
     * Find a plannedTime in `plannedTimes`
     *
     * The test is done on `startDate` and `row` properties
     *
     * @uses plannedTimes
     *
     * @method findPlannedTime
     * @param  {plannedTime} plannedTime - plannedTime to find
     * @return {plannedTime} plannedTime found in `plannedTimes` array
     */
    findPlannedTime: function findPlannedTime(plannedTime) {
      var startDate = (0, _moment.default)(Ember.get(plannedTime, 'startDate'));
      var rowNo = Ember.get(plannedTime, 'row');
      return this.get('plannedTimes').find(function (item) {
        return item.get('startDate').isSame(startDate) && item.get('row') === rowNo;
      });
    },
    /**
     * @method updatePlannedTime
     *
     * @param {FakePlannedTime|PlannedTime} plannedTime - (Fake)PlannedTime to update with value
     * @param {Number} value - plannedTime's work unit value
     * @param {PlannedTimesRow} plannedTimesRow - row in which this plannedTime will be updated
     * @return {PlannedTime} updated plannedTime
     */
    updatePlannedTime: function updatePlannedTime(plannedTime, value, plannedTimesRow) {
      // get plannedTime to update : it's an already existing plannedTime or a new one
      var updatedPlannedTime = this.findPlannedTime(plannedTime) || this.addPlannedTime(plannedTime, plannedTimesRow);

      // update its value
      updatedPlannedTime.set('value', value);
      return updatedPlannedTime;
    },
    /**
     * @method addPlannedTime
     * @param {FakePlannedTime|PlannedTime} plannedTime - (Fake)PlannedTime to add
     * @param {PlannedTimesRow} plannedTimesRow - row in which this plannedTime will be added
     * @param {Boolean} mustCopy - if plannedTime is a PlannedTime (fragment) object, copy it or use its reference (default)
     * @return {PlannedTime} - added plannedTime
     */
    addPlannedTime: function addPlannedTime(plannedTime, plannedTimesRow) {
      var mustCopy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var addedPlannedTime;
      var defaultComment = plannedTimesRow.get('comment');
      switch (_typeof(plannedTime)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(plannedTime)) {
              addedPlannedTime = mustCopy ? (0, _emberCopy.copy)(plannedTime) : plannedTime;
              addedPlannedTime.set('comment', defaultComment);
              this.get('plannedTimes').addFragment(addedPlannedTime);
            } else {
              var normalizedPlannedTimePOJO = plannedTimesRow.getNormalizedPlannedTimePOJO(plannedTime);
              addedPlannedTime = this.get('plannedTimes').createFragment(normalizedPlannedTimePOJO);
              addedPlannedTime.set('comment', defaultComment);
              addedPlannedTime.set('workUnitRate', plannedTimesRow.selectedWorkUnitRate);
            }
            var dayIndex = Ember.get(plannedTime, 'startDate').date() - 1;
            var plannedTimes = plannedTimesRow.get('plannedTimes');

            // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
            plannedTimes[dayIndex] = addedPlannedTime;
            // Tell plannedTimes that it has changed for template update
            plannedTimes.arrayContentDidChange(dayIndex);
            break;
          }
        default:
          _logger.default.error.apply(_logger.default, ['appresourceplannerresource model - addPlannedTime - wrong plannedTime type'].concat(Array.prototype.slice.call(arguments)));
      }
      return addedPlannedTime;
    },
    /**
     * @uses PlannedTimesRow.delPlannedTime
     * @uses plannedTimes
     *
     * @method delPlannedTime
     * @param {PlannedTime} plannedTime - plannedTime to delete
     * @param {PlannedTimesRow} plannedTimesRow - row in which this plannedTime will be removed
     * @return {PlannedTime|undefined} - deleted plannedTime or `undefined` if this plannedTime can not be removed from
     *                                   `plannedTimes` array
     */
    delPlannedTime: function delPlannedTime(plannedTime, plannedTimesRow) {
      if ((0, _fragment.isFragment)(plannedTime)) {
        plannedTimesRow.delPlannedTime(plannedTime);
        return this.get('plannedTimes').removeFragment(plannedTime);
      }
    },
    addAbsencesTimesToPlannedTimes: function addAbsencesTimesToPlannedTimes() {
      var _this6 = this;
      var plannedTimesRowsPromisesMap = {};
      // display absencesTimes
      var absencesTimes = this.get('absencesTimes');
      var rowNo = -1;
      var currentRef;

      //absencesTimes.sortBy('workUnitType').forEach(plannedTime => {
      absencesTimes.sortBy('workUnitType.reference').forEach(function (absenceTime) {
        var plannedTime = absenceTime.copy();
        // attention, l'ordre crée par le sortBy est différent de l'ordre sauvegardé (on peut avoir une premiere ligne à -2 et la suivante a -1)
        var rowRef = plannedTime.get('workUnitType.reference');

        // update rowNo: it works because we sorted absencesTimes by reference before!
        if (typeof currentRef !== 'undefined' && currentRef !== rowRef) {
          rowNo--;
        }
        plannedTime.setProperties({
          row: rowNo,
          workUnitRate: _this6.get('currentWorkUnitRate')
        });

        // Create a plannedTimesRow if needed
        if (typeof plannedTimesRowsPromisesMap[rowRef] === 'undefined') {
          // find selectedActivity
          var selectedActivity = {
            id: getActivityIDFromPlannedTime(plannedTime)
          };
          var selectedWorkUnitType = plannedTime.get('workUnitType');

          // set currentRef to manage rowNo (cf comment 'update rowNo' at the begining of this forEach loop)
          currentRef = rowRef;
          plannedTimesRowsPromisesMap[rowRef] = _this6.createPlannedTimesRow({
            row: rowNo,
            selectedActivity: selectedActivity,
            selectedWorkUnitType: selectedWorkUnitType
          }).then(function (newPlannedTimesRow) {
            _this6.addPlannedTime(plannedTime, newPlannedTimesRow);
            newPlannedTimesRow.set('creation', true);
            _this6.get('plannedTimesRows').then(function (plannedTimesRows) {
              return plannedTimesRows.pushObject(newPlannedTimesRow);
            });
            return newPlannedTimesRow;
          });
        } else {
          // we chain
          plannedTimesRowsPromisesMap[rowRef].then(function (newPlannedTimesRow) {
            _this6.addPlannedTime(plannedTime, newPlannedTimesRow);
            return newPlannedTimesRow;
          });
        }
      });
      var plannedTimesRowsPromise = Ember.RSVP.all(Object.keys(plannedTimesRowsPromisesMap).sort(function (a, b) {
        var aInt = parseInt(a);
        var bInt = parseInt(b);
        return aInt - bInt;
      }).map(function (rowNo) {
        return plannedTimesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: plannedTimesRowsPromise
      });
    },
    plannedTimesRows: Ember.computed('plannedTimes', function () {
      var _this7 = this;
      var plannedTimesRowsPromisesMap = {};

      // display plannedTimes
      var plannedTimes = Ember.A(this.getWithDefault('plannedTimes', []));
      plannedTimes.sortBy('row').forEach(function (plannedTime) {
        var dayIndex = plannedTime.get('startDate').date() - 1;
        var rowNo = plannedTime.get('row');

        // Create a plannedTimesRow if needed
        if (typeof plannedTimesRowsPromisesMap[rowNo] === 'undefined') {
          // find selectedActivity
          var selectedActivity = {
            id: getActivityIDFromPlannedTime(plannedTime)
          };
          var selectedWorkUnitType = plannedTime.get('workUnitType');
          var currentComment = plannedTime.get('comment');
          plannedTimesRowsPromisesMap[rowNo] = _this7.createPlannedTimesRow({
            comment: currentComment,
            row: rowNo,
            selectedActivity: selectedActivity,
            selectedWorkUnitType: selectedWorkUnitType
          }).then(function (newPlannedTimesRow) {
            newPlannedTimesRow.get('plannedTimes')[dayIndex] = plannedTime;
            return newPlannedTimesRow;
          });
        } else {
          // we chain
          plannedTimesRowsPromisesMap[rowNo].then(function (newPlannedTimesRow) {
            newPlannedTimesRow.get('plannedTimes')[dayIndex] = plannedTime;
            return newPlannedTimesRow;
          });
        }
      });
      var plannedTimesRowsPromise = Ember.RSVP.all(Object.keys(plannedTimesRowsPromisesMap).sort(function (a, b) {
        var aInt = parseInt(a);
        var bInt = parseInt(b);
        return aInt - bInt;
      }).map(function (rowNo) {
        return plannedTimesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: plannedTimesRowsPromise
      });
    }).readOnly(),
    realId: Ember.computed('id', function () {
      return this.get('id').split('_')[0];
    }).readOnly(),
    internalReference: Ember.computed('id', function () {
      return !this.get('isNew') ? "".concat(this.get('prefix')).concat(this.get('realId')) : '';
    }).readOnly(),
    sortPlannedTimes: function sortPlannedTimes() {
      // before , we have to reorder regularTimes with the same order as
      // backend return it after saved model
      var plannedTimes = this.get('plannedTimes');
      if (plannedTimes.get('length')) {
        // can't use sortBy because we need to put row < 0 at the end in reverse order :)
        // const sortedRegularTimes = regularTimes.sortBy('row', 'startDate');
        //
        var sortedPlannedTimes = plannedTimes.toArray().sort(function (a, b) {
          var rowA = a.get('row');
          var rowB = b.get('row');
          var diff = rowA - rowB;
          if (diff === 0) {
            var startDateA = a.get('startDate');
            var startDateB = b.get('startDate');
            return startDateA - startDateB;
          } else {
            if (rowA < 0) {
              return rowB < 0 ? rowB - rowA : 1;
            } else {
              return rowB < 0 ? -1 : diff;
            }
          }
        });
        this.set('plannedTimes', sortedPlannedTimes);
      }
    }
  });
  AppResourcePlannerResource.reopenClass({
    prefix: 'COMP'
  });
  AppResourcePlannerResource.reopen({
    prefix: Ember.computed(function () {
      return AppResourcePlannerResource.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppResourcePlannerResource;
});