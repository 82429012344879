define("boondmanager/models/apporganizationcharts-orgchart", ["exports", "boondmanager/models/apporganizationcharts-basenode", "ember-data"], function (_exports, _apporganizationchartsBasenode, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ERROR_CODE_COMPANYORGCHART_NOTEXISTS = void 0;
  var ERROR_CODE_COMPANYORGCHART_NOTEXISTS = _exports.ERROR_CODE_COMPANYORGCHART_NOTEXISTS = '11000';
  var _default = _exports.default = _apporganizationchartsBasenode.default.extend({
    canReadOrganizationChart: _emberData.default.attr('boolean'),
    isOnlyLastChild: function isOnlyLastChild(child) {
      return this.get('children').then(function (children) {
        return children.length === 1 && children.objectAt(0) === child;
      });
    }
  });
});