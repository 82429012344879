define("boondmanager/models/appplanproductionresource", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPE_PRODUCTION = _exports.TYPE_PLANNED = _exports.TYPE_CONSUMPTION = _exports.TYPE_ABSENCE = void 0;
  var TYPE_PLANNED = _exports.TYPE_PLANNED = '0';
  var TYPE_ABSENCE = _exports.TYPE_ABSENCE = '1';
  var TYPE_CONSUMPTION = _exports.TYPE_CONSUMPTION = '2';
  var TYPE_PRODUCTION = _exports.TYPE_PRODUCTION = '3';
  var _default = _exports.default = _emberData.default.Model.extend({});
});