define("boondmanager/models/apporganizationcharts-basenode", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    dependsOn: _emberData.default.belongsTo('base', {
      polymorphic: true
    }),
    children: _emberData.default.hasMany('apporganizationchartsNode', {
      inverse: 'parent'
    })
  });
});