define("boondmanager/models/appemailingresource", ["exports", "ember-data", "boondmanager/models/resource"], function (_exports, _emberData, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRANSACTIONAL_SENDING_TYPE_SMTP = _exports.TRANSACTIONAL_SENDING_TYPE_MICROSOFT = _exports.TRANSACTIONAL_SENDING_TYPE_MANDRILL = _exports.TRANSACTIONAL_SENDING_TYPE_GMAIL = _exports.TRANSACTIONAL_SENDING_TYPE_DEFAULT = _exports.TRANSACTIONAL_DEFAULT_PROTOCOL = _exports.TRANSACTIONAL_DEFAULT_PORT = void 0;
  var TRANSACTIONAL_SENDING_TYPE_DEFAULT = _exports.TRANSACTIONAL_SENDING_TYPE_DEFAULT = 'default';
  var TRANSACTIONAL_SENDING_TYPE_SMTP = _exports.TRANSACTIONAL_SENDING_TYPE_SMTP = 'smtp';
  var TRANSACTIONAL_SENDING_TYPE_GMAIL = _exports.TRANSACTIONAL_SENDING_TYPE_GMAIL = 'gmail';
  var TRANSACTIONAL_SENDING_TYPE_MANDRILL = _exports.TRANSACTIONAL_SENDING_TYPE_MANDRILL = 'mandrill';
  var TRANSACTIONAL_SENDING_TYPE_MICROSOFT = _exports.TRANSACTIONAL_SENDING_TYPE_MICROSOFT = 'Microsoft';
  var TRANSACTIONAL_DEFAULT_PORT = _exports.TRANSACTIONAL_DEFAULT_PORT = 25;
  var TRANSACTIONAL_DEFAULT_PROTOCOL = _exports.TRANSACTIONAL_DEFAULT_PROTOCOL = 'none';
  var AppsEMailingResource = _resource.default.extend({
    transactionalEmailsServerLogin: _emberData.default.attr('string'),
    transactionalEmailsServerPassword: _emberData.default.attr('string'),
    transactionalEmailsServerUrl: _emberData.default.attr('string'),
    transactionalEmailsServerPort: _emberData.default.attr('number'),
    transactionalEmailsServerProtocol: _emberData.default.attr('string'),
    transactionalEmailsMandrillApiKey: _emberData.default.attr('string'),
    transactionalEmailsSendingType: _emberData.default.attr('string'),
    defaultTransactionalEmailsSendingType: _emberData.default.attr('string'),
    isCustomizedSignature: _emberData.default.attr('boolean'),
    signature: _emberData.default.attr('string'),
    customerSignature: _emberData.default.attr('string'),
    plainSignature: _emberData.default.attr('string'),
    fromEmail: _emberData.default.attr('string'),
    allowedStates: _emberData.default.attr(''),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'resource',
    canReadRecord: Ember.computed.alias('canReadResource').readOnly(),
    recordRouteName: Ember.computed('', function () {
      return 'private.resources.resource.overview';
    }).readOnly()
  });
  AppsEMailingResource.reopenClass({
    prefix: 'COMP'
  });
  AppsEMailingResource.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingResource.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingResource;
});