define("boondmanager/pods/components/bm-field/role/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2oE9Zh+f",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,9],null,[[\"options\",\"noUnderline\",\"name\",\"selected\",\"useObjects\",\"valueKeyname\",\"searchPlaceholder\",\"searchMessage\",\"forceSearch\",\"onchange\",\"placeholder\",\"disabled\",\"required\",\"allowClear\",\"triggerComponent\",\"afterOptionsComponent\",\"loadingMessage\"],[[32,0,[\"options\"]],true,\"role\",[35,8,[\"id\"]],true,\"name\",\"\",\"\",true,[30,[36,1],[[32,0],\"onItemChange\"],null],[30,[36,2],[\"common:none\"],null],[35,7],[35,6],[35,5],[30,[36,4],[\"bm-field/power-select/role/trigger\"],null],[30,[36,4],[\"bm-field/power-select/role/footer\"],[[\"createRole\",\"showFooter\"],[[30,[36,1],[[32,0],\"createRole\"],null],[32,0,[\"showFooter\"]]]]],[30,[36,2],[\"components:bmFieldRole.loadingMessage\"],null]]]]],[2,\"\\n\"],[6,[37,10],[[32,0,[\"showRoleLink\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-link\"],[24,\"data-name\",\"button-recipients-number\"],[16,\"aria-label\",[30,[36,2],[\"components:bmFieldRole.roleLinkTitle\"],null]]],[[\"@disabled\",\"@onClick\"],[[30,[36,0],[[32,0,[\"selected\",\"id\"]]],null],[30,[36,1],[[32,0],\"seeRole\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,3],[\"bmi-visible\"],null]],[2,\" \"],[1,[30,[36,2],[\"components:bmFieldRole.roleLinkTitle\"],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"action\",\"t\",\"icon\",\"component\",\"allowClear\",\"required\",\"disabled\",\"selected\",\"bm-field/power-select\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/role/template.hbs"
  });
});