define("boondmanager/services/notify", ["exports", "toastr", "jquery"], function (_exports, _toastr, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* cf https://stackoverflow.com/questions/39558434/ember-js-show-spinner-when-something-is-loading-in-background */
  var _default = _exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    toastrOptions: Object.freeze({
      closeButton: true,
      debug: false,
      positionClass: 'toast-bottom-right',
      onclick: null,
      showDuration: '200',
      hideDuration: '200',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'slideDown',
      hideMethod: 'slideUp'
    }),
    genericErrorModal: {
      enabled: false,
      show: false,
      lastError: {},
      readableErrors: []
    },
    success: function success(msg, title, options) {
      return _toastr.default.success(msg, title, this._getOptions(options));
    },
    warning: function warning(msg, title, options) {
      return _toastr.default.warning(msg, title, this._getOptions(options));
    },
    error: function error(msg, title, options) {
      options = this._getOptions(options);
      if (options.onclick) {
        msg += '<br><br>' + '<button class="bmb-rectangle bmb-compact toast-link-detail">' + this.get('i18n').t('messages:error.genericError.clickForDetails') + '</button>';
      }
      return _toastr.default.error(msg, title, options);
    },
    info: function info(msg, title, options) {
      return _toastr.default.info(msg, title, this._getOptions(options));
    },
    close: function close() {
      _toastr.default.clear();
    },
    _getOptions: function _getOptions(options) {
      var baseOptions = Ember.assign({}, this.toastrOptions);
      baseOptions.onShown = function () {
        // save all opened tooltips
        var allTooltips = (0, _jquery.default)('div.tooltip.show');
        var allElements = [];
        allTooltips.each(function () {
          allElements.push((0, _jquery.default)(this).attr('id'));
        });
        allTooltips.remove();
        allElements.forEach(function (id) {
          (0, _jquery.default)(".bm-tooltips[aria-describedby=\"".concat(id, "\"]")).tooltip('show');
        });
      };
      var genericErrorHandler = this._getGenericErrorHandler();
      if (genericErrorHandler) {
        baseOptions.onclick = genericErrorHandler;
      }
      return Ember.assign({}, baseOptions, options);
    },
    _getGenericErrorHandler: function _getGenericErrorHandler() {
      var _this = this;
      if (this.genericErrorModal.enabled && this.genericErrorModal.lastError.status == 422) {
        var readableErrors = [];
        var errors = this.genericErrorModal.lastError.payload.errors;
        if (errors) {
          var i18n = this.get('i18n');
          var modelName = this.genericErrorModal.lastError.model;
          errors.forEach(function (err) {
            if (err.status == 422 && err.detail) {
              var errorDetail = err.detail;
              if (err.code && i18n.exists('messages:error:' + err.code + ':details')) {
                errorDetail = i18n.t('messages:error:' + err.code + ':details');
              }
              var errorMessage = errorDetail;
              if (err.source && err.source.pointer) {
                errorMessage = err.source.pointer + ' : ' + errorDetail;
                if (modelName) {
                  var attrs = err.source.pointer.split('/');
                  var errorField = 'models:' + modelName + '.attributes.' + attrs[attrs.length - 1] + '.name';
                  if (i18n.exists(errorField)) {
                    errorMessage = i18n.t(errorField) + ' : ' + errorDetail;
                  }
                }
              }
              readableErrors.push(errorMessage);
            }
          });
        }
        this.set('genericErrorModal.readableErrors', readableErrors);
        return function () {
          _this.set('genericErrorModal.show', true);
        };
      }
      return null;
    }
  });
});