define("boondmanager/serializers/absencesreport", ["exports", "boondmanager/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    // BACK -> FRONT
    normalize: function normalize(modelName, resourceHash) {
      /*
       * Ici on force l'ordre de l'attribut renvoyé par le back par ID. En effet, la version 5.0.0-beta
       * des fragments a l'air de poser des soucis en terme d'ordonnancement. Les fragments ne sont pas ajoutés
       * dans l'ordre où le back les renvoie ici donc on force l'ordre dans le même ordre que le backend.
       * cf https://wishgroupe.atlassian.net/browse/BM-13726
       */
      if (resourceHash.attributes && resourceHash.attributes.absencesPeriods) {
        resourceHash.attributes.absencesPeriods = resourceHash.attributes.absencesPeriods.sortBy('id');
      }
      return this._super.apply(this, arguments);
    }
  });
});