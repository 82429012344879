define("boondmanager/routes/entity", ["exports", "boondmanager/routes/base", "boondmanager/utils/logger", "boondmanager/helpers/icon", "bootbox", "jquery"], function (_exports, _base, _logger, _icon, _bootbox, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * Routeur parent des fiches
   *
   * @class EntityRoute
   * @extends Ember.Route
   * @namespace Ember
   * @module Routes
   */
  var _default = _exports.default = _base.default.extend({
    /** SERVICES **/
    router: Ember.inject.service(),
    openProtocol: Ember.inject.service('open-protocol'),
    threadService: Ember.inject.service('thread'),
    appsSections: Ember.inject.service('apps-sections'),
    modalNativeAction: Ember.inject.service(),
    notifications: Ember.inject.service(),
    /** PROPERTIES **/
    /**
     * Transition courante pour la confirmation avant de quitter la page
     *
     * @property currentTransition
     * @type Object
     * @default null
     */
    currentTransition: null,
    /**
     * Activation de la confirmation avant de quitter la page
     *
     * @property leavePageConfirm
     * @type boolean
     * @default true
     */
    leavePageConfirm: true,
    /**
     * Activation de la fonctionnalité de sauvegarde lors de l'appuie sur la touche entrée
     *
     * @property saveOnEnter
     * @type boolean
     * @default true
     */
    saveOnEnter: true,
    /**
     * Liste des modèles à mettre à jour suite aux notifications des threads
     *
     * @property threadModels
     * @type {ReadOnlyArray}
     * @default []
     */
    threadModels: Object.freeze([]),
    /**
     * Écoute des fils de discussions
     *
     * @property listenThreads
     * @type {boolean}
     * @default true
     */
    listenThreads: true,
    /**
     * Nom du module
     * Utile pour récupérer les droits d'une fiche
     *
     * @property rightsModule
     * @type {null|string}
     * @default null
     */
    rightsPath: null,
    rightsParams: null,
    loadRights: true,
    eventUnsubscribesFunctions: [],
    entityRights: Ember.computed('rights.lastUpdate', 'rightsPath', function () {
      return this.get('rightsPath') !== null ? this.get('rights').getEntityRights(this.get('rightsPath')) : null;
    }).readOnly(),
    /** COMPUTED */
    parentController: Ember.computed(function () {
      return this.controllerFor(this.get('router.currentRoute.parent.name'));
    }).readOnly(),
    /** METHODS */
    /**
     * Get default model for controller (override if model is not stored in "model" property of controller)
     * @returns {*}
     */
    getControllerModel: function getControllerModel(routeName) {
      routeName = routeName || this.get('router.currentRouteName');
      return this.modelFor(routeName);
    },
    getFromController: function getFromController(routeName) {
      routeName = routeName || this.get('router.currentRouteName');
      return this.controllerFor(routeName);
    },
    getFromControllerForThread: function getFromControllerForThread(routeName) {
      routeName = routeName || this.get('router.currentRouteName');
      try {
        var controller = this.getFromController(routeName);

        /**
         * getFromController can throw error or return undefined
         */
        if (typeof controller === 'undefined') {
          return this.notFoundControllerForThread(routeName);
        }
        return controller;
      } catch (error) {
        return this.notFoundControllerForThread(routeName);
      }
    },
    /**
     * dans le cas où on serait redirigé vers une route du style
     * projects.project.index, controllerFor renvoie une erreur
     * puisque le routeur existe mais pas le contrôleur
     * on remonte donc au niveau supérieur pour que le process puisse continuer
     * @param routeName
     * @return {*}
     */
    notFoundControllerForThread: function notFoundControllerForThread(routeName) {
      var parts = routeName.split('.');
      var tab = parts.pop();
      if (tab === 'index') {
        routeName = parts.join('.');
        return this.getFromController(routeName);
      }
    },
    /**
     * On before unload event to display confirm modal if user trying to leave page
     * (refresh, another URL or exit browser or tab) and model is dirty
     * @param e
     * @returns {*}
     */
    onBeforeunload: function onBeforeunload(e) {
      if (this.isPageDirty()) {
        var message = this.get('i18n').t('common:leavingConfirmationMessage');
        e.returnValue = message; // Return for IE and Firefox
        return message; // Return for Safari
      }
    },
    /**
     * Test if page is dirty
     * @returns {boolean}
     */
    isPageDirty: function isPageDirty() {
      if (this.get('openProtocol.bypassUnload')) return false;
      var model = this.getControllerModel();
      if (model) {
        var fromController = this.getFromController();
        var disableSaveButton = typeof fromController.get('disableSaveButton') !== 'undefined' ? fromController.get('disableSaveButton') : !Ember.get(model, 'hasDirtyAttributes');
        return !disableSaveButton;
      } else {
        return false;
      }
    },
    /**
     * When user click on leave without save, wait for this method to be completed
     * @returns {Promise}
     */
    onLeaveNotSave: function onLeaveNotSave() {
      return Ember.RSVP.resolve(true);
    },
    /**
     * Dispath events depending on name
     * @param event
     */
    handleEvent: function handleEvent(event) {
      var fnName = event.type.split('');
      if (fnName.length) {
        fnName[0] = fnName[0].toUpperCase();
        var fn = this['on' + fnName.join('')];
        if (typeof fn === 'function') {
          fn.apply(this, arguments);
        }
      }
    },
    triggerSave: function triggerSave(event) {
      if (event.eventPhase === Event.BUBBLING_PHASE) {
        _logger.default.debug('saveOnEnter', event, event.eventPhase);
        // check validEvent
        var isValid = true;
        if (['Enter', 'NumpadEnter'].includes(event.key)) {
          var allowedInputTypes = ['text', 'checkbox', 'radio'];
          isValid = event.target.tagName.toLowerCase() === 'input' && event.target.type && allowedInputTypes.includes(event.target.type.toLowerCase()) || event.target.type === undefined;
        }
        if (isValid) {
          event.stopPropagation();
          // if modal is open, trigger click on OK modal button
          var modalObject = (0, _jquery.default)(document).find('div.modal');
          var modalNativeObect = (0, _jquery.default)(document).find('.bmc-modal-native.open:not(.reduce)');
          if (this.isInModal(modalObject)) {
            modalObject.find('.modal-footer button.btn').filter(function () {
              return (0, _jquery.default)(this).data('bb-handler') === 'ok';
            }).trigger('click');
          } else if (modalNativeObect.length) {
            modalNativeObect.find('.bmc-modal-native-box_footer button.bmb-confirm').trigger('click');
          } else {
            // test controller to save only in the current route controller
            if (this.controller) {
              Ember.run.next(this, function () {
                // delay to take into account pending modification on model
                this.controller.send('save');
              });
            }
          }
          event.preventDefault();
        }
      }
      return false;
    },
    /**
     * Test if element target is in modal or not
     * @param modalObject
     * @returns {Boolean}
     */
    isInModal: function isInModal(modalObject) {
      return modalObject.length > 0 && modalObject.is(':visible');
    },
    /** -------- EMBER ROUTER LIFECYCLE -------- */beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.to.queryParams.thread) {
        this.set('initOnThread', transition.to.queryParams.thread);
      } else {
        this.set('initOnThread', false);
      }
      if (this.get('rightsPath') !== null && this.get('loadRights')) {
        if (!transition.isAborted) {
          return this.rights.loadRights(this.get('rightsPath'), this.get('rightsParams'));
        } else {
          _logger.default.debug('rights not loaded because transition is aborted');
        }
      }
      return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        // always resolve because sometimes rights can be undefined
        // because current user has no rights :)
        resolve();
      });
    },
    afterModel: function afterModel(model, transition) {
      if (window && window.addEventListener && this.leavePageConfirm) {
        window.addEventListener('beforeunload', this, false);
      }
      // On répète ici ce fonctionnement pour gérer le cas d'un refresh de la route qui n'appel pas setupController.
      // Typiquement ouverture d'une notification thread en étant déjà sur la fiche.
      if (transition.to.name) {
        if (this.get('initOnThread') && !this.get('threadService').getCurrentThreadId()) {
          var controller = this.getFromControllerForThread(transition.to.name);
          /**
           * Controller shouldn't be empty with a valid transition
           */
          if (controller) {
            this.get('threadService').setModel(controller.get('currentModel'));
          }
          this.get('threadService').resetThreadValues();
          this.get('threadService').openThread(this.get('initOnThread'));
        }
      }
    },
    setupController: function setupController(controller /*, model */) {
      this._super.apply(this, arguments);

      // si c'est un tableau de modèles
      if (this.get('listenThreads')) {
        if (typeof controller.get('currentModel.length') !== 'undefined') {
          this.set('threadModels', controller.get('currentModel').toArray());
        } else {
          this.get('threadModels').pushObject(controller.get('currentModel'));
        }
        var reloadThread = this.notifications.on('reloadThread', Ember.run.bind(this, this._onNotification));
        this.eventUnsubscribesFunctions.push(reloadThread);
      }
      if (this.get('initOnThread')) {
        this.get('threadService').setModel(controller.get('currentModel'));
        this.get('threadService').resetThreadValues();
        this.get('threadService').openThread(this.get('initOnThread'));
      }
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        if (window && window.removeEventListener && this.leavePageConfirm) {
          window.removeEventListener('beforeunload', this, false);
        }
        var _iterator = _createForOfIteratorHelper(this.eventUnsubscribesFunctions),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var unsub = _step.value;
            unsub();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        this.get('threadService').clear();
        this.set('threadModels', Ember.A([]));
      }
      this._super.apply(this, arguments);
    },
    activate: function activate() {
      if (this.saveOnEnter) {
        document.addEventListener('keydown', this._onKeyDown, false);
      }
    },
    deactivate: function deactivate() {
      if (this.saveOnEnter) {
        document.removeEventListener('keydown', this._onKeyDown, false);
      }
    },
    /**
     * Canceling "confirmForTransition"
     */
    cancelForTransition: function cancelForTransition() {
      this.controller.set('isConfirmForTransitionDisplayed', false);
      var currentTransition = this.get('currentTransition');
      // On remplace la transition courante par la même que la source afin de conserver les queryParams.
      // Cela ne recharge pas la page et ne relance pas de requête XHR côté back mais permet de conserver les queryParams
      // du modèle en cours lorsque l'on annule la modale de confirmation de quitter/sauvegarder
      this.get('router').replaceWith(currentTransition.from.name, {
        queryParams: currentTransition.from.queryParams
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('threadModels', Ember.A([]));
      this._onKeyDown = this._onKeyDown.bind(this);
    },
    _onKeyDown: function _onKeyDown(event) {
      var isEnterKey = ['Enter', 'NumpadEnter'].includes(event.key);
      var isCombinedSaveKey = (event.ctrlKey || event.metaKey) && event.key === 's';
      if (isEnterKey || isCombinedSaveKey) {
        this.triggerSave(event);
      }
      return true;
    },
    /**
     * Action à l'arrivée d'une nouvelle notification node pour la mise à jour d'un thread si un contexte est défini
     * @param {Object} notification Notification NodeJS
     * @private
     */
    _onNotification: function _onNotification(notification) {
      // récupération de la référence interne du modèle concerné par la mise à jour des threads
      var dependsOnReference = notification.additionalData.thread.dependsOnReference;

      // on recharge les threads du modèle s'il existe sur la page sur laquelle on est actuellement
      // on ne recharge la liste des threads que si on est sur la liste des threads
      var modelFound = this.get('threadModels').find(function (thread) {
        return typeof thread !== 'undefined' && thread.get('internalReference') === dependsOnReference;
      });
      if (modelFound) {
        // cas spécifique pour les actions où on ne souhaite pas exécuter le refresh de l'action en double
        // car il est aussi écouté depuis le composant bm-modal/native/action
        if (modelFound.get('modelName') !== 'action' || !this.get('modalNativeAction.isActive') || this.get('modalNativeAction.actionModel.internalReference') !== dependsOnReference) {
          this.get('threadService').queryThreads(modelFound.get('internalReference'), 1).then(function (threads) {
            modelFound.set('threads', threads.toArray());
          });
        }
      }
    },
    /** ACTIONS **/
    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        if (this.leavePageConfirm) {
          this.set('currentTransition', transition);
        }
      },
      confirmForTransition: function confirmForTransition(transition, model, saveOptions, additionalText) {
        var _this = this;
        if (this.leavePageConfirm) {
          // #Custom revert of Back button result
          //
          // on a stoppé la transition, notre router est donc de nouveau dans l'état "d'où on vient",
          // cad this._router.currentRouteName === la route d'où l'on vient !
          var router = this.get('router');
          var currentRouteName = router.get('currentRouteName');
          var routeNameParts = currentRouteName.split('.');
          var fromController = this.getFromController();
          // Check if we have actions, otherwise index was added to currentRouteName
          if (!fromController.actions && routeNameParts[routeNameParts.length - 1] === 'index') {
            fromController = this.controllerFor(routeNameParts.slice(0, -1).join('.'));
          }
          var disableSaveButton = typeof fromController.get('disableSaveButton') !== 'undefined' ? fromController.get('disableSaveButton') : !model.get('hasDirtyAttributes');
          _logger.default.debug("Transition #".concat(transition.sequence, " - ").concat(this.routeName, "/route.actions#confirmForTransition"), {
            transition: transition,
            model: model,
            saveOptions: saveOptions,
            disableSaveButton: disableSaveButton,
            forceTransition: this.get('forceTransition')
          });
          if (!disableSaveButton && !this.get('forceTransition')) {
            _logger.default.debug("Transition #".concat(transition.sequence, " - ").concat(this.routeName, "/route.actions#confirmForTransition - model has dirty attributes:"), {
              dirtyType: model.get('dirtyType'),
              changedAttributes: model.changedAttributes()
            });
            transition.abort(); //On arrête la transition

            var contexts = [];
            var currentRouteInfos = transition.to;
            while (currentRouteInfos !== null) {
              currentRouteInfos.paramNames.forEach(function (paramName) {
                contexts.push(currentRouteInfos.params[paramName]);
              });
              currentRouteInfos = currentRouteInfos.parent;
            }
            var destURL = router.urlFor.apply(router, [transition.to.name].concat(contexts));
            var fromURL = router.urlFor(currentRouteName, {
              queryParams: router._router._routerMicrolib.state.queryParams
            });

            //En cas de redirection vers bad-version on n'affiche pas la modale
            if (destURL.endsWith('bad-version')) {
              this.set('forceTransition', true);
              this.controller.set('isConfirmForTransitionDisplayed', false);
              transition.retry();
              return false;
            }
            if (fromURL !== destURL) {
              Ember.run.schedule('routerTransitions', function task1() {
                router.location.replaceState(fromURL);
              });
            }
            // /#Custom revert of Back button result

            if (!this.controller.get('isConfirmForTransitionDisplayed')) {
              var i18n = this.get('i18n');
              var msg = "<p class=\"bmc-modal-text-with-icon\">\n\t\t\t\t\t\t<i class=\"bmi-save bmc-modal-icon\"></i><br>\n\t\t\t\t\t\t".concat(i18n.t('components:modals.uncommitedChanges.title'), "\n\t\t\t\t\t\t</p>");
              if (additionalText) msg = "".concat(msg, "<p>").concat(additionalText, "</p>");
              _bootbox.default.dialog({
                message: msg,
                onEscape: function onEscape() {
                  _this.cancelForTransition();
                },
                className: 'bmc-modal',
                title: i18n.t('components:modals.uncommitedChanges.titleModal'),
                backdrop: true,
                buttons: {
                  cancel: {
                    label: i18n.t('components:modals.uncommitedChanges.cancel'),
                    className: 'bmb-rectangle bmb-rectangle-border-on-hover',
                    callback: function callback() {
                      _this.cancelForTransition();
                    }
                  },
                  notsave: {
                    label: "".concat((0, _icon.icon)(['bmi-close']), "\n\t\t\t\t\t\t\t\t\t\t").concat(i18n.t('components:modals.uncommitedChanges.cancelAndContinue')),
                    className: 'bmb-rectangle',
                    callback: function callback() {
                      _this.onLeaveNotSave().then(function () {
                        _this.set('forceTransition', true);
                        _this.controller.set('isConfirmForTransitionDisplayed', false);
                        _this.get('appsSections').rollbackEntities();
                        transition.retry().followRedirects().then(function () {
                          _this.set('forceTransition', false);
                          if (model) {
                            if (model.get('isNew')) {
                              model.destroyRecord();
                            } else if (model.get('id') !== '0') {
                              model.rollbackAttributes();
                            } else {
                              _logger.default.error('confirmForTransition#notsave: model not new with a id === 0!?', model.toJSON({
                                includeId: true
                              }));
                            }
                          }
                        });
                      });
                    }
                  },
                  save: {
                    label: "".concat((0, _icon.icon)(['bmi-modal-save']), "\n\t\t\t\t\t\t\t\t\t\t").concat(i18n.t('components:modals.uncommitedChanges.saveAndContinue')),
                    className: 'bmb-confirm',
                    callback: function callback() {
                      //Il faut sauvegarder avant de relancer la transition
                      if (typeof fromController.actions.save === 'function') {
                        _this.controller.set('isConfirmForTransitionDisplayed', false);
                        fromController.send('save', transition);
                      } else {
                        model.save(saveOptions).then(function () {
                          _this.controller.set('isConfirmForTransitionDisplayed', false);
                          transition.retry();
                        });
                      }
                    }
                  }
                }
              });
              this.controller.set('isConfirmForTransitionDisplayed', true);
            }
          }
          return false;
        }
      }
    },
    /** EVENTS */
    activateKeyboard: Ember.on('init', function () {
      this.set('keyboardActivated', true);
    })
  });
});