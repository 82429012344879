define("boondmanager/models/activityexpense", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    quantity: _emberData.default.attr('string'),
    //hour format : 1H30
    typeOf: _emberData.default.attr('string'),
    quantityUnit: _emberData.default.attr('string'),
    amountExcludingTax: _emberData.default.attr('number'),
    totalAmountExcludingTax: _emberData.default.attr('number'),
    //relationships
    project: _emberData.default.belongsTo(),
    delivery: _emberData.default.belongsTo()
  });
});