define("boondmanager/models/agency", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable", "boondmanager/models/advantagetype", "boondmanager/models/expensetype"], function (_exports, _base, _emberData, _attributes, _emberDataCopyable, _advantagetype, _expensetype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.METHOD2 = _exports.METHOD1 = _exports.JDAY_LAST_DAY_PREV_MONTH = _exports.JDAY_ACTUAL = _exports.CURRENCY_US_DOLLAR = _exports.CURRENCY_POUND_STERLING = _exports.CURRENCY_EURO = void 0;
  var METHOD1 = _exports.METHOD1 = 'takenUntilN-1AndAskedFromN';
  var METHOD2 = _exports.METHOD2 = 'totalTakenAndAsked';
  var JDAY_ACTUAL = _exports.JDAY_ACTUAL = 'actualDay';
  var JDAY_LAST_DAY_PREV_MONTH = _exports.JDAY_LAST_DAY_PREV_MONTH = 'lastDayOfMonth';
  var CURRENCY_EURO = _exports.CURRENCY_EURO = 0;
  var CURRENCY_US_DOLLAR = _exports.CURRENCY_US_DOLLAR = 1;
  var CURRENCY_POUND_STERLING = _exports.CURRENCY_POUND_STERLING = 2;
  var Agency = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['factor']
    }),
    absencesLegals: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    contractsSalaryType: _emberData.default.attr('string'),
    contractsNumberOfHoursPerWeek: _emberData.default.attr('number'),
    allowExceptionalScalesOnContracts: _emberData.default.attr('boolean'),
    allowResourcesToViewAbsencesAccounts: _emberData.default.attr('boolean'),
    defaultCandidateTypeOf: _emberData.default.attr('number'),
    defaultResourceTypeOf: _emberData.default.attr('number'),
    apeCode: _emberData.default.attr('string'),
    calendar: _emberData.default.attr('string'),
    chargeFactor: _emberData.default.attr('number'),
    country: _emberData.default.attr('string'),
    subDivision: _emberData.default.attr('string'),
    currency: _emberData.default.attr('number'),
    defaultAbsencesAccountsPeriod: _emberData.default.attr('string'),
    email1: _emberData.default.attr('string'),
    expensesLegals: _emberData.default.attr('string'),
    exchangeRate: _emberData.default.attr('number'),
    legalStatus: _emberData.default.attr('string'),
    groupLogo: _emberData.default.attr('string'),
    invoicesLogo: _emberData.default.attr('string'),
    activityExpensesLogo: _emberData.default.attr('string'),
    maskExceedingWarningsWithQuotas: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    numberOfWorkingDays: _emberData.default.attr('number'),
    phone1: _emberData.default.attr('string'),
    postcode: _emberData.default.attr('string'),
    registeredOffice: _emberData.default.attr('string'),
    registrationNumber: _emberData.default.attr('string'),
    town: _emberData.default.attr('string'),
    timesLegals: _emberData.default.attr('string'),
    timesAlerts: _emberData.default.attr(),
    url: _emberData.default.attr('string'),
    vatNumber: _emberData.default.attr('string'),
    workUnitRate: _emberData.default.attr('number'),
    workUnitRateOnProjectsAndOpportunities: _emberData.default.attr('number'),
    timezone: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    code: _emberData.default.attr('string'),
    group: _emberData.default.attr('string'),
    absencesCalculationMethod: _emberData.default.attr('string'),
    timesReportsWorkflow: _emberData.default.attr(),
    expensesReportsWorkflow: _emberData.default.attr(),
    absencesReportsWorkflow: _emberData.default.attr(),
    contractualExpensesAutomaticFilling: _emberData.default.attr('boolean'),
    opportunitiesTechnicalAssistanceReferenceMask: _emberData.default.attr('string'),
    opportunitiesPackageReferenceMask: _emberData.default.attr('string'),
    opportunitiesRecruitmentReferenceMask: _emberData.default.attr('string'),
    opportunitiesProductReferenceMask: _emberData.default.attr('string'),
    quotationsValidityInDays: _emberData.default.attr('number'),
    quotationsLegals: _emberData.default.attr('string'),
    quotationsStartingNumber: _emberData.default.attr('string'),
    quotationsReferenceMask: _emberData.default.attr('string'),
    quotationsPaymentTerm: _emberData.default.attr('number'),
    quotationsTaxRate: _emberData.default.attr('number'),
    quotationsTaxRates: _emberData.default.attr(),
    quotationsAllowMultiplesTaxRates: _emberData.default.attr('boolean'),
    quotationsShowCompanyVATNumberOnPDF: _emberData.default.attr('boolean'),
    quotationsShowCompanyRegistrationNumberOnPDF: _emberData.default.attr('boolean'),
    quotationsShowCompanyNumberOnPDF: _emberData.default.attr('boolean'),
    quotationsShowOpportunityReferenceOnPDF: _emberData.default.attr('boolean'),
    quotationsShowFooterOnPDF: _emberData.default.attr('boolean'),
    purchasesReferenceMask: _emberData.default.attr('string'),
    purchasesPaymentTerm: _emberData.default.attr('number'),
    purchasesTaxRate: _emberData.default.attr('number'),
    purchasesTaxRates: _emberData.default.attr(),
    purchasesAllowMultiplesTaxRates: _emberData.default.attr('boolean'),
    purchasesPaymentMethod: _emberData.default.attr('number'),
    projectsTechnicalAssistanceReferenceMask: _emberData.default.attr('string'),
    projectsPackageReferenceMask: _emberData.default.attr('string'),
    projectsRecruitmentReferenceMask: _emberData.default.attr('string'),
    projectsProductReferenceMask: _emberData.default.attr('string'),
    projectsValidityInDays: _emberData.default.attr('number'),
    allowAlonesMarkers: _emberData.default.attr('boolean'),
    allowAdvantagesOnProjects: _emberData.default.attr('boolean'),
    allowExceptionalScalesOnProjects: _emberData.default.attr('boolean'),
    jDay: _emberData.default.attr(),
    expensesCreationAndMailingDate: _emberData.default.attr(),
    timesCreationAndMailingDate: _emberData.default.attr(),
    projectsRebillingRate: _emberData.default.attr('number'),
    invoicesLegals: _emberData.default.attr('string'),
    invoicesLockingStates: _emberData.default.attr(),
    invoicesAutomaticCreation: _emberData.default.attr('boolean'),
    invoicesDeltaCreationInDays: _emberData.default.attr('number'),
    invoicesStateForSendingMailToClient: _emberData.default.attr(),
    invoicesStateToSetAfterSendingMailToClient: _emberData.default.attr(),
    invoicesMailSenderType: _emberData.default.attr('string'),
    invoicesMailSenderCustomized: _emberData.default.attr('string'),
    invoicesMailCcSenderType: _emberData.default.attr(''),
    invoicesMailBccSenderType: _emberData.default.attr(''),
    invoicesReferenceMask: _emberData.default.attr('string'),
    invoicesStartingNumber: _emberData.default.attr('string'),
    invoicesStrictDate: _emberData.default.attr('boolean'),
    ordersPaymentTerm: _emberData.default.attr('number'),
    ordersPaymentMethod: _emberData.default.attr('number'),
    ordersSendingMode: _emberData.default.attr('number'),
    ordersTaxRate: _emberData.default.attr('number'),
    ordersTaxRates: _emberData.default.attr(),
    ordersAllowMultiplesTaxRates: _emberData.default.attr('boolean'),
    ordersCopyCommentsOnNewInvoice: _emberData.default.attr('boolean'),
    ordersDistinguishHeadOffice: _emberData.default.attr('boolean'),
    ordersShowCommentsOnPDF: _emberData.default.attr('boolean'),
    ordersShowFactorOnPDF: _emberData.default.attr('boolean'),
    ordersShowCompanyVATNumberOnPDF: _emberData.default.attr('boolean'),
    ordersShowCompanyRegistrationNumberOnPDF: _emberData.default.attr('boolean'),
    ordersShowCompanyNumberOnPDF: _emberData.default.attr('boolean'),
    ordersShowBankDetailsOnPDF: _emberData.default.attr('boolean'),
    ordersShowProjectReferenceOnPDF: _emberData.default.attr('boolean'),
    ordersShowResourcesNameOnPDF: _emberData.default.attr('boolean'),
    ordersShowAverageDailyPriceOnPDF: _emberData.default.attr('boolean'),
    ordersShowNumberOfWorkingDaysOnPDF: _emberData.default.attr('boolean'),
    ordersShowFooterOnPDF: _emberData.default.attr('boolean'),
    ordersSeparateActivityExpensesAndPurchases: _emberData.default.attr('boolean'),
    ordersSeparateExceptionalActivity: _emberData.default.attr('boolean'),
    ordersGroupDeliveries: _emberData.default.attr('boolean'),
    ordersGroupExpenses: _emberData.default.attr('boolean'),
    ordersAttachSignedTimesheets: _emberData.default.attr('boolean'),
    ordersAttachTimesheets: _emberData.default.attr('boolean'),
    ordersRequestTimesheetsSignature: _emberData.default.attr('boolean'),
    ordersAttachExpenses: _emberData.default.attr('boolean'),
    ordersShowInformationCommentsOnCustomerPdf: _emberData.default.attr('boolean'),
    ordersShowResourceFullNameOnCustomerPdf: _emberData.default.attr('boolean'),
    ordersShowWorkUnitTypeNameOnCustomerPdf: _emberData.default.attr('boolean'),
    ordersUseWorkUnitsForRegularDurationsOnCustomerPdf: _emberData.default.attr('boolean'),
    ordersMergeInvoiceAttachments: _emberData.default.attr('boolean'),
    ordersExpensesReinvoicedIncludingOrExcludingTax: _emberData.default.attr('string'),
    certificationEnabledOnExpenseReports: _emberData.default.attr('boolean'),
    productsTaxRate: _emberData.default.attr('number'),
    productsTaxRates: _emberData.default.attr(),
    productsAllowMultiplesTaxRates: _emberData.default.attr('boolean'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    generateQrInvoice: _emberData.default.attr('boolean'),
    qrInvoiceType: _emberData.default.attr('number'),
    exceptionalScales: (0, _attributes.fragmentArray)('exceptionalscale', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    workUnitTypes: (0, _attributes.fragmentArray)('workunittype'),
    expenseTypes: (0, _attributes.fragmentArray)('expensetype'),
    absencesReportsQuestions: (0, _attributes.fragmentArray)('absencesquestion'),
    ratePerKilometerTypes: (0, _attributes.fragmentArray)('rateperkmtype'),
    banksDetails: (0, _attributes.fragmentArray)('bankdetailfragment'),
    invoiceRecordTypes: (0, _attributes.fragmentArray)('invoicerecordtype'),
    absencesQuotas: (0, _attributes.fragmentArray)('absencequota'),
    advantagePaidExceptionalTypes: (0, _attributes.fragmentArray)('advantagepaidexceptionaltypes'),
    advantageTypes: (0, _attributes.fragmentArray)('advantagetype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    workplaceTypes: (0, _attributes.fragmentArray)('workplacetype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    factor: _emberData.default.belongsTo('company', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    punctualAdvantageTypes: Ember.computed('advantageTypes', function () {
      return this.get('advantageTypes').filterBy('frequency', _advantagetype.TYPE_PUNCTUAL);
    }),
    contractualAdvantageTypes: Ember.computed('advantageTypes', function () {
      return this.get('advantageTypes').filter(function (advantageType) {
        return advantageType.get('frequency') !== _advantagetype.TYPE_PUNCTUAL;
      });
    }),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }).readOnly(),
    allExpenseTypes: Ember.computed('expenseTypes', function () {
      var options = Ember.A([]);
      this.get('expenseTypes').forEach(function (expenseType) {
        options.pushObject({
          reference: expenseType.get('reference'),
          taxRate: expenseType.get('taxRate'),
          name: expenseType.get('name'),
          state: expenseType.get('state'),
          ocrcategory: expenseType.get('ocrcategory'),
          value: expenseType.get('value'),
          referenceForColor: expenseType.get('referenceForColor')
        });
      });

      // ajout de l'option des frais kilométriques
      options.pushObject({
        reference: _expensetype.travelExpensesTypeReference,
        value: this.get('i18n').t('models:expense.attributes.isKilometricExpense.name'),
        referenceForColor: "expense".concat(_expensetype.travelExpensesTypeReference % _expensetype.EXPENSE_COLOR_MODULO || _expensetype.EXPENSE_COLOR_MODULO)
      });
      return options;
    }).readOnly(),
    purchasesTaxRatesSelected: Ember.computed('purchasesTaxRates', 'purchasesAllowMultiplesTaxRates', function () {
      if (this.get('purchasesAllowMultiplesTaxRates')) return this.get('purchasesTaxRates');else return this.get('purchasesTaxRates.0');
    }).readOnly(),
    quotationsTaxRatesSelected: Ember.computed('quotationsTaxRates', 'quotationsAllowMultiplesTaxRates', function () {
      if (this.get('quotationsAllowMultiplesTaxRates')) return this.get('quotationsTaxRates');else return this.get('quotationsTaxRates.0');
    }).readOnly(),
    productsTaxRatesSelected: Ember.computed('productsTaxRates', 'productsAllowMultiplesTaxRates', function () {
      if (this.get('productsAllowMultiplesTaxRates')) return this.get('productsTaxRates');else return this.get('productsTaxRates.0');
    }).readOnly(),
    ordersTaxRatesSelected: Ember.computed('ordersTaxRates', 'ordersAllowMultiplesTaxRates', function () {
      if (this.get('ordersAllowMultiplesTaxRates')) return this.get('ordersTaxRates');else return this.get('ordersTaxRates.0');
    }).readOnly(),
    workUnitTypesList: Ember.computed('workUnitTypes.[]', function () {
      return this.get('workUnitTypes').toArray();
    }).readOnly()
  });
  Agency.reopenClass({
    prefix: 'AJU'
  });
  Agency.reopen({
    prefix: Ember.computed(function () {
      return Agency.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Agency;
});