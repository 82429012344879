define("boondmanager/pods/components/bm-field/row-additional-turnover/opportunity/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/cachecomputed"], function (_exports, _component, _cachecomputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    /**
     * Attributs par défaut de la balise __class__ du composant.
     *
     * @property classNames
     * @type Array
     * @default  ['bmc-field-row-additional-turnover_opportunity']
     */
    classNames: ['bmc-field-row-additional-turnover_opportunity'],
    canSetTypeOfAdditional: Ember.computed('i18n._locale', function () {
      return this.get('settings').customer.additionalTurnoverTypes.state;
    }).readOnly(),
    revertedItems: Ember.computed('model.additionalTurnoverAndCosts.[]', function () {
      return this.get('model.additionalTurnoverAndCosts').toArray().reverse();
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      var model = this.get('model');
      var opportunity = this.get('opportunity');
      var deleteAdditional = this.get('deleteAdditional');
      var addAdditional = this.get('addAdditional');
      var save = this.get('save');

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert('bm-field/row-additional-turnover/project must have a "name" attribute!', !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert('bm-field/row-additional-turnover/project "name" attribute must be a string!', Ember.typeOf(name) === 'string')); // check deleteAdditional
      (false && !(!Ember.isBlank(deleteAdditional)) && Ember.assert('bm-field/row-additional-turnover/project must have a "deleteAdditional" attribute!', !Ember.isBlank(deleteAdditional)));
      (false && !(Ember.typeOf(deleteAdditional) === 'function') && Ember.assert('bm-field/row-additional-turnover/project "deleteAdditional" attribute must be a function!', Ember.typeOf(deleteAdditional) === 'function')); // check addAdditional
      (false && !(!Ember.isBlank(addAdditional)) && Ember.assert('bm-field/row-additional-turnover/project must have a "addAdditional" attribute!', !Ember.isBlank(addAdditional)));
      (false && !(Ember.typeOf(addAdditional) === 'function') && Ember.assert('bm-field/row-additional-turnover/project "addAdditional" attribute must be a function!', Ember.typeOf(addAdditional) === 'function')); // check save
      (false && !(!Ember.isBlank(save)) && Ember.assert('bm-field/row-additional-turnover/project must have a "save" attribute!', !Ember.isBlank(save)));
      (false && !(Ember.typeOf(save) === 'function') && Ember.assert('bm-field/row-additional-turnover/project "save" attribute must be a function!', Ember.typeOf(save) === 'function')); // check opportunity
      (false && !(!Ember.isBlank(opportunity)) && Ember.assert('bm-field/row-additional-turnover/project must have a "opportunity" attribute!', !Ember.isBlank(opportunity))); // check model
      (false && !(!Ember.isBlank(model)) && Ember.assert('bm-field/row-additional-turnover/project must have a "model" attribute!', !Ember.isBlank(model)));
    },
    hasValue: (0, _cachecomputed.default)('item', function () {
      return !this.isBlankValue();
    }),
    isBlankValue: function isBlankValue() {
      return typeof this.get('revertedItems') === 'undefined' || this.get('revertedItems') === null || this.get('revertedItems').length === 0;
    },
    actions: {
      deleteRow: function deleteRow(item) {
        this.get('deleteAdditional')(item);
      },
      addAdditional: function addAdditional() {
        this.get('addAdditional')();
      },
      updateAdditionalTurnoverType: function updateAdditionalTurnoverType(item, value) {
        item.set('typeOf', value.id);
      },
      save: function save() {
        this.get('save')();
      }
    }
  });
});