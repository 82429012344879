define("boondmanager/pods/components/bm-field/translation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hd5PtaO+",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"bmc-field-translation-inline\"],[12],[2,\"\\n\"],[6,[37,9],null,[[\"noTitle\"],[true]],[[\"default\"],[{\"statements\":[[6,[37,8],null,[[\"name\",\"class\",\"options\",\"selected\",\"onchange\",\"disabled\"],[\"language\",\"translationLanguage\",[35,7],[35,0,[\"language\"]],[30,[36,6],[[32,0],[30,[36,5],[[35,0,[\"language\"]]],null]],null],[35,3]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,4],[[32,1,[\"icon\"]]],null]],[2,\" \"],[1,[32,1,[\"value\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,11],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@noTitle\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"octane/bm-field/input\",[[24,3,\"text\"],[24,\"data-name\",\"text\"],[16,\"maxlength\",[34,2]]],[[\"@value\",\"@disabled\"],[[34,0,[\"text\"]],[34,3]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[24,\"data-name\",\"button-delete-translation\"],[16,\"data-original-title\",[30,[36,1],[\"common:actions.delete\"],null]]],[[\"@iconOnly\",\"@disabled\",\"@onClick\"],[\"bmi-delete\",[32,0,[\"disabled\"]],[30,[36,6],[[32,0],\"deleteTranslation\",[35,12]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,13],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-field/textarea\",[],[[\"@name\",\"@value\",\"@placeholder\",\"@limit\"],[\"text\",[34,0,[\"text\"]],[30,[36,1],[\"components:bmFieldTranslation.writeDescription\"],null],[34,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"translation\",\"t\",\"maxLength\",\"disabled\",\"icon\",\"mut\",\"action\",\"languages\",\"bm-field/power-select\",\"bm-field\",\"isTextArea\",\"unless\",\"index\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/translation/template.hbs"
  });
});