define("boondmanager/models/account", ["exports", "ember-data", "boondmanager/models/resource"], function (_exports, _emberData, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Account = _resource.default.extend({
    copyableOptions: Object.freeze({
      copyByReference: ['resource', 'agency', 'mainManager', 'pole', 'role', 'otherPoles', 'otherAgencies', 'apps', 'advancedAppCalendar', 'advancedAppMail', 'advancedAppEmailing', 'stateReason', 'advancedAppViewer']
    }),
    lastLogInDate: _emberData.default.attr('moment'),
    resource: _emberData.default.belongsTo(),
    customer: _emberData.default.belongsTo(),
    //rights
    canWriteAccount: _emberData.default.attr('boolean'),
    canConnectAccount: _emberData.default.attr('boolean'),
    canDeleteAccount: _emberData.default.attr('boolean'),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'resource'
  });
  Account.reopenClass({
    prefix: 'COMP'
  });
  Account.reopen({
    prefix: Ember.computed(function () {
      return Account.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Account;
});