define("boondmanager/utils/activity", ["exports", "ember-copy", "moment", "boondmanager/models/project", "boondmanager/models/delivery", "boondmanager/models/batch"], function (_exports, _emberCopy, _moment, _project, _delivery, _batch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPE_PRODUCTION = _exports.TYPE_OPPORTUNITY = _exports.TYPE_INTERNAL = _exports.TYPE_ABSENCE = _exports.Activity = void 0;
  _exports.getDefaultActivity = getDefaultActivity;
  _exports.getDuration = getDuration;
  _exports.getIDsFromActivityID = getIDsFromActivityID;
  var TYPE_PRODUCTION = _exports.TYPE_PRODUCTION = 'production';
  var TYPE_INTERNAL = _exports.TYPE_INTERNAL = 'internal';
  var TYPE_ABSENCE = _exports.TYPE_ABSENCE = 'absence';
  var TYPE_OPPORTUNITY = _exports.TYPE_OPPORTUNITY = 'opportunity';

  /**
   * @module Utils
   * @submodule Activiy
   */

  /**
   * Extract Project, Delivery (and Batch - optional) IDs from Activity ID
   *
   * @param  {String}    activityID string of the form "${Project.prefix}${project.id} ${Delivery.prefix}${delivery.id} ${Batch.prefix}${batch.id}"
   * @return {String[]}  The 2 (project, delivery) or 3 (project, delivery and batch) IDs.
   */
  function getIDsFromActivityID(activityID) {
    var prefixes = [_project.default.prefix, _delivery.default.prefix, _batch.default.prefix];
    return activityID.split(' ').map(function (part, idx) {
      return part.replace(prefixes[idx], '');
    });
  }

  /**
   * To get a default activity from a list
   *
   * This method search first 'production' or first 'internal' or first 'absence'
   * activity in `activities` array and return it.
   *
   * @param  {Activity[]}  activities
   * @param  {Boolean}     returnCopy  if `true`, return a copy a the default activity found. Default is `false`.
   * @return {Activity}
   */
  function getDefaultActivity(activities) {
    var returnCopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var defaultActivity;
    if (activities.length) {
      [TYPE_PRODUCTION, TYPE_INTERNAL, TYPE_ABSENCE].some(function (type) {
        return defaultActivity = activities.find(function (activity) {
          return activity.type === type;
        });
      });
      if (typeof defaultActivity === 'undefined') {
        defaultActivity = activities[0];
      }
    }
    return returnCopy ? (0, _emberCopy.copy)(defaultActivity, true) : defaultActivity;
  }

  /**
   * Get real duration depending on diff in days and current duration
   * @param startDate
   * @param endDate
   * @param currentDate
   * @param duration
   * @returns {number}
   * @private
   */
  function getDuration(startDate, endDate, currentDate, duration) {
    // get decimal part to check how to convert total duration into partial duration
    var intPart = parseInt((duration + '').split('.')[0]);
    var decimalPart = parseInt((duration + '').split('.')[1]);
    var diffInDays = endDate.diff(startDate, 'days') + 1;
    var realDuration;

    // in case of decimal part and current is the same of end date of absence period
    // we return the decimal part only
    if (decimalPart > 0) {
      if (currentDate.isSame(endDate)) {
        var totalDuration = 0;
        var intDuration = diffInDays > 1 ? intPart / (diffInDays - 1) : intPart;
        var newStartDate = startDate.clone();
        do {
          totalDuration += intDuration;
          newStartDate.add(1, 'days');
        } while (newStartDate.isBefore(endDate));
        realDuration = duration - totalDuration;
      } else {
        realDuration = diffInDays > 1 ? intPart / (diffInDays - 1) : intPart;
      }
    } else {
      realDuration = duration / diffInDays;
    }
    return realDuration;
  }

  /**
   * Activity
   *
   * {{#crossLink "RegularTimesRow"}}RegularTimes row{{/crossLink}} use an activities'
   * array to display a select to define its
   * {{#crossLink "RegularTimesRow/selectedActivity"}}selected activity{{/crossLink}}.
   *
   * Notes:
   *   * if `type` = 'absence' || 'internal', we need `term` to define '(start|end)Date' CP.
   *   * if `type` = 'production', we need `project` & `delivery`. `company` & `batch` are optionals.
   *
   * @class Activity
   * @namespace BoondManager.Utils
   * @extends Ember.Object
   * @uses Ember.Copyable
   * @private
   */
  var Activity = _exports.Activity = Ember.Object.extend(Ember.Copyable, {
    i18n: Ember.inject.service(),
    /**
     * Term
     * @property term
     * @type {Moment}
     */

    /**
     * Type
     *
     * Sould be :
     *   * "production",
     *   * "absence",
     *   * "internal",
     *   * "exceptionalTime",
     *   * "exceptionalCalendar"
     *
     * @property type
     * @type {String}
     */

    /**
     * Disabled
     *
     * To know if this activity is disable (use in <select> to disallow the selection
     * of this activity)
     *
     * @property disabled
     * @type {Boolean}
     * @default  false
     */
    disabled: false,
    /**
     * Project
     * @property project
     * @type {Project}
     */

    /**
     * Delivery (depend of project)
     * @property delivery
     * @type {Delivery}
     */

    /**
     * Batch (depend of project)
     * @property batch
     * @type {Batch}
     */

    /**
     * Company (depend of project)
     * @property company
     * @type {Company}
     */

    /**
     * Activity ID
     *
     *   if activity's Type === 'production', Activity ID is equal to a string
     *   composed of each project, delivery and batch's "{PREFIX}{ID}"
     *   space-separated.
     *
     *   if activity's Type === 'internal', it's identical to 'production'
     *   type unless there is no project (& delivery) where Activity ID is
     *   equal to 'internal' (=== type).
     *
     *   Activity's ID is equal to activity's Type else.
     *
     * @property id
     * @type {String}
     * @readOnly
     */
    id: Ember.computed('type', 'project', 'delivery', 'batch', function () {
      var type = this.get('type');
      var id;
      switch (type) {
        case TYPE_INTERNAL:
        case TYPE_PRODUCTION:
          {
            // we test only project because if there is a project, a delivery is here too...
            var project = this.get('project');
            var delivery = this.get('delivery');
            var batch = this.get('batch');
            if (project) {
              id = "".concat(_project.default.prefix).concat(Ember.get(project, 'id'), " ").concat(_delivery.default.prefix).concat(Ember.get(delivery, 'id'));
              if (batch) {
                id += " ".concat(_batch.default.prefix).concat(Ember.get(batch, 'id'));
              }
            } else {
              id = type;
            }
            break;
          }
        default:
          id = type;
      }
      return id;
    }).readOnly(),
    /**
     * Value
     *
     * @property value
     * @type {String}
     * @readOnly
     */
    value: Ember.computed('i18n._locale', 'type', 'project', 'delivery', 'batch', 'company', function () {
      var type = this.get('type');
      var value;
      switch (type) {
        case TYPE_INTERNAL:
        case TYPE_PRODUCTION:
          {
            var project = this.get('project');
            if (project) {
              var _this$getProperties = this.getProperties('batch', 'company', 'delivery'),
                batch = _this$getProperties.batch,
                company = _this$getProperties.company,
                delivery = _this$getProperties.delivery;
              if (project instanceof _project.default) {
                value = "".concat(Ember.get(project, 'getReference'), " - ").concat(Ember.get(delivery, 'getReference'));
                if (company) {
                  value += " - ".concat(Ember.get(company, 'name'));
                }
                if (batch) {
                  if (typeof Ember.get(batch, 'getReference') !== 'undefined') {
                    value = "".concat(Ember.get(batch, 'getReference'), " - ").concat(value);
                  } else {
                    value = "".concat(_batch.default.prefix).concat(Ember.get(batch, 'id'), " - ").concat(value);
                  }
                }
              } else if (typeof Ember.get(project, 'reference') !== 'undefined' && typeof Ember.get(delivery, 'title') !== 'undefined') {
                value = Ember.get(project, 'reference') !== '' ? "".concat(Ember.get(project, 'reference'), " - ") : "".concat(_project.default.prefix).concat(Ember.get(project, 'id'), " - ");
                value += Ember.get(delivery, 'title') !== '' ? Ember.get(delivery, 'title') : "".concat(_delivery.default.prefix).concat(Ember.get(delivery, 'id'));
                if (company) {
                  value += " - ".concat(Ember.get(company, 'name'));
                }
                if (batch) {
                  if (typeof Ember.get(batch, 'getReference') !== 'undefined') {
                    value = "".concat(Ember.get(batch, 'getReference'), " - ").concat(value);
                  } else {
                    value = "".concat(_batch.default.prefix).concat(Ember.get(batch, 'id'), " - ").concat(value);
                  }
                }
              } else {
                value = "".concat(_project.default.prefix).concat(Ember.get(project, 'id'), " - ").concat(_delivery.default.prefix).concat(Ember.get(delivery, 'id'));
                if (company) {
                  value += " - ".concat(Ember.get(company, 'name'));
                }
                if (batch) {
                  if (typeof Ember.get(batch, 'getReference') !== 'undefined') {
                    value = "".concat(Ember.get(batch, 'getReference'), " - ").concat(value);
                  } else {
                    value = "".concat(_batch.default.prefix).concat(Ember.get(batch, 'id'), " - ").concat(value);
                  }
                }
              }
            } else {
              value = this.get('i18n').t("activityexpenses:view.".concat(type));
            }
            break;
          }
        default:
          value = this.get('i18n').t("activityexpenses:view.".concat(type));
      }
      return value;
    }).readOnly(),
    /**
     * Value for resourceplanner in project view
     *
     * @property value
     * @type {String}
     * @readOnly
     */
    resourcePlannerProjectValue: Ember.computed('i18n._locale', 'delivery', 'batch', function () {
      var value;
      var _this$getProperties2 = this.getProperties('batch', 'delivery'),
        batch = _this$getProperties2.batch,
        delivery = _this$getProperties2.delivery;
      if (typeof delivery.get('title') !== 'undefined') {
        value = Ember.get(delivery, 'title') !== '' ? Ember.get(delivery, 'title') : "".concat(_delivery.default.prefix).concat(Ember.get(delivery, 'id'));
        if (batch) {
          if (typeof Ember.get(batch, 'getReference') !== 'undefined') {
            value = "".concat(Ember.get(batch, 'getReference'), " - ").concat(value);
          } else {
            value = "".concat(_batch.default.prefix).concat(Ember.get(batch, 'id'), " - ").concat(value);
          }
        }
      } else {
        value = "".concat(_delivery.default.prefix).concat(Ember.get(delivery, 'id'));
        if (batch) {
          if (typeof Ember.get(batch, 'getReference') !== 'undefined') {
            value = "".concat(Ember.get(batch, 'getReference'), " - ").concat(value);
          } else {
            value = "".concat(_batch.default.prefix).concat(Ember.get(batch, 'id'), " - ").concat(value);
          }
        }
      }
      return value;
    }).readOnly(),
    // juste because power-select use `name` in our docs ;p
    name: Ember.computed.alias('value').readOnly(),
    /**
     * Start date
     *
     * @property startDate
     * @type {Moment}
     */
    startDate: Ember.computed('term', 'delivery', function () {
      var term = this.get('term');
      var delivery = this.get('delivery');
      var startDate = (0, _moment.default)(term).date(1);
      if (delivery) {
        var deliveryStartDate = this.get('delivery.startDate');
        if (deliveryStartDate && this.get('delivery.endDate')) {
          startDate = (0, _moment.default)(deliveryStartDate);
        } else {
          //Logger.error('regularTime delivery does not have startDate and endDate.');
        }
      }
      return startDate;
    }).readOnly(),
    /**
     * End date
     *
     * @property endDate
     * @type {Moment}
     */
    endDate: Ember.computed('term', 'delivery', function () {
      var term = this.get('term');
      var delivery = this.get('delivery');
      var endDate = (0, _moment.default)(term).endOf('month');
      if (delivery) {
        var deliveryEndDate = this.get('delivery.endDate');
        if (this.get('delivery.startDate') && deliveryEndDate) {
          endDate = (0, _moment.default)(deliveryEndDate);
        } else {
          //Logger.error('regularTime delivery does not have startDate and endDate.');
        }
      }
      return endDate;
    }).readOnly(),
    /**
     * Return a copy of this Activity object
     *
     *     const activityCopy = Ember.copy(originalActivity);
     *
     * @method copy
     * @param {undefined|Boolean} deep  if `false` or `true`, a copy is made with `Ember.copy(prop, deep)` for
     *                                  `project`, `delivery`, `batch` and `company` properties. if `undefined`,
     *                                  `project`, `delivery`, `batch` and `company` are copied by reference.
     * @return {Activity} copy of this Activity object
     * @private
     */
    copy: function copy(deep) {
      var _this$getProperties3 = this.getProperties('project', 'delivery', 'batch', 'company'),
        project = _this$getProperties3.project,
        delivery = _this$getProperties3.delivery,
        batch = _this$getProperties3.batch,
        company = _this$getProperties3.company;
      var owner = Ember.getOwner(this);
      var Activity = owner.factoryFor('bm:activity');
      return Activity.create({
        term: this.get('term').clone(),
        type: this.get('type'),
        project: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(project, deep) : project,
        delivery: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(delivery, deep) : delivery,
        batch: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(batch, deep) : batch,
        company: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(company, deep) : company
      });
    }
  });
  var _default = _exports.default = Activity;
});