define("boondmanager/models/webhook", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Webhook = _base.default.extend({
    type: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    entity: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    events: (0, _attributes.fragmentArray)('webhookevent'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    creationDateFormat: Ember.computed('creationDate', function () {
      if (!this.get('creationDate')) return '';
      return this.get('creationDate').format('DD/MM/YYYY');
    }),
    updateDateFormat: Ember.computed('updateDate', function () {
      if (!this.get('updateDate')) return '';
      return this.get('updateDate').format('DD/MM/YYYY');
    })
  });
  Webhook.reopenClass({
    prefix: 'WBH'
  });
  Webhook.reopen({
    prefix: Ember.computed(function () {
      return Webhook.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Webhook;
});