define("boondmanager/models/roletemplate", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _base, _emberDataCopyable, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RoleTemplate = _base.default.extend(_emberDataCopyable.default, {
    currentUser: Ember.inject.service(),
    copyableOptions: Object.freeze({
      copyByReference: ['apps', 'advancedAppCalendar', 'advancedAppMail', 'advancedAppEmailing', 'advancedAppViewer']
    }),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    translations: (0, _attributes.fragmentArray)('translation', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    advancedRights: _emberData.default.attr(),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    logInOnlyFromThirdParty: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    manageAllowedDevices: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    warningForNewDevice: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    apps: _emberData.default.hasMany(),
    advancedAppCalendar: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppMail: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppViewer: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    advancedAppEmailing: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    dashboards: _emberData.default.hasMany('dashboard', {
      inverse: null
    }),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }).readOnly(),
    translationsForName: Ember.computed('translations.{[].@each.attribute}', function () {
      return this.get('translations').filter(function (item) {
        return item.attribute === 'name';
      });
    }).readOnly(),
    translationsForDescription: Ember.computed('translations.{[].@each.attribute}', function () {
      return this.get('translations').filter(function (item) {
        return item.attribute === 'description';
      });
    }).readOnly(),
    nameTranslated: Ember.computed('translations.{[].@each.language,@each.attribute},currentUser.isFulfilled', function () {
      var _this = this;
      var findT = this.get('translations').find(function (tr) {
        return tr.language === _this.currentUser.get('language') && tr.attribute === 'name';
      });
      return findT ? findT.text : this.get('name');
    }).readOnly(),
    descriptionTranslated: Ember.computed('translations.{[].@each.language,@each.attribute},currentUser.isFulfilled', function () {
      var _this2 = this;
      var findT = this.get('translations').find(function (tr) {
        return tr.language === _this2.currentUser.get('language') && tr.attribute === 'description';
      });
      return findT ? findT.text : this.get('description');
    }).readOnly()
  });
  RoleTemplate.reopenClass({
    prefix: 'ROLETPL'
  });
  RoleTemplate.reopen({
    prefix: Ember.computed(function () {
      return RoleTemplate.prefix;
    }).readOnly()
  });
  var _default = _exports.default = RoleTemplate;
});