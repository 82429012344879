define("boondmanager/serializers/exceptionaltime", ["exports", "boondmanager/serializers/fragment", "moment", "boondmanager/models/workunittype"], function (_exports, _fragment, _moment, _workunittype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    // FRONT -> BACK
    serialize: function serialize(snapshot /*, options*/) {
      var json = this._super.apply(this, arguments);
      if (snapshot.attr('workUnitType').activityType === _workunittype.WUT_EXCEPTIONAL_CALENDAR) {
        json.startDate = snapshot.attr('startDate').format('YYYY-MM-DD');
        json.endDate = snapshot.attr('endDate').format('YYYY-MM-DD');
      }
      return json;
    },
    // BACK -> FRONT
    normalize: function normalize(_, resourceHash) {
      var normalized = this._super.apply(this, arguments);
      normalized.data.attributes.startDate = _moment.default.tz(resourceHash.startDate, 'Europe/Paris');
      normalized.data.attributes.endDate = _moment.default.tz(resourceHash.endDate, 'Europe/Paris');
      return normalized;
    }
  });
});