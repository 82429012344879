define("boondmanager/pods/components/octane/bm-search-filters/fields/simple-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ufekofa/",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[6,[37,0],[[32,1,[\"displayFilter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[30,[36,1],[\"col-12\",[30,[36,0],[[32,1,[\"minimal\"]],\" col-xl-6\"],null]],null]],[12],[2,\"\\n            \"],[8,\"bm-field\",[],[[\"@name\"],[[32,1,[\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,4],null,[[\"addMissingSelected\",\"name\",\"options\",\"selected\",\"forceSearch\",\"multiple\",\"allowClear\",\"onchange\",\"placeholder\"],[false,[32,1,[\"label\"]],[32,1,[\"options\"]],[32,1,[\"modalValue\"]],false,false,[30,[36,3],[[32,1,[\"required\"]]],null],[32,0,[\"onChange\"]],[30,[36,2],[\"common:indifferent\"],null]]]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"t\",\"not\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/simple-select/template.hbs"
  });
});