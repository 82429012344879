define("boondmanager/models/followeddocument", ["exports", "boondmanager/models/base", "ember-data-copyable", "ember-data"], function (_exports, _base, _emberDataCopyable, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_NOT_FOLLOWED = void 0;
  var STATE_NOT_FOLLOWED = _exports.STATE_NOT_FOLLOWED = 0;
  var FollowedDocument = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['dependsOn']
    }),
    typeOf: _emberData.default.attr('number'),
    state: _emberData.default.attr('number'),
    issuedDate: _emberData.default.attr('date'),
    expirationDate: _emberData.default.attr('date'),
    description: _emberData.default.attr('string'),
    files: _emberData.default.hasMany('document'),
    numberOfFiles: _emberData.default.attr('number'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    dependsOn: _emberData.default.belongsTo('base', {
      inverse: null
    }),
    //right
    canReadFollowedDocument: _emberData.default.attr('boolean'),
    canWriteFollowedDocument: _emberData.default.attr('boolean'),
    // Droit d'écriture ( par procuration de suppresion)
    /**************************************************************************/
    /* /JSDoc - typedef *******************************************************/
    /**************************************************************************/

    /**
     * Translated state
     *
     * @property stateValue
     * @type {state}
     *
     * If i18n.locale = "fr" and state = 1, return:
     * ```
     *   "Suivi"
     * ```
     */
    stateValue: Ember.computed('state', 'i18n._locale', function () {
      var state = this.get('state');
      var stateValue = this.get('i18n').t('models:followeddocument.attributes.state.values', {
        returnObjects: true
      }).find(function (item) {
        return item.id === state;
      });
      return stateValue ? stateValue.value : state;
    })
  });
  FollowedDocument.reopenClass({
    prefix: 'DAS'
  });
  FollowedDocument.reopen({
    prefix: Ember.computed(function () {
      return FollowedDocument.prefix;
    }).readOnly()
  });
  var _default = _exports.default = FollowedDocument;
});