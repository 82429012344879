define("boondmanager/models/quotationrecord", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/utils/financial"], function (_exports, _emberData, _emberDataModelFragments, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    amountExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    quantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    taxRate: _emberData.default.attr('number'),
    taxRates: _emberData.default.attr(),
    owner: _emberDataModelFragments.default.fragmentOwner(),
    isTaxRatesMultiples: Ember.computed('taxRates', 'owner.opportunity.agency.quotationsAllowMultiplesTaxRates', function () {
      return this.get('taxRates').length > 1 || this.get('owner.opportunity.agency.quotationsAllowMultiplesTaxRates');
    }).readOnly(),
    taxRatesSelected: Ember.computed('isTaxRatesMultiples', 'taxRates', function () {
      if (this.get('isTaxRatesMultiples')) return this.get('taxRates');else return this.get('taxRates.0');
    }),
    turnoverExcludingTax: Ember.computed('quantity', 'amountExcludingTax', 'owner.locked', 'totalAmountExcludingTax', function () {
      var exchangeRate = this.get('owner.exchangeRate');
      var exchangeRateAgency = this.get('owner.exchangeRateAgency');
      var quantity = this.get('quantity') || 0;
      var amountExcludingTax = this.get('amountExcludingTax') || 0;
      return _financial.default.calculateTotalAmountExcludingTax(amountExcludingTax, quantity, exchangeRate, exchangeRateAgency);
    }).readOnly(),
    turnoverIncludingTax: Ember.computed('amountExcludingTax', 'quantity', 'taxRates', 'owner.locked', 'totalAmountIncludingTax', function () {
      var exchangeRate = this.get('owner.exchangeRate');
      var exchangeRateAgency = this.get('owner.exchangeRateAgency');
      var quantity = this.get('quantity') || 0;
      var taxRates = this.get('taxRates') || [0];
      var amountExcludingTax = this.get('amountExcludingTax') || 0;
      return _financial.default.calculateTotalAmountIncludingTax(amountExcludingTax, quantity, taxRates, exchangeRate, exchangeRateAgency);
    }).readOnly()
  });
});