define("boondmanager/pods/components/bm-field/simple-colorpicker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eLL0+s1W",
    "block": "{\"symbols\":[\"color\"],\"statements\":[[8,\"power-select\",[],[[\"@triggerClass\",\"@dropdownClass\",\"@selectedItemComponent\",\"@extra\",\"@options\",\"@selected\",\"@matchTriggerWidth\",\"@calculatePosition\",\"@onChange\"],[\"bmc-field-simple-colorpicker-trigger\",\"bmc-field-simple-colorpicker-options\",\"bm-field/simple-colorpicker/selected-item\",[30,[36,1],null,[[\"fieldname\"],[[35,0]]]],[34,2],[34,3],false,[32,0,[\"_calculatePosition\"]],[30,[36,4],[[32,0],\"onChange\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-field-simple-colorpicker-color\"],[15,5,[30,[36,6],[[30,[36,5],[\"background: \",[32,1,[\"value\"]],\";\"],null]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fieldname\",\"hash\",\"options\",\"selected\",\"action\",\"concat\",\"html-safe\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/simple-colorpicker/template.hbs"
  });
});