define("boondmanager/pods/components/bm-field/power-select/rights/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TwfglWh+",
    "block": "{\"symbols\":[\"@triggerIcon\",\"@triggerPlaceholder\"],\"statements\":[[10,\"span\"],[14,0,\"ember-power-select-trigger-item\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1]],null]],[2,\" \"],[1,[32,2]],[2,\"\\n\\t\"],[1,[30,[36,0],[\"bmi-caret-down\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/rights/trigger/template.hbs"
  });
});