define("boondmanager/models/appemailingcontact", ["exports", "boondmanager/models/contact", "ember-data"], function (_exports, _contact, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsEMailingContact = _contact.default.extend({
    allowedStates: _emberData.default.attr(''),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'contact',
    canReadRecord: Ember.computed.alias('canReadContact').readOnly(),
    emailsOptions: Ember.computed('email1', 'email2', 'email3', function () {
      var options = [];
      var emails = [];
      var distinct = function distinct(value, index, self) {
        return self.indexOf(value) === index && value;
      };
      emails.push(this.get('email1'));
      emails.push(this.get('email2'));
      emails.push(this.get('email3'));
      var distinctEmails = emails.filter(distinct);
      distinctEmails.forEach(function (email) {
        options.push({
          id: email,
          value: email
        });
      });
      return options;
    }).readOnly(),
    entityName: Ember.computed('lastName', 'firstName', function () {
      var lastName = this.get('lastName');
      var firstName = this.get('firstName');
      if (Ember.isBlank(lastName) && Ember.isBlank(firstName)) {
        return this.get('internalReference');
      } else {
        return "".concat(lastName, " ").concat(firstName);
      }
    }).readOnly(),
    recordRouteName: Ember.computed('', function () {
      return 'private.contacts.contact.overview';
    }).readOnly()
  });
  AppsEMailingContact.reopenClass({
    prefix: 'CCON'
  });
  AppsEMailingContact.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingContact.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingContact;
});