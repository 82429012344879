define("boondmanager/models/appresourceplannerproject", ["exports", "boondmanager/models/project", "boondmanager/models/delivery", "boondmanager/models/batch", "boondmanager/utils/logger", "moment", "ember-copy", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "boondmanager/utils/financial", "ember-data", "boondmanager/utils/activity"], function (_exports, _project, _delivery, _batch, _logger, _moment, _emberCopy, _fragment, _attributes, _financial, _emberData, _activity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getActivityIDFromPlannedTime = _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  /**
   * To get Activity ID from a plannedTime fragment
   *
   * plannedTime is a fragment so its properties `project`, `delivery` and
   * `batch` are not Ember `DS.Model` but "simple" POJO ! So they do not
   * have CP like `getReference` :/
   *
   * Activity ID is equal to plannedTime's workUnitType.activityType if its type is
   * not 'production', else it's project's internal + space + ref delivery's internal ref
   * + batch's internal ref (optional). But we can not use 'internalReference' CP :'( So
   * we build it...
   *
   * cf GET Activity.id CP.
   *
   * @param  {Project} project
   * @param  {PlannedTime} objTime
   * @return {String|undefined}
   */
  var getActivityIDFromPlannedTime = _exports.getActivityIDFromPlannedTime = function getActivityIDFromPlannedTime(project, objTime) {
    var type = objTime.get('workUnitType.activityType');
    var id;
    switch (type) {
      case _activity.TYPE_PRODUCTION:
        {
          var _objTime$getPropertie = objTime.getProperties('delivery', 'batch'),
            delivery = _objTime$getPropertie.delivery,
            batch = _objTime$getPropertie.batch;
          if (project && delivery) {
            id = "".concat(_project.default.prefix).concat(project.id, " ").concat(_delivery.default.prefix).concat(objTime.get('delivery.id'));
            if (batch) {
              id += " ".concat(_batch.default.prefix).concat(objTime.get('batch.id'));
            }
          }
          break;
        }
      default:
        id = type;
    }
    return id;
  };

  /**
   * Fake Regular Time (FRT) object
   *
   * {{#crossLink "PlannedTime"}}plannedTime{{/crossLink}} are fragments. But we need
   * FRT objects to represent empty plannedTime in our
   * {{#crossLink "PlannedTimesRow/plannedtimes"}}`plannedtimes` array{{/crossLink}}
   * defined in each {{#crossLink "PlannedTimesRow"}}PlannedTimes row{{/crossLink}}
   *
   * @class FakePlannedTime
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var FakePlannedTime = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Flag to identify this object as a "fake"
     *
     * @property isFake
     * @type {Boolean}
     * @default  true
     * @readOnly
     */
    isFake: Ember.computed(function () {
      return true;
    }).readOnly(),
    /**
     * Start date
     *
     * @property startDate
     * @type {Moment}
     */

    /**
     * Value
     *
     * @property startDate
     * @type {Moment}
     * @default  ''
     * @readOnly
     */
    value: Ember.computed(function () {
      return '';
    }).readOnly(),
    /**
     * Return a copy of this FakePlannedTime (FRT) object
     *
     *     const FRTCopy = Ember.copy(originalFRT);
     *
     * @method copy
     * @return {FakePlannedTime} copy of this FRT object
     * @private
     */
    copy: function copy() {
      return FakePlannedTime.create({
        startDate: this.get('startDate').clone()
      });
    },
    /**
     * Return a POJO representation
     *
     * The POJO has 'isFake', 'startDate' and 'value' properties.
     *
     * CAUTION: `startDate` is not a moment clone! It's the **same** Moment
     *          object present in this FRT object! If you want a POJO as 'deep'
     *          copy, use `POJO = FRTObj.copy().toJSON()` to not share the same
     *          Moment instance beetwen `POJO.startDate` and `FRTObj.startDate`
     *
     * @return {Object}
     */
    toJSON: function toJSON() {
      return this.getProperties('isFake', 'startDate', 'value');
    }
  });

  /**
   * Regular Time Row (RTR)
   *
   * Independants props :
   *   * row
   *   * fillstate
   *
   * Interdependant props (init order) :
   *   1. term
   *   2. activities
   *   3. workUnitTypes
   *   3. selectedActivity ( -> activities & workUnitTypes)
   *   4. selectedWorkUnitType ( -> selectedActivity & workUnitTypes)
   *
   * @class PlannedTimesRow
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var PlannedTimesRow = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Term
     *
     * @property term
     * @type {Moment}
     */
    /**
     * Comment
     *
     * @property comment
     * @type {String}
     */
    /**
     * Resources
     *
     * @property resources
     * @type {*}
     */

    /**
     * Row Number
     *
     * if < 0, it's a new row
     *
     * @property row
     * @type {Number}
     * @default -1
     */
    row: Ember.computed(function () {
      return -1;
    }),
    /**
     * Row's fill state
     *
     * To know if all plannedTimes of this row are filled. Used when user click
     * on "fill" button of the row
     *
     * @property fillState
     * @type {Boolean}
     * @default false
     */
    fillState: Ember.computed(function () {
      return false;
    }),
    /**
     * Activities
     *
     * List of all activities
     *
     * @property activities
     * @type {Activity[]}
     */

    /**
     * Selected Resource
     *
     * @property selectedResource
     * @type {Resource}
     * @default if exists, first resource
     */

    selectedResource: Ember.computed({
      get: function get() {
        // by default, we considere it's the first new row
        var firstResource = this.get('resources').get('firstObject');
        var selectedResource;
        if (firstResource && !firstResource.disabled) {
          selectedResource = {
            id: firstResource.get('id'),
            firstName: firstResource.get('firstName'),
            lastName: firstResource.get('lastName')
          };
        }
        if (typeof selectedResource === 'undefined') {
          _logger.default.warn('Model appresourceplannerproject - GET selectedResource CP - selectedResource is undefined...');
        }
        return selectedResource;
      },
      set: function set(_, newResource, oldResource) {
        var _this = this;
        var searchedID = Ember.get(newResource, 'id');
        var foundResource = this.get('resources').find(function (resource) {
          return resource.get('id') === searchedID;
        });
        // ici, on va créer un objet ressource JS de base afin d'éviter un problème de sérialisation JS avec des références circulaires
        // pour exemple, le model appresourceplannerresource contient un tableau de relationships appresourceplannerproject
        // ici, si on renvoit un model appresourceplannerressource, alors le projet actuel (ce model appresourceplannerproject) est
        // également présent dans le tableau de relationships du model appresourceplannerresource et donc lors de l'enregistrement
        // une erreur javascript apparait indiquant : "catch _save error: Converting circular structure to JSON"
        var selectedResource = foundResource ? {
          id: foundResource.get('id'),
          realId: foundResource.get('realId'),
          firstName: foundResource.get('firstName'),
          lastName: foundResource.get('lastName'),
          canReadResource: foundResource.get('canReadResource'),
          currentWorkUnitRate: foundResource.get('currentWorkUnitRate')
        } : oldResource;
        if (typeof selectedResource === 'undefined') {
          _logger.default.warn('Model appresourceplannerproject - SET selectedResource CP - newResource not find in resources array!', newResource, this.get('resources'));
        } else {
          this.set('selectedWorkUnitRate', selectedResource.currentWorkUnitRate);
          this.get('plannedTimes').forEach(function (plannedTime) {
            if ((0, _fragment.isFragment)(plannedTime)) {
              plannedTime.setProperties({
                workUnitType: _this.get('selectedWorkUnitType'),
                workUnitRate: selectedResource.currentWorkUnitRate,
                resource: (0, _emberCopy.copy)(selectedResource)
              });
            }
          });
        }
        return selectedResource;
      }
    }),
    selectedResourceEntity: Ember.computed('selectedResource', function () {
      var _this2 = this;
      return this.get('resources').find(function (resource) {
        return resource.get('id') === _this2.get('selectedResource.id');
      });
    }).readOnly(),
    /**
     * Selected Activity
     *
     * When selected activity is updated, the
     * {{#crossLink "PlannedTimesRow/selectedWorkUnitType}}selected WUT{{/crossLink}}
     * is reset to its default value. Each
     * {{#crossLink "PlannedTimesRow/plannedTimes}}plannedTimes{{/crossLink}}
     * item is then updated too.
     *
     * @see PlannedTimesRow.activities
     *
     * @property selectedActivity
     * @type {Activity}
     * @default if exists, first production activity, else first activity with first non empty WUT
     */
    selectedActivity: Ember.computed({
      get: function get() {
        // by default, we considere it's the first new row
        var firstActivity = this.get('activities').get('firstObject');
        var selectedActivity;
        if (firstActivity) {
          selectedActivity = firstActivity;
        }
        if (typeof selectedActivity === 'undefined') {
          _logger.default.warn('Model appresourceplannerproject - GET selectedActivity CP - selectedActivity is undefined...');
        }
        return selectedActivity;
      },
      set: function set(_, newActivity, oldActivity) {
        // here, newActivity could be an Activity object or a simple POJO with an `id` property
        var searchedID = Ember.get(newActivity, 'id');
        var selectedActivity = this.get('activities').find(function (activity) {
          return activity.get('id') === searchedID;
        }) || oldActivity;
        if (typeof selectedActivity === 'undefined') {
          _logger.default.warn('Model appresourceplannerproject - SET selectedActivity CP - newActivity not find in activities array!', newActivity, this.get('activities'));
        } else {
          var _selectedActivity$get = selectedActivity.getProperties('project', 'delivery', 'batch', 'opportunity'),
            project = _selectedActivity$get.project,
            delivery = _selectedActivity$get.delivery,
            batch = _selectedActivity$get.batch,
            opportunity = _selectedActivity$get.opportunity;
          var propertiesToSet = {};
          var selectedDelivery = this.get('selectedResourceEntity').get('deliveries').find(function (deliveryItem) {
            return deliveryItem.get('id') === Ember.get(delivery, 'id');
          });
          propertiesToSet.project = {
            id: project.id,
            reference: project.reference // used in totalsByActivity CP
          };

          propertiesToSet.delivery = {
            id: Ember.get(delivery, 'id'),
            canReadDelivery: selectedDelivery.get('canReadDelivery')
          };
          propertiesToSet.batch = batch ? {
            id: Ember.get(batch, 'id')
          } : null;
          propertiesToSet.opportunity = opportunity ? {
            id: Ember.get(opportunity, 'id')
          } : null;
          this.get('plannedTimes').forEach(function (plannedTime) {
            if ((0, _fragment.isFragment)(plannedTime)) {
              plannedTime.setProperties(propertiesToSet);
            }
          });
        }
        return selectedActivity;
      }
    }),
    selectedActivityName: Ember.computed('selectedActivity', function () {
      var selectedActivity = this.get('selectedActivity');
      var value = '';
      if (Ember.get(selectedActivity, 'batch.title')) {
        value += "".concat(Ember.get(selectedActivity, 'batch.title'), " - ");
      } else if (Ember.get(selectedActivity, 'batch.id')) {
        value += "".concat(Ember.get(selectedActivity, 'batch.id'), " - ");
      }
      value += Ember.get(selectedActivity, 'delivery.getReference');
      return value;
    }).readOnly(),
    /**
     * Availables activities for the selectedResource
     *
     * @property activitiesOptions
     * @type {Array}
     * @default []
     */
    activitiesOptions: Ember.computed('selectedResource', 'selectedActivity', 'activities', function () {
      var selectedResource = this.get('selectedResource');
      var selectedActivity = this.get('selectedActivity');
      var activities = this.get('activities');
      var options = [];
      if (selectedResource) {
        var allowedActivities = activities.filter(function (activity) {
          return Ember.get(activity, 'resource.id') === Ember.get(selectedResource, 'id');
        });
        allowedActivities.forEach(function (activity) {
          options.push(activity);
        });
      }

      // check if selected activity exists in options, else add it (in case of deleted deliveries)
      if (selectedActivity) {
        var selectActivityExists = options.findBy('id', selectedActivity.id);
        if (!selectActivityExists) {
          options.pushObject(selectedActivity);
        }
      }
      return options;
    }).readOnly(),
    /**
     * Regular times by day (0-based).
     *
     * Array's length = number of days in the month (cf term)
     *
     * @property plannedTimes
     * @uses  _regularTimes
     * @type {(Fake)PlannedTime[]}
     * @readOnly
     */
    plannedTimes: Ember.computed('term', function () {
      var term = this.get('term');
      var daysInMonth = term.daysInMonth();
      var plannedTimes = Ember.A();
      for (var i = 0; i < daysInMonth; i++) {
        plannedTimes.push(FakePlannedTime.create({
          startDate: term.clone().date(i + 1)
        }));
      }
      return plannedTimes;
    }).readOnly(),
    // TODO code en commun avec la CP `plannedTimes` (cf le switch)
    getNormalizedPlannedTimePOJO: function getNormalizedPlannedTimePOJO(plannedTime) {
      var normalizedPOJO = {};
      if (plannedTime && ((0, _fragment.isFragment)(plannedTime) || typeof plannedTime.toJSON === 'function')) {
        normalizedPOJO = (0, _emberCopy.copy)(plannedTime).toJSON();
      }
      var _this$getProperties = this.getProperties('row', 'selectedResource', 'selectedActivity', 'selectedWorkUnitType'),
        row = _this$getProperties.row,
        selectedResource = _this$getProperties.selectedResource,
        selectedActivity = _this$getProperties.selectedActivity,
        selectedWorkUnitType = _this$getProperties.selectedWorkUnitType;
      var _selectedActivity$get2 = selectedActivity.getProperties('project', 'delivery', 'batch', 'opportunity'),
        project = _selectedActivity$get2.project,
        delivery = _selectedActivity$get2.delivery,
        batch = _selectedActivity$get2.batch,
        opportunity = _selectedActivity$get2.opportunity;
      normalizedPOJO.row = row;
      normalizedPOJO.project = {
        id: project.id,
        reference: project.reference // used in totalsByActivity CP
      };

      normalizedPOJO.delivery = {
        id: Ember.get(delivery, 'id')
      };
      normalizedPOJO.batch = batch ? {
        id: Ember.get(batch, 'id')
      } : null;
      normalizedPOJO.opportunity = opportunity ? {
        id: Ember.get(opportunity, 'id')
      } : null;
      normalizedPOJO.resource = selectedResource ? {
        id: Ember.get(selectedResource, 'id')
      } : null;
      normalizedPOJO.duration = 0;
      normalizedPOJO.workUnitType = selectedWorkUnitType ? {
        reference: selectedWorkUnitType.reference
      } : null;
      if (typeof normalizedPOJO.startDate === 'undefined') {
        _logger.default.error('appresourceplannerproject model - normalizePlannedTime - startDate undefined', normalizedPOJO);
        normalizedPOJO.startDate = (0, _moment.default)();
      }
      if (typeof normalizedPOJO.value !== 'undefined') {
        delete normalizedPOJO.value;
      }
      return normalizedPOJO;
    },
    /**
     * Del a plannedTime = Replace a plannedTime with  a fakePlannedTime
     *
     * @method delPlannedTime
     * @param {plannedTime} plannedTime the plannedTime to replace
     */
    delPlannedTime: function delPlannedTime(plannedTime) {
      if ((0, _fragment.isFragment)(plannedTime)) {
        // Replace Ember fragment by JS object
        var dayIndex = plannedTime.get('startDate').date() - 1;
        var plannedTimes = this.get('plannedTimes');

        // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
        plannedTimes[dayIndex] = FakePlannedTime.create({
          startDate: plannedTime.get('startDate').clone()
        });
        // Tell plannedTimes that it has changed for template update
        plannedTimes.arrayContentDidChange(dayIndex);
      }
    },
    creation: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    edition: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    isReadonly: Ember.computed('edition', 'creation', function () {
      return !this.get('edition') && !this.get('creation');
    }).readOnly(),
    totalActivity: Ember.computed('plannedTimes.@each.{duration,value}', function () {
      return this.get('plannedTimes').reduce(function (totals, plannedTime) {
        var duration = parseFloat(plannedTime.get('duration')) || 0;
        totals.days += duration;
        totals.workUnits += (0, _financial.roundAtPrecision)(plannedTime.get('value'), 3);
        return totals;
      }, {
        days: 0,
        workUnits: 0
      });
    }).readOnly(),
    totalsByDay: Ember.computed('plannedTimes.@each.{startDate,value}', function () {
      var plannedTimes = this.get('plannedTimes');
      return plannedTimes.reduce(function (totals, plannedTime) {
        var dayNumber = (0, _moment.default)(plannedTime.get('startDate')).format('D');
        totals[dayNumber] = totals[dayNumber] || 0;
        totals[dayNumber] += (0, _financial.roundAtPrecision)(plannedTime.get('value') || 0, 3);
        return totals;
      }, {});
    }).readOnly(),
    /**
     * Start edition on fixed expense row
     * @param plannedTimeRow
     * @private
     */
    startPlannedTimeRowEdition: function startPlannedTimeRowEdition() {
      // backup current values to restore them if user click on cancel button
      var plannedTimeRowOriginalValues = {
        selectedResource: {
          id: this.get('selectedResource.id')
        },
        selectedActivity: {
          id: this.get('selectedActivity.id')
        },
        plannedTimes: []
      };
      var plannedTimes = this.get('plannedTimes');
      if (plannedTimes) {
        plannedTimes.forEach(function (plannedTime, dayIndex) {
          plannedTimeRowOriginalValues.plannedTimes.push({
            dayIndex: dayIndex,
            value: plannedTime.value
          });
        });
      }
      this.set('originalValues', plannedTimeRowOriginalValues);
      this.set('edition', true);
    },
    /**
     * Confirm edition validation on fixed expense row
     * @param plannedTimeRow
     * @private
     */
    confirmPlannedTimeRowEdition: function confirmPlannedTimeRowEdition() {
      // clear original values and do nothing, if edition is confirmed, current values are now the last setted values
      this.set('originalValues', undefined);
      this.set('edition', false);
      this.set('creation', false);
    },
    /**
     * Update comment for planned times row
     * @param comment
     */
    updateComment: function updateComment(comment) {
      var plannedTimes = this.get('plannedTimes');
      plannedTimes.forEach(function (plannedTime) {
        if (plannedTime.get('duration')) {
          plannedTime.set('comment', comment);
        }
      });
      this.set('comment', comment);
    },
    /**
     * Return a copy of this PlannedTimesRow (RTR) object
     *
     *     const RTRCopy = Ember.copy(originalRTR);
     *
     * @method copy
     * @return {PlannedTimesRow} copy of this RTR object
     * @private
     */
    copy: function copy(deep) {
      var _this$getProperties2 = this.getProperties('term', 'row', 'fillState', 'Activities', 'Resources', 'selectedActivity', 'selectedResource'),
        term = _this$getProperties2.term,
        row = _this$getProperties2.row,
        fillState = _this$getProperties2.fillState,
        Activities = _this$getProperties2.Activities,
        Resources = _this$getProperties2.Resources,
        selectedActivity = _this$getProperties2.selectedActivity,
        selectedResource = _this$getProperties2.selectedResource;
      return PlannedTimesRow.create({
        term: term.clone(),
        row: row,
        fillState: fillState,
        Activities: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(Activities, deep) : Activities,
        Resources: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(Resources, deep) : Resources,
        selectedActivity: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(selectedActivity, deep) : selectedActivity,
        selectedResource: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(selectedResource, deep) : selectedResource
      });
    }
  });
  var AppResourcePlannerProject = _project.default.extend({
    /** SERVICES */
    i18n: Ember.inject.service(),
    /** PROPERTIES */
    term: _emberData.default.attr('date'),
    validationDate: _emberData.default.attr('date'),
    reference: _emberData.default.attr('string'),
    signedTime: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    takenTimeToJ: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    plannedTimeFromJ: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    takenTimeInWorkUnitsToJ: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    plannedTimeInWorkUnitsFromJ: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    /** RELATIONSHIPS */
    resources: _emberData.default.hasMany('appresourceplannerresource', {
      inverse: null
    }),
    deliveries: _emberData.default.hasMany('delivery', {
      inverse: null
    }),
    // contient des presta ou des groupements
    company: _emberData.default.belongsTo(),
    contact: _emberData.default.belongsTo(),
    batches: _emberData.default.hasMany(),
    opportunity: _emberData.default.belongsTo(),
    validator: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    /**
     * List of planned times
     * @type {PlannedTime[]}
     */
    plannedTimes: (0, _attributes.fragmentArray)('plannedtimeproject'),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'project',
    signedTimeInWorkUnits: Ember.computed('signedTime', 'workUnitRate', function () {
      var value = this.get('signedTime') * this.get('workUnitRate');
      return isNaN(value) ? 0 : value;
    }),
    restBudgetToTake: Ember.computed('signedTime', 'takenTimeToJ', function () {
      var value = this.get('signedTime') - this.get('takenTimeToJ');
      return isNaN(value) ? 0 : value;
    }),
    restBudgetInWorkUnitsToTake: Ember.computed('signedTimeInWorkUnits', 'takenTimeInWorkUnitsToJ', function () {
      var value = this.get('signedTimeInWorkUnits') - this.get('takenTimeInWorkUnitsToJ');
      return isNaN(value) ? 0 : value;
    }),
    restBudgetToPlan: Ember.computed('restBudgetToTake', 'plannedTimeFromJ', function () {
      var value = this.get('restBudgetToTake') - this.get('plannedTimeFromJ');
      return isNaN(value) ? 0 : value;
    }).readOnly(),
    restBudgetInWorkUnitsToPlan: Ember.computed('restBudgetInWorkUnitsToTake', 'plannedTimeInWorkUnitsFromJ', function () {
      var value = this.get('restBudgetInWorkUnitsToTake') - this.get('plannedTimeInWorkUnitsFromJ');
      return isNaN(value) ? 0 : value;
    }).readOnly(),
    /**
     * Production workunittype
     */
    defaultWorkUnitType: Ember.computed('i18n._locale', function () {
      return {
        reference: 1,
        activityType: _activity.TYPE_PRODUCTION,
        name: this.get('settingsService').customer.typeOf.activity.find(function (activity) {
          return activity.id === 0;
        }).value
      };
    }).readOnly(),
    totalDays: Ember.computed('plannedTimes.@each.duration', function () {
      var plannedTimes = this.get('plannedTimes');
      var totals = 0;
      plannedTimes.forEach(function (plannedTime) {
        totals += plannedTime.get('duration');
      });
      return (0, _financial.roundAtPrecision)(totals, 3);
    }),
    totalDaysInWorkUnits: Ember.computed('plannedTimes.@each.value', function () {
      var plannedTimes = this.get('plannedTimes');
      var totals = 0;
      plannedTimes.forEach(function (plannedTime) {
        totals += plannedTime.get('value');
      });
      return (0, _financial.roundAtPrecision)(totals, 3);
    }),
    resourcesOptions: Ember.computed('resources', function () {
      var promise = this.get('resources').then(function (resources) {
        var options = [];
        resources.forEach(function (resource) {
          options.push({
            id: resource.get('id'),
            firstName: resource.get('firstName'),
            lastName: resource.get('lastName')
          });
        });
        return options;
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    totalsByDaysAndResource: Ember.computed('plannedTimes.@each.{value,resource}', function () {
      return this.get('plannedTimes').reduce(function (totals, plannedTime) {
        var resourceId = plannedTime.get('resource.id');
        var dayNumber = (0, _moment.default)(plannedTime.get('startDate')).format('D');
        if (!totals[resourceId]) totals[resourceId] = {};
        totals[resourceId][dayNumber] = totals[resourceId][dayNumber] || 0;
        totals[resourceId][dayNumber] += (0, _financial.roundAtPrecision)(plannedTime.get('value') || 0, 3);
        return totals;
      }, {});
    }).readOnly(),
    totalsByDaysAndResourceForExistingPlannedTimes: Ember.computed('resources.@each.plannedTimes.@each.duration', function () {
      var _this3 = this;
      var totals = {};
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        return _this3.get('resources').then(function (resources) {
          var promises = [];
          resources.forEach(function (resource) {
            promises.push(resource.get('plannedTimesRows').then(function (plannedTimesRows) {
              plannedTimesRows.forEach(function (plannedTimesRow) {
                plannedTimesRow.get('plannedTimes').forEach(function (plannedTime) {
                  var resourceId = resource.get('id');
                  var dayNumber = (0, _moment.default)(plannedTime.get('startDate')).format('D');
                  if (!totals[resourceId]) {
                    totals[resourceId] = {};
                  }
                  totals[resourceId][dayNumber] = totals[resourceId][dayNumber] || 0;
                  totals[resourceId][dayNumber] += (0, _financial.roundAtPrecision)(plannedTime.get('value') || 0, 3);
                });
              });
            }));
          });
          Ember.RSVP.all(promises, 'Compute total by resources and day for existing planned times on resourceplanner').then(function () {
            resolve(totals);
          }, function (reason) {
            reject(reason);
          });
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**************************************************************************/
    /*** ACTIVITIES ***********************************************************/
    /**************************************************************************/

    /**
     * activities
     *
     * @property productionActivities
     * @observes term, projects and workUnitTypes.production
     * @return {Promise}
     * @filfil {Activity[]}
     * @readonly
     */
    activities: Ember.computed('term', 'opportunity', 'workUnitTypes.production', 'resources.@each.{batches,deliveries,opportunity}', function () {
      var _this4 = this;
      var term = this.get('term');
      // create a promise resolved when we get all projects' companies & batches & deliveries

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var activities = [];
        _this4.get('resources').then(function (resources) {
          var owner = Ember.getOwner(_this4);
          var Activity = owner.factoryFor('bm:activity');

          // allResourcesPromises is an array of Promises
          var allResourcesPromises = resources.map(function (resource) {
            var resourcePromises = {
              company: _this4.get('company'),
              batches: resource.get('batches'),
              deliveries: resource.get('deliveries'),
              opportunity: _this4.get('opportunity')
            };
            return Ember.RSVP.hash(resourcePromises, "Get company, batches and deliveries from resources for project ".concat(_this4.get('id'))).then(function (_ref) {
              var company = _ref.company,
                batches = _ref.batches,
                deliveries = _ref.deliveries,
                opportunity = _ref.opportunity;
              // push activity id-value obj in `activities` array
              var project = {
                id: _this4.id,
                reference: _this4.reference
              };
              deliveries.forEach(function (delivery) {
                var activity = {
                  type: _activity.TYPE_PRODUCTION,
                  term: term,
                  project: project,
                  resource: resource,
                  delivery: delivery,
                  opportunity: opportunity
                };
                if (company) {
                  activity.company = company;
                }
                if (batches && batches.length) {
                  batches.forEach(function (batch) {
                    activity.batch = batch;
                    activities.push(Activity.create(activity));
                  });
                } else {
                  activities.push(Activity.create(activity));
                }
              });

              // on ajoute l'activité qui n'existerait plus, par ex une saisie sur une presta sans lot alors que la presta actuelle possede des lots
              _this4.get('plannedTimes').filter(function (time) {
                return Ember.get(time, 'resource.id') === Ember.get(resource, 'id');
              }).forEach(function (time) {
                var activityExists = activities.find(function (item) {
                  return Ember.get(time, 'resource.id') && item.type === _activity.TYPE_PRODUCTION && Ember.get(item, 'resource.id') === Ember.get(time, 'resource.id') && Ember.get(item, 'delivery.id') === Ember.get(time, 'delivery.id') && Ember.get(item, 'batch.id') === Ember.get(time, 'batch.id') && Ember.get(item, 'opportunity.id') === Ember.get(time, 'opportunity.id');
                });
                if (!activityExists) {
                  var deliveryID = Ember.get(time, 'delivery.id');
                  var delivery = null;
                  if (deliveryID) {
                    // récupération du model dans le store (création ou mise à jour)
                    delivery = _this4.store.push({
                      data: {
                        id: deliveryID,
                        type: 'delivery',
                        attributes: {
                          title: Ember.get(time, 'delivery.title'),
                          startDate: Ember.get(time, 'delivery.startDate'),
                          endDate: Ember.get(time, 'delivery.endDate')
                        }
                      }
                    });
                  }
                  var activity = {
                    type: _activity.TYPE_PRODUCTION,
                    term: term,
                    project: project,
                    delivery: delivery,
                    opportunity: opportunity,
                    resource: resource
                  };
                  if (company) {
                    activity.company = company;
                  }
                  var batch = Ember.get(time, 'batch');
                  if (batch) {
                    activity.batch = batch;
                  }
                  activities.push(Activity.create(activity));
                }
              });
            });
          });
          Ember.RSVP.all(allResourcesPromises, "All ".concat(resources.length, " resources promises")).then(function () {
            resolve(activities);
          }, function (reason) {
            reject(reason);
          });
        }, function (reason) {
          reject(reason);
        });
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**************************************************************************/
    /** PLANNED TIMES *********************************************************/
    /**************************************************************************/
    /**
     * Create new plannedTimes row.
     *
     * @method createPlannedTimesRow
     * @param {PlannedTimesRowPOJO} plannedTimesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {PlannedTimesRow} - the new PlannedTimes row
     */
    createPlannedTimesRow: function createPlannedTimesRow(plannedTimesRow) {
      var _this5 = this;
      var promises = {
        resources: this.get('resources'),
        activities: this.get('activities')
      };
      var promise = Ember.RSVP.hash(promises).then(function (_ref2) {
        var resources = _ref2.resources,
          activities = _ref2.activities;
        var term = _this5.get('term');
        // plannedTimesRow properties
        var rowNo = Ember.getWithDefault(plannedTimesRow, 'row', null);
        var comment = Ember.getWithDefault(plannedTimesRow, 'comment', '');
        var newPlannedTimesRow = {
          term: (0, _moment.default)(term),
          row: rowNo,
          comment: comment,
          resources: resources,
          activities: activities,
          selectedWorkUnitType: _this5.get('defaultWorkUnitType')
        };
        if (Object.keys(plannedTimesRow).length !== 0) {
          var selectedResource = Ember.get(plannedTimesRow, 'selectedResource');
          var selectedActivity = Ember.get(plannedTimesRow, 'selectedActivity');
          if (selectedResource) newPlannedTimesRow.selectedResource = Ember.get(plannedTimesRow, 'selectedResource');
          if (selectedActivity) newPlannedTimesRow.selectedActivity = Ember.get(plannedTimesRow, 'selectedActivity');
        } else {
          newPlannedTimesRow.selectedResource = resources.get('firstObject');
        }
        return PlannedTimesRow.create(newPlannedTimesRow);
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Add new PlannedTimes row.
     *
     * Create a new Plannedtimes row and add it in `plannedTimesRows` array
     *
     * @uses createPlannedTimesRow
     * @uses plannedTimesRows
     *
     * @method addPlannedTimesRow
     * @param {PlannedTimesRowPOJO} addedPlannedTimesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {PlannedTimesRow} - The new PlannedTimes row.
     */
    addPlannedTimesRow: function addPlannedTimesRow(addedPlannedTimesRow) {
      var _this6 = this;
      var promise = this.get('plannedTimesRows').then(function (plannedTimesRows) {
        var nextRowPOJO = {};
        if (plannedTimesRows.get('length')) {
          var lastRow = plannedTimesRows.get('lastObject');
          nextRowPOJO = {
            selectedResource: lastRow.get('selectedResource'),
            selectedActivity: lastRow.get('selectedActivity')
          };
        }
        Ember.assign(nextRowPOJO, addedPlannedTimesRow);
        return _this6.createPlannedTimesRow(nextRowPOJO).then(function (newPlannedTimesRow) {
          // we must calculate the new row number now in the case 2 row has been added with the same action (project expenses)
          if (newPlannedTimesRow.get('row') === null) {
            var rowNumber = -1;
            if (plannedTimesRows.get('length')) {
              plannedTimesRows.forEach(function (row) {
                if (rowNumber >= row.get('row')) {
                  rowNumber = row.get('row') - 1;
                }
              });
            }
            newPlannedTimesRow.set('row', rowNumber);
          }
          newPlannedTimesRow.set('creation', true);
          plannedTimesRows.pushObject(newPlannedTimesRow);
          return newPlannedTimesRow;
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Delete a PlannedTimes row.
     *
     * @method delPlannedTimesRow
     * @param {PlannedTimesRow} row - PlannedTimes row to delete
     * @return {Promise}
     * @fulfil {PlannedTimesRow} - deleted PlannedTimes row
     */
    delPlannedTimesRow: function delPlannedTimesRow(row) {
      var _this7 = this;
      Ember.get(row, 'plannedTimes').forEach(function (plannedTime) {
        _this7.delPlannedTime(plannedTime, row);
      });
      return this.get('plannedTimesRows').then(function (plannedTimesRows) {
        return plannedTimesRows.removeObject(row);
      });
    },
    /**
     * Find a plannedTime in `plannedTimes`
     *
     * The test is done on `startDate` and `row` properties
     *
     * @uses plannedTimes
     *
     * @method findPlannedTime
     * @param  {plannedTime} plannedTime - plannedTime to find
     * @return {plannedTime} plannedTime found in `plannedTimes` array
     */
    findPlannedTime: function findPlannedTime(plannedTime) {
      var startDate = (0, _moment.default)(Ember.get(plannedTime, 'startDate'));
      var rowNo = Ember.get(plannedTime, 'row');
      return this.get('plannedTimes').find(function (item) {
        return item.get('startDate').isSame(startDate) && item.get('row') === rowNo;
      });
    },
    /**
     * @method updatePlannedTime
     *
     * @param {FakePlannedTime|PlannedTime} plannedTime - (Fake)PlannedTime to update with value
     * @param {Number} value - plannedTime's work unit value
     * @param {PlannedTimesRow} plannedTimesRow - row in which this plannedTime will be updated
     * @return {PlannedTime} updated plannedTime
     */
    updatePlannedTime: function updatePlannedTime(plannedTime, value, plannedTimesRow) {
      // get plannedTime to update : it's an already existing plannedTime or a new one
      var updatedPlannedTime = this.findPlannedTime(plannedTime) || this.addPlannedTime(plannedTime, plannedTimesRow);

      // update its value
      updatedPlannedTime.set('value', value);
      return updatedPlannedTime;
    },
    /**
     * @method addPlannedTime
     * @param {FakePlannedTime|PlannedTime} plannedTime - (Fake)PlannedTime to add
     * @param {PlannedTimesRow} plannedTimesRow - row in which this plannedTime will be added
     * @param {Boolean} mustCopy - if plannedTime is a PlannedTime (fragment) object, copy it or use its reference (default)
     * @return {PlannedTime} - added plannedTime
     */
    addPlannedTime: function addPlannedTime(plannedTime, plannedTimesRow) {
      var mustCopy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var addedPlannedTime;
      var defaultComment = plannedTimesRow.get('comment');
      switch (_typeof(plannedTime)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(plannedTime)) {
              addedPlannedTime = mustCopy ? (0, _emberCopy.copy)(plannedTime) : plannedTime;
              addedPlannedTime.set('comment', defaultComment);
              this.get('plannedTimes').addFragment(addedPlannedTime);
            } else {
              var normalizedPlannedTimePOJO = plannedTimesRow.getNormalizedPlannedTimePOJO(plannedTime);
              addedPlannedTime = this.get('plannedTimes').createFragment(normalizedPlannedTimePOJO);
              addedPlannedTime.set('workUnitType', (0, _emberCopy.copy)(plannedTimesRow.selectedWorkUnitType));
              addedPlannedTime.set('comment', defaultComment);
              addedPlannedTime.set('workUnitRate', plannedTimesRow.selectedWorkUnitRate);
            }
            var dayIndex = Ember.get(plannedTime, 'startDate').date() - 1;
            var plannedTimes = plannedTimesRow.get('plannedTimes');

            // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
            plannedTimes[dayIndex] = addedPlannedTime;
            // Tell plannedTimes that it has changed for template update
            plannedTimes.arrayContentDidChange(dayIndex);
            break;
          }
        default:
          _logger.default.error.apply(_logger.default, ['appresourceplannerproject model - addPlannedTime - wrong plannedTime type'].concat(Array.prototype.slice.call(arguments)));
      }
      return addedPlannedTime;
    },
    /**
     * @uses PlannedTimesRow.delPlannedTime
     * @uses plannedTimes
     *
     * @method delPlannedTime
     * @param {PlannedTime} plannedTime - plannedTime to delete
     * @param {PlannedTimesRow} plannedTimesRow - row in which this plannedTime will be removed
     * @return {PlannedTime|undefined} - deleted plannedTime or `undefined` if this plannedTime can not be removed from
     *                                   `plannedTimes` array
     */
    delPlannedTime: function delPlannedTime(plannedTime, plannedTimesRow) {
      if ((0, _fragment.isFragment)(plannedTime)) {
        plannedTimesRow.delPlannedTime(plannedTime);
        return this.get('plannedTimes').removeFragment(plannedTime);
      }
    },
    plannedTimesRows: Ember.computed('plannedTimes', function () {
      var _this8 = this;
      var plannedTimesRowsPromisesMap = {};

      // display plannedTimes
      var plannedTimes = Ember.A(this.getWithDefault('plannedTimes', []));
      var defaultWorkUnitType = this.get('defaultWorkUnitType');
      plannedTimes.sortBy('row').forEach(function (plannedTime) {
        var dayIndex = plannedTime.get('startDate').date() - 1;
        var rowNo = plannedTime.get('row');
        var resourceItem = _this8.get('resources').find(function (resource) {
          return resource.get('id') === plannedTime.resource.id;
        });
        var workUnitRate = resourceItem.get('currentWorkUnitRate');

        // Create a plannedTimesRow if needed
        if (typeof plannedTimesRowsPromisesMap[rowNo] === 'undefined') {
          // find selectedActivity
          var selectedResource = {
            id: plannedTime.get('resource.id')
          };
          var selectedActivity = {
            id: getActivityIDFromPlannedTime(_this8, plannedTime)
          };
          var currentComment = plannedTime.get('comment');
          plannedTimesRowsPromisesMap[rowNo] = _this8.createPlannedTimesRow({
            comment: currentComment,
            row: rowNo,
            selectedWorkUnitRate: workUnitRate,
            selectedResource: selectedResource,
            selectedActivity: selectedActivity,
            selectedWorkUnitType: defaultWorkUnitType
          }).then(function (newPlannedTimesRow) {
            newPlannedTimesRow.get('plannedTimes')[dayIndex] = plannedTime;
            return newPlannedTimesRow;
          });
        } else {
          // we chain
          plannedTimesRowsPromisesMap[rowNo].then(function (newPlannedTimesRow) {
            newPlannedTimesRow.get('plannedTimes')[dayIndex] = plannedTime;
            return newPlannedTimesRow;
          });
        }
      });
      var plannedTimesRowsPromise = Ember.RSVP.all(Object.keys(plannedTimesRowsPromisesMap).sort(function (a, b) {
        var aInt = parseInt(a);
        var bInt = parseInt(b);
        return aInt - bInt;
      }).map(function (rowNo) {
        return plannedTimesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: plannedTimesRowsPromise
      });
    }).readOnly(),
    realId: Ember.computed('id', function () {
      return this.get('id').split('_')[0];
    }).readOnly(),
    internalReference: Ember.computed('id', function () {
      return !this.get('isNew') ? "".concat(this.get('prefix')).concat(this.get('realId')) : '';
    }).readOnly(),
    sortPlannedTimes: function sortPlannedTimes() {
      // before , we have to reorder regularTimes with the same order as
      // backend return it after saved model
      var plannedTimes = this.get('plannedTimes');
      if (plannedTimes.get('length')) {
        // can't use sortBy because we need to put row < 0 at the end in reverse order :)
        // const sortedRegularTimes = regularTimes.sortBy('row', 'startDate');
        //
        var sortedPlannedTimes = plannedTimes.toArray().sort(function (a, b) {
          var rowA = a.get('row');
          var rowB = b.get('row');
          var diff = rowA - rowB;
          if (diff === 0) {
            var startDateA = a.get('startDate');
            var startDateB = b.get('startDate');
            return startDateA - startDateB;
          } else {
            if (rowA < 0) {
              return rowB < 0 ? rowB - rowA : 1;
            } else {
              return rowB < 0 ? -1 : diff;
            }
          }
        });
        this.set('plannedTimes', sortedPlannedTimes);
      }
    }
  });
  AppResourcePlannerProject.reopenClass({
    prefix: 'PRJ'
  });
  AppResourcePlannerProject.reopen({
    prefix: Ember.computed(function () {
      return AppResourcePlannerProject.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppResourcePlannerProject;
});