define("boondmanager/pods/components/bm-field/simple-colorpicker/state/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/cachecomputed"], function (_exports, _component, _cachecomputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * State color field component.
   *
   * Component attributes:
   *
   *   * {string}     name          REQUIRED field name
   *   * {boolean}    selected      OPTIONAL Selected color
   *   * {function}   onChange      REQUIRED callback call on switch change color
   *
   * Example:
   *
   * ```handlebars
   * {{bm-field/simple-colorpicker/state name="stateColor" selected=this.color onChange=(action 'onColorChange')}}
   * ```
   *
   * @class  FieldSimpleColorpickerStateComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * Component default __class__ attribute
     *
     * @property classNames
     * @type {Array.<string>}
     * @default  ['bmc-field-simple-colorpicker']
     */
    classNames: ['bmc-field-simple-colorpicker', 'bmc-field-simple-colorpicker-state'],
    attributeBindings: ['dataName:data-name'],
    /**
     * Couleur pour l'état PROFORMA pour les factures
     *
     * @var stateColorProforma
     * @type {Number}
     * @default 999
     */
    stateColorProforma: 999,
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      var onChange = this.get('onChange');
      this._super.apply(this, arguments);

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert("".concat(this, " must have a \"name\" attribute!"), !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert("".concat(this, " \"name\" attribute must be a string!"), Ember.typeOf(name) === 'string')); // check onChange
      (false && !(Ember.typeOf(onChange) === 'function') && Ember.assert("".concat(this, " must have an \"onChange\" function attribute!"), Ember.typeOf(onChange) === 'function'));
    },
    colors: Ember.computed('selected', function () {
      // cas spécifique pour l'état proforma
      if (this.get('selected') === this.get('stateColorProforma')) {
        return [{
          id: this.get('stateColorProforma')
        }];
      }
      return _toConsumableArray(Array(20).keys()).map(function (color) {
        return {
          id: color
        };
      });
    }).readOnly(),
    disabled: Ember.computed('selected', function () {
      return this.get('selected') === this.get('stateColorProforma');
    }).readOnly(),
    hasValue: (0, _cachecomputed.default)('value', function () {
      return !this.isBlankValue();
    }),
    isBlankValue: function isBlankValue() {
      return typeof this.get('selected') === 'undefined' || this.get('selected') === null;
    },
    actions: {
      onChange: function onChange(selected) {
        this.get('onChange')(selected);
      }
    }
  });
});