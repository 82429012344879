define("boondmanager/controllers/kanban", ["exports", "boondmanager/controllers/search", "boondmanager/utils/search"], function (_exports, _search, _search2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _search.default.extend({
    /**
     * viewMode
     *
     * View mode : 'list' or "kanban'
     *
     * @property viewMode
     * @default VIEW_MODE_LIST ('list')
     */
    viewMode: _search2.VIEW_MODE_LIST,
    // Const used in template
    viewModeKanban: _search2.VIEW_MODE_KANBAN,
    viewModeList: _search2.VIEW_MODE_LIST,
    /*
     * Default sort mode for Kanban view
     */
    kanbanSortMode: '',
    currentViewMode: Ember.computed('viewMode', function () {
      var viewMode = this.get('viewMode');
      return [_search2.VIEW_MODE_LIST, _search2.VIEW_MODE_KANBAN].includes(viewMode) ? viewMode : _search2.VIEW_MODE_LIST;
    }).readOnly(),
    /*
     * Default sort mode for Kanban view
     */
    kanbanSort: Ember.computed('sort', 'currentViewMode', function () {
      if (this.get('currentViewMode') === _search2.VIEW_MODE_KANBAN) {
        var kanbanSortList = this.get('kanbanSortList');
        var sort = this.get('sort');
        var isKanbanSort = kanbanSortList.find(function (kanbanSort) {
          return sort === kanbanSort.id;
        });
        if (!isKanbanSort) {
          var _kanbanSortList$0$id;
          this.set('sort', (_kanbanSortList$0$id = kanbanSortList[0].id) !== null && _kanbanSortList$0$id !== void 0 ? _kanbanSortList$0$id : '');
          this.set('order', '');
        }
      }
      return this.get('sort');
    }),
    /*
     * Save actual column pages, so after reload retrieve page for each column
     */
    kanbanColumnPages: Ember.A([]),
    /*
     * Options list for viewmode (ex: "List", "Kanban")
     */
    viewModeOptions: Ember.computed('i18n._locale', function () {
      return this.get('settings').bm.common.kanban.viewModes;
    }),
    kanbanData: Object.freeze({}),
    modelList: Ember.computed('model', 'model.meta.pending.cols', 'currentViewMode', function () {
      // Lorsqu'on passe d'un viewMode à un autre via une recherche sauvegardée
      // le model est mis à jour après que "viewMode" soit mis à jour
      // on vérie alors que le model soit bien chargé selon le "viewMode"
      // sinon on retourn un tableau vide en attendant que le bon model soit complétement chargé
      if (typeof this.get('model') === 'undefined' || this.get('currentViewMode') === _search2.VIEW_MODE_LIST && !this.get('model.modelName') || this.get('currentViewMode') === _search2.VIEW_MODE_KANBAN && !this.get('model.data')) {
        return Ember.A([]);
      }
      if (this.get('currentViewMode') === _search2.VIEW_MODE_LIST) {
        if (this.get('model.modelName')) {
          return this.get('model');
        } else {
          return Ember.A([]);
        }
      } else if (this.get('currentViewMode') === _search2.VIEW_MODE_KANBAN) {
        return this.get('model.data').reduce(function (fullList, column) {
          fullList.pushObjects(column.get('data'));
          return fullList;
        }, Ember.A());
      }
      return Ember.A([]);
    }),
    hasNoKanbanResults: Ember.computed('total', 'model.meta.pending.cols', function () {
      return !this.get('total') && !this.get('model.meta.pending.cols');
    }),
    changeViewMode: function changeViewMode(viewMode) {
      if (viewMode !== this.get('currentViewMode')) {
        // ici on force à undefined le model pour que modelList renvoie un ember array vide afin que les appels à
        // cette computed ne fasse pas planter le front. Ainsi, la computed renvoie toujours un tableau même vide
        this.set('model', undefined);
        this.set('viewMode', viewMode);
        this.set('page', 1);
        this.set('savedSearchId', undefined);
      }
    },
    actions: {
      // Desactivé, ralentissements en cas d'un grand nombre de cartes
      // onScrollDown() {
      // 	if (this.get('currentViewMode') === VIEW_MODE_KANBAN) {
      // 		this.set('page', this.get('page') + 1);
      // 	}
      // },
      onDragStartAction: function onDragStartAction() {
        this.set('dragActionStarted', true);
      } /* content */,
      onDragEndAction: function onDragEndAction() {
        this.set('dragActionStarted', false);
      } /* content */,
      /*
       * Update cards when kanban sort mode change
       */
      onChangeKanbanSort: function onChangeKanbanSort(sort) {
        this.set('sort', sort);
        // Set default order according to filter
        this.set('order', ['company.name', 'dependsOn.lastName', 'expertiseArea', 'function', 'information', 'lastName', 'mainManager.lastName', 'name', 'opportunity.company.name', 'opportunity.title', 'place', 'title', 'availability', 'town'].includes(sort) ? '' : 'desc');
        this.set('page', 1);
        this.set('savedSearchId', undefined);
      },
      loadKanbanColumnPage: function loadKanbanColumnPage(columnId, page) {
        var kanbanColumnPages = this.get('kanbanColumnPages');
        kanbanColumnPages[columnId] = page;
        var queryParamsVar = this.get('queryParams');
        var queryParams = this.getProperties(queryParamsVar);
        this.send('loadKanbanModelColumnPage', queryParams, columnId, page);
      },
      resetKanbanColumnPages: function resetKanbanColumnPages() {
        this.get('kanbanColumnPages').clear();
      },
      updateFilter: function updateFilter() {
        this.send('resetKanbanModel');
        this._super.apply(this, arguments);
      },
      updatePerimeterFilter: function updatePerimeterFilter() {
        this.send('resetKanbanModel');
        this._super.apply(this, arguments);
      },
      /**
       * @param filters {keywords: <string>, param2: <any>}
       * @param preserveSortFilter {boolean}
       */
      updateSearchFilters: function updateSearchFilters(filters) {
        var preserveSortFilter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.set('withKeywordsFilter', !!filters.keywords);
        if (!preserveSortFilter && filters.keywords !== this.get('keywords')) {
          if (this.get('currentViewMode') === _search2.VIEW_MODE_KANBAN) {
            filters.sort = filters.keywords ? undefined : 'updateDate';
            filters.order = undefined;
          } else {
            filters.sort = undefined;
          }
        }
        this._super(filters);
      }
    }
  });
});