define("boondmanager/transforms/boolean", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.BooleanTransform.extend({
    serialize: function serialize(deserialized) {
      return typeof deserialized === 'undefined' ? deserialized : this._super.apply(this, arguments);
    }
  });
});