define("boondmanager/models/appsepapurchase", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var AppsSepaPurchase = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['companyBankDetail', 'agencyBankDetail', 'company']
    }),
    currency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_DEVISE
    currencyAgency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_DEVISEAGENCE
    exchangeRate: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_CHANGE
    exchangeRateAgency: _emberData.default.attr('number'),
    // TAB_ACHAT.ACH_CHANGEAGENCE
    reference: _emberData.default.attr('string'),
    // TAB_ACHAT.ACHAT_REF
    subscription: _emberData.default.attr('number'),
    // TAB_ACHAT.ACHAT_TYPE
    title: _emberData.default.attr('string'),
    // TAB_ACHAT.ACHAT_TITLE
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    companyBankDetail: _emberData.default.belongsTo('bankdetail', {
      inverse: null
    }),
    agencyBankDetail: _emberData.default.belongsTo('bankdetail', {
      inverse: null
    }),
    company: _emberData.default.belongsTo('appsepacompany'),
    agency: _emberData.default.belongsTo('appsepaagency'),
    subscriptionType: Ember.computed('subscription', 'i18n._locale', function () {
      var typeID = this.get('subscription');

      // in case of changing language in another tab, dictionnary is not fully loaded so
      // we need to test if type of variable is an object
      var subscriptionType = this.get('settingsService').customer.typeOf.subscription;
      if (_typeof(subscriptionType) === 'object') {
        subscriptionType = subscriptionType.find(function (item) {
          return item.id === typeID;
        });
      } else {
        subscriptionType = null;
      }
      return subscriptionType ? subscriptionType : {
        id: typeID,
        value: this.get('i18n').t('common:unknown.name')
      };
    }),
    purchaseType: Ember.computed('typeOf', 'i18n._locale', function () {
      var typeID = this.get('typeOf');

      // in case of changing language in another tab, dictionnary is not fully loaded so
      // we need to test if type of variable is an object
      var purchaseType = this.get('settingsService').customer.typeOf.purchase;
      if (_typeof(purchaseType) === 'object') {
        purchaseType = purchaseType.find(function (item) {
          return item.id === typeID;
        });
      } else {
        purchaseType = null;
      }
      return purchaseType ? purchaseType : {
        id: typeID,
        value: this.get('i18n').t('common:unknown.name')
      };
    }),
    entityName: Ember.computed('title', function () {
      var title = this.get('title');
      if (Ember.isBlank(title)) {
        return this.get('internalReference');
      } else {
        return title;
      }
    })
  });
  AppsSepaPurchase.reopenClass({
    prefix: 'ACH'
  });
  AppsSepaPurchase.reopen({
    prefix: Ember.computed(function () {
      return AppsSepaPurchase.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsSepaPurchase;
});