define("boondmanager/pods/components/bm-field/simple-colorpicker/selected-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XPxt0XyJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bmc-field-simple-colorpicker-fieldname\"],[12],[1,[35,0,[\"fieldname\"]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"bmc-field-simple-colorpicker-color\"],[15,5,[30,[36,3],[[30,[36,2],[\"background: \",[35,1,[\"selected\",\"value\"]],\";\"],null]],null]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"extra\",\"select\",\"concat\",\"html-safe\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/simple-colorpicker/selected-item/template.hbs"
  });
});