define("boondmanager/pods/components/octane/bm-search-filters/fields/reporting-compare-indicators-period/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dpr1Xmne",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[6,[37,5],[[32,1,[\"displayFilter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n            \"],[8,\"bm-field\",[],[[\"@name\"],[[32,1,[\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,4],null,[[\"addMissingSelected\",\"name\",\"options\",\"selected\",\"forceSearch\",\"multiple\",\"allowClear\",\"onchange\",\"placeholder\"],[false,\"compare-indicators-period\",[32,1,[\"options\"]],[32,1,[\"modalValue\"]],false,false,[30,[36,3],[[32,1,[\"required\"]]],null],[30,[36,2],[[30,[36,1],[[32,1,[\"modalValue\"]]],null]],null],[30,[36,0],[\"common:indifferent\"],null]]]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"fn\",\"not\",\"bm-field/power-select\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/reporting-compare-indicators-period/template.hbs"
  });
});