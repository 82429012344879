define("boondmanager/models/absencesaccountfragment", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    originalID: _emberData.default.attr('string'),
    // owner = resource
    owner: _emberDataModelFragments.default.fragmentOwner(),
    /**
     * Period
     *
     * Possible values:
     *   - "JanuaryToDecember"
     *   - "FebruaryToJanuary"
     *   - "MarchToFebruary"
     *   - "AprilToMarch"
     *   - "MayToApril"
     *   - "JuneToMay"
     *   - "JulyToJune"
     *   - "AugustToJuly"
     *   - "SeptemberToAugust"
     *   - "OctoberToSeptember"
     *   - "NovemberToOctober"
     *   - "DecemberToNovember"
     *
     * @type {String}
     */
    period: _emberData.default.attr('string'),
    year: _emberData.default.attr('number'),
    /**
     * amountAcquired - amountAcquired
     *
     * @type {Number}
     */
    amountAcquired: _emberData.default.attr('number'),
    /**
     * comments - comments
     *
     * @type {String}
     */
    comments: _emberData.default.attr('string'),
    /**
     * amountBeingAcquired - amountBeingAcquired
     *
     * @type {Number}
     */
    amountBeingAcquired: _emberData.default.attr('number'),
    /**
     * Sum of all periods asked on acquired's absences with this work unit type
     * & including in this term
     *
     * @type {Number}
     */
    amountAcquiredAsked: _emberData.default.attr('number'),
    /**
     * amountAcquiredUsed - amountAcquiredUsed
     *
     * @type {Number}
     */
    amountAcquiredUsed: _emberData.default.attr('number'),
    /**
     * Sum of all periods asked on being acquired's absences with this work unit type
     * & including in this term
     *
     * @type {Number}
     */
    amountBeingAcquiredAsked: _emberData.default.attr('number'),
    /**
     * amountBeingAcquiredUsed - amountBeingAcquiredUsed
     *
     * @type {Number}
     */
    amountBeingAcquiredUsed: _emberData.default.attr('number'),
    /**
     * amountAcquired - amountAcquiredAsked
     *
     * @type {Number}
     */
    deltaAcquiredAsked: _emberData.default.attr('number'),
    /**
     * deltaAcquiredUsed - deltaAcquiredUsed
     *
     * @type {Number}
     */
    deltaAcquiredUsed: _emberData.default.attr('number'),
    /**
     * deltaBeingAcquiredAsked - deltaBeingAcquiredAsked
     *
     * @type {Number}
     */
    deltaBeingAcquiredAsked: _emberData.default.attr('number'),
    /**
     * deltaBeingAcquiredUsed - deltaBeingAcquiredUsed
     *
     * @type {Number}
     */
    deltaBeingAcquiredUsed: _emberData.default.attr('number'),
    /**
     * deltaAskedAndUsed - deltaAskedAndUsed
     *
     * @type {Number}
     */
    deltaAskedAndUsed: _emberData.default.attr('number'),
    /**
     * usePose - usePose
     *
     * @type {Boolean}
     */
    isQuotaExists: _emberData.default.attr('boolean'),
    /**
     * useBeingAcquired - useBeingAcquired
     *
     * @type {Number}
     */
    useBeingAcquired: _emberData.default.attr('string'),
    /**
     * workUnitType : Object
     * workUnitType.reference    : (required) Number
     * workUnitType.activityType : (required) "production" | "absence" | "internal" | "exceptionalTime" | "exceptionalCalendar"
     * workUnitType.name         : (required) String
     *
     * @type {Object}
     */
    workUnitType: (0, _attributes.fragment)('workunittype'),
    /**
     * agency : Object
     * agency.id   : (required) String
     * agency.name : (required) String
     *
     * @type {Object}
     */
    agency: _emberData.default.attr(),
    monthStart: Ember.computed('period', function () {
      var period = this.get('period');
      var months = /(.+)To.+/.exec(period);
      var monthname = months[1].toLowerCase();
      var monthsList = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
      return monthsList.indexOf(monthname) + 1;
    }),
    yearStart: Ember.computed('year', function () {
      return this.get('year');
    }),
    monthEnd: Ember.computed('period', function () {
      var period = this.get('period');
      var months = /.+To(.+)/.exec(period);
      var monthname = months[1].toLowerCase();
      var monthsList = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
      return monthsList.indexOf(monthname) + 1;
    }),
    yearEnd: Ember.computed('monthStart', 'year', function () {
      var month = this.get('monthStart');
      var year = this.get('year');
      if (month === 1) {
        return year;
      } else {
        return year + 1;
      }
    })
  });
});