define("boondmanager/pods/components/bm-field/rate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AjpGq/vb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"bmc-field-rate-stars\"],[15,\"data-rank\",[34,0]],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[\"rank\"],null]],[2,\"\\n\\t\"],[1,[30,[36,1],[\"rank\"],null]],[2,\"\\n\\t\"],[1,[30,[36,1],[\"rank\"],null]],[2,\"\\n\\t\"],[1,[30,[36,1],[\"rank\"],null]],[2,\"\\n\\t\"],[1,[30,[36,1],[\"rank\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"octane/bm-field/input\",[[24,3,\"rate\"],[24,\"data-name\",\"rate\"]],[[\"@value\",\"@type\"],[[32,0,[\"value\"]],\"hidden\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/rate/template.hbs"
  });
});