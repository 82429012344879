define("boondmanager/models/appdigitalworkplacedocument", ["exports", "boondmanager/models/file", "ember-data"], function (_exports, _file, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Doc = _file.default.extend({
    name: _emberData.default.attr('string'),
    resourcesAllowed: _emberData.default.attr('array'),
    urlEmbedded: _emberData.default.attr('string'),
    urlMain: _emberData.default.attr('string'),
    mimeType: Ember.computed('name', function () {
      var name = this.get('name');
      var nameParts = name.split('.');
      var extension = nameParts[nameParts.length - 1].toLowerCase();
      switch (extension) {
        case 'png':
        case 'jpeg':
        case 'jpg':
        case 'bmp':
        case 'gif':
        case 'tiff':
        case 'svg':
        case 'webp':
          return 'image/' + extension;
        case 'txt':
        case 'json':
          return 'text/plain';
        case 'mpeg':
        case 'mp4':
        case 'avi':
          return 'video/' + extension;
        case 'ods':
          return 'application/vnd.oasis.opendocument.spreadsheet';
        case 'xls':
          return 'application/vnd.ms-excel';
        case 'xlsx':
          return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'csv':
          return 'text/csv';
        case 'odt':
          return 'application/vnd.oasis.opendocument.text';
        case 'doc':
          return 'application/msword';
        case 'docx':
          return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'odp':
          return 'application/vnd.oasis.opendocument.presentation';
        case 'ppt':
          return 'application/vnd.ms-powerpoint';
        case 'pptx':
          return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        case 'wav':
          return 'audio/x-wav';
        case 'mp3':
          return 'audio/mpeg';
        case 'pdf':
          return 'application/pdf';
        case 'zip':
          return 'application/zip';
        case 'gz':
          return 'application/gzip';
        default:
          return 'application/octet-stream';
      }
    })
  });
  Doc.reopenClass({
    prefix: 'DOC'
  });
  Doc.reopen({
    prefix: Ember.computed(function () {
      return Doc.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Doc;
});