define("boondmanager/utils/alerts", ["exports", "boondmanager/classes/alerts/field", "boondmanager/pods/components/bm-field/perimeter/component", "boondmanager/models/alert"], function (_exports, _field, _component, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildDescription = buildDescription;
  _exports.buildFieldList = buildFieldList;
  _exports.buildOptions = buildOptions;
  _exports.computeFieldLabel = computeFieldLabel;
  _exports.getDynamicPerimeters = getDynamicPerimeters;
  _exports.getFieldListFromIndicator = getFieldListFromIndicator;
  _exports.getPerimeterModule = getPerimeterModule;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

  /**
   * Get field list depending on indicator reference
   * @param indicator
   * @returns {{period: {defaultValue: number, type: string, required: boolean}, U: {defaultValue: *[], type: string, required: boolean}, perimeter: {defaultValue: *[], type: string, required: boolean}, X: {defaultValue: *[], type: string, required: boolean}, Y: {defaultValue: *[], type: string, required: boolean}, Z: {defaultValue: *[], type: string, required: boolean}}|{period: {defaultValue: number, type: string, required: boolean}, perimeter: {defaultValue: *[], type: string, required: boolean}, X: {defaultValue: *[], type: string, required: boolean}, Y: {defaultValue: *[], type: string, required: boolean}, Z: {defaultValue: *[], type: string, required: boolean}}|{period: {defaultValue: number, type: string, required: boolean}, perimeter: {defaultValue: *[], type: string, required: boolean}, X: {defaultValue: *[], type: string, required: boolean}}|{}|{perimeter: {defaultValue: *[], type: string, required: boolean}, X: {defaultValue: *[], type: string, required: boolean}, Y: {defaultValue: *[], type: string, required: boolean}, Z: {defaultValue: *[], type: string, required: boolean}}|{period: {defaultValue: number, type: string, required: boolean}, perimeter: {defaultValue: *[], type: string, required: boolean}, X: {defaultValue: *[], type: string, required: boolean}, Y: {defaultValue: *[], type: string, required: boolean}}|{perimeter: {defaultValue: *[], type: string, required: boolean}, X: {defaultValue: *[], type: string, required: boolean}, Y: {defaultValue: *[], type: string, required: boolean}}}
   */
  function getFieldListFromIndicator(indicator) {
    switch (indicator) {
      /**
       * Actions
       **/
      case _alert.INDICATOR_ACTIONSUPCOMING:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_ACTIONSOFTHEDAY:
        return {
          period: {
            type: 'label',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_ACTIONSLATE:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      /**
       * Activités et frais
       **/
      case _alert.INDICATOR_TIMESREPORTSWITHNOVALIDATION:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_EXPENSESREPORTSWITHNOVALIDATION:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_ABSENCESREPORTSWITHNOVALIDATION:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      /**
       * Candidats
       **/
      case _alert.INDICATOR_CANDIDATESWITHNOACTION:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_CANDIDATESNOTUPDATED:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      /**
       * Ressources
       **/
      case _alert.INDICATOR_RESOURCESWITHNOACTION:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESWITHNOCONTRACTS:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESWITHNOMORECONTRACTS:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESPROBATIONARYDATEUPCOMING:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESPROBATIONARYDATETODAY:
        return {
          period: {
            type: 'label',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESPROBATIONARYDATETERMINATED:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_CONTRACTSENDEDUPCOMING:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_CONTRACTSENDEDTODAY:
        return {
          period: {
            type: 'label',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_CONTRACTSENDED:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESWITHOVERLAPPEDCONTRACTS:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESWITHNOCONTRACTENDDATE:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESARCHIVEDWITHACTIVATEDINTRANET:
        return {
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_RESOURCESWITHFOLLOWEDDOCUMENTS:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      /**
       * Besoins
       **/
      case _alert.INDICATOR_OPPORTUNITIESWITHNOACTION:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      /**
       * Positionnements
       **/
      case _alert.INDICATOR_POSITIONINGSNOTUPDATED:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      /**
       * Projets
       **/
      case _alert.INDICATOR_PROJECTSWITHNOACTION:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_PROJECTSENDEDUPCOMING:
      case _alert.INDICATOR_PROJECTSENDEDUPCOMINGORALREADYENDED:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_PROJECTSENDEDTODAY:
        return {
          period: {
            type: 'label',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_PROJECTSENDED:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_DELIVERIESENDEDUPCOMING:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          U: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_DELIVERIESENDEDTODAY:
        return {
          period: {
            type: 'label',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          U: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_DELIVERIESENDED:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          U: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_DELIVERIESNOTENTIRELYCOVEREDBYCONTRACTS:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      /**
       * Facturation
       **/
      case _alert.INDICATOR_PROJECTSWITHNOORDERNOTARCHIVED:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_PROJECTSWITHSIGNEDTURNOVERGREATERTHANORDEREDTURNOVER:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_ORDERSWITHNOBILLINGDETAIL:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_ORDERSWITHNODELIVERYCORRELATED:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_ORDERSWITHINVOICEDTURNOVERGREATERTHANORDEREDTURNOVER:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_ORDERSWITHSCHEDULESAMOUNTDIFFERENTFROMORDEREDTURNOVER:
        return {
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_INVOICESWITHNOACTION:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSUPCOMING:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSISTODAY:
        return {
          period: {
            type: 'label',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSPAST:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Z: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
      case _alert.INDICATOR_SCHEDULESPASTWITHNOINVOICE:
        return {
          period: {
            type: 'numeric',
            defaultValue: 0,
            required: true
          },
          X: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          Y: {
            type: 'select',
            defaultValue: [],
            required: false
          },
          perimeter: {
            type: 'select',
            defaultValue: [],
            required: false
          }
        };
    }
    return {};
  }

  /**
   * Build description for alert
   * @param i18n
   * @param perimeter
   * @param indicator
   * @param indicatorFields
   * @param params
   * @returns {*}
   */
  function buildDescription(i18n, perimeter, indicator, indicatorFields, params) {
    var i18nAllParameters = {
      interpolation: {
        escapeValue: false
      }
    };
    ['period', 'X', 'Y', 'Z', 'U'].forEach(function (variable) {
      var indicatorField = indicatorFields[variable];
      if (typeof indicatorField !== 'undefined' && indicatorField.type !== 'undefined') {
        var value = params[variable];
        var i18nParameters = {
          interpolation: {
            escapeValue: false
          }
        };
        i18nParameters[variable] = value;
        var optionList = buildOptions(i18n, perimeter, indicator, indicatorField.type, variable, false);

        // On remplace les variables contenues dans la description
        if (variable === 'period') {
          var intValue = !isNaN(parseInt(value)) ? parseInt(value) : 0;
          if (intValue !== 0) {
            i18nParameters.count = intValue;
          }
          i18nParameters.value = value;
          i18nAllParameters[variable] = i18n.t("alerts:view.indicators.".concat(indicator, ".").concat(variable), i18nParameters);
        } else {
          if (Array.isArray(value)) {
            i18nAllParameters.count = value.length;

            // S'il n'y a qu'une valeur sélectionnée, on l'affiche directement, sinon, on affiche le _plural
            // Par exemple :
            // value.length = 1 : Candidats sans présentation client depuis plus de X jours
            // value.length > 1 : Candidats à surveiller
            if (value.length === 1 && optionList.length) {
              var optionValue;
              optionList.forEach(function (option) {
                if (option.option) {
                  option.option.forEach(function (opt) {
                    if (value[0] === opt.id) {
                      optionValue = opt.value;
                    }
                  });
                } else {
                  if (value[0] === option.id) {
                    optionValue = option.value;
                  }
                }
              });
              i18nAllParameters[variable] = optionValue;
            }
          }
        }
      }
    });
    return i18n.t("alerts:view.indicators.".concat(indicator, ".description"), i18nAllParameters);
  }

  /**
   * Compute field label
   * @param i18n
   * @param perimeter
   * @param fieldsList
   * @param indicator
   * @param admin
   */
  function computeFieldLabel(i18n, perimeter, fieldsList, indicator, admin) {
    var promises = perimeter.then(function (perimeters) {
      var paramsDescription = [];
      fieldsList.forEach(function (field) {
        var i18nParameters = {
          interpolation: {
            escapeValue: false
          },
          decoratorStart: ' ',
          decoratorEnd: ' '
        };
        var intValue = !isNaN(parseInt(field.value)) ? parseInt(field.value) : 0;
        i18nParameters[field.variable] = intValue;
        if (field.variable === 'period') {
          i18nParameters.value = intValue;
          i18nParameters.count = intValue;
          paramsDescription.push(i18n.t(field.indicatorLabelKey) + ' : ' + i18n.t("alerts:view.indicators.".concat(indicator, ".").concat(field.variable), i18nParameters));
        } else if (field.variable === 'perimeter') {
          var optionValues = [];
          var maxOptions = 0;
          var optionLabel = '';
          if (admin) {
            maxOptions = 5;
            var referencePerimeters = getDynamicPerimeters(i18n);
            field.value.forEach(function (val) {
              var tmp = val.split('_');
              var foundValue = referencePerimeters.findBy('id', tmp[1]);
              if (foundValue) {
                optionValues.push(foundValue.entityName);
              }
            });
          } else {
            var _referencePerimeters = {
              dynamic: getDynamicPerimeters(i18n),
              agencies: perimeters.agencies,
              businessUnits: perimeters.businessUnits,
              managers: perimeters.managers,
              poles: perimeters.poles
            };
            maxOptions = perimeters.agencies.length + perimeters.businessUnits.length + perimeters.managers.length + perimeters.poles.length + 5;
            field.value.forEach(function (val) {
              var tmp = val.split('_');
              if (_referencePerimeters[tmp[0]]) {
                var foundValue = _referencePerimeters[tmp[0]].findBy('id', tmp[1]);
                if (foundValue) {
                  optionValues.push(foundValue.entityName);
                }
              }
            });
          }
          if (optionValues.length > 0) {
            if (optionValues.length >= maxOptions) {
              optionLabel = i18n.t('common:all');
            } else if (optionValues.length > 5) {
              optionLabel = i18n.t('common:selectedItems', {
                count: optionValues.length
              });
            } else {
              optionLabel = optionValues.join(', ');
            }
            paramsDescription.push("".concat(i18n.t('common:commonColonMessage', {
              message: i18n.t(field.indicatorLabelKey, {
                count: field.value.length
              })
            }), " ").concat(optionLabel));
          }
        } else if (field.type === 'select') {
          var _optionValues = [];
          var _maxOptions = 0;
          var _optionLabel = '';
          if (field.options) {
            field.options.forEach(function (option) {
              if (option.option) {
                _maxOptions += option.option.filter(function (opt) {
                  return opt.isEnabled;
                }).length;
                option.option.forEach(function (opt) {
                  if (field.value.includes(opt.id)) {
                    _optionValues.push(opt.value);
                  }
                });
              } else {
                _maxOptions++;
                if (field.value.includes(option.id)) {
                  _optionValues.push(option.value);
                }
              }
            });
          }
          if (_optionValues.length > 0) {
            if (_optionValues.length >= _maxOptions) {
              _optionLabel = i18n.t('common:all');
            } else if (_optionValues.length > 5) {
              _optionLabel = i18n.t('common:selectedItems', {
                count: _optionValues.length
              });
            } else {
              _optionLabel = _optionValues.join(', ');
            }
            paramsDescription.push("".concat(i18n.t('common:commonColonMessage', {
              message: i18n.t(field.indicatorLabelKey, {
                count: field.value.length
              })
            }), " ").concat(_optionLabel));
          }
        }
      });
      return paramsDescription;
    });
    return ArrProxy.create({
      promise: promises
    });
  }

  /**
   * Build options
   * @param i18n
   * @param perimeter
   * @param indicator
   * @param type
   * @param variable
   * @param admin
   * @return {*[]}
   * @private
   */
  function buildOptions(i18n, perimeter, indicator, type, variable, admin) {
    var fieldOptions = [];
    if (variable === 'perimeter') {
      fieldOptions = perimeter.then(function (perimeters) {
        var fieldOptions = [];
        fieldOptions.push({
          id: 'dynamic',
          value: i18n.t('components:bmFieldPerimeters.dynamic.title'),
          option: [{
            id: 'dynamic_agencies',
            value: i18n.t('components:bmFieldPerimeters.dynamic.agencies')
          }, {
            id: 'dynamic_poles',
            value: i18n.t('components:bmFieldPerimeters.dynamic.poles')
          }, {
            id: 'dynamic_businessUnits',
            value: i18n.t('components:bmFieldPerimeters.dynamic.businessUnits')
          }, {
            id: 'dynamic_managers',
            value: i18n.t('components:bmFieldPerimeters.dynamic.managers')
          }, {
            id: 'dynamic_data',
            value: i18n.t('components:bmFieldPerimeters.dynamic.data')
          }]
        });
        if (!admin) {
          _component.defaultPerimeterTypes.forEach(function (perimeterType) {
            var items = perimeters[perimeterType];
            var groupName;
            switch (perimeterType) {
              case 'managers':
                groupName = i18n.t('components:bmFieldPerimeters.managers');
                break;
              case 'poles':
                groupName = i18n.t('components:bmFieldPerimeters.poles');
                break;
              case 'businessUnits':
                groupName = i18n.t('components:bmFieldPerimeters.businessUnits');
                break;
              case 'agencies':
                groupName = i18n.t('components:bmFieldPerimeters.agencies');
                break;
            }
            var options = [];
            items.forEach(function (item) {
              var _item$get;
              options.push({
                id: (0, _component.buildPerimeterValue)(item.get('id'), perimeterType),
                value: (_item$get = item.get('entityName')) !== null && _item$get !== void 0 ? _item$get : item.get('name')
              });
            });
            if (options.length > 0) {
              fieldOptions.pushObject({
                id: perimeterType,
                value: groupName,
                option: options
              });
            }
          });
        }
        return fieldOptions;
      });
    } else if (type === 'select') {
      var i18nkey = "alerts:view.indicators.".concat(indicator, ".i18nKey.").concat(variable);
      var options = i18n.t(i18nkey, {
        returnObjects: true
      });
      if (['candidatesWithNoAction', 'candidatesNotUpdated'].includes(indicator) && variable === 'X') {
        fieldOptions.pushObject({
          id: -1,
          value: i18n.t('common.undefined')
        });
      }
      if (_typeof(options) === 'object') {
        options.forEach(function (varObjectKey) {
          var moduleObject = varObjectKey.split('.').pop();
          var objectName = moduleObject === 'resource' ? 'employee' : moduleObject;
          fieldOptions.pushObject({
            id: moduleObject,
            value: i18n.t("models:".concat(objectName, ".name")),
            option: i18n.t(varObjectKey, {
              returnObjects: true
            })
          });
        });
      } else {
        var i18nvar = i18n.t(i18nkey);
        fieldOptions.pushObjects(i18n.t(i18nvar, {
          returnObjects: true
        }));
      }
    }
    return fieldOptions;
  }

  /**
   * Build field list
   * @param i18n
   * @param perimeter
   * @param indicator
   * @param indicatorFields
   * @param params
   * @param admin
   * @return {*[]}
   * @private
   */
  function buildFieldList(i18n, perimeter, indicator, indicatorFields, params) {
    var admin = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var fields = [];
    ['period', 'X', 'Y', 'Z', 'U', 'perimeter'].forEach(function (variable) {
      var indicatorField = indicatorFields[variable];
      if (indicatorField && typeof indicatorField.type !== 'undefined') {
        var field = new _field.Field();
        field.indicator = indicator;
        field.type = indicatorField.type;
        field.indicatorLabelKey = "alerts:view.indicators.".concat(indicator, ".labels.").concat(variable);
        field.variable = variable;
        field.value = params[variable] || indicatorField.defaultValue;
        field.required = indicatorField.required;
        field.options = buildOptions(i18n, perimeter, indicator, indicatorField.type, variable, admin);
        fields.push(field);
      }
    });
    return fields;
  }

  /**
   * Get perimeter module from indicator id
   * @param indicator
   * @returns {*}
   */
  function getPerimeterModule(indicator) {
    var perimeterModule = null;
    switch (indicator) {
      /**
       * Actions
       **/
      case _alert.INDICATOR_ACTIONSOFTHEDAY:
      case _alert.INDICATOR_ACTIONSLATE:
      case _alert.INDICATOR_ACTIONSUPCOMING:
        perimeterModule = 'actions';
        break;
      /**
       * Activités et frais
       **/
      case _alert.INDICATOR_TIMESREPORTSWITHNOVALIDATION:
      case _alert.INDICATOR_EXPENSESREPORTSWITHNOVALIDATION:
      case _alert.INDICATOR_ABSENCESREPORTSWITHNOVALIDATION:
        perimeterModule = 'resources';
        break;
      /**
       * Candidats
       **/
      case _alert.INDICATOR_CANDIDATESWITHNOACTION:
      case _alert.INDICATOR_CANDIDATESNOTUPDATED:
        perimeterModule = 'candidates';
        break;
      /**
       * Ressources
       **/
      case _alert.INDICATOR_RESOURCESWITHNOACTION:
      case _alert.INDICATOR_RESOURCESWITHNOCONTRACTS:
      case _alert.INDICATOR_RESOURCESWITHNOMORECONTRACTS:
      case _alert.INDICATOR_RESOURCESPROBATIONARYDATEUPCOMING:
      case _alert.INDICATOR_RESOURCESPROBATIONARYDATETODAY:
      case _alert.INDICATOR_RESOURCESPROBATIONARYDATETERMINATED:
      case _alert.INDICATOR_CONTRACTSENDEDUPCOMING:
      case _alert.INDICATOR_CONTRACTSENDEDTODAY:
      case _alert.INDICATOR_CONTRACTSENDED:
      case _alert.INDICATOR_RESOURCESWITHOVERLAPPEDCONTRACTS:
      case _alert.INDICATOR_RESOURCESWITHNOCONTRACTENDDATE:
      case _alert.INDICATOR_RESOURCESARCHIVEDWITHACTIVATEDINTRANET:
      case _alert.INDICATOR_RESOURCESWITHFOLLOWEDDOCUMENTS:
        perimeterModule = 'resources';
        break;
      /**
       * Besoins
       **/
      case _alert.INDICATOR_OPPORTUNITIESWITHNOACTION:
        perimeterModule = 'opportunities';
        break;
      /**
       * Positionnements
       **/
      case _alert.INDICATOR_POSITIONINGSNOTUPDATED:
        perimeterModule = 'opportunities';
        break;
      /**
       * Projets
       **/
      case _alert.INDICATOR_PROJECTSWITHNOACTION:
      case _alert.INDICATOR_PROJECTSENDEDUPCOMING:
      case _alert.INDICATOR_PROJECTSENDEDUPCOMINGORALREADYENDED:
      case _alert.INDICATOR_PROJECTSENDEDTODAY:
      case _alert.INDICATOR_PROJECTSENDED:
      case _alert.INDICATOR_DELIVERIESENDEDUPCOMING:
      case _alert.INDICATOR_DELIVERIESENDEDTODAY:
      case _alert.INDICATOR_DELIVERIESENDED:
      case _alert.INDICATOR_DELIVERIESNOTENTIRELYCOVEREDBYCONTRACTS:
        perimeterModule = 'projects';
        break;
      /**
       * Facturation
       **/
      case _alert.INDICATOR_PROJECTSWITHNOORDERNOTARCHIVED:
      case _alert.INDICATOR_PROJECTSWITHSIGNEDTURNOVERGREATERTHANORDEREDTURNOVER:
        perimeterModule = 'projects';
        break;
      case _alert.INDICATOR_ORDERSWITHNOBILLINGDETAIL:
      case _alert.INDICATOR_ORDERSWITHNODELIVERYCORRELATED:
      case _alert.INDICATOR_ORDERSWITHINVOICEDTURNOVERGREATERTHANORDEREDTURNOVER:
      case _alert.INDICATOR_ORDERSWITHSCHEDULESAMOUNTDIFFERENTFROMORDEREDTURNOVER:
        perimeterModule = 'orders';
        break;
      case _alert.INDICATOR_INVOICESWITHNOACTION:
      case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSUPCOMING:
      case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSISTODAY:
      case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSPAST:
      case _alert.INDICATOR_SCHEDULESPASTWITHNOINVOICE:
        perimeterModule = 'invoices';
        break;
    }
    return perimeterModule;
  }

  /**
   *
   * @return {{id: string, value: *, option: [{id: string, value: *},{id: string, value: *},{id: string, value: *},{id: string, value: *},{id: string, value: *}]}}
   * @private
   */
  function getDynamicPerimeters(i18n) {
    return Ember.A([{
      id: 'agencies',
      entityName: i18n.t('components:bmFieldPerimeters.dynamic.agencies')
    }, {
      id: 'poles',
      entityName: i18n.t('components:bmFieldPerimeters.dynamic.poles')
    }, {
      id: 'businessUnits',
      entityName: i18n.t('components:bmFieldPerimeters.dynamic.businessUnits')
    }, {
      id: 'managers',
      entityName: i18n.t('components:bmFieldPerimeters.dynamic.managers')
    }, {
      id: 'data',
      entityName: i18n.t('components:bmFieldPerimeters.dynamic.data')
    }]);
  }
});