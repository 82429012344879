define("boondmanager/models/absenceaccountautomaticdescription", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    creationDate: _emberData.default.attr('moment'),
    previousAmountBeingAcquired: _emberData.default.attr(),
    previousAmountAcquired: _emberData.default.attr(),
    newAmountBeingAcquired: _emberData.default.attr(),
    newAmountAcquired: _emberData.default.attr()
  });
});