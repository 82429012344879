define("boondmanager/models/appaccountingpayrollcompany", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsAccountingPayrollCompany = _base.default.extend({
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'company',
    name: _emberData.default.attr('string'),
    thirdAccount: _emberData.default.attr('string'),
    state: _emberData.default.attr('number') // TAB_CRMSOCIETE.CSOC_TYPE
  });

  AppsAccountingPayrollCompany.reopenClass({
    prefix: 'CSOC'
  });
  AppsAccountingPayrollCompany.reopen({
    prefix: Ember.computed(function () {
      return AppsAccountingPayrollCompany.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsAccountingPayrollCompany;
});