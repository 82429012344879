define("boondmanager/serializers/workplacesdefaultweekday", ["exports", "boondmanager/serializers/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    // FRONT -> BACK
    serialize: function serialize() {
      // ici on ajoute ce hack pour supprimer les temps vides afin de ne pas les renvoyer au back
      var json = this._super.apply(this, arguments);
      return json.duration > 0 ? json : undefined;
    } /*snapshot, options*/
  });
});