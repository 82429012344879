define("boondmanager/models/appemailingcandidate", ["exports", "boondmanager/models/candidate", "ember-data"], function (_exports, _candidate, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsEMailingCandidate = _candidate.default.extend({
    allowedStates: _emberData.default.attr(''),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'candidate',
    canReadRecord: Ember.computed.alias('canReadCandidate').readOnly(),
    recordRouteName: Ember.computed('', function () {
      return 'private.candidates.candidate.overview';
    }).readOnly()
  });
  AppsEMailingCandidate.reopenClass({
    prefix: 'CCAN'
  });
  AppsEMailingCandidate.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingCandidate.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingCandidate;
});