define("boondmanager/pods/private/apps/corporatedocs/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WncW4ROf",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "boondmanager/pods/private/apps/corporatedocs/index/template.hbs"
  });
});