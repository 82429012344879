define("boondmanager/pods/components/bm-field/customer/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-customer'],
    context: 'customer',
    useInit: false,
    _getItems: function _getItems(input, keywordsType) {
      var params = {
        keywords: input,
        keywordsType: typeof keywordsType === 'undefined' ? 'name' : keywordsType
      };
      return this.get('store').query('customer', params);
    },
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var initialSelect = this.get('initialSelect') && !Ember.isArray(this.get('initialSelect')) ? [this.get('initialSelect')] : this.get('initialSelect');
      if (initialSelect && initialSelect.length) {
        var input = initialSelect.reduce(function (previous, current /*, idx*/) {
          return previous + ' ' + current;
        }, '').trim();
        var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
        proxy.create({
          promise: this._getItems(input, 'code')
        }).then(function (items) {
          if (_this.get('multiple')) {
            _this.set('selected', items);
          } else if (items.length === 1) {
            _this.set('selected', items.get('firstObject'));
          } else {
            _this.set('selected', null);
          }
        });
      }
    }
  });
});