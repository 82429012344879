define("boondmanager/pods/components/bm-field/tinymce/form-description/component", ["exports", "boondmanager/pods/components/bm-field/tinymce/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component BM Field Textarea for Mentions
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-tinymce-one-line', 'bmc-field-tinymce-form-description'],
    /**
     * Basic options to instanciate tinymce when needed
     */
    userOptions: Ember.computed('getBaseOptions', 'options', 'getElementId', function () {
      var elementId = this.get('getElementId');
      var paramOptions = this.get('options');
      return this.get('getBaseOptions').then(function (baseOptions) {
        return Ember.assign({}, baseOptions, paramOptions, {
          selector: "#".concat(elementId),
          toolbar: 'undo redo bold italic underline bullist outdent indent link'
        });
      });
    }).readOnly()
  });
});