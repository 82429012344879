define("boondmanager/models/project", ["exports", "ember-copy", "boondmanager/utils/logger", "boondmanager/models/base", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "boondmanager/models/additionalturnoverandcostsfragment"], function (_exports, _emberCopy, _logger, _base, _emberData, _emberDataCopyable, _attributes, _fragment, _additionalturnoverandcostsfragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.progressRate = _exports.default = _exports.TYPE_TA = _exports.TYPE_RECRUITMENT = _exports.TYPE_PRODUCT = _exports.TYPE_PACKAGE = _exports.STATE_ON_GOING = _exports.STATE_ARCHIVED = _exports.ACTIVITY_WORKUNIT = _exports.ACTIVITY_DAY = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var TYPE_TA = _exports.TYPE_TA = 1;
  var TYPE_PACKAGE = _exports.TYPE_PACKAGE = 2;
  var TYPE_RECRUITMENT = _exports.TYPE_RECRUITMENT = 3;
  var TYPE_PRODUCT = _exports.TYPE_PRODUCT = 4;
  var ACTIVITY_DAY = _exports.ACTIVITY_DAY = 'day';
  var ACTIVITY_WORKUNIT = _exports.ACTIVITY_WORKUNIT = 'workUnit';
  var STATE_ON_GOING = _exports.STATE_ON_GOING = 1;
  var STATE_ARCHIVED = _exports.STATE_ARCHIVED = 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  /**
   * progressRate
   *
   * Calcul `progressRate` from `timesProduction` and `remainsToBeDone`.
   *
   *  * If `timesProduction` === undefined, return `undefined`
   *  * If `remainsToBeDone` and `timesProduction` === 0, return 100
   *
   * @param  {Number|undefined} timesProduction
   * @param  {Number}           remainsToBeDone
   * @return {Number|undefined}  value between [0,100] or undefined if `timesProduction` is undefined too
   */
  var progressRate = _exports.progressRate = function progressRate(timesProduction, remainsToBeDone) {
    if (typeof timesProduction !== 'undefined') {
      var _progressRate = timesProduction / (timesProduction + remainsToBeDone);

      // we do not use Number.isFinite because IE (not Edge) do not support it :/
      // cf https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Number/isFinite
      return typeof _progressRate === 'number' && isFinite(_progressRate) ? _progressRate * 100 : 100;
    }
  };
  var Project = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'batches', 'candidate', 'company', 'contact', 'deliveries', 'files', 'mainManager', 'opportunity', 'pole', 'projectManagers', 'resources', 'resourcesNotInProject', 'technical', 'stateReason']
    }),
    // ATTRIBUTES
    address: _emberData.default.attr('string'),
    allowCreateMarkersOnBatches: _emberData.default.attr('boolean'),
    country: _emberData.default.attr('string'),
    subDivision: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    currency: _emberData.default.attr('number'),
    currencyAgency: _emberData.default.attr('number'),
    endDate: _emberData.default.attr('date'),
    exchangeRate: _emberData.default.attr('number'),
    exchangeRateAgency: _emberData.default.attr('number'),
    workUnitRate: _emberData.default.attr('number'),
    informationComments: _emberData.default.attr('string'),
    isProjectManager: _emberData.default.attr('boolean'),
    /**
     * Generic Project Type
     *
     * -1 = Inactivité de type "Absence"
     * -2 = Inactivité de type "Interne"
     *  1 = AT/Régie Forfaitée
     *  2 = Forfait
     *  3 = Recrutement
     *  4 = Produit
     *
     * @type {Number}
     */
    mode: _emberData.default.attr('number'),
    numberOfOrders: _emberData.default.attr('number'),
    postcode: _emberData.default.attr('string'),
    town: _emberData.default.attr('string'),
    productivityDisplay: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    remainsToBeDone: _emberData.default.attr('number'),
    showBatchesMarkersTab: _emberData.default.attr('boolean'),
    startDate: _emberData.default.attr('date'),
    state: _emberData.default.attr('number'),
    stateReason: (0, _attributes.fragment)('statereason'),
    synchronizeRemainsToBeDone: _emberData.default.attr('boolean'),
    updateProductivityDate: _emberData.default.attr('moment'),
    turnoverOrderedExcludingTax: _emberData.default.attr('number'),
    turnoverSignedExcludingTax: _emberData.default.attr('number'),
    turnoverSimulatedExcludingTax: _emberData.default.attr('number'),
    costsSimulatedExcludingTax: _emberData.default.attr('number'),
    deltaOrderedExcludingTax: _emberData.default.attr('number'),
    marginSignedExcludingTax: _emberData.default.attr('number'),
    marginSimulatedExcludingTax: _emberData.default.attr('number'),
    profitabilitySigned: _emberData.default.attr('number'),
    profitabilitySimulated: _emberData.default.attr('number'),
    deliverySuggestFilters: (0, _attributes.fragmentArray)('suggestfilterfragment'),
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number'),
    mailValidatorSignature: _emberData.default.attr('string'),
    // FRAGMENTS
    additionalTurnoverAndCosts: (0, _attributes.fragmentArray)('additionalturnoverandcostsfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    batchesMarkers: (0, _attributes.fragmentArray)('batchmarkersfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    aloneMarkers: (0, _attributes.fragmentArray)('alonemarkerfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // RELATIONSHIPS
    agency: _emberData.default.belongsTo(),
    batches: _emberData.default.hasMany(),
    deliveryRecruitment: _emberData.default.belongsTo('delivery', {
      inverse: null
    }),
    company: _emberData.default.belongsTo(),
    contact: _emberData.default.belongsTo(),
    deliveries: _emberData.default.hasMany('basedelivery', {
      inverse: 'project'
    }),
    // contient des presta ou des groupements
    files: _emberData.default.hasMany(),
    indicators: _emberData.default.hasMany(),
    mainManager: _emberData.default.belongsTo('resource'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    opportunity: _emberData.default.belongsTo(),
    pole: _emberData.default.belongsTo(),
    projectManagers: _emberData.default.hasMany('resource'),
    purchases: _emberData.default.hasMany({
      inverse: 'project'
    }),
    resources: _emberData.default.hasMany(),
    technical: _emberData.default.belongsTo('contact'),
    intermediaryContact: _emberData.default.belongsTo('contact', {
      inverse: null
    }),
    intermediaryCompany: _emberData.default.belongsTo('company', {
      inverse: null
    }),
    //right
    canReadProject: _emberData.default.attr('boolean'),
    canWriteProject: _emberData.default.attr('boolean'),
    // Droit d'écriture ( par procuration de suppresion)
    canShowContact: _emberData.default.attr('boolean'),
    canShowCompany: _emberData.default.attr('boolean'),
    canShowCurrency: _emberData.default.attr('boolean'),
    canShowCurrencyAgency: _emberData.default.attr('boolean'),
    canShowExchangeRate: _emberData.default.attr('boolean'),
    canShowExchangeRateAgency: _emberData.default.attr('boolean'),
    canShowTurnoverSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowMarginSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowProfitabilitySimulated: _emberData.default.attr('boolean'),
    canShowCostsSimulatedExcludingTax: _emberData.default.attr('boolean'),
    canShowAdditionalTurnoverAndCosts: _emberData.default.attr('boolean'),
    isMonitorSignedTimesheets: _emberData.default.attr('boolean'),
    // METHODS
    remainsToBeDoneInWorkUnits: Ember.computed('remainsToBeDone', 'workUnitRate', {
      get: function get() {
        return this.get('remainsToBeDone') * this.get('workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model project - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('remainsToBeDone', this.get('workUnitRate') ? value / this.get('workUnitRate') : value);
        }
        return value;
      }
    }),
    // - Manage additionalTurnoverAndCosts fragments
    addTurnoverAndCosts: function addTurnoverAndCosts(turnoverAndCosts) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var additionalTurnoverAndCosts = this.get('additionalTurnoverAndCosts');
      switch (_typeof(turnoverAndCosts)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(turnoverAndCosts)) {
              additionalTurnoverAndCosts.addFragment(makeAcopy ? (0, _emberCopy.copy)(turnoverAndCosts) : turnoverAndCosts);
            } else {
              additionalTurnoverAndCosts.createFragment(turnoverAndCosts);
            }
            break;
          }
        default:
          {
            // get last additional turnover and cost typeof if exists
            var defaultTypeOf = _additionalturnoverandcostsfragment.INDIFFERENT_TYPEOF_ADDITIONAL_TURNOVER;
            if (additionalTurnoverAndCosts && additionalTurnoverAndCosts.lastObject) {
              defaultTypeOf = additionalTurnoverAndCosts.lastObject.typeOf;
            }
            additionalTurnoverAndCosts.createFragment({
              typeOf: defaultTypeOf
            });
          }
      }
    },
    delTurnoverAndCosts: function delTurnoverAndCosts(turnoverAndCosts) {
      if ((0, _fragment.isFragment)(turnoverAndCosts)) {
        this.get('additionalTurnoverAndCosts').removeFragment(turnoverAndCosts);
      } else {
        _logger.default.warn("Project#".concat(this.get('id'), ":delTurnoverAndCosts() - turnoverAndCosts is not a fragment !"), turnoverAndCosts);
      }
    },
    addAloneMarker: function addAloneMarker(marker) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var markers = this.get('aloneMarkers');
      switch (_typeof(marker)) {
        case 'object':
          if ((0, _fragment.isFragment)(marker)) {
            markers.addFragment(makeAcopy ? (0, _emberCopy.copy)(marker) : marker);
          } else {
            markers.createFragment(marker);
          }
          break;
        default:
          markers.createFragment();
      }
    },
    delAloneMarker: function delAloneMarker(marker) {
      if ((0, _fragment.isFragment)(marker)) {
        return this.get('aloneMarkers').removeFragment(marker);
      } else {
        _logger.default.warn("BatchMarkers#".concat(this.get('originalID'), ":delMarker(marker) - marker is not a fragment !"), marker);
      }
    },
    // - Manager batchesMarkers
    addBatch: function addBatch(batch) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var batchesMarkers = this.get('batchesMarkers');
      switch (_typeof(batch)) {
        case 'object':
          if ((0, _fragment.isFragment)(batch)) {
            batchesMarkers.addFragment(makeAcopy ? (0, _emberCopy.copy)(batch) : batch);
          } else {
            batchesMarkers.createFragment(batch);
          }
          break;
        default:
          batch = batchesMarkers.createFragment();
          batch.addMarker();
          break;
      }
    },
    delBatch: function delBatch(batch) {
      if ((0, _fragment.isFragment)(batch)) {
        return this.get('batchesMarkers').removeFragment(batch);
      } else {
        _logger.default.warn("Project#".concat(this.get('id'), ":delBatch(batch) - batch is not a fragment !"), batch);
      }
    },
    entityName: Ember.computed('reference', function () {
      return this.get('getReference');
    }),
    totalDuration: Ember.computed('batchesMarkers.@each.durationForecast', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + Number(batchMarkers.get('durationForecast'));
      }, 0);
    }),
    totalDurationInWorkUnits: Ember.computed('batchesMarkers.@each.durationForecastInWorkUnits', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + Number(batchMarkers.get('durationForecastInWorkUnits'));
      }, 0);
    }),
    totalTaken: Ember.computed('batchesMarkers.@each.timesProduction', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + batchMarkers.get('timesProduction');
      }, 0);
    }),
    totalTakenInWorkUnits: Ember.computed('batchesMarkers.@each.timesProductionInWorkUnits', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + batchMarkers.get('timesProductionInWorkUnits');
      }, 0);
    }),
    totalBalance: Ember.computed('batchesMarkers.@each.balance', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + batchMarkers.get('balance');
      }, 0);
    }),
    totalBalanceInWorkUnits: Ember.computed('batchesMarkers.@each.balanceInWorkUnits', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + batchMarkers.get('balanceInWorkUnits');
      }, 0);
    }),
    totalRemains: Ember.computed('batchesMarkers.@each.remainsToBeDone', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + Number(batchMarkers.get('remainsToBeDone'));
      }, 0);
    }),
    totalRemainsInWorkUnits: Ember.computed('batchesMarkers.@each.remainsToBeDoneInWorkUnits', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + Number(batchMarkers.get('remainsToBeDoneInWorkUnits'));
      }, 0);
    }),
    totalDifference: Ember.computed('batchesMarkers.@each.difference', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + batchMarkers.get('difference');
      }, 0);
    }),
    totalDifferenceInWorkUnits: Ember.computed('batchesMarkers.@each.differenceInWorkUnits', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + batchMarkers.get('differenceInWorkUnits');
      }, 0);
    }),
    totalProgress: Ember.computed('totalTaken', 'totalRemains', function () {
      var _this$getProperties = this.getProperties('totalTaken', 'totalRemains'),
        totalTaken = _this$getProperties.totalTaken,
        totalRemains = _this$getProperties.totalRemains;
      return progressRate(totalTaken, totalRemains);
    }),
    totalDistribution: Ember.computed('batchesMarkers.@each.distributionRate', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + Number(batchMarkers.get('distributionRate'));
      }, 0);
    }),
    totalTurnoverSigned: Ember.computed('batchesMarkers.@each.turnoverSignedDistributedExcludingTax', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + batchMarkers.get('turnoverSignedDistributedExcludingTax');
      }, 0);
    }),
    totalTurnoverProgress: Ember.computed('batchesMarkers.@each.turnoverProgressDistributedExcludingTax', function () {
      var batchesMarkers = this.get('batchesMarkers');
      return batchesMarkers.reduce(function (total, batchMarkers) {
        return total + batchMarkers.get('turnoverProgressDistributedExcludingTax');
      }, 0);
    }),
    /**
     * Sum of all additional turnovers (manual sum instead of Ember.computed.sum to allow undefined filtering)
     *
     * @property additionalTurnoverTotal
     * @type {Number}
     */
    additionalTurnoverTotal: Ember.computed('additionalTurnoverAndCosts.{[],@each.turnoverExcludingTax}', function () {
      return this.get('additionalTurnoverAndCosts').reduce(function (amount, item) {
        return amount + item.get('turnoverExcludingTax');
      }, 0);
    }),
    /**
     * Sum of all additional costs (manual sum instead of Ember.computed.sum to allow undefined filtering)
     *
     * @property additionalCostsTotal
     * @type {Number}
     */
    additionalCostsTotal: Ember.computed('additionalTurnoverAndCosts.{[],@each.costsExcludingTax}', function () {
      return this.get('additionalTurnoverAndCosts').reduce(function (amount, item) {
        return amount + item.get('costsExcludingTax');
      }, 0);
    }),
    additionalTurnoverAndCostsMargin: Ember.computed('additionalTurnoverTotal', 'additionalCostsTotal', function () {
      return this.get('additionalTurnoverTotal') - this.get('additionalCostsTotal');
    }),
    isProfitabilityCalculatingBasedOnMarginRate: Ember.computed('i18n._locale', function () {
      var profitability = this.get('settingsService').customer.profitabilityMethodOfCalculating;
      return profitability === 'marginRate';
    }).readOnly(),
    additionalTurnoverAndCostsProfitability: Ember.computed('isProfitabilityCalculatingBasedOnMarginRate', 'additionalTurnoverAndCostsMargin', 'additionalCostsTotal', 'additionalTurnoverTotal', function () {
      var value;
      if (this.get('isProfitabilityCalculatingBasedOnMarginRate')) {
        value = Math.round(100000 * this.get('additionalCostsTotal')) ? Math.round(10000 * (this.get('additionalTurnoverAndCostsMargin') / this.get('additionalCostsTotal'))) / 10000 : 0;
      } else {
        value = Math.round(100000 * this.get('additionalTurnoverTotal')) ? Math.round(10000 * (this.get('additionalTurnoverAndCostsMargin') / this.get('additionalTurnoverTotal'))) / 10000 : 0;
      }
      return value;
    }).readOnly(),
    currencyLabel: Ember.computed('currency', 'i18n._locale', function () {
      var currencyID = this.get('currency');
      if (typeof currencyID === 'undefined') {
        _logger.default.log("Fragment 'actualexpense'#".concat(this.get('id'), ": undefined currency ID"));
        return '-';
      }
      var currencies = this.get('settingsService').customer.currency;
      return currencies.find(function (currency) {
        return currency.id === currencyID;
      });
    }),
    negativeDelta: Ember.computed('deltaOrderedExcludingTax', function () {
      return this.get('deltaOrderedExcludingTax') < 0;
    }),
    labelColor: Ember.computed('mode', function () {
      if (this.get('isTA')) return 'regie';else if (this.get('isPackage')) return 'forfait';else if (this.get('isRecruitment')) return 'recrutement';else return 'produit';
    }),
    isTA: Ember.computed('mode', function () {
      return this.get('mode') === TYPE_TA;
    }),
    isPackage: Ember.computed('mode', function () {
      return this.get('mode') === TYPE_PACKAGE;
    }),
    isRecruitment: Ember.computed('mode', function () {
      return this.get('mode') === TYPE_RECRUITMENT;
    }),
    isProduct: Ember.computed('mode', function () {
      return this.get('mode') === TYPE_PRODUCT;
    }),
    isActivityTypeInWorkUnit: Ember.computed('typeOf', 'i18n._locale', function () {
      var _this = this;
      var typeOf = this.get('settingsService').customer.typeOf.project.find(function (item) {
        return item.id === _this.get('typeOf');
      });
      return typeOf && typeOf.activityType === ACTIVITY_WORKUNIT;
    }).readOnly(),
    remains: Ember.computed('synchronizeRemainsToBeDone', 'totalRemains', 'remainsToBeDone', {
      get: function get() {
        if (this.get('synchronizeRemainsToBeDone')) {
          return this.get('totalRemains');
        } else {
          return this.get('remainsToBeDone');
        }
      },
      set: function set(_, value) {
        this.set('remainsToBeDone', value);
        return value;
      }
    }),
    provider: Ember.computed('contact', 'company', function () {
      var providerPromises = {
        contact: this.get('contact'),
        company: this.get('company')
      };
      var promise = new Ember.RSVP.hash(providerPromises, "get provider contact and company").then(function (_ref) {
        var contact = _ref.contact,
          company = _ref.company;
        return {
          id: contact ? contact.get('id') : null,
          entityName: contact ? contact.get('entityName') : null,
          // for bm-field-crm. CAUTION: contact could be null
          completeName: contact ? contact.get('completeName') : null,
          // for bm-client. CAUTION: contact could be null
          company: company
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    billingIntermediary: Ember.computed('intermediaryContact', 'intermediaryCompany', function () {
      var providerPromises = {
        intermediaryContact: this.get('intermediaryContact'),
        intermediaryCompany: this.get('intermediaryCompany')
      };
      var promise = new Ember.RSVP.hash(providerPromises, "get billing intermediaryContact and intermediaryCompany").then(function (_ref2) {
        var intermediaryContact = _ref2.intermediaryContact,
          intermediaryCompany = _ref2.intermediaryCompany;
        return {
          id: intermediaryContact ? intermediaryContact.get('id') : null,
          entityName: intermediaryContact ? intermediaryContact.get('entityName') : null,
          // for bm-field-crm. CAUTION: intermediaryContact could be null
          completeName: intermediaryContact ? intermediaryContact.get('completeName') : null,
          // for bm-client. CAUTION: intermediaryContact could be null
          company: intermediaryCompany
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    isScheduleProductionTurnoverActive: Ember.computed('typeOf', 'i18n._locale', function () {
      var _this2 = this;
      var typeOf = this.get('settingsService').customer.typeOf.project.find(function (item) {
        return item.id === _this2.get('typeOf');
      });
      return typeOf ? typeOf.scheduleProductionTurnover : false;
    }).readOnly(),
    billingDetailCompany: Ember.computed('company', 'intermediaryCompany', function () {
      return this.get('intermediaryCompany.id') ? this.get('intermediaryCompany') : this.get('company');
    }).readOnly()
  });
  Project.reopenClass({
    prefix: 'PRJ'
  });
  Project.reopen({
    prefix: Ember.computed(function () {
      return Project.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Project;
});