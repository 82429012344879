define("boondmanager/models/payment", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_PLANNED = _exports.STATE_PAID = _exports.STATE_CONFIRMED = _exports.PAYMENT_TERM_X_OF_MONTH_THE_Y = _exports.PAYMENT_TERM_X_OF_MONTH = _exports.PAYMENT_TERM_NET = _exports.PAYMENT_TERM_END_OF_MONTH = _exports.PAYMENT_TERM_CASH = void 0;
  /*
   * Purchase subscrition types
   */
  var PAYMENT_TERM_CASH = _exports.PAYMENT_TERM_CASH = 0;
  var PAYMENT_TERM_NET = _exports.PAYMENT_TERM_NET = 1;
  var PAYMENT_TERM_END_OF_MONTH = _exports.PAYMENT_TERM_END_OF_MONTH = 2;
  var PAYMENT_TERM_X_OF_MONTH = _exports.PAYMENT_TERM_X_OF_MONTH = 3;
  var PAYMENT_TERM_X_OF_MONTH_THE_Y = _exports.PAYMENT_TERM_X_OF_MONTH_THE_Y = 4;
  var STATE_PAID = _exports.STATE_PAID = 2;
  var STATE_PLANNED = _exports.STATE_PLANNED = 0;
  var STATE_CONFIRMED = _exports.STATE_CONFIRMED = 1;
  var Payment = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['purchase']
    }),
    /* `activityDetails` should be a relationship property `hasMany('resource')`
        or a `framentArray('activitydetail')` where `activitydetail` Fragment
        object would have a relationship property `resource: belongsTo()`
     */
    activityDetails: _emberData.default.attr(),
    amountExcludingTax: _emberData.default.attr('number'),
    // TAB_PAIEMENT.PMT_MONTANTHT
    amountIncludingTax: _emberData.default.attr('number'),
    date: _emberData.default.attr('date'),
    // TAB_PAIEMENT.PMT_DATE
    endDate: _emberData.default.attr('date'),
    // TAB_PAIEMENT.PMT_FIN
    expectedDate: _emberData.default.attr('date'),
    // TAB_PAIEMENT.PMT_DATEPAIEMENTATTENDU
    informationComments: _emberData.default.attr('string'),
    // TAB_PAIEMENT.PMT_COMMENTAIRES
    number: _emberData.default.attr('string'),
    // TAB_PAIEMENT.PMT_REFPROVIDER
    paymentMethod: _emberData.default.attr('number'),
    // TAB_PAIEMENT.PMT_TYPEPAYMENT
    performedDate: _emberData.default.attr('date'),
    // TAB_PAIEMENT.PMT_DATEPAIEMENTEFFECTUE
    startDate: _emberData.default.attr('date'),
    // TAB_PAIEMENT.PMT_DEBUT
    state: _emberData.default.attr('number'),
    // TAB_PAIEMENT.PMT_ETAT
    taxRate: _emberData.default.attr('number'),
    // TAB_PAIEMENT.PMT_TAUXTVA
    taxRates: _emberData.default.attr(),
    numberOfFiles: _emberData.default.attr('number'),
    canReadPayment: _emberData.default.attr('boolean'),
    canWritePayment: _emberData.default.attr('boolean'),
    file: _emberData.default.belongsTo('document'),
    files: _emberData.default.hasMany('document'),
    purchase: _emberData.default.belongsTo(),
    providerInvoice: _emberData.default.belongsTo('providerinvoice'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    creationDate: _emberData.default.attr('moment'),
    // TAB_PAIEMENT.PMT_CREATEDAT,
    updateDate: _emberData.default.attr('moment'),
    isTaxRatesMultiples: Ember.computed('taxRates', 'purchase.agency.purchasesAllowMultiplesTaxRates', function () {
      return this.get('taxRates').length > 1 || this.get('purchase.agency.purchasesAllowMultiplesTaxRates');
    }).readOnly(),
    taxRatesSelected: Ember.computed('isTaxRatesMultiples', 'taxRates', function () {
      if (this.get('isTaxRatesMultiples')) return this.get('taxRates');else return this.get('taxRates.0');
    })
  });
  Payment.reopenClass({
    prefix: 'PMT'
  });
  Payment.reopen({
    prefix: Ember.computed(function () {
      return Payment.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Payment;
});