define("boondmanager/models/actualexpense", ["exports", "boondmanager/utils/logger", "ember-data", "boondmanager/models/expense", "boondmanager/utils/financial"], function (_exports, _logger, _emberData, _expense, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* ** ATTRIBUTES TYPE CASTING **
  
  	see: http://discuss.emberjs.com/t/ember-data-store-my-attr-number-as-a-string-why/11904
  
  	Ember-data doesn't auto-cast attributes when we use data in our app! Ex: if
  	you use a number attribut with an {{input}}, you'll get a string :'/
  
  	Ember-data cast attributes only when data is received from or send to the
  	backend...
  
  	So we have to manually cast them when we want to do some calculation for
  	example :'(
  
  	We use Number() constructor because it doesn't detect octals si of a string
  	like "010" is casting, we have the number 10 (Number("010") => 10 vs
  	parseInt("010") > 8 but if we use parseInt("010", 10) => 10, it's ok ;p)
  */
  var _default = _exports.default = _expense.default.extend({
    /**
     * Currency ID
     * @type {Number}
     */
    currency: _emberData.default.attr('number'),
    // TAB_FRAISREEL.FRAISREEL_DEVISE
    /**
     * Line No
     * @type {Number}
     */
    number: _emberData.default.attr('number'),
    // TAB_FRAISREEL.FRAISREEL_NUM
    /**
     * Attachment
     * @type {String}
     */
    file: _emberData.default.attr('string'),
    // TAB_FRAISREEL.FRAISREEL_ID_JUSTIFICATIF
    /**
     * To know if the actual expense is reinvoiced or not
     * @type {[type]}
     */
    reinvoiced: _emberData.default.attr('boolean'),
    /**
     * Tax rate
     * @type {number}
     */
    tax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // TAB_FRAISREEL.FRAISREEL_VA, TAB_LIGNEFRAIS.LIGNEFRAIS_TVA
    /**
     * Title
     * @type {String}
     */
    title: _emberData.default.attr('string'),
    // TAB_FRAISREEL.FRAISREEL_INTITULE

    /* *** Local Properties, without database mapping *** */
    /**
     * Taxed Amound is autocalculated
     * @type {boolean}
     */
    latestTaxedAmountValue: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    /**
     * Is new actual expense created from OCR
     * @type {boolean}
     */
    isTaxedAmountAutoUpdated: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    /* *** End of Local Properties *** */

    category: Ember.computed(function () {
      return 'actual';
    }).readOnly(),
    agencyTaxedAmount: Ember.computed('latestTaxedAmountValue', 'isTaxedAmountAutoUpdated', 'originalAmountIncludingTax', 'tax', 'currency', 'exchangeRate', function () {
      var latestTaxedAmountValue = Number(this.get('latestTaxedAmountValue'));
      var agencyTaxedAmount;

      /*
       * La TVA n'est calculée automatiquement selon le montant et le taux du frais réel que si :
       *  - Il n'y a pas eut de saisie manuelle du montant TVA ( latestTaxedAmountValue === 0 )
       *  - On est pas dans la création d'un frais via l'OCR ( isTaxedAmountAutoUpdated désactivé )
       * Sinon, on garde le montant TVA inchangé.
       */

      if (latestTaxedAmountValue === 0 && this.get('isTaxedAmountAutoUpdated')) {
        agencyTaxedAmount = (0, _financial.extractTaxedAmount)(this.get('originalAmountIncludingTax'), this.get('tax'));
      } else {
        agencyTaxedAmount = latestTaxedAmountValue;
      }

      /*
       * Si on est dans une autre monnaie que la fiche,
       * on applique le taux de change pour revenir à la monnaie par défaut
       */
      if (this.get('currency') !== this.get('owner.currencyAgency')) {
        agencyTaxedAmount = (0, _financial.applyExchangeRates)(agencyTaxedAmount, [Number(this.get('exchangeRate'))]);
      }
      return agencyTaxedAmount;
    }),
    originalTaxedAmount: Ember.computed('originalAmountIncludingTax', 'tax', {
      get: function get() {
        var latestTaxedAmountValue = Number(this.get('latestTaxedAmountValue'));
        if (this.get('isKilometricExpense') || !this.get('isTaxedAmountAutoUpdated') || latestTaxedAmountValue !== 0) {
          return latestTaxedAmountValue;
        } else {
          var _this$getProperties = this.getProperties('originalAmountIncludingTax', 'tax'),
            originalAmountIncludingTax = _this$getProperties.originalAmountIncludingTax,
            tax = _this$getProperties.tax;

          // see ATTRIBUTES TYPE CASTING at the beginning of this file
          return (0, _financial.extractTaxedAmount)(originalAmountIncludingTax, Number(tax));
        }
      } /*key*/,
      set: function set(key, value) {
        this.set('latestTaxedAmountValue', value);
        if (value !== 0 || this.get('isTaxedAmountAutoUpdated')) {
          var _newTax = (0, _financial.calculateTax)(this.get('originalAmountIncludingTax'), Number(value));
          this.set('tax', _newTax);
        }
        return value;
      }
    }),
    currencyLabel: Ember.computed('currency', function () {
      var currencyID = this.get('currency');
      if (typeof currencyID === 'undefined') {
        _logger.default.log("Fragment 'actualexpense'#".concat(this.get('id'), ": undefined currency ID"));
        return '-';
      }
      var currencies = this.get('settingsService').customer.currency;
      return currencies.find(function (currency) {
        return currency.id === currencyID;
      });
    }).readOnly(),
    creation: Ember.computed('originalID', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    edition: Ember.computed('originalID', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    toggleCurrency: Ember.computed('currency', 'owner.currencyAgency', {
      get: function get() {
        return this.get('currency') !== this.get('owner.currencyAgency');
      },
      set: function set(name, value) {
        return value;
      }
    }),
    newFileAttached: Ember.computed('file', {
      get: function get() {
        return this.get('newFile');
      },
      set: function set(name, value) {
        this.set('newFile', value);
        return value;
      }
    }),
    isReadonly: Ember.computed('edition', 'creation', function () {
      return !this.get('edition') && !this.get('creation');
    }).readOnly(),
    /**
     * Start edition mode and save current values
     */
    startEdition: function startEdition() {
      this.set('edition', true);
      this.set('isTaxedAmountAutoUpdated', true);
      this.set('originalValues', {
        startDate: this.get('startDate'),
        selectedActivity: this.get('selectedActivity.id'),
        selectedExpenseType: this.get('selectedExpenseType.reference'),
        title: this.get('title'),
        numberOfKilometers: this.get('numberOfKilometers'),
        originalAmountIncludingTax: this.get('originalAmountIncludingTax'),
        originalTaxedAmount: this.get('originalTaxedAmount'),
        currency: this.get('currency'),
        reinvoiced: this.get('reinvoiced')
      });
      // Garder l'objet "moment" d'origine et travailler sur un clone pour l'édition
      // Si on annule l'édition, on reviens à l'objet d'origine et le model se remet automatiquement à "clean"
      this.set('startDate', this.get('startDate').clone());
    },
    /**
     * Confirm edition
     */
    confirmEdition: function confirmEdition() {
      // clear original values and do nothing, if edition is confirmed, current values are now the last setted values
      this.set('originalValues', undefined);
      this.set('creation', false);
      this.set('edition', false);
    },
    /**
     * Cancel edition mode and restore previous values
     */
    cancelEdition: function cancelEdition() {
      var _this = this;
      // restore previews values
      this.set('edition', false);
      var originalValues = this.get('originalValues');
      this.set('startDate', originalValues.startDate);
      this.set('selectedActivity', originalValues.selectedActivity);
      // wait set and get finished to restore others values
      this.get('selectedActivity').then(function () {
        _this.set('selectedExpenseType', originalValues.selectedExpenseType);
        _this.get('selectedExpenseType').then(function () {
          _this.set('title', originalValues.title);
          _this.set('numberOfKilometers', originalValues.numberOfKilometers);
          _this.set('originalAmountIncludingTax', originalValues.originalAmountIncludingTax);
          _this.set('originalTaxedAmount', originalValues.originalTaxedAmount);
          _this.set('currency', originalValues.currency);
          _this.set('reinvoiced', originalValues.reinvoiced);
          _this.set('originalValues', undefined);
        });
      });
    }
  });
});