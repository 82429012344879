define("boondmanager/models/appsaaseditorproductfragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    workUnitTypes: _emberData.default.attr(),
    expenseTypes: _emberData.default.attr(),
    purchaseTypes: _emberData.default.attr(),
    advantageTypes: _emberData.default.attr(),
    selectedModel: Ember.computed('originalID', 'name', 'reference', function () {
      if (this.get('originalID')) {
        return {
          id: this.get('originalID'),
          entityName: this.get('name'),
          reference: this.get('reference'),
          internalReference: 'PROD' + this.get('originalID')
        };
      }
    }),
    workUnitTypesSelected: Ember.computed('workUnitTypes.@each.{reference,agency.id}', function () {
      var options = [];
      this.get('workUnitTypes').forEach(function (item) {
        options.push(item.agency.id + '_' + item.reference);
      });
      return options;
    }),
    expenseTypesSelected: Ember.computed('expenseTypes.@each.{reference,agency.id}', function () {
      var options = [];
      this.get('expenseTypes').forEach(function (item) {
        options.push(item.agency.id + '_' + item.reference);
      });
      return options;
    }),
    advantageTypesSelected: Ember.computed('advantageTypes.@each.{reference,agency.id}', function () {
      var options = [];
      this.get('advantageTypes').forEach(function (item) {
        options.push(item.agency.id + '_' + item.reference);
      });
      return options;
    })
  });
});