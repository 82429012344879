define("boondmanager/pods/components/bm-field/power-select/saved-search/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3eHbs+L1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"bmc-field-power-select_footer\"],[12],[2,\"\\n\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,\"role\",\"button\"],[24,\"data-name\",\"button-save-search\"],[24,0,\"bmb-link\"],[4,[38,0],[\"click\",[32,0,[\"save\"]]],null],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-star-full\"],null]],[2,\" \"],[1,[30,[36,2],[\"components:savedSearch.save\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,\"role\",\"button\"],[24,\"data-name\",\"button-reset-search\"],[24,0,\"bmb-link\"],[4,[38,0],[\"click\",[32,0,[\"reset\"]]],null],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-reset\"],null]],[2,\" \"],[1,[30,[36,2],[\"components:savedSearch.reset\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"icon\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/saved-search/footer/template.hbs"
  });
});