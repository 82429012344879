define("boondmanager/pods/components/bm-field/currency/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/logger"], function (_exports, _component, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * bm-field-currency component.
   *
   * Component attributes: (temporay, will have more)
   *   * {number}  currency             REQUIRED currency id
   *   * {number}  currencyAgency       REQUIRED currencyAgency id
   *   * {number}  exchangeRate         REQUIRED exchangeRate
   *   * {number}  exchangeRateAgency   REQUIRED exchangeRateAgency
   *   * {boolean} disabled             OPTIONAL whether the inputs should be disabled
   *
   * Example:
   * ```handlebars
   * {{bm-field/currency
   *     currency=0 currencyAgency=0
   *     exchangeRate=1 exchangeRateAgency=1
   *     disabled=false}}
   * ```
   *
   * @class BmFieldCurrencyComponent
   * @namespace Ember
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-currency'],
    infoboxClass: 'bottom',
    /**
     * Application currency
     * if needed elsewhere, make it a global var
     *
     * @property appCurrency
     * @type Number
     */
    appCurrency: Ember.computed('i18n._locale', function () {
      return 0;
    }),
    /**
     * Whether to show the change input
     *
     * @property showAgencyRate
     * @type {Boolean}
     */
    showAgencyRate: false,
    disallowChangeExchangeAgency: true,
    showLock: true,
    displayCalculator: true,
    /**
     * Whether to show the change input
     *
     * @property editChange
     * @type {Boolean}
     */
    editChange: Ember.computed('currency', 'currencyAgency', 'showSelect', function () {
      var currency = this.get('currency');
      var currencyAgency = this.get('currencyAgency');
      var appCurrency = this.get('appCurrency');
      return this.get('showSelect') && currency !== currencyAgency && currency !== appCurrency;
    }),
    showChange: Ember.computed('currency', 'currencyAgency', function () {
      var currency = this.get('currency');
      var currencyAgency = this.get('currencyAgency');
      var appCurrency = this.get('appCurrency');
      return currency !== currencyAgency && currency !== appCurrency;
    }),
    disabledChange: Ember.computed('currency', 'appCurrency', 'disabled', function () {
      var currency = this.get('currency');
      var appCurrency = this.get('appCurrency');
      var disabled = this.get('disabled');
      return currency === appCurrency || disabled;
    }),
    /**
     * save previous exchange rate in order to reload it if the user temporary change the currency to the agency currency (=> exchangeRate will be automatically set at 1)
     *
     * @property previousExchangeRate
     * @type Number
     * @default 1
     */
    previousExchangeRate: 1,
    /**
     * Exchange rate
     *
     * @property exchangeRate
     * @type Number
     * @default 1
     */
    exchangeRate: Ember.computed({
      get: function get() {
        return 1;
      } /*key*/,
      set: function set(key, value) {
        var ret = value;
        if (ret === 0) {
          _logger.default.warn("[bmc-field-money#".concat(this.get('elementId'), "] - SET exchangeRate (").concat(ret, ")!"));
          ret = 1;
        }
        if (Number.isNaN(Number(ret))) {
          _logger.default.error("[bmc-field-money#".concat(this.get('elementId'), "] - SET exchangeRate (").concat(ret, ") is not a number!"));
          ret = 1;
        }
        var currency = this.get('currency');
        var currencyAgency = this.get('currencyAgency');
        var appCurrency = this.get('appCurrency');
        if (currency !== currencyAgency && currency !== appCurrency) {
          this.set('previousExchangeRate', ret);
        }
        return ret;
      }
    }),
    /**
     * Agency's exchange rate
     *
     * @property exchangeRateAgency
     * @type Number
     * @default 1
     */
    exchangeRateAgency: Ember.computed({
      get: function get() {
        return 1;
      } /*key*/,
      set: function set(key, value) {
        var ret = value;
        if (ret === 0) {
          _logger.default.log("[bmc-field-money#".concat(this.get('elementId'), "] - SET exchangeRateAgency (").concat(ret, ")!"));
          ret = 1;
        }
        if (Number.isNaN(Number(ret))) {
          _logger.default.error("[bmc-field-money#".concat(this.get('elementId'), "] - SET exchangeRateAgency (").concat(ret, ") is not a number!"));
          ret = 1;
        }
        return ret;
      }
    }),
    showSelect: false,
    /**
     * Component attributes validation
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var currency = this.get('currency');
      var currencyAgency = this.get('currencyAgency');
      var exchangeRate = this.get('exchangeRate');
      var exchangeRateAgency = this.get('exchangeRateAgency');
      var onExchangeRateChange = this.get('onExchangeRateChange');
      var onCurrencyChange = this.get('onCurrencyChange');
      (false && !(!Ember.isBlank(currency)) && Ember.assert('bm-field-currency must have an "currency" attribute!', !Ember.isBlank(currency)));
      (false && !(!Ember.isBlank(currencyAgency)) && Ember.assert('bm-field-currency must have an "currencyAgency" attribute!', !Ember.isBlank(currencyAgency)));
      (false && !(!Ember.isBlank(exchangeRate)) && Ember.assert('bm-field-currency must have an "exchangeRate" attribute!', !Ember.isBlank(exchangeRate)));
      (false && !(!Ember.isBlank(exchangeRateAgency)) && Ember.assert('bm-field-currency must have an "exchangeRateAgency" attribute!', !Ember.isBlank(exchangeRateAgency)));
      (false && !(typeof onExchangeRateChange === 'function') && Ember.assert('bm-field-currency must have a callback "onExchangeRateChange"', typeof onExchangeRateChange === 'function'));
      (false && !(typeof onCurrencyChange === 'function') && Ember.assert('bm-field-currency must have a callback "onCurrencyChange"', typeof onCurrencyChange === 'function'));
    },
    _action: function _action(name) {
      var action = this.get(name);
      if (typeof action === 'function') {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        action.apply(void 0, args);
      }
    },
    isBlankValue: function isBlankValue() {
      return this.get('currency') == null || this.get('currency') === 'undefined';
    },
    actions: {
      toggleSelect: function toggleSelect() {
        if (!this.get('disabled')) {
          this.toggleProperty('showSelect');
        }
      },
      changeAgencyRate: function changeAgencyRate() {
        this.toggleProperty('showAgencyRate');
      },
      onExchangeRateAgencyChange: function onExchangeRateAgencyChange(exchangeRateAgency) {
        var currency = this.get('currency');
        if (currency === this.get('appCurrency')) {
          var exchangeRate = exchangeRateAgency ? 1 / exchangeRateAgency : 1;
          this._action('onExchangeRateChange', exchangeRate);
        }
        this._action('onExchangeRateAgencyChange', exchangeRateAgency);
      },
      onCurrencyChange: function onCurrencyChange(currency) {
        this._action('onCurrencyChange', currency);
        if (currency === this.get('currencyAgency')) {
          this.set('previousExchangeRate', this.get('exchangeRate'));
          var exchangeRate = 1;
          this._action('onExchangeRateChange', exchangeRate);
        } else if (currency === this.get('appCurrency')) {
          this.set('previousExchangeRate', this.get('exchangeRate'));
          var exchangeRateAgency = this.get('exchangeRateAgency');
          var _exchangeRate = exchangeRateAgency ? 1 / exchangeRateAgency : 1;
          this._action('onExchangeRateChange', _exchangeRate);
        } else {
          this._action('onExchangeRateChange', this.get('previousExchangeRate'));
        }
      }
    }
  });
});