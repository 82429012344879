define("boondmanager/pods/components/bm-field/perimeter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ixV1rcAl",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,12],null,[[\"multiple\",\"useObjects\",\"name\",\"options\",\"selected\",\"disabled\",\"placeholder\",\"customMatcher\",\"onchange\"],[[35,11],true,[35,10],[35,9],[35,8],[35,7],[30,[36,1],[[35,6],[30,[36,5],[\"common:indifferent\"],null],[35,4]],null],[35,3],[30,[36,2],[[32,0],\"onChangePerimeter\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"firstName\"]],[32,1,[\"lastName\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[32,1,[\"entityName\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\",\"action\",\"perimeterMatcher\",\"placeholder\",\"t\",\"search\",\"disabled\",\"selectedPerimeters\",\"options\",\"name\",\"multiple\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/perimeter/template.hbs"
  });
});