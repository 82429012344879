define("boondmanager/pods/components/bm-field/product/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field_product'],
    searchPrefix: 'PROD',
    context: 'product',
    isFragment: false,
    useInit: false,
    displayNbSelected: true,
    _getItems: function _getItems(input) {
      var params = {
        keywords: input
      };
      if (this.get('isFragment')) {
        return this.get('store').query('product', params).then(function (products) {
          var options = [];
          products.forEach(function (product) {
            options.push({
              id: '' + product.get('id'),
              entityName: product.get('name'),
              reference: product.get('reference'),
              internalReference: product.get('internalReference')
            });
          });
          return options;
        });
      } else {
        return this.get('store').query('product', params);
      }
    },
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var initialSelect = this.get('initialSelect') && !Ember.isArray(this.get('initialSelect')) ? [this.get('initialSelect')] : this.get('initialSelect');
      if (initialSelect && initialSelect.length && this.get('searchPrefix')) {
        var input = initialSelect.map(function (item) {
          return _this.get('searchPrefix') + item;
        }).join(' ');
        var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
        proxy.create({
          promise: this._getItems(input)
        }).then(function (items) {
          if (_this.get('multiple') && !_this.get('isFragment')) {
            _this.set('selected', items);
          } else if (items.length === 1) {
            _this.set('selected', _this.get('isFragment') ? items[0] : items.get('firstObject'));
          } else {
            _this.set('selected', null);
          }
        });
      }
    }
  });
});