define("boondmanager/models/timesreport", ["exports", "boondmanager/utils/logger", "boondmanager/utils/financial", "boondmanager/models/baseactivity", "ember-data", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "moment", "ember-data-copyable", "ember-copy", "boondmanager/models/project", "boondmanager/models/delivery", "boondmanager/models/batch", "boondmanager/models/workunittype"], function (_exports, _logger, _financial, _baseactivity, _emberData, _attributes, _fragment, _moment, _emberDataCopyable, _emberCopy, _project, _delivery, _batch, _workunittype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getActivityIDFromRegularOrExceptionalTime = _exports.default = _exports.STATES = _exports.INTERNAL_REFERENCE_CRA = _exports.ERROR_CODE_ENTITY_EXISTS = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * @module TimesReports
   * @submodule TimesReport
   */ // usefull Factories :)
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  var ERROR_CODE_ENTITY_EXISTS = _exports.ERROR_CODE_ENTITY_EXISTS = '3905';
  var INTERNAL_REFERENCE_CRA = _exports.INTERNAL_REFERENCE_CRA = 'CRA';

  /**
   * To get Activity ID from a regularTime fragment
   *
   * regularTime is a fragment so its properties `project`, `delivery` and
   * `batch` are not Ember `DS.Model` but "simple" POJO ! So they do not
   * have CP like `getReference` :/
   *
   * Activity ID is equal to regularTime's workUnitType.activityType if its type is
   * not 'production', else it's project's internal + space + ref delivery's internal ref
   * + batch's internal ref (optional). But we can not use 'internalReference' CP :'( So
   * we build it...
   *
   * cf GET Activity.id CP.
   *
   * TODO: regularTime should be real DS.Model with `project`, `delivery`, etc relationships!
   *
   * @param  {RegularTime|ExceptionalTime} objTime
   * @return {String|undefined}
   */
  var getActivityIDFromRegularOrExceptionalTime = _exports.getActivityIDFromRegularOrExceptionalTime = function getActivityIDFromRegularOrExceptionalTime(objTime) {
    var type = objTime.get('workUnitType.activityType');
    var id;
    switch (type) {
      case _workunittype.WUT_EXCEPTIONAL_CALENDAR:
      case _workunittype.WUT_EXCEPTIONAL_TIME:
      case _workunittype.WUT_INTERNAL:
      case _workunittype.WUT_PRODUCTION:
        {
          var _objTime$getPropertie = objTime.getProperties('project', 'delivery', 'batch'),
            project = _objTime$getPropertie.project,
            delivery = _objTime$getPropertie.delivery,
            batch = _objTime$getPropertie.batch;
          if (project && delivery) {
            id = "".concat(_project.default.prefix).concat(objTime.get('project.id'), " ").concat(_delivery.default.prefix).concat(objTime.get('delivery.id'));
            if (batch) {
              id += " ".concat(_batch.default.prefix).concat(objTime.get('batch.id'));
            }
          } else if (type === _workunittype.WUT_INTERNAL) {
            id = type;
          }
          break;
        }
      default:
        id = type;
    }
    return id;
  };

  /**
   * Fake Regular Time (FRT) object
   *
   * {{#crossLink "RegularTime"}}regularTime{{/crossLink}} are fragments. But we need
   * FRT objects to represent empty regularTime in our
   * {{#crossLink "RegularTimesRow/regulartimes"}}`regulartimes` array{{/crossLink}}
   * defined in each {{#crossLink "RegularTimesRow"}}RegularTimes row{{/crossLink}}
   *
   * @class FakeRegularTime
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var FakeRegularTime = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Flag to identify this object as a "fake"
     *
     * @property isFake
     * @type {Boolean}
     * @default  true
     * @readOnly
     */
    isFake: Ember.computed(function () {
      return true;
    }).readOnly(),
    /**
     * Start date
     *
     * @property startDate
     * @type {Moment}
     */

    /**
     * Value
     *
     * @property startDate
     * @type {Moment}
     * @default  ''
     * @readOnly
     */
    value: Ember.computed(function () {
      return '';
    }).readOnly(),
    /**
     * Return a copy of this FakeRegularTime (FRT) object
     *
     *     const FRTCopy = Ember.copy(originalFRT);
     *
     * @method copy
     * @return {FakeRegularTime} copy of this FRT object
     * @private
     */
    copy: function copy() {
      return FakeRegularTime.create({
        startDate: this.get('startDate').clone()
      });
    },
    /**
     * Return a POJO representation
     *
     * The POJO has 'isFake', 'startDate' and 'value' properties.
     *
     * CAUTION: `startDate` is not a moment clone! It's the **same** Moment
     *          object present in this FRT object! If you want a POJO as 'deep'
     *          copy, use `POJO = FRTObj.copy().toJSON()` to not share the same
     *          Moment instance beetwen `POJO.startDate` and `FRTObj.startDate`
     *
     * @return {Object}
     */
    toJSON: function toJSON() {
      return this.getProperties('isFake', 'startDate', 'value');
    }
  });

  /**
   * Fake Workplace Time (FRT) object
   *
   * {{#crossLink "WorkplaceTime"}}workplaceTime{{/crossLink}} are fragments. But we need
   * FRT objects to represent empty workplaceTime in our
   * {{#crossLink "WorkplaceTimesRow/workplacetimes"}}`worplacetimes` array{{/crossLink}}
   * defined in each {{#crossLink "RegularTimesRow"}}WorkplaceTimes row{{/crossLink}}
   *
   * @class FakeWorkplaceTime
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var FakeWorkplaceTime = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Flag to identify this object as a "fake"
     *
     * @property isFake
     * @type {Boolean}
     * @default  true
     * @readOnly
     */
    isFake: Ember.computed(function () {
      return true;
    }).readOnly(),
    /**
     * Start date
     *
     * @property startDate
     * @type {Moment}
     */

    /**
     * Value
     *
     * @property startDate
     * @type {Moment}
     * @default  ''
     * @readOnly
     */
    value: Ember.computed(function () {
      return '';
    }).readOnly(),
    /**
     * Return a copy of this FakeWorkplaceTime (FRT) object
     *
     *     const FRTCopy = Ember.copy(originalFRT);
     *
     * @method copy
     * @return {FakeWorkplaceTime} copy of this FRT object
     * @private
     */
    copy: function copy() {
      return FakeWorkplaceTime.create({
        startDate: this.get('startDate').clone()
      });
    },
    /**
     * Return a POJO representation
     *
     * The POJO has 'isFake', 'startDate' and 'value' properties.
     *
     * CAUTION: `startDate` is not a moment clone! It's the **same** Moment
     *          object present in this FRT object! If you want a POJO as 'deep'
     *          copy, use `POJO = FRTObj.copy().toJSON()` to not share the same
     *          Moment instance beetwen `POJO.startDate` and `FRTObj.startDate`
     *
     * @return {Object}
     */
    toJSON: function toJSON() {
      return this.getProperties('isFake', 'startDate', 'value');
    }
  });

  /**
   * Regular Time Row (RTR)
   *
   * Independants props :
   *   * row
   *   * fillstate
   *
   * Interdependant props (init order) :
   *   1. term
   *   2. activities
   *   3. workUnitTypes
   *   3. selectedActivity ( -> activities & workUnitTypes)
   *   4. selectedWorkUnitType ( -> selectedActivity & workUnitTypes)
   *
   * @class RegularTimesRow
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var RegularTimesRow = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Term
     *
     * @property term
     * @type {Moment}
     */

    /**
     * Row Number
     *
     * if < 0, it's a new row
     *
     * @property row
     * @type {Number}
     * @default -1
     */
    row: Ember.computed(function () {
      return -1;
    }),
    /**
     * Row's fill state
     *
     * To know if all regularTimes of this row are filled. Used when user click
     * on "fill" button of the row
     *
     * @property fillState
     * @type {Boolean}
     * @default false
     */
    fillState: Ember.computed(function () {
      return false;
    }),
    /**
     * Activities
     *
     * List of all activities
     *
     * @property activities
     * @type {Activity[]}
     */

    /**
     * Work Unit Types (WUTs) Object
     *
     * `workUnitTypes` is a JS POJO with 3 properties :
     *   - 'absence'   : array of agency's absence workUnitTypes
     *   - 'internal'  : array of agency's internal workUnitTypes
     *   - 'production': array of agency's production workUnitTypes
     *
     * @property workUnitTypes
     * @type {Object}
     */

    /**
     * Selected Activity
     *
     * When selected activity is updated, the
     * {{#crossLink "RegularTimesRow/selectedWorkUnitType}}selected WUT{{/crossLink}}
     * is reset to its default value. Each
     * {{#crossLink "RegularTimesRow/regularTimes}}regularTimes{{/crossLink}}
     * item is then updated too.
     *
     * @see RegularTimesRow.activities
     *
     * @property selectedActivity
     * @type {Activity}
     * @default if exists, first production activity, else first activity with first non empty WUT
     */
    selectedActivity: Ember.computed({
      get: function get() {
        // by default, we considere it's the first new row
        var firstProductionActivity = this.get('activities').find(function (activity) {
          return activity.type === _workunittype.WUT_PRODUCTION;
        });
        var selectedActivity;
        if (firstProductionActivity && !firstProductionActivity.disabled) {
          selectedActivity = firstProductionActivity;
        } else {
          // WUT = WorkUnitTime :)
          var workUnitTypes = this.get('workUnitTypes');
          var firstNonEmptyWUTType = [_workunittype.WUT_INTERNAL, _workunittype.WUT_ABSENCE].find(function (type) {
            return workUnitTypes[type].length;
          });
          if (typeof firstNonEmptyWUTType === 'undefined') {
            _logger.default.warn('Model timesreport - GET selectedActivity CP - firstNonEmptyWUTType is undefined...');
          } else {
            selectedActivity = this.get('activities').find(function (activity) {
              return activity.type === firstNonEmptyWUTType;
            });
          }
        }
        if (typeof selectedActivity === 'undefined') {
          _logger.default.warn('Model timesreport - GET selectedActivity CP - selectedActivity is undefined...');
        }
        return selectedActivity;
      },
      set: function set(_, newActivity, oldActivity) {
        // here, newActivity could be an Activity object or a simple POJO with an `id` property
        var searchedID = Ember.get(newActivity, 'id');
        var selectedActivity = this.get('activities').find(function (activity) {
          return activity.get('id') === searchedID;
        }) || oldActivity;
        if (typeof selectedActivity === 'undefined') {
          _logger.default.warn('Model timesreport - SET selectedActivity CP - newActivity not find in activities array!', newActivity, this.get('activities'));
        } else {
          var _selectedActivity$get = selectedActivity.getProperties('type', 'project', 'delivery', 'batch'),
            type = _selectedActivity$get.type,
            project = _selectedActivity$get.project,
            delivery = _selectedActivity$get.delivery,
            batch = _selectedActivity$get.batch;
          var propertiesToSet = {};
          switch (type) {
            case _workunittype.WUT_ABSENCE:
              propertiesToSet.project = null;
              propertiesToSet.delivery = null;
              propertiesToSet.batch = null;
              break;
            case _workunittype.WUT_INTERNAL:
              propertiesToSet.project = null;
              propertiesToSet.delivery = null;
              propertiesToSet.batch = null;
              break;
            default:
              propertiesToSet.project = {
                id: project.get('id'),
                reference: project.get('getReference') // used in totalsByActivity CP
              };

              propertiesToSet.delivery = {
                id: delivery.get('id')
              };
              propertiesToSet.batch = batch ? {
                id: batch.get('id')
              } : null;
          }
          var selectedWorkUnitType = this.get("workUnitTypes.".concat(type))[0];
          this.get('regularTimes').forEach(function (regularTime) {
            if ((0, _fragment.isFragment)(regularTime)) {
              propertiesToSet.workUnitType = (0, _emberCopy.copy)(selectedWorkUnitType);
              regularTime.setProperties(propertiesToSet);
            }
          });
        }
        return selectedActivity;
      }
    }),
    /**
     * Selected Work Unit Type (WUT)
     *
     * Selected WUT is reset to its default value when
     * {{#crosslink "RegularTimesRow/selectedActivity}}selected activity{{/crossLink}}
     * is changed. Each {{#crossLink "RegularTimesRow/regularTimes}}regularTimes{{/crossLink}}
     * item is then updated too.
     *
     * @property selectedWorkUnitType
     * @type {Object}
     * @default  first WUT object of the selected activity WUTs
     */
    selectedWorkUnitType: Ember.computed('selectedActivity', {
      get: function get() {
        var workUnitType = this.get('selectedActivity.type');
        return typeof workUnitType !== 'undefined' ? this.get("workUnitTypes.".concat(workUnitType))[0] : null;
      },
      set: function set(key, newWUT) {
        this.get('regularTimes').forEach(function (regularTime) {
          if ((0, _fragment.isFragment)(regularTime)) {
            regularTime.set('workUnitType', (0, _emberCopy.copy)(newWUT, true));
          }
        });
        return newWUT;
      }
    }),
    workUnitTypesOptions: Ember.computed('selectedActivity', function () {
      var options = this.get('workUnitTypes')[this.get('selectedActivity.type')];
      // check for selected option which not exists in list to add it
      if (this.get('selectedWorkUnitType')) {
        var selectedWorkUnitTypeExists = options.findBy('reference', this.get('selectedWorkUnitType.reference'));
        if (!selectedWorkUnitTypeExists) {
          options.pushObject(this.get('selectedWorkUnitType'));
        }
      }
      return options;
    }).readOnly(),
    /**
     * Regular times by day (0-based).
     *
     * Array's length = number of days in the month (cf term)
     *
     * @property regularTimes
     * @uses  _regularTimes
     * @type {(Fake)RegularTime[]}
     * @readOnly
     */
    regularTimes: Ember.computed('term', function () {
      var term = this.get('term');
      var daysInMonth = term.daysInMonth();
      var regularTimes = Ember.A();
      for (var i = 0; i < daysInMonth; i++) {
        regularTimes.push(FakeRegularTime.create({
          startDate: term.clone().date(i + 1)
        }));
      }
      return regularTimes;
    }).readOnly(),
    // TODO code en commun avec la CP `regularTimes` (cf le switch)
    getNormalizedRegularTimePOJO: function getNormalizedRegularTimePOJO(regularTime) {
      var normalizedPOJO = {};
      if (regularTime && ((0, _fragment.isFragment)(regularTime) || typeof regularTime.toJSON === 'function')) {
        normalizedPOJO = (0, _emberCopy.copy)(regularTime).toJSON();
      }
      var _this$getProperties = this.getProperties('row', 'selectedActivity'),
        row = _this$getProperties.row,
        selectedActivity = _this$getProperties.selectedActivity;
      var _selectedActivity$get2 = selectedActivity.getProperties('type', 'project', 'delivery', 'batch'),
        type = _selectedActivity$get2.type,
        project = _selectedActivity$get2.project,
        delivery = _selectedActivity$get2.delivery,
        batch = _selectedActivity$get2.batch;
      normalizedPOJO.row = row;
      switch (type) {
        case _workunittype.WUT_ABSENCE:
          normalizedPOJO.delivery = null;
          normalizedPOJO.batch = null;
          break;
        case _workunittype.WUT_INTERNAL:
          normalizedPOJO.delivery = null;
          normalizedPOJO.batch = null;
          break;
        default:
          normalizedPOJO.project = {
            id: project.get('id'),
            reference: project.get('getReference') // used in totalsByActivity CP
          };

          normalizedPOJO.delivery = {
            id: delivery.get('id')
          };
          normalizedPOJO.batch = batch ? {
            id: batch.get('id')
          } : null;
      }
      normalizedPOJO.workUnitType = (0, _emberCopy.copy)(this.get('selectedWorkUnitType'));
      normalizedPOJO.duration = 0;
      if (typeof normalizedPOJO.startDate === 'undefined') {
        _logger.default.error('TimesReport model - normalizeRegularTime - startDate undefined', normalizedPOJO);
        normalizedPOJO.startDate = (0, _moment.default)();
      }
      if (typeof normalizedPOJO.value !== 'undefined') {
        delete normalizedPOJO.value;
      }
      return normalizedPOJO;
    },
    /**
     * Del a regularTime = Replace a regularTime with  a fakeRegularTime
     *
     * @method delRegularTime
     * @param {regularTime} regularTime the regularTime to replace
     */
    delRegularTime: function delRegularTime(regularTime) {
      if ((0, _fragment.isFragment)(regularTime)) {
        // Replace Ember fragment by JS object
        var dayIndex = regularTime.get('startDate').date() - 1;
        var regularTimes = this.get('regularTimes');

        // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
        regularTimes[dayIndex] = FakeRegularTime.create({
          startDate: regularTime.get('startDate').clone()
        });
        // Tell regularTimes that it has changed for template update
        regularTimes.arrayContentDidChange(dayIndex);
      }
    },
    creation: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    edition: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    isReadonly: Ember.computed('edition', 'creation', function () {
      return !this.get('edition') && !this.get('creation');
    }).readOnly(),
    /**
     * Start edition on fixed expense row
     * @param regularTimeRow
     * @private
     */
    startRegularTimeRowEdition: function startRegularTimeRowEdition() {
      // backup current values to restore them if user click on cancel button
      var regularTimeRowriginalValues = {
        selectedActivity: this.get('selectedActivity.id'),
        selectedWorkUnitType: this.get('selectedWorkUnitType.reference'),
        regularTimes: []
      };
      var regularTimes = this.get('regularTimes');
      if (regularTimes) {
        regularTimes.forEach(function (regularTime, dayIndex) {
          regularTimeRowriginalValues.regularTimes.push({
            dayIndex: dayIndex,
            value: regularTime.value
          });
        });
      }
      this.set('originalValues', regularTimeRowriginalValues);
      this.set('edition', true);
    },
    /**
     * Confirm edition validation on fixed expense row
     * @param regularTimeRow
     * @private
     */
    confirmRegularTimeRowEdition: function confirmRegularTimeRowEdition() {
      // clear original values and do nothing, if edition is confirmed, current values are now the last setted values
      this.set('originalValues', undefined);
      this.set('edition', false);
      this.set('creation', false);
    },
    totalActivity: Ember.computed('regularTimes.@each.duration', 'workUnitRate', function () {
      var _this = this;
      return this.get('regularTimes').reduce(function (totals, regularTime) {
        var duration = parseFloat(regularTime.get('duration')) || 0;
        var workUnitRate = _this.get('workUnitRate');
        totals.days += duration;
        totals.workUnits += duration * workUnitRate;
        return totals;
      }, {
        days: 0,
        workUnits: 0
      });
    }).readOnly(),
    /**
     * Return a copy of this RegularTimesRow (RTR) object
     *
     *     const RTRCopy = Ember.copy(originalRTR);
     *
     * @method copy
     * @return {RegularTimesRow} copy of this RTR object
     * @private
     */
    copy: function copy(deep) {
      var _this$getProperties2 = this.getProperties('term', 'row', 'workUnitRate', 'fillState', 'Activities', 'WorkUnitTimes', 'selectedActivity', 'selectedWorkUnitType'),
        term = _this$getProperties2.term,
        row = _this$getProperties2.row,
        workUnitRate = _this$getProperties2.workUnitRate,
        fillState = _this$getProperties2.fillState,
        Activities = _this$getProperties2.Activities,
        WorkUnitTimes = _this$getProperties2.WorkUnitTimes,
        selectedActivity = _this$getProperties2.selectedActivity,
        selectedWorkUnitType = _this$getProperties2.selectedWorkUnitType;
      return RegularTimesRow.create({
        term: term.clone(),
        row: row,
        workUnitRate: workUnitRate,
        fillState: fillState,
        Activities: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(Activities, deep) : Activities,
        WorkUnitTimes: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(WorkUnitTimes, deep) : WorkUnitTimes,
        selectedActivity: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(selectedActivity, deep) : selectedActivity,
        selectedWorkUnitType: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(selectedWorkUnitType, deep) : selectedWorkUnitType
      });
    }
  });

  /**
   * Workplace Time Row (RTR)
   *
   * Independants props :
   *   * row
   *   * fillstate
   *
   * Interdependant props (init order) :
   *   1. term
   *   2. selectedWorplaceType
   *
   * @class WorkplaceTimesRow
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var WorkplaceTimesRow = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Term
     *
     * @property term
     * @type {Moment}
     */

    /**
     * Row Number
     *
     * if < 0, it's a new row
     *
     * @property row
     * @type {Number}
     * @default -1
     */
    row: Ember.computed(function () {
      return -1;
    }),
    /**
     * Row's fill state
     *
     * To know if all workplaceTimes of this row are filled. Used when user click
     * on "fill" button of the row
     *
     * @property fillState
     * @type {Boolean}
     * @default false
     */
    fillState: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(key, fillState) {
        return fillState;
      }
    }),
    /**
     * Selected workplace Type
     *
     * @property selectedWorkplaceType
     * @type {Object}
     * @default  first WP object of the selected activity WPs
     */
    selectedWorkplaceType: Ember.computed({
      get: function get() {
        return this.get("workplaceTypes")[0];
      },
      set: function set(key, newWorkplaceType) {
        this.get('workplaceTimes').forEach(function (workplaceTime) {
          if ((0, _fragment.isFragment)(workplaceTime)) {
            workplaceTime.set('workplaceType', (0, _emberCopy.copy)(newWorkplaceType, true));
          }
        });
        return newWorkplaceType;
      }
    }),
    /**
     * Workplace times by day (0-based).
     *
     * Array's length = number of days in the month (cf term)
     *
     * @property workplaceTimes
     * @uses  _workplaceTimes
     * @type {WorkplaceTime[]|FakeWorkplaceTime[]}
     * @readOnly
     */
    workplaceTimes: Ember.computed('term', function () {
      var term = this.get('term');
      var daysInMonth = term.daysInMonth();
      var workplaceTimes = Ember.A();
      for (var i = 0; i < daysInMonth; i++) {
        workplaceTimes.push(FakeWorkplaceTime.create({
          startDate: term.clone().date(i + 1)
        }));
      }
      return workplaceTimes;
    }).readOnly(),
    workplaceTypesOptions: Ember.computed('selectedWorkplaceType', function () {
      var options = (0, _emberCopy.copy)(this.get('workplaceTypes'), true);
      // check for selected option which not exists in list to add it
      if (this.get('selectedWorkplaceType')) {
        var selectedWorkplaceTypeExists = options.findBy('reference', this.get('selectedWorkplaceType.reference'));
        if (!selectedWorkplaceTypeExists) {
          options.pushObject((0, _emberCopy.copy)(this.get('selectedWorkplaceType'), true));
        }
      }
      return options;
    }).readOnly(),
    // TODO code en commun avec la CP `regularTimes` (cf le switch)
    getNormalizedWorkplaceTimePOJO: function getNormalizedWorkplaceTimePOJO(workplaceTime) {
      var normalizedPOJO = {};
      if (workplaceTime && ((0, _fragment.isFragment)(workplaceTime) || typeof workplaceTime.toJSON === 'function')) {
        normalizedPOJO = (0, _emberCopy.copy)(workplaceTime).toJSON();
      }
      var _this$getProperties3 = this.getProperties('row'),
        row = _this$getProperties3.row;
      normalizedPOJO.row = row;
      normalizedPOJO.workplaceType = (0, _emberCopy.copy)(this.get('selectedWorkplaceType'));
      normalizedPOJO.duration = 0;
      if (typeof normalizedPOJO.startDate === 'undefined') {
        _logger.default.error('TimesReport model - normalizeWorkplaceTime - startDate undefined', normalizedPOJO);
        normalizedPOJO.startDate = (0, _moment.default)();
      }
      if (typeof normalizedPOJO.value !== 'undefined') {
        delete normalizedPOJO.value;
      }
      return normalizedPOJO;
    },
    /**
     * Del a workplaceTime = Replace a workplaceTime with  a fakeWorkplaceTime
     *
     * @method delWorkplaceTime
     * @param {workplaceTime} workplaceTime the workplaceTime to replace
     */
    delWorkplaceTime: function delWorkplaceTime(workplaceTime) {
      if ((0, _fragment.isFragment)(workplaceTime)) {
        // Replace Ember fragment by JS object
        var dayIndex = workplaceTime.get('startDate').date() - 1;
        var workplaceTimes = this.get('workplaceTimes');

        // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
        workplaceTimes[dayIndex] = FakeWorkplaceTime.create({
          startDate: workplaceTime.get('startDate').clone()
        });
        // Tell workplaceTimes that it has changed for template update
        workplaceTimes.arrayContentDidChange(dayIndex);
      }
    },
    creation: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    edition: Ember.computed('row', {
      get: function get() {
        return false;
      },
      set: function set(name, value) {
        return value;
      }
    }),
    isReadonly: Ember.computed('edition', 'creation', function () {
      return !this.get('edition') && !this.get('creation');
    }).readOnly(),
    /**
     * Start edition on workplace time row
     * @private
     */
    startWorkplaceTimeRowEdition: function startWorkplaceTimeRowEdition() {
      // backup current values to restore them if user click on cancel button
      var workplaceTimeRowriginalValues = {
        selectedWorkplaceType: (0, _emberCopy.copy)(this.get('selectedWorkplaceType')),
        workplaceTimes: []
      };
      var workplaceTimes = this.get('workplaceTimes');
      if (workplaceTimes) {
        workplaceTimes.forEach(function (workplaceTime, dayIndex) {
          workplaceTimeRowriginalValues.workplaceTimes.push({
            dayIndex: dayIndex,
            value: workplaceTime.value
          });
        });
      }
      this.set('originalValues', workplaceTimeRowriginalValues);
      this.set('edition', true);
    },
    /**
     * Confirm edition validation on workplace time row
     * @private
     */
    confirmWorkplaceTimeRowEdition: function confirmWorkplaceTimeRowEdition() {
      // clear original values and do nothing, if edition is confirmed, current values are now the last setted values
      this.set('originalValues', undefined);
      this.set('edition', false);
      this.set('creation', false);
    },
    totalActivity: Ember.computed('workplaceTimes.@each.duration', 'workUnitRate', function () {
      var _this2 = this;
      return this.get('workplaceTimes').reduce(function (totals, workplaceTime) {
        var duration = parseFloat(workplaceTime.get('duration')) || 0;
        var workUnitRate = _this2.get('workUnitRate');
        totals.days += duration;
        totals.workUnits += duration * workUnitRate;
        return totals;
      }, {
        days: 0,
        workUnits: 0
      });
    }).readOnly(),
    /**
     * Return a copy of this RegularTimesRow (RTR) object
     *
     *     const RTRCopy = Ember.copy(originalRTR);
     *
     * @method copy
     * @return {WorkplaceTimesRow} copy of this RTR object
     * @private
     */
    copy: function copy(deep) {
      var _this$getProperties4 = this.getProperties('term', 'row', 'workUnitRate', 'fillState', 'selectedWorplaceType'),
        term = _this$getProperties4.term,
        row = _this$getProperties4.row,
        workUnitRate = _this$getProperties4.workUnitRate,
        fillState = _this$getProperties4.fillState,
        selectedWorplaceType = _this$getProperties4.selectedWorplaceType;
      return RegularTimesRow.create({
        term: term.clone(),
        row: row,
        workUnitRate: workUnitRate,
        fillState: fillState,
        selectedWorplaceType: typeof deep !== 'undefined' ? (0, _emberCopy.copy)(selectedWorplaceType, deep) : selectedWorplaceType
      });
    }
  });

  /**
   * timeReport's states
   *
   * @type {String[]}
   */
  var STATES = _exports.STATES = [_baseactivity.ACTIVITY_SAVED_AND_NO_VALIDATION, _baseactivity.ACTIVITY_WAITING_FOR_VALIDATION, _baseactivity.ACTIVITY_VALIDATED, _baseactivity.ACTIVITY_REJECTED];

  /**
   * @class Timesreport
   * @namespace BoondManager.TimesReport
   * @extends Ember.Object
   * @uses Copyable
   */
  var TimesReport = _baseactivity.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'resource', 'orders', 'projects', 'validations', 'validationWorkflow', 'expensesReport']
    }),
    /**************************************************************************/
    /** ATTRIBUTES ************************************************************/
    /**************************************************************************/
    closed: _emberData.default.attr('boolean'),
    /**
     * Information Comments maxLength=500
     * @type {String}
     */
    informationComments: _emberData.default.attr('string'),
    term: _emberData.default.attr('date'),
    // TAB_LISTETEMPS.LISTETEMPS_DATE
    creationDate: _emberData.default.attr('moment'),
    // TAB_LISTETEMPS.LISTETEMPS_CREATEDAT,
    updateDate: _emberData.default.attr('moment'),
    workUnitRate: _emberData.default.attr('number'),
    /**************************************************************************/
    /** FRAGMENTS *************************************************************/
    /**************************************************************************/
    /**
     * List of absences
     * @type {Regulartime[]}
     */
    absencesTimes: (0, _attributes.fragmentArray)('regulartime'),
    /**
     * List of planned times
     * @type {Regulartime[]}
     */
    plannedTimes: (0, _attributes.fragmentArray)('regulartime'),
    /**
     * List of exceptional times
     * @type {Exceptionaltime[]}
     */
    exceptionalTimes: (0, _attributes.fragmentArray)('exceptionaltime'),
    /**
     * List of regular times
     * @type {Regulartime[]}
     */
    regularTimes: (0, _attributes.fragmentArray)('regulartime'),
    /**
     * List of workplace times
     * @type {WorkplaceTime[]}
     */
    workplaceTimes: (0, _attributes.fragmentArray)('workplacetime'),
    /**************************************************************************/
    /** RELATIONSHIPS *********************************************************/
    /**************************************************************************/
    expensesReport: _emberData.default.belongsTo('expensesreport'),
    orders: _emberData.default.hasMany(),
    projects: _emberData.default.hasMany(),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    canReadTimesReport: _emberData.default.attr('boolean'),
    /**************************************************************************/
    /** CPs CREATED ON INIT ***************************************************/
    /**************************************************************************/
    /**
     * @property isSavedAndNoValidation
     * @see  _createIsStatesReadOnlyCP
     * @return {Boolean}
     * @readOnly
     */
    /**
     * @property isWaitingForValidation
     * @see  _createIsStatesReadOnlyCP
     * @return {Boolean}
     * @readOnly
     */
    /**
     * @property isValidated
     * @see  _createIsStatesReadOnlyCP
     * @return {Boolean}
     * @readOnly
     */
    /**
     * @property isRejected
     * @see  _createIsStatesReadOnlyCP
     * @return {Boolean}
     * @readOnly
     */
    /**
     * @property isMine
     * @see  _createIsMineReadOnlyCP
     * @return {Boolean} - `true`if the report is owned by current connected user
     * @readOnly
     */
    init: function init() {
      var _this3 = this;
      this._super.apply(this, arguments);

      /**
       * On Init, create some boolean CPs based on STATES
       *   * isSavedAndNoValidation
       *   * isWaitingForValidation
       *   * isValidated
       *   * ...
       */
      STATES.forEach(function (state) {
        var stateName = Ember.String.capitalize(state);
        Ember.defineProperty(_this3, "is".concat(stateName), Ember.computed.equal('state', state));
      });
    },
    /**
     * Resource's validation (= first item of model.validations relationship array)
     *
     * @property resourceValidation
     * @observes validations.[]
     * @type {Promise}
     * @fulfil {Validation} - Resource's validation
     */
    resourceValidation: Ember.computed('validations.[]', function () {
      var promise = this.get('validations').then(function (validations) {
        var resourceValidation;
        if (validations.get('length')) {
          resourceValidation = validations.get('firstObject');
        }
        return resourceValidation;
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    /**
     * Last month day
     *
     * @property lastTermDay
     * @observes term
     * @return {Moment}
     */
    lastTermDay: Ember.computed('term', function () {
      return (0, _moment.default)(this.get('term')).endOf('month');
    }),
    /**************************************************************************/
    /*** ACTIVITIES ***********************************************************/
    /**************************************************************************/
    /**
     * Absence activity
     *
     * @property absenceActivity
     * @observes term, workUnitTypes.absence
     * @return {Promise}
     * @fulfil {Activity}
     * @readonly
     */
    absenceActivity: Ember.computed('term', 'workUnitTypes.absence', function () {
      var owner = Ember.getOwner(this);
      var Activity = owner.factoryFor('bm:activity');
      var activityValue = {
        type: _workunittype.WUT_ABSENCE,
        term: this.get('term')
      };
      var promises = {
        workUnitTypes: this.get('workUnitTypes')
      };
      var promise = Ember.RSVP.hash(promises).then(function (_ref) {
        var workUnitTypes = _ref.workUnitTypes;
        var absenceWorkUnitTypes = workUnitTypes.absence;
        activityValue.disabled = Object.keys(absenceWorkUnitTypes).length === 0;
        return Activity.create(activityValue);
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**
     * Internal activity
     *
     * @property internalActivity
     * @observes term, workUnitType.internal
     * @return {Promise}
     * @fulfil {Activity}
     * @readonly
     */
    internalActivity: Ember.computed('term', 'workUnitTypes.internal', function () {
      var owner = Ember.getOwner(this);
      var Activity = owner.factoryFor('bm:activity');
      var activityValue = {
        type: _workunittype.WUT_INTERNAL,
        term: this.get('term')
      };
      var promise = this.get('workUnitTypes').then(function (workUnitType) {
        activityValue.disabled = Object.keys(workUnitType.internal).length === 0;
        return Activity.create(activityValue);
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**
     * Production activities
     *
     * @property productionActivities
     * @observes term, projects and workUnitTypes.production
     * @return {Promise}
     * @filfil {Activity[]}
     * @readonly
     */
    productionActivities: Ember.computed('term', 'projects', 'workUnitTypes.production', 'projects.@each.{batches,deliveries}', function () {
      var _this4 = this;
      var term = this.get('term');
      // create a promise resolved when we get all projects' companies & batches & deliveries
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var activities = [];
        _this4.get('workUnitTypes').then(function (workUnitTypes) {
          var noWUTProduct = workUnitTypes.production.length === 0;
          //tsi: on disabled le champs si pas de workUnitTypes "production"
          var disabled = noWUTProduct;
          _this4.get('projects').then(function (projects) {
            var owner = Ember.getOwner(_this4);
            var Activity = owner.factoryFor('bm:activity');

            // allProjectsPromises is an array of Promises
            var allProjectsPromises = projects.map(function (project) {
              var projectPromises = {
                company: project.get('company'),
                batches: project.get('batches'),
                deliveries: project.get('deliveries')
              };
              return Ember.RSVP.hash(projectPromises, "project ".concat(project.get('getReference'), " - activities - get company, batches & deliveries")).then(function (_ref2) {
                var company = _ref2.company,
                  batches = _ref2.batches,
                  deliveries = _ref2.deliveries;
                // push activity id-value obj in `activities` array
                deliveries.forEach(function (delivery) {
                  var activity = {
                    type: _workunittype.WUT_PRODUCTION,
                    term: term,
                    project: project,
                    delivery: delivery,
                    noWUTProduct: noWUTProduct,
                    disabled: disabled
                  };
                  if (company) {
                    activity.company = company;
                  }
                  if (batches && batches.length) {
                    batches.forEach(function (batch) {
                      activity.batch = batch;
                      activities.push(Activity.create(activity));
                    });
                  } else {
                    activities.push(Activity.create(activity));
                  }
                });

                // on ajoute l'activité qui n'existerait plus, par ex une saisie sur une presta sans lot alors que la presta actuelle possede des lots
                var regularTimes = _this4.get('regularTimes');
                var exceptionalTimes = _this4.get('exceptionalTimes');
                var plannedTimes = _this4.get('plannedTimes');
                [regularTimes, exceptionalTimes, plannedTimes].forEach(function (times) {
                  times.filter(function (time) {
                    return Ember.get(time, 'project.id') === Ember.get(project, 'id');
                  }).forEach(function (time) {
                    var activityExists = activities.find(function (item) {
                      return Ember.get(time, 'project.id') && item.type === _workunittype.WUT_PRODUCTION && Ember.get(item, 'project.id') === Ember.get(time, 'project.id') && Ember.get(item, 'delivery.id') === Ember.get(time, 'delivery.id') && Ember.get(item, 'batch.id') === Ember.get(time, 'batch.id');
                    });
                    if (!activityExists) {
                      var deliveryID = Ember.get(time, 'delivery.id');
                      var delivery = null;
                      if (deliveryID) {
                        // récupération du model dans le store (création ou mise à jour)
                        delivery = _this4.store.push({
                          data: {
                            id: deliveryID,
                            type: 'delivery',
                            attributes: {
                              title: Ember.get(time, 'delivery.title'),
                              startDate: Ember.get(time, 'delivery.startDate'),
                              endDate: Ember.get(time, 'delivery.endDate')
                            }
                          }
                        });
                      }
                      var activity = {
                        type: _workunittype.WUT_PRODUCTION,
                        term: term,
                        project: project,
                        delivery: delivery,
                        noWUTProduct: noWUTProduct,
                        disabled: disabled
                      };
                      if (company) {
                        activity.company = company;
                      }
                      var batch = Ember.get(time, 'batch');
                      if (batch) {
                        activity.batch = batch;
                      }
                      activities.push(Activity.create(activity));
                    }
                  });
                });
              });
            });
            Ember.RSVP.all(allProjectsPromises, "All ".concat(projects.length, " projects promises")).then(function () {
              resolve(activities);
            }, function (reason) {
              reject(reason);
            });
          }, function (reason) {
            reject(reason);
          });
        });
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**
     * Activities
     *
     * Concatenation of *Activities
     *
     * @property activities
     * @observes absenceActivity, internalActivity, productionActivities
     * @return {Promise}
     * @fulfil {Activity[]}
     * @readonly
     */
    activities: Ember.computed('productionActivities', 'absenceActivity', 'internalActivity', function () {
      var activities = [this.get('absenceActivity'), this.get('internalActivity'), this.get('productionActivities')];
      var promise = new Ember.RSVP.all(activities).then(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 3),
          absence = _ref4[0],
          internal = _ref4[1],
          production = _ref4[2];
        var activities = [];
        activities.push(absence, internal);
        return activities.concat(production).filter(function (activity) {
          return activity.get('disabled') === false;
        });
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**************************************************************************/
    /** WORK UNIT TYPES *******************************************************/
    /**************************************************************************/
    /**
     * WUTs Object
     *
     * @typedef {Object} WorkUnitType
     * @property {Number} reference
     * @property {String} name
     * @property {String} activityType
     *
     * @typedef {Object} WorkUnitTypes
     * @property {WorkUnitType[]} absence
     * @property {WorkUnitType[]} internal
     * @property {WorkUnitType[]} production
     * @property {WorkUnitType[]} exceptionalCalendar
     * @property {WorkUnitType[]} exceptionalTime
     */

    /**
     * Work Unit Types by Activity Type
     *
     * Activity types are:
     *   * absence
     *   * internal
     *   * production
     *   * exceptionalCalendar
     *   * exceptionalTime
     *
     * @todo Optimize object building (use a single loop to build the object and not many 'filterBy'...)
     *
     * @property workUnitTypes
     * @observes resource.workUnitTypesAllowed.[]
     * @type {Promise}
     * @fulfil {WorkUnitTypes}
     */
    workUnitTypes: Ember.computed('resource.workUnitTypesAllowed.[]', 'absencesTimes.@each.workUnitType', 'plannedTimes.@each.workUnitType', 'regularTimes.@each.workUnitType', 'exceptionalTimes.@each.workUnitType', function () {
      var _this5 = this;
      var promise = Ember.RSVP.hash({
        resource: this.get('resource')
      }).then(function (_ref5) {
        var resource = _ref5.resource;
        var workUnitTypesAllowed = (0, _emberCopy.copy)(resource.get('workUnitTypesAllowed'));

        //Début des ajouts
        /* on parse les temps exceptionnel & l'activité normale pour etre ajouter des workUnitTypes qui auraient été supprimés */
        var regularTimes = _this5.get('regularTimes');
        regularTimes.forEach(function (time) {
          var wut = time.get('workUnitType');
          if (!workUnitTypesAllowed.find(function (type) {
            return type.reference === wut.reference;
          })) {
            workUnitTypesAllowed.pushObject(wut);
          }
        });
        var exceptionalTimes = _this5.get('exceptionalTimes');
        exceptionalTimes.forEach(function (time) {
          var wut = time.get('workUnitType');
          if (!workUnitTypesAllowed.find(function (type) {
            return type.reference === wut.reference;
          })) {
            workUnitTypesAllowed.pushObject(wut);
          }
        });

        /* on parse les temps planifiés pour etre ajouter des workUnitTypes qui auraient été supprimés */
        var plannedTimes = _this5.get('plannedTimes');
        plannedTimes.forEach(function (time) {
          var wut = time.get('workUnitType');
          if (!workUnitTypesAllowed.find(function (type) {
            return type.reference === wut.reference;
          })) {
            workUnitTypesAllowed.pushObject(wut);
          }
        });

        /* on parse les absences saisis pour etre ajouter des workUnitTypes qui auraient été supprimés */
        var absencesTimes = _this5.get('absencesTimes');
        absencesTimes.forEach(function (time) {
          var wut = time.get('workUnitType');
          if (!workUnitTypesAllowed.find(function (type) {
            return type.reference === wut.reference;
          })) {
            workUnitTypesAllowed.pushObject(wut);
          }
        });
        // fin des ajouts

        return {
          //activityType : workUnitTypes
          absence: workUnitTypesAllowed.filterBy('activityType', _workunittype.WUT_ABSENCE),
          internal: workUnitTypesAllowed.filterBy('activityType', _workunittype.WUT_INTERNAL),
          production: workUnitTypesAllowed.filterBy('activityType', _workunittype.WUT_PRODUCTION),
          exceptionalCalendar: workUnitTypesAllowed.filterBy('activityType', _workunittype.WUT_EXCEPTIONAL_CALENDAR),
          exceptionalTime: workUnitTypesAllowed.filterBy('activityType', _workunittype.WUT_EXCEPTIONAL_TIME)
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    /**
     * Exceptional Work Unit Types
     * @property exceptionalWorkUnitTypes
     * @type {Promise}
     * @fulfil {Object[]}
     */
    exceptionalWorkUnitTypes: Ember.computed('workUnitTypes', function () {
      var promise = this.get('workUnitTypes').then(function (workUnitTypes) {
        return [].concat(workUnitTypes.exceptionalCalendar, workUnitTypes.exceptionalTime);
      });
      return ArrProxy.create({
        promise: promise
      });
    }),
    absencesDurations: Ember.computed('regularTimes.@each.{startDate,workUnitType,value}', function () {
      // construction du tableau des durées des absences par jour
      var absences = Ember.A([]);
      this.get('regularTimes').forEach(function (regularTime) {
        if (regularTime.get('workUnitType.activityType') === _workunittype.WUT_ABSENCE && regularTime.get('duration') > 0) {
          var key = regularTime.get('startDate').format('YYYYMMDD');
          if (!absences.hasOwnProperty(key)) {
            absences[key] = 0;
          }
          absences[key] += regularTime.get('duration');
        }
      });
      return absences;
    }).readOnly(),
    /**
     * Add an absenceTime (eq. regularTime) in a new Row. Add it in `this.regularTimes`
     * array too.
     *
     * @param {RegularTime}    absenceTime  absenceTime to add in `newRow`
     * @param {regularTimeRow} newRow       where to add the absenceTime
     *
     * @return {regularTimeRow}  the `newRow` passed in params
     * @private
     */
    _addAbsenceinNewRow: function _addAbsenceinNewRow(absenceTime, newRow) {
      var regularTimes = this.get('regularTimes');
      var dayIndex = absenceTime.get('startDate').date() - 1;
      absenceTime.set('row', newRow.get('row'));
      regularTimes.addFragment(absenceTime);
      newRow.get('regularTimes')[dayIndex] = absenceTime;
      return newRow;
    },
    /**
     * Create a new RegularTimeRow row and an absenceTime (eq. regularTime) in it.
     * Add it in `this.regularTimes` array too.
     *
     * @uses _addAbsenceinNewRow private method.
     *
     * @param {RegularTime} absenceTime  absenceTime to add in the new created row
     *
     * @return {Promise} Promise which resolve as a `newRegularTimesRow`
     * @private
     */
    _addNewRowAndAddAbsenceInIt: function _addNewRowAndAddAbsenceInIt(absenceTime) {
      var _this6 = this;
      // find selectedActivity
      var selectedActivity = {
        id: getActivityIDFromRegularOrExceptionalTime(absenceTime)
      };
      var selectedWorkUnitType = absenceTime.get('workUnitType');
      return this.addRegularTimesRow({
        selectedActivity: selectedActivity,
        selectedWorkUnitType: selectedWorkUnitType
      }).then(function (newRegularTimesRow) {
        return _this6._addAbsenceinNewRow(absenceTime, newRegularTimesRow);
      });
    },
    /**
     * Add missing Absences
     *
     * Create `RegularTimesRow` row for each uniq absence's WUT reference and add
     * related `RegularTimes` absences in it.
     *
     * @uses _addAbsenceinNewRow
     * @uses _addNewRowAndAddAbsenceInIt
     *
     * @return {Promise} Promise which resolve as the last `newRegularTimesRow` created
     *
     */
    addMissingAbsences: function addMissingAbsences() {
      var _this7 = this;
      var absencesTimes = this.get('absencesNotInRegularTimes');
      var promise;
      var previousRowRef;
      absencesTimes.sortBy('workUnitType.reference').forEach(function (regularTimeRef) {
        var regularTime = regularTimeRef.copy();
        var currentRowRef = regularTime.get('workUnitType.reference');
        regularTime.set('originalID', null);
        regularTime.set('workUnitRate', _this7.get('workUnitRate'));
        if (previousRowRef !== currentRowRef) {
          // Create a regularTimesRow
          previousRowRef = currentRowRef;
          if (promise) {
            promise = promise.then(function () {
              return _this7._addNewRowAndAddAbsenceInIt(regularTime);
            });
          } else {
            promise = _this7._addNewRowAndAddAbsenceInIt(regularTime);
          }
        } else {
          // juste add absence in current new row, so we chain
          promise = promise.then(function (newRegularTimesRow) {
            return _this7._addAbsenceinNewRow(regularTime, newRegularTimesRow);
          });
        }
      });
      return promise;
    },
    /*
     * Tsi Mpi: Creation d'un tableau de difference entre les regularTimes et les absenceTimes
     */
    absencesNotInRegularTimes: Ember.computed('regularTimes.[]', 'regularTimes.@each.{startDate,workUnitType,duration}', 'absencesTimes.@each.{startDate,workUnitType}', function () {
      var absencesRegularTimes = this.get('regularTimes').filterBy('workUnitType.activityType', _workunittype.WUT_ABSENCE);
      var absencesTimes = this.get('absencesTimes');
      return absencesTimes.map(function (absencesTime) {
        var totalDuration = absencesRegularTimes.reduce(function (total, absencesRegularTime) {
          if (absencesRegularTime.get('startDate').isSame(absencesTime.get('startDate')) && absencesRegularTime.get('workUnitType.reference') === absencesTime.get('workUnitType.reference')) {
            total.found = true;
            total.total += absencesRegularTime.get('duration');
          }
          return total;
        }, {
          found: false,
          total: 0
        });
        var absTime = (0, _emberCopy.copy)(absencesTime);
        if (!totalDuration.found) {
          return absTime;
        }
        absTime.set('duration', (0, _financial.roundAtPrecision)(absencesTime.get('duration') - totalDuration.total, 6));
        return absTime;
      }).filter(function (absencesTime) {
        return absencesTime.get('duration') > 0;
      });
    }),
    isAbsencesFilled: Ember.computed('absencesNotInRegularTimes.[]', function () {
      return this.get('absencesNotInRegularTimes.length') === 0;
    }),
    /**************************************************************************/
    /** PLANNED TIME **********************************************************/
    /**************************************************************************/
    /**
     * Add Planned time
     *
     * Create `RegularTimesRow` row for each planned time's WUT reference and add
     * related `RegularTimes` in it.
     *
     * @uses _addAbsenceinNewRow
     * @uses _addNewRowAndAddAbsenceInIt
     *
     * @return {Promise} Promise which resolve as the last `newRegularTimesRow` created
     *
     */
    addPlannedTimes: function addPlannedTimes() {
      var _this8 = this;
      var plannedTimesRowsPromisesMap = {};
      var plannedTimes = Ember.A(Ember.getWithDefault(this, 'plannedTimes', []));
      plannedTimes.sortBy('row').forEach(function (times) {
        // we make a copy of the projectsExpense because :
        // 1 - we would like to be able to add it several times independently of his original projectsExpense instance
        // 2 - we will update its `row` property with new row no from `plannedTimes`
        var timeToAdd = times.copy();
        // this "old" rowNo will be used to build the promises map `plannedTimesRowsPromisesMap`
        var rowNo = timeToAdd.get('row');
        timeToAdd.set('originalID', null);
        timeToAdd.set('workUnitRate', _this8.get('workUnitRate'));

        // Create a fixedExpensesRow if needed
        if (typeof plannedTimesRowsPromisesMap[rowNo] === 'undefined') {
          // find selectedActivity
          var selectedActivity = {
            id: getActivityIDFromRegularOrExceptionalTime(times)
          };
          var workUnityType = times.get('workUnitType');
          plannedTimesRowsPromisesMap[rowNo] = _this8.addRegularTimesRow({
            // we don't need a `row` property here because we leave `addRegularTime`
            // to give us a new one
            selectedActivity: selectedActivity,
            selectedWorkUnitType: workUnityType
          }).then(function (newtimesRow) {
            var newRowNo = newtimesRow.get('row');
            timeToAdd.set('row', newRowNo);
            _this8.addRegularTime(timeToAdd, newtimesRow);
            return newtimesRow;
          });
        } else {
          // we chain
          plannedTimesRowsPromisesMap[rowNo].then(function (newTimesRow) {
            var newRowNo = newTimesRow.get('row');
            timeToAdd.set('row', newRowNo);
            _this8.addRegularTime(timeToAdd, newTimesRow);
            return newTimesRow;
          });
        }
      });
      var plannedTimesRowPromise = Ember.RSVP.all(Object.keys(plannedTimesRowsPromisesMap).sort().map(function (rowNo) {
        return plannedTimesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: plannedTimesRowPromise
      });
    },
    /**************************************************************************/
    /** REGULAR TIMES *********************************************************/
    /**************************************************************************/
    /**
     * RegularTimesRow POJO
     *
     * @typedef {Object} RegularTimesRowPOJO
     * @property {Number}   row - row's no
     * @property {Activity} selectedActivity - selectedActivity (workUnitType have to be defined too)
     * @property {Object}   selectedWorkUnitType - workUnitType (selectedActivity have to be defined too) compatible with selectedActivity,
     */
    /**
     * Create new regularTimes row.
     *
     * @method createRegularTimesRow
     * @param {RegularTimesRowPOJO} regularTimesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {RegularTimesRow} - the new RegularTimes row
     */
    createRegularTimesRow: function createRegularTimesRow(regularTimesRow) {
      var _this9 = this;
      var promises = {
        workUnitTypes: this.get('workUnitTypes'),
        activities: this.get('activities')
      };
      var promise = Ember.RSVP.hash(promises).then(function (_ref6) {
        var workUnitTypes = _ref6.workUnitTypes,
          activities = _ref6.activities;
        var term = _this9.get('term');
        var workUnitRate = _this9.get('workUnitRate');
        // regularTimesRow properties
        var rowNo = Ember.getWithDefault(regularTimesRow, 'row', null);
        var newRegularTimesRow = {
          term: (0, _moment.default)(term),
          row: rowNo,
          workUnitRate: workUnitRate,
          activities: activities,
          workUnitTypes: workUnitTypes
        };
        if (Object.keys(regularTimesRow).length !== 0) {
          newRegularTimesRow.selectedActivity = Ember.get(regularTimesRow, 'selectedActivity');
          newRegularTimesRow.selectedWorkUnitType = Ember.get(regularTimesRow, 'selectedWorkUnitType');
        }
        return RegularTimesRow.create(newRegularTimesRow);
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Add new RegularTimes row.
     *
     * Create a new Regulartimes row and add it in `regularTimesRows` array
     *
     * @uses createRegularTimesRow
     * @uses regularTimesRows
     *
     * @method addRegularTimesRow
     * @param {RegularTimesRowPOJO} addedRegularTimesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {RegularTimesRow} - The new RegularTimes row.
     */
    addRegularTimesRow: function addRegularTimesRow(addedRegularTimesRow) {
      var _this10 = this;
      var promise = this.get('regularTimesRows').then(function (regularTimesRows) {
        var nextRowPOJO = {};
        if (regularTimesRows.get('length')) {
          var lastRow = regularTimesRows.get('lastObject');
          nextRowPOJO = {
            selectedActivity: lastRow.get('selectedActivity'),
            selectedWorkUnitType: lastRow.get('selectedWorkUnitType')
          };
        }
        Ember.assign(nextRowPOJO, addedRegularTimesRow);
        return _this10.createRegularTimesRow(nextRowPOJO).then(function (newRegularTimesRow) {
          // we must calculate the new row number now in the case 2 row has been added with the same action (project expenses)
          if (newRegularTimesRow.get('row') === null) {
            var rowNumber = -1;
            if (regularTimesRows.get('length')) {
              regularTimesRows.forEach(function (row) {
                if (rowNumber >= row.get('row')) {
                  rowNumber = row.get('row') - 1;
                }
              });
            }
            newRegularTimesRow.set('row', rowNumber);
          }
          newRegularTimesRow.set('creation', true);
          regularTimesRows.pushObject(newRegularTimesRow);
          return newRegularTimesRow;
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Delete a RegularTimes row.
     *
     * @method delRegularTimesRow
     * @param {RegularTimesRow} row - RegularTimes row to delete
     * @return {Promise}
     * @fulfil {RegularTimesRow} - deleted RegularTimes row
     */
    delRegularTimesRow: function delRegularTimesRow(row) {
      var _this11 = this;
      Ember.get(row, 'regularTimes').forEach(function (regularTime) {
        _this11.delRegularTime(regularTime, row);
      });
      return this.get('regularTimesRows').then(function (regularTimesRows) {
        return regularTimesRows.removeObject(row);
      });
    },
    /**
     * Find a regularTime in `regularTimes`
     *
     * The test is done on `startDate` and `row` properties
     *
     * @uses regularTimes
     *
     * @method findRegularTime
     * @param  {regularTime} regularTime - regularTime to find
     * @return {regularTime} regularTime found in `regularTimes` array
     */
    findRegularTime: function findRegularTime(regularTime) {
      var startDate = (0, _moment.default)(Ember.get(regularTime, 'startDate'));
      var rowNo = Ember.get(regularTime, 'row');
      return this.get('regularTimes').find(function (item) {
        return item.get('startDate').isSame(startDate) && item.get('row') === rowNo;
      });
    },
    /**
     * @method updateRegularTime
     *
     * @param {FakeRegularTime|RegularTime} regularTime - (Fake)RegularTime to update with value
     * @param {Number} value - regularTime's work unit value
     * @param {RegularTimesRow} regularTimesRow - row in which this regularTime will be updated
     * @return {RegularTime} updated regularTime
     */
    updateRegularTime: function updateRegularTime(regularTime, value, regularTimesRow) {
      // get regularTime to update : it's an already existing regularTime or a new one
      var updatedRegularTime = this.findRegularTime(regularTime) || this.addRegularTime(regularTime, regularTimesRow);

      // update its value
      updatedRegularTime.set('value', value);
      return updatedRegularTime;
    },
    /**
     * @method addRegularTime
     * @param {FakeRegularTime|RegularTime} regularTime - (Fake)RegularTime to add
     * @param {RegularTimesRow} regularTimesRow - row in which this regularTime will be added
     * @param {Boolean} mustCopy - if regularTime is a RegularTime (fragment) object, copy it or use its reference (default)
     * @return {RegularTime} - added regularTime
     */
    addRegularTime: function addRegularTime(regularTime, regularTimesRow) {
      var mustCopy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var addedRegularTime;
      switch (_typeof(regularTime)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(regularTime)) {
              addedRegularTime = mustCopy ? (0, _emberCopy.copy)(regularTime) : regularTime;
              this.get('regularTimes').addFragment(addedRegularTime);
            } else {
              var normalizedRegularTimePOJO = regularTimesRow.getNormalizedRegularTimePOJO(regularTime);
              addedRegularTime = this.get('regularTimes').createFragment(normalizedRegularTimePOJO);
              addedRegularTime.set('workUnitRate', this.get('workUnitRate'));
            }
            var dayIndex = Ember.get(regularTime, 'startDate').date() - 1;
            var regularTimes = regularTimesRow.get('regularTimes');

            // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
            regularTimes[dayIndex] = addedRegularTime;
            // Tell regularTimes that it has changed for template update
            regularTimes.arrayContentDidChange(dayIndex);
            break;
          }
        default:
          _logger.default.error.apply(_logger.default, ['TimesReport model - addRegularTime - wrong regularTime type'].concat(Array.prototype.slice.call(arguments)));
      }
      return addedRegularTime;
    },
    /**
     * @uses RegularTimesRow.delRegularTime
     * @uses regularTimes
     *
     * @method delRegularTime
     * @param {RegularTime} regularTime - regularTime to delete
     * @param {RegularTimesRow} regularTimesRow - row in which this regularTime will be removed
     * @return {RegularTime|undefined} - deleted regularTime or `undefined` if this regularTime can not be removed from
     *                                   `regularTimes` array
     */
    delRegularTime: function delRegularTime(regularTime, regularTimesRow) {
      if ((0, _fragment.isFragment)(regularTime)) {
        regularTimesRow.delRegularTime(regularTime);
        return this.get('regularTimes').removeFragment(regularTime);
      }
    },
    addAbsencesTimesToRegularTimes: function addAbsencesTimesToRegularTimes() {
      var _this12 = this;
      var regularTimesRowsPromisesMap = {};
      // display absencesTimes
      var absencesTimes = this.get('absencesTimes');
      var regularTimes = this.get('regularTimes');
      var rowNo = -1;
      var currentRef;
      //absencesTimes.sortBy('workUnitType').forEach(regularTime => {
      absencesTimes.sortBy('workUnitType.reference').forEach(function (absenceTime) {
        var regularTime = absenceTime.copy();
        // attention, l'ordre crée par le sortBy est différent de l'ordre sauvegardé (on peut avoir une premiere ligne à -2 et la suivante a -1)
        var rowRef = regularTime.get('workUnitType.reference');
        var dayIndex = regularTime.get('startDate').date() - 1;

        // update rowNo: it works because we sorted absencesTimes by reference before!
        if (typeof currentRef !== 'undefined' && currentRef !== rowRef) {
          rowNo--;
        }
        regularTime.setProperties({
          row: rowNo,
          workUnitRate: _this12.get('workUnitRate')
        });
        // put absencesTime in regularTimes to allow proper save
        regularTimes.addFragment(regularTime);

        // Create a regularTimesRow if needed
        if (typeof regularTimesRowsPromisesMap[rowRef] === 'undefined') {
          // find selectedActivity
          var selectedActivity = {
            id: getActivityIDFromRegularOrExceptionalTime(regularTime)
          };
          var selectedWorkUnitType = regularTime.get('workUnitType');

          // set currentRef to manage rowNo (cf comment 'update rowNo' at the begining of this forEach loop)
          currentRef = rowRef;
          regularTimesRowsPromisesMap[rowRef] = _this12.createRegularTimesRow({
            row: rowNo,
            selectedActivity: selectedActivity,
            selectedWorkUnitType: selectedWorkUnitType
          }).then(function (newRegularTimesRow) {
            newRegularTimesRow.get('regularTimes')[dayIndex] = regularTime;
            return newRegularTimesRow;
          });
        } else {
          // we chain
          regularTimesRowsPromisesMap[rowRef].then(function (newRegularTimesRow) {
            newRegularTimesRow.get('regularTimes')[dayIndex] = regularTime;
            return newRegularTimesRow;
          });
        }
      });
      var regularTimesRowsPromise = Ember.RSVP.all(Object.keys(regularTimesRowsPromisesMap).sort(function (a, b) {
        var aInt = parseInt(a);
        var bInt = parseInt(b);
        return aInt - bInt;
      }).map(function (rowNo) {
        return regularTimesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: regularTimesRowsPromise
      });
    },
    /*
     * On ajoute 'updateDate' dans la computed pour forcer le déclenchement de la reconstruction
     * des lignes sur les feuilles de temps après enregistrement afin de mettre à jour la propriété
     * row sur chacune des lignes avec son id en BDD
     */
    regularTimesRows: Ember.computed('regularTimes', 'updateDate', 'workUnitRate', function () {
      var _this13 = this;
      var regularTimesRowsPromisesMap = {};

      // display regularTimes
      var regularTimes = Ember.A(this.getWithDefault('regularTimes', []));
      regularTimes.sortBy('row').forEach(function (regularTime) {
        var dayIndex = regularTime.get('startDate').date() - 1;
        var rowNo = regularTime.get('row');
        regularTime.set('workUnitRate', _this13.get('workUnitRate'));

        // Create a regularTimesRow if needed
        if (typeof regularTimesRowsPromisesMap[rowNo] === 'undefined') {
          // find selectedActivity
          var selectedActivity = {
            id: getActivityIDFromRegularOrExceptionalTime(regularTime)
          };
          var selectedWorkUnitType = regularTime.get('workUnitType');
          regularTimesRowsPromisesMap[rowNo] = _this13.createRegularTimesRow({
            row: rowNo,
            selectedActivity: selectedActivity,
            selectedWorkUnitType: selectedWorkUnitType
          }).then(function (newRegularTimesRow) {
            newRegularTimesRow.get('regularTimes')[dayIndex] = regularTime;
            return newRegularTimesRow;
          });
        } else {
          // we chain
          regularTimesRowsPromisesMap[rowNo].then(function (newRegularTimesRow) {
            newRegularTimesRow.get('regularTimes')[dayIndex] = regularTime;
            return newRegularTimesRow;
          });
        }
      });
      var regularTimesRowsPromise = Ember.RSVP.all(Object.keys(regularTimesRowsPromisesMap).sort(function (a, b) {
        var aInt = parseInt(a);
        var bInt = parseInt(b);
        return aInt - bInt;
      }).map(function (rowNo) {
        return regularTimesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: regularTimesRowsPromise
      });
    }),
    /**************************************************************************/
    /** WORKPLACE TIMES *********************************************************/
    /**************************************************************************/
    /**
     * WorkplaceTimesRow POJO
     *
     * @typedef {Object} WorkplaceTimesRowPOJO
     * @property {Number}   row - row's no
     * @property {Activity} selectedActivity - selectedActivity (workUnitType have to be defined too)
     * @property {Object}   selectedWorkUnitType - workUnitType (selectedActivity have to be defined too) compatible with selectedActivity,
     */
    /**
     * Create new workplaceTimes row.
     *
     * @method createWorkplaceTimesRow
     * @param {WorkplaceTimesRowPOJO} workplaceTimesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {WorkplaceTimesRow} - the new WorkplaceTimes row
     */
    createWorkplaceTimesRow: function createWorkplaceTimesRow(workplaceTimesRow) {
      var _this14 = this;
      var promise = this.get('agency').then(function (agency) {
        return WorkplaceTimesRow.create({
          term: (0, _moment.default)(_this14.get('term')),
          row: Ember.getWithDefault(workplaceTimesRow, 'row', null),
          workUnitRate: _this14.get('workUnitRate'),
          workplaceTypes: agency.get('workplaceTypes'),
          selectedWorkplaceType: Ember.getWithDefault(workplaceTimesRow, 'selectedWorkplaceType', agency.get('workplaceTypes.firstObject'))
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Add new WorkplaceTimes row.
     *
     * Create a new Workplacetimes row and add it in `workplaceTimesRows` array
     *
     * @uses createWorkplaceTimesRow
     * @uses workplaceTimesRows
     *
     * @method addWorkplaceTimesRow
     * @param {WorkplaceTimesRowPOJO} addedWorkplaceTimesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {WorkplaceTimesRow} - The new WorkplaceTimes row.
     */
    addWorkplaceTimesRow: function addWorkplaceTimesRow(addedWorkplaceTimesRow) {
      var _this15 = this;
      var promise = this.get('agency').then(function (agency) {
        return _this15.get('workplaceTimesRows').then(function (workplaceTimesRows) {
          var selectedWorkplaceType;
          if (workplaceTimesRows.get('length')) {
            var lastRow = workplaceTimesRows.get('lastObject');
            selectedWorkplaceType = (0, _emberCopy.copy)(lastRow.get('selectedWorkplaceType'));
          }
          var nextRowPOJO = {
            selectedWorkplaceType: selectedWorkplaceType,
            workplaceTypes: agency.get('workplaceTypes')
          };
          Ember.assign(nextRowPOJO, addedWorkplaceTimesRow);
          return _this15.createWorkplaceTimesRow(nextRowPOJO).then(function (newWorkplaceTimesRow) {
            // we must calculate the new row number now in the case 2 row has been added with the same action (project expenses)
            if (newWorkplaceTimesRow.get('row') === null) {
              var rowNumber = -1;
              if (workplaceTimesRows.get('length')) {
                workplaceTimesRows.forEach(function (row) {
                  if (rowNumber >= row.get('row')) {
                    rowNumber = row.get('row') - 1;
                  }
                });
              }
              newWorkplaceTimesRow.set('row', rowNumber);
            }
            newWorkplaceTimesRow.set('creation', true);
            workplaceTimesRows.pushObject(newWorkplaceTimesRow);
            return newWorkplaceTimesRow;
          });
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Delete a WorkplaceTimes row.
     *
     * @method delWorkplaceTimesRow
     * @param {WorkplaceTimesRow} row - WorkplaceTimes row to delete
     * @return {Promise}
     * @fulfil {WorkplaceTimesRow} - deleted WorkplaceTimes row
     */
    delWorkplaceTimesRow: function delWorkplaceTimesRow(row) {
      var _this16 = this;
      Ember.get(row, 'workplaceTimes').forEach(function (workplaceTime) {
        _this16.delWorkplaceTime(workplaceTime, row);
      });
      return this.get('workplaceTimesRows').then(function (workplaceTimesRows) {
        return workplaceTimesRows.removeObject(row);
      });
    },
    /**
     * Find a workplaceTime in `workplaceTimes`
     *
     * The test is done on `startDate` and `row` properties
     *
     * @uses workplaceTimes
     *
     * @method findWorkplaceTime
     * @param  {workplaceTime} workplaceTime - workplaceTime to find
     * @return {workplaceTime} workplaceTime found in `workplaceTimes` array
     */
    findWorkplaceTime: function findWorkplaceTime(workplaceTime) {
      var startDate = (0, _moment.default)(Ember.get(workplaceTime, 'startDate'));
      var rowNo = Ember.get(workplaceTime, 'row');
      return this.get('workplaceTimes').find(function (item) {
        return item.get('startDate').isSame(startDate) && item.get('row') === rowNo;
      });
    },
    /**
     * @method updateWorkplaceTime
     *
     * @param {FakeWorkplaceTime|WorkplaceTime} workplaceTime - (Fake)WorkplaceTime to update with value
     * @param {Number} value - workplaceTime's work unit value
     * @param {WorkplaceTimesRow} workplaceTimesRow - row in which this workplaceTime will be updated
     * @return {WorkplaceTime} updated workplaceTime
     */
    updateWorkplaceTime: function updateWorkplaceTime(workplaceTime, value, workplaceTimesRow) {
      // get workplaceTime to update : it's an already existing workplaceTime or a new one
      var updatedWorkplaceTime = this.findWorkplaceTime(workplaceTime) || this.addWorkplaceTime(workplaceTime, workplaceTimesRow);

      // update its value
      updatedWorkplaceTime.set('value', value);
      return updatedWorkplaceTime;
    },
    /**
     * @method addWorkplaceTime
     * @param {FakeWorkplaceTime|WorkplaceTime} workplaceTime - (Fake)WorkplaceTime to add
     * @param {WorkplaceTimesRow} workplaceTimesRow - row in which this workplaceTime will be added
     * @param {Boolean} mustCopy - if workplaceTime is a WorkplaceTime (fragment) object, copy it or use its reference (default)
     * @return {WorkplaceTime} - added workplaceTime
     */
    addWorkplaceTime: function addWorkplaceTime(workplaceTime, workplaceTimesRow) {
      var mustCopy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var addedWorkplaceTime;
      switch (_typeof(workplaceTime)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(workplaceTime)) {
              addedWorkplaceTime = mustCopy ? (0, _emberCopy.copy)(workplaceTime) : workplaceTime;
              this.get('workplaceTimes').addFragment(addedWorkplaceTime);
            } else {
              var normalizedWorkplaceTimePOJO = workplaceTimesRow.getNormalizedWorkplaceTimePOJO(workplaceTime);
              addedWorkplaceTime = this.get('workplaceTimes').createFragment(normalizedWorkplaceTimePOJO);
              addedWorkplaceTime.set('workUnitRate', this.get('workUnitRate'));
            }
            var dayIndex = Ember.get(workplaceTime, 'startDate').date() - 1;
            var workplaceTimes = workplaceTimesRow.get('workplaceTimes');

            // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
            workplaceTimes[dayIndex] = addedWorkplaceTime;
            // Tell workplaceTimes that it has changed for template update
            workplaceTimes.arrayContentDidChange(dayIndex);
            break;
          }
        default:
          _logger.default.error.apply(_logger.default, ['TimesReport model - addWorkplaceTime - wrong workplaceTime type'].concat(Array.prototype.slice.call(arguments)));
      }
      return addedWorkplaceTime;
    },
    /**
     * @uses WorkplaceTimesRow.delWorkplaceTime
     * @uses workplaceTimes
     *
     * @method delWorkplaceTime
     * @param {WorkplaceTime} workplaceTime - workplaceTime to delete
     * @param {WorkplaceTimesRow} workplaceTimesRow - row in which this workplaceTime will be removed
     * @return {WorkplaceTime|undefined} - deleted workplaceTime or `undefined` if this workplaceTime can not be removed from
     *                                   `workplaceTimes` array
     */
    delWorkplaceTime: function delWorkplaceTime(workplaceTime, workplaceTimesRow) {
      if ((0, _fragment.isFragment)(workplaceTime)) {
        workplaceTimesRow.delWorkplaceTime(workplaceTime);
        return this.get('workplaceTimes').removeFragment(workplaceTime);
      }
    },
    /*
     * Voir le commentaire pour le BM-4117
     */
    workplaceTimesRows: Ember.computed('workplaceTimes', 'updateDate', 'workUnitRate', function () {
      var _this17 = this;
      var workplaceTimesRowsPromisesMap = {};

      // display workplaceTimes
      var workplaceTimes = Ember.A(this.getWithDefault('workplaceTimes', []));
      workplaceTimes.sortBy('row').forEach(function (workplaceTime) {
        var dayIndex = workplaceTime.get('startDate').date() - 1;
        var rowNo = workplaceTime.get('row');
        workplaceTime.set('workUnitRate', _this17.get('workUnitRate'));

        // Create a workplaceTimesRow if needed
        if (typeof workplaceTimesRowsPromisesMap[rowNo] === 'undefined') {
          workplaceTimesRowsPromisesMap[rowNo] = _this17.get('agency').then(function (agency) {
            return _this17.createWorkplaceTimesRow({
              row: rowNo,
              selectedWorkplaceType: workplaceTime.get('workplaceType'),
              workplaceTypes: agency.get('workplaceTypes')
            }).then(function (newWorkplaceTimesRow) {
              newWorkplaceTimesRow.get('workplaceTimes')[dayIndex] = workplaceTime;
              return newWorkplaceTimesRow;
            });
          });
        } else {
          // we chain
          workplaceTimesRowsPromisesMap[rowNo].then(function (newWorkplaceTimesRow) {
            newWorkplaceTimesRow.get('workplaceTimes')[dayIndex] = workplaceTime;
            return newWorkplaceTimesRow;
          });
        }
      });
      var workplaceTimesRowsPromise = Ember.RSVP.all(Object.keys(workplaceTimesRowsPromisesMap).sort(function (a, b) {
        var aInt = parseInt(a);
        var bInt = parseInt(b);
        return aInt - bInt;
      }).map(function (rowNo) {
        return workplaceTimesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: workplaceTimesRowsPromise
      });
    }),
    /**************************************************************************/
    /** EXCEPTIONAL TIMES *********************************************************/
    /**************************************************************************/
    // XXXXX BUG POTENTIEL XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    // Dans son état actuel, cette CP ne pourra être utilisée que dans un template !
    // Effectivement, dans un template `{{model.canAddExceptionalTimes}}` utilisera
    // la valeur de `model.canAddExceptionalTimes.content` qui est le booléen
    // recherché.
    // OR CE N'EST PAS LE CAS DANS UN FICHIER JS (route, controller ,composant,
    // etc) : un `this.get('model.canAddExceptionalTimes')`renverra l'ObjectProxy !
    // et non le booléen...
    //
    // cf https://ember-twiddle.com/d12cb16bf3578ccac1b7b39eb2ad9dfe?openFiles=templates.application.hbs%2C
    canAddExceptionalTimes: Ember.computed('exceptionalTimes', 'resource.allowExceptionalTimes', function () {
      var _this18 = this;
      var promise = this.get('productionActivities').then(function (productionActivities) {
        return _this18.get('exceptionalTimes.length') > 0 || productionActivities.length > 0 && _this18.get('resource.allowExceptionalTimes') !== 0;
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    canRecoverExceptionalTimes: Ember.computed('resource.{allowExceptionalTimes,canRecoverExceptionalTimes}', function () {
      return this.get('resource.allowExceptionalTimes') !== 0 && this.get('resource.canRecoverExceptionalTimes') === true;
    }),
    addExceptionalTime: function addExceptionalTime() {
      var exceptionalTimes = this.get('exceptionalTimes');
      // new ExceptionalTime's properties
      var newExceptionalTime = {
        description: '',
        recovering: false
      };

      // promises to get new exceptionalTime fragment's selected(Activity|WorkUnitType)
      var promises;
      if (exceptionalTimes.get('length')) {
        var lastExceptionalTime = exceptionalTimes.get('lastObject');
        promises = {
          selectedActivity: lastExceptionalTime.get('selectedActivity'),
          selectedWorkUnitType: lastExceptionalTime.get('selectedWorkUnitType')
        };
        newExceptionalTime.startDate = lastExceptionalTime.get('startDate').clone();
        newExceptionalTime.endDate = lastExceptionalTime.get('endDate').clone();
      } else {
        promises = {
          selectedActivity: this.get('productionActivities').then(function (productionActivities) {
            return productionActivities[0];
          }),
          selectedWorkUnitType: this.get('exceptionalWorkUnitTypes').then(function (exceptionalWorkUnitTypes) {
            return exceptionalWorkUnitTypes[0];
          })
        };
        newExceptionalTime.startDate = (0, _moment.default)(this.get('term')).tz('Europe/Paris', true).date(1); // firstDay
        newExceptionalTime.endDate = this.get('lastTermDay').clone().tz('Europe/Paris', true);
      }
      var promise = Ember.RSVP.hash(promises, 'get selectedActivity + selectedWorkUnitType').then(function (_ref7) {
        var selectedActivity = _ref7.selectedActivity,
          selectedWorkUnitType = _ref7.selectedWorkUnitType;
        if (!selectedWorkUnitType) {
          return;
        }
        newExceptionalTime.selectedActivity = selectedActivity;
        newExceptionalTime.selectedWorkUnitType = selectedWorkUnitType;
        newExceptionalTime.creation = true;
        if (newExceptionalTime.selectedWorkUnitType.activityType === 'exceptionalTime') {
          newExceptionalTime.endDate = newExceptionalTime.startDate.clone();
        }
        // Can't use fragmentArray.createFragment because the new fragment is:
        // 1 - created
        // 2 - its CP are initialized
        // 3 - added in the array
        //
        // step 2 crash because CPs use 'owner' CP. This CP can be used only after step 3!
        // (because the new fragment only know who is its owner when it is added in it ;p)
        //
        //return exceptionalTimes.createFragment(newExceptionalTime);
        //
        // so we use this: (cf https://github.com/lytics/ember-data-model-fragments/blob/master/addon/array/fragment.js#L20)
        var record = Ember.get(exceptionalTimes, 'owner');
        var store = Ember.get(record, 'store');
        var declaredModelName = Ember.get(exceptionalTimes, 'type');
        var options = Ember.get(exceptionalTimes, 'options');
        var key = Ember.get(exceptionalTimes, 'name');

        // 1 - create the fragment with no data but with its 'owner' (ie. record) initialized
        var exceptionalTime = (0, _fragment.createFragment)(store, declaredModelName, record, key, options);
        // 2 - set properties
        exceptionalTime.setProperties(newExceptionalTime);
        // 3 - push the new fragment
        return exceptionalTimes.pushObject(exceptionalTime);
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    delExceptionalTime: function delExceptionalTime(exceptionalTime) {
      this.get('exceptionalTimes').removeFragment(exceptionalTime);
    },
    totalsByDay: Ember.computed('regularTimes.@each.duration', 'workUnitRate', function () {
      var regularTimes = this.get('regularTimes');
      var workUnitRate = this.get('workUnitRate');
      var totals = regularTimes.reduce(function (totals, regularTime) {
        var dayNumber = (0, _moment.default)(regularTime.get('startDate')).format('D');
        totals[dayNumber] = totals[dayNumber] || 0;
        totals[dayNumber] += parseFloat(regularTime.get('duration'));
        return totals;
      }, {});

      // application du coef de workUnitRate pour ne pas accumuler des erreurs de précisions
      for (var day in totals) {
        if (totals.hasOwnProperty(day)) {
          totals[day] = (0, _financial.roundAtPrecision)((0, _financial.mult)(totals[day], workUnitRate), 3);
        }
      }
      return totals;
    }),
    totalsWorkplaceByDay: Ember.computed('workplaceTimes.@each.{startDate,duration}', 'workUnitRate', function () {
      var workplaceTimes = this.get('workplaceTimes');
      var workUnitRate = this.get('workUnitRate');
      var totals = workplaceTimes.reduce(function (totals, workplaceTime) {
        var dayNumber = (0, _moment.default)(workplaceTime.get('startDate')).format('D');
        totals[dayNumber] = totals[dayNumber] || 0;
        totals[dayNumber] += parseFloat(workplaceTime.get('duration'));
        return totals;
      }, {});

      // application du coef de workUnitRate pour ne pas accumuler des erreurs de précisions
      for (var day in totals) {
        if (totals.hasOwnProperty(day)) {
          totals[day] = (0, _financial.roundAtPrecision)((0, _financial.mult)(totals[day], workUnitRate), 3);
        }
      }
      return totals;
    }),
    activityByDay: Ember.computed('regularTimes.@each.workUnitType', function () {
      var regularTimes = this.get('regularTimes');
      return regularTimes.reduce(function (activities, regularTime) {
        // Absence > Production > Internal
        var sort = [_workunittype.WUT_INTERNAL, _workunittype.WUT_PRODUCTION, _workunittype.WUT_ABSENCE];
        var dayNumber = (0, _moment.default)(regularTime.get('startDate')).format('D');
        var activity = regularTime.get('duration') ? regularTime.get('workUnitType.activityType') : null;
        if (activities[dayNumber]) {
          var i1 = sort.indexOf(activities[dayNumber]);
          var i2 = sort.indexOf(activity);
          activity = sort[Math.max(i1, i2)];
        }
        if (activity) {
          activities[dayNumber] = activity;
        }
        return activities;
      }, {});
    }),
    companyByProjectID: Ember.computed('projects', function () {
      var _this19 = this;
      // create a promise resolved when we get all projects' companies
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var companyByProjectID = {};
        _this19.get('projects').then(function (projects) {
          var allProjectsPromises = projects.map(function (project) {
            var projectID = project.get('id');
            return project.get('company').then(function (company) {
              companyByProjectID[projectID] = company ? company : null;
            });
          });
          Ember.RSVP.all(allProjectsPromises, "companyByProjectID - All ".concat(projects.length, " projects promises")).then(function () {
            resolve(companyByProjectID);
          }, function (reason) {
            reject(reason);
          });
        }, function (reason) {
          reject(reason);
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    sortMapByType: function sortMapByType(mapData) {
      var self = this;
      return new Map(Array.from(mapData.entries()).sort(function (a, b) {
        var aName = a[1].type + ' ' + a[1].name;
        var bName = b[1].type + ' ' + b[1].name;
        return aName.localeCompare(bName, self.get('i18n.locale'), {
          ignorePunctuation: true,
          sensitivity: 'base'
        });
      }));
    },
    totalsByRegularActivity: Ember.computed('regularTimes.@each.{value,workUnitType,project,delivery,batch}', 'workUnitRate', function () {
      var _this20 = this;
      var promise = this.get('companyByProjectID').then(function (companyByProjectID) {
        var regularTotals = _this20.get('regularTimes').reduce(function (totals, regularTime) {
          // ici on force le workunitrate dans le regulartime car dans le cas du dashboard, on ne parcours pas les lignes
          // et donc on ne renseigne pas le workunitrate sur chaque regulartime comme cela est fait sur une fiche
          // timesreport. La computed regularTimesRows construit pour chaque regulartime les données calculées
          // à partir de la fiche et non renvoyées par le back
          regularTime.set('workUnitRate', _this20.get('workUnitRate'));
          var reference = regularTime.get('workUnitType.reference');
          var workUnitType = regularTime.get('workUnitType.name');
          var activityType = regularTime.get('workUnitType.activityType');
          var project = regularTime.get('project');
          var index = project && project.id > 0 ? "".concat(project.id, " ").concat(reference) : reference;
          var duration = parseFloat(regularTime.get('duration'));
          // on arrondi la valeur à une précision 3 telle qu'utilisée sur les templates
          var workUnits = (0, _financial.roundAtPrecision)(regularTime.get('value'), 3);
          var projectName;
          if (project && project.id > 0) {
            if (project.reference === '') {
              projectName = "".concat(_project.default.prefix).concat(project.id);
            } else if (companyByProjectID[project.id]) {
              projectName = "".concat(project.reference, " - ").concat(companyByProjectID[project.id].get('name'));
            } else {
              projectName = "".concat(project.reference);
            }
          } else {
            projectName = '';
          }
          if (!totals[activityType].projects.has(index)) {
            totals[activityType].projects.set(index, {
              days: 0,
              workUnits: 0,
              type: workUnitType,
              name: projectName
            });
          }
          totals.days = (0, _financial.add)(totals.days, duration);
          totals.workUnits = (0, _financial.add)(totals.workUnits, workUnits);
          totals[activityType].days = (0, _financial.add)(totals[activityType].days, duration);
          totals[activityType].workUnits = (0, _financial.add)(totals[activityType].workUnits, workUnits);
          totals[activityType].projects.get(index).days = (0, _financial.add)(totals[activityType].projects.get(index).days, duration);
          totals[activityType].projects.get(index).workUnits = (0, _financial.add)(totals[activityType].projects.get(index).workUnits, workUnits);
          return totals;
        }, {
          days: 0,
          workUnits: 0,
          production: {
            days: 0,
            workUnits: 0,
            projects: new Map()
          },
          absence: {
            days: 0,
            workUnits: 0,
            projects: new Map()
          },
          internal: {
            days: 0,
            workUnits: 0,
            projects: new Map()
          }
        });

        // calcul du workUnits dans un second temps pour minimiser l'erreur de précision
        regularTotals.workUnits = regularTotals.workUnits.toFixed(6);
        regularTotals.days = regularTotals.days.toFixed(6);
        [_workunittype.WUT_PRODUCTION, _workunittype.WUT_ABSENCE, _workunittype.WUT_INTERNAL].forEach(function (activityType) {
          regularTotals[activityType].days = parseFloat(regularTotals[activityType].days.toFixed(6));
          regularTotals[activityType].projects.forEach(function (project) {
            project.days = parseFloat(project.days.toFixed(6));
          });
        });
        regularTotals.production.projects = _this20.sortMapByType(regularTotals.production.projects);
        regularTotals.absence.projects = _this20.sortMapByType(regularTotals.absence.projects);
        regularTotals.internal.projects = _this20.sortMapByType(regularTotals.internal.projects);
        return Ember.assign({}, regularTotals);
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    totalsByExceptionalActivity: Ember.computed('exceptionalTimes.@each.{duration,selectedActivity,selectedWorkUnitType}', function () {
      var _this21 = this;
      var promise = this.get('companyByProjectID').then(function (companyByProjectID) {
        var exceptionalTotals = _this21.get('exceptionalTimes').reduce(function (totals, exceptionalTime) {
          var reference = exceptionalTime.get('workUnitType.reference');
          var workUnitType = exceptionalTime.get('workUnitType.name');
          var activityType = exceptionalTime.get('workUnitType.activityType');
          var project = exceptionalTime.get('project');
          var index = project && project.id > 0 ? "".concat(project.id, " ").concat(reference) : reference;
          var duration = exceptionalTime.get('duration');
          var durationType = exceptionalTime.get('isExceptionalTime') ? 'hours' : 'days';
          var projectName;
          if (project && project.id > 0) {
            if (project.reference === '') {
              projectName = "".concat(_project.default.prefix).concat(project.id);
            } else if (companyByProjectID[project.id]) {
              projectName = "".concat(project.reference, " - ").concat(companyByProjectID[project.id].get('name'));
            } else {
              projectName = "".concat(project.reference);
            }
          } else {
            projectName = '';
          }
          if (!totals[activityType].projects.has(index)) {
            if (durationType === 'hours') {
              totals[activityType].projects.set(index, {
                hours: _moment.default.duration(0),
                type: workUnitType,
                name: projectName
              });
            } else {
              totals[activityType].projects.set(index, {
                days: _moment.default.duration(0),
                type: workUnitType,
                name: projectName
              });
            }
          }
          if (durationType === 'hours') {
            totals[activityType].hours.add(duration);
            totals[activityType].projects.get(index).hours.add(duration);
          } else {
            totals[activityType].days.add(duration);
            totals[activityType].projects.get(index).days.add(duration);
          }
          return totals;
        }, {
          exceptionalTime: {
            hours: _moment.default.duration(0),
            projects: new Map()
          },
          exceptionalCalendar: {
            days: _moment.default.duration(0),
            projects: new Map()
          }
        });
        exceptionalTotals.exceptionalTime.projects = _this21.sortMapByType(exceptionalTotals.exceptionalTime.projects);
        exceptionalTotals.exceptionalCalendar.projects = _this21.sortMapByType(exceptionalTotals.exceptionalCalendar.projects);
        return Ember.assign({}, exceptionalTotals);
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    totalsWorkplacesTimes: Ember.computed('workplaceTimes.@each.value', function () {
      var workplaceTotals = this.get('workplaceTimes').reduce(function (totals, workplaceTime) {
        var duration = parseFloat(workplaceTime.get('duration'));
        // on arrondi la valeur à une précision 3 telle qu'utilisée sur les templates
        var workUnits = (0, _financial.roundAtPrecision)(workplaceTime.get('value'), 3);
        totals.days = (0, _financial.add)(totals.days, duration);
        totals.workUnits = (0, _financial.add)(totals.workUnits, workUnits);
        return totals;
      }, {
        days: 0,
        workUnits: 0
      });
      return Ember.assign({}, workplaceTotals);
    }).readOnly(),
    labelWithInternalReference: Ember.computed('state', 'label', function () {
      return this.get('label') + ' - ' + this.get('internalReference');
    }).readOnly(),
    label: Ember.computed('state', 'term', 'i18n._locale', function () {
      var date = (0, _moment.default)(this.get('term')).format('MMMM YYYY');
      return date.charAt(0).toUpperCase() + date.slice(1);
    }).readOnly(),
    sortRegularTimes: function sortRegularTimes() {
      // before , we have to reorder regularTimes with the same order as
      // backend return it after saved model
      var regularTimes = this.get('regularTimes');
      if (regularTimes.get('length')) {
        // can't use sortBy because we need to put row < 0 at the end in reverse order :)
        // const sortedRegularTimes = regularTimes.sortBy('row', 'startDate');
        //
        var sortedRegularTimes = regularTimes.toArray().sort(function (a, b) {
          var rowA = a.get('row');
          var rowB = b.get('row');
          var diff = rowA - rowB;
          if (diff === 0) {
            var startDateA = a.get('startDate');
            var startDateB = b.get('startDate');
            return startDateA - startDateB;
          } else {
            if (rowA < 0) {
              return rowB < 0 ? rowB - rowA : 1;
            } else {
              return rowB < 0 ? -1 : diff;
            }
          }
        });
        this.set('regularTimes', sortedRegularTimes);
      }
    },
    sortWorkplaceTimes: function sortWorkplaceTimes() {
      // before , we have to reorder workplaceTimes with the same order as
      // backend return it after saved model
      var workplaceTimes = this.get('workplaceTimes');
      if (workplaceTimes.get('length')) {
        // can't use sortBy because we need to put row < 0 at the end in reverse order :)
        // const sortedRegularTimes = regularTimes.sortBy('row', 'startDate');
        //
        var sortedWorkplaceTimes = workplaceTimes.toArray().sort(function (a, b) {
          var rowA = a.get('row');
          var rowB = b.get('row');
          var diff = rowA - rowB;
          if (diff === 0) {
            var startDateA = a.get('startDate');
            var startDateB = b.get('startDate');
            return startDateA - startDateB;
          } else {
            if (rowA < 0) {
              return rowB < 0 ? rowB - rowA : 1;
            } else {
              return rowB < 0 ? -1 : diff;
            }
          }
        });
        this.set('workplaceTimes', sortedWorkplaceTimes);
      }
    }
  });
  TimesReport.reopenClass({
    prefix: INTERNAL_REFERENCE_CRA
  });
  TimesReport.reopen({
    prefix: Ember.computed(function () {
      return TimesReport.prefix;
    }).readOnly()
  });
  var _default = _exports.default = TimesReport;
});