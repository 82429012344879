define("boondmanager/models/subscription", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Subscription = _base.default.extend({
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    pricingType: _emberData.default.attr('string'),
    automaticSubscription: _emberData.default.attr('boolean'),
    numberOfAllowedResourcesPerManager: _emberData.default.attr('number'),
    numberOfAllowedManagers: _emberData.default.attr('number'),
    numberOfAllowedResources: _emberData.default.attr('number'),
    numberOfAllowedAccounts: _emberData.default.attr('number'),
    numberOfActivatedManagers: _emberData.default.attr('number'),
    numberOfActivatedResources: _emberData.default.attr('number'),
    numberOfActivatedAccounts: _emberData.default.attr('number'),
    numberOfEsignatureAllowed: _emberData.default.attr('number'),
    numberOfEsignatureUsed: _emberData.default.attr('number'),
    priceExcludingTaxPerAllowedManager: _emberData.default.attr('number'),
    priceExcludingTaxPerAllowedResource: _emberData.default.attr('number'),
    priceExcludingTaxPerAllowedAccount: _emberData.default.attr('number'),
    priceRateForAppsNoCode: _emberData.default.attr('number'),
    priceRateForAdvancedBi: _emberData.default.attr('number'),
    schedulesAllowedAccounts: (0, _attributes.fragmentArray)('scheduleallowedaccounts'),
    regulates: (0, _attributes.fragmentArray)('subscriptionregulates'),
    totalAmountExcludingTax: Ember.computed('pricingType', 'numberOfAllowedManagers', 'priceExcludingTaxPerAllowedManager', 'numberOfAllowedResources', 'priceExcludingTaxPerAllowedResource', 'numberOfAllowedAccounts', 'priceExcludingTaxPerAllowedAccount', 'priceRateForAppsNoCode', 'priceRateForAdvancedBi', 'modules.{appsNoCode,advancedBi}', 'regulates.@each.amountExcludingTax', function () {
      var total = 0;
      var totalAppsNoCode = 0;
      var totalAdvancedBi = 0;
      switch (this.get('pricingType')) {
        case 'custom':
          total = this.get('numberOfAllowedManagers') * this.get('priceExcludingTaxPerAllowedManager') + this.get('numberOfAllowedResources') * this.get('priceExcludingTaxPerAllowedResource');
          break;
        case 'manager':
          total = this.get('numberOfAllowedManagers') * this.get('priceExcludingTaxPerAllowedManager');
          break;
        case 'account':
          total = this.get('numberOfAllowedAccounts') * this.get('priceExcludingTaxPerAllowedAccount');
          break;
        case 'scheduleAccount':
          total = this.get('numberOfAllowedAccounts') * this.get('priceExcludingTaxPerAllowedAccount');
          break;
      }
      this.get('regulates').forEach(function (r) {
        total += r.amountExcludingTax;
      });
      var priceRateForAppsNoCode = this.get('priceRateForAppsNoCode');
      if (priceRateForAppsNoCode) {
        totalAppsNoCode = total * priceRateForAppsNoCode / 100;
      }
      var priceRateForAdvancedBi = this.get('priceRateForAdvancedBi');
      if (priceRateForAdvancedBi) {
        totalAdvancedBi = total * priceRateForAdvancedBi / 100;
      }
      return total + totalAppsNoCode + totalAdvancedBi;
    }).readOnly(),
    numberOfEsignatureRemaining: Ember.computed('numberOfEsignatureAllowed', 'numberOfEsignatureUsed', function () {
      return this.get('numberOfEsignatureAllowed') - this.get('numberOfEsignatureUsed');
    }).readOnly()
  });
  var _default = _exports.default = Subscription;
});