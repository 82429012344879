define("boondmanager/models/appemailinginvoice", ["exports", "ember-data", "boondmanager/models/invoice", "boondmanager/utils/file"], function (_exports, _emberData, _invoice, _file) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsEMailingInvoice = _invoice.default.extend({
    from: _emberData.default.attr('string'),
    cc: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    bcc: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    hasAttachment: _emberData.default.attr('boolean'),
    allowedStates: _emberData.default.attr(''),
    files: _emberData.default.hasMany('document'),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'invoice',
    canReadRecord: Ember.computed.alias('canReadInvoice').readOnly(),
    emailsOptions: Ember.computed('order.billingDetail.emails.[]', function () {
      var options = [];
      var emails = [];
      var distinct = function distinct(value, index, self) {
        return self.indexOf(value) === index && value;
      };
      if (this.get('order') && this.get('order.billingDetail') && this.get('order.billingDetail.emails')) {
        emails = this.get('order.billingDetail.emails');
      }
      var distinctEmails = emails.filter(distinct);
      distinctEmails.forEach(function (email) {
        options.push({
          id: email,
          value: email
        });
      });
      return options;
    }),
    recordRouteName: Ember.computed('', function () {
      return 'private.invoices.invoice.information';
    }).readOnly(),
    totalFilesSize: Ember.computed('files', function () {
      var filesSize = this.get('files').reduce(function (size, file) {
        return size + parseInt(file.size);
      }, 0);
      return filesSize / _file.UNIT_CONVERTER / _file.UNIT_CONVERTER;
    })
  });
  AppsEMailingInvoice.reopenClass({
    prefix: 'FACT'
  });
  AppsEMailingInvoice.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingInvoice.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingInvoice;
});