define("boondmanager/models/apporganizationcharts-node", ["exports", "ember-data", "boondmanager/models/apporganizationcharts-basenode"], function (_exports, _emberData, _apporganizationchartsBasenode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _apporganizationchartsBasenode.default.extend({
    orgchart: _emberData.default.belongsTo('apporganizationchartsOrgchart'),
    // should be company (root) or contact node
    parent: _emberData.default.belongsTo('apporganizationchartsBasenode', {
      inverse: 'children'
    })
  });
});