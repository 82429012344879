define("boondmanager/routes/activity", ["exports", "boondmanager/routes/entity"], function (_exports, _entity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Routeur parent des fiches concernant l'activité (absence, fiche de temps, note de frais)
   *
   * @class ActivityRoute
   * @extends Ember.Route
   * @namespace Ember
   * @module Routes
   */
  var _default = _exports.default = _entity.default.extend({
    setupController: function setupController(controller /*, model */) {
      this._super.apply(this, arguments);
      controller.set('showValidationForm', false);
      controller.set('showDevalidationForm', false);
      controller.set('showRejectionForm', false);
      controller.set('showUnableToValidateModal', false);
      controller.set('showInvoiceForm', false);
    }
  });
});