define("boondmanager/pods/components/bm-field/availability/candidate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dzKgEtUt",
    "block": "{\"symbols\":[\"@name\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[15,0,[31,[\"col-12 \",[30,[36,7],[[30,[36,6],[[35,5],\"date\"],null],\"col-xl-6\"],null]]]],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,11],null,[[\"name\",\"options\",\"selected\",\"defaultKey\",\"disabled\",\"onchange\"],[[30,[36,0],[[35,10],\"-type\"],null],[30,[36,9],[[35,8]],[[\"selected\"],[[35,5]]]],[35,5],-1,[35,3],[30,[36,4],[[32,0],\"updateSelectedType\"],null]]]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5],\"date\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"col-12 col-xl-6\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/date\",[],[[\"@id\",\"@name\",\"@value\",\"@format\",\"@disabled\",\"@onChange\"],[[32,1],[30,[36,0],[[32,1],\"-date\"],null],[34,1,[\"date\"]],[34,2],[34,3],[30,[36,4],[[32,0],\"updateSelectedDate\"],null]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"availability\",\"displayedDateFormat\",\"disabled\",\"action\",\"selectedType\",\"eq\",\"if\",\"availabilityTypes\",\"options-for\",\"name\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/availability/candidate/template.hbs"
  });
});