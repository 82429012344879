define("boondmanager/models/billingdetail", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/utils/bm"], function (_exports, _emberData, _emberDataModelFragments, _bm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    settings: Ember.inject.service(),
    originalID: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    address3: _emberData.default.attr('string'),
    contact: _emberData.default.attr('string'),
    subDivision: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    emails: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    cc: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    bcc: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    name: _emberData.default.attr('string'),
    vatNumber: _emberData.default.attr('string'),
    registrationNumber: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    sendingMode: _emberData.default.attr('number'),
    sendingModeParams: _emberData.default.attr(),
    phone1: _emberData.default.attr('string'),
    postcode: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    town: _emberData.default.attr('string'),
    isUsed: _emberData.default.attr('boolean'),
    canEdit: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean'),
    sendingModeParamsValue: Ember.computed('sendingModeParams', 'sendingModeCode', {
      get: function get() {
        switch (this.get('sendingModeCode')) {
          case _bm.INVOICE_SENDING_MODE_CHORUS_PRO:
          case _bm.INVOICE_SENDING_MODE_PEPPOL:
            return this.get('sendingModeParams.value');
        }
        return '';
      },
      set: function set(_, value) {
        switch (this.get('sendingModeCode')) {
          case _bm.INVOICE_SENDING_MODE_CHORUS_PRO:
          case _bm.INVOICE_SENDING_MODE_PEPPOL:
            this.set('sendingModeParams', {
              value: value
            });
            break;
          default:
            this.set('sendingModeParams', null);
        }
        return value;
      }
    }),
    sendingModeCode: Ember.computed('sendingMode', function () {
      var currentSendingMode = this.get('settings').customer.sendingMode.findBy('id', this.get('sendingMode'));
      if (currentSendingMode) {
        return currentSendingMode.code;
      }
      return false;
    }).readOnly(),
    inSelectEmails: Ember.computed('emails', function () {
      if (this.get('emails')) {
        return this.get('emails').slice(0, 3);
      }
      return [];
    })
  });
});