define("boondmanager/pods/components/bm-field/period/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/logger", "moment", "jquery", "boondmanager/utils/cachecomputed"], function (_exports, _component, _logger, _moment, _jquery, _cachecomputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component BM Field Period.
   *
   *   * depends of [Datepicker for Bootstrap v1.4.0](https://github.com/eternicode/bootstrap-datepicker)
   *
   * Display a field composed of 4 parts:
   *
   *   * 1st part is a dropdown where you can select the period type like 'indifferent',
   *     'Update date', 'Availability', etc. This list is dependant of the module (Resources,
   *     'Candidates', etc)
   *   * 2nd part is a field composed of 2 inputs to enter a date range. This part is only
   *     visible if period type is not 'indifferent'
   *   * 3rd part is a dropdown with predefined date range types like this week, this month, etc
   *   * 4th last part is an optional dynamic period value . Like this week, this fiscal year, ect
   *
   * Component attributes:
   *
   *   * {string}             name            REQUIRED field name
   *   * {Array.<OptionHash>} options         REQUIRED period types list
   *   * {string}             selected        OPTIONAL selected period type ID
   *   * {Moment}             startDate       OPTIONAL period' start date
   *   * {Moment}             endDate         OPTIONAL period's end date
   *   * {Array.<string>}     rangeImmediate  OPTIONAL list of period type IDs where range 'immediate' is available
   *   * {string}             class           OPTIONAL additional classes applied to the main DOM element
   *   * {string}             size            OPTIONAL component Bootstrap size
   *   * {function}           onChange        OPTIONAL callback call on period change
   *
   * Example:
   * ```handlebars
   * {{bm-field/period
   *     name="period"
   *     class="col-12 col-md-10"
   *
   *     size=size
   *
   *     options=getPeriods
   *     rangeImmediate=getRangeImmediate
   *
   *     selected=period
   *     startDate=startDate
   *     endDate=endDate
   *
   *     onChange=(action 'updatePeriodFilter')
   * }}
   *
   * @class  BmFieldPeriodComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * Component default __class__ attribute
     *
     * @property classNames
     * @type {Array}
     * @default  ['bm-field-period']
     */
    classNames: ['bmc-field-period'],
    /**
     * The size of the element. Available options: 'lg' and 'sm'.
     * @type {String}
     * @default ''
     */
    size: '',
    /**
     * Debug calendar or not
     *
     * @property debug
     * @type boolean
     * @default false
     */
    debug: false,
    /**
     * View mode
     *
     * @property format
     * @type string
     * @default 'days'
     */
    viewMode: 'days',
    /**
     * Keep options order
     *
     * @property keepOptionsOrder
     * @type boolean
     * @default false
     */
    keepOptionsOrder: false,
    /**
     * Defaut format (http://momentjs.com/docs/#/displaying/format/)
     *
     * @property format
     * @type string
     * @default 'DD/MM/YY'
     */
    format: 'DD/MM/YY',
    /**
     * Allow clear value
     *
     * @property allowClear
     * @type boolean
     * @default true
     */
    allowClear: true,
    /**
     * Start date name
     *
     * This name is used in start date <input>
     *
     * @property startDateName
     * @type {string}
     * @default `${this.get('name')}-startDate`
     */
    startDateName: Ember.computed('name', function () {
      return "".concat(this.get('name'), "-startDate");
    }).readOnly(),
    /**
     * End date name
     *
     * This name is used in end date <input>
     *
     * @property endDateName
     * @type {string}
     * @default `${this.get('name')}-endDate`
     */
    endDateName: Ember.computed('name', function () {
      return "".concat(this.get('name'), "-endDate");
    }).readOnly(),
    /**
     * Dropdown name of range field
     *
     * This name is used in the <button>
     *
     * @property rangeName
     * @type {string}
     * @default `${this.get('name')}-range`
     */
    rangeName: Ember.computed('name', function () {
      return "".concat(this.get('name'), "-range");
    }).readOnly(),
    /**************************************************************************/
    /* JSDoc - typedef ********************************************************/
    /**************************************************************************/
    /**
     * Datalist type from dicts or return in model.meta
     *
     * @typedef IdValueHash
     * @type {Object}
     * @property {string} id
     * @property {(string|Object)} value
     */

    /**
     * <option> hash
     *
     * @typedef OptionHash
     * @type {Object}
     * @property {string} id
     * @property {string} value
     * @property {string} [icon]
     * @property {boolean} [disabled]
     * @property {boolean} [selected]
     * @property {Array.<OptionHash>} [option]
     */
    /**************************************************************************/
    /* /JSDoc - typedef *******************************************************/
    /**************************************************************************/

    indifferentPeriodTypeSelected: Ember.computed('selectedPeriodType', function () {
      var selectedPeriodTypeID = this.get('selectedPeriodType.id');
      return typeof selectedPeriodTypeID === 'undefined';
    }),
    /**
     * Period types list
     *
     * @property periodTypes
     * @type {OptionHash}
     */
    periodTypes: Ember.computed('options', function () {
      var options = this.get('options') || [];

      // we use slice() (= shallow copy) because if not, we update 'options' array!!!
      var periodTypes = options.slice();
      if (!this.get('keepOptionsOrder')) {
        var self = this;
        periodTypes.sort(function (a, b) {
          return "".concat(a.value).localeCompare(b.value, self.get('i18n.locale'), {
            ignorePunctuation: true,
            sensitivity: 'base'
          });
        });
      }

      // convert all ids to string !
      periodTypes.forEach(function (period) {
        Ember.set(period, 'id', '' + period.id);
      });
      return periodTypes;
    }),
    /**
     * Range types list
     *
     * Read from 'components:filterPeriod.range' in dictionary
     *
     * @property rangeTypes
     * @type {IdValueHash}
     * @default {id: '-1', value: ''}
     */
    rangeTypes: Ember.computed('selectedPeriodType', 'rangeImmediate', 'i18n._locale', function () {
      var ranges = [];
      var selectedPeriodTypeID = this.get('selectedPeriodType.id');
      var i18nRanges = this.get('settings').bm.component.filterPeriod.range;
      var rangeImmediate = this.get('rangeImmediate');

      // add 'Immediate' range if we have selected a period present in the 'rangeImmediate' array
      if (typeof selectedPeriodTypeID !== 'undefined' && Ember.isArray(rangeImmediate) && rangeImmediate.length && !Ember.isEmpty(this.get('rangeImmediate').indexOf(selectedPeriodTypeID))) {
        ranges.push(i18nRanges.immediate);
      }
      ranges.push(i18nRanges.thisCustomPeriod);
      ranges.push(i18nRanges.thisWeek);
      ranges.push(i18nRanges.thisMonth);
      ranges.push(i18nRanges.thisTrimester);
      ranges.push(i18nRanges.thisSemester);
      ranges.push(i18nRanges.thisYear);
      return ranges;
    }).readOnly(),
    /**
     * Get Period Type object from its ID
     *
     * @method _getSelectedPeriodTypeById
     * @params {Array.<OptionHash>} options
     * @params {string} id
     * @return {OptionHash}
     * @private
     */
    _getSelectedPeriodTypeById: function _getSelectedPeriodTypeById(options, id) {
      var periodType;
      if (typeof options !== 'undefined' && typeof id !== 'undefined') {
        options.every(function (opt) {
          //Ember.logger.debug(` ${id} (${typeof id}) === ${opt.id} (${typeof opt.id})`);

          if (opt.option && opt.option.length) {
            var subFind = opt.option.find(function (subopt) {
              return subopt.id === id;
            });
            if (typeof subFind !== 'undefined') {
              periodType = subFind;
              return false;
            }
          }
          if (opt.id === id) {
            periodType = opt;
            return false;
          }
          return true;
        });
      }
      return periodType;
    },
    /**
     * Selected Period type
     *
     * @property selectedPeriodType
     * @type {IdValueHash}
     * @default indifferentPeriodType
     */
    selectedPeriodType: Ember.computed('options', 'selected', 'i18n._locale', {
      get: function get() {
        var selectedPeriodTypeID = this.get('selected');
        var selectedPeriodType = null;
        if (typeof selectedPeriodTypeID !== 'undefined' && !Ember.isEmpty(selectedPeriodTypeID)) {
          var foundPeriodType = this._getSelectedPeriodTypeById(this.get('options'), selectedPeriodTypeID);
          if (typeof foundPeriodType !== 'undefined') {
            selectedPeriodType = foundPeriodType;
          }
        }
        return selectedPeriodType;
      } /*key*/,
      set: function set(key, value) {
        var selectedPeriodType = this._getSelectedPeriodTypeById(this.get('options'), value);

        // DDAU
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          var selectedDate = this.get('selectedDate');
          var onChangeValues = {
            period: value,
            startDate: '',
            endDate: ''
          };
          if (typeof selectedDate !== 'undefined' && typeof selectedPeriodType !== 'undefined') {
            onChangeValues.startDate = selectedDate.start.format('YYYY-MM-DD');
            onChangeValues.endDate = selectedDate.end.format('YYYY-MM-DD');
          }
          onChange(onChangeValues);
        }
        return selectedPeriodType;
      }
    }),
    /**
     * Selected Range
     *
     * @property selectedRange
     * @type {IdValueHash}
     * @default {id: '-1', value: ''}
     */
    selectedRange: Ember.computed('rangeTypes', 'startDate', 'endDate', {
      get: function get() {
        var _this = this;
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        var selectedRangeName = Object.keys(this.range).find(function (rangeName) {
          var range = _this.range[rangeName]();
          return range ? range.start.isSame(startDate, 'day') && range.end.isSame(endDate, 'day') : false;
        });
        var RangeID = selectedRangeName ? this.get('settings').bm.component.filterPeriod.range[selectedRangeName].id : this.get('settings').bm.component.filterPeriod.range.thisCustomPeriod.id;
        return Ember.A(this.get('rangeTypes')).findBy('id', RangeID);
      } /*key*/,
      set: function set(key, value) {
        if (value !== this.get('settings').bm.component.filterPeriod.range.toDefine.id) {
          var rangeTypesObj = this.get('settings').bm.component.filterPeriod.range;
          var selectedDate;
          for (var rangeName in rangeTypesObj) {
            if (rangeTypesObj.hasOwnProperty(rangeName) && rangeTypesObj[rangeName].id === value) {
              selectedDate = this.range[rangeName]();
              this.set('selectedDate', selectedDate);
              break;
            }
          }

          // DDAU
          var onChange = this.get('onChange');
          if (typeof onChange === 'function') {
            if (selectedDate) {
              onChange({
                period: this.get('selectedPeriodType.id'),
                startDate: selectedDate.start.format('YYYY-MM-DD'),
                endDate: selectedDate.end.format('YYYY-MM-DD')
              });
            } else {
              onChange({
                period: this.get('selectedPeriodType.id')
              });
            }
          }
        }
        return Ember.A(this.get('rangeTypes')).findBy('id', value);
      }
    }),
    /**************************************************************************/
    /* JSDoc - typedef ********************************************************/
    /**************************************************************************/
    /**
     * Period object with start and end dates
     *
     * @typedef periodHash
     * @type {Object}
     * @property {Moment} start
     * @property {Moment} end
     */
    /**************************************************************************/
    /* /JSDoc - typedef *******************************************************/
    /**************************************************************************/
    initSelectedDate: Ember.computed('selectedPeriodType', 'selectedDate', function () {
      var _this2 = this;
      var selectedDate = {
        start: (0, _moment.default)(),
        end: (0, _moment.default)()
      };
      ['start', 'end'].forEach(function (dateType) {
        var d = _this2.get("".concat(dateType, "Date"));
        if (d) {
          if (_moment.default.isMoment(d)) {
            // on s'assure de travailler avec un clone et non l'objet moment lui-même
            // au cas-où on modifierait quoique ce soit...
            selectedDate[dateType] = d.clone();
          } else {
            var momentDate = (0, _moment.default)(d);
            if (momentDate.isValid()) {
              selectedDate[dateType] = momentDate;
            } else {
              var invalidAt = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
              _logger.default.warn("Invalid ".concat(dateType, " date at ").concat(invalidAt[momentDate.invalidAt()]));
            }
          }
        }
      });
      return selectedDate;
    }).readOnly(),
    /**
     * Selected Date
     *
     * @property selectedDate
     * @type {periodHash}
     * @default {start: moment(), end: moment()}
     */
    selectedDate: Ember.computed('startDate', 'endDate', 'selectedRange', {
      get: function get() {
        var _this3 = this;
        var selectedDate = {
          start: (0, _moment.default)(),
          end: (0, _moment.default)()
        };
        ['start', 'end'].forEach(function (dateType) {
          var d = _this3.get("".concat(dateType, "Date"));
          if (d) {
            if (_moment.default.isMoment(d)) {
              // on s'assure de travailler avec un clone et non l'objet moment lui-même
              // au cas-où on modifierait quoique ce soit...
              selectedDate[dateType] = d.clone();
            } else {
              var momentDate = (0, _moment.default)(d);
              if (momentDate.isValid()) {
                selectedDate[dateType] = momentDate;
              } else {
                var invalidAt = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
                _logger.default.warn("Invalid ".concat(dateType, " date at ").concat(invalidAt[momentDate.invalidAt()]));
              }
            }
          }
        });
        return selectedDate;
      } /*key*/,
      set: function set(key, value, oldValue) {
        var selectedDate = oldValue ? oldValue : {
          start: (0, _moment.default)(),
          end: (0, _moment.default)()
        };
        var changed = false;
        ['start', 'end'].forEach(function (dateType) {
          if (value && value[dateType]) {
            if (_moment.default.isMoment(value[dateType])) {
              Ember.set(selectedDate, dateType, value[dateType].clone());
              changed = true;
            } else {
              var momentDate = (0, _moment.default)(value[dateType]);
              if (momentDate.isValid()) {
                selectedDate[dateType] = momentDate;
                changed = true;
              } else {
                var invalidAt = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
                _logger.default.warn("Invalid ".concat(dateType, " date at ").concat(invalidAt[momentDate.invalidAt()]));
              }
            }
          }
        });

        // DDAU
        var onChange = this.get('onChange');
        if (changed && typeof onChange === 'function') {
          onChange({
            period: this.get('selectedPeriodType.id'),
            startDate: selectedDate.start.format('YYYY-MM-DD'),
            endDate: selectedDate.end.format('YYYY-MM-DD')
          });
        }
        return selectedDate;
      }
    }),
    /**
     * Calendar <input>s display status
     *
     * @property showCalendars
     * @type {boolean}
     * @default false
     */
    showCalendars: Ember.computed('selectedPeriodType', function () {
      return !Ember.isEmpty(this.get('selectedPeriodType'));
    }),
    /**
     * Default range values
     *
     * (defined in init())
     *
     *  @property range
     * @type {object}
     * @default true
     */
    /**
     * Periods list where "immediate" range is available
     *
     * (defined in init())
     *
     * @property rangeImmediate
     * @type {Array}
     * @default  []
     */
    init: function init() {
      this._super.apply(this, arguments);
      this.range = {
        immediate: function immediate() {
          return {
            start: (0, _moment.default)().subtract(15, 'd'),
            end: (0, _moment.default)().add(15, 'd')
          };
        },
        thisCustomPeriod: function thisCustomPeriod() {
          return null;
        },
        thisWeek: function thisWeek() {
          if ((0, _moment.default)().day() === 0) {
            // cas particulier du dimanche qui doit appartenir à la semaine derniere et pas à cette semaine (semaine moment, va de 0 = dimanche à 6 = samedi)
            return {
              start: (0, _moment.default)().day(-6),
              end: (0, _moment.default)().day(0)
            };
          } else {
            return {
              start: (0, _moment.default)().day(1),
              end: (0, _moment.default)().day(7)
            };
          }
        },
        thisMonth: function thisMonth() {
          var start = (0, _moment.default)().date(1);
          return {
            start: start,
            end: (0, _moment.default)().date(start.daysInMonth())
          };
        },
        thisTrimester: function thisTrimester() {
          return {
            start: (0, _moment.default)().startOf('quarter'),
            end: (0, _moment.default)().endOf('quarter')
          };
        },
        thisSemester: function thisSemester() {
          var now = (0, _moment.default)();
          var start = now.set({
            date: 1,
            month: now.month() > 5 ? 6 : 0
          });
          var end = start.clone().add(5, 'M');
          return {
            start: start,
            end: end.date(end.daysInMonth())
          };
        },
        thisYear: function thisYear() {
          return {
            start: (0, _moment.default)().dayOfYear(1),
            end: (0, _moment.default)().dayOfYear(0).add(1, 'y')
          };
        }
      };
      this.rangeImmediate = [];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      var options = this.get('options');
      this._super.apply(this, arguments);

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert("bm-field/period must have a \"name\" attribute!", !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert("bm-field/period \"name\" attribute must be a string!", Ember.typeOf(name) === 'string')); // check options
      (false && !(Ember.isArray(options)) && Ember.assert("bm-field/period \"options\" attribute must be an array!", Ember.isArray(options)));
    },
    /**
     * Add range classes on calendar view
     * @param type
     * @private
     */
    _addRangeClasses: function _addRangeClasses(type) {
      var datetimepicker = this.$(".".concat(type, "date")).find('div.bootstrap-datetimepicker-widget');
      if (datetimepicker.length) {
        var from = this.get('selectedDate.start');
        var to = this.get('selectedDate.end');
        var format = this.get('format');
        datetimepicker.find('div.datepicker-days').find('td.day').each(function () {
          // reset active class to avoid range class on inactive dates
          (0, _jquery.default)(this).removeClass('range');
          var date = new _moment.default((0, _jquery.default)(this).data('day'), format.replace('YY', 'YYYY'));
          if (date.isSame(from) || date.isSame(to) || date.isBetween(from, to)) {
            (0, _jquery.default)(this).addClass('range');
          }
        });
      }
    },
    hasValue: (0, _cachecomputed.default)('selected', function () {
      return !this.isBlankValue();
    }),
    isBlankValue: function isBlankValue() {
      return typeof this.get('selected') === 'undefined' || this.get('selected') === null || this.get('selected') === 'null';
    },
    /**
     * Component actions list
     *
     * @property actions
     * @type {Object}
     */
    actions: {
      /**
       * Period type change event
       *
       * @event  changePeriodType
       * @param {string|number} value PeriodType ID
       */
      changePeriodType: function changePeriodType(value) {
        //value MUST BE a string. Sometimes an integer is given (converted by
        //jQuery from <option> value?) so we convert it to a string.
        //Logger.debug(`changePeriodType ${value} (${typeof value}) => ${''+value} (${typeof (''+value)})`);
        this.set('selectedPeriodType', Ember.isEmpty(value) ? null : value.toString());
      },
      /**
       * Range type change even
       *
       * @event  changeRangeType
       * @param {number} value rangeType ID
       */
      changeRangeType: function changeRangeType(value) {
        this.set('selectedRange', value);
      },
      /**
       * Date change event
       *
       * @event  changeDate
       * @param {string} dateType ie. 'startDate' or 'endDate'
       * @param {Moment} value
       */
      changeDate: function changeDate(dateType, value) {
        var selectedDate = {};
        selectedDate[dateType] = value;
        this.set('selectedDate', selectedDate);
      },
      onDpUpdate: function onDpUpdate(type, e) {
        // only display range on days view mode and when user change month (on days view)
        if (this.get('viewMode') === 'days' && e.change === 'M') {
          this._addRangeClasses(type);
        }
      },
      onDpShow: function onDpShow(type /*, e*/) {
        // only display range on days view mode
        if (this.get('viewMode') === 'days') {
          this._addRangeClasses(type);
        }
      }
    }
  });
});