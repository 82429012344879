define("boondmanager/models/appadvancedprojectsproject", ["exports", "ember-data", "boondmanager/models/project"], function (_exports, _emberData, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppAdvancedProjectsProject = _project.default.extend({
    currentTerm: _emberData.default.attr('date'),
    automaticAgent: _emberData.default.attr('boolean'),
    calculationMode: _emberData.default.attr('string'),
    remainsToBeDoneMode: _emberData.default.attr('string'),
    turnoverProductionRateMode: _emberData.default.attr('string'),
    productionDetailsDisplay: _emberData.default.attr('string'),
    productionDetails: _emberData.default.attr(),
    groupments: _emberData.default.attr(),
    canShowTurnoverProductionExcludingTax: _emberData.default.attr('boolean'),
    canShowTurnoverInvoicedExcludingTax: _emberData.default.attr('boolean'),
    canShowBillingOutstanding: _emberData.default.attr('boolean'),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'project'
  });
  var _default = _exports.default = AppAdvancedProjectsProject;
});