define("boondmanager/models/appemailingtimesreport", ["exports", "ember-data", "boondmanager/models/timesreport"], function (_exports, _emberData, _timesreport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsEMailingTimesReport = _timesreport.default.extend({
    canReadActivity: _emberData.default.attr('boolean'),
    validator: _emberData.default.belongsTo('resource'),
    canReadRecord: Ember.computed.alias('canReadActivity').readOnly(),
    recordRouteName: Ember.computed('', function () {
      return 'private.timesreports.timesreport';
    }).readOnly(),
    emailsOptions: Ember.computed('validator.emailsOptions', function () {
      var options = [];
      var emails = [];
      var distinct = function distinct(value, index, self) {
        return self.indexOf(value) === index && value;
      };
      if (this.get('validator')) {
        emails.push(this.get('validator.email1'));
        emails.push(this.get('validator.email2'));
        emails.push(this.get('validator.email3'));
      }
      var distinctEmails = emails.filter(distinct);
      distinctEmails.forEach(function (email) {
        options.push({
          id: email,
          value: email
        });
      });
      return options;
    }).readOnly()
  });
  AppsEMailingTimesReport.reopenClass({
    prefix: 'CRA'
  });
  AppsEMailingTimesReport.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingTimesReport.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingTimesReport;
});