define("boondmanager/models/savedsearch", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    /*
     * @type {String}
     */
    module: _emberData.default.attr('string'),
    // TAB_USER_SEARCH.USER_SEARCH_MODULE
    /*
     * @type {String}
     */
    name: _emberData.default.attr('string'),
    // TAB_USER_SEARCH.USER_SEARCH_NAME
    /*
     * @type {Object}
     */
    params: _emberData.default.attr(),
    // TAB_USER_SEARCH.USER_SEARCH_PARAMS
    /*
     * @type {Object}
     */
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    // TAB_USER_SEARCH.USER_SEARCH_ID_PROFIL
    /*
     * @type {Object}
     */
    share: (0, _attributes.fragment)('sharedentityfragment'),
    /*
     * @type {Date}
     */
    creationDate: _emberData.default.attr('moment'),
    // TAB_USER_SEARCH.USER_SEARCH_DATE
    /*
     * @type {Date}
     */
    updateDate: _emberData.default.attr('moment'),
    // TAB_USER_SEARCH.USER_SEARCH_DATEUPDATE

    entityName: Ember.computed.alias('name').readOnly()
  });
});