define("boondmanager/models/indicatorfragment", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    endDate: _emberData.default.attr('date'),
    scorecard: (0, _attributes.fragment)('scorecard'),
    startDate: _emberData.default.attr('date'),
    value: _emberData.default.attr('string'),
    daysValues: Ember.computed('value', function () {
      return this.get('value') ? this.get('value').split('|') : [];
    }),
    preparedValue: Ember.computed('value', function () {
      if (this.get('scorecard.typeOf') === 'day' || this.get('scorecard.typeOf') === 'hour') {
        return parseFloat(this.get('value'));
      } else if (this.get('value') && this.get('scorecard.reference') === 'activityArea') {
        return this.get('value').split(',');
      }
      return this.get('value');
    })
  });
});