define("boondmanager/pods/components/bm-field/period/navigate-months/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['bmc-field-period-navigate-months'],
    /**
     * Selected period
     *
     * @property selectedPeriod
     * @type moment.Moment
     * @default now()
     */
    selectedPeriod: (0, _moment.default)(),
    /**
     * List of years
     *
     * @property years
     * @type ReadonlyArray
     * @default []
     */
    years: Object.freeze([]),
    /**
     * List of months
     *
     * @property months
     * @type ReadonlyArray
     * @default []
     */
    months: Ember.computed('i18n._locale', function () {
      var monthList = [];
      _moment.default.months().forEach(function (value, index) {
        monthList.push({
          id: index,
          value: Ember.String.capitalize(value)
        });
      });
      return monthList;
    }).readOnly(),
    selectedMonth: Ember.computed('selectedPeriod', function () {
      var period = this.get('selectedPeriod');
      return period.month();
    }).readOnly(),
    selectedYear: Ember.computed('selectedPeriod', function () {
      var period = this.get('selectedPeriod');
      return period.year();
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      // init years list
      var yearPanel = 20;
      var years = [];
      var baseYear = (0, _moment.default)().year() - yearPanel + 1;
      years.push({
        id: baseYear,
        value: baseYear
      });
      for (var i = 0; i < yearPanel; i++) {
        baseYear++;
        years.push({
          id: baseYear,
          value: baseYear
        });
      }
      this.set('years', years.reverse());
    },
    actions: {
      onChange: function onChange(type, value) {
        var period = this.get('selectedPeriod');
        switch (type) {
          case 'month':
            period.month(value);
            break;
          case 'year':
            period.year(value);
            break;
        }
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange(period);
        }
      },
      onNext: function onNext() {
        var period = this.get('selectedPeriod');
        period.add(1, 'months');
        var onNext = this.get('onNext');
        if (typeof onNext === 'function') {
          onNext(period);
        }
      },
      onPrevious: function onPrevious() {
        var period = this.get('selectedPeriod');
        period.subtract(1, 'months');
        var onPrevious = this.get('onPrevious');
        if (typeof onPrevious === 'function') {
          onPrevious(period);
        }
      }
    }
  });
});