define("boondmanager/services/session", ["exports", "ember-simple-auth/services/session", "boondmanager/config/environment"], function (_exports, _session, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _session.default.extend({
    jwt: Ember.inject.service(),
    storage: Ember.inject.service(),
    router: Ember.inject.service(),
    /**
     * if the user is already connected and we receive a token, it backup the page requested before invalidating session
     * @type {string}
     */
    landingPageFromJwt: null,
    invalidate: function invalidate() {
      return this._super.apply(this, arguments);
    },
    //Si on veut modifier ou ajouter des choses au moment de l'authentification
    handleAuthentication: function handleAuthentication() {
      return this._super.apply(this, arguments);
    },
    requireAuthentication: function requireAuthentication() {
      return this._super.apply(this, arguments);
    },
    clearErrors: function clearErrors() {
      var authenticatedData = this.get('data.authenticated');
      authenticatedData.errors = undefined;
      // on nettoie aussi le customer car normalement l'erreur a empéché la connexion sur l'interface et l'identification de l'espace courant se base sur cette valeur
      authenticatedData.data.customer = undefined;
      this.session.authenticate('authenticator:update', authenticatedData);
    },
    getErrors: function getErrors() {
      return this.get('data.authenticated.errors');
    },
    requireAuthenticationOnCustomer: function requireAuthenticationOnCustomer() {
      var isAuthenticated = this.requireAuthentication.apply(this, arguments);
      var hasCustomerInterface = !!this.get('data.authenticated.meta.customer');
      if (isAuthenticated && !hasCustomerInterface) {
        // todo inserer l'erreur pour la récupérer
        this.router.transitionTo('boondmanager.myaccount.settings');
      } else return isAuthenticated && hasCustomerInterface;
    },
    handleInvalidation: function handleInvalidation() {
      if (_environment.default.environment !== 'test') {
        // keeping the jwt parameter if needed for a new authentication
        var jwt = this.jwt.consumeJWT();
        var customerCode = this.storage.get('customerCode');
        var loginType = this.storage.get('loginType');
        var returnTo = this.storage.get('returnTo');
        this.storage.clear('customerCode');
        this.storage.clear('loginType');
        this.storage.clear('returnTo');
        this.storage.clear('logoutPage');
        this.storage.clear('attemptedTransitionUrl');
        var queryParams = [];
        var url = _environment.default['ember-simple-auth'].rootURL;
        if (this.landingPageFromJwt) {
          url = this.landingPageFromJwt;
          this.set('landingPageFromJwt', null);
        } else {
          if (jwt) {
            queryParams.push('jwt=' + jwt);
          }
          if (customerCode) {
            queryParams.push('customerCode=' + customerCode);
          }
          if (loginType) {
            queryParams.push('loginType=' + loginType);
          }
          if (returnTo) {
            queryParams.push('return_to=' + encodeURI(returnTo));
          }
          if (queryParams.length) {
            url += '?' + queryParams.join('&');
          }
        }
        this.router.location.location.replace(url);
      }
    } /*routeAfterInvalidation*/
  });
});