define("boondmanager/serializers/expense", ["exports", "boondmanager/serializers/fragment", "boondmanager/models/expensetype"], function (_exports, _fragment, _expensetype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _fragment.default.extend({
    i18n: Ember.inject.service(),
    // BACK -> FRONT
    normalize: function normalize() {
      var normalized = this._super.apply(this, arguments);
      if (typeof normalized.data.attributes.expenseType === 'undefined' || normalized.data.attributes.expenseType === null) {
        normalized.data.attributes.expenseType = {
          reference: _expensetype.travelExpensesTypeReference
        };
      }
      return normalized;
    } /*modelName, resourceHash*/,
    // FRONT -> BACK
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (json.expenseType && json.expenseType.reference === _expensetype.travelExpensesTypeReference) {
        json.expenseType = null;
      }
      return json;
    } /*snapshot, options*/
  });
});