define("boondmanager/models/markerfragment", ["exports", "ember-data", "ember-data-model-fragments", "moment", "boondmanager/utils/logger", "ember-data-model-fragments/attributes", "boondmanager/models/project"], function (_exports, _emberData, _emberDataModelFragments, _moment, _logger, _attributes, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    // originalID (front) <=> id (back)
    // cf serializers/fragment.js
    originalID: _emberData.default.attr('string'),
    date: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)();
      }
    }),
    durationForecast: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    remainsToBeDone: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    // owner = batchmarkersfragment
    owner: _emberDataModelFragments.default.fragmentOwner(),
    // COMPUTED
    remainsToBeDoneInWorkUnits: Ember.computed('remainsToBeDone', 'owner.owner.workUnitRate', {
      get: function get() {
        return this.get('remainsToBeDone') * this.get('owner.owner.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model markerfragment - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('remainsToBeDone', this.get('owner.owner.workUnitRate') ? value / this.get('owner.owner.workUnitRate') : value);
        }
        return value;
      }
    }),
    durationForecastInWorkUnits: Ember.computed('durationForecast', 'owner.owner.workUnitRate', {
      get: function get() {
        return this.get('durationForecast') * this.get('owner.owner.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model markerfragment - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('durationForecast', this.get('owner.owner.workUnitRate') ? value / this.get('owner.owner.workUnitRate') : value);
        }
        return value;
      }
    }),
    progressRate: Ember.computed('resource.timesProduction', 'remainsToBeDone', function () {
      var timesProduction = this.get('resource.timesProduction');
      var remainsToBeDone = this.get('remainsToBeDone');
      return (0, _project.progressRate)(timesProduction, remainsToBeDone);
    }),
    // FRAGMENTS
    resource: (0, _attributes.fragment)('resourcefragment', {
      defaultValue: function defaultValue() {
        return {
          originalID: null
        };
      }
    })
  });
});