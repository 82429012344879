define("boondmanager/pods/components/octane/bm-rights-tabs/widgets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WrrFuw+s",
    "block": "{\"symbols\":[\"subFilter\",\"FilterComponent\",\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3,[\"subFilters\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,1,[\"component\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"component\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,[32,2],[],[[\"@filter\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"let\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-rights-tabs/widgets/template.hbs"
  });
});