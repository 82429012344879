define("boondmanager/models/tabiframe", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    title: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    urlReplaced: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return '';
      }
    })
  });
});