define("boondmanager/models/providerinvoicedocument", ["exports", "boondmanager/models/file", "ember-data"], function (_exports, _file, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Doc = _file.default.extend({
    name: _emberData.default.attr('string')
  });
  Doc.reopenClass({
    prefix: 'DOC'
  });
  Doc.reopen({
    prefix: Ember.computed(function () {
      return Doc.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Doc;
});