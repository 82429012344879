define("boondmanager/models/basedelivery", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Base model
   *
   * Base model for all our other models. This allows using polymorphic relationships
   * and define common methods and/or CP.
   *
   * @class BaseModel
   * @extends Model
   * @namespace DS
   * @module Models
   */
  var BaseDelivery = _base.default.extend({
    project: _emberData.default.belongsTo({
      inverse: 'deliveries'
    }) // deliveries ou deliveryRecruitment
  });
  var _default = _exports.default = BaseDelivery;
});