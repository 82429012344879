define("boondmanager/pods/components/bm-field/delivery/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component BM Field Positioning.
   *
   * Display a searchable field for positionings
   *
   * Component attributes:
   *
   *   * {string}             name            REQUIRED field name
   *   * {boolean}            onlyFullVisible OPTIONAL filter the positioning list with item having a dependsOn and a contact and a company on opportunity. default: false
   *   * {function}           onChange        OPTIONAL callback call on period change
   *
   *
   * @class  BmFieldPositioning
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-delivery'],
    store: Ember.inject.service(),
    /**
     * filter the positioning (if `true`, remove positioning with insuficients rights)
     * @type {boolean}
     * @default false
     */
    onlyFullVisible: false,
    disabled: false,
    allowClear: true,
    parent: null,
    _getDeliveries: function _getDeliveries(input) {
      var params = {
        keywords: input
      };
      return this.get('store').query('deliverygroupment', params);
    },
    deliveries: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.deliveries = [];
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._getDeliveries(this.get('parent.internalReference')).then(function (items) {
        var list = items;
        if (_this.get('onlyFullVisible')) {
          list = items.filter(function (item) {
            var isParentCorrect = true;
            if (_this.get('parent')) {
              switch (_this.get('parent.modelName')) {
                case 'resource':
                  isParentCorrect = item.get('dependsOn.modelName') === 'resource' && item.get('dependsOn.id') === _this.get('parent.id');
                  break;
                case 'project':
                  isParentCorrect = item.get('project.id') === _this.get('parent.id');
                  break;
                case 'contact':
                  isParentCorrect = item.get('project.contact.id') === _this.get('parent.id');
                  break;
                default:
                  isParentCorrect = false;
                  break;
              }
            }
            return isParentCorrect;
          });
        }
        if (list.length === 1) {
          _this.set('selected', list[0]);
        }
        _this.set('deliveries', list);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-delivery must have a "onChange" attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-delivery "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
    },
    actions: {
      onchange: function onchange(value) {
        this.get('onChange')(value);
      }
    }
  });
});