define("boondmanager/pods/components/octane/bm-search-filters/fields/period-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Q8fKvRLA",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"hidden\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"name\",\"class\"],[[30,[36,0],[\"components:bmSearchFilters.periodActions\"],null],\"bmc-field-action_periodActions\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,1],null,[[\"name\",\"addMissingSelected\",\"required\",\"multiple\",\"options\",\"selected\",\"placeholder\",\"onchange\"],[[32,0,[\"name\"]],true,[32,1,[\"required\"]],[32,0,[\"multiple\"]],[32,1,[\"optionsValues\"]],[32,1,[\"modalValue\"]],[30,[36,0],[\"common:indifferent\"],null],[32,0,[\"onChange\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"bm-field/power-select\",\"bm-field\",\"not\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/period-actions/template.hbs"
  });
});