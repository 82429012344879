define("boondmanager/pods/components/bm-field/simple-colorpicker/state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GD6pDqj8",
    "block": "{\"symbols\":[\"color\"],\"statements\":[[6,[37,2],null,[[\"name\",\"selected\",\"disabled\",\"useObjects\",\"forceSearch\",\"options\",\"onchange\",\"triggerClass\",\"dropdownClass\",\"selectedItemComponent\"],[[32,0,[\"name\"]],[32,0,[\"selected\"]],[32,0,[\"disabled\"]],true,false,[32,0,[\"colors\"]],[30,[36,1],[[32,0],\"onChange\"],null],\"bmc-field-simple-colorpicker-trigger\",\"bmc-field-simple-colorpicker-options bmc-field-simple-colorpicker-state-options\",\"bm-field/simple-colorpicker/state/selected-item\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-field-simple-colorpicker-color \",[30,[36,0],[\"bm-state-color-\",[32,1,[\"id\"]]],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"action\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/simple-colorpicker/state/template.hbs"
  });
});