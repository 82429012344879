define("boondmanager/initializers/model-name", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from "ember";
  var _default = _exports.default = {
    name: 'add modelName to DS.Model',
    initialize: function initialize() {
      _emberData.default.Model.reopen({
        /**
         * Model name
         *
         * cf https://github.com/emberjs/ember.js/issues/14014#issuecomment-279445221
         *
         * When we use polymorphic relationships, we can't use {{model.ourRelation.constructor.modelName}}
         * because relationships are PromiseObjects which are ObjectProxy. ObjectProxy
         * only forwards properties that are undefined in the proxy object to the
         * object in the content property. So `model.ourRelation.constructor` is the
         * constructor of PromiseObject and not the constrcutor of the reseolved model...
         * We have to use {{model.ourRelation.content.constructor.modelName}}. With this CP,
         * we can use {{model.ourRelation.modelName}} :)
         * @see http://discuss.emberjs.com/t/how-to-get-the-modelname-of-a-polymorphic-relationship-belongsto/11422
         *
         * @property modelName
         * @type {string}
         */
        /*
        modelName: Ember.computed(function() {
        	return this.get('constructor.modelName');
        })
        	 Some addons test if modelName is a String but here, we defined it as a function (Ember.computed)
         so now, we're defining it as a String in init hook
        */
        init: function init() {
          this._super.apply(this, arguments);
          this.modelName = this.constructor.modelName;
        }
      });
    }
  };
});