define("boondmanager/models/expensescertificationhistory", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EXPENSES_HISTORY_STATE_SUCCESS = _exports.EXPENSES_HISTORY_STATE_INPROGRESS = _exports.EXPENSES_HISTORY_STATE_FAILED = _exports.EXPENSES_HISTORY_STATE_ERROR = void 0;
  var EXPENSES_HISTORY_STATE_INPROGRESS = _exports.EXPENSES_HISTORY_STATE_INPROGRESS = 0;
  var EXPENSES_HISTORY_STATE_SUCCESS = _exports.EXPENSES_HISTORY_STATE_SUCCESS = 1;
  var EXPENSES_HISTORY_STATE_FAILED = _exports.EXPENSES_HISTORY_STATE_FAILED = 2;
  var EXPENSES_HISTORY_STATE_ERROR = _exports.EXPENSES_HISTORY_STATE_ERROR = 3;
  var ExpensesCertificationHistory = _base.default.extend({
    // ATTRIBUTES
    updateDate: _emberData.default.attr('date'),
    certifUUID: _emberData.default.attr('string'),
    checksum: _emberData.default.attr('string'),
    filename: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    state: _emberData.default.attr('number'),
    errorMsg: _emberData.default.attr('string'),
    // RELATIONSHIPS
    expensesReport: _emberData.default.belongsTo('expensesreport', {
      inverse: 'certification'
    }),
    isSuccess: Ember.computed('state', function () {
      return this.get('state') === EXPENSES_HISTORY_STATE_SUCCESS;
    }).readOnly(),
    isFailed: Ember.computed('state', function () {
      return this.get('state') === EXPENSES_HISTORY_STATE_FAILED;
    }).readOnly(),
    isInternalError: Ember.computed('state', function () {
      return this.get('state') === EXPENSES_HISTORY_STATE_ERROR;
    }).readOnly(),
    isInProgress: Ember.computed('state', function () {
      return this.get('state') === EXPENSES_HISTORY_STATE_INPROGRESS;
    }).readOnly()
  });
  ExpensesCertificationHistory.reopenClass({
    prefix: 'HISTOFRS'
  });
  ExpensesCertificationHistory.reopen({
    prefix: Ember.computed(function () {
      return ExpensesCertificationHistory.prefix;
    }).readOnly()
  });
  var _default = _exports.default = ExpensesCertificationHistory;
});