define("boondmanager/models/appsepapayment", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsSepaPayment = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['purchase']
    }),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'payment',
    amountExcludingTax: _emberData.default.attr('number'),
    // TAB_PAIEMENT.PMT_MONTANTHT
    amountIncludingTax: _emberData.default.attr('number'),
    expectedDate: _emberData.default.attr('date'),
    // TAB_PAIEMENT.PMT_DATEPAIEMENTATTENDU
    number: _emberData.default.attr('string'),
    // TAB_PAIEMENT.PMT_REFPROVIDER
    state: _emberData.default.attr('number'),
    // TAB_PAIEMENT.PMT_ETAT

    purchase: _emberData.default.belongsTo('appsepapurchase')
  });
  AppsSepaPayment.reopenClass({
    prefix: 'PMT'
  });
  AppsSepaPayment.reopen({
    prefix: Ember.computed(function () {
      return AppsSepaPayment.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsSepaPayment;
});