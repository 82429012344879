define("boondmanager/models/vendor", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Vendor = _base.default.extend({
    name: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    email1: _emberData.default.attr('string'),
    phone1: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    postcode: _emberData.default.attr('string'),
    town: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    isOwner: _emberData.default.attr('boolean'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    })
  });
  Vendor.reopenClass({
    prefix: ''
  });
  Vendor.reopen({
    prefix: Ember.computed(function () {
      return Vendor.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Vendor;
});