define("boondmanager/models/exceptionalscale", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    reference: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    parentType: _emberData.default.attr('number'),
    parentId: _emberData.default.attr('number'),
    workUnitTypes: _emberData.default.attr(),
    default: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean'),
    exceptionalRules: (0, _attributes.fragmentArray)('exceptionalrule'),
    /**
     * DependsOn object
     *
     * dependsOn.id                    : number
     * dependsOn.type                  : string ("agency" || "company")
     * dependsOn.name                  : string
     * dependsOn.exceptionalScales     : array of objects
     *
     * dependsOn.exceptionalScales[].reference            : number
     * dependsOn.exceptionalScales[].name                 : string
     * dependsOn.exceptionalScales[].exceptionalRules : array of objects
     *
     * dependsOn.exceptionalScales[].exceptionalRules[].reference                    : integer
     * dependsOn.exceptionalScales[].exceptionalRules[].name                         : string
     * dependsOn.exceptionalScales[].exceptionalRules[].priceExcludingTaxOrPriceRate : number
     * dependsOn.exceptionalScales[].exceptionalRules[].grossCostOrSalaryRate        : number
     */
    dependsOn: _emberData.default.attr(),
    // company or agency (cf delivery)

    agency: _emberData.default.attr(),
    // (cf delivery)

    fullReference: Ember.computed('reference', 'dependsOn', function () {
      var reference = this.get('reference');
      var dependsOnId = this.get('dependsOn.id');
      var dependsOnType = this.get('dependsOn.type');
      return "".concat(reference, "_").concat(dependsOnType, "_").concat(dependsOnId);
    }).readOnly()
  });
});