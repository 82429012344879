define("boondmanager/pods/components/bm-field/subscription-regulate/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * bm-field-subscription-regulate component.
   *
   * To be used in languages loop:
   * {{#each model.regulates key="@index" as |regulate index|}}
   *    {{bm-field/subscription-regulate regulate=regulate index=index}}
   * {{/each}}
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-subscription-regulate'],
    classNameBindings: ['errorClass'],
    errorClass: Ember.computed('errors', function () {
      return this.get('errors') ? 'bm-error has-error' : '';
    }),
    actions: {
      deleteRegulate: function deleteRegulate(index) {
        this.get('deleteRegulate')(index);
      }
    }
  });
});