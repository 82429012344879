define("boondmanager/services/thread", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Thread Service
   *
   * Service destiné à gérer les notifications de fils de discussions et les refresh automatique
   *
   * Exemple:
   *
   * //controller.js
   * ```javascript
   * threadService: Ember.inject.service('thread')
   * ```
   *
   * @class ThreadService
   * @namespace  Ember.Service
   * @extends {Ember.ObjectProxy}
   * @uses Ember.PromiseProxyMixin
   * @public
   */
  var ThreadService = Ember.ObjectProxy.extend({
    store: Ember.inject.service(),
    /**
     * Activer l'écouteur d'événements de mise à jour des threads
     *
     * @property listen
     * @type {boolean}
     * @default false
     */
    listen: false,
    /**
     * Modèle associé aux fils de discussion
     *
     * @property model
     * @type {Object}
     * @default null
     */
    model: null,
    /**
     * Fil de discussion courant (dans le cas d'une ouverture de page avec un thread=XX dans l'URL)
     *
     * @property currentThread
     * @type {Object}
     * @default null
     */
    currentThread: null,
    /**
     * Id du thread ouvert actuellement.
     * @property currentThreadId
     * @type {Number}
     * @default null
     */
    currentThreadId: null,
    /**
     * Nombre de résultats par page pour les fils de discussions
     *
     * @property threadMaxResult
     * @type {Number}
     * @default 50
     */
    threadMaxResult: 50,
    /**
     * Nombre de fils de discussions total
     *
     * @property numberOfThreads
     * @type {Number}
     * @default 0
     */
    numberOfThreads: 0,
    /**
     * Page courante
     *
     * @property threadPage
     * @type {Number}
     * @default 1
     */
    threadPage: 1,
    /**
     * Nombre de pages de fils de discussions
     *
     * @property threadNbPage
     * @type {Number}
     * @default 1
     */
    threadNbPage: 1,
    /**
     * Booléen indiquant le chargement en cours des fils de discussions
     *
     * @property loadingThreads
     * @type {boolean}
     * @default false
     */
    loadingThreads: false,
    /**
     * Booléen indiquant le chargement en cours des fils de discussions (header)
     *
     * @property loadingThreads
     * @type {boolean}
     * @default false
     */
    threadHeaderLoader: false,
    /**
     * Booléen indiquant si la barre latérale est ouverte ou fermée
     *
     * @property showThreadSidebar
     * @type {boolean}
     * @default false
     */
    showThreadSidebar: false,
    /**
     * Liste des fils de discussions affichés sur la page
     *
     * @property threads
     * @type {ReadonlyArray}
     * @default []
     */
    threads: Object.freeze([]),
    /**
     * Liste des mentions disponibles
     *
     * @property mentionsAvailables
     * @type {ReadonlyArray}
     * @default []
     */
    mentionsAvailables: Object.freeze([]),
    /**
     * Tableau de correspondance entre les références des utilisateurs mentionables et leur valeur HTML pour le tinyMCE
     *
     * @property replacements
     * @type {ReadonlyArray}
     * @default []
     */
    replacements: Object.freeze([]),
    /**
     * Initialisation du service
     */
    init: function init() {
      this.set('replacements', Ember.A([]));
    },
    /**
     * Définition du modèle courant
     * @param {Object} model Modèle
     */
    setModel: function setModel(model) {
      if (model && model.get('internalReference')) {
        this.set('model', model);
      }
    },
    /**
     * Reset du service
     */
    clear: function clear() {
      this.set('model', null);
      this.setCurrentThread(null);
      this.set('replacements', Ember.A([]));
      this.set('mentionsAvailables', Ember.A([]));
      this.set('showThreadSidebar', false);
      this.set('currentThreadId', null);
    },
    /**
     * Initialisation des fils de discussions sur la page courante
     * @param {boolean} reload Rechargement forcé (reset des valeurs et initialisation des mentions) ou non
     */
    initThreads: function initThreads(reload) {
      this.set('threads', Ember.A([]));
      this.set('loadingThreads', true);
      if (reload) {
        this.resetThreadValues();
        this.initMentionsAvailables();
      }
      this.loadThreads();
    },
    setThreads: function setThreads(threads) {
      this.set('threads', threads);
      this.set('numberOfThreads', threads.length);
      this.set('threadHeaderLoader', false);
      var nbPage = Math.ceil(threads.length / this.get('threadMaxResult'));
      this.set('threadNbPage', nbPage);
      this.set('loadingThreads', false);
    },
    loadThread: function loadThread(id, model) {
      var params = {
        id: id
      };
      if (id === '0') {
        var modelName = model.modelName;
        switch (model.modelName) {
          case 'timesreport':
            modelName = 'timesReport';
            break;
          case 'expensesreport':
            modelName = 'expensesReport';
            break;
          case 'absencesreport':
            modelName = 'absencesReport';
            break;
          case 'followeddocument':
            modelName = 'followedDocument';
            break;
          case 'appadvancedcandidatescandidate':
            modelName = 'appAdvancedCandidatesCandidate';
            break;
          case 'appentity':
            modelName = 'appEntity';
            break;
        }
        Ember.set(params, modelName, model.id);
      }
      return this.store.queryRecord('thread', params);
    },
    initThreadFromCardAction: function initThreadFromCardAction(model) {
      this.setModel(model);
      this.resetThreadValues();
      this.setThreads(model.get('threads'));
      this.initMentionsAvailables();
    },
    initThreadSidebar: function initThreadSidebar(model) {
      var _this = this;
      this.setModel(model);
      this.initThreads(true);
      if (model.get('threads')) {
        this.setThreads(model.get('threads'));
      }
      this.set('showThreadSidebar', true);

      // S'il n'y a aucun thread, on fait un getNew
      if (this.get('numberOfThreads') === 0) {
        this.loadThread('0', model).then(function (thread) {
          thread.copy(true).then(function (copy) {
            // remove template from the store
            _this.store.peekRecord('thread', '0').unloadRecord();
            copy.set('text', '');
            copy.set('answers', Ember.A([]));
            _this.setCurrentThread(copy);
            return copy;
          }).catch(function (e) {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              // re-throw the non-cancelation error
              throw e;
            }
          });
        });
      }
    },
    /**
     * Ouverture d'un thread spécifique depuis une page (avec ouverture de la barre latérale)
     * @param {Number} id Id du thread à ouvrir
     * */
    openThread: function openThread(id) {
      var _this2 = this;
      this.set('showThreadSidebar', true);
      this.set('loadingThreads', true);
      this.set('currentThreadId', id);
      this.store.queryRecord('thread', {
        id: id
      }).then(function (thread) {
        if (thread.get('dependsOn.internalReference') === _this2.get('model.internalReference')) {
          _this2.setCurrentThread(thread);
          _this2.store.query('thread', {
            parentThread: id,
            typeOf: 'answer',
            sort: 'creationDate',
            order: 'ASC'
          }).then(function (answers) {
            thread.set('answers', answers.toArray());
            _this2.set('loadingThreads', false);
            _this2.initMentionsAvailables();
          }).catch(function () {
            _this2.set('loadingThreads', false);
          });
        } else {
          _this2.initThreads(true);
          _this2.set('currentThreadId', null);
        }
      }).catch(function (error) {
        _this2.set('currentThreadId', null);
        // si le thread n'existe pas, on charge la liste des fils de discussions
        if (error.errors[0].code === '404') {
          _this2.initThreads(true);
        } else {
          _this2.set('loadingThreads', false);
        }
      });
    },
    /**
     * Définition du fil de discussion courant
     * @param {Object} thread Fil de discussion courant
     */
    setCurrentThread: function setCurrentThread(thread) {
      this.set('currentThread', thread);
    },
    /**
     * Retourne l'id du thread actuellement ouvert.
     */
    getCurrentThreadId: function getCurrentThreadId() {
      return this.get('currentThreadId');
    },
    /**
     * Remise à zéro des valeurs du service
     */
    resetThreadValues: function resetThreadValues() {
      this.setCurrentThread(null);
      this.set('threadPage', 1);
      this.set('threadNbPage', 1);
      this.set('currentThreadId', null);
    },
    /**
     * Initialisation du tableau des mentions possibles et des correspondances de remplacement
     * entre référence interne et HTML tinyMCE
     */
    initMentionsAvailables: function initMentionsAvailables() {
      var _this3 = this;
      this.get('store').query('thread', {
        keywords: this.get('model.internalReference'),
        sort: 'firstName',
        endpoint: 'mentions'
      }).then(function (mentionsAvailables) {
        _this3.set('mentionsAvailables', mentionsAvailables);
        mentionsAvailables.forEach(function (mentionAvailable) {
          _this3.get('replacements').pushObject({
            reference: mentionAvailable.get('internalReference'),
            needle: "<span class=\"mceNonEditable mentionEditThread\" data-id=\"".concat(mentionAvailable.get('internalReference'), "\">@").concat(mentionAvailable.get('completeName'), "</span>")
          });
        });
      }).catch(function () {});
    },
    /**
     * Chargement des fils de discussion
     * @returns {*}
     * @private
     */
    loadThreads: function loadThreads() {
      var _this4 = this;
      if (this.get('model.internalReference')) {
        this.queryThreads(this.get('model.internalReference'), this.get('threadPage')).then(function (threads) {
          _this4.set('threads', threads);
          _this4.set('model.threads', threads);
          _this4.set('numberOfThreads', threads.meta.totals.rows);
          _this4.set('threadHeaderLoader', false);
          // ici, dans le cas où un utilisateur aurait supprimé un fil de discussion depuis un autre navigateur
          // lorsque l'on recharge les threads, si le nombre de page a changé et qu'on était sur la dernière page
          // il faudra alors recharger le contenu sur la page précédente
          var nbPage = Math.ceil(threads.meta.totals.rows / _this4.get('threadMaxResult'));
          if (threads.get('length') === 0 && _this4.get('threadPage') > nbPage && nbPage > 0) {
            _this4.goToNewestThreads();
          } else {
            _this4.set('threadNbPage', nbPage);
            _this4.set('loadingThreads', false);
          }
        }).catch(function () {
          _this4.set('loadingThreads', false);
        });
      }
    },
    /**
     * Méthode pour lancer le chargement des threads (mutualisée avec les routers/controllers)
     * @param reference
     * @param page
     * @return {*}
     */
    queryThreads: function queryThreads(reference, page) {
      return this.store.query('thread', {
        keywords: reference,
        sort: 'creationDate',
        order: 'desc',
        typeOf: 'comment,share',
        page: page,
        maxResults: this.get('threadMaxResult')
      });
    },
    /**
     * Pagination vers les fils de discussions plus anciens
     */
    goToOlderThreads: function goToOlderThreads() {
      this.set('threadPage', this.get('threadPage') < this.get('threadNbPage') ? this.get('threadPage') + 1 : this.get('threadNbPage'));
      this.initThreads();
    },
    /**
     * Pagination vers les fils de discussions plus récents
     */
    goToNewestThreads: function goToNewestThreads() {
      this.set('threadPage', this.get('threadPage') > 1 ? this.get('threadPage') - 1 : 1);
      this.initThreads();
    }
  });

  /*
  	Reopen Class to add `isServiceFactory` and set it to `true` so `ThreadService`
  	which is an `Ember.ObjectProxy` will be seen as an `Ember.Service`.
  
  	see https://github.com/emberjs/ember.js/issues/13249
  	see fix example here: https://github.com/grownups/ember-cart/pull/1/files
   */
  ThreadService.reopenClass({
    isServiceFactory: true
  });
  var _default = _exports.default = ThreadService;
});