define("boondmanager/models/pole", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Pole = _base.default.extend(_emberDataCopyable.default, {
    name: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    entityName: Ember.computed('name', function () {
      var name = this.get('name');
      if (Ember.isBlank(name)) {
        return this.get('internalReference');
      } else {
        return name;
      }
    }).readOnly()
  });
  Pole.reopenClass({
    prefix: 'POLE'
  });
  Pole.reopen({
    prefix: Ember.computed(function () {
      return Pole.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Pole;
});