define("boondmanager/initializers/fragment-serializer", ["exports", "boondmanager/serializers/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('serializer:-fragment', _fragment.default);
  }
  var _default = _exports.default = {
    name: 'fragment-serializer',
    initialize: initialize
  };
});