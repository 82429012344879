define("boondmanager/models/scorecard", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/models/target", "boondmanager/models/candidate"], function (_exports, _emberData, _emberDataModelFragments, _target, _candidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    category: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    typeOf: _emberData.default.attr('string'),
    activityType: _emberData.default.attr('string'),
    dictionaryId: _emberData.default.attr('number'),
    resource: _emberData.default.attr(),
    candidate: _emberData.default.attr(),
    project: _emberData.default.attr(),
    opportunity: _emberData.default.attr(),
    settingsService: Ember.inject.service('settings'),
    currentUser: Ember.inject.service('current-user'),
    hash: Ember.computed('category', 'reference', 'dictionaryId', 'project.id', function () {
      return this.category + this.reference + this.dictionaryId + this.get('project.id');
    }),
    isSeparator: Ember.computed.equal('typeOf', 'separator'),
    title: Ember.computed('typeOf', 'i18n._locale', function () {
      var _this = this;
      var i18n = this.get('i18n');
      //@FixMe GRI : discuss with Louis
      var dictKey = 'reporting:categories.' + this.get('category') + '.scorecards.' + this.get('reference');
      var title = i18n.t("".concat(dictKey, ".title"));
      var dictionaryLabels = (0, _target.getDictionaryLabelFromCategoryAndReference)(this.get('settingsService'), this.get('category'), this.get('reference'));
      if (typeof this.get('dictionaryId') !== 'undefined') {
        var dictItem = false;
        dictItem = _typeof(dictionaryLabels) === 'object' ? dictionaryLabels.find(function (item) {
          return item.id === _this.get('dictionaryId');
        }) : '';
        title += ' - ' + (!dictItem ? this.get('dictionaryId') : dictItem.value);
      }
      return title;
    }),
    uid: Ember.computed('category,reference,dictionaryId', function () {
      var uid = this.get('reference');
      if (typeof this.get('dictionaryId') !== 'undefined') {
        uid += '_' + this.get('dictionaryId');
      }
      return uid;
    }),
    dictKey: Ember.computed('scorecard', function () {
      return 'reporting:categories.' + this.get('category') + '.scorecards.' + this.get('reference');
    }),
    unit: Ember.computed('typeOf', 'activityType', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      switch (this.get('typeOf')) {
        case 'day|day':
        case 'day':
          return i18n.t('common:dateAndTime.days.abbr');
        case 'workUnit':
          return i18n.t('models:project.attributes.workUnitRate.abbr');
        case 'dayOrWorkUnit|dayOrWorkUnit':
        case 'dayOrWorkUnit':
          if (this.get('activityType') === 'workUnit') return i18n.t('models:project.attributes.workUnitRate.abbr');else return i18n.t('common:dateAndTime.days.abbr');
        case 'money':
          {
            var currency = this.get('currentUser.agency.currency');
            return this.get('settingsService').customer.currency.findBy('id', currency).symbol;
          }
        case 'percentage':
          return '%';
        case 'hour':
          return i18n.t('common:dateAndTime.hours.abbr');
        default:
          return '';
      }
    }),
    scoreCardMapper: Ember.computed('reference', function () {
      var category = this.get('category');
      var queryParams = {
          keywords: '',
          perimeterAgencies: [],
          perimeterBusinessUnits: [],
          perimeterManagers: [],
          perimeterPoles: []
        },
        route = false,
        type = false,
        projectTypes = this.get('settingsService').customer.typeOf.project,
        resourceTypes = this.get('settingsService').customer.typeOf.resource,
        deliveryStates = this.get('settingsService').customer.state.delivery;
      switch (this.get('reference')) {
        case 'numberOfActionsCreatedOnContactsPerTypes':
        case 'numberOfActionsOnContactsPerTypes':
          route = 'private.actions';
          type = 'actionTypes';
          queryParams.period = this.get('reference') === 'numberOfActionsCreatedOnContactsPerTypes' ? 'created' : 'started';
          queryParams.actionTypes = [];
          queryParams.flags = [];
          queryParams.states = [];
          break;
        case 'numberOfContactsCreated':
          queryParams.period = 'created';
          queryParams.activityAreas = [];
          queryParams.expertiseAreas = [];
          queryParams.flags = [];
          queryParams.origins = [];
          queryParams.states = [];
          queryParams.tools = [];
          route = 'private.contacts';
          type = 'contactTypes';
          break;
        case 'numberOfCompaniesCreated':
          queryParams.period = 'created';
          queryParams.expertiseAreas = [];
          queryParams.flags = [];
          queryParams.origins = [];
          queryParams.states = [];
          route = 'private.companies';
          type = 'companyTypes';
          break;
        case 'numberOfDeliveriesInProgressPerType':
        case 'numberOfDeliveriesInProgressNone':
        case 'numberOfDeliveriesInProgress':
        case 'numberOfDeliveriesStartedPerType':
        case 'numberOfDeliveriesStartedNone':
        case 'numberOfDeliveriesStarted':
        case 'numberOfDeliveriesStoppedPerType':
        case 'numberOfDeliveriesStoppedNone':
        case 'numberOfDeliveriesStopped':
        case 'marginSignedExcludingTax':
        case 'marginSimulatedExcludingTax':
        case 'profitabilitySimulated':
        case 'turnoverSignedExcludingTax':
        case 'turnoverSimulatedExcludingTax':
        case 'turnoverForecastExcludingTax':
        case 'profitabilitySigned':
        case 'marginForecastExcludingTax':
        case 'profitabilityForecast':
          queryParams.deliveryStates = deliveryStates.map(function (s) {
            return s.id;
          }).filter(function (s) {
            return s !== 1;
          });
          queryParams.projectTypes = [];
          queryParams.period = 'running';
          queryParams.sumAdditionalData = true;
          queryParams.showGroupment = true;
          if (['numberOfDeliveriesStartedPerType', 'numberOfDeliveriesStartedNone', 'numberOfDeliveriesStarted', 'numberOfDeliveriesStoppedPerType', 'numberOfDeliveriesStoppedNone', 'numberOfDeliveriesStopped', 'numberOfDeliveriesInProgressPerType', 'numberOfDeliveriesInProgressNone', 'numberOfDeliveriesInProgress'].indexOf(this.get('reference')) > -1) {
            queryParams.deliveryStates = [];
            if (['numberOfDeliveriesInProgressPerType', 'numberOfDeliveriesInProgressNone', 'numberOfDeliveriesInProgress'].indexOf(this.get('reference')) > -1) {
              queryParams.period = 'running';
            } else if (['numberOfDeliveriesStartedPerType', 'numberOfDeliveriesStartedNone', 'numberOfDeliveriesStarted'].indexOf(this.get('reference')) > -1) {
              queryParams.period = 'started';
            } else if (['numberOfDeliveriesStoppedPerType', 'numberOfDeliveriesStoppedNone', 'numberOfDeliveriesStopped'].indexOf(this.get('reference')) > -1) {
              queryParams.period = 'stopped';
            }

            // Cas de "None"
            if (['numberOfDeliveriesStartedNone', 'numberOfDeliveriesStoppedNone', 'numberOfDeliveriesInProgressNone'].indexOf(this.get('reference')) > -1) {
              queryParams.deliveriesTypes = [0];
            }
            queryParams.showGroupment = false;
            queryParams.sort = 'project.reference';
            queryParams.sumAdditionalData = false;
            queryParams.projectTypes = projectTypes.map(function (type) {
              if ([1, 2].indexOf(type.mode) > -1 && !type.isInternal) {
                return type.id;
              }
            }).filter(function (type) {
              return type;
            });
          }
          if (['turnoverForecastExcludingTax', 'marginSignedExcludingTax', 'marginSimulatedExcludingTax', 'turnoverSignedExcludingTax', 'turnoverSimulatedExcludingTax', 'marginForecastExcludingTax', 'profitabilitySimulated', 'profitabilityForecast'].indexOf(this.get('reference')) > -1) {
            if (['turnoverSimulatedExcludingTax', 'marginSimulatedExcludingTax', 'profitabilitySimulated'].indexOf(this.get('reference')) > -1) {
              queryParams.deliveryStates = [];
            } else if (['marginForecastExcludingTax', 'profitabilityForecast', 'turnoverForecastExcludingTax'].includes(this.get('reference'))) {
              queryParams.deliveryStates = [1];
            }
            queryParams.projectTypes = projectTypes.map(function (type) {
              if (!type.isInternal) {
                return type.id;
              }
            }).filter(function (type) {
              return type;
            });
          }
          queryParams.activityAreas = [];
          queryParams.expertiseAreas = [];
          queryParams.flags = [];
          queryParams.projectStates = [];
          queryParams.transferType = '';
          route = 'private.deliveries';
          type = 'deliveriesTypes';
          break;
        case 'numberOfProjectsCreated':
        case 'numberOfProjectsInProgress':
        case 'numberOfPackageProjectsInProgress':
        case 'rateOfPackageProjectsInProgress':
        case 'numberOfTechnicalAssistanceProjectsInProgress':
        case 'rateOfTechnicalAssistanceProjectsInProgress':
        case 'numberOfRecruitmentProjectsInProgress':
        case 'rateOfRecruimentProjectsInProgress':
        case 'numberOfProductProjectsInProgress':
        case 'rateOfProductProjectsInProgress':
          queryParams.period = this.get('reference') === 'numberOfProjectsCreated' ? 'created' : 'running';
          queryParams.projectTypes = projectTypes.map(function (type) {
            if (!type.isInternal) {
              return type.id;
            }
          }).filter(function (type) {
            return type;
          });
          if (['numberOfTechnicalAssistanceProjectsInProgress', 'rateOfTechnicalAssistanceProjectsInProgress'].indexOf(this.get('reference')) > -1) {
            queryParams.projectTypes = projectTypes.map(function (type) {
              if (type.mode === 1 && !type.isInternal) {
                return type.id;
              }
            }).filter(function (type) {
              return type;
            });
          }
          if (['numberOfPackageProjectsInProgress', 'rateOfPackageProjectsInProgress'].indexOf(this.get('reference')) > -1) {
            queryParams.projectTypes = projectTypes.map(function (type) {
              if (type.mode === 2 && !type.isInternal) {
                return type.id;
              }
            }).filter(function (type) {
              return type;
            });
          }
          if (['numberOfRecruitmentProjectsInProgress', 'rateOfRecruimentProjectsInProgress'].indexOf(this.get('reference')) > -1) {
            queryParams.projectTypes = projectTypes.map(function (type) {
              if (type.mode === 3 && !type.isInternal) {
                return type.id;
              }
            }).filter(function (type) {
              return type;
            });
          }
          if (['numberOfProductProjectsInProgress', 'rateOfProductProjectsInProgress'].indexOf(this.get('reference')) > -1) {
            queryParams.projectTypes = projectTypes.map(function (type) {
              if (type.mode === 4 && !type.isInternal) {
                return type.id;
              }
            }).filter(function (type) {
              return type;
            });
          }
          queryParams.activityAreas = [];
          queryParams.expertiseAreas = [];
          queryParams.flags = [];
          queryParams.projectStates = [];
          queryParams.sumAdditionalData = true;
          route = 'private.projects';
          type = 'projectTypes';
          break;
        case 'durationOfAllSignedTime':
        case 'durationOfAllUsedTime':
        case 'costsOfSignedExpensesExcludingTax':
        case 'costsOfUsedExpensesExcludingTax':
          queryParams.startMonth = '';
          queryParams.endMonth = '';
          route = this.get('reference').indexOf('Time') > -1 ? 'private.timesreports' : 'private.expensesreports';
          break;
        case 'turnoverWeightedExcludingTaxPerStates':
        case 'numberOfOpportunitiesPerStates':
          queryParams.period = 'created';
          route = 'private.opportunities';
          type = 'opportunityStates';
          break;
        case 'numberOfOpportunitiesCreated':
        case 'turnoverWeightedExcludingTax':
          queryParams.period = 'created';
          queryParams.opportunityStates = [];
          route = 'private.opportunities';
          type = 'opportunityTypes';
          break;
        case 'numberOfPositioningsCreated':
          if (category === 'recruitmentSynthesis') {
            queryParams.entityTypes = [_candidate.CANDIDATE_ENTITY_TYPE];
            queryParams.perimeterManagersType = 'profiles';
            queryParams.returnMoreData = ['dependsOn.hrManager', 'dependsOn.mainManager'];
          } else if (category === 'humanResourcesSynthesis') {
            queryParams.entityTypes = resourceTypes.mapBy('id');
            queryParams.perimeterManagersType = 'profiles';
            queryParams.returnMoreData = ['dependsOn.hrManager', 'dependsOn.mainManager'];
          } else {
            queryParams.entityTypes = [];
            queryParams.perimeterManagersType = 'opportunities';
            queryParams.returnMoreData = [];
          }
          queryParams.period = 'created';
          queryParams.candidate = '';
          queryParams.flags = [];
          queryParams.opportunityStates = [];
          queryParams.opportunityTypes = [];
          queryParams.positioningStates = [];
          queryParams.positioningType = 'resourcesOrCandidates';
          route = 'private.positionings';
          type = 'positioningTypes';
          break;
        case 'numberOfActionsOnOpportunitiesPerTypes':
        case 'numberOfActionsOnProjectsPerTypes':
        case 'numberOfActionsOnResourcesPerTypes':
        case 'numberOfActionsOnCandidatesPerTypes':
          queryParams.period = 'started';
          queryParams.actionTypes = [];
          queryParams.flags = [];
          queryParams.states = [];
          type = 'actionTypes';
          route = 'private.actions';
          break;
        case 'numberOfInternalStaff':
        case 'numberOfExternalStaff':
        case 'numberOfHired':
        case 'numberOfDepartures':
        case 'numberOfResourcesOnDeliveries':
        case 'numberOfExternalResourcesOnDeliveries':
        case 'numberOfInternalResourcesOnDeliveries':
        case 'numberOfResourcesOnInternalActivity':
        case 'numberOfResourcesOnAbsencesActivity':
          queryParams.period = 'working';
          if (this.get('reference') !== 'numberOfResourcesOnDeliveries') {
            queryParams.resourceTypes = resourceTypes.filter(function (type) {
              return !type.isExternal;
            }).map(function (type) {
              return type.id;
            });
            if (['numberOfExternalResourcesOnDeliveries', 'numberOfExternalStaff'].includes(this.get('reference'))) {
              queryParams.resourceTypes = resourceTypes.filter(function (type) {
                return type.isExternal;
              }).map(function (type) {
                return type.id;
              });
            }
          }
          if (this.get('reference') === 'numberOfResourcesOnAbsencesActivity') {
            queryParams.period = 'absent';
          }
          if (this.get('reference') === 'numberOfResourcesOnInternalActivity') {
            queryParams.period = 'idle';
          }
          if (this.get('reference') === 'numberOfHired') {
            queryParams.period = 'hired';
          }
          if (this.get('reference') === 'numberOfDepartures') {
            queryParams.period = 'left';
          }
          if (['numberOfInternalStaff', 'numberOfExternalStaff'].includes(this.get('reference'))) {
            queryParams.period = 'employed';
          }
          queryParams.activityAreas = [];
          queryParams.availabilityTypes = [];
          queryParams.excludeManager = false;
          queryParams.excludeResourceTypes = [];
          queryParams.experiences = [];
          queryParams.expertiseAreas = [];
          queryParams.flags = [];
          queryParams.languages = [];
          queryParams.mobilityAreas = [];
          queryParams.resourceStates = [];
          queryParams.returnHRManager = false;
          queryParams.tools = [];
          queryParams.trainings = [];
          route = 'private.resources';
          type = 'resourceTypes';
          break;
        case 'numberOfCandidatesCreatedPerStates':
          route = 'private.candidates';
          type = 'candidateStates';
          queryParams.period = 'created';
          queryParams.activityAreas = [];
          queryParams.availabilityTypes = [];
          queryParams.candidateStates = [];
          queryParams.contractTypes = [];
          queryParams.evaluations = [];
          queryParams.experiences = [];
          queryParams.expertiseAreas = [];
          queryParams.flags = [];
          queryParams.languages = [];
          queryParams.mobilityAreas = [];
          queryParams.sources = [];
          queryParams.tools = [];
          queryParams.trainings = [];
          break;
        case 'numberOfCandidatesCreated':
          route = 'private.candidates';
          type = 'candidateTypes';
          queryParams.period = 'created';
          queryParams.activityAreas = [];
          queryParams.availabilityTypes = [];
          queryParams.candidateStates = [];
          queryParams.contractTypes = [];
          queryParams.evaluations = [];
          queryParams.experiences = [];
          queryParams.expertiseAreas = [];
          queryParams.flags = [];
          queryParams.languages = [];
          queryParams.mobilityAreas = [];
          queryParams.sources = [];
          queryParams.tools = [];
          queryParams.trainings = [];
          queryParams.trainings = [];
          break;
        case 'turnoverInvoicedExcludingTaxFollowingInvoicesDate':
        case 'turnoverInvoicedProFormaExcludingTaxFollowingInvoicesDate':
        case 'turnoverInvoicedExcludingTaxFollowingInvoicesDatePerStates':
          route = 'private.invoices';
          type = 'states';
          queryParams.period = 'created';
          queryParams.closing = undefined;
          queryParams.creditNote = undefined;
          queryParams.flags = [];
          queryParams.paymentMethods = [];
          queryParams.projectTypes = [];
          queryParams.states = [];
          if (this.get('reference') === 'turnoverInvoicedProFormaExcludingTaxFollowingInvoicesDate') {
            queryParams['states'] = [10];
          }
          break;
        case 'turnoverInvoicedExcludingTaxFollowingInvoicesPeriod':
        case 'turnoverInvoicedProFormaExcludingTaxFollowingInvoicesPeriod':
        case 'turnoverInvoicedExcludingTaxFollowingInvoicesPeriodPerStates':
          route = 'private.invoices';
          type = 'states';
          queryParams.period = 'period';
          queryParams.closing = undefined;
          queryParams.creditNote = undefined;
          queryParams.flags = [];
          queryParams.paymentMethods = [];
          queryParams.projectTypes = [];
          queryParams.states = [];
          if (this.get('reference') === 'turnoverInvoicedProFormaExcludingTaxFollowingInvoicesPeriod') {
            queryParams['states'] = [10];
          }
          break;
        case 'turnoverOrderedExcludingTax':
        case 'deltaInvoicedExcludingTaxFollowingInvoicesDate':
        case 'turnoverInvoicedExcludingTaxFollowingCommandsPeriod':
          queryParams.period = 'created';
          queryParams.flags = [];
          queryParams.paymentMethods = [];
          queryParams.projectTypes = [];
          queryParams.states = [];
          route = 'private.orders';
          type = 'states';
          break;
        case 'numberOfCandidatesCreatedPerSources':
          type = 'sourceTypes';
          queryParams.period = 'created';
          break;
        case 'durationOfAllUsedTimePerActivity':
        case 'costsOfAllUsedTimeExcludingTaxPerActivity':
          type = 'activityTypes';
          break;
        case 'numberOfValidatedTimesReports':
        case 'numberOfRejectedTimesReports':
        case 'numberOfWaitingForValidationTimesReports':
        case 'numberOfValidatedExpensesReports':
        case 'numberOfRejectedExpensesReports':
        case 'numberOfWaitingForValidationExpensesReports':
        case 'numberOfValidatedAbsencesReports':
        case 'numberOfRejectedAbsencesReports':
        case 'numberOfWaitingForValidationAbsencesReports':
          if (['numberOfValidatedTimesReports', 'numberOfValidatedExpensesReports', 'numberOfValidatedAbsencesReports'].indexOf(this.get('reference')) > -1) {
            queryParams.validationStates = ['validated'];
          }
          if (['numberOfRejectedTimesReports', 'numberOfRejectedExpensesReports', 'numberOfRejectedAbsencesReports'].indexOf(this.get('reference')) > -1) {
            queryParams.validationStates = ['rejected'];
          }
          if (['numberOfWaitingForValidationTimesReports', 'numberOfWaitingForValidationExpensesReports', 'numberOfWaitingForValidationAbsencesReports'].indexOf(this.get('reference')) > -1) {
            queryParams.validationStates = ['waitingForValidation'];
          }
          if (['numberOfValidatedTimesReports', 'numberOfRejectedTimesReports', 'numberOfWaitingForValidationTimesReports'].indexOf(this.get('reference')) > -1) {
            route = 'private.timesreports';
          }
          if (['numberOfValidatedExpensesReports', 'numberOfRejectedExpensesReports', 'numberOfWaitingForValidationExpensesReports'].indexOf(this.get('reference')) > -1) {
            route = 'private.expensesreports';
          }
          if (['numberOfValidatedAbsencesReports', 'numberOfRejectedAbsencesReports', 'numberOfWaitingForValidationAbsencesReports'].indexOf(this.get('reference')) > -1) {
            route = 'private.absencesreports';
          }
          queryParams.startMonth = '';
          queryParams.endMonth = '';
          queryParams.period = undefined;
          queryParams.resourceTypes = [];
          break;
      }
      if (typeof this.get('dictionaryId') !== 'undefined' && route) {
        queryParams[type] = [this.get('dictionaryId')];
      }
      return {
        queryParams: queryParams,
        route: route
      };
    })
  });
});