define("boondmanager/pods/components/bm-field/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field'],
    classNameBindings: ['errorClass', 'uniqErrorClass', 'fitContent:bmc-field_fit-content', 'inline:bmc-field_inline', 'inlineContent:bmc-field_inline-content', 'inlineMobile:bmc-field_inline-mobile', 'important:bmc-field_important', 'importantData:bmc-field_important-data', 'veryImportantData:bmc-field_very-important-data', 'noTitle:bmc-field_no-title', 'noTitleOnMobile:bmc-field_no-title-on-mobile', 'noTitleOnHover:bmc-field_no-title-on-hover', 'required:bmc-field_required', 'reverse:bmc-field_inline-reverse', 'readOnly:bmc-field_readonly', 'labelTop:bmc-field_labeltop'],
    attributeBindings: ['data-name'],
    /**
     * Bind data-custom-class to add specific class on tooltip div
     *
     * @property helpCustomClass
     * @type String
     * @default ''
     */
    helpCustomClass: '',
    /**
     * Help is in fulltext or truncated (default)
     *
     * @property helpFulltext
     * @type boolean
     * @default false
     */
    helpFulltext: false,
    genericLengthErrors: false,
    isHelpFulltext: Ember.computed('helpFulltext', function () {
      return this.get('helpFulltext') ? '1' : '';
    }).readOnly(),
    errorClass: Ember.computed('errors.length', 'isValid', function () {
      // here we test if valid is only false value to avoid case of undefined and display
      // error class only if validation failed
      return (this.get('errors.length') || this.get('isValid') === false) && !this.get('uniqListElement') ? 'bm-error has-error' : '';
    }).readOnly(),
    uniqErrorClass: Ember.computed('errors.length', function () {
      return this.get('errors.length') && this.get('uniqListElement') ? 'bm-error-uniq has-error' : '';
    }).readOnly(),
    inputClass: Ember.computed('isValid', 'errors.length', function () {
      var klass = '';
      if (!this.get('uniqListElement')) {
        if (this.get('isValid')) {
          klass = 'bm-input_validated';
        } else {
          // here we test if valid is only false value to avoid case of undefined and display
          // error class only if validation failed
          if (this.get('errors.length') || this.get('isValid') === false) {
            klass = 'bm-input_error';
          }
        }
      }
      return klass;
    }).readOnly()
  });
});