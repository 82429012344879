define("boondmanager/models/appsgcalendarresource", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsGCalendarResource = _base.default.extend({
    providerEmail: _emberData.default.attr('string'),
    oauthRedirection: _emberData.default.attr('string')
  });
  AppsGCalendarResource.reopenClass({
    prefix: 'COMP'
  });
  AppsGCalendarResource.reopen({
    prefix: Ember.computed(function () {
      return AppsGCalendarResource.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsGCalendarResource;
});