define("boondmanager/models/appaccountingpayrollcustomer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    accountingVersion: _emberData.default.attr('string'),
    payrollVersion: _emberData.default.attr('string'),
    invoiceNumber: _emberData.default.attr('string'),
    invoiceTitle: _emberData.default.attr('string'),
    paymentNumber: _emberData.default.attr('string'),
    paymentTitle: _emberData.default.attr('string'),
    expensesNumber: _emberData.default.attr('string'),
    expensesTitle: _emberData.default.attr('string'),
    waitingThirdAccount: _emberData.default.attr('string'),
    invoiceAccount: _emberData.default.attr('string'),
    invoiceJournal: _emberData.default.attr('string'),
    invoiceGlobalThirdAccount: _emberData.default.attr('string'),
    paymentAccount: _emberData.default.attr('string'),
    paymentJournal: _emberData.default.attr('string'),
    paymentGlobalThirdAccount: _emberData.default.attr('string'),
    expensesAccount: _emberData.default.attr('string'),
    expensesJournal: _emberData.default.attr('string'),
    expensesGlobalThirdAccount: _emberData.default.attr('string'),
    expensesTaxAccount: _emberData.default.attr('string'),
    expensesGroupAllCredit: _emberData.default.attr('boolean'),
    expensesAllowToUseAccounts: _emberData.default.attr('boolean'),
    invoiceAllowToUseAccounts: _emberData.default.attr('boolean'),
    payrollExtractConsolidatedExpenses: _emberData.default.attr('boolean'),
    payrollExtractConsolidatedAbsences: _emberData.default.attr('boolean'),
    invoiceTaxAccounts: _emberData.default.attr(),
    paymentTaxAccounts: _emberData.default.attr(),
    agencies: _emberData.default.attr()
  });
});