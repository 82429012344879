define("boondmanager/pods/components/bm-field/role/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-role'],
    required: false,
    showRoleLink: true,
    showFooter: true,
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-role must have a "onChange" attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-role "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
    },
    actions: {
      onItemChange: function onItemChange(selected) {
        if (this.get('errors') && selected) {
          this.set('errors', []);
        }
        this.get('onChange')(selected);
      },
      createRole: function createRole() {
        var onClickCreateRole = this.get('onClickCreateRole');
        if (typeof onClickCreateRole === 'function') {
          onClickCreateRole();
        }
      },
      seeRole: function seeRole() {
        var id = this.get('selected.id');
        this.get('router').transitionTo('private.administrator.roles.role', id);
      }
    }
  });
});