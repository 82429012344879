define("boondmanager/pods/components/bm-field/calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Wj4UDViM",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,11],null,[[\"name\",\"customSearch\",\"selected\",\"onchange\",\"renderInPlace\",\"options\",\"required\",\"placeholder\",\"searchPlaceholder\",\"searchMessage\",\"allowClear\",\"loadingMessage\",\"noMatchesMessage\",\"disabled\",\"useObjects\"],[[35,10],[30,[36,8],[[32,0],\"searchCalendars\"],null],[35,9],[30,[36,8],[[32,0],\"onchange\"],null],[35,7],[35,6],[35,5],[30,[36,4],[\"common:none\"],[[\"context\"],[\"calendar\"]]],[30,[36,4],[\"components:bmFieldSearchItems.searchMessage\"],[[\"context\"],[\"calendar\"]]],\"\",[35,3],[35,2],[35,1],[35,0],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"noMatchesMessage\",\"loadingMessage\",\"allowClear\",\"t\",\"required\",\"options\",\"renderInPlace\",\"action\",\"customSelected\",\"name\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/calendar/template.hbs"
  });
});