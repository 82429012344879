define("boondmanager/models/absencesreport", ["exports", "ember-copy", "boondmanager/utils/logger", "boondmanager/models/baseactivity", "ember-data", "moment", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "ember-data-copyable"], function (_exports, _emberCopy, _logger, _baseactivity, _emberData, _moment, _attributes, _fragment, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATES = _exports.ABSENSE_COLOR_MODULO = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var STATES = _exports.STATES = [_baseactivity.ACTIVITY_SAVED_AND_NO_VALIDATION, _baseactivity.ACTIVITY_WAITING_FOR_VALIDATION, _baseactivity.ACTIVITY_VALIDATED, _baseactivity.ACTIVITY_REJECTED];
  var ABSENSE_COLOR_MODULO = _exports.ABSENSE_COLOR_MODULO = 8;
  var AbsencesReport = _baseactivity.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'resource', 'validations', 'validationWorkflow']
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);

      /**
       * On Init, create some boolean CPs based on STATES
       *   * isSavedAndNoValidation
       *   * isWaitingForValidation
       *   * isValidated
       *   * ...
       */
      STATES.forEach(function (state) {
        var stateName = Ember.String.capitalize(state);
        Ember.defineProperty(_this, "is".concat(stateName), Ember.computed.equal('state', state));
      });
    },
    closed: _emberData.default.attr('boolean'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    informationComments: _emberData.default.attr('string'),
    absencesPeriods: (0, _attributes.fragmentArray)('absencesperiod'),
    absencesQuestions: (0, _attributes.fragmentArray)('absencesquestion'),
    absencesAccounts: _emberData.default.hasMany('absencesaccount'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    canReadAbsence: _emberData.default.attr('boolean'),
    labelWithInternalReference: Ember.computed('state', 'label', 'i18n._locale', function () {
      return this.get('label') + ' - ' + this.get('internalReference');
    }).readOnly(),
    label: Ember.computed('absencesPeriods.@each.label', function () {
      return Ember.String.htmlSafe(this.get('absencesPeriods').map(function (absencePeriod) {
        return absencePeriod.get('label');
      }).join(', '));
    }).readOnly(),
    resourceListLabel: Ember.computed('absencesPeriods.@each.{duration,startDate,endDate,workUnitType}', 'i18n._locale', function () {
      var total = Math.round(this.get('absencesPeriods').reduce(function (total, absencePeriod) {
        return total + absencePeriod.get('duration');
      }, 0) * 1000) / 1000;
      var wutLabels = [];
      var durationByWut = [];
      this.get('absencesPeriods').forEach(function (absencePeriod) {
        var workUnitTypeDuration = durationByWut.find(function (item) {
          return item.reference === absencePeriod.get('workUnitType.reference');
        });
        if (workUnitTypeDuration) {
          workUnitTypeDuration.value += absencePeriod.get('duration');
        } else {
          durationByWut.pushObject({
            reference: absencePeriod.get('workUnitType.reference'),
            title: absencePeriod.get('workUnitType.name'),
            value: absencePeriod.get('duration')
          });
        }
      });
      if (durationByWut.length === 1) {
        wutLabels.push(durationByWut[0].title);
      } else {
        durationByWut.forEach(function (durationWut) {
          wutLabels.push("".concat(durationWut.value, " ").concat(durationWut.title));
        });
      }
      return total > 0 ? "".concat(total, " ").concat(this.get('i18n').t('common:dateAndTime.days.title', {
        count: total
      }), " (").concat(wutLabels.join(', '), ")") : "".concat(total, " ").concat(this.get('i18n').t('common:dateAndTime.days.title', {
        count: total
      }));
    }).readOnly(),
    resourceListPeriods: Ember.computed('absencesPeriods.@each.{duration,startDate,endDate,workUnitType}', 'i18n._locale', function () {
      var periods = [];
      var foundPeriods = [];
      this.get('absencesPeriods').sortBy('startDate').forEach(function (absencePeriod) {
        var periodKey = absencePeriod.get('startDate').clone().format('YYYYMM');
        if (foundPeriods.indexOf(periodKey) === -1) {
          foundPeriods.push(periodKey);
        }
      });

      // sort array by key to have ascending dates order
      foundPeriods.sort();
      foundPeriods.forEach(function (period) {
        periods.push(Ember.String.capitalize((0, _moment.default)(period, 'YYYYMM').format('MMMM YYYY')));
      });
      return periods.join(' - ');
    }).readOnly(),
    completeLabel: Ember.computed('resourceListLabel', 'resourceListPeriods', function () {
      return "".concat(this.get('resourceListLabel'), " - ").concat(this.get('resourceListPeriods'));
    }).readOnly(),
    /**
     * Resource's validation (= first item of model.validations relationship array)
     *
     * @property resourceValidation
     * @type Ember.ObjectProxy
     */
    resourceValidation: Ember.computed('validations.[]', function () {
      var _this2 = this;
      var promise = this.get('validations').then(function (validations) {
        var resourceValidation;
        if (validations.get('length')) {
          resourceValidation = validations.get('firstObject');
        } else {
          // no validations so create first validation
          resourceValidation = _this2.get('resource').then(function (resource) {
            return _this2.get('store').createRecord('validation', {
              dependsOn: _this2,
              expectedValidator: resource
            });
          });
        }
        return resourceValidation;
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    /** Absences Periods Methods **********************************************/addAbsencesPeriod: function addAbsencesPeriod(absencesPeriod) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var absencesPeriods = this.get('absencesPeriods');
      switch (_typeof(absencesPeriod)) {
        case 'object':
          if ((0, _fragment.isFragment)(absencesPeriod)) {
            absencesPeriods.addFragment(makeAcopy ? (0, _emberCopy.copy)(absencesPeriod) : absencesPeriod);
          } else {
            absencesPeriods.createFragment(absencesPeriod);
          }
          break;
        default:
          _logger.default.error('Model AbsencesReport.addAbsencesPeriod - bad "absencesPeriod" arg: must be an object or an "absencesperiod" fragment!', absencesPeriod);
      }
    },
    delAbsencesPeriod: function delAbsencesPeriod(absencesPeriod) {
      if ((0, _fragment.isFragment)(absencesPeriod)) {
        this.get('absencesPeriods').removeFragment(absencesPeriod);
      } else {
        _logger.default.error('Model AbsencesReport.delAbsencesPeriod - bad "absencesPeriod" arg: must be an "absencesperiod" fragment!', absencesPeriod);
      }
    }
  });
  AbsencesReport.reopenClass({
    prefix: 'ABS'
  });
  AbsencesReport.reopen({
    prefix: Ember.computed(function () {
      return AbsencesReport.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AbsencesReport;
});