define("boondmanager/pods/components/bm-field/calendar/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    store: Ember.inject.service(),
    allowClear: false,
    options: Ember.A([]),
    canLaunchSearch: Ember.computed('input', function () {
      return this.get('input') && this.get('input').length >= 3;
    }),
    customSelected: null,
    _getCalendars: function _getCalendars(input, keywordsType) {
      var _this = this;
      var params = {
        keywords: input,
        keywordsType: keywordsType
      };
      return this.get('store').adapterFor('application').call('/calendars', 'GET', params).then(function (calendarsResponse) {
        var calendars = [];
        calendarsResponse.data.forEach(function (item) {
          calendars.push({
            id: item.iso,
            value: item.value
          });
          var subCalendars = Ember.get(item, 'subCalendars');
          if (subCalendars && Ember.isArray(subCalendars) && subCalendars.length) {
            subCalendars.forEach(function (subItem) {
              calendars.push({
                id: subItem.iso,
                value: item.value + ' - ' + subItem.value
              });
            });
          }
        });
        _this.set('options', calendars);
        return calendars;
      });
    },
    loadingMessage: Ember.computed('i18n._locale', 'input', function () {
      var i18n = this.get('i18n');
      return this.get('canLaunchSearch') ? i18n.t('components:bmFieldSearchItems.loadingMessage') : i18n.t('components:bmFieldSearchItems.searchMessage');
    }).readOnly(),
    noMatchesMessage: Ember.computed('i18n._locale', 'input', function () {
      var i18n = this.get('i18n');
      return this.get('canLaunchSearch') ? i18n.t('components:bmFieldSearchItems.noMatchesMessage') : i18n.t('components:bmFieldSearchItems.searchMessage');
    }),
    searchCalendars: Ember.computed('input', function () {
      var input = this.get('input');
      var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      return proxy.create({
        promise: this._getCalendars(input, 'name')
      });
    }),
    init: function init() {
      var _this2 = this;
      this._super.apply(this, arguments);
      var initialSelect = this.get('selected');
      if (!Ember.isBlank(initialSelect) && initialSelect !== null) {
        this._getCalendars(initialSelect, 'iso').then(function (calendars) {
          calendars.forEach(function (calendar) {
            if (calendar.id === initialSelect) {
              _this2.set('customSelected', calendar);
            }
          });
        });
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-calendar must have a "onChange" attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-calendar "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
    },
    actions: {
      searchCalendars: function searchCalendars(input) {
        this.set('input', input);
        if (this.get('canLaunchSearch')) {
          return this.get('searchCalendars');
        }
      },
      onchange: function onchange(value) {
        this.set('customSelected', value);
        this.get('onChange')(value === null ? value : value.id);
      }
    }
  });
});