define("boondmanager/pods/components/bm-field/nationalities/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Countries field component.
   *
   * * Depend on:
   *   - Boondmanager countries list in i18n files
   *   - FieldSelect component (in template)
   *   - Flag icons (https://www.flag-sprites.com/) - TODO use SVG icons
   *
   * Component attributes:
   *
   *   * {string}             name           REQUIRED field name
   *   * {string}             class          OPTIONAL additional classes applied to the main DOM element
   *   * {boolean}            useNationality OPTIONAL countries or nationality
   *   * {boolean}            disabled       OPTIONAL component state
   *   * {string}             size           OPTIONAL component Bootstrap size
   *   * {Array}              selection      OPTIONAL selected <option>s ID
   *   * {function}           onChange       OPTIONAL callback call on <option> selection
   *
   * Example:
   *
   * ```handlebars
   * {{bm-field/countries
   *     name="country"
   *     size=size
   *     disabled=disabled
   *     selection=model.country
   *     onChange=(action "changeCountry")
   * }}
   * ```
   *
   *
   * @class  BmFieldCountriesComponent
   * @extends Ember.Component
   * @namespace Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bm-field-nationalities'],
    /**
     * <select>'s <option>s list.
     *
     * @property options
     * @type {Array.<OptionHash>}
     * @default []
     * @public
     */
    options: Ember.computed('i18n._locale', function () {
      var nationalities = this.get('i18n').nationality;
      return Ember.isArray(nationalities) ? nationalities.map(function (nationality) {
        return {
          id: nationality.id,
          value: nationality.value,
          icon: "flag flag-".concat(nationality.iso.toLowerCase())
        };
      }) : [];
    }),
    /**
     * 'didReceiveAttrs' Component hook
     *
     * Check __name__ component attribute
     *
     * @method didReceiveAttrs
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert('bm-field-nationalities must have a "name" attribute!', !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert('bm-field-nationalities "name" attribute must be a string!', Ember.typeOf(name) === 'string'));
    }
  });
});