define("boondmanager/models/architecture", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Architecture = _base.default.extend({
    maintenanceDate: _emberData.default.attr('moment'),
    databaseServers: (0, _attributes.fragmentArray)('databaseserverfragment'),
    solrServers: (0, _attributes.fragmentArray)('solrserverfragment'),
    gedServers: (0, _attributes.fragmentArray)('gedserverfragment'),
    nodeServers: (0, _attributes.fragmentArray)('nodeserverfragment'),
    customersInMaintenance: (0, _attributes.array)('string')
  });
  Architecture.reopenClass({
    prefix: 'ARCH'
  });
  Architecture.reopen({
    prefix: Ember.computed(function () {
      return Architecture.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Architecture;
});