define("boondmanager/pods/components/bm-field/sandbox/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field_company'],
    classNameBindings: ['showLock:bmc-field-lock', 'isLocked:bmc-field-locked'],
    attributeBindings: ['dataName:data-name'],
    store: Ember.inject.service(),
    allowClear: false,
    showSelect: false,
    showLock: false,
    excludedCustomer: null,
    options: Ember.A([]),
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    isLocked: Ember.computed('showLock', 'disabled', 'showSelect', function () {
      return !this.get('showLock') && !this.get('disabled') || !this.get('showSelect');
    }),
    canLaunchSearch: Ember.computed('input', function () {
      return this.get('input') && this.get('input').length >= 3;
    }),
    _getCustomers: function _getCustomers(input) {
      var params = {
        keywords: input,
        subscriptionStates: ['active', 'demonstration'],
        dataTypes: 'sandbox'
      };
      return this.get('store').query('customer', params);
    },
    loadingMessage: Ember.computed('i18n._locale', 'input', function () {
      var i18n = this.get('i18n');
      return this.get('canLaunchSearch') ? i18n.t('components:bmFieldSearchItems.loadingMessage') : i18n.t('components:bmFieldSearchItems.searchMessage');
    }).readOnly(),
    noMatchesMessage: Ember.computed('i18n._locale', 'input', function () {
      var i18n = this.get('i18n');
      return this.get('canLaunchSearch') ? i18n.t('components:bmFieldSearchItems.noMatchesMessage') : i18n.t('components:bmFieldSearchItems.searchMessage');
    }),
    searchCustomers: Ember.computed('input', function () {
      var _this = this;
      var input = this.get('input');
      var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      return proxy.create({
        promise: this._getCustomers(input).then(function (companies) {
          return companies.filter(function (company) {
            return company.id !== _this.get('excludedCustomer');
          });
        })
      });
    }),
    init: function init() {
      var _this2 = this;
      this._super.apply(this, arguments);
      var initialSelect = this.get('initialSelect');
      if (!Ember.isBlank(initialSelect)) {
        if (this.get('multiple')) {
          initialSelect = Ember.isArray(initialSelect) ? initialSelect : [initialSelect];
          var input = initialSelect.map(function (id) {
            return "CUST".concat(id);
          }).join(' ');
          this._getCustomers(input).then(function (companies) {
            return _this2.get('onChange')(companies);
          });
        } else {
          initialSelect = Ember.isArray(initialSelect) && !Ember.isBlank(initialSelect[0]) ? initialSelect[0] : initialSelect;
          this.get('store').findRecord('customer', initialSelect).then(function (company) {
            return _this2.get('onChange')(company);
          });
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-sandbox must have a "onChange" attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-sandbox "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
    },
    actions: {
      toggleSelect: function toggleSelect() {
        if (!this.get('disabled')) {
          var onUnlock = this.get('onUnlock');
          this.toggleProperty('showSelect');
          if (this.get('showSelect') && typeof onUnlock === 'function') {
            onUnlock();
          }
        }
      },
      searchCustomers: function searchCustomers(input) {
        this.set('input', input);
        if (this.get('canLaunchSearch')) {
          return this.get('searchCustomers');
        }
      },
      onchange: function onchange(value) {
        this.set('showSelect', true);
        this.get('onChange')(value);
      }
    }
  });
});