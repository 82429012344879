define("boondmanager/transforms/date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Transform.extend({
    /* The deserialize function transforms the ISO8601 string it receives from
    backend into a Moment Object. */
    deserialize: function deserialize(serialized) {
      //TODO improve checking serialized
      if (serialized) {
        var result = (0, _moment.default)(serialized);
        if (_moment.default.isMoment(result) && result.isValid()) {
          return result;
        }
      }
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        if (_moment.default.isMoment(deserialized)) {
          return deserialized.format('YYYY-MM-DD');
        }
        return (0, _moment.default)(deserialized).format('YYYY-MM-DD');
      }
      return deserialized;
    }
  });
});