define("boondmanager/pods/components/bm-field/assignment/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/logger", "boondmanager/utils/string"], function (_exports, _component, _logger, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-assigment'],
    classNameBindings: ['thumbnail:bmc-field-assigment_thumbnail', 'headerIcon:bmc-field-assigment_thumbnail'],
    attributeBindings: ['dataName:data-name'],
    assignments: Ember.inject.service(),
    rights: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    optionLength: 0,
    module: null,
    required: true,
    initialSelectedValue: null,
    modelStateUpdated: false,
    idKeyname: 'id',
    valueKeyname: 'entityName',
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    assignment: Ember.computed('assignments', 'module', 'rights.globalLastUpdate', 'currentUser.lastupdate', function () {
      return this.get('assignments').getAssignment(this.get('module'));
    }),
    customOptions: Ember.computed('assignment.isFulfilled', 'selected.isFulfilled', 'modelStateUpdated', 'i18n._locale', function () {
      var _this = this;
      var opts = [];
      return Ember.RSVP.resolve(this.get('selected')).then(function (selected) {
        if (!_this.get('initialSelectedValue')) {
          _this.set('initialSelectedValue', selected);
        }
        if (_this.get('assignment.isFulfilled')) {
          var assignmentType = _this.get('assignmentType');
          (false && !(!Ember.isBlank(assignmentType)) && Ember.assert('bm-field-assignments must have a "assignmentType" attribute!', !Ember.isBlank(assignmentType)));
          var assignments = _this.get("assignment.".concat(assignmentType));
          if (Ember.isArray(assignments)) {
            opts = assignments.slice(0); // used of slice to copy the array
          } else {
            _logger.default.warn("[bm-field-assignments - ".concat(_this.get('name'), "] - customOptions CP -  this.get('assignment.").concat(assignmentType, "') is not an array!"), assignments);
          }
        }
        function rebuildOptions(options) {
          for (var i = 0; i < options.length; i++) {
            if (options[i].option) {
              options[i].options = Ember.A(rebuildOptions(options[i].option));
            }
          }
          return options;
        }
        opts = Ember.A(rebuildOptions(opts));
        _this.set('optionLength', opts.length);
        _logger.default.debug("[".concat(_this.get('name'), "] populate with options"), opts);

        // si l'élément séléctionné n'est pas dans la liste assignement, on le rajoute à nos options.
        if (selected) {
          if (_this.get('multiple')) {
            _this.get('initialSelectedValue').filter(function (item) {
              return !opts.findBy('id', Ember.get(item, 'id'));
            }).forEach(function (item) {
              return opts.push(item);
            });
          } else if (!opts.findBy('id', Ember.get(_this.get('initialSelectedValue'), 'id'))) {
            opts.push(_this.get('initialSelectedValue'));
          }
        }
        return opts;
      });
    }).readOnly(),
    /**
     * Selected object from custom options array
     *
     * Ember Power Select `selected` attribut. Works well with a promise.
     *
     * @property selectedOption
     * @observes customOptions, selected, multiple
     * @type {Promise} resolve to an array of objects if mulirple = true, an object alone else.
     */
    selectedOption: Ember.computed('customOptions.[]', 'selected', 'selected.[]', 'multiple', function () {
      var _this2 = this;
      // on ne sait pas si `selected` est une Promesse (par ex. une relationship), d'où l'utilisation
      // de la méthode `resolve`.
      return Ember.RSVP.resolve(this.get('selected')).then(function (selected) {
        return _this2.get('customOptions').then(function (options) {
          if (Ember.typeOf(selected) === 'instance') {
            var selectedOption;
            if (_this2.get('multiple')) {
              selectedOption = options.filter(function (option) {
                return selected.mapBy('id').indexOf(Ember.get(option, 'id')) > -1;
              });
            } else {
              selectedOption = options.find(function (option) {
                return Ember.get(option, 'id') === Ember.get(selected, 'id');
              });
            }
            return selectedOption;
          } else {
            return null;
          }
        });
      });
    }).readOnly(),
    searchEnabled: Ember.computed('optionLength', function () {
      return this.get('optionLength') >= 10;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this3 = this;
      var name = this.get('name');
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check name
      (false && !(!Ember.isBlank(name)) && Ember.assert('bm-field-assignments must have a "name" attribute!', !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert('bm-field-assignments "name" attribute must be a string!', Ember.typeOf(name) === 'string')); // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-assignments must have a "onChange" attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-assignments "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
      Ember.RSVP.resolve(this.get('selected')).then(function (selected) {
        if (selected) {
          var valueUpdated = false;
          if (_this3.get('multiple')) {
            valueUpdated = selected.filter(function (item) {
              return !_this3.get('initialSelectedValue') || !_this3.get('initialSelectedValue').findBy('id', Ember.get(item, 'id'));
            }).length !== 0;
          } else {
            // Fix made for BMLABS-10603, unknown method to reproduce bug
            var initialValue = _this3.get('initialSelectedValue');
            var initialValueExists = typeof initialValue !== 'undefined' && initialValue !== null;
            valueUpdated = initialValueExists && _this3.get('initialSelectedValue').get('id') !== selected.get('id') || !initialValueExists;
          }
          if (valueUpdated && !(_this3.get('isDestroyed') || _this3.get('isDestroying'))) {
            _this3.set('initialSelectedValue', selected);
            _this3.toggleProperty('modelStateUpdated');
          }
        }
      });
    },
    assignmentMatcher: function assignmentMatcher(item, term) {
      // Posibilité d'évolution : faire une recherche par regex
      // Ne pas chercher dans l'id 0 (valeur vide ajoutée manuellement)
      if (item.id) {
        var value;
        var search = (0, _string.removeDiacritics)(term).toLowerCase();
        if (item.get('firstName') || item.get('lastName')) {
          value = (0, _string.removeDiacritics)("".concat(item.get('firstName'), " ").concat(item.get('lastName'))).toLowerCase();
        } else {
          value = (0, _string.removeDiacritics)(item.get('name')).toLowerCase();
        }
        return value.indexOf(search);
      }
    },
    actions: {
      onChangeAssignment: function onChangeAssignment(values) {
        this.get('onChange')(values);
      }
    }
  });
});