define("boondmanager/helpers/in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    /**
     * @private
     * Find and outputs translated text.
     *
     * @param {Array} params - positional parameters passed to the helper.
     *   The 1st element may be the currentWhen
     * @return {Boolean}
     */
    compute: function compute(params) {
      var arr = params[0];
      var key = params.length > 1 ? params[1] : null;
      if (Ember.isArray(arr)) {
        return arr.includes(key);
      }
      return false;
    }
  });
});