define("boondmanager/pods/components/bm-field/email/multiple/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/utils/forms"], function (_exports, _component, _forms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

  /**
   * Component BM Field Positioning.
   *
   * Display a searchable field for positionings
   *
   * Component attributes:
   *
   *   * {string}             name            REQUIRED field name
   *   * {boolean}            onlyFullVisible OPTIONAL filter the positioning list with item having a dependsOn and a contact and a company on opportunity. default: false
   *   * {function}           onChange        OPTIONAL callback call on period change
   *
   *
   * @class  BmFieldPositioning
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-email-multiple'],
    store: Ember.inject.service(),
    disabled: false,
    allowClear: true,
    displayNbSelected: true,
    options: Ember.computed('input', function () {
      var input = this.get('input');
      var promises = {
        resources: this.get('store').query('resource', {
          keywords: input
        }),
        contacts: this.get('store').query('contact', {
          keywords: input
        })
      };
      var promise = Ember.RSVP.hash(promises).then(function (_ref) {
        var resources = _ref.resources,
          contacts = _ref.contacts;
        var emails = resources.toArray().filter(function (resource) {
          return resource.get('email1') !== '';
        }).map(function (resource) {
          return resource.get('email1');
        });
        emails = emails.concat(contacts.toArray().filter(function (contact) {
          return contact.get('email1') !== '';
        }).map(function (contact) {
          return contact.get('email1');
        }));
        if ((0, _forms.validateEmail)(input)) {
          emails.unshift(input);
        }
        return emails;
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      var onChange = this.get('onChange');
      var disabled = this.get('disabled');

      // check onChange
      (false && !(!Ember.isBlank(onChange) || disabled) && Ember.assert('bm-field-guests must have a "onChange" attribute!', !Ember.isBlank(onChange) || disabled));
      (false && !(Ember.typeOf(onChange) === 'function' || disabled) && Ember.assert('bm-field-guests "onChange" attribute must be a function!', Ember.typeOf(onChange) === 'function' || disabled));
    },
    isBlankValue: function isBlankValue() {
      return this.get('selected') === null || typeof this.get('selected') === 'undefined' || Array.isArray(this.get('selected')) && this.get('selected').length === 0;
    },
    actions: {
      searchEmails: function searchEmails(input) {
        this.set('input', input);
        return this.get('options').then(function (options) {
          return options;
        }, function () {
          return [input];
        });
      },
      onchange: function onchange(value) {
        this.get('onChange')(value);
      }
    }
  });
});