define("boondmanager/initializers/activity", ["exports", "boondmanager/utils/activity"], function (_exports, _activity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('bm:activity', _activity.default, {
      singleton: false
    });
  }
  var _default = _exports.default = {
    name: 'activity',
    initialize: initialize
  };
});