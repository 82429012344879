define("boondmanager/models/positioning", ["exports", "ember-inflector", "ember-copy", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "boondmanager/utils/logger", "ember-data-copyable", "moment", "boondmanager/models/additionalturnoverandcostsfragment"], function (_exports, _emberInflector, _emberCopy, _base, _emberData, _attributes, _fragment, _logger, _emberDataCopyable, _moment, _additionalturnoverandcostsfragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_WON = _exports.STATE_WAITING = _exports.STATE_REJECTED = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var STATE_WAITING = _exports.STATE_WAITING = 0;
  var STATE_REJECTED = _exports.STATE_REJECTED = 1;
  var STATE_WON = _exports.STATE_WON = 2;
  var Positioning = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['candidate', 'dependsOn', 'mainManager', 'opportunity', 'product', 'project', 'resource']
    }),
    averageDailyCost: _emberData.default.attr('number'),
    averageDailyPriceExcludingTax: _emberData.default.attr('number'),
    // TAB_POSITIONNEMENT.POS_TARIF
    comments: _emberData.default.attr('string'),
    costsSimulatedExcludingTax: _emberData.default.attr('number'),
    creationDate: _emberData.default.attr('moment'),
    endDate: _emberData.default.attr('date'),
    includedInSimulation: _emberData.default.attr('boolean'),
    // TAB_POSITIONNEMENT.POS_CORRELATION
    informationComments: _emberData.default.attr('string'),
    marginSimulatedExcludingTax: _emberData.default.attr('number'),
    numberOfDaysFree: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numberOfDaysInvoicedOrQuantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // TAB_POSITIONNEMENT.POS_NBJRSFACTURE
    occupationRate: _emberData.default.attr('number'),
    profitabilitySimulated: _emberData.default.attr('number'),
    startDate: _emberData.default.attr('date'),
    state: _emberData.default.attr('number'),
    stateReason: (0, _attributes.fragment)('statereason'),
    turnoverSimulatedExcludingTax: _emberData.default.attr('number'),
    updateDate: _emberData.default.attr('moment'),
    // FRAGMENTS
    additionalTurnoverAndCosts: (0, _attributes.fragmentArray)('additionalturnoverandcostsfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // RELATIONSHIPS
    candidate: _emberData.default.belongsTo(),
    dependsOn: _emberData.default.belongsTo('base'),
    files: _emberData.default.hasMany('documents'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    opportunity: _emberData.default.belongsTo(),
    product: _emberData.default.belongsTo(),
    project: _emberData.default.belongsTo(),
    resource: _emberData.default.belongsTo(),
    // Opportunity Actions (previous & next)
    previousAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    nextAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    //rights
    canReadPositioning: _emberData.default.attr('boolean'),
    canWritePositioning: _emberData.default.attr('boolean'),
    canShowDependsOn: _emberData.default.attr('boolean'),
    canShowOpportunity: _emberData.default.attr('boolean'),
    canShowCreationDate: _emberData.default.attr('boolean'),
    canShowUpdateDate: _emberData.default.attr('boolean'),
    canShowState: _emberData.default.attr('boolean'),
    canShowStartDate: _emberData.default.attr('boolean'),
    canShowEndDate: _emberData.default.attr('boolean'),
    canShowInformationComments: _emberData.default.attr('boolean'),
    reportingClass: Ember.computed('id', function () {
      var promise = Ember.RSVP.Promise.resolve('positioning');
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    averageWorkUnitPriceExcludingTax: Ember.computed('averageDailyPriceExcludingTax', 'opportunity.workUnitRate', {
      get: function get() {
        return this.get('opportunity.workUnitRate') ? this.get('averageDailyPriceExcludingTax') / this.get('opportunity.workUnitRate') : this.get('averageDailyPriceExcludingTax');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model positioning - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('averageDailyPriceExcludingTax', value * this.get('opportunity.workUnitRate'));
        }
        return value;
      }
    }),
    numberOfWorkUnitsInvoicedOrQuantity: Ember.computed('numberOfDaysInvoicedOrQuantity', 'opportunity.workUnitRate', {
      get: function get() {
        return this.get('numberOfDaysInvoicedOrQuantity') * this.get('opportunity.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model positioning - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('numberOfDaysInvoicedOrQuantity', this.get('opportunity.workUnitRate') ? value / this.get('opportunity.workUnitRate') : value);
        }
        return value;
      }
    }),
    numberOfWorkUnitsFree: Ember.computed('numberOfDaysFree', 'opportunity.workUnitRate', {
      get: function get() {
        return this.get('numberOfDaysFree') * this.get('opportunity.workUnitRate');
      },
      set: function set(_, newValue, oldValue) {
        var value = parseFloat(newValue);
        if (isNaN(value)) {
          _logger.default.error('Model positioning - SET value - newValue is NaN!', newValue);
          value = oldValue;
        } else {
          this.set('numberOfDaysFree', this.get('opportunity.workUnitRate') ? value / this.get('opportunity.workUnitRate') : value);
        }
        return value;
      }
    }),
    // METHODS
    // - Manage additionalTurnoverAndCosts fragments
    addTurnoverAndCosts: function addTurnoverAndCosts(turnoverAndCosts) {
      var makeAcopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var additionalTurnoverAndCosts = this.get('additionalTurnoverAndCosts');
      switch (_typeof(turnoverAndCosts)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(turnoverAndCosts)) {
              additionalTurnoverAndCosts.addFragment(makeAcopy ? (0, _emberCopy.copy)(turnoverAndCosts) : turnoverAndCosts);
            } else {
              additionalTurnoverAndCosts.createFragment(turnoverAndCosts);
            }
            break;
          }
        default:
          {
            // get last additional turnover and cost typeof if exists
            var defaultTypeOf = _additionalturnoverandcostsfragment.INDIFFERENT_TYPEOF_ADDITIONAL_TURNOVER;
            if (additionalTurnoverAndCosts && additionalTurnoverAndCosts.lastObject) {
              defaultTypeOf = additionalTurnoverAndCosts.lastObject.typeOf;
            }
            additionalTurnoverAndCosts.createFragment({
              typeOf: defaultTypeOf
            });
          }
      }
    },
    delTurnoverAndCosts: function delTurnoverAndCosts(turnoverAndCosts) {
      if ((0, _fragment.isFragment)(turnoverAndCosts)) {
        this.get('additionalTurnoverAndCosts').removeFragment(turnoverAndCosts);
      } else {
        _logger.default.warn("Project#".concat(this.get('id'), ":delTurnoverAndCosts() - turnoverAndCosts is not a fragment !"), turnoverAndCosts);
      }
    },
    //dependsOnProduct  : Ember.computed('dependsOn.modelName', 'product'),
    dependsOnProduct: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'product';
    }),
    //dependsOnCandidate: Ember.computed.equal('dependsOn.modelName', 'candidate'),
    dependsOnCandidate: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'candidate';
    }),
    //dependsOnResource : Ember.computed.equal('dependsOn.modelName', 'resource'),
    dependsOnResource: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'resource';
    }),
    dependsOnPlural: Ember.computed('dependsOn', function () {
      return (0, _emberInflector.pluralize)(this.get('dependsOn.modelName'));
    }),
    dependsOnLinkTo: Ember.computed('dependsOn', function () {
      return "private.".concat(this.get('dependsOnPlural'), ".").concat(this.get('dependsOn.modelName'));
    }),
    lengthInDays: Ember.computed('startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate') || (0, _moment.default)();
      return _moment.default.duration(endDate.diff(startDate)).asDays();
    }),
    subscriptionPeriods: Ember.computed('dependsOn.isFulfilled', 'startDate', 'endDate', function () {
      var _this = this;
      var promise = this.get('dependsOn').then(function (product) {
        var subscription = product.get('subscription');
        var startDate = _this.get('startDate');
        var endDate = _this.get('endDate');
        var monthes = 0;
        if (endDate && startDate && endDate.isAfter(startDate)) {
          monthes = endDate.month() - startDate.month() + 1 + 12 * (endDate.year() - startDate.year());
        }
        var result;
        switch (subscription) {
          case 0:
            {
              // Unitaire
              result = 0;
              break;
            }
          case 1:
            {
              // Mensuel
              result = monthes;
              break;
            }
          case 2:
            {
              // Trimestriel
              result = Math.ceil(monthes / 3);
              break;
            }
          case 3:
            {
              // Semestriel
              result = Math.ceil(monthes / 6);
              break;
            }
          case 4:
            {
              // Annuel
              result = Math.ceil(monthes / 12);
              break;
            }
        }
        return {
          value: result
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    periodsLabel: Ember.computed('dependsOn.{isFulfilled,subscription}', 'i18n._locale', function () {
      var promise = this.get('dependsOn').then(function (product) {
        var subscription = product.get('subscription');
        var result;
        switch (subscription) {
          case 0:
            {
              // Unitaire
              result = '';
              break;
            }
          case 1:
            {
              // Mensuel
              result = 'purchase.information.periodsLabel.month';
              break;
            }
          case 2:
            {
              // Trimestriel
              result = 'purchase.information.periodsLabel.quarter';
              break;
            }
          case 3:
            {
              // Semestriel
              result = 'purchase.information.periodsLabel.semester';
              break;
            }
          case 4:
            {
              // Annuel
              result = 'purchase.information.periodsLabel.year';
              break;
            }
        }
        return {
          value: result
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    reportingTooltip: Ember.computed('opportunity', 'creationDate', 'startDate', 'endDate', 'occupationRate', function () {
      var i18n = this.get('i18n');
      var state = this.get('state');
      var startDate = this.get('startDate') ? this.get('startDate').format('DD/MM/YY') : '';
      var endDate = this.get('endDate') ? this.get('endDate').format('DD/MM/YY') : '';
      var positioningState = this.get('settingsService').customer.state.positioning.find(function (item) {
        return item.id === state;
      }).value;
      var opportunity = this.get('opportunity');
      var opportunityDuration = this.get('settingsService').customer.duration.find(function (item) {
        return item.id === opportunity.get('duration');
      }).value;
      return '<dl class="periodInfoTooltip">' + "<dt>".concat(i18n.t('models:positioning.attributes.creationDate.abbr'), "</dt><dd>").concat(this.get('creationDate').format('DD/MM/YY - HH:mm'), "</dd>") + "<dt>".concat(i18n.t('models:positioning.attributes.startDate.abbr'), " - ").concat(i18n.t('models:positioning.attributes.endDate.name'), " </dt>") + "<dd>".concat(startDate, " - ").concat(endDate, "</dd>") + "<dt>".concat(i18n.t('models:positioning.attributes.state.name'), "</dt><dd>").concat(positioningState, "</dd>") + "<dt>".concat(i18n.t('models:opportunity.attributes.title.name'), "</dt><dd>").concat(opportunity.get('title'), "</dd>") + "<dt>".concat(i18n.t('models:opportunity.attributes.duration.name'), "</dt><dd>").concat(opportunityDuration, "</dd>") + "<dt>".concat(i18n.t('models:positioning.attributes.occupationRate.name'), "</dt><dd>").concat(Math.round(this.get('occupationRate')), "%</dd>") + "<dt>".concat(i18n.t('models:positioning.attributes.mainManager.abbr'), "</dt><dd>").concat(opportunity.get('mainManager.entityName'), "</dd>") + "<dt>".concat(i18n.t('models:opportunity.attributes.customer.name'), "</dt><dd>").concat(opportunity.get('contact.entityName'), "<br>").concat(opportunity.get('company.entityName'), "</dd>") + '</dl>';
    }),
    reportingCreatedTooltip: Ember.computed('opportunity', 'creationDate', 'startDate', 'endDate', 'occupationRate', function () {
      var i18n = this.get('i18n');
      var state = this.get('state');
      var positioningState = this.get('settingsService').customer.state.positioning.find(function (item) {
        return item.id === state;
      }).value;
      var opportunity = this.get('opportunity');
      var opportunityDuration = this.get('settingsService').customer.duration.find(function (item) {
        return item.id === opportunity.get('duration');
      }).value;
      return '<dl class="periodInfoTooltip">' + "<dt>".concat(i18n.t('models:positioning.attributes.creationDate.abbr'), "</dt><dd>").concat(this.get('creationDate').format('DD/MM/YY - HH:mm'), "</dd>") + "<dt>".concat(i18n.t('models:positioning.attributes.state.name'), "</dt><dd>").concat(positioningState, "</dd>") + "<dt>".concat(i18n.t('models:opportunity.attributes.title.name'), "</dt><dd>").concat(opportunity.get('title'), "</dd>") + "<dt>".concat(i18n.t('models:opportunity.attributes.duration.name'), "</dt><dd>").concat(opportunityDuration, "</dd>") + "<dt>".concat(i18n.t('models:positioning.attributes.occupationRate.name'), "</dt><dd>").concat(Math.round(this.get('occupationRate')), "%</dd>") + "<dt>".concat(i18n.t('models:positioning.attributes.mainManager.abbr'), "</dt><dd>").concat(opportunity.get('mainManager.entityName'), "</dd>") + "<dt>".concat(i18n.t('models:opportunity.attributes.customer.name'), "</dt><dd>").concat(opportunity.get('contact.entityName'), "<br>").concat(opportunity.get('company.entityName'), "</dd>") + '</dl>';
    }),
    /**
     * Sum of all additional turnovers (manual sum instead of Ember.computed.sum to allow undefined filtering)
     *
     * @property additionalTurnoverTotal
     * @type {Number}
     */
    additionalTurnoverTotal: Ember.computed('additionalTurnoverAndCosts.{[],@each.turnoverExcludingTax}', function () {
      return this.get('additionalTurnoverAndCosts').mapBy('turnoverExcludingTax').filter(function (item) {
        return item;
      }).reduce(function (prevItem, item) {
        return prevItem + Number(item);
      }, 0);
    }),
    turnoverSimulatedExcludingTaxCalculated: Ember.computed('numberOfDaysInvoicedOrQuantity', 'averageDailyPriceExcludingTax', 'additionalTurnoverTotal', function () {
      return this.get('numberOfDaysInvoicedOrQuantity') * this.get('averageDailyPriceExcludingTax') + this.get('additionalTurnoverTotal');
    }).readOnly(),
    costsSimulatedExcludingTaxCalculated: Ember.computed('numberOfDaysInvoicedOrQuantity', 'numberOfDaysFree', 'averageDailyCost', 'additionalCostsTotal', function () {
      return (this.get('numberOfDaysInvoicedOrQuantity') + this.get('numberOfDaysFree')) * this.get('averageDailyCost') + this.get('additionalCostsTotal');
    }).readOnly(),
    marginSimulatedExcludingTaxCalculated: Ember.computed('turnoverSimulatedExcludingTaxCalculated', 'costsSimulatedExcludingTaxCalculated', function () {
      return this.get('turnoverSimulatedExcludingTaxCalculated') - this.get('costsSimulatedExcludingTaxCalculated');
    }).readOnly(),
    profitabilitySimulatedCalculated: Ember.computed('isProfitabilityCalculatingBasedOnMarginRate', 'marginSimulatedExcludingTaxCalculated', 'costsSimulatedExcludingTaxCalculated', 'turnoverSimulatedExcludingTaxCalculated', function () {
      var value;
      var margin = this.get('marginSimulatedExcludingTaxCalculated');
      if (this.get('isProfitabilityCalculatingBasedOnMarginRate')) {
        var cost = this.get('costsSimulatedExcludingTaxCalculated');
        value = Math.round(100000 * cost) ? Math.round(10000 * margin / cost) / 100 : 0;
      } else {
        var turnover = this.get('turnoverSimulatedExcludingTaxCalculated');
        value = Math.round(100000 * turnover) ? Math.round(10000 * (margin / turnover)) / 100 : 0;
      }
      return value;
    }).readOnly(),
    /**
     * Sum of all additional costs (manual sum instead of Ember.computed.sum to allow undefined filtering)
     *
     * @property additionalCostsTotal
     * @type {Number}
     */
    additionalCostsTotal: Ember.computed('additionalTurnoverAndCosts.{[],@each.costsExcludingTax}', function () {
      return this.get('additionalTurnoverAndCosts').mapBy('costsExcludingTax').filter(function (item) {
        return item;
      }).reduce(function (prevItem, item) {
        return prevItem + Number(item);
      }, 0);
    }),
    numberOfDaysTotal: Ember.computed('numberOfDaysInvoicedOrQuantity', 'numberOfDaysFree', function () {
      return this.get('numberOfDaysInvoicedOrQuantity') + this.get('numberOfDaysFree');
    }),
    numberOfDaysTotalInWorkUnits: Ember.computed('numberOfWorkUnitsInvoicedOrQuantity', 'numberOfWorkUnitsFree', function () {
      return this.get('numberOfWorkUnitsInvoicedOrQuantity') + this.get('numberOfWorkUnitsFree');
    }),
    stateReasonLabel: Ember.computed('state', 'stateReason.typeOf', function () {
      var _this2 = this;
      var currentState = this.get('settingsService').customer.state.positioning.find(function (item) {
        return item.id === _this2.get('state');
      });
      var foundState = currentState.reason.find(function (item) {
        return item.id === _this2.get('stateReason.typeOf');
      });
      return foundState ? foundState.value : '';
    }),
    isProfitabilityCalculatingBasedOnMarginRate: Ember.computed(function () {
      var profitability = this.get('settingsService').customer.profitabilityMethodOfCalculating;
      return profitability === 'marginRate';
    }).readOnly(),
    hasPeriod: Ember.computed('startDate', 'endDate', function () {
      return this.get('startDate') && this.get('endDate');
    }),
    labelStateSelected: Ember.computed('state', 'i18n._locale', function () {
      var dictionnary = this.get('settingsService').customer.state.positioning;
      var stateId = this.get('state');
      return dictionnary.find(function (item) {
        return item.id === stateId;
      }).value;
    }),
    isWon: Ember.computed('state', function () {
      return this.get('state') === STATE_WON;
    }),
    /**
     * Used to avoid blinking state if there's an error while saving
     */
    frozenState: Ember.computed('hasDirtyAttributes', 'state', function () {
      var changedAttributes = this.changedAttributes();
      return this.get('hasDirtyAttributes') && changedAttributes.state ? changedAttributes.state[0] : this.get('state');
    }),
    isAverageDailyPriceExcludingTaxShown: Ember.computed('averageDailyPriceExcludingTax', function () {
      return this.get('averageDailyPriceExcludingTax') !== undefined;
    })
  });
  Positioning.reopenClass({
    prefix: 'POS'
  });
  Positioning.reopen({
    prefix: Ember.computed(function () {
      return Positioning.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Positioning;
});