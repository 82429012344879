define("boondmanager/models/providerinvoice", ["exports", "boondmanager/models/base", "ember-data-copyable", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberDataCopyable, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_WAITING = _exports.STATE_VALIDATED = _exports.STATE_REJECTED = _exports.STATE_PAID = _exports.STATE_DRAFT = _exports.STATES = void 0;
  var STATE_DRAFT = _exports.STATE_DRAFT = 0;
  var STATE_WAITING = _exports.STATE_WAITING = 1;
  var STATE_VALIDATED = _exports.STATE_VALIDATED = 2;
  var STATE_REJECTED = _exports.STATE_REJECTED = 3;
  var STATE_PAID = _exports.STATE_PAID = 4;
  var STATES = _exports.STATES = [STATE_DRAFT, STATE_WAITING, STATE_VALIDATED, STATE_REJECTED, STATE_PAID];
  var ProviderInvoice = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['resource', 'agency', 'mainManager', 'pole', 'document']
    }),
    settingsService: Ember.inject.service('settings'),
    stateClassModelName: 'providerinvoice',
    //tmpFile uploaded before creation and to pass at getNew
    tmpFile: _emberData.default.attr('string'),
    state: _emberData.default.attr('number'),
    amountExcludingTax: _emberData.default.attr('number'),
    amountIncludingTax: _emberData.default.attr('number'),
    currency: _emberData.default.attr('number'),
    exchangeRate: _emberData.default.attr('number'),
    invoiceDate: _emberData.default.attr('date'),
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    paidDate: _emberData.default.attr('date'),
    reference: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('date'),
    updateDate: _emberData.default.attr('date'),
    currencyAgency: _emberData.default.attr('number'),
    exchangeRateAgency: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    payments: (0, _attributes.fragmentArray)('paymentfragment'),
    // Relationships
    resource: _emberData.default.belongsTo(),
    agency: _emberData.default.belongsTo(),
    document: _emberData.default.belongsTo('providerinvoicedocument'),
    mainManager: _emberData.default.belongsTo('resource'),
    pole: _emberData.default.belongsTo(),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    toggleCurrency: Ember.computed('currency', 'currencyAgency', {
      get: function get() {
        return this.get('currency') !== this.get('currencyAgency');
      },
      set: function set(name, value) {
        return value;
      }
    }),
    currencyLabel: Ember.computed('currency', function () {
      var currencyID = this.get('currency');
      var currencies = this.get('settingsService').customer.currency;
      return currencies.find(function (currency) {
        return currency.id === currencyID;
      });
    }).readOnly(),
    entityName: Ember.computed('reference', function () {
      var reference = this.get('reference');
      if (Ember.isBlank(reference)) {
        return this.get('internalReference');
      } else {
        return reference;
      }
    })
  });
  ProviderInvoice.reopenClass({
    prefix: 'PROINV'
  });
  ProviderInvoice.reopen({
    prefix: Ember.computed(function () {
      return ProviderInvoice.prefix;
    }).readOnly()
  });
  var _default = _exports.default = ProviderInvoice;
});