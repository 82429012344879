define("boondmanager/modifiers/click-outside", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * Modifier to check if a click is inside or outside an element
   * We must double-check with composedPath because DOM can change with an onClick modifier and previous test return false
   * Usage : {{click-outside this.handler}}
   * @link https://developer.mozilla.org/en-US/docs/Web/API/Event/composedPath
   */
  var _default = _exports.default = (0, _emberModifier.modifier)(function (element) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [undefined, false],
      _ref2 = _slicedToArray(_ref, 2),
      outsideClickHandler = _ref2[0],
      useCapture = _ref2[1];
    // La gestion du touchstart/end ne semble pas nécessaire. Elle cause plus de problème qu'autre chose
    var eventName = 'click';
    if (typeof outsideClickHandler !== 'function') {
      throw new Error('the handler given to {{click-outside}} modifier must be a function');
    }
    var handler = function handler(event) {
      /**
       * Should i call outsideClickHandler ?
       * @type {boolean}
       */
      var callHandler = false;
      /**
       * Check if element is same as event.target or in element contains event.target
       * @type {boolean}
       */
      var isClickOutside = event.target !== element && !element.contains(event.target);
      if (isClickOutside) {
        /**
         * Check if element was in event.composedPath at click event
         * @type {boolean}
         */
        var isClickOutsideComposed = event.composedPath().filter(function (composePathElement) {
          return composePathElement === element;
        }).length === 0;
        if (isClickOutsideComposed) {
          callHandler = true;
        }
      }
      if (!callHandler) {
        return;
      }
      outsideClickHandler(event);
    };
    document.addEventListener(eventName, handler, useCapture);
    return function () {
      document.removeEventListener(eventName, handler, useCapture);
    };
  });
});