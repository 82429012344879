define("boondmanager/pods/components/bm-field/rate-per-kilometer-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1nS/ooza",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,9],null,[[\"name\",\"options\",\"selected\",\"useObjects\",\"idKeyname\",\"onchange\",\"disabled\"],[[35,8],[35,7],[35,6],true,\"id\",[30,[36,5],[[32,0],\"customOnChange\"],null],[35,4]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[1,[32,1,[\"name\"]]],[2,\" - \"],[1,[30,[36,2],[[32,1,[\"amount\"]]],[[\"sign\",\"precision\",\"change\"],[[35,1],[35,0],[32,1,[\"exchangeRate\"]]]]]],[2,\"/\"],[1,[30,[36,3],[\"components:bmFieldRatePerKilometerType.kilometers.abbr\"],null]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"precision\",\"sign\",\"money\",\"t\",\"disabled\",\"action\",\"selected\",\"options\",\"name\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/rate-per-kilometer-type/template.hbs"
  });
});