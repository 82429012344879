define("boondmanager/models/esignature", ["exports", "ember-data", "ember-data-copyable", "boondmanager/models/base", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataCopyable, _base, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NB_MAX_SIGN = _exports.ESIGNATURE_STATE_WAITING = _exports.ESIGNATURE_STATE_SIGNED = _exports.ESIGNATURE_STATE_CREATED = void 0;
  var NB_MAX_SIGN = _exports.NB_MAX_SIGN = 4;
  var ESIGNATURE_STATE_CREATED = _exports.ESIGNATURE_STATE_CREATED = 'created';
  var ESIGNATURE_STATE_WAITING = _exports.ESIGNATURE_STATE_WAITING = 'waiting';
  var ESIGNATURE_STATE_SIGNED = _exports.ESIGNATURE_STATE_SIGNED = 'signed';
  var ESignature = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['dependsOn', 'file']
    }),
    typeOf: _emberData.default.attr('string'),
    attachment: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    date: _emberData.default.attr('moment'),
    credits: _emberData.default.attr('number'),
    creditsUsed: _emberData.default.attr('number'),
    recipients: (0, _attributes.fragmentArray)('esignaturerecipientfragment'),
    /**
     * dependsOn
     * @type {Candidate|Resource|Contract|Opportunity}
     */
    dependsOn: _emberData.default.belongsTo('base'),
    /**
     * file
     * @type {File|Document}
     */
    file: _emberData.default.belongsTo('file'),
    creditsAvailable: Ember.computed('credits', 'creditsUsed', function () {
      return this.get('credits') - this.get('creditsUsed');
    }).readOnly()
  });
  ESignature.reopenClass({
    prefix: 'ESIGN'
  });
  ESignature.reopen({
    prefix: Ember.computed(function () {
      return ESignature.prefix;
    }).readOnly()
  });
  var _default = _exports.default = ESignature;
});