define("boondmanager/models/absence", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    endDate: _emberData.default.attr('date'),
    // TAB_PABSENCE.PABS_FIN
    startDate: _emberData.default.attr('date'),
    // TAB_PABSENCE.PABS_DEBUT
    workUnitType: (0, _attributes.fragment)('workunittype'),
    label: Ember.computed('startDate', 'endDate', 'workUnitType', function () {
      var elements = [];
      elements.push(this.get('workUnitType').name);
      elements.push(this.get('startDate').format('L'));
      elements.push(this.get('endDate').format('L'));
      return elements.join(' - ');
    })
  });
});