define("boondmanager/pods/components/bm-field/company/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field_company'],
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    searchPrefix: 'CSOC',
    context: 'company',
    excludeCompanies: null,
    // Liste d'id a exclure du résultat
    currentCompanyId: null,
    displayNbSelected: true,
    _getItems: function _getItems(input) {
      var _this = this;
      var params = {
        keywords: input,
        keywordsType: 'name'
      };
      return this.get('store').query('company', params).then(function (companies) {
        return companies.filter(function (company) {
          return (!Ember.isArray(_this.get('excludeCompanies')) || !_this.get('excludeCompanies').includes(company.id)) && _this.get('currentCompanyId') != company.id;
        });
      });
    }
  });
});