define("boondmanager/pods/components/bm-field/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jnKVflGW",
    "block": "{\"symbols\":[\"@fitContent\",\"@value\",\"@type\",\"@placeholder\",\"@autocomplete\",\"&attrs\",\"@key-up\",\"@key-down\",\"@enter\",\"@maxFitLength\",\"@checked\"],\"statements\":[[6,[37,1],[[32,0,[\"isCheckbox\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[8,\"input\",[[16,0,[32,0,[\"inputClass\"]]],[16,\"placeholder\",[34,6]],[16,\"autocomplete\",[34,7]],[16,\"required\",[34,2]],[16,\"disabled\",[34,3]],[17,6],[16,1,[32,0,[\"inputId\"]]]],[[\"@checked\",\"@type\",\"@focusIn\",\"@focusOut\",\"@key-up\",\"@key-down\",\"@enter\"],[[32,11],\"checkbox\",[30,[36,4],[[32,0],\"focusIn\"],null],[30,[36,4],[[32,0],\"focusOut\"],null],[32,7],[32,8],[32,9]]],null]],\"parameters\":[]},{\"statements\":[[8,\"input\",[[16,0,[30,[36,1],[[32,1],[30,[36,0],[\"bm-input_fitcontent \",[32,0,[\"inputClass\"]]],null],[32,0,[\"inputClass\"]]],null]],[16,\"placeholder\",[32,4]],[16,\"autocomplete\",[32,5]],[16,\"required\",[34,2]],[16,\"disabled\",[34,3]],[17,6],[16,1,[32,0,[\"inputId\"]]],[16,\"onclick\",[30,[36,4],[[32,0],\"click\"],null]],[16,\"autofocus\",[34,5]]],[[\"@value\",\"@type\",\"@focus-in\",\"@focus-out\",\"@key-up\",\"@key-down\",\"@enter\",\"@maxFitLength\"],[[32,2],[32,3],[30,[36,4],[[32,0],\"focusIn\"],null],[30,[36,4],[[32,0],\"focusOut\"],null],[32,7],[32,8],[32,9],[32,10]]],null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"required\",\"disabled\",\"action\",\"autofocus\",\"placeholder\",\"autocomplete\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/input/template.hbs"
  });
});