define("boondmanager/initializers/bootbox", ["exports", "jquery", "bootbox"], function (_exports, _jquery, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    var originalDialogFn = _bootbox.default.dialog;
    _bootbox.default.dialog = function (options) {
      var dialog = originalDialogFn(options);
      var $dialog = (0, _jquery.default)(dialog);

      // add data-bb-handler
      $dialog.find('.modal-footer button.btn').each(function () {
        var $btn = (0, _jquery.default)(this);
        var bbHandler = $btn.data('bb-handler');
        if (bbHandler) {
          $btn.attr('data-name', 'button-' + bbHandler);
        }
      });
      return $dialog;
    };
  }
  var _default = _exports.default = {
    name: 'bootbox',
    initialize: initialize
  };
});