define("boondmanager/controllers/tab", ["exports", "boondmanager/controllers/entity"], function (_exports, _entity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Contrôleur parent principal des onglets
   *
   * @class  TabController
   * @extends Ember.Controller
   * @namespace  Ember
   * @module  Controllers
   */
  var _default = _exports.default = _entity.default.extend();
});