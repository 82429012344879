define("boondmanager/models/share", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['dependsOn', 'managers', 'resources']
    }),
    body: _emberData.default.attr('string'),
    object: _emberData.default.attr('string'),
    settings: _emberData.default.attr('string'),
    threadMessage: _emberData.default.attr('string'),
    recipients: _emberData.default.attr(),
    emails: _emberData.default.attr(),
    dependsOn: _emberData.default.belongsTo('base'),
    dependsOnResource: Ember.computed('dependsOn', function () {
      return this.get('dependsOn.modelName') === 'resource';
    })
  });
});