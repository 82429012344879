define("boondmanager/models/expensedetail", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTE
    /**
     * ExpenseType Object
     *
     * expenseType.reference : integer
     * expenseType.name      : string
     *
     * @type {Object}
     */
    expenseType: (0, _attributes.fragment)('expensetype'),
    /**
     * periodicity = "daily" || "monthly"
     * @type {String}
     */
    periodicity: _emberData.default.attr('string'),
    netAmount: _emberData.default.attr('number'),
    /**
     * Agency Object
     *
     * agency.id            : string
     * agency.name          : string
     * agency.expensesTypes : array of objects
     *
     * agency.expensesTypes[].reference : number
     * agency.expensesTypes[].name      : string
     * agency.expensesTypes[].taxRate   : number
     *
     * @type {Object}
     */
    agency: _emberData.default.attr()
  });
});