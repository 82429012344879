define("boondmanager/models/perimeter", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    required: _emberData.default.attr('boolean'),
    allManagers: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    allManagerTypes: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    allAgencies: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    resourceTypes: _emberData.default.attr(),
    agencies: _emberData.default.hasMany(),
    businessUnits: _emberData.default.hasMany('businessunit'),
    managers: _emberData.default.hasMany('resource'),
    poles: _emberData.default.hasMany()
  });
});