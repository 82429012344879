define("boondmanager/pods/components/octane/bm-add-multiple-rows/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M65rZWXO",
    "block": "{\"symbols\":[\"&attrs\",\"@onConfirm\",\"@icon\"],\"statements\":[[11,\"p\"],[24,0,\"bmc-add-multiple-rows bm-paragraphe bm-paragraphe_complex\"],[17,1],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bm-paragraphe_complex-text\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-link\"],[24,\"data-name\",\"button-add-multiple-rows\"],[16,\"aria-label\",[30,[36,3],[\"common:actions.add\"],null]]],[[\"@onClick\"],[[30,[36,1],[[32,2],[32,0,[\"quantity\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],[[32,3]],null]],[2,\" \"],[1,[30,[36,3],[\"common:actions.add\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bm-paragraphe_complex-text\"],[12],[2,\"\\n\"],[6,[37,5],null,[[\"noTitle\",\"class\"],[true,\"bmc-field_nosection\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,2],null,[[\"name\",\"options\",\"selected\",\"onchange\",\"forceSearch\"],[\"addQuantity\",[32,0,[\"options\"]],[32,0,[\"quantity\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"quantity\"]]],null]],null],false]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bm-paragraphe_complex-text\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,3],[\"orders:view.tabs.information.schedule.modal.rows\"],[[\"count\"],[[32,0,[\"quantity\"]]]]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"bm-field/power-select\",\"t\",\"icon\",\"bm-field\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-add-multiple-rows/template.hbs"
  });
});