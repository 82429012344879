define("boondmanager/models/appemailingtemplate", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _base, _emberDataCopyable, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TEMPLATE_TYPEOF_QUOTATION = _exports.TEMPLATE_TYPEOF_PUSHMESSAGE = _exports.TEMPLATE_TYPEOF_INVOICE = _exports.TEMPLATE_TYPEOF_ACTIVITY = void 0;
  var TEMPLATE_TYPEOF_PUSHMESSAGE = _exports.TEMPLATE_TYPEOF_PUSHMESSAGE = 'pushMessage';
  var TEMPLATE_TYPEOF_INVOICE = _exports.TEMPLATE_TYPEOF_INVOICE = 'invoice';
  var TEMPLATE_TYPEOF_ACTIVITY = _exports.TEMPLATE_TYPEOF_ACTIVITY = 'activity';
  var TEMPLATE_TYPEOF_QUOTATION = _exports.TEMPLATE_TYPEOF_QUOTATION = 'quotation';
  var AppsEMailingTemplate = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['mainManager']
    }),
    typeOf: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    object: _emberData.default.attr('string'),
    nextState: _emberData.default.attr('number'),
    lastStateUsed: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    createdBy: _emberData.default.belongsTo('resource'),
    share: (0, _attributes.fragment)('sharedentityfragment')
  });
  AppsEMailingTemplate.reopenClass({
    prefix: 'PUSHTPL'
  });
  AppsEMailingTemplate.reopen({
    prefix: Ember.computed(function () {
      return AppsEMailingTemplate.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsEMailingTemplate;
});