define("boondmanager/models/order", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable"], function (_exports, _base, _emberData, _attributes, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_ON_GOING = _exports.STATE_ARCHIVED = void 0;
  var STATE_ON_GOING = _exports.STATE_ON_GOING = 1;
  var STATE_ARCHIVED = _exports.STATE_ARCHIVED = 0;
  var Order = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['project', 'mainManager', 'bankDetail', 'billingDetail', 'factor', 'deliveriesPurchases']
    }),
    // Attributes
    date: _emberData.default.attr('date'),
    number: _emberData.default.attr('string'),
    customerAgreement: _emberData.default.attr('boolean'),
    state: _emberData.default.attr('number'),
    typeOf: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    endDate: _emberData.default.attr('date'),
    startDate: _emberData.default.attr('date'),
    language: _emberData.default.attr('string'),
    paymentMethod: _emberData.default.attr('number'),
    paymentTerm: _emberData.default.attr('number'),
    billingInstructions: _emberData.default.attr('string'),
    taxRate: _emberData.default.attr('number'),
    taxRates: _emberData.default.attr(),
    turnoverOrderedExcludingTax: _emberData.default.attr('number'),
    informationComments: _emberData.default.attr('string'),
    legals: _emberData.default.attr('string'),
    createInvoiceAutomatically: _emberData.default.attr('boolean'),
    copyCommentsOnNewInvoice: _emberData.default.attr('boolean'),
    showCommentsOnPDF: _emberData.default.attr('boolean'),
    showFactorOnPDF: _emberData.default.attr('boolean'),
    showCompanyVATNumberOnPDF: _emberData.default.attr('boolean'),
    showCompanyRegistrationNumberOnPDF: _emberData.default.attr('boolean'),
    showCompanyNumberOnPDF: _emberData.default.attr('boolean'),
    showBankDetailsOnPDF: _emberData.default.attr('boolean'),
    showProjectReferenceOnPDF: _emberData.default.attr('boolean'),
    showResourcesNameOnPDF: _emberData.default.attr('boolean'),
    showAverageDailyPriceOnPDF: _emberData.default.attr('boolean'),
    showNumberOfWorkingDaysOnPDF: _emberData.default.attr('boolean'),
    showFooterOnPDF: _emberData.default.attr('boolean'),
    separateActivityExpensesAndPurchases: _emberData.default.attr('boolean'),
    separateExceptionalActivity: _emberData.default.attr('boolean'),
    groupDeliveries: _emberData.default.attr('boolean'),
    groupExpenses: _emberData.default.attr('boolean'),
    attachSignedTimesheets: _emberData.default.attr('boolean'),
    attachTimesheets: _emberData.default.attr('boolean'),
    requestTimesheetsSignature: _emberData.default.attr('boolean'),
    attachExpenses: _emberData.default.attr('boolean'),
    showInformationCommentsOnCustomerPdf: _emberData.default.attr('boolean'),
    showResourceFullNameOnCustomerPdf: _emberData.default.attr('boolean'),
    showWorkUnitTypeNameOnCustomerPdf: _emberData.default.attr('boolean'),
    useWorkUnitsForRegularDurationsOnCustomerPdf: _emberData.default.attr('boolean'),
    mergeInvoiceAttachments: _emberData.default.attr('boolean'),
    creationDate: _emberData.default.attr('moment'),
    // TAB_BONDECOMMANDE.BDC_CREATEDAT,
    updateDate: _emberData.default.attr('moment'),
    schedules: (0, _attributes.fragmentArray)('orderschedule', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    project: _emberData.default.belongsTo({
      inverse: null
    }),
    mainManager: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    bankDetail: _emberData.default.belongsTo('bankdetail', {
      inverse: null
    }),
    billingDetail: _emberData.default.belongsTo('detail', {
      inverse: null
    }),
    headOffice: _emberData.default.belongsTo('detail', {
      inverse: null
    }),
    factor: _emberData.default.belongsTo('company', {
      inverse: null
    }),
    deltaInvoicedExcludingTax: _emberData.default.attr('number'),
    deltaProductionExcludingTax: _emberData.default.attr('number'),
    numberOfInvoices: _emberData.default.attr('number'),
    turnoverInvoicedExcludingTax: _emberData.default.attr('number'),
    turnoverProductionExcludingTax: _emberData.default.attr('number'),
    deliveriesPurchases: _emberData.default.hasMany('base', {
      defaultValue: function defaultValue() {
        return [];
      },
      inverse: null
    }),
    // Purchase || Delivery
    files: _emberData.default.hasMany('file', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    //right
    canReadOrder: _emberData.default.attr('boolean'),
    canWriteOrder: _emberData.default.attr('boolean'),
    // Droit d'écriture ( par procuration de suppresion)

    // Relationships

    negativeDelta: Ember.computed('deltaInvoicedExcludingTax', function () {
      return this.get('deltaInvoicedExcludingTax') < 0;
    }),
    getReference: Ember.computed('number', 'reference', 'internalReference', function () {
      return this.get('number') || this.get('reference') || this.get('internalReference');
    }),
    canReadBilling: Ember.computed.alias('canReadOrder'),
    canWriteBilling: Ember.computed.alias('canWriteOrder'),
    isMonthly: Ember.computed('typeOf', function () {
      return this.get('typeOf') === 'monthly';
    }),
    isSchedule: Ember.computed('typeOf', function () {
      return this.get('typeOf') === 'schedule';
    }),
    labelColor: Ember.computed('state', function () {
      var state = this.get('state');
      switch (state) {
        case 0:
          return 'bm-note';
        case 1:
          return 'bm-danger';
        default:
          return 'bm-default';
      }
    }),
    entityName: Ember.computed('number', function () {
      var number = this.get('number');
      if (Ember.isBlank(number)) {
        return this.get('internalReference');
      } else {
        return number;
      }
    }),
    isTaxRatesMultiples: Ember.computed('taxRates', 'project.agency.ordersAllowMultiplesTaxRates', function () {
      return this.get('taxRates').length > 1 || this.get('project.agency.ordersAllowMultiplesTaxRates');
    }).readOnly(),
    taxRatesSelected: Ember.computed('isTaxRatesMultiples', 'taxRates', function () {
      if (this.get('isTaxRatesMultiples')) return this.get('taxRates');else return this.get('taxRates.0');
    })
  });
  Order.reopenClass({
    prefix: 'BDC'
  });
  Order.reopen({
    prefix: Ember.computed(function () {
      return Order.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Order;
});