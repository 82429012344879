define("boondmanager/pods/components/bm-field/departments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xAQkptUo",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,11],null,[[\"multiple\",\"name\",\"selected\",\"createOnEnter\",\"maxlength\",\"forceSearch\",\"allowClear\",\"options\",\"placeholder\",\"onchange\",\"disabled\"],[[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[30,[36,2],[\"common:none\"],null],[35,1],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"onchange\",\"t\",\"options\",\"allowClear\",\"forceSearch\",\"maxlength\",\"createOnEnter\",\"selected\",\"name\",\"multiple\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/departments/template.hbs"
  });
});