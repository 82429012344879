define("boondmanager/pods/components/bm-field/power-select/selecteditem-for-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'span',
    className: 'ember-power-select-selected-item',
    selectedItem: Ember.computed('selected', 'options', 'i18nForSelectedItemForGroup', 'i18n._locale', function () {
      var _this = this;
      var groupValue = undefined;
      var selectedValue = undefined;
      this.get('options').forEach(function (options) {
        var optionFound = options.option.find(function (option) {
          return option.id === _this.get('selected').id;
        });
        if (typeof optionFound !== 'undefined') {
          groupValue = options.value;
          selectedValue = optionFound.value;
        }
      });
      return this.get('i18n').t(this.get('i18nForSelectedItemForGroup'), {
        groupValue: groupValue,
        selectedValue: selectedValue
      });
    })
  });
});