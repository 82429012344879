define("boondmanager/pods/components/bm-field/state-editable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Udsb4JoL",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,6],null,[[\"name\",\"selected\",\"disabled\",\"options\",\"onchange\"],[[32,0,[\"name\"]],[32,0,[\"model\",\"state\"]],[32,0,[\"disabled\"]],[30,[36,5],[[32,0,[\"states\"]]],[[\"model\",\"attribute\",\"selected\"],[[32,0,[\"model\",\"modelName\"]],\"state\",[32,0,[\"model\",\"state\"]]]]],[30,[36,0],[[32,0],\"onUpdateState\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-sticker\",[[16,\"data-name\",[30,[36,2],[[32,0,[\"model\",\"modelName\"]],[32,1,[\"id\"]]],null]]],[[\"@sticker\"],[[30,[36,2],[[32,0,[\"model\",\"modelName\"]],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[32,1,[\"value\"]]],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[32,0,[\"stateReasonLabel\",\"content\"]],[30,[36,3],[[32,1,[\"id\"]],[32,0,[\"model\",\"state\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"stateReasonLabel\",\"content\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"br\"],[12],[13],[10,\"span\"],[14,0,\"bm-truncate bm-text-secondary\"],[12],[1,[32,0,[\"stateReasonLabel\",\"content\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"stateReason\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-field/state-reason-profile\",[],[[\"@readOnly\",\"@model\",\"@type\",\"@openStateReasonModal\"],[true,[32,0,[\"model\"]],[32,0,[\"model\",\"modelName\"]],[30,[36,0],[[32,0],\"onOpenStateReasonModal\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"state-color\",\"eq\",\"and\",\"options-for\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/state-editable/template.hbs"
  });
});