define("boondmanager/models/orderschedule", ["exports", "ember-data", "ember-data-model-fragments", "moment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _moment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OrderSchedule = void 0;
  var OrderSchedule = _exports.OrderSchedule = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    date: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)();
      }
    }),
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    turnoverQuotaExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    turnoverTermOfPaymentExcludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    forceTermOfPaymentExcludingTax: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    invoices: (0, _attributes.fragmentArray)('invoiceschedule', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
  var _default = _exports.default = OrderSchedule;
});