define("boondmanager/models/appsepaagency", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes", "ember-data-copyable"], function (_exports, _base, _emberData, _attributes, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsSepaAgency = _base.default.extend(_emberDataCopyable.default, {
    name: _emberData.default.attr('string'),
    creditorIdentifier: _emberData.default.attr('string'),
    nameToExtract: _emberData.default.attr('string'),
    banksDetails: (0, _attributes.fragmentArray)('bankdetailfragment')
  });
  AppsSepaAgency.reopenClass({
    prefix: 'AJU'
  });
  AppsSepaAgency.reopen({
    prefix: Ember.computed(function () {
      return AppsSepaAgency.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsSepaAgency;
});