define("boondmanager/pods/components/octane/bm-sticker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "G6AnKlrp",
    "block": "{\"symbols\":[\"&default\",\"@smallSticker\",\"@square\",\"&attrs\",\"@sticker\",\"@namedBlocksInfo\"],\"statements\":[[11,\"span\"],[16,0,[30,[36,1],[\"bmc-sticker\",[30,[36,0],[[32,3],\" bmc-sticker-square\"],null],[30,[36,0],[[32,2],\" bmc-sticker-color-small\"],null]],null]],[17,4],[12],[2,\"\\n\\t\"],[10,\"span\"],[15,0,[31,[\"bmc-sticker-color \",[32,5]]]],[12],[13],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,6],\"default\",[27,[32,1]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"span\"],[14,0,\"bmc-sticker-content\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"-has-block\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-sticker/template.hbs"
  });
});