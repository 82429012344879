define("boondmanager/models/actiontemplate", ["exports", "ember-data", "boondmanager/models/base", "ember-data-copyable", "ember-data-model-fragments/attributes", "boondmanager/utils/bm"], function (_exports, _emberData, _base, _emberDataCopyable, _attributes, _bm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ActionTemplate = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['mainManager']
    }),
    typeOf: _emberData.default.attr({
      defaultValue: _bm.NOT_USED
    }),
    content: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    updateDate: _emberData.default.attr('moment'),
    createdBy: _emberData.default.belongsTo('resource'),
    share: (0, _attributes.fragment)('sharedentityfragment')
  });
  ActionTemplate.reopenClass({
    prefix: 'ACTTPL'
  });
  ActionTemplate.reopen({
    prefix: Ember.computed(function () {
      return ActionTemplate.prefix;
    }).readOnly()
  });
  var _default = _exports.default = ActionTemplate;
});