define("boondmanager/models/taskfragment", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TaskFragment = _exports.TASK_TYPE_TASK_WITH_CHILDREN = _exports.TASK_TYPE_TASK = _exports.TASK_STATE_ENABLED = _exports.TASK_STATE_ARCHIVED = void 0;
  var TASK_STATE_ARCHIVED = _exports.TASK_STATE_ARCHIVED = false;
  var TASK_STATE_ENABLED = _exports.TASK_STATE_ENABLED = true;
  var TASK_TYPE_TASK = _exports.TASK_TYPE_TASK = 0;
  var TASK_TYPE_TASK_WITH_CHILDREN = _exports.TASK_TYPE_TASK_WITH_CHILDREN = 1;
  var TaskFragment = _exports.TaskFragment = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    validatedAt: _emberData.default.attr('date'),
    validatedBy: _emberData.default.attr(),
    settingsService: Ember.inject.service('settings'),
    children: (0, _attributes.fragmentArray)('childtaskfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    edition: false,
    /**
     *
     */
    taskType: Ember.computed('children.[]', 'taskTypeValue', {
      get: function get() {
        if (this.get('taskTypeValue')) return this.get('taskTypeValue');
        return this.get('children').length ? TASK_TYPE_TASK_WITH_CHILDREN : TASK_TYPE_TASK;
      },
      set: function set(key, value) {
        this.set('taskTypeValue', value);
        return value;
      }
    }),
    /**
     *
     */
    childrenList: Ember.computed('children.[]', function () {
      return this.get('children').toArray();
    }).readOnly(),
    /**
     *
     */
    enabledChildren: Ember.computed('children.@each.state', function () {
      return this.get('children').toArray().filterBy('state');
    }).readOnly(),
    /**
     *
     */
    taskTypeDisabled: Ember.computed('taskType', 'children.[]', 'canDelete', function () {
      return this.get('taskType') == TASK_TYPE_TASK_WITH_CHILDREN && this.get('children').length || this.get('taskType') == TASK_TYPE_TASK && !this.get('canDelete');
    }).readOnly(),
    /**
     *
     */
    title: Ember.computed('children.[]', 'state', function () {
      var _this = this;
      var i18n = this.get('i18n');
      var option = this.get('settingsService').bm.todolists.templates.task.types.find(function (item) {
        return item.id === _this.get('taskType');
      });
      var title = typeof option !== 'undefined' ? option.value : '';
      if (this.get('state') == TASK_STATE_ARCHIVED) {
        title += " - ".concat(i18n.t('common:archived', {
          context: 'todolist'
        }));
      }
      return title;
    }).readOnly()
  });
  var _default = _exports.default = TaskFragment;
});