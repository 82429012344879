define("boondmanager/pods/components/bm-field/customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LT2f2Eum",
    "block": "{\"symbols\":[\"customer\"],\"statements\":[[6,[37,12],null,[[\"name\",\"debug\",\"customSearch\",\"useObjects\",\"multiple\",\"selected\",\"onchange\",\"renderInPlace\",\"placeholder\",\"searchMessage\",\"searchPlaceholder\",\"loadingMessage\",\"noMatchesMessage\",\"allowClear\",\"required\",\"disabled\"],[[35,11],true,[30,[36,8],[[32,0],\"searchItems\"],null],true,[35,10],[35,9],[30,[36,8],[[32,0],\"customOnChange\"],null],[35,7],[30,[36,6],[\"common:none\"],null],\"\",[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"disabled\",\"required\",\"allowClear\",\"noMatchesMessage\",\"loadingMessage\",\"searchPlaceholder\",\"t\",\"renderInPlace\",\"action\",\"selected\",\"multiple\",\"name\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/customer/template.hbs"
  });
});