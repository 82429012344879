define("boondmanager/models/fixedexpense", ["exports", "ember-data", "boondmanager/models/expense", "boondmanager/utils/financial"], function (_exports, _emberData, _expense, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* ** ATTRIBUTES TYPE CASTING **
  
  	see: http://discuss.emberjs.com/t/ember-data-store-my-attr-number-as-a-string-why/11904
  
  	Ember-data doesn't auto-cast attributes when we use data in our app! Ex: if
  	you use a number attribut with an {{input}}, you'll get a string :'/
  
  	Ember-data cast attributes only when data is received from or send to the
  	backend...
  
  	So we have to manually cast them when we want to do some calculation for
  	example :'(
  
  	We use Number() constructor because it doesn't detect octals si of a string
  	like "010" is casting, we have the number 10 (Number("010") => 10 vs
  	parseInt("010") > 8 but if we use parseInt("010", 10) => 10, it's ok ;p)
  */
  var _default = _exports.default = _expense.default.extend({
    /**
     * Row number in the table where appear this expense.
     * @type {Number}
     */
    row: _emberData.default.attr('number'),
    category: Ember.computed(function () {
      return 'fixed';
    }).readOnly(),
    agencyTaxedAmount: Ember.computed('agencyAmountIncludingTax', 'expenseType.taxRate', function () {
      var agencyAmountIncludingTax = this.get('agencyAmountIncludingTax');
      // see ATTRIBUTES TYPE CASTING at the beginning of this file
      var taxRate = Number(this.get('expenseType.taxRate'));
      return (0, _financial.extractTaxedAmount)(agencyAmountIncludingTax, taxRate);
    }),
    originalTaxedAmount: Ember.computed('originalAmountIncludingTax', 'expenseType.taxRate', function () {
      var originalAmountIncludingTax = this.get('originalAmountIncludingTax');
      // see ATTRIBUTES TYPE CASTING at the beginning of this file
      var taxRate = Number(this.get('expenseType.taxRate'));
      return (0, _financial.extractTaxedAmount)(originalAmountIncludingTax, taxRate);
    }),
    value: Ember.computed('isKilometricExpense', 'numberOfKilometers', 'originalAmountIncludingTax', {
      get: function get() {
        if (this.get('isKilometricExpense')) {
          return this.get('numberOfKilometers');
        } else {
          return this.get('originalAmountIncludingTax');
        }
      },
      set: function set(_, newValue) {
        newValue = Number(newValue);
        if (this.get('isKilometricExpense')) {
          this.set('numberOfKilometers', newValue);
        } else {
          this.set('originalAmountIncludingTax', newValue);
        }
        return newValue;
      }
    })
  });
});