define("boondmanager/models/file", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    name: _emberData.default.attr('string'),
    urlEmbedded: _emberData.default.attr('string'),
    urlMain: _emberData.default.attr('string'),
    esignature: _emberData.default.belongsTo('esignature', {
      inverse: null
    }),
    mimeType: Ember.computed('name', function () {
      var name = this.get('name');
      var nameParts = name.split('.');
      var extension = nameParts[nameParts.length - 1].toLowerCase();
      switch (extension) {
        case 'png':
        case 'jpeg':
        case 'jpg':
        case 'bmp':
        case 'gif':
        case 'tiff':
        case 'svg':
        case 'webp':
          return 'image/' + extension;
        case 'txt':
        case 'json':
          return 'text/plain';
        case 'mpeg':
        case 'mp4':
        case 'avi':
          return 'video/' + extension;
        case 'ods':
          return 'application/vnd.oasis.opendocument.spreadsheet';
        case 'xls':
          return 'application/vnd.ms-excel';
        case 'xlsx':
          return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'csv':
          return 'text/csv';
        case 'odt':
          return 'application/vnd.oasis.opendocument.text';
        case 'doc':
          return 'application/msword';
        case 'docx':
          return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'odp':
          return 'application/vnd.oasis.opendocument.presentation';
        case 'ppt':
          return 'application/vnd.ms-powerpoint';
        case 'pptx':
          return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        case 'wav':
          return 'audio/x-wav';
        case 'mp3':
          return 'audio/mpeg';
        case 'pdf':
          return 'application/pdf';
        case 'zip':
          return 'application/zip';
        case 'gz':
          return 'application/gzip';
        default:
          return 'application/octet-stream';
      }
    }),
    isImage: Ember.computed('mimeType', function () {
      return this.get('mimeType').indexOf('image/') === 0;
    }),
    isAudio: Ember.computed('mimeType', function () {
      return this.get('mimeType').indexOf('audio/') === 0;
    }),
    isVideo: Ember.computed('mimeType', function () {
      return this.get('mimeType').indexOf('video/') === 0;
    }),
    isArchive: Ember.computed('mimeType', function () {
      return this.get('mimeType') === 'application/zip' && this.get('mimeType') === 'application/gzip';
    }),
    isDocument: Ember.computed('mimeType', function () {
      var documentTypes = ['application/vnd.oasis.opendocument.text', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
      return documentTypes.indexOf(this.get('mimeType')) > -1;
    }),
    isText: Ember.computed('mimeType', function () {
      return this.get('mimeType').indexOf('text/') === 0;
    }),
    isViewable: Ember.computed('mimeType', function () {
      return this.get('isDocument') || this.get('isImage') || this.get('isVideo') || this.get('isAudio') || this.get('isText');
    })
  });
});