define("boondmanager/models/document", ["exports", "boondmanager/models/file", "ember-data", "boondmanager/utils/file"], function (_exports, _file, _emberData, _file2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PROOF_CATEGORY_UNSIGNED = _exports.PROOF_CATEGORY_SIGNED = _exports.PROOF_CATEGORY_SIGNATURE_REQUESTED = _exports.PROOF_CATEGORY_OTHER = _exports.PROOF_CATEGORY_EXPENSE = void 0;
  var PROOF_CATEGORY_OTHER = _exports.PROOF_CATEGORY_OTHER = 'other';
  var PROOF_CATEGORY_SIGNED = _exports.PROOF_CATEGORY_SIGNED = 'signedProof';
  var PROOF_CATEGORY_SIGNATURE_REQUESTED = _exports.PROOF_CATEGORY_SIGNATURE_REQUESTED = 'signatureRequested';
  var PROOF_CATEGORY_UNSIGNED = _exports.PROOF_CATEGORY_UNSIGNED = 'unsignedProof';
  var PROOF_CATEGORY_EXPENSE = _exports.PROOF_CATEGORY_EXPENSE = 'expense';
  var Doc = _file.default.extend({
    settings: Ember.inject.service(),
    name: _emberData.default.attr('string'),
    size: _emberData.default.attr('string'),
    category: _emberData.default.attr('string', {
      defaultValue: 'other'
    }),
    project: _emberData.default.belongsTo('project', {
      inverse: null
    }),
    dependsOn: _emberData.default.belongsTo('base', {
      inverse: null
    }),
    signature: _emberData.default.belongsTo('signature', {
      inverse: null
    }),
    backupProject: null,
    backup: function backup() {
      this.set('backupProject', this.hasProject() ? this.get('project.id') : null);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.backup();
    },
    hasProject: function hasProject() {
      return typeof this.get('project.id') !== 'undefined';
    },
    hasDirtyProject: function hasDirtyProject() {
      return this.hasProject() && this.get('project.id') !== this.get('backupProject');
    },
    sizeWithUnit: Ember.computed('size', 'i18n._locale', function () {
      return (0, _file2.getFileSizeWithUnit)(this.get('size'), this.get('i18n'));
    }),
    categoryLabel: Ember.computed('category', 'i18n_locale', function () {
      var _this = this,
        _proofCategory$value;
      var proofCategories = this.get('settings').bm.timesreport.proofCategory;
      var proofCategory = proofCategories.find(function (category) {
        return category.id === _this.get('category');
      });
      return (_proofCategory$value = proofCategory === null || proofCategory === void 0 ? void 0 : proofCategory.value) !== null && _proofCategory$value !== void 0 ? _proofCategory$value : this.get('category');
    })
  });
  Doc.reopenClass({
    prefix: 'DOC'
  });
  Doc.reopen({
    prefix: Ember.computed(function () {
      return Doc.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Doc;
});