define("boondmanager/pods/components/bm-field/-base/component", ["exports", "jquery", "boondmanager/utils/logger", "boondmanager/app"], function (_exports, _jquery, _logger, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.bmHasValueClass = _exports.bmFocusClass = void 0;
  var bmFocusClass = _exports.bmFocusClass = 'bm-focus';
  var bmHasValueClass = _exports.bmHasValueClass = 'bm-has-value';
  var _default = _exports.default = Ember.Component.extend({
    debug: false,
    classNameBindings: ["focused:".concat(bmFocusClass), "hasValue:".concat(bmHasValueClass)],
    attributeBindings: ['tabindex'],
    tabIndex: false,
    disabled: false,
    focused: false,
    bubbles: true,
    _onFn: function _onFn(fnName) {
      var fn = this[fnName];
      if (typeof fn === 'function') {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        return fn.apply(void 0, args);
      }
    },
    // Allow element to be focusable by supplying a tabindex 0
    tabindex: Ember.computed('disabled', function () {
      return this.disabled ? '-1' : '';
    }),
    // Only render the "focused" state if the element gains focus due to
    // keyboard navigation.
    focusOnlyOnKey: false,
    bmFocusIn: function bmFocusIn() {
      if (this.debug) {
        _logger.default.debug.apply(_logger.default, ["".concat(this, " - ").concat(_app.bmCustomEvents.focusIn.handler, "()")].concat(Array.prototype.slice.call(arguments)));
      }
      if (!this.disabled && !this.focusOnlyOnKey) {
        if (this.debug) {
          _logger.default.debug("".concat(this, " - ").concat(_app.bmCustomEvents.focusIn.handler, "() - set focused to true"));
        }
        this.set('focused', true);
        return this._onFn.apply(this, ['onFocusIn'].concat(Array.prototype.slice.call(arguments)));
      }
    },
    bmFocusOut: function bmFocusOut() {
      if (this.debug) {
        _logger.default.debug.apply(_logger.default, ["".concat(this, " - ").concat(_app.bmCustomEvents.focusOut.handler, "()")].concat(Array.prototype.slice.call(arguments)));
      }
      this.set('focused', false);
      return this._onFn.apply(this, ['onFocusOut'].concat(Array.prototype.slice.call(arguments)));
    },
    bmHasValue: function bmHasValue(event) {
      if (this.debug) {
        _logger.default.debug.apply(_logger.default, ["".concat(this, " - ").concat(_app.bmCustomEvents.hasValue.handler, "()")].concat(Array.prototype.slice.call(arguments)));
      }
      if (typeof this.mainComponentId !== 'undefined') {
        if (event.target.id === this.mainComponentId) {
          this.set('hasValue', true);
        } else {
          event.stopPropagation();
          return false;
        }
      } else {
        this.set('hasValue', true);
      }

      //return this._onFn('onChange', ...arguments);
    },
    bmHasNoValue: function bmHasNoValue(event) {
      if (this.debug) {
        _logger.default.debug.apply(_logger.default, ["".concat(this, " - ").concat(_app.bmCustomEvents.hasNoValue.handler, "()")].concat(Array.prototype.slice.call(arguments)));
      }
      if (typeof this.mainComponentId !== 'undefined') {
        if (event.target.id === this.mainComponentId) {
          this.set('hasValue', false);
        } else {
          event.stopPropagation();
          return false;
        }
      } else {
        this.set('hasValue', false);
      }

      //return this._onFn('onChange', ...arguments);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.isBlankValue) {
        var hasValueNeverDefined = typeof this._hasValue === 'undefined';
        if (this.isBlankValue()) {
          if (hasValueNeverDefined || this._hasValue) {
            this._hasValue = false;
            (0, _jquery.default)(this.element).trigger(_app.bmCustomEvents.hasNoValue.name);
            if (this.debug) {
              _logger.default.debug("".concat(this, " trigger '").concat(_app.bmCustomEvents.hasNoValue.name, "' event"), this.element);
            }
          }
        } else {
          if (hasValueNeverDefined || !this._hasValue) {
            this._hasValue = true;
            (0, _jquery.default)(this.element).trigger(_app.bmCustomEvents.hasValue.name);
            if (this.debug) {
              _logger.default.debug("".concat(this, " trigger '").concat(_app.bmCustomEvents.hasValue.name, "' event"), this.element);
            }
          }
        }
      }
    }
  });
});