define("boondmanager/pods/components/bm-field/availability/candidate/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * BM field Availability component
   *
   * Component attributes:
   *
   *   * {string}             name          REQUIRED field name
   *   * {Availability}       availability  REQUIRED Availability object
   *   * {boolean}            automatic     OPTIONAL used only with 'resource' profile
   *   * {string}             class         OPTIONAL additional classes applied to the main DOM element
   *   * {boolean}            disabled      OPTIONAL component state
   *   * {string}             size          OPTIONAL component Bootstrap size
   *   * {function}           onChange      OPTIONAL callback call on <option> selection
   *
   * see AvailabilityInitializer to learn about Availability object format.
   *
   * Example:
   *
   * ```handlebars
   * <div>
   *     {{bm-field/availability
   *         name="profile-availability"
   *         size=size
   *         disabled=disabled
   *         availability=availability
   *         onChange=(action (mut availability))
   *     }}
   * </div>ww
   * ```
   *
   * @class  BmFieldAvailabilityComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * Component default __class__ attribute
     *
     * @property classNames
     * @type {Array.<string>}
     * @default  ['bm-field-availability']
     */
    classNames: ['bm-field-availability-candidate'],
    /**
     * The size of the element. Available options: 'lg' and 'sm'.
     * @type {String}
     * @default ''
     * @public
     */
    size: '',
    /**
     * Component disabled state
     *
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,
    /**
     * Format of date displayed in date input
     *
     * @type {String}
     * @default  'DD/MM/YY'
     */
    displayedDateFormat: 'DD/MM/YY',
    /**
     * Is availability automatic (ie. availability.date defined by backend when profile is saved) ?
     *
     * @type {Boolean}
     * @default  false
     */
    automatic: false,
    /**
     * Profile-dependent availability types
     *
     * @property availabilityTypes
     * @type {IdValue[]}
     * @readOnly
     */
    availabilityTypes: Ember.computed('i18n._locale', function () {
      var availabilityTypes = [];
      availabilityTypes = availabilityTypes.concat(this.get('settings').bm.component.bmFieldAvailability.types.candidate);
      return availabilityTypes.concat(this.get('settings').customer.availability);
    }).readOnly(),
    /**
     * Type currently selected
     *
     * @property selectedType
     * @type {String|Number}
     */
    selectedType: Ember.computed('availability.raw', function () {
      var selectedType = this.get('availability.raw');
      if (this.get('availability.date')) {
        selectedType = 'date';
      }
      return selectedType;
    }),
    /**
     * Date previously defined when selectedType was 'date'
     *
     * @property previousSelectedDate
     * @type {Moment}
     */
    /**
     * 'didReceiveAttrs' Component hook
     *
     * Check `name` and `availability` attributes
     *
     * @method didReceiveAttrs
     * @public
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var name = this.get('name');
      //const availability = this.get('availability');

      // check name & module
      (false && !(!Ember.isBlank(name)) && Ember.assert("bm-field-availability-candidate#".concat(this.get('elementId'), " must have a \"name\" attribute!"), !Ember.isBlank(name)));
      (false && !(Ember.typeOf(name) === 'string') && Ember.assert("bm-field-availability-candidate#".concat(this.get('elementId'), " \"name\" attribute must be a string!"), Ember.typeOf(name) === 'string')); //assert(`bm-field-availability#${this.get('elementId')} must have an "availability" attribute!`, !isBlank(availability));
      //assert(`bm-field-availability#${this.get('elementId')} "availability" attribute must be a Ember.Availability instance!`, (availability instanceof Availability));
    },
    actions: {
      /**
       * Update selected type
       *
       * @event  updateSelectedType
       */
      updateSelectedType: function updateSelectedType(type) {
        var previousType = this.get('selectedType');
        // onChange only if we change type ;p
        if (previousType !== type) {
          var selectedDate;
          if (previousType === 'date') {
            this.set('previousSelectedDate', this.get('availability.date'));
          }
          switch (type) {
            case 'date':
              selectedDate = this.get('previousSelectedDate') || (0, _moment.default)();
              break;
          }

          //Logger.error('tsi type seletedDate', type, selectedDate);
          this.get('onChange')(type, selectedDate);
        }
      },
      /**
       * update selected date (when selected type === 'date')
       *
       * @event  updateSelectedDate
       */
      updateSelectedDate: function updateSelectedDate(date) {
        var type = this.get('selectedType');
        this.get('onChange')(type, date);
      }
    }
  });
});