define("boondmanager/models/apphouraccountsresource", ["exports", "boondmanager/models/resource", "ember-data-model-fragments/attributes", "ember-data", "boondmanager/models/apphouraccountshouraccountfragment", "moment"], function (_exports, _resource, _attributes, _emberData, _apphouraccountshouraccountfragment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FakeSetting = void 0;
  /**
   * Fake Settings object
   *
   * Cet objet va permettre de créer des entrées dans le tableau des décomptes horaires
   * pour des lignes qui n'ont pas de valeurs, ainsi l'objet timesreport ne sera pas dirty
   * mais pour autant le template fonctionnera quand même avec les valeurs et computed du fragment d'origine
   *
   * @class FakeSetting
   * @namespace BoondManager.AppHourAccountsResource
   * @extends Ember.Object
   * @private
   */
  var FakeSetting = _exports.FakeSetting = Ember.Object.extend({
    /**
     * Total day
     *
     * @property totalDay
     * @type {string}
     * @default  ''
     */
    totalDay: Ember.computed('date', 'morningStartHour', 'morningEndHour', 'afternoonStartHour', 'afternoonEndHour', function () {
      return (0, _apphouraccountshouraccountfragment.computeTotalDay)(this, 'h');
    }).readOnly(),
    /**
     * Break
     *
     * @property break
     * @type {string}
     * @default  ''
     */
    break: Ember.computed('date', 'morningEndHour', 'afternoonStartHour', function () {
      return (0, _apphouraccountshouraccountfragment.computeBreak)(this, 'h');
    }).readOnly(),
    dayType: Ember.computed('day', function () {
      return ![0, 6].includes(this.get('day')) ? 'normal' : 'weekend';
    }).readOnly(),
    dayTitle: Ember.computed('day', 'i18n_locale', function () {
      var testDate = (0, _moment.default)();
      return testDate.day(this.get('day')).format('dddd');
    }).readOnly()
  });
  var _default = _exports.default = _resource.default.extend({
    contractTypes: _emberData.default.attr(),
    daySettings: (0, _attributes.fragmentArray)('apphouraccountsdaysettingsfragment'),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'resource',
    /**
     * Création et récupération d'une fausse ligne de configuration
     * @param day
     * @return {FakeSetting}
     */
    getFakeDaySetting: function getFakeDaySetting(day) {
      return FakeSetting.create({
        day: day
      });
    },
    /**
     * Mise à jour d'un décompte horaire.
     * Création de celui-ci s'il n'existe pas ou suppression de celui-ci si toutes les valeurs sont vides.
     * @param day
     * @param field
     * @param value
     */
    updateDaySetting: function updateDaySetting(day, field, value) {
      var daySetting = this.findDaySetting(day.day) || this.createDaySetting(day.day);
      if (value) daySetting.set(field, value.format('HH:mm'));else daySetting.set(field, null);
      if (!daySetting.get('morningStartHour') && !daySetting.get('morningEndHour') && !daySetting.get('afternoonStartHour') && !daySetting.get('afternoonEndHour')) {
        this.get('daySettings').removeFragment(daySetting);
        return this.getFakeDaySetting(day.day);
      }
      return daySetting;
    },
    /**
     * Création d'une configuration horaire pour un jour donné
     * @param day
     * @return {MF.Fragment}
     */
    createDaySetting: function createDaySetting(day) {
      return this.get('daySettings').createFragment({
        day: day
      });
    },
    findDaySetting: function findDaySetting(day) {
      return this.get('daySettings').findBy('day', day);
    }
  });
});