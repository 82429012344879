define("boondmanager/services/tasks", ["exports", "boondmanager/models/form", "boondmanager/models/appentity", "boondmanager/models/esignature", "boondmanager/models/invoice"], function (_exports, _form, _appentity, _esignature, _invoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ADDITIONAL_TASK_REMIND_FORM_ACTION = _exports.ADDITIONAL_TASK_INVOICE = _exports.ADDITIONAL_TASK_FORM = _exports.ADDITIONAL_TASK_ESIGNATURE = _exports.ADDITIONAL_TASK_CREDITNOTE = _exports.ADDITIONAL_TASK_APPENTITY = void 0;
  var ADDITIONAL_TASK_ESIGNATURE = _exports.ADDITIONAL_TASK_ESIGNATURE = 'esignature';
  var ADDITIONAL_TASK_FORM = _exports.ADDITIONAL_TASK_FORM = 'form';
  var ADDITIONAL_TASK_APPENTITY = _exports.ADDITIONAL_TASK_APPENTITY = 'appentity';
  var ADDITIONAL_TASK_REMIND_FORM_ACTION = _exports.ADDITIONAL_TASK_REMIND_FORM_ACTION = 'remindFormAction';
  var ADDITIONAL_TASK_INVOICE = _exports.ADDITIONAL_TASK_INVOICE = 'invoice';
  var ADDITIONAL_TASK_CREDITNOTE = _exports.ADDITIONAL_TASK_CREDITNOTE = 'creditnote';
  var _default = _exports.default = Ember.Service.extend({
    /** SERVICES **/
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),
    /** PROPERTIES **/

    /**
     * Liste de tâches
     *
     * @property tasks
     * @type {Object}
     * @default null
     */
    tasks: Ember.A([]),
    /**
     * Liste de tâches additionelles
     *
     * @property additionalTasks
     * @type {Object}
     * @default null
     */
    additionalTasks: Ember.A([]),
    /**
     * Liste de tâches visible ou non
     *
     * @property isTodolistDisplayed
     * @type {Boolean}
     * @default true
     */
    isTodolistDisplayed: false,
    /**
     * Tache checked visible ou non
     *
     * @property isCheckedTasksDisplayed
     * @type {Boolean}
     * @default true
     */
    isCheckedTasksDisplayed: false,
    /**
     *
     *
     * @property reload
     * @type {Boolean}
     * @default false
     */
    reload: false,
    /**
     *
     *
     * @property loading
     * @type {Boolean}
     * @default false
     */
    loading: false,
    /*** COMPUTED ***/

    /**
     * Nombre de tâches non validées (on ne prend pas en compte les tâches filles)
     */
    pendingTasksCount: Ember.computed('tasks.@each.validatedAt', 'pendingAdditionalTasksCount', function () {
      return Ember.A(this.get('tasks')).filterBy('validatedAt', '').length + this.get('pendingAdditionalTasksCount');
    }).readOnly(),
    /**
     * Nombre de tâches validées
     */
    pendingAdditionalTasksCount: Ember.computed('additionalTasks.[]', function () {
      return this.get('additionalTasks').reduce(function (sum, additionalTask) {
        sum += additionalTask.isValidated ? 0 : 1;
        return sum;
      }, 0);
    }).readOnly(),
    hasTasks: Ember.computed('tasks.[]', 'additionalTasks.[]', function () {
      return this.get('tasks.length') || this.get('additionalTasks.length');
    }).readOnly(),
    totalTasksCount: Ember.computed('tasks.[]', 'additionalTasks.[]', function () {
      return this.get('tasks.length') + this.get('additionalTasks.length');
    }).readOnly(),
    /** METHODS **/loadTasks: function loadTasks(model, writeRights, params) {
      var _this = this;
      if (model.get('id') && writeRights) {
        this.set('loading', true);
        if (typeof params === 'undefined') {
          params = {
            id: model.get('id'),
            endpoint: 'tasks'
          };
        }

        // Ici, quotation et advanced-candidates sont des apps, l'url est différente
        if (model.get('modelName') === 'quotation') {
          params.app = 'quotations';
          params.type = 'quotations';
        } else if (model.get('modelName') === 'appadvancedcandidatescandidate') {
          params.app = 'advanced-candidates';
          params.type = 'candidates';
        }
        this.get('store').query(model.get('modelName'), params).then(function (tasks) {
          tasks.forEach(function (task) {
            task.set('forceVisible', false);
            task.get('children').forEach(function (child) {
              child.set('forceVisible', false);
            });
          });
          _this.setTasks(tasks);
        }).catch(function () {
          //On catch l'erreur pour ne pas planter le front en cas de 403 (ce qui peut arriver notamment lors de la création de fiche sur un périmètre interdit)
        }).finally(function () {
          _this.set('loading', false);
        });
      } else {
        this.reset();
      }
    },
    setTasks: function setTasks(tasks) {
      this.set('tasks', tasks);
      this.set('additionalTasks', this.buildAdditionalTasks(tasks));
      if (!this.get('isTodolistDisplayed')) {
        if (this.get('pendingTasksCount') > 0 || this.get('hasEsignaturNotClosed')) {
          this.showTodolist();
        } else {
          this.hideTodolist();
        }
      } else if (!this.get('pendingTasksCount') && !this.get('reload') && !this.get('isCheckedTasksDisplayed') || !this.get('hasTasks')) {
        this.hideTodolist();
      }
      this.setReload(false);
    },
    buildAdditionalTasks: function buildAdditionalTasks(tasks) {
      var _this2 = this;
      var metaTasks = tasks.meta.additionalTasks || [];
      var additionalTasks = [];
      metaTasks.forEach(function (task) {
        var additionalTask;
        switch (task.type) {
          case ADDITIONAL_TASK_ESIGNATURE:
            {
              var stateList = _this2.get('i18n').t('appesignature.states', {
                returnObjects: true
              });
              var currentState = stateList.findBy('id', task.state);
              additionalTask = Ember.assign({
                title: _this2.get('i18n').t('common:appesignature.todolistTitle', {
                  filename: task.file.name
                }),
                stateIcon: currentState ? currentState.icon : 'bmi-hourglass-half',
                isValidated: task.state === _esignature.ESIGNATURE_STATE_SIGNED
              }, task);
              break;
            }
          case ADDITIONAL_TASK_FORM:
            {
              var _stateList = _this2.get('i18n').t('forms.states', {
                returnObjects: true
              });
              var _currentState = _stateList.findBy('id', task.state);
              additionalTask = Ember.assign({
                title: _currentState.todolistTitle,
                stateIcon: _currentState ? _currentState.icon : 'bmi-hourglass-half',
                isValidated: task.state === _form.FORM_STATE_VALIDATED
              }, task);
              if (task.button) {
                additionalTask.button = Ember.assign({
                  title: _this2.get('i18n').t('common:actions.remind'),
                  icon: 'bmi-notification-full'
                }, task.button);
              }
              break;
            }
          case ADDITIONAL_TASK_APPENTITY:
            {
              var _stateList2 = _this2.get('i18n').t('appentities:view.validations', {
                returnObjects: true
              });
              var _currentState2 = _stateList2.findBy('id', task.state);
              if ([_appentity.APPENTITY_GUEST_PENDING, _appentity.APPENTITY_GUEST_VALIDATED].includes(task.state)) {
                additionalTask = Ember.assign({
                  title: _currentState2.todolistTitle,
                  stateIcon: _currentState2 ? _currentState2.icon : 'bmi-hourglass-half',
                  isValidated: task.state === _appentity.APPENTITY_GUEST_VALIDATED
                }, task);
              }
              if ([_appentity.APPENTITY_MANAGER_PENDING, _appentity.APPENTITY_MANAGER_VALIDATED].includes(task.state)) {
                additionalTask = Ember.assign({
                  title: _currentState2.todolistTitle,
                  stateIcon: _currentState2 ? _currentState2.icon : 'bmi-hourglass-half',
                  isValidated: task.state === _appentity.APPENTITY_MANAGER_VALIDATED
                }, task);
              }
              break;
            }
          case ADDITIONAL_TASK_INVOICE:
          case ADDITIONAL_TASK_CREDITNOTE:
            {
              var _stateList3 = _this2.get('i18n').t('invoices:view.tasks.states', {
                returnObjects: true
              });
              var _currentState3 = _stateList3.findBy('id', task.subtype + '_' + task.state);
              var title = _currentState3.invoiceTodolistTitle;
              if (ADDITIONAL_TASK_CREDITNOTE === task.type) {
                title = _currentState3.creditnoteTodolistTitle;
              }
              additionalTask = Ember.assign({
                title: title,
                stateIcon: _currentState3 ? _currentState3.icon : 'bmi-check-circle-light',
                isValidated: task.state === _invoice.INVOICE_TASK_STATE_VALIDATED
              }, task);
              break;
            }
        }
        if (additionalTask) additionalTasks.push(additionalTask);
      });
      return additionalTasks;
    },
    setReload: function setReload(reload) {
      this.set('reload', reload);
    },
    showTodolist: function showTodolist() {
      this.set('isTodolistDisplayed', true);
    },
    hideTodolist: function hideTodolist() {
      this.set('isTodolistDisplayed', false);
    },
    toggleTodolist: function toggleTodolist() {
      this.set('isTodolistDisplayed', !this.get('isTodolistDisplayed'));
    },
    showCheckedTasks: function showCheckedTasks() {
      this.set('isCheckedTasksDisplayed', true);
    },
    toggleCheckedTasks: function toggleCheckedTasks() {
      this.set('isCheckedTasksDisplayed', !this.get('isCheckedTasksDisplayed'));
    },
    reset: function reset() {
      this.set('tasks', Ember.A([]));
      this.set('additionalTasks', Ember.A([]));
      this.set('isTodolistDisplayed', false);
      this.set('reload', false);
    },
    manageChipClick: function manageChipClick(router, route) {
      if (router.get('currentRouteName') !== route) {
        router.transitionTo(route);
        this.showTodolist();
        if (!this.get('pendingTasksCount')) {
          this.showCheckedTasks();
        }
      } else if (!this.get('pendingTasksCount')) {
        this.toggleTodolist();
        this.showCheckedTasks();
      }
    },
    check: function check(task, profile, profileId, parentId) {
      var _this3 = this;
      var adapterOptions = {
        id: task.get('id'),
        pathForType: 'tasks',
        endpoint: 'check',
        queryParams: this._buildQueryParams(profile, profileId)
      };
      task.save({
        adapterOptions: adapterOptions
      }).then(function () {
        _this3._reloadParentTask(task, profile, profileId, parentId);
      }).catch(function () {
        _this3._reloadParentTask(task, profile, profileId, parentId);
      });

      // On indique que cette tâche ne doit pas être masquable
      task.set('forceVisible', true);
    },
    uncheck: function uncheck(task, profile, profileId, parentId) {
      var _this4 = this;
      var adapterOptions = {
        id: task.get('id'),
        pathForType: 'tasks',
        endpoint: 'uncheck',
        queryParams: this._buildQueryParams(profile, profileId)
      };
      task.save({
        adapterOptions: adapterOptions
      }).then(function () {
        _this4._reloadParentTask(task, profile, profileId, parentId);
      }).catch(function () {
        _this4._reloadParentTask(task, profile, profileId, parentId);
      });
    },
    _buildQueryParams: function _buildQueryParams(profile, profileId) {
      var queryParams = [];
      if (profile === 'appadvancedcandidatescandidate') {
        profile = 'candidate';
      }
      if (profile === 'appentity') {
        profile = 'appEntity';
      }
      queryParams[profile] = profileId;
      return queryParams;
    },
    _reloadParentTask: function _reloadParentTask(task, profile, profileId, parentId) {
      var _this5 = this;
      if (parentId) {
        var queryParams = this._buildQueryParams(profile, profileId);
        queryParams.id = parentId;
        this.get('store').queryRecord('task', queryParams).then(function (parentTask) {
          parentTask.set('forceVisible', true);
          _this5._checkIfAllTasksAreChecked();
        });
      } else {
        this._checkIfAllTasksAreChecked();
      }
    },
    _checkIfAllTasksAreChecked: function _checkIfAllTasksAreChecked() {
      if (this.get('pendingTasksCount') === 0) {
        this.get('notify').success(this.get('i18n').t('admintodolists:view.saveSuccess'), this.get('i18n').t('admintodolists:view.saveSuccessTitle'));
        this.hideTodolist();
      }
    }
  });
});