define("boondmanager/models/group", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    originalID: _emberData.default.attr('string'),
    recipients: (0, _attributes.fragment)('sharedentityfragment'),
    inputRecipients: Ember.A([])
  });
});