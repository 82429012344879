define("boondmanager/pods/components/octane/bm-rights-filters/fields/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+odZCFAz",
    "block": "{\"symbols\":[\"@displayNbSelected\",\"@disabled\",\"@filter\"],\"statements\":[[1,[30,[36,3],null,[[\"name\",\"selected\",\"initialSelect\",\"onChange\",\"multiple\",\"userSubscriptions\",\"placeholder\",\"renderInPlace\",\"disableCheckAll\",\"disabled\",\"displayNbSelected\",\"addMissingSelected\"],[\"account\",[32,3,[\"modalValue\"]],[32,3,[\"modalValue\"]],[32,0,[\"update\"]],true,null,[30,[36,2],[\"common:none\"],[[\"context\"],[\"account\"]]],true,true,[32,2],[30,[36,1],[[30,[36,0],[[32,1],false],null]],null],false]]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"not\",\"t\",\"bm-field/account\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-rights-filters/fields/manager/template.hbs"
  });
});