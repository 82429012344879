define("boondmanager/pods/components/octane/bm-rights-filters/fields/multiple-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0WSa92Bs",
    "block": "{\"symbols\":[\"@disabled\",\"@displayNbSelected\",\"@filter\"],\"statements\":[[1,[30,[36,3],null,[[\"addMissingSelected\",\"name\",\"options\",\"selected\",\"forceSearch\",\"multiple\",\"renderInPlace\",\"onchange\",\"placeholder\",\"disableCheckAll\",\"displayNbSelected\",\"disabled\"],[false,[32,3,[\"label\"]],[32,3,[\"options\"]],[32,3,[\"modalValue\"]],false,true,true,[32,0,[\"onChange\"]],[30,[36,2],[\"common:none\"],[[\"context\"],[[32,3,[\"placeholderContext\"]]]]],true,[30,[36,1],[[30,[36,0],[[32,2],false],null]],null],[32,1]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"not\",\"t\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-rights-filters/fields/multiple-select/template.hbs"
  });
});