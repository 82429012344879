define("boondmanager/models/time", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes", "moment", "boondmanager/models/workunittype"], function (_exports, _base, _emberData, _emberDataCopyable, _attributes, _moment, _workunittype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Time = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['timesReport', 'delivery', 'project', 'batch']
    }),
    // Attributes
    startDate: _emberData.default.attr('moment'),
    endDate: _emberData.default.attr('moment'),
    exceptionalRules: _emberData.default.attr(),
    category: _emberData.default.attr('string'),
    workUnitType: (0, _attributes.fragment)('workunittype'),
    recovering: _emberData.default.attr('boolean'),
    calculationMethod: _emberData.default.attr('string'),
    cost: _emberData.default.attr('number'),
    transferPriceCostExcludingTax: _emberData.default.attr('number'),
    priceExcludingTax: _emberData.default.attr('number'),
    processed: _emberData.default.attr('boolean'),
    canGenerateFinancialData: _emberData.default.attr('boolean'),
    // Relationships
    timesReport: _emberData.default.belongsTo('timesreport'),
    delivery: _emberData.default.belongsTo(),
    project: _emberData.default.belongsTo(),
    batch: _emberData.default.belongsTo(),
    entityName: Ember.computed('reference', function () {
      var reference = this.get('reference');
      if (Ember.isBlank(reference)) {
        return this.get('internalReference');
      } else {
        return reference;
      }
    }),
    datesLabel: Ember.computed('startDate', 'endDate', 'isExceptionalTime', function () {
      var startDay = this.get('startDate').format('DD/MM/YY');
      var endDay = this.get('endDate').format('DD/MM/YY');
      if (this.get('isExceptionalTime')) {
        return startDay + ' ' + this.get('startDate').format('HH:mm') + ' - ' + (startDay === endDay ? '' : endDay + ' ') + this.get('endDate').format('HH:mm');
      } else {
        return startDay + (startDay === endDay ? '' : ' - ' + endDay);
      }
    }),
    isExceptionalTime: Ember.computed('workUnitType.activityType', function () {
      return this.get('workUnitType.activityType') === _workunittype.WUT_EXCEPTIONAL_TIME;
    }),
    isExceptionalCalendar: Ember.computed('workUnitType.activityType', function () {
      return this.get('workUnitType.activityType') === _workunittype.WUT_EXCEPTIONAL_CALENDAR;
    }),
    duration: Ember.computed('startDate', 'endDate', 'workUnitType', function () {
      var _this$getProperties = this.getProperties('startDate', 'endDate', 'isExceptionalCalendar'),
        startDate = _this$getProperties.startDate,
        endDate = _this$getProperties.endDate,
        isExceptionalCalendar = _this$getProperties.isExceptionalCalendar;
      if (startDate && endDate) {
        var durationUtcOffset = endDate.utcOffset() - startDate.utcOffset(); //minutes
        var duration = _moment.default.duration(endDate.diff(startDate)).add(_moment.default.duration(durationUtcOffset, 'minutes'));
        if (isExceptionalCalendar && endDate.hour() === 0) {
          // add + 1 day because we have to include endDate day in diff and endDate is defined as begining of the day (00:00).
          // ex: if startDate = 2007-08-09T00:00:00 and endDate = 2007-08-10T00:00:00 so diff = 1 day but we need 2 ;)
          return duration.add(_moment.default.duration(1, 'days'));
        } else {
          return duration;
        }
      }
    }).readOnly(),
    durationType: Ember.computed('duration', 'isExceptionalTime', function () {
      return this.get('isExceptionalTime') ? 'hours' : 'days';
    }),
    durationMoment: Ember.computed('duration', 'durationType', function () {
      var duration = _moment.default.duration(0);
      if (this.get('durationType') === 'hours') {
        duration.add(this.get('duration'));
      } else {
        duration.add(this.get('duration'));
      }
      return duration;
    }),
    exceptionalRulesTooltip: Ember.computed('exceptionalRules', function () {
      var tooltip = '';
      this.get('exceptionalRules').forEach(function (rule) {
        tooltip += '<tr><td>' + Ember.get(rule, 'name') + '<div class="bm-text-secondary">' + Ember.get(rule, 'scale.dependsOn.name') + '</div></td><td>x ' + Ember.get(rule, 'quantity') + '</td></tr>';
      });
      return tooltip ? '<table class="bm-tooltip-table">' + tooltip + '</table>' : '';
    })
  });
  Time.reopenClass({
    prefix: 'TPSEXP'
  });
  Time.reopen({
    prefix: Ember.computed(function () {
      return Time.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Time;
});