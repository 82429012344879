define("boondmanager/pods/components/bm-field/customized/wemailing-settings/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "boondmanager/models/appemailingresource"], function (_exports, _component, _appemailingresource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  var CUSTOMIZED = 'customized';
  var _default = _exports.default = _component.default.extend({
    ajax: Ember.inject.service(),
    classNames: ['bmc-field-customized-wemailing'],
    classNameBindings: ['disabled:disabled'],
    resetPassword: false,
    canCustomized: true,
    locked: true,
    edition: Ember.computed('disabled', 'locked', function () {
      return !this.get('disabled') && !this.get('locked');
    }).readOnly(),
    selected: Ember.computed('resource.transactionalEmailsSendingType', function () {
      return this.get('resource.transactionalEmailsSendingType') !== _appemailingresource.TRANSACTIONAL_SENDING_TYPE_DEFAULT ? CUSTOMIZED : _appemailingresource.TRANSACTIONAL_SENDING_TYPE_DEFAULT;
    }).readOnly(),
    isSmtpSettings: Ember.computed('resource.transactionalEmailsSendingType', 'isCustomizedSelected', function () {
      return this.get('resource.transactionalEmailsSendingType') === _appemailingresource.TRANSACTIONAL_SENDING_TYPE_SMTP;
    }).readOnly(),
    isMandrillSettings: Ember.computed('resource.transactionalEmailsSendingType', function () {
      return this.get('resource.transactionalEmailsSendingType') === _appemailingresource.TRANSACTIONAL_SENDING_TYPE_MANDRILL;
    }).readOnly(),
    showResetButton: Ember.computed('edition', 'resetPassword', function () {
      return !this.get('resetPassword') && this.get('edition');
    }).readOnly(),
    mandrillApiKeyChanged: Ember.computed('resource.transactionalEmailsMandrillApiKey', function () {
      return this.get('resource').changedAttributes().transactionalEmailsMandrillApiKey;
    }).readOnly(),
    mandrillFilled: Ember.computed('resource.transactionalEmailsMandrillApiKey', function () {
      return !!this.get('resource.transactionalEmailsMandrillApiKey');
    }).readOnly(),
    isCustomizedSelected: Ember.computed('selected', function () {
      return this.get('selected') === CUSTOMIZED;
    }).readOnly(),
    customizedEdition: Ember.computed('selected', 'edition', function () {
      return this.get('edition') && this.get('selected') === CUSTOMIZED;
    }).readOnly(),
    mandrillDomains: Ember.computed('mandrillApiKeyChanged', 'mandrillFilled', function () {
      var _this = this;
      var i18n = this.get('i18n');
      this.set('warning', null);
      this.set('warningType', null);
      // get cache domains if user has already test this key and change settings without changing api key
      var promise = Ember.RSVP.resolve(this.get('mandrillDomainsTests'));
      if (this.get('mandrillFilled') && (this.get('mandrillApiKeyChanged') || !this.get('mandrillDomainsTests.length'))) {
        // if user has changed api key, re-launch tests
        var apiUrl = 'https://mandrillapp.com/api/1.0/senders/domains.json';
        var apiKey = this.get('resource.transactionalEmailsMandrillApiKey');
        promise = this.get('ajax').request(apiUrl, {
          method: 'POST',
          data: {
            key: apiKey
          }
        }).then(function (results) {
          var domains = [];
          if (results.length) {
            results.forEach(function (result) {
              domains.push({
                name: result.domain,
                isSpfValid: result.spf.valid,
                isDkimValid: result.dkim.valid
              });
            });

            // add tests results in cache to avoid relaunch tests if not necessary
            _this.set('mandrillDomainsTests', domains);
            return domains;
          } else {
            _this.set('warning', i18n.t('wemailing:settings.mandrill.warnings.noDomain'));
            _this.set('warningType', 'warning');
          }
          return results;
        }).catch(function () {
          _this.set('warning', i18n.t('wemailing:settings.mandrill.warnings.requestError'));
          _this.set('warningType', 'danger');
        });
      }
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('mandrillDomainsTests', []);
      this.set('warning', null);
      this.set('warningType', null);
    },
    /**
     * Init port and protocol to default value if not defined
     * @private
     */
    _initPortProtocol: function _initPortProtocol() {
      if (!this.get('resource.transactionalEmailsServerPort')) {
        this.set('resource.transactionalEmailsServerPort', _appemailingresource.TRANSACTIONAL_DEFAULT_PORT);
      }
      if (!this.get('resource.transactionalEmailsServerProtocol')) {
        this.set('resource.transactionalEmailsServerProtocol', _appemailingresource.TRANSACTIONAL_DEFAULT_PROTOCOL);
      }
      if (!this.get('resource.transactionalEmailsServerLogin')) {
        this.set('resetPassword', true);
      }
    },
    isBlankValue: function isBlankValue() {
      return typeof this.get('selected') === 'undefined' || this.get('selected') === null;
    },
    actions: {
      activateResetPassword: function activateResetPassword() {
        this.set('resetPassword', true);
      },
      toggleLock: function toggleLock() {
        if (!this.get('disabled')) {
          this.toggleProperty('locked');
        }
      },
      onUpdateSettings: function onUpdateSettings(field, value) {
        this.set("resource.".concat(field), value);
        if (field === 'transactionalEmailsSendingType' && value === _appemailingresource.TRANSACTIONAL_SENDING_TYPE_SMTP) {
          this._initPortProtocol();
        }
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange(field, value);
        }
      },
      updateSelected: function updateSelected(value) {
        if (value !== _appemailingresource.TRANSACTIONAL_SENDING_TYPE_DEFAULT) {
          // by default, set to smtp custom configuration and clear all fields
          if (this.get('resource.transactionalEmailsSendingType') === _appemailingresource.TRANSACTIONAL_SENDING_TYPE_DEFAULT) {
            value = _appemailingresource.TRANSACTIONAL_SENDING_TYPE_GMAIL;
            this._initPortProtocol();
          } else {
            value = this.get('resource.transactionalEmailsSendingType');
          }
        } else {
          // reset values for resource
          this.set('resetPassword', false);
          var changedAttributes = this.get('resource').changedAttributes();
          if (changedAttributes.transactionalEmailsSendingType) {
            this.set('resource.transactionalEmailsSendingType', changedAttributes.transactionalEmailsSendingType[0]);
          }
          if (changedAttributes.transactionalEmailsServerUrl) {
            this.set('resource.transactionalEmailsServerUrl', changedAttributes.transactionalEmailsServerUrl[0]);
          }
          if (changedAttributes.transactionalEmailsServerLogin) {
            this.set('resource.transactionalEmailsServerLogin', changedAttributes.transactionalEmailsServerLogin[0]);
          }
          if (changedAttributes.transactionalEmailsServerPort) {
            this.set('resource.transactionalEmailsServerPort', changedAttributes.transactionalEmailsServerPort[0]);
          }
          if (changedAttributes.transactionalEmailsServerProtocol) {
            this.set('resource.transactionalEmailsServerProtocol', changedAttributes.transactionalEmailsServerProtocol[0]);
          }
          if (changedAttributes.transactionalEmailsMandrillApiKey) {
            this.set('resource.transactionalEmailsMandrillApiKey', changedAttributes.transactionalEmailsMandrillApiKey[0]);
          }
        }
        this.send('onUpdateSettings', 'transactionalEmailsSendingType', value);
      }
    }
  });
});