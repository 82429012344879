define("boondmanager/controllers/entity", ["exports", "boondmanager/controllers/base", "boondmanager/utils", "boondmanager/utils/logger", "boondmanager/models/base", "boondmanager/models/fieldtemplatefragment"], function (_exports, _base, _utils, _logger, _base2, _fieldtemplatefragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ERROR_GLOBAL_INSUFICIENT_RIGHT = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var ERROR_GLOBAL_INSUFICIENT_RIGHT = _exports.ERROR_GLOBAL_INSUFICIENT_RIGHT = '1004';
  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;

  /**
   * Contrôleur parent des modules de fiches
   *
   * @class  EntityController
   * @extends Ember.Controller
   * @namespace  Ember
   * @module  Controllers
   */
  var _default = _exports.default = _base.default.extend({
    /** SERVICES **/
    router: Ember.inject.service(),
    appTriggers: Ember.inject.service('app-triggers'),
    notifications: Ember.inject.service(),
    threadService: Ember.inject.service('thread'),
    appsSections: Ember.inject.service('apps-sections'),
    mailtoProvider: Ember.inject.service('mailto-provider'),
    /** PROPERTIES **/
    /**
     * Activation de la sauvegarde du modèle
     *
     * @property activateSaveModel
     * @type {boolean}
     * @default true
     */
    activateSaveModel: true,
    /**
     * Object Ember Transition pour récupérer les paramètres lors du save
     *
     * @property transition
     * @type {Object}
     * @default null
     */
    transition: null,
    /**
     * Attribut du modèle utilisé pour générer les messages de notifications
     *
     * @property modelReference
     * @type {string}
     * @default 'attributeForSaveNotifications'
     */
    modelReference: 'attributeForSaveNotifications',
    /**
     * adapterOptions à passer lors du save. Calculé automatiquement si non surchargé
     *
     * @property adapterOptions
     * @type {Object}
     * @default null
     */
    adapterOptions: null,
    /**
     * Onglet courant pour le modèle courant (à surcharger uniquement si spécifique)
     * Cette propriété sert lors de la sauvegarde des entités pour définir le endpoint
     *
     * @property currentTabName
     * @type {string}
     * @default ''
     */
    currentTabName: '',
    /**
     * Booléen définissant si la sauvegarde a été lancée et si le process de sauvegarde est
     * avant le lancement du save du modèle (pre-hook)
     *
     * @property isBeforeSaving
     * @type {boolean}
     * @default false
     */
    isBeforeSaving: false,
    /**
     * Booléen définissant si la sauvegarde a été lancée et si le process de sauvegarde est
     * après le lancement du save du modèle (post-hook)
     *
     * @property isAfterSaving
     * @type {boolean}
     * @default false
     */
    isAfterSaving: false,
    /**
     * Alias vers le booléen qui permet de savoir si les apps autorisent la sauvegarde du modèle ou pas
     *
     * @property enableSaveFromApp
     * @type {boolean}
     */
    enableSaveFromApp: Ember.computed.alias('appTriggers.enableSaveFromApp'),
    /**
     * Nom du module
     * Utile pour récupérer les droits par model d'un module
     *
     * @property rightsModule
     * @type {null|string}
     * @default null
     */
    rightsPath: null,
    /**
     * Booléen pour l'affichage de la modale pour saisir un motif et un commentaire
     *
     * @property showAddStateReasonModal
     * @type boolean
     * @default false
     */
    showAddStateReasonModal: false,
    /**
     * State reason indifferent key
     * integer
     * */
    stateReasonIndifferentKey: _base2.STATE_REASON_INDIFFERENT_KEY,
    /** COMPUTED **/
    /**
     * Route rights
     */
    entityRights: Ember.computed('rights.lastUpdate', function () {
      return this.get('rightsPath') !== null ? this.get('rights').getEntityRights(this.get('rightsPath')) : null;
    }).readOnly(),
    /**
     * Current model accessor
     * Can be overriden if model is not in "model" namespace
     * @return {String}
     */
    currentModel: Ember.computed('model', function () {
      return this.get('model');
    }).readOnly(),
    currentModule: Ember.computed('router.currentRoute', function () {
      return this.get('router.currentRoute.name').split('.').filter(function (part) {
        return part !== 'private';
      })[0];
    }),
    /**
     * Get current tab of navigation to determine if current entity is tabbed or not
     *
     * @return {String}
     */
    currentTab: Ember.computed('router.currentRoute.name', 'currentTabName', function () {
      if (this.currentTabName !== '') {
        return this.currentTabName;
      }
      // test if tab is active, if its, set endpoint to the current tab
      var routeNameParts = this.get('router.currentRoute.name').split('.');
      // if first element is 'private', remove it :)
      if (routeNameParts.length && routeNameParts[0] === 'private') {
        routeNameParts.shift();
      }
      var _routeNameParts = _slicedToArray(routeNameParts, 3),
        /*module*/ /*submodule*/tab = _routeNameParts[2];
      return tab;
    }).readOnly(),
    isSaving: Ember.computed('currentModel.isSaving', 'isBeforeSaving', 'isAfterSaving', function () {
      return this.get('currentModel.isSaving') || this.get('isBeforeSaving') || this.get('isAfterSaving');
    }).readOnly(),
    disableSaveButton: Ember.computed('enableSaveFromApp', 'currentModel.hasDirtyAttributes', 'saveButtonEnabled', function () {
      return !this.get('enableSaveFromApp') && !this.get('currentModel.hasDirtyAttributes') && !this.get('saveButtonEnabled');
    }),
    /** METHODS */
    /**
     * Endpoint adapter options for saving method
     *
     * @return {Object}
     * @private
     */
    _getAdapterOptions: function _getAdapterOptions() {
      var adapterOptions = this.get('adapterOptions');
      // if endpoint is overrided, take override value instead of current tab value
      if (!adapterOptions) {
        var tab = this.get('currentTab');
        var isTabbedEntity = this._isTabbedEntity(tab);
        if (isTabbedEntity) {
          adapterOptions = {
            adapterOptions: {
              endpoint: tab
            }
          };
        }
      }
      return adapterOptions ? adapterOptions : {};
    },
    /**
     * Test if current tab if from tabbed entity or not
     * @param {String} tab
     * @returns {boolean}
     * @private
     */
    _isTabbedEntity: function _isTabbedEntity(tab) {
      return tab !== 'index' && tab !== false;
    },
    /**
     * Exécution du hook "onBeforeSave" des applications liées à la page avant la sauvegarde du modèle courant
     * @return {Promise<T> | Promise<T> | Promise<any> | Promise<T>}
     */
    onBeforeSave: function onBeforeSave() {
      var _this = this;
      this.set('isBeforeSaving', true);
      return this.get('appTriggers').onBeforeSave().finally(function () {
        _this.set('isBeforeSaving', false);
      });
    },
    /**
     * Exécution du hook "onAfterSave" des applications liées à la page après la sauvegarde du modèle courant
     * @param model
     * @return {Promise<T> | Promise<T> | Promise<any> | Promise<T>}
     */
    onAfterSave: function onAfterSave(model) {
      var _this2 = this;
      this.set('isAfterSaving', true);
      return this.get('appTriggers').onAfterSave(model).then(function () {
        return model;
      }).finally(function () {
        _this2.set('isAfterSaving', false);
      });
    },
    /**
     * Affichage de la notification de succès après sauvegarde réussie
     * @param savedModel
     */
    sendSuccessNotification: function sendSuccessNotification(savedModel) {
      var model = this.get('mainModel');
      // @FIXME rustine pour le modele resource qui va dans employee
      // @FIXME2 rustine fallback ([`models:${model}.name`, `common:entityCard`]) pour le cas d'un manager
      if (model === 'resource') model = 'employee';
      this.get('notify').success(this.get('i18n').t('common:saveSuccess'), this.get('i18n').t(["models:".concat(model, ".name"), "common:entityCard"]) + ' ' + escapeExpression(savedModel.get(this.get('modelReference'))));
    },
    /**
     * Exécution du process après réussite de la sauvegarde du modèle
     * @param savedModel
     * @param isNew
     * @param params
     * @returns {Promise}
     */
    onSaveSuccess: function onSaveSuccess(savedModel, isNew /*, params*/) {
      // launch notifications
      this.sendSuccessNotification(savedModel);
      var transition = this.get('transition');
      if (isNew) {
        var newTargetTransition = this.replaceRoute(this.get('router.currentRoute.name'), savedModel.get('id'));
        if (transition) {
          return newTargetTransition.then(function () {
            return transition.retry();
          });
        }
      } else if (transition) {
        return transition.retry();
      }
      // On a besoin du rightsPath pour effectuer le reload
      //
      // Sans ce test, si on va sur une fiche ressource (par exemple) et qu'on va sur une vue sans rightsPath
      // le loadRights du service rights utilise les variables routeName et routeParams persistées
      // au niveau du service pour appeler l'api rights
      else if (this.get('rightsPath') !== null) {
        this.send('reloadRights');
      }
      return Ember.RSVP.Promise.resolve(savedModel);
    },
    /**
     * Affichage de la notification d'erreur après sauvegarde en échec
     * @param {Object} error Erreur remontée par le backend (ou JS)
     * @param {Object} model Modèle Ember.Data
     */
    sendErrorNotification: function sendErrorNotification(error, model) {
      var modelName = this.get('mainModel');
      // @FIXME rustine pour le modele resource qui va dans employee
      if (modelName === 'resource') modelName = 'employee';
      this.set('notify.genericErrorModal.lastError.model', modelName);
      this.handleErrorOnRequest(error, this.get('i18n').t('common:saveError'), this.get('i18n').t(["models:".concat(modelName, ".name"), "common:entityCard"]) + ' ' + (escapeExpression(model.get(this.get('modelReference'))) || ''));
    },
    /**
     * Exécution du process à l'échec de la sauvegarde du modèle
     * @param error
     * @returns {Promise}
     */
    onSaveError: function onSaveError(error) {
      var model = this.get('currentModel');
      this.sendErrorNotification(error, model);
      if (model.isNew) {
        var hasInsufficientRights = false;
        if (error.errors && Ember.isArray(error.errors)) {
          hasInsufficientRights = error.errors.some(function (bmError) {
            return bmError.code === ERROR_GLOBAL_INSUFICIENT_RIGHT;
          });
        }
        if (hasInsufficientRights) {
          model.rollbackAttributes();
          this.handle403(error);
        }
      }
      return Ember.RSVP.Promise.resolve(error);
    },
    /**
     * Conditions pour pouvoir lancer la sauvegarde du modèle
     * @returns {boolean}
     */
    canSaveModel: function canSaveModel() {
      return (this.get('enableSaveFromApp') || this.get('currentModel').get('hasDirtyAttributes')) && !this.get('currentModel.isSaving');
    },
    /**
     * Sauvegarde du modèle
     * @return {Promise}
     */
    save: function save() {
      var _this3 = this;
      var adapterOptions = this._getAdapterOptions();
      var model = this.get('currentModel');
      return model.save(adapterOptions).then(function (savedModel) {
        _this3.set('adapterOptions', null);
        return savedModel;
      });
    },
    /**
     * Méthode privée qui exécute le process complet de sauvegarde d'un modèle (checks + hooks + save)
     * @param params
     * @returns {Promise}
     */
    _save: function _save(params) {
      var _this4 = this;
      this.set('transition', (0, _utils.isTransition)(params) ? params : null);
      var isNew = this.get('currentModel.isNew');

      // execute specific actions before saving
      return this.onBeforeSave(params).then(function () {
        return _this4.save().then(function (savedModel) {
          return _this4.onAfterSave(savedModel).then(function () {
            return _this4.onSaveSuccess(savedModel, isNew, params);
          });
        }).catch(function (error) {
          if (!navigator.onLine) {
            _this4.get('notify').error(_this4.get('i18n').t('common:saveErrorConnection'));
          } else if (error.name !== 'TransitionAborted') {
            _logger.default.error("".concat(_this4, " - catch _save error: ").concat(error.message));
            _this4.onSaveError(error);
          }
        });
      }, function /*error*/ () {});
    },
    /**
     * check if a state has reasons
     * @param modelName
     * @param modelState
     * @returns boolean
     */
    _hasReasons: function _hasReasons(modelName, modelState) {
      var dict = this.get('settings').customer.state[modelName];
      return (0, _utils.getReasons)(dict, modelState).length > 0;
    },
    /**
     * check if a state has reasons
     * @returns string
     */
    hasStateReason: Ember.computed('currentModel.{modelName,state}', function () {
      var modelName = this.get('currentModel.modelName') === 'appadvancedcandidatescandidate' ? 'candidate' : this.get('currentModel.modelName');
      return this._hasReasons(modelName, this.get('currentModel.state'));
    }).readOnly(),
    /** ACTIONS **/
    actions: {
      /**
       * Execute process before execute saving
       * Test if save is allowed
       * @param {Transition|MouseEvent} params
       * @returns {Promise}
       */
      save: function save(params) {
        if (this.get('activateSaveModel') && this.canSaveModel()) {
          return this._save(params);
        }
      },
      showThreadSidebar: function showThreadSidebar() {
        this.threadService.initThreadSidebar(this.get('currentModel'));
      } /*threads*/,
      hideThreadSidebar: function hideThreadSidebar() {
        this.get('threadService').set('showThreadSidebar', false);
        this.get('threadService').set('currentThreadId', null);
      },
      initThreadSidebar: function initThreadSidebar(reload) {
        this.get('threadService').resetThreadValues();
        this.get('threadService').initThreads(reload);
      },
      seeOlderThreads: function seeOlderThreads() {
        this.get('threadService').goToOlderThreads();
      },
      seeNewestThreads: function seeNewestThreads() {
        this.get('threadService').goToNewestThreads();
      },
      /**
       * Gestion de l'affichage de la modale pour compléter la fiche
       */
      openStateReasonModal: function openStateReasonModal() {
        this.set('saveAfterValidateStateReasonModal', false);
        this.set('showAddStateReasonModal', true);
      },
      /**
       * Comportement à la fermeture de la modale
       * Rajout du motif et du commentaire avant la save
       * @param save
       * @param data
       */
      addStateReason: function addStateReason(save, data) {
        this.set('model.stateReason', data);
        this.set('saveAfterValidateStateReasonModal', false);
        if (save) {
          this.send('save');
        } else {
          this.set('showAddStateReasonModal', false);
        }
      },
      /**
       * Comportement à la fermeture de la modale à l'annulation
       */
      cancelStateReason: function cancelStateReason(reset) {
        this.set('saveAfterValidateStateReasonModal', false);
        this.set('showAddStateReasonModal', false);
        if (reset) {
          this.get('currentModel').rollbackAttributes();
        }
      },
      updateAppEntityModel: function updateAppEntityModel(appentity, data) {
        var response = null;
        var field = null;
        switch (data.attribute) {
          case 'responses':
            response = appentity.get('responses').find(function (response) {
              return response.field.id === data.id;
            });
            if (response) {
              response.set('value', data.value);
            }
            field = appentity.get('app.sections').find(function (section) {
              return section.get('fields').find(function (field) {
                return [_fieldtemplatefragment.FIELD_TOOLS, _fieldtemplatefragment.FIELD_PERIOD, _fieldtemplatefragment.FIELD_PERIODTIME].includes(field.get('typeOf')) && field.get('originalID') === data.id;
              });
            });
            if (field) {
              appentity.send('becomeDirty');
            }
            break;
          default:
            appentity.set(data.attribute, data.value);
            break;
        }
      },
      doAppEntityAction: function doAppEntityAction(appentity, action) {
        var params = null;
        switch (action.type) {
          case 'save':
            this.send('save');
            break;
          case 'sendMail':
            params = {
              routeName: this.get('router.currentRouteName'),
              id: this.get('model.id')
            };
            this.get('mailtoProvider').sendMail(action.value, appentity, params);
            break;
          case 'changeRelationship':
            this.send('changeRelationship', appentity, action.attribute, action.value);
            break;
        }
      },
      saveAppEntityModel: function saveAppEntityModel(appentity) {
        return appentity.save({
          adapterOptions: {
            id: appentity.get('app.id'),
            entity_id: appentity.get('id')
          }
        });
      }
    }
  });
});