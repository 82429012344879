define("boondmanager/models/todolist", ["exports", "boondmanager/models/base", "ember-data-copyable", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberDataCopyable, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Todolist = _base.default.extend(_emberDataCopyable.default, {
    i18n: Ember.inject.service(),
    title: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    profile: _emberData.default.attr('string'),
    profileTypesOf: _emberData.default.attr('array'),
    profileStates: _emberData.default.attr('array'),
    parentProfileTypesOf: _emberData.default.attr('array'),
    parentProfileStates: _emberData.default.attr('array'),
    agencies: _emberData.default.hasMany('agency', {
      inverse: null
    }),
    tasks: (0, _attributes.fragmentArray)('taskfragment'),
    app: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    /**
     *
     */
    countConditions: Ember.computed('profileTypesOf', function () {
      var count = 0;
      if (this.get('profileTypesOf').length) count++;
      if (this.get('profileStates').length) count++;
      if (this.get('parentProfileTypesOf').length) count++;
      if (this.get('parentProfileStates').length) count++;
      return count;
    }).readOnly(),
    /**
     *
     */
    hasSavedTasks: Ember.computed('tasks.[]', function () {
      var savedTasks = this.get('tasks').filter(function (task) {
        return task.originalID;
      });
      return savedTasks && savedTasks.length;
    }).readOnly(),
    taskList: Ember.computed('tasks.[]', function () {
      return this.get('tasks').toArray();
    }).readOnly(),
    profileLabel: Ember.computed('profile', 'app.name', function () {
      var _this = this;
      var profile = this.get('i18n').t('todolists.templates.profiles', {
        returnObjects: true
      }).find(function (item) {
        return item.id === _this.get('profile');
      });
      if (profile) return profile.value;
      return this.get('i18n').t('apps:view.module', {
        name: this.get('app.name')
      });
    })
  });
  Todolist.reopenClass({
    prefix: 'TODOLST'
  });
  Todolist.reopen({
    prefix: Ember.computed(function () {
      return Todolist.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Todolist;
});