define("boondmanager/models/appextractbicustomer", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsExtractBiCustomer = _base.default.extend({
    downloadCenterAnonymousAccess: _emberData.default.attr('boolean'),
    mainManager: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    /**
     * @todo rename to `typeOfID`. It will be easier to create a `typeOf' CP.
     */
    typeOf: _emberData.default.attr('number')
  });
  var _default = _exports.default = AppsExtractBiCustomer;
});