define("boondmanager/models/appansweringvalidatorsansweringmachine", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppAnswering = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['mainManagerToReplace', 'mainManagerSubstitute']
    }),
    // RELATIONSHIPS
    mainManagerToReplace: _emberData.default.belongsTo('resource'),
    mainManagerSubstitute: _emberData.default.belongsTo('resource')
  });
  AppAnswering.reopenClass({
    prefix: 'ANS'
  });
  AppAnswering.reopen({
    prefix: Ember.computed(function () {
      return AppAnswering.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppAnswering;
});