define("boondmanager/pods/components/bm-field/tinymce/thread/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2iQsvkV5",
    "block": "{\"symbols\":[],\"statements\":[[8,\"textarea\",[[16,3,[32,0,[\"name\"]]],[16,0,[32,0,[\"inputClass\"]]],[16,\"onchange\",[30,[36,0],[[32,0],\"onValueChanged\"],null]],[16,\"placeholder\",[30,[36,1],[\"components:bmFieldTinyMCE.threadPlaceholder\"],null]]],[[\"@value\",\"@id\"],[[32,0,[\"value\"]],[32,0,[\"getElementId\"]]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/tinymce/thread/template.hbs"
  });
});