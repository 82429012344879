define("boondmanager/models/expensetype", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.travelExpensesTypeReference = _exports.default = _exports.ExpenseType = _exports.EXPENSE_COLOR_MODULO = void 0;
  var travelExpensesTypeReference = _exports.travelExpensesTypeReference = 0;
  var EXPENSE_COLOR_MODULO = _exports.EXPENSE_COLOR_MODULO = 9;
  var OCRCATEGORY_UNDEFINED = -1;
  var ExpenseType = _exports.ExpenseType = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    settingsService: Ember.inject.service('settings'),
    reference: _emberData.default.attr('number'),
    taxRate: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean'),
    ocrcategory: _emberData.default.attr('number', {
      defaultValue: OCRCATEGORY_UNDEFINED
    }),
    /**
     * Agency Object
     *
     * agency.id            : string
     * agency.name          : string
     *
     * @type {Object}
     */
    agency: _emberData.default.attr(),
    value: Ember.computed('i18n._locale', 'reference', 'name', 'ocrcategory', function () {
      var _this$getProperties = this.getProperties('i18n', 'reference', 'name', 'ocrcategory'),
        i18n = _this$getProperties.i18n,
        reference = _this$getProperties.reference,
        name = _this$getProperties.name,
        ocrcategory = _this$getProperties.ocrcategory;
      // CAUTION: Ember.A(string) return a string and not an array with one item equal to the string !
      // so if 'agencies.expenseTypes' do not exist, Ember.A(i18n.t('agencies.expenseTypes', ...) = 'agencies.expenseTypes'
      var i18nExpenseTypes = Ember.A(i18n.exists('agencies.expenseTypes') ? this.get('settingsService').bm.agencies.expenseTypes : []);
      // CAUTION: reference is a Number and i18n 'id' is a String
      var translation = reference === travelExpensesTypeReference ? i18n.t('models:expense.attributes.isKilometricExpense.name') : i18nExpenseTypes.findBy('id', String(reference));
      return translation || name || reference || ocrcategory;
    }),
    referenceForColor: Ember.computed('reference', function () {
      var index = this.get('reference') % EXPENSE_COLOR_MODULO || EXPENSE_COLOR_MODULO;
      return "expense".concat(index);
    }).readOnly()
  });
  var _default = _exports.default = ExpenseType;
});