define("boondmanager/pods/components/bm-field/contract/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-contract'],
    _getItems: function _getItems() {
      return false;
    } /*input*/,
    actions: {
      customOnChange: function customOnChange(newSelected) {
        if (this.get('errors') && newSelected) {
          this.set('errors', []);
        }
        this.set('showSelect', true);
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange(newSelected);
        }
      }
    }
  });
});