define("boondmanager/models/administrator", ["exports", "ember-data", "boondmanager/models/resource"], function (_exports, _emberData, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Administrator = _resource.default.extend({
    numberOfActivableManagers: _emberData.default.attr('number'),
    numberOfActivatedManagers: _emberData.default.attr('number'),
    numberOfIntranetMax: _emberData.default.attr('number'),
    numberOfIntranet: _emberData.default.attr('number'),
    numberOfResourcesMaxPerManager: _emberData.default.attr('number'),
    quantityOfStorageMax: _emberData.default.attr('number'),
    quantityOfStorage: _emberData.default.attr('number'),
    isBoondManagerAdministrator: _emberData.default.attr('boolean'),
    isSandboxActivated: _emberData.default.attr('boolean'),
    canCreateSandbox: _emberData.default.attr('boolean'),
    sandboxUrl: _emberData.default.attr('string'),
    clientKey: _emberData.default.attr('string'),
    xJwtClientApiAccess: _emberData.default.attr('boolean'),
    clientToken: _emberData.default.attr('string'),
    groupLogo: _emberData.default.attr('string'),
    modules: _emberData.default.attr(),
    boondmanagerContracts: _emberData.default.hasMany('boondmanagercontract', {
      inverse: null
    }),
    /**
     * Nom du modèle associé à la classe pour la couleur de l'état
     */
    stateClassModelName: 'resource'
  });
  Administrator.reopenClass({
    prefix: ''
  });
  Administrator.reopen({
    prefix: Ember.computed(function () {
      return Administrator.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Administrator;
});