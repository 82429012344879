define("boondmanager/models/apphrflowresume", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Resume = _base.default.extend({
    creationDate: _emberData.default.attr('moment'),
    hash: _emberData.default.attr('string'),
    parsingData: _emberData.default.attr(),
    dependsOn: _emberData.default.belongsTo('candidate')
  });
  Resume.reopenClass({
    prefix: 'HIRE'
  });
  Resume.reopen({
    prefix: Ember.computed(function () {
      return Resume.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Resume;
});