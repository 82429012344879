define("boondmanager/models/workunittype", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WorkUnitType = _exports.WUT_STATE_ENABLED = _exports.WUT_STATE_ARCHIVED = _exports.WUT_PRODUCTION = _exports.WUT_INTERNAL = _exports.WUT_EXCEPTIONAL_TIME = _exports.WUT_EXCEPTIONAL_CALENDAR = _exports.WUT_EXCEPTIONAL = _exports.WUT_ABSENCE = _exports.PERMISSIONS_RESTRICTED = _exports.PERMISSIONS_REFUSED = _exports.PERMISSIONS_ALL = void 0;
  var WUT_ABSENCE = _exports.WUT_ABSENCE = 'absence';
  var WUT_INTERNAL = _exports.WUT_INTERNAL = 'internal';
  var WUT_PRODUCTION = _exports.WUT_PRODUCTION = 'production';
  var WUT_EXCEPTIONAL_CALENDAR = _exports.WUT_EXCEPTIONAL_CALENDAR = 'exceptionalCalendar';
  var WUT_EXCEPTIONAL_TIME = _exports.WUT_EXCEPTIONAL_TIME = 'exceptionalTime';
  var WUT_EXCEPTIONAL = _exports.WUT_EXCEPTIONAL = 'exceptional';
  var WUT_STATE_ARCHIVED = _exports.WUT_STATE_ARCHIVED = false;
  var WUT_STATE_ENABLED = _exports.WUT_STATE_ENABLED = true;
  var PERMISSIONS_REFUSED = _exports.PERMISSIONS_REFUSED = 0;
  var PERMISSIONS_ALL = _exports.PERMISSIONS_ALL = 1;
  var PERMISSIONS_RESTRICTED = _exports.PERMISSIONS_RESTRICTED = 2;
  var WorkUnitType = _exports.WorkUnitType = _emberDataModelFragments.default.Fragment.extend({
    settingsService: Ember.inject.service('settings'),
    originalID: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    reference: _emberData.default.attr('number'),
    activityType: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    allowAccessLevel: _emberData.default.attr('number'),
    resourcesTypes: _emberData.default.attr('array'),
    warning: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean'),
    /**
     * Permet d'editer un WUT avec la bm-card en mode edition
     * @property edition
     * @type {boolean}
     */
    edition: false,
    /**
     * Agency Object
     *
     * agency.id            : string
     * agency.name          : string
     *
     * @type {Object}
     */
    agency: _emberData.default.attr(),
    title: Ember.computed('state', 'name', 'reference', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      var title = this.get('reference') + ' - ' + this.get('name');
      if (this.get('state') === WUT_STATE_ARCHIVED) {
        title += " - ".concat(i18n.t('common:archived'));
      }
      return title;
    }).readOnly(),
    resourcesTypesLabel: Ember.computed('allowAccessLevel', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      var label = '';
      if (this.get('allowAccessLevel') === PERMISSIONS_ALL) {
        label += i18n.t('models:workunittype.attributes.excludedResourcesTypes.name');
      } else {
        if (this.get('allowAccessLevel') === PERMISSIONS_RESTRICTED) {
          label += i18n.t('models:workunittype.attributes.allowedResourcesTypes.name');
        }
      }
      return label;
    }).readOnly(),
    resourcesTypesValues: Ember.computed('resourcesTypes', 'i18n._locale', function () {
      var resourcesTypesValues = [];
      var typeOfResources = this.get('settingsService').customer.typeOf.resource;
      this.get('resourcesTypes').forEach(function (resourceTypeId) {
        var resourceTypeData = typeOfResources.find(function (typeOf) {
          return typeOf.id === resourceTypeId;
        });
        if (resourceTypeData) {
          resourcesTypesValues.push(resourceTypeData.value);
        }
      });
      return resourcesTypesValues;
    }).readOnly(),
    allowAccessLevelLabel: Ember.computed('allowAccessLevel', 'resourcesTypes', 'resourcesTypesLabel', 'i18n._locale', function () {
      var i18n = this.get('i18n');
      var allowAccessLevelLabel = '';
      if (this.get('allowAccessLevel') === PERMISSIONS_ALL && this.get('resourcesTypes.length')) {
        allowAccessLevelLabel = Ember.String.htmlSafe(i18n.t('adminagencies:view.tabs.activityexpenses.allResourcesTypesWithRestrictionLabel'));
      } else if (this.get('allowAccessLevel') === PERMISSIONS_ALL || this.get('allowAccessLevel') === PERMISSIONS_RESTRICTED && this.get('resourcesTypes.length') === 0) {
        allowAccessLevelLabel = i18n.t('adminagencies:view.tabs.activityexpenses.allResourcesTypes');
      } else {
        if (this.get('allowAccessLevel') === PERMISSIONS_REFUSED) {
          allowAccessLevelLabel = i18n.t('adminagencies:view.tabs.activityexpenses.noResourcesTypes.label');
        }
      }
      return allowAccessLevelLabel;
    }),
    activityTypeValue: Ember.computed('activityType', 'i18n._locale', function () {
      var _this = this;
      var activityTypes = this.get('settingsService').bm.agency.activityExpenses.timesreports.workunitTypes.activityType.options;
      var activityTypeOption = activityTypes.find(function (activityType) {
        return activityType.id === _this.get('activityType');
      });
      return activityTypeOption ? activityTypeOption.value : this.get('activityType');
    }).readOnly()
  });
  var _default = _exports.default = WorkUnitType;
});