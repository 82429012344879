define("boondmanager/models/expensesreport", ["exports", "ember-copy", "boondmanager/utils/logger", "boondmanager/models/baseactivity", "ember-data", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "moment", "ember-data-copyable", "boondmanager/models/expensetype", "boondmanager/utils/activity", "boondmanager/utils/financial", "boondmanager/models/workunittype"], function (_exports, _emberCopy, _logger, _baseactivity, _emberData, _attributes, _fragment, _moment, _emberDataCopyable, _expensetype, _activity, _financial, _workunittype) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_WON = _exports.STATE_WAITING = _exports.STATE_REJECTED = _exports.STATES = _exports.PAYMENT_WAITING = _exports.PAYMENT_DONE = _exports.INTERNAL_REFERENCE_EXP = _exports.ERROR_CODE_ENTITY_EXISTS = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var PAYMENT_DONE = _exports.PAYMENT_DONE = 'done';
  var PAYMENT_WAITING = _exports.PAYMENT_WAITING = 'waiting';
  /**
   * @module ExpensesReports
   * @submodule ExpensesReport
   */

  // usefull Factories :)
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  var ERROR_CODE_ENTITY_EXISTS = _exports.ERROR_CODE_ENTITY_EXISTS = '3804';
  var INTERNAL_REFERENCE_EXP = _exports.INTERNAL_REFERENCE_EXP = 'EXP';
  var STATE_WAITING = _exports.STATE_WAITING = 0;
  var STATE_REJECTED = _exports.STATE_REJECTED = 1;
  var STATE_WON = _exports.STATE_WON = 2;

  /**
   * Fake Fixed Expense (FFE) object
   *
   * {{#crossLink "FixedExpense"}}fixedExpenses{{/crossLink}} are fragments. But we need
   * FFE objects to represent empty fixedExpense in our
   * {{#crossLink "FixedExpensesRoW/fixedExpenses"}}`fixedexpenses` array{{/crossLink}}
   * defined in each {{#crossLink "FixedExpensesRoW"}}FixedExpenses row{{/crossLink}}
   *
   * @class FakeFixedExpense
   * @namespace BoondManager.ExpensesReport
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var FakeFixedExpense = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Flag to identify this object as a "fake"
     *
     * @property isFake
     * @type {Boolean}
     * @default  true
     * @readOnly
     */
    isFake: Ember.computed(function () {
      return true;
    }).readOnly(),
    isOutOfActivityPeriod: Ember.computed('selectedActivity', 'startDate', function () {
      var _this$getProperties = this.getProperties('selectedActivity', 'startDate'),
        selectedActivity = _this$getProperties.selectedActivity,
        startDate = _this$getProperties.startDate;
      if (selectedActivity && startDate) {
        var isOutOfActivityPeriod = !startDate.isBetween(selectedActivity.get('startDate'), selectedActivity.get('endDate'), 'day', '[]');
        return isOutOfActivityPeriod;
      } else {
        return false;
      }
    }).readOnly(),
    /**
     * Start date
     *
     * @property startDate
     * @type {Moment}
     */

    /**
     * Value
     *
     * @property startDate
     * @type {Moment}
     * @default  ''
     * @readOnly
     */
    value: Ember.computed(function () {
      return '';
    }).readOnly(),
    /**
     * Return a copy of this FakeFixedExpense (FFE) object
     *
     *     const FFECopy = Ember.copy(originalFFE);
     *
     * @method copy
     * @return {FakeFixedExpense} copy of this FFE object
     * @private
     */
    copy: function copy() {
      return FakeFixedExpense.create({
        startDate: this.get('startDate').clone(),
        selectedActivity: this.get('selectedActivity')
      });
    },
    /**
     * Return a POJO representation
     *
     * The POJO has 'isFake', 'startDate' and 'value' properties.
     *
     * CAUTION: `startDate` is not a moment clone! It's the **same** Moment
     *          object present in this FFE object! If you want a POJO as 'deep'
     *          copy, use `POJO = FFEObj.copy().toJSON()` to not share the same
     *          Moment instance beetwen `POJO.startDate` and `FFEObj.startDate`
     *
     * @return {Object}
     */
    toJSON: function toJSON() {
      return this.getProperties('isFake', 'startDate', 'value');
    }
  });

  /**
   * Fixed Expenses Row (FER)
   *
   * Independants props :
   *   * row
   *
   * Interdependant props (init order) :
   *   1. term
   *   2. activities
   *   3. expensesTypes
   *   4. selectedActivity ( -> activities)
   *   5. selectedExpenseType ( -> expensesTypes)
   *
   * @class FixedExpensesRow
   * @extends Ember.Object
   * @uses EmberCopyable
   * @private
   */
  var FixedExpensesRow = Ember.Object.extend(_emberCopy.Copyable, {
    /**
     * Term
     *
     * @property term
     * @type {Moment}
     */

    /**
     * Row Number
     *
     * if < 0, it's a new row
     *
     * @property row
     * @type {Number}
     * @default -1
     */
    row: Ember.computed(function () {
      return -1;
    }),
    /**
     * Activities
     *
     * List of all activities
     *
     * @property activities
     * @type {Activity[]}
     */

    /**
     * Expense Types (ETs)
     *
     * Agency's Expense Types array
     *
     * @property expenseTypes
     * @type {Expensetype[]}
     */

    /**
     * Selected Activity
     *
     * @see FixedExpensesRow.activities
     * @see  utils/activity.getDefaultActivity
     *
     * @property selectedActivity
     * @type {Activity}
     * @default activity return by `getDefaultActivity` function
     */
    selectedActivity: Ember.computed({
      get: function get() {
        return (0, _activity.getDefaultActivity)(this.get('activities'));
      },
      set: function set(_, newActivity, oldActivity) {
        // here, newActivity could be an Activity object or a simple POJO with an `id` property
        var searchedID = Ember.get(newActivity, 'id');
        var selectedActivity = this.get('activities').find(function (activity) {
          return activity.get('id') === searchedID;
        }) || oldActivity;
        if (typeof selectedActivity === 'undefined') {
          _logger.default.warn('Model timesreport - SET selectedActivity CP - newActivity not find in activities array!', newActivity, this.get('activities'));
          selectedActivity = oldActivity;
        }
        if (typeof this._fixedExpenses !== 'undefined') {
          this._fixedExpenses.forEach(function (fixedExpense) {
            if (fixedExpense.isFake) {
              fixedExpense.set('selectedActivity', selectedActivity);
            }
          });
        }
        return selectedActivity;
      }
    }),
    /**
     * Selected Expense Type (SET)
     *
     * @property selectedExpenseType
     * @type {Object}
     * @default first item of expense types array
     */
    selectedExpenseType: Ember.computed({
      get: function get() {
        var expenseTypes = this.get('expenseTypes');
        var selectedExpenseType;
        if (expenseTypes && expenseTypes.length) {
          selectedExpenseType = expenseTypes.get('firstObject');
        } else {
          _logger.default.warn("FixedExpensesRow n\xB0".concat(this.get('row'), " - GET selectedExpenseType: no expenseTypes!"), expenseTypes);
        }
        return selectedExpenseType;
      },
      set: function set(_, newET, oldET) {
        var searchedRef = Ember.get(newET, 'reference');
        var selectedExpenseType = this.get('expenseTypes').findBy('reference', searchedRef) || oldET;
        if (typeof selectedExpenseType === 'undefined') {
          _logger.default.warn('FixedExpensesRow - SET selectedExpenseType CP - new Expense Type not find in expenseTypes array!', newET, this.get('expenseTypes'));
          selectedExpenseType = oldET;
        }
        return selectedExpenseType;
      }
    }),
    /**
     * Is this row is a kilometric expense?
     *
     * @property isKilometricExpense
     * @observes selectedExpenseType.reference
     * @type {Boolean}
     * @readOnly
     */

    isKilometricExpense: Ember.computed('selectedExpenseType.reference', function () {
      return this.get('selectedExpenseType.reference') === _expensetype.travelExpensesTypeReference;
    }).readOnly(),
    /**
     * (Private) Fixed Expenses by day (0-based).
     *
     * Array's length = number of days in the month (cf term)
     *
     * **Private** property used in {{#crossLink "FixedExpensesRow/fixesExpenses}}fixesExpenses CP{{/crossLink}}
     * to not rebuild the array when {{#crossLink "FixedExpensesRow/term"}}term{{/crossLink}} or
     * {{#crossLink "FixedExpensesRow/selectedActivity}}selectedActivity{{/crossLink}}, etc
     * are updated.
     *
     * @property _fixedExpenses
     * @type {(Fake)FixedExpense[]}
     * @private
     */

    /**
     * Fixed expenses by day (0-based).
     *
     * Array's length = number of days in the month (cf term)
     *
     * @property fixedExpenses
     * @observes term, selectedActivity.id, selectedExpenseType.reference
     * @uses  _fixedExpenses
     * @type {(Fake)FixedExpense[]}
     * @readOnly
     */
    fixedExpenses: Ember.computed('term', 'selectedActivity.id', 'selectedExpenseType.reference', function () {
      // if `this._fixedExpenses` array does not already exists, create it!
      if (typeof this._fixedExpenses === 'undefined') {
        var term = this.get('term');
        var daysInMonth = term.daysInMonth();
        this._fixedExpenses = Ember.A();
        for (var i = 0; i < daysInMonth; i++) {
          this._fixedExpenses.push(FakeFixedExpense.create({
            startDate: term.clone().date(i + 1),
            selectedActivity: this.get('selectedActivity')
          }));
        }
      }
      // else update its content
      else {
        var selectedActivity = this.get('selectedActivity');
        var selectedExpenseType = this.get('selectedExpenseType');
        this._fixedExpenses.forEach(function (fixedExpense) {
          if ((0, _fragment.isFragment)(fixedExpense)) {
            fixedExpense.setProperties({
              selectedActivity: selectedActivity,
              selectedExpenseType: selectedExpenseType
            });
          }
        });
      }
      return this._fixedExpenses;
    }).readOnly(),
    /**
     * Get a normalized Fixed expense POJO
     *
     * Add usefull and remove useless properties :)
     *
     * @method getNormalizedFixedExpensePOJO
     * @param  {Fixedexpense|Object} expense
     * @return {Object}              normalized POJO
     */
    getNormalizedFixedExpensePOJO: function getNormalizedFixedExpensePOJO(expense) {
      var normalizedPOJO = {};
      var _this$getProperties2 = this.getProperties('row', 'selectedActivity', 'selectedExpenseType'),
        row = _this$getProperties2.row,
        selectedActivity = _this$getProperties2.selectedActivity,
        selectedExpenseType = _this$getProperties2.selectedExpenseType;
      if (expense && ((0, _fragment.isFragment)(expense) || typeof expense.toJSON === 'function')) {
        normalizedPOJO = (0, _emberCopy.copy)(expense).toJSON();
      }
      normalizedPOJO.row = row;
      normalizedPOJO.selectedActivity = selectedActivity;
      normalizedPOJO.selectedExpenseType = selectedExpenseType;
      if (typeof normalizedPOJO.startDate === 'undefined') {
        _logger.default.error('ExpensesReport model - normalizeFixedTime - startDate undefined', normalizedPOJO);
        normalizedPOJO.startDate = (0, _moment.default)();
      }
      if (typeof normalizedPOJO.value !== 'undefined') {
        delete normalizedPOJO.value;
      }

      // do not need isFake property :)
      if (typeof normalizedPOJO.isFake !== 'undefined') {
        delete normalizedPOJO.isFake;
      }
      return normalizedPOJO;
    },
    /**
     * Delete a fixedExpense = Replace a fixedExpense with  a fakeFixedExpense
     *
     * @method delFixedExpense
     * @param {fixedExpense} fixedExpense the fixedExpense to replace
     */
    delFixedExpense: function delFixedExpense(fixedExpense) {
      if ((0, _fragment.isFragment)(fixedExpense)) {
        // Replace Ember fragment by JS object
        var dayIndex = fixedExpense.get('startDate').date() - 1;
        var fixedExpenses = this.get('fixedExpenses');

        // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
        fixedExpenses[dayIndex] = FakeFixedExpense.create({
          startDate: fixedExpense.get('startDate').clone(),
          selectedActivity: fixedExpense.get('selectedActivity')
        });
        // Tell fixedExpenses that it has changed for template update
        fixedExpenses.arrayContentDidChange(dayIndex);
      }
    }
  });

  /**
   * expensesReport's states
   *
   * @type {String[]}
   */
  var STATES = _exports.STATES = [_baseactivity.ACTIVITY_SAVED_AND_NO_VALIDATION, _baseactivity.ACTIVITY_WAITING_FOR_VALIDATION, _baseactivity.ACTIVITY_VALIDATED, _baseactivity.ACTIVITY_REJECTED];

  /* ** ATTRIBUTES TYPE CASTING **
  
  	see: http://discuss.emberjs.com/t/ember-data-store-my-attr-number-as-a-string-why/11904
  
  	Ember-data doesn't auto-cast attributes when we use data in our app! Ex: if
  	you use a number attribut with an {{input}}, you'll get a string :'/
  
  	Ember-data cast attributes only when data is received from or send to the
  	backend...
  
  	So we have to manually cast them when we want to do some calculation for
  	example :'(
  
  	We use Number() constructor because it doesn't detect octals si of a string
  	like "010" is casting, we have the number 10 (Number("010") => 10 vs
  	parseInt("010") > 8 but if we use parseInt("010", 10) => 10, it's ok ;p)
  */
  var ExpensesReport = _baseactivity.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'resource', 'orders', 'projects', 'validations', 'validationWorkflow', 'timesReport']
    }),
    /**************************************************************************/
    /** ATTRIBUTES ************************************************************/
    /**************************************************************************/
    advance: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    closed: _emberData.default.attr('boolean'),
    currencyAgency: _emberData.default.attr('number'),
    exchangeRateAgency: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    /**
     * Information Comments maxLength=500
     * @type {String}
     */
    informationComments: _emberData.default.attr('string'),
    paid: _emberData.default.attr('boolean'),
    toPay: _emberData.default.attr('number'),
    /**
     * State
     * see STATES
     * @type {String}
     */
    state: _emberData.default.attr('string'),
    term: _emberData.default.attr('date'),
    creationDate: _emberData.default.attr('moment'),
    // TAB_LISTEFRAIS.LISTEFRAIS_CREATEDAT,
    updateDate: _emberData.default.attr('moment'),
    /**************************************************************************/
    /** FRAGMENTS *************************************************************/
    /**************************************************************************/
    /**
     * List of actual expenses
     * @type {Actualexpense[]}
     */
    actualExpenses: (0, _attributes.fragmentArray)('actualexpense'),
    /**
     * List of fixed expenses
     * @type {Fixedexpense[]}
     */
    fixedExpenses: (0, _attributes.fragmentArray)('fixedexpense', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * List of project's expenses
     * @type {Fixedexpense[]}
     */
    projectsExpenses: (0, _attributes.fragmentArray)('fixedexpense', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    ratePerKilometerType: (0, _attributes.fragment)('rateperkmtype', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    /**************************************************************************/
    /** RELATIONSHIPS *********************************************************/
    /**************************************************************************/
    timesReport: _emberData.default.belongsTo('timesreport'),
    orders: _emberData.default.hasMany(),
    projects: _emberData.default.hasMany(),
    certification: _emberData.default.belongsTo('expensescertificationhistory'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    /**************************************************************************/
    /** CPs CREATED ON INIT ***************************************************/
    /**************************************************************************/
    /**
     * @property isSavedAndNoValidation
     * @see  _createIsStatesReadOnlyCP
     * @return {Boolean}
     * @readOnly
     */
    /**
     * @property isWaitingForValidation
     * @see  _createIsStatesReadOnlyCP
     * @return {Boolean}
     * @readOnly
     */
    /**
     * @property isValidated
     * @see  _createIsStatesReadOnlyCP
     * @return {Boolean}
     * @readOnly
     */
    /**
     * @property isRejected
     * @see  _createIsStatesReadOnlyCP
     * @return {Boolean}
     * @readOnly
     */
    /**
     * @property isMine
     * @see  _createIsMineReadOnlyCP
     * @return {Boolean} `true`if the report is owned by current connected user
     * @readOnly
     */
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);

      /**
       * On Init, create some boolean CPs based on STATES
       *   * isSavedAndNoValidation
       *   * isWaitingForValidation
       *   * isValidated
       *   * ...
       */
      STATES.forEach(function (state) {
        var stateName = Ember.String.capitalize(state);
        Ember.defineProperty(_this, "is".concat(stateName), Ember.computed.equal('state', state).readOnly());
      });
    },
    validExpenseTypes: Ember.computed('agency', 'actualExpenses.@each.reference', 'fixedExpenses.@each.reference', function () {
      var actualExpenses = this.get('actualExpenses');
      var fixedExpenses = this.get('fixedExpenses');
      return this.get('agency').then(function (agency) {
        var options = agency.get('allExpenseTypes');
        actualExpenses.forEach(function (expense) {
          if (!options.find(function (type) {
            return type.reference === expense.get('expenseType.reference');
          })) {
            options.pushObject({
              reference: expense.get('expenseType.reference'),
              name: expense.get('expenseType.reference'),
              ocrcategory: expense.get('expenseType.ocrcategory'),
              taxRate: expense.get('expenseType.taxRate'),
              value: expense.get('expenseType.value'),
              referenceForColor: expense.get('expenseType.referenceForColor')
            });
          }
        });
        fixedExpenses.forEach(function (expense) {
          if (!options.find(function (type) {
            return type.reference === expense.get('expenseType.reference');
          })) {
            options.pushObject({
              reference: expense.get('expenseType.reference'),
              name: expense.get('expenseType.reference'),
              ocrcategory: expense.get('expenseType.ocrcategory'),
              taxRate: expense.get('expenseType.taxRate'),
              value: expense.get('expenseType.value'),
              referenceForColor: expense.get('expenseType.referenceForColor')
            });
          }
        });
        return options;
      });
    }).readOnly(),
    /**
     * Resource's validation (= first item of model.validations relationship array)
     *
     * @property resourceValidation
     * @observes validations.[]
     * @type {Promise}
     * @fulfil {Validation} - Resource's validation
     */
    resourceValidation: Ember.computed('validations.[]', function () {
      var _this2 = this;
      var promise = this.get('validations').then(function (validations) {
        var resourceValidation;
        if (validations.get('length')) {
          resourceValidation = validations.get('firstObject');
        } else {
          // no validations so create first validation
          resourceValidation = _this2.get('resource').then(function (resource) {
            return _this2.get('store').createRecord('validation', {
              dependsOn: _this2,
              expectedValidator: resource
            });
          });
        }
        return resourceValidation;
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    /**
     * Last month day
     *
     * @property lastTermDay
     * @observes term
     * @type {Moment}
     */
    lastTermDay: Ember.computed('term', function () {
      return (0, _moment.default)(this.get('term')).endOf('month');
    }),
    /**
     * First month day
     *
     * @property firstTermDay
     * @observes term
     * @type {Moment}
     */
    firstTermDay: Ember.computed('term', function () {
      return (0, _moment.default)(this.get('term')).startOf('month');
    }),
    /**************************************************************************/
    /*** ACTIVITIES ***********************************************************/
    /**************************************************************************/
    /**
     * Absence activity
     *
     * @property absenceActivity
     * @observes term
     * @return {Promise}
     * @fulfil {Activity}
     * @readonly
     */
    absenceActivity: Ember.computed('term', function () {
      var owner = Ember.getOwner(this);
      var Activity = owner.factoryFor('bm:activity');
      var activityValue = {
        type: _workunittype.WUT_ABSENCE,
        term: this.get('term')
      };
      return Activity.create(activityValue);
    }).readOnly(),
    /**
     * Internal activity
     *
     * @property internalActivity
     * @observes term
     * @return {Promise}
     * @fulfil {Activity}
     * @readonly
     */
    internalActivity: Ember.computed('term', function () {
      var owner = Ember.getOwner(this);
      var Activity = owner.factoryFor('bm:activity');
      var activityValue = {
        type: _workunittype.WUT_INTERNAL,
        term: this.get('term')
      };
      return Activity.create(activityValue);
    }).readOnly(),
    /**
     * Production activities
     *
     * @property productionActivities
     * @observes term, projects
     * @return {Promise}
     * @fulfil {Activity[]}
     * @readonly
     */
    productionActivities: Ember.computed('term', 'projects', function () {
      var _this3 = this;
      var term = this.get('term');
      // create a promise resolved when we get all projects' companies & batches & deliveries
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var activities = [];
        _this3.get('projects').then(function (projects) {
          var owner = Ember.getOwner(_this3);
          var Activity = owner.factoryFor('bm:activity');

          // allProjectsPromises is an array of Promises
          var allProjectsPromises = projects.map(function (project) {
            var projectPromises = {
              company: project.get('company'),
              batches: project.get('batches'),
              deliveries: project.get('deliveries')
            };
            return Ember.RSVP.hash(projectPromises, "project ".concat(project.get('getReference'), " - activities - get company, batches & deliveries")).then(function (_ref) {
              var company = _ref.company,
                batches = _ref.batches,
                deliveries = _ref.deliveries;
              // push activity id-value obj in `activities` array
              deliveries.forEach(function (delivery) {
                var activity = {
                  type: _workunittype.WUT_PRODUCTION,
                  term: term,
                  project: project,
                  delivery: delivery
                };
                if (company) {
                  activity.company = company;
                }
                if (batches && batches.length) {
                  batches.forEach(function (batch) {
                    activity.batch = batch;
                    activities.push(Activity.create(activity));
                  });
                } else {
                  activities.push(Activity.create(activity));
                }
              });

              // on ajoute l'activité qui n'existerait plus, par ex une saisie sur une presta sans lot alors que la presta actuelle possede des lots
              var actualExpenses = _this3.get('actualExpenses');
              var fixedExpenses = _this3.get('fixedExpenses');
              var projectsExpenses = _this3.get('projectsExpenses');
              [actualExpenses, fixedExpenses, projectsExpenses].forEach(function (times) {
                times.filter(function (time) {
                  return Ember.get(time, 'project.id') === Ember.get(project, 'id');
                }).forEach(function (time) {
                  var activityExists = activities.find(function (item) {
                    return Ember.get(time, 'project.id') && item.type === _workunittype.WUT_PRODUCTION && Ember.get(item, 'project.id') === Ember.get(time, 'project.id') && Ember.get(item, 'delivery.id') === Ember.get(time, 'delivery.id') && Ember.get(item, 'batch.id') === Ember.get(time, 'batch.id');
                  });
                  if (!activityExists) {
                    var deliveryID = Ember.get(time, 'delivery.id');
                    var delivery = null;
                    if (deliveryID) {
                      // récupération du model dans le store (création ou mise à jour)
                      delivery = _this3.store.push({
                        data: {
                          id: deliveryID,
                          type: 'delivery',
                          attributes: {
                            title: Ember.get(time, 'delivery.title'),
                            startDate: Ember.get(time, 'delivery.startDate'),
                            endDate: Ember.get(time, 'delivery.endDate')
                          }
                        }
                      });
                    }
                    var activity = {
                      type: _workunittype.WUT_PRODUCTION,
                      term: term,
                      project: project,
                      delivery: delivery
                    };
                    if (company) {
                      activity.company = company;
                    }
                    var batch = Ember.get(time, 'batch');
                    if (batch) {
                      activity.batch = batch;
                    }
                    activities.push(Activity.create(activity));
                  }
                });
              });
            });
          });
          Ember.RSVP.all(allProjectsPromises, "All ".concat(projects.length, " projects promises")).then(function () {
            resolve(activities);
          }, function (reason) {
            reject(reason);
          });
        }, function (reason) {
          reject(reason);
        });
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**
     * Activities
     *
     * Concatenation of *Activities
     *
     * @property activities
     * @observes absenceActivity, internalActivity, productionActivities
     * @return {Promise}
     * @fulfil {Activity[]}
     * @readonly
     */
    activities: Ember.computed('absenceActivity', 'internalActivity', 'productionActivities', function () {
      var activities = [this.get('absenceActivity'), this.get('internalActivity'), this.get('productionActivities')];
      var promise = new Ember.RSVP.all(activities).then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 3),
          absence = _ref3[0],
          internal = _ref3[1],
          production = _ref3[2];
        var activities = [];
        activities.push(absence, internal);
        return activities.concat(production);
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**************************************************************************/
    /** ACTUAL EXPENSES *******************************************************/
    /**************************************************************************/
    addActualExpense: function addActualExpense(expense) {
      var copy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return this.addActualExpenseCore(expense, copy, true);
    },
    addActualExpenseNoKilometric: function addActualExpenseNoKilometric(expense) {
      var copy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return this.addActualExpenseCore(expense, copy, false);
    },
    addActualExpenseCore: function addActualExpenseCore(expense) {
      var copy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var canBeKilometric = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var actualExpenses = this.get('actualExpenses');
      var promise;
      switch (_typeof(expense)) {
        case 'object':
          promise = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
            var newActualExpense;
            if ((0, _fragment.isFragment)(expense)) {
              newActualExpense = copy ? copy(expense) : expense;
              actualExpenses.addFragment(newActualExpense);
            } else {
              newActualExpense = actualExpenses.createFragment(expense);
            }
            newActualExpense.set('creation', true);
            resolve(newActualExpense);
          });
          break;
        default:
          {
            var newActualExpense = {
              currency: this.get('currencyAgency'),
              currencyAgency: this.get('currencyAgency'),
              exchangeRate: 1,
              exchangeRateAgency: this.get('exchangeRateAgency')
            };

            // promises to get new actualExpense fragment's selected(Activity|ExpenseType)
            var promises;
            if (actualExpenses.get('length')) {
              var lastActualExpense = actualExpenses.get('lastObject');
              promises = {
                selectedActivity: lastActualExpense.get('selectedActivity')
              };
              newActualExpense.startDate = lastActualExpense.get('startDate').clone();
              newActualExpense.currency = lastActualExpense.get('currency');
              newActualExpense.reinvoiced = lastActualExpense.get('reinvoiced');
              newActualExpense.currencyAgency = lastActualExpense.get('currencyAgency');
              newActualExpense.exchangeRate = lastActualExpense.get('exchangeRate');
              newActualExpense.exchangeRateAgency = lastActualExpense.get('exchangeRateAgency');
              if (canBeKilometric || lastActualExpense.get('selectedExpenseType.reference') !== _expensetype.travelExpensesTypeReference) {
                promises.selectedExpenseType = lastActualExpense.get('selectedExpenseType');
                if (lastActualExpense.isKilometricExpense) {
                  newActualExpense['numberOfKilometers'] = 0;
                }
              } else {
                promises.selectedExpenseType = this.get('validExpenseTypes').then(function (expenses) {
                  return expenses.get('firstObject');
                });
              }
            } else {
              promises = {
                selectedActivity: this.get('activities').then(function (activities) {
                  return (0, _activity.getDefaultActivity)(activities);
                }),
                selectedExpenseType: this.get('validExpenseTypes').then(function (expenses) {
                  return expenses.get('firstObject');
                })
              };
              newActualExpense.startDate = (0, _moment.default)(this.get('term')).date(1); // firstDay
              newActualExpense.reinvoiced = false;
              if (newActualExpense.isKilometricExpense) {
                newActualExpense['numberOfKilometers'] = 0;
              }
            }
            promise = Ember.RSVP.hash(promises, 'get selectedActivity + selectedExpenseType').then(function (_ref4) {
              var selectedActivity = _ref4.selectedActivity,
                selectedExpenseType = _ref4.selectedExpenseType;
              newActualExpense.selectedActivity = selectedActivity;
              newActualExpense.selectedExpenseType = selectedExpenseType;
              newActualExpense.creation = true;

              // Can't use fragmentArray.createFragment because the new fragment is:
              // 1 - created
              // 2 - its CP are initialized
              // 3 - added in the array
              //
              // step 2 crash because CPs use 'owner' CP. This CP can be used only after step 3!
              // (because the new fragment only know who is its owner when it is added in it ;p)
              //
              // so we use this: (cf https://github.com/lytics/ember-data-model-fragments/blob/master/addon/array/fragment.js#L20)
              var record = Ember.get(actualExpenses, 'owner');
              var store = Ember.get(record, 'store');
              var declaredModelName = Ember.get(actualExpenses, 'type');
              var options = Ember.get(actualExpenses, 'options');
              var key = Ember.get(actualExpenses, 'name');

              // 1 - create the fragment with no data but with its 'owner' (ie. record) initialized
              var actualExpense = (0, _fragment.createFragment)(store, declaredModelName, record, key, options);
              // 2 - set properties
              actualExpense.setProperties(newActualExpense);
              // 3 - push the new fragment
              return actualExpenses.pushObject(actualExpense);
            });
          }
      }
      return ObjProxy.create({
        promise: promise
      });
    },
    delActualExpense: function delActualExpense(expense) {
      if ((0, _fragment.isFragment)(expense) && Ember.get(expense, 'category') === 'actual') {
        this.get('actualExpenses').removeFragment(expense);
      } else {
        _logger.default.warn("ExpensesReport#".concat(this.get('id'), ":delActualExpense() - expense is not a fragment or actual expense!"), expense);
      }
    },
    /**************************************************************************/
    /** FIXED EXPENSES ********************************************************/
    /**************************************************************************/
    /**
     * FixedExpenses POJO
     *
     * @typedef {Object} FixedExpensesPOJO
     * @property {Number}      row - row's no
     * @property {Activity}    selectedActivity
     * @property {ExpenseType} selectedExpenseType
     */
    /**
     * Add projects expenses
     *
     * Create `FixedExpensesRow` row for each notalready existing `row` no and add
     * related `FixedExpense` expense in it.
     *
     * @uses createFixedExpensesRow
     * @uses addFixedExpense
     *
     * @method addProjectsExpenses
     * @return {Promise}
     * @fulfil {FixedExpensesRow[]} - Array of new created FixedExpenses rows
     */
    addProjectsExpenses: function addProjectsExpenses() {
      var _this4 = this;
      var fixedExpensesRowsPromisesMap = {};
      var projectsExpenses = Ember.A(this.getWithDefault('projectsExpenses', []));
      projectsExpenses.sortBy('row').forEach(function (projectsExpense) {
        // we make a copy of the projectsExpense because :
        // 1 - we would like to be able to add it several times independently of his original projectsExpense instance
        // 2 - we will update its `row` property with new row no from `fixedExpensesRows`
        var projectsExpenseToAdd = projectsExpense.copy();
        // this "old" rowNo will be used to build the promises map `fixedExpensesRowsPromisesMap`
        var rowNo = projectsExpenseToAdd.get('row');
        projectsExpenseToAdd.set('originalID', null);

        // Create a fixedExpensesRow if needed
        if (typeof fixedExpensesRowsPromisesMap[rowNo] === 'undefined') {
          // find selectedActivity
          var selectedActivity = projectsExpense.get('selectedActivity');
          var selectedExpenseType = projectsExpense.get('expenseType');
          fixedExpensesRowsPromisesMap[rowNo] = _this4.addFixedExpensesRow({
            // we don't need a `row` property here because we leave `addFixedExpensesRow`
            // to give us a new one
            selectedActivity: selectedActivity,
            selectedExpenseType: selectedExpenseType,
            creation: true
          }).then(function (newFixedExpensesRow) {
            var newRowNo = newFixedExpensesRow.get('row');
            projectsExpenseToAdd.set('row', newRowNo);
            _this4.addFixedExpense(projectsExpenseToAdd, newFixedExpensesRow);
            return newFixedExpensesRow;
          });
        } else {
          // we chain
          fixedExpensesRowsPromisesMap[rowNo].then(function (newFixedExpensesRow) {
            var newRowNo = newFixedExpensesRow.get('row');
            projectsExpenseToAdd.set('row', newRowNo);
            _this4.addFixedExpense(projectsExpenseToAdd, newFixedExpensesRow);
            return newFixedExpensesRow;
          });
        }
      });
      var fixedExpensesRowsPromise = Ember.RSVP.all(Object.keys(fixedExpensesRowsPromisesMap).sort().map(function (rowNo) {
        return fixedExpensesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: fixedExpensesRowsPromise
      });
    },
    /**
     * Create new fixedExpenses row.
     *
     * @method createFixedExpensesRow
     * @param {FixedExpensesPOJO} fixedExpensesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {FixedExpensesRow} - the new created FixedExpenses row
     */
    createFixedExpensesRow: function createFixedExpensesRow(fixedExpensesRow) {
      var _this5 = this;
      var promises = {
        activities: this.get('activities'),
        validExpenseTypes: this.get('validExpenseTypes')
      };
      var promise = Ember.RSVP.hash(promises).then(function (_ref5) {
        var activities = _ref5.activities,
          validExpenseTypes = _ref5.validExpenseTypes;
        var term = _this5.get('term');

        // fixedExpensesRow properties
        var rowNo = Ember.getWithDefault(fixedExpensesRow, 'row', null);
        var newFixedExpensesRow = {
          term: (0, _moment.default)(term),
          row: rowNo,
          activities: activities,
          expenseTypes: validExpenseTypes
        };
        if (Object.keys(fixedExpensesRow).length !== 0) {
          newFixedExpensesRow.selectedActivity = Ember.get(fixedExpensesRow, 'selectedActivity');
          newFixedExpensesRow.selectedExpenseType = Ember.get(fixedExpensesRow, 'selectedExpenseType');
        }
        return FixedExpensesRow.create(newFixedExpensesRow);
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Add new FixedExpenses row.
     *
     * Create a new FixedExpenses row and add it in `fixedExpensesRows` array
     *
     * @uses createFixedExpensesRow, fixedExpensesRows
     *
     * @method addFixedExpensesRow
     * @param {FixedExpensesPOJO} addedFixedExpensesRow - A POJO could be passed to defined some properties of this new row.
     * @return {Promise}
     * @fulfil {FixedExpensesRow} - The new FixedExpenses row.
     */
    addFixedExpensesRow: function addFixedExpensesRow(addedFixedExpensesRow) {
      var _this6 = this;
      var promise = this.get('fixedExpensesRows').then(function (fixedExpensesRows) {
        var nextRowPOJO = {};
        if (fixedExpensesRows.get('length')) {
          var lastRow = fixedExpensesRows.get('lastObject');
          nextRowPOJO = {
            selectedActivity: lastRow.get('selectedActivity'),
            selectedExpenseType: lastRow.get('selectedExpenseType')
          };
        }
        Ember.assign(nextRowPOJO, addedFixedExpensesRow);
        return _this6.createFixedExpensesRow(nextRowPOJO).then(function (newFixedExpensesRow) {
          // we must calculate the new row number now in the case 2 row has been added with the same action (project expenses)
          if (newFixedExpensesRow.get('row') === null) {
            var rowNumber = -1;
            if (fixedExpensesRows.get('length')) {
              var _lastRow = fixedExpensesRows.get('lastObject');
              var lastRowNo = _lastRow.get('row');
              rowNumber = lastRowNo < 0 ? lastRowNo - 1 : -1;
            }
            newFixedExpensesRow.set('row', rowNumber);
          }
          newFixedExpensesRow.creation = true;
          newFixedExpensesRow.hasValue = false;
          fixedExpensesRows.addObject(newFixedExpensesRow);
          return newFixedExpensesRow;
        });
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    /**
     * Delete a FixedExpenses row.
     *
     * @method delFixedExpensesRow
     * @param {FixedExpensesRow} row - FixedExpenses row to delete
     * @return {Promise}
     * @fulfil {FixedExpensesRow} - deleted FixedExpenses row
     */
    delFixedExpensesRow: function delFixedExpensesRow(row) {
      var _this7 = this;
      // del all fixed expenses fragments
      Ember.get(row, 'fixedExpenses').forEach(function (expense) {
        _this7.delFixedExpense(expense, row);
      });
      return this.get('fixedExpensesRows').then(function (fixedExpensesRows) {
        return fixedExpensesRows.removeObject(row);
      });
    },
    /**
     * Find a fixed expense in `fixedExpenses`
     *
     * The test is done on `startDate` and `row` properties
     *
     * @uses fixedExpenses
     *
     * @method findFixedExpense
     * @param  {FixedExpense} fixedExpense - regularTime to find
     * @return {FixedExpense} fixedExpense found in `regularTimes` array
     */
    findFixedExpense: function findFixedExpense(expense) {
      var startDate = (0, _moment.default)(Ember.get(expense, 'startDate'));
      var rowNo = Ember.get(expense, 'row');
      return this.get('fixedExpenses').find(function (item) {
        return item.get('startDate').isSame(startDate) && item.get('row') === rowNo;
      });
    },
    /**
     * @method updateFixedExpense
     * @param {FakeFixedExpense|FixedExpense} expense - (Fake)FixedExpense to update with value
     * @param {Number} value - fixedExpense's value
     * @param {FixedExpensesRow} fixedExpensesRow - row in which this fixedExpense will be updated
     * @return {FixedExpense} - Updated fixedExpense
     */
    updateFixedExpense: function updateFixedExpense(expense, value, fixedExpensesRow) {
      // get fixedExpense to update : it's an already existing fixedExpense or a new one
      var updatedFixedExpense = this.findFixedExpense(expense) || this.addFixedExpense(expense, fixedExpensesRow);

      // update its value
      updatedFixedExpense.set('value', value);
      return updatedFixedExpense;
    },
    /**
     * @method addFixedExpense
     * @param {FakeFixedExpense|FixedExpense} expense - (Fake)FixedExpense to add
     * @param {FixedExpensesRow} fixedExpensesRow - row in which this fixedExpense will be added
     * @param {Boolean} copy - if fixedExpense is a FixedExpense (fragment) object, copy it or use its reference (default)
     * @return {FixedExpense} - added fixedExpense
     */
    addFixedExpense: function addFixedExpense(expense, fixedExpensesRow) {
      var copy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var addedFixedExpense;
      switch (_typeof(expense)) {
        case 'object':
          {
            if ((0, _fragment.isFragment)(expense)) {
              addedFixedExpense = copy ? copy(expense) : expense;
              this.get('fixedExpenses').addFragment(addedFixedExpense);
            } else {
              var normalizedFixedExpensePOJO = fixedExpensesRow.getNormalizedFixedExpensePOJO(expense);
              //addedFixedExpense = this.get('fixedExpenses').createFragment(normalizedFixedExpensePOJO);

              // Can't use fragmentArray.createFragment because the new fragment is:
              // 1 - created
              // 2 - its CP are initialized
              // 3 - added in the array
              //
              // step 2 crash because CPs use 'owner' CP. This CP can be used only after step 3!
              // (because the new fragment only know who is its owner when it is added in it ;p)
              //
              //return exceptionalTimes.createFragment(newExceptionalTime);
              //
              // so we use this: (cf https://github.com/lytics/ember-data-model-fragments/blob/master/addon/array/fragment.js#L20)
              var _fixedExpenses = this.get('fixedExpenses');
              var record = Ember.get(_fixedExpenses, 'owner');
              var store = Ember.get(record, 'store');
              var declaredModelName = Ember.get(_fixedExpenses, 'type');
              var options = Ember.get(_fixedExpenses, 'options');
              var key = Ember.get(_fixedExpenses, 'name');

              // 1 - create the fragment with no data but with its 'owner' (ie. record) initialized
              addedFixedExpense = (0, _fragment.createFragment)(store, declaredModelName, record, key, options);
              // 2 - set properties
              addedFixedExpense.setProperties(normalizedFixedExpensePOJO);
              _fixedExpenses.pushObject(addedFixedExpense);
            }
            var dayIndex = Ember.get(expense, 'startDate').date() - 1;
            var fixedExpenses = fixedExpensesRow.get('fixedExpenses');

            // TODO: use Ember.Array.replace instead, to avoid calling arrayContentDidChange
            fixedExpenses[dayIndex] = addedFixedExpense;
            // Tell fixedExpenses that it has changed for template update
            fixedExpenses.arrayContentDidChange(dayIndex);
            break;
          }
        default:
          _logger.default.error.apply(_logger.default, ['ExpenseReport Model - addFixedExpense - Bad expense type'].concat(Array.prototype.slice.call(arguments)));
      }
      return addedFixedExpense;
    },
    /**
     * @uses FixedExpensesRow.delFixedExpense
     * @uses fixedExpenses
     *
     * @method delFixedExpense
     * @param {FixedExpense} expense - fixedExpense to delete
     * @param {FixedExpensesRow} fixedExpensesRow - row in which this fixedExpense will be removed
     * @return {FixedExpense|undefined} - deleted fixedExpense or `undefined` if this expense can not be removed from
     *                                    `fixedExpenses` array
     */
    delFixedExpense: function delFixedExpense(expense, fixedExpensesRow) {
      if ((0, _fragment.isFragment)(expense)) {
        fixedExpensesRow.delFixedExpense(expense);
        return this.get('fixedExpenses').removeFragment(expense);
      }
    },
    expenses: Ember.computed.union('actualExpenses', 'fixedExpenses'),
    kilometricExpenses: Ember.computed.filterBy('expenses', 'isKilometricExpense'),
    fixedExpensesRows: Ember.computed('fixedExpenses', function () {
      var _this8 = this;
      var fixedExpensesRowsPromisesMap = {};
      var fixedExpenses = Ember.A(this.getWithDefault('fixedExpenses', []));
      fixedExpenses.sortBy('row').forEach(function (fixedExpense) {
        var dayIndex = fixedExpense.get('startDate').date() - 1;
        var rowNo = fixedExpense.get('row');

        // Create a fixedExpensesRow if needed
        if (typeof fixedExpensesRowsPromisesMap[rowNo] === 'undefined') {
          // find selectedActivity
          var selectedActivity = fixedExpense.get('selectedActivity');
          var selectedExpenseType = fixedExpense.get('expenseType');
          fixedExpensesRowsPromisesMap[rowNo] = _this8.createFixedExpensesRow({
            row: rowNo,
            selectedActivity: selectedActivity,
            selectedExpenseType: selectedExpenseType
          }).then(function (newFixedExpensesRow) {
            newFixedExpensesRow.get('fixedExpenses')[dayIndex] = fixedExpense;
            return newFixedExpensesRow;
          });
        } else {
          // we chain
          fixedExpensesRowsPromisesMap[rowNo].then(function (newFixedExpensesRow) {
            newFixedExpensesRow.get('fixedExpenses')[dayIndex] = fixedExpense;
            return newFixedExpensesRow;
          });
        }
      });
      var fixedExpensesRowsPromise = Ember.RSVP.all(Object.keys(fixedExpensesRowsPromisesMap).sort().map(function (rowNo) {
        return fixedExpensesRowsPromisesMap[rowNo];
      }));
      return ArrProxy.create({
        promise: fixedExpensesRowsPromise
      });
    }),
    sortMapByType: function sortMapByType(mapData) {
      var self = this;
      return new Map(Array.from(mapData.entries()).sort(function (a, b) {
        return a[1].type.localeCompare(b[1].type, self.get('i18n.locale'), {
          ignorePunctuation: true,
          sensitivity: 'base'
        });
      }));
    },
    totalsByExpensesType: Ember.computed('expenses.@each.{agencyAmountIncludingTax,agencyTaxedAmount,expenseType}', function () {
      var expenses = this.getWithDefault('expenses', []);
      var expensesTotal = expenses.reduce(function (totals, expense) {
        if (!Ember.get(expense, 'isKilometricExpense')) {
          var key = Ember.get(expense, 'expenseType.reference');
          var _Ember$getProperties = Ember.getProperties(expense, 'agencyAmountIncludingTax', 'agencyTaxedAmount'),
            agencyAmountIncludingTax = _Ember$getProperties.agencyAmountIncludingTax,
            agencyTaxedAmount = _Ember$getProperties.agencyTaxedAmount;
          if (!totals.has(key)) {
            totals.set(key, {
              amountIncludingTax: 0,
              taxedAmount: 0,
              type: Ember.get(expense, 'expenseType.name'),
              amountExcludingTax: 0
            });
          }
          var roundedAgencyTaxedAmount = Math.round(agencyTaxedAmount * 100) / 100;
          var roundedAgencyAmountIncludingTax = Math.round(agencyAmountIncludingTax * 100) / 100;
          totals.get(key).amountIncludingTax += roundedAgencyAmountIncludingTax;
          totals.get(key).taxedAmount += roundedAgencyTaxedAmount;
          totals.get(key).amountExcludingTax += roundedAgencyAmountIncludingTax - roundedAgencyTaxedAmount;
          totals.get(key).taxRate = Ember.get(expense, 'expenseType.taxRate');
        }
        return totals;
      }, new Map());
      return this.sortMapByType(expensesTotal);
    }),
    kilometricExpensesTotal: Ember.computed('kilometricExpenses.@each.numberOfKilometers', 'ratePerKilometerType.amount', 'exchangeRateAgency', function () {
      var exchangeRateAgency = this.get('exchangeRateAgency');
      var kilometricExpenses = this.getWithDefault('kilometricExpenses', []);
      // see ATTRIBUTES TYPE CASTING at the beginning of this file
      var ratePerKilometer = Number(this.get('ratePerKilometerType.amount'));
      var kilometricExpensesTotal = kilometricExpenses.reduce(function (total, expense) {
        // see ATTRIBUTES TYPE CASTING at the beginning of this file
        var numberOfKilometers = Number(Ember.get(expense, 'numberOfKilometers')) || 0;
        if (isNaN(numberOfKilometers)) numberOfKilometers = 0;
        total.numberOfKilometers += numberOfKilometers;
        total.kilometricExpenses += isNaN(ratePerKilometer) ? 0 : (0, _financial.applyExchangeRates)(numberOfKilometers * ratePerKilometer, [1 / exchangeRateAgency]);
        return total;
      }, {
        numberOfKilometers: 0,
        kilometricExpenses: 0
      });
      kilometricExpensesTotal.kilometricExpenses = (0, _financial.roundBigDecimals)(kilometricExpensesTotal.kilometricExpenses);
      return kilometricExpensesTotal;
    }),
    actualExpensesTotal: Ember.computed('actualExpenses.@each.{agencyAmountIncludingTax,expenseType}', function () {
      return this.get('actualExpenses').reduce(function (total, expense) {
        return total += Ember.get(expense, 'isKilometricExpense') ? 0 : Ember.get(expense, 'agencyAmountIncludingTax');
      }, 0);
    }),
    fixedExpensesTotal: Ember.computed('fixedExpenses.@each.{agencyAmountIncludingTax,expenseType}', function () {
      return this.get('fixedExpenses').reduce(function (total, expense) {
        return total += Ember.get(expense, 'isKilometricExpense') ? 0 : Ember.get(expense, 'agencyAmountIncludingTax');
      }, 0);
    }),
    totalExpenseTax: Ember.computed('totalsByExpensesType', function () {
      var totalsByExpensesType = this.get('totalsByExpensesType');
      return Array.from(totalsByExpensesType.values()).reduce(function (total, expense) {
        var taxedAmount = Math.round(Ember.get(expense, 'taxedAmount') * 100) / 100;
        return total += taxedAmount;
      }, 0);
    }),
    totalExpense: Ember.computed('kilometricExpensesTotal.kilometricExpenses', 'actualExpensesTotal', 'fixedExpensesTotal', function () {
      return this.get('kilometricExpensesTotal.kilometricExpenses') + this.get('actualExpensesTotal') + this.get('fixedExpensesTotal');
    }),
    totalRebill: Ember.computed('actualExpenses.@each.{agencyAmountIncludingTax,reinvoiced,isKilometricExpense,numberOfKilometers}', 'ratePerKilometerType.amount', function () {
      var _this9 = this;
      return this.get('actualExpenses').reduce(function (total, expense) {
        var amount = Ember.get(expense, 'isKilometricExpense') ? Ember.get(expense, 'numberOfKilometers') * _this9.get('ratePerKilometerType.amount') : Ember.get(expense, 'agencyAmountIncludingTax');
        return total += Ember.get(expense, 'reinvoiced') ? amount : 0;
      }, 0);
    }),
    agencyAdvance: Ember.computed('advance', 'exchangeRateAgency', {
      get: function get() {
        return (0, _financial.applyExchangeRates)(this.get('advance'), [1 / this.get('exchangeRateAgency')]);
      } /*key*/,
      set: function set(key, value) {
        this.set('advance', (0, _financial.applyExchangeRates)(value, [this.get('exchangeRateAgency')]));
        return value;
      }
    }),
    totalAgencyRemaining: Ember.computed('totalExpense', 'agencyAdvance', function () {
      return this.get('totalExpense') - this.get('agencyAdvance');
    }),
    currencyAgencyLabel: Ember.computed('currencyAgency', 'i18n._locale', function () {
      var currencyAgenceID = this.get('currencyAgency');
      if (typeof currencyAgenceID === 'undefined') {
        _logger.default.log("Model 'expensereport'#".concat(this.get('id'), ": undefined currency Agence ID"));
        return '-';
      }
      var currencies = this.get('settingsService').customer.currency;
      return currencies.find(function (currency) {
        return currency.id === currencyAgenceID;
      });
    }),
    labelWithInternalReference: Ember.computed('state', 'label', 'i18n._locale', function () {
      return this.get('label') + ' - ' + this.get('internalReference');
    }).readOnly(),
    label: Ember.computed('state', 'term', 'i18n._locale', function () {
      var date = (0, _moment.default)(this.get('term')).format('MMMM YYYY');
      return date.charAt(0).toUpperCase() + date.slice(1);
    }).readOnly(),
    sortFixedExpenses: function sortFixedExpenses() {
      // before , we have to reorder regularTimes with the same order as
      // backend return it after saved model
      var fixedExpenses = this.get('fixedExpenses');
      if (fixedExpenses.get('length')) {
        // can't use sortBy because we need to put row < 0 at the end in reverse order :)
        // const sortedRegularTimes = regularTimes.sortBy('row', 'startDate');
        var sortedFixedExpenses = fixedExpenses.toArray().sort(function (a, b) {
          var rowA = a.get('row');
          var rowB = b.get('row');
          var diff = rowA - rowB;
          if (diff === 0) {
            var startDateA = a.get('startDate');
            var startDateB = b.get('startDate');
            return startDateA - startDateB;
          } else {
            if (rowA < 0) {
              return rowB < 0 ? rowB - rowA : 1;
            } else {
              return rowB < 0 ? -1 : diff;
            }
          }
        });
        this.set('fixedExpenses', sortedFixedExpenses);
      }
    },
    sortActualExpenses: function sortActualExpenses() {
      // before , we have to reorder actualExpenses with the same order as
      // backend return it after saved model
      var actualExpenses = this.get('actualExpenses');
      if (actualExpenses.get('length')) {
        var sortedActualExpenses = actualExpenses.toArray().sort(function (a, b) {
          var startDateA = a.get('startDate');
          var startDateB = b.get('startDate');
          return startDateA - startDateB;
        });
        this.set('actualExpenses', sortedActualExpenses);
      }
    },
    /**
     * Return files that are not attached to any expense
     */
    notAttachedFiles: Ember.computed('files', 'actualExpenses.@each.file', function () {
      var _this10 = this;
      // we only return files that are not attached to expense line
      var promise = this.get('files').then(function (files) {
        var actualExpenses = _this10.get('actualExpenses');
        var arrayFiles = files.toArray();
        var fileIds = arrayFiles.map(function (file) {
          return file.get('id');
        });
        actualExpenses.forEach(function (actualExpense) {
          if (actualExpense.get('file')) {
            fileIds.splice(fileIds.indexOf(actualExpense.get('file')), 1);
          }
        });
        return arrayFiles.filter(function (file) {
          return fileIds.indexOf(file.get('id')) > -1;
        });
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly()
  });
  ExpensesReport.reopenClass({
    prefix: INTERNAL_REFERENCE_EXP
  });
  ExpensesReport.reopen({
    prefix: Ember.computed(function () {
      return ExpensesReport.prefix;
    }).readOnly()
  });
  var _default = _exports.default = ExpensesReport;
});