define("boondmanager/services/viewport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIEWPORT_MOBILE_BREAKPOINT_SIDEMENU = 1200;
  var VIEWPORT_MOBILE_BREAKPOINT = 992;

  /**
   * TODO A la migration supprimer les @computed suivantes :
   * app/pods/components/octane/bm-viewer-toolbar/component.js (get viewerDidLoad())
   * app/pods/private/timesreports/timesreport/controller.js (get projectLabel())
   */
  var _default = _exports.default = Ember.Service.extend({
    resizeService: Ember.inject.service('resize'),
    /**
     * Largeur courante de la fenêtre de navigation
     *
     * @property currentSize
     * @type {Number}
     * @default undefined
     */
    currentSize: undefined,
    /**
     * Initialisation du service
     */
    init: function init() {
      this._super.apply(this, arguments);
      this.get('resizeService').on('didResize', this, this._testWindowSize);
      this._testWindowSize();
    },
    /**
     * Test window size to set default visibility on mobile mode for sidebar menu only the first time
     * and to switch visibility on sidebar menu when window size switch between mobile/wide mode
     *
     * @private
     */
    _testWindowSize: function _testWindowSize() {
      this.set('currentSize', Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
    },
    isSidemenuMobileDisplay: Ember.computed('currentSize', function () {
      return typeof this.get('currentSize') !== 'undefined' && this.get('currentSize') < VIEWPORT_MOBILE_BREAKPOINT_SIDEMENU;
    }).readOnly(),
    isMobileDisplay: Ember.computed('currentSize', function () {
      return typeof this.get('currentSize') !== 'undefined' && this.get('currentSize') < VIEWPORT_MOBILE_BREAKPOINT;
    }).readOnly()
  });
});