define("boondmanager/models/notificationschannelspropertiesfragment", ["exports", "ember-data-model-fragments", "ember-data/attr"], function (_exports, _emberDataModelFragments, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    active: (0, _attr.default)('boolean'),
    editable: (0, _attr.default)('boolean')
  });
});