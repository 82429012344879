define("boondmanager/models/appdigitalworkplacenews", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "moment", "boondmanager/utils/sharedEntity", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _emberDataCopyable, _moment, _sharedEntity, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.createdAgoLabel = void 0;
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
  var createdAgoLabel = _exports.createdAgoLabel = function createdAgoLabel(creationDate) {
    return (0, _moment.default)(creationDate, 'YYYY-MM-DD hh:mm:ss').fromNow();
  };
  var News = _base.default.extend(_emberDataCopyable.default, {
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    canReadNews: _emberData.default.attr('boolean'),
    canWriteNews: _emberData.default.attr('boolean'),
    share: (0, _attributes.fragment)('sharedentityfragment'),
    creationDate: _emberData.default.attr('date'),
    updateDate: _emberData.default.attr('date'),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    file: _emberData.default.belongsTo('appdigitalworkplacedocument'),
    createdAgoLabel: Ember.computed('creationDate', function () {
      var creationDate = (0, _moment.default)(this.get('creationDate'));
      return createdAgoLabel(creationDate);
    }),
    inputRecipients: Ember.computed('share.{agenciesTypesOf.[],allAgenciesTypesOf.[]}', function () {
      var _this$get;
      var share = (_this$get = this.get('share')) !== null && _this$get !== void 0 ? _this$get : [];
      return (0, _sharedEntity.shareFragmentToInput)(share);
    }),
    files: Ember.computed('file', function () {
      var _this = this;
      var promise = new Promise(function (resolve) {
        var file = _this.get('file');
        var files = file.get('id') ? [file] : [];
        resolve(Ember.A(files));
      });
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    recipientsLabel: Ember.computed('share.{agenciesTypesOf.[],allAgenciesTypesOf.[]}', function () {
      var _this$get2, _share$get;
      var i18n = this.get('i18n');
      var settings = this.get('settingsService');
      var share = (_this$get2 = this.get('share')) !== null && _this$get2 !== void 0 ? _this$get2 : {};
      var numberOfSharings = (_share$get = share.get('numberOfSharings')) !== null && _share$get !== void 0 ? _share$get : 0;
      var label = '';
      if (numberOfSharings === 0) {
        return ' - ';
      }
      if (numberOfSharings > 1) {
        return i18n.t('digitalworkplace:publishToCount', {
          count: numberOfSharings
        });
      }
      if (share.allAgenciesTypesOf.length) {
        var firstAgencyTypeOf = share.allAgenciesTypesOf[0];
        label += "".concat(i18n.t('components:sharedEntity.allAgencies'), " - ");
        if (firstAgencyTypeOf === 'allManagerTypes') {
          label += i18n.t('components:sharedEntity.allManagerTypes');
        } else {
          // Si le type n'existe plu sdans le dico on retourne la clé
          var resourceType = settings.customer.typeOf.resource.findBy('id', firstAgencyTypeOf) || {
            value: firstAgencyTypeOf
          };
          label += resourceType.value;
        }
      } else if (share.agenciesTypesOf.length) {
        var _firstAgencyTypeOf = share.agenciesTypesOf[0];
        var firstTypeOf = _firstAgencyTypeOf.typesOf[0];
        label += "".concat(_firstAgencyTypeOf.agency.name, " - ");
        if (firstTypeOf === 'allManagerTypes') {
          label += i18n.t('components:sharedEntity.allManagerTypes');
        } else {
          // Si le type n'existe plu sdans le dico on retourne la clé
          var _resourceType = settings.customer.typeOf.resource.findBy('id', firstTypeOf) || {
            value: firstTypeOf
          };
          label += _resourceType.value;
        }
      }
      return label;
    }),
    entityName: Ember.computed('title', function () {
      return this.get('title');
    })
  });
  News.reopenClass({
    prefix: 'POST'
  });
  News.reopen({
    prefix: Ember.computed(function () {
      return News.prefix;
    }).readOnly()
  });
  var _default = _exports.default = News;
});