define("boondmanager/pods/components/bm-field/simple-colorpicker/selected-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * @class  FieldSimpleColorpickerSelectedItemComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = Ember.Component.extend({
    /**
     * Component default __class__ attribute
     *
     * @property classNames
     * @type {Array.<string>}
     * @default  ['bmc-field-simple-colorpicker-selected-item']
     */
    classNames: ['bmc-field-simple-colorpicker-selected-item']
  });
});