define("boondmanager/pods/components/bm-field/input-legend/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['bmc-field-input-legend'],
    attributeBindings: ['data-name']
  });
});