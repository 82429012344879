define("boondmanager/pods/components/bm-field/civility-name/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Composant Filtre Contact.
   *
   * @class  FilterContactComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   */
  var _default = _exports.default = _component.default.extend({
    /**
     * Attributs par défaut de la balise __class__ du composant.
     *
     * @property classNames
     * @type Array
     * @default  ['bmc-field-civility-name']
     */
    classNames: ['bmc-field-civility-name'],
    /**
     * The size of the element. Available options: 'lg' and 'sm'.
     * @type {String}
     * @default ''
     */
    size: '',
    /**
     * Contact title
     *
     * @property title
     * @type int
     * @default 0
     */
    title: 0,
    /**
     * Contact lastname
     *
     * @property lastname
     * @type string
     * @default  ''
     */
    lastname: '',
    /**
     * Contact firstname
     *
     * @property firstname
     * @type string
     * @default  ''
     */
    firstname: '',
    /**
     * Auto focus
     *
     * @property autofocus
     * @type boolean
     * @default  false
     */
    autofocus: false,
    disabled: false,
    init: function init() {
      return this._super.apply(this, arguments);
    },
    civilityLabel: Ember.computed('i18n._locale', function () {
      return this.get('i18n').t('components:bmFieldCivilityName.civility');
    }),
    firstNameLabel: Ember.computed('i18n._locale', function () {
      return this.get('i18n').t('components:bmFieldCivilityName.firstName');
    }),
    lastNameLabel: Ember.computed('i18n._locale', function () {
      return this.get('i18n').t('components:bmFieldCivilityName.lastName');
    }),
    contact: Ember.computed('title', 'firstname', 'lastname', {
      get: function get() {
        return this.getProperties('title', 'firstname', 'lastname');
      } /*key*/,
      set: function set(key, value) {
        if (typeof value !== 'undefined') {
          this.setProperties(value);
          return value;
        }
      }
    }),
    /**
     * Titles list
     *
     * @property getTitles
     * @type Array
     */
    getTitles: Ember.computed('i18n._locale', function () {
      var title2icon = {
        Mr: 'fa-male',
        'Mr.': 'fa-male',
        Mme: 'fa-female',
        'Mrs.': 'fa-female',
        Mlle: 'fa-female',
        Miss: 'fa-female'
      };
      var selectedTitle = this.get('title');
      var titles = this.get('settings').customer.civility;
      titles.forEach(function (title) {
        title.icon = title2icon[title.value];
        if (title.id === selectedTitle) {
          title.selected = true;
        }
      });

      //this.get('settings').customer.civility;
      /**
       * application/i18n
       * application/settings
       * application/dictionaryz
       */

      return titles;
    }).readOnly(),
    onContactChange: Ember.observer('title', 'firstname', 'lastname', function () {
      var onChange = this.get('onChange');
      if (typeof onChange === 'function') {
        onChange(this.get('contact'));
      }
    }),
    /**
     * Liste des évènements réalisables par  le composant.
     *
     * @property actions
     * @type Object
     */
    actions: {
      /**
       * Exécutée lorsqu'on sélectionne une autre civilité.
       *
       * @event  changeTitle
       */
      changeTitle: function changeTitle(title) {
        this.set('title', title);
      }
    }
  });
});