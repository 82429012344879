define("boondmanager/pods/components/octane/bm-field/recipients/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uk5SxnI7",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"record\",\"selected\",\"&attrs\",\"@autofocus\",\"@opened\",\"@renderInPlace\",\"@disabled\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-recipients\"],[17,5],[4,[38,4],[[32,0,[\"onInsert\"]]],null],[4,[38,5],[[32,0,[\"onUpdate\"]],[32,0,[\"selected\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[],[[\"@options\",\"@selected\",\"@multiple\",\"@autofocus\",\"@opened\",\"@onChange\",\"@renderInPlace\",\"@disabled\",\"@useObjects\",\"@namedBlocksInfo\"],[[32,0,[\"options\"]],[32,0,[\"selected\"]],true,[32,6],[32,7],[32,0,[\"onChange\"]],[32,8],[32,9],true,[30,[36,6],null,[[\"selected-item\",\"option\"],[1,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,3],[[32,1],\"selected-item\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[32,4,[\"completeName\"]]],[2,\"\\n\\t\\t\"]],\"parameters\":[4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[30,[36,3],[[32,1],\"option\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/select/results/profile\",[],[[\"@name\",\"@headerThumbnail\",\"@headerIcon\"],[[32,3,[\"value\"]],[30,[36,0],[[32,3,[\"thumbnailUrl\"]],[32,3,[\"thumbnailUrl\"]],\"\"],null],[31,[[30,[36,1],[[30,[36,0],[[32,3,[\"type\"]],[32,3,[\"type\"]],\"managerType\"],null]],null],\" bmi-xsmall\"]]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"icon-for-type\",\"let\",\"-is-named-block-invocation\",\"did-insert\",\"did-update\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/recipients/template.hbs"
  });
});