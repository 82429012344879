define("boondmanager/pods/components/bm-field/socialnetworks/component", ["exports", "boondmanager/models/socialnetwork", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _socialnetwork, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    /**
     * Component default __class__ attribute
     *
     * @property classNames
     * @type {Array.<string>}
     * @default  ['bm-field-socialnetworks']
     */
    classNames: ['bmc-field-socialnetworks'],
    classNameBindings: ['readOnly:bmc-field-socialnetworks_readOnly'],
    store: Ember.inject.service(),
    /**
     * OnChange Event
     * @TODO l'évènement change fait perdre la tabulation car fait référence au onChange du DOM, problème javascript pur
     */
    change: function change() {
      var onChange = this.get('onChange');
      if (typeof onChange === 'function') {
        // return only socialNetworks with an url.
        var items = this.get('items').filterBy('url').map(function (socialNetwork) {
          var url = socialNetwork.get('url');
          if (!url.match(/(^[a-zA-Z]+:\/\/)/)) {
            socialNetwork.set('url', "https://".concat(url));
          }
          return socialNetwork;
        });
        onChange(items);
      }
    },
    /**
     * True if no social networks or if all their url are blank
     *
     * @property noSocialNetworks
     * @type {Boolean}
     */
    noSocialNetworks: Ember.computed('items.@each.url', function () {
      var items = this.get('items');
      return !Ember.isArray(items) || !items.get('length');
    }),
    hasSocialNetworks: Ember.computed.not('noSocialNetworks'),
    actions: {
      addSocialNetwork: function addSocialNetwork() {
        var items = this.get('items');
        items.addFragment(this.get('store').createFragment('socialnetwork', {
          network: 'other',
          url: ''
        }));
      },
      changeSocialNetwork: function changeSocialNetwork(item, socialNetwork) {
        item.set('url', socialNetwork);
        item.set('network', item.get('guessedNetwork'));
        if (!item.get('url').length) {
          this.get('items').removeFragment(item);
        }
      },
      openURL: function openURL(network) {
        var url = Ember.get(network, 'url');
        // dans le cas où on est pas sur un réseau social et où il manquerait http/https en début de chaine
        // on vient préfixer l'url avec https://
        if (!(network instanceof _socialnetwork.default) && url.search('http://') !== 0 && url.search('https://') !== 0) {
          url = "https://".concat(url);
        }
        window.open(url, '_blank');
      }
    }
  });
});