define("boondmanager/models/appsepaorder", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AppsSepaOrder = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['project', 'companyBankDetail', 'agencyBankDetail']
    }),
    // Attributes
    number: _emberData.default.attr('string'),
    uniqueReferenceMandate: _emberData.default.attr('string'),
    sequenceTypeOf: _emberData.default.attr('string'),
    signatureDate: _emberData.default.attr('date'),
    project: _emberData.default.belongsTo('appsepaproject', {
      inverse: null
    }),
    companyBankDetail: _emberData.default.belongsTo('bankdetail', {
      inverse: null
    }),
    agencyBankDetail: _emberData.default.belongsTo('bankdetail', {
      inverse: null
    }),
    getReference: Ember.computed('number', 'reference', 'internalReference', function () {
      return this.get('number') || this.get('reference') || this.get('internalReference');
    }),
    entityName: Ember.computed('number', function () {
      var number = this.get('number');
      if (Ember.isBlank(number)) {
        return this.get('internalReference');
      } else {
        return number;
      }
    })
  });
  AppsSepaOrder.reopenClass({
    prefix: 'BDC'
  });
  AppsSepaOrder.reopen({
    prefix: Ember.computed(function () {
      return AppsSepaOrder.prefix;
    }).readOnly()
  });
  var _default = _exports.default = AppsSepaOrder;
});