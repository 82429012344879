define("boondmanager/pods/components/bm-field/countries/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jJPS+JSJ",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,8],null,[[\"multiple\",\"name\",\"size\",\"options\",\"selected\",\"disabled\",\"onchange\",\"renderInPlace\"],[[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[32,0,[\"renderInPlace\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,0],[[32,1,[\"icon\"]]],null]],[2,\" \"],[1,[32,1,[\"value\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"onChange\",\"disabled\",\"selection\",\"options\",\"size\",\"name\",\"multiple\",\"bm-field/power-select\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/countries/template.hbs"
  });
});