define("boondmanager/pods/components/bm-field/contact/component", ["exports", "boondmanager/pods/components/bm-field/searchItem-abstract/component", "boondmanager/utils/search"], function (_exports, _component, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field_contact'],
    dataName: Ember.computed('name', function () {
      return 'input-' + this.get('name');
    }),
    searchPrefix: 'CCON',
    context: 'contact',
    useInit: false,
    displayNbSelected: true,
    _getItems: function _getItems(input) {
      var _this = this;
      var companyId = this.get('restrictToCompany');
      if (companyId) {
        return this.get('options');
      } else {
        var params = {
          keywords: input
        };
        if (this.get('sendKeywordsTypeParam')) params['keywordsType'] = this.get('sendKeywordsTypeParam');
        return this.get('store').query('contact', params).then(function (contacts) {
          return contacts.filter(function (contact) {
            return !Ember.isArray(_this.get('excludeContacts')) || !_this.get('excludeContacts').includes(contact.id);
          });
        });
      }
    },
    options: Ember.computed('restrictToCompany', 'input', function () {
      var companyId = this.get('restrictToCompany');
      var input = this.get('input');
      var options;
      if (!companyId) {
        options = Ember.A([]);
      } else {
        var keywords = 'CSOC' + companyId;
        var keywordsType = 'default';
        if (this.get('canLaunchSearch')) {
          keywords = keywords + '#' + input;
          keywordsType = 'companyFullName';
        }
        options = this.get('store').query('contact', {
          keywords: keywords,
          keywordsType: keywordsType,
          sort: 'lastName',
          maxResults: _search.MAX_ROWS_BY_PAGE
        });
      }
      return options;
    }),
    init: function init() {
      var _this2 = this;
      this._super.apply(this, arguments);
      var initialSelect = this.get('initialSelect') && !Ember.isArray(this.get('initialSelect')) ? [this.get('initialSelect')] : this.get('initialSelect');
      if (initialSelect && initialSelect.length && this.get('searchPrefix')) {
        var input = initialSelect.map(function (item) {
          return _this2.get('searchPrefix') + item;
        }).join(' ');
        var proxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
        proxy.create({
          promise: this._getItems(input)
        }).then(function (items) {
          if (_this2.get('multiple')) {
            _this2.set('selected', items);
          } else if (items.length === 1) {
            _this2.set('selected', items.get('firstObject'));
          } else {
            _this2.set('selected', null);
          }
        });
      } else if (Ember.isBlank(this.get('selected')) && !this.get('allowClear') && this.get('restrictToCompany')) {
        this.get('options').then(function (options) {
          if (options) {
            var newSelected = options.get('firstObject');
            var onChange = _this2.get('onChange');
            if (typeof onChange === 'function') {
              onChange(newSelected);
            }
          }
        });
      }
    }
  });
});