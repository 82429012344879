define("boondmanager/models/workplacetype", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    settingsService: Ember.inject.service('settings'),
    name: _emberData.default.attr('string'),
    reference: _emberData.default.attr('number'),
    typeOf: _emberData.default.attr('string'),
    state: _emberData.default.attr('boolean'),
    canDelete: _emberData.default.attr('boolean'),
    /**
     * Agency Object
     *
     * agency.id            : string
     * agency.name          : string
     *
     * @type {Object}
     */
    agency: _emberData.default.attr(),
    workplaceTypeObject: Ember.computed('typeOf', 'i18n._locale', function () {
      return this.get('settingsService').bm.agency.activityExpenses.workplaceTypes.types.options.findBy('id', this.get('typeOf'));
    }).readOnly()
  });
});