define("boondmanager/pods/components/bm-field/power-select/maxitems/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eVi99CfU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[\"components:bmFieldPowerSelectMaxitems.maxItems\"],[[\"count\"],[[35,0,[\"maxItems\"]]]]]]],\"hasEval\":false,\"upvars\":[\"extra\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/maxitems/template.hbs"
  });
});