define("boondmanager/pods/components/bm-field/power-select/templates/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Lci/2CdT",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[32,0,[\"currentUser\",\"isManager\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"ul\"],[14,0,\"bmc-field-power-select_footer\"],[12],[2,\"\\n\\t\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\\t\"],[11,\"a\"],[24,\"role\",\"button\"],[24,\"data-name\",\"button-save-search\"],[24,0,\"bmb-link\"],[4,[38,0],[\"click\",[32,0,[\"createTemplate\"]]],null],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-add\"],null]],[2,\" \"],[1,[30,[36,2],[\"components:bmFieldPowerSelectTemplates.templates.createTemplate\"],null]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"icon\",\"t\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/bm-field/power-select/templates/footer/template.hbs"
  });
});