define("boondmanager/pods/components/bm-field/error/component", ["exports", "boondmanager/pods/components/bm-field/-base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['bmc-field-error'],
    attributeBindings: ['dataName:data-name'],
    dataName: 'elmt-errors',
    genericLengthErrors: false,
    errors: Object.freeze([]),
    errorMessages: Ember.computed('errors.[]', 'i18n._locale', 'genericLengthErrors', function () {
      var _this = this;
      var errors = this.get('errors');
      if (typeof errors === 'undefined') {
        return [];
      }
      return errors.map(function (error) {
        var code = error.message ? error.message.split(' - ') : error.detail.split(' - ');
        if (_this.get('i18n').exists("messages:error.".concat(code[0], ".details"))) {
          return _this.get('i18n').t("messages:error.".concat(code[0], ".details"));
        }
        return code.length > 1 ? _this.translateTextualError(code[1], _this.get('genericLengthErrors')) : error.message;
      });
    }),
    translateTextualError: function translateTextualError(message, genericLengthErrors) {
      var i18n = this.get('i18n');
      var maxLength = /^This value must not exceed ([0-9]+) characters$/.exec(message);
      var minLength = /^This value must be at least ([0-9]+) characters long$/.exec(message);
      if (maxLength) {
        return genericLengthErrors ? i18n.t('messages:error.textualErrors.maxLengthGeneric') : i18n.t('messages:error.textualErrors.maxLengthGeneric', {
          value: maxLength[1],
          count: parseInt(maxLength[1])
        });
      } else if (minLength) {
        return genericLengthErrors ? i18n.t('messages:error.textualErrors.minLengthGeneric') : i18n.t('messages:error.textualErrors.minLength', {
          value: minLength[1],
          count: parseInt(minLength[1])
        });
      } else {
        return message;
      }
    },
    isBlankValue: function isBlankValue() {
      var errors = this.get('errors');
      return !errors || errors.length === 0;
    }
  });
});