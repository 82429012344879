define("boondmanager/controllers/reporting", ["exports", "boondmanager/controllers/search", "moment", "boondmanager/classes/reporting/compare", "boondmanager/utils/logger"], function (_exports, _search, _moment, _compare, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var ArrProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

  /**
   * Contrôleur parent des modules de reporting
   *
   * @class  ReportingController
   * @extends Ember.Controller
   * @namespace  Ember
   * @module  Controllers
   */
  var _default = _exports.default = _search.default.extend({
    /** SERVICES **/
    currentUser: Ember.inject.service('current-user'),
    /** PROPERTIES **/
    /**
     * Nombre de requête de cours pour savoir si on doit ou non afficher le toast d'information
     * indiquant à l'utilisateur que la requête est en cours et peut prendre du temps
     *
     * @property nbQueries
     * @type {Number}
     * @default 0
     */
    nbQueries: 0,
    /**
     * Toast de recherche actuel affiché pendant la recherche
     *
     * @property currentSearchToast
     * @type {Object}
     * @default null
     */
    currentSearchToast: null,
    /**
     * Timeout actuel pour afficher le toast de recherche
     *
     * @property currentTimeout
     * @type {Object}
     * @default null
     */
    currentTimeout: null,
    /** COMPUTED **/
    displayLoading: Ember.computed('i18n._locale', 'model', 'errors', function () {
      var _this = this;
      // here we count number of queries to avoid display multiple toast
      var nbQueries = this.get('nbQueries');
      var firstRequest = nbQueries === 0;
      nbQueries++;
      this.set('nbQueries', nbQueries);
      var promise = new Promise(function (resolve) {
        return setTimeout(resolve, 3000);
      }).then(function () {
        nbQueries = _this.get('nbQueries');
        nbQueries--;
        _this.set('nbQueries', nbQueries);
        if (nbQueries === 0) {
          // if a current toast exists, we detach it from the DOM instead of calling toastr.clear()
          // because toastr.clear() clear all toasts and if other toast are displayed (like info toast with link to results)
          // it will be destroyed too
          // if user dismiss manually the toastr, no errors are shown in console
          var currentToast = _this.get('currentSearchToast');
          if (currentToast !== null) {
            currentToast.fadeOut();
            currentToast.remove();
            _this.set('currentSearchToast', null);
          }
        }
        return {
          shouldDisplay: !_this.get('model.isFulfilled') && !_this.get('errors')
        };
      });
      var cacheState = this.get('settings').customer.cache.reporting.state;
      if (cacheState) {
        if (firstRequest) {
          var currentTimeout = Ember.run.later(this, this._displayLoadingToast, 10000);
          this.set('currentTimeout', currentTimeout);
        }
      }
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    currentPeriod: Ember.computed('period', 'periodDynamic', function () {
      var period = this.get('period');
      var now = (0, _moment.default)();
      var label = '';
      var letter = this.get('i18n').t("common:dateAndTime.periods.".concat(period, ".abbr"));
      switch (period) {
        case 'periodDynamic':
          var periodDynamic = this.get('periodDynamic');
          letter = this.get('i18n').t("common:dateAndTime.periods.periodDynamic.".concat(periodDynamic));
          label = letter + now.format('W YYYY');
          break;
        case 'weekly':
          label = letter + now.format('W YYYY');
          break;
        case 'monthly':
          label = letter + now.format('M YYYY');
          break;
        case 'quarterly':
          label = letter + now.format('Q YYYY');
          break;
        case 'semiAnnual':
          label = letter + (now.quarter() < 3 ? '1' : '2') + now.format(' YYYY');
          break;
        case 'annual':
          label = now.format('YYYY');
          break;
      }
      return label;
    }).readOnly(),
    onePeriodCurrent: Ember.computed('period', 'periodDynamic', 'startDate', 'endDate', function () {
      var period = this.get('period');
      var startDate = (0, _moment.default)(this.get('startDate'));
      var endDate = (0, _moment.default)(this.get('endDate'));
      var now = (0, _moment.default)();
      return period === 'onePeriod' && now.isBetween(startDate, endDate, 'day', '[]');
    }).readOnly(),
    /**
     * Compute period list and return list and new endDate
     * @return []
     */
    periods: Ember.computed('period', 'periodDynamic', 'startDate', 'endDate', 'model.{meta.dates,content.meta.dates}', function () {
      var _this2 = this;
      var period = this.get('period');
      var startDate = null;
      var endDate = null;
      if (period === 'periodDynamic') {
        var dates = this.get('model.meta.dates') || this.get('model.content.meta.dates');
        startDate = (0, _moment.default)(dates.startDate);
        endDate = (0, _moment.default)(dates.endDate);
      } else {
        startDate = (0, _moment.default)(this.get('startDate'));
        endDate = (0, _moment.default)(this.get('endDate'));
      }
      this.computeCompare();
      var promise = Promise.resolve();
      // replace startDate and endDate by dates computed in backend to be sure we have good dates
      // to display results
      if (period && startDate && endDate) {
        if (period !== 'onePeriod' && period !== 'periodDynamic' && this.get('compareIndicators') === undefined) {
          promise = this.get('model').then(function (values) {
            values.forEach(function (reportingValue) {
              if (reportingValue.get('startDate') && reportingValue.get('startDate').isBefore(startDate)) {
                startDate = reportingValue.get('startDate').clone();
              }
              if (reportingValue.get('endDate') && reportingValue.get('endDate').isAfter(endDate)) {
                endDate = reportingValue.get('endDate').clone();
              }
            });
            return _this2._computePeriods(period, startDate, endDate);
          });
        } else {
          promise = Ember.RSVP.resolve(this._computePeriods(period, startDate, endDate));
        }
      }
      return ArrProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**
     * Compute period list and return list and new endDate
     * @return []
     */
    periodsWithoutPromise: Ember.computed('period', 'periodDynamic', 'startDate', 'endDate', 'model', function () {
      var period = this.get('period');
      var startDate = null;
      var endDate = null;
      if (period === 'periodDynamic') {
        var dates = this.get('model.meta.dates') || this.get('model.content.meta.dates');
        startDate = (0, _moment.default)(dates.startDate);
        endDate = (0, _moment.default)(dates.endDate);
      } else {
        startDate = (0, _moment.default)(this.get('startDate'));
        endDate = (0, _moment.default)(this.get('endDate'));
      }
      // replace startDate and endDate by dates computed in backend to be sure we have good dates
      // to display results
      if (period !== 'onePeriod' && this.get('compareIndicators') === undefined) {
        this.get('model').forEach(function (reportingValue) {
          if (reportingValue.get('startDate') && reportingValue.get('startDate').isBefore(startDate)) {
            startDate = reportingValue.get('startDate').clone();
          }
          if (reportingValue.get('endDate') && reportingValue.get('endDate').isAfter(endDate)) {
            endDate = reportingValue.get('endDate').clone();
          }
        });
        return this._computePeriods(period, startDate, endDate);
      } else {
        return this._computePeriods(period, startDate, endDate);
      }
    }).readOnly(),
    /** METHODS **/emptyCompare: function emptyCompare(reportingValue) {
      reportingValue.compare = null;
    },
    /**
     * Si j'ai bien des compareIndicators, je lance le calcul des indicateurs que pour les éléments concernés
     * @private
     */
    computeCompare: function computeCompare() {
      var _this3 = this;
      this.get('model').forEach(this.emptyCompare, this);
      if (this.get('compareIndicators') === undefined || this.get('compareIndicators').length === 0) {
        return;
      }

      //pour chaque indicateur que j'ai choisi dans le filtre
      this.get('compareIndicators').forEach(function (compareIndicator) {
        _this3.get('model')
        //Je filtre uniquement sur ce type
        .filter(function (reportingValue) {
          return compareIndicator === reportingValue.scorecard.uid;
        }).forEach(_this3.calculateCompare, _this3);
      });
    },
    /**
     * Initialise la classe Compare pour les indicateurs concernés
     * @param {Indicator} reportingValue
     * @param {number} index
     * @param {array} array
     */
    calculateCompare: function calculateCompare(reportingValue, index, array) {
      var indexComparing = this.get('compareIndicatorsPeriod') === 'year' ? _compare.PERIOD_INDEX_OFFSET[this.get('period')] : 1;
      if (index < indexComparing) {
        return;
      }
      var indicatorToCompare = array.objectAt(index - indexComparing);
      if (indicatorToCompare) {
        reportingValue.compare = new _compare.default();
        reportingValue.compare.indicator = reportingValue;
        reportingValue.compare.indicatorToCompare = indicatorToCompare;
      } else {
        _logger.default.error('controllers/reporting.js:calculateCompare : Invalid indexComparing');
      }
    },
    /**
     * Compute period list
     * @param period
     * @param startDate
     * @param endDate
     * @returns {Array}
     * @private
     */
    _computePeriods: function _computePeriods(period, startDate, endDate) {
      var i18n = this.get('i18n');
      var letter = i18n.t("common:dateAndTime.periods.".concat(period, ".abbr"));
      var label = '';
      if (period === 'periodDynamic') {
        var periodDynamic = this.get('periodDynamic');
        letter = this.get('i18n').t("common:dateAndTime.periods.periodDynamic.".concat(periodDynamic));
        period = periodDynamic;
        switch (period) {
          case 'today':
          case 'tomorrow':
          case 'yesterday':
            {
              label = startDate.format('DD/MM/YYYY');
              break;
            }
          case 'thisWeek':
          case 'nextWeek':
          case 'lastWeek':
            {
              label = letter + startDate.format('W YYYY');
              break;
            }
          case 'thisMonth':
          case 'nextMonth':
          case 'lastMonth':
            {
              label = letter + startDate.format('M YYYY');
              break;
            }
          case 'thisTrimester':
          case 'nextTrimester':
          case 'lastTrimester':
            {
              label = letter + startDate.format('Q YYYY');
              break;
            }
          case 'thisSemester':
          case 'nextSemester':
          case 'lastSemester':
            {
              label = letter + (startDate.quarter() < 3 ? '1' : '2') + startDate.format(' YYYY');
              break;
            }
          case 'thisYear':
          case 'nextYear':
          case 'lastYear':
            {
              label = startDate.format('YYYY');
              break;
            }
          case 'lastCustomPeriod':
          case 'nextCustomPeriod':
          case 'thisFiscalYear':
          case 'nextFiscalYear':
          case 'lastFiscalYear':
            {
              label = [i18n.t('common:dateFrom'), startDate.format('DD/MM/YYYY'), i18n.t('common:dateTo'), endDate.format('DD/MM/YYYY')].join(' ');
              break;
            }
        }
      }
      var periods = [];
      switch (period) {
        case 'onePeriod':
          {
            periods.push({
              startDate: startDate,
              endDate: endDate,
              label: [i18n.t('common:dateFrom'), startDate.format('L'), i18n.t('common:dateTo'), endDate.format('L')].join(' ')
            });
            break;
          }
        case 'weekly':
          {
            for (var i = 0; i < 10; i++) {
              periods.push({
                startDate: startDate.clone(),
                endDate: startDate.clone().endOf('week'),
                label: letter + startDate.format('W YYYY')
              });
              startDate.add(1, 'weeks');
            }
            break;
          }
        case 'monthly':
          {
            for (var _i = 0; _i < 6; _i++) {
              periods.push({
                startDate: startDate.clone(),
                endDate: startDate.clone().endOf('month'),
                label: letter + startDate.format('M YYYY')
              });
              startDate.add(1, 'months');
            }
            break;
          }
        case 'quarterly':
          {
            for (var _i2 = 0; _i2 < 6; _i2++) {
              periods.push({
                startDate: startDate.clone(),
                endDate: startDate.clone().endOf('quarter'),
                label: letter + startDate.format('Q YYYY')
              });
              startDate.add(1, 'quarters');
            }
            break;
          }
        case 'semiAnnual':
          {
            if (startDate.quarter() == 2 || startDate.quarter() == 4) {
              startDate.subtract(1, 'quarters');
            }
            startDate.startOf('quarter');
            for (var _i3 = 0; _i3 < 6; _i3++) {
              periods.push({
                startDate: startDate.clone(),
                endDate: startDate.clone().add(1, 'quarters').endOf('quarter'),
                label: letter + (startDate.quarter() < 3 ? '1' : '2') + startDate.format(' YYYY')
              });
              // force set enddate in case of bad parameters in query
              startDate.add(2, 'quarters');
            }
            break;
          }
        case 'annual':
          {
            //Je me remet au début de l'année sinon la période ne correspond pas
            startDate.startOf('year');
            for (var _i4 = 0; _i4 < 3; _i4++) {
              periods.push({
                startDate: startDate.clone(),
                endDate: startDate.clone().endOf('year'),
                label: startDate.format('YYYY')
              });
              startDate.add(1, 'years');
            }
            break;
          }
        /* Dynamic Period : just one period */
        case 'today':
        case 'thisWeek':
        case 'thisMonth':
        case 'thisTrimester':
        case 'thisSemester':
        case 'thisYear':
        case 'thisFiscalYear':
        case 'yesterday':
        case 'lastWeek':
        case 'lastMonth':
        case 'lastTrimester':
        case 'lastSemester':
        case 'lastYear':
        case 'lastFiscalYear':
        case 'tomorrow':
        case 'nextWeek':
        case 'nextMonth':
        case 'nextTrimester':
        case 'nextSemester':
        case 'nextYear':
        case 'nextFiscalYear':
        case 'lastCustomPeriod':
        case 'nextCustomPeriod':
          {
            periods.push({
              startDate: startDate,
              endDate: endDate,
              label: label
            });
            break;
          }
      }
      return periods;
    },
    _displayLoadingToast: function _displayLoadingToast() {
      if (!this.get('model.isFulfilled')) {
        var currentSearchToast = this.get('notify').info(this.get('i18n').t('reporting:view.loadingTooLong.message'), '', {
          timeOut: 0,
          extendedTimeOut: 0,
          tapToDismiss: true
        });
        this.set('currentSearchToast', currentSearchToast);
      }
      Ember.run.cancel(this.get('currentTimeout'));
      this.set('currentTimeout', null);
    },
    /** ACTIONS **/
    actions: {
      updateCurrentView: function updateCurrentView(value) {
        if (value !== this.get('currentView')) {
          this.set('savedSearchId', undefined);
          this.set('currentView', value);
          this.send('updateFilter', 'currentView', this.get('currentView'));
        }
      },
      updateRelationshipFilter: function updateRelationshipFilter(model, selectedEntries) {
        if (typeof selectedEntries !== 'undefined') {
          this.set(model, selectedEntries.mapBy('id'));
          this.set('selected' + Ember.String.capitalize(model), selectedEntries);
          this.set('page', 1);
        }
      },
      /**
       * Get period start date and end date dependson type and current start date to avoid bad parameters in URL
       */
      setNow: function setNow() {
        var period = this.get('period');
        this.send('updatePeriod', false, {
          period: period,
          startDate: (0, _moment.default)(),
          endDate: (0, _moment.default)()
        });
      },
      /**
       * Get period start date and end date dependson type and current start date to avoid bad parameters in URL
       * @param params
       * @param forceDates
       */
      updatePeriod: function updatePeriod(forceDates, params) {
        var newEndDate;
        var newStartDate;
        // add format only to avoid warning in console https://momentjs.com/guides/#/warnings/js-date/
        var startDate = (0, _moment.default)(params.startDate, 'YYYY-MM-DD');
        var endDate = (0, _moment.default)(params.endDate, 'YYYY-MM-DD');
        if (!startDate.isValid() || !endDate.isValid()) {
          this.send('updatePeriodFilter', {
            period: params.period,
            startDate: '',
            endDate: ''
          });
          return;
        }
        var period = forceDates ? 'onePeriod' : params.period;
        switch (period) {
          case 'onePeriod':
            {
              newStartDate = startDate.clone();
              newEndDate = endDate.clone();
              break;
            }
          case 'weekly':
            {
              newStartDate = startDate.clone().startOf('week').subtract(4, 'weeks');
              newEndDate = startDate.clone().endOf('week').add(5, 'weeks');
              break;
            }
          case 'quarterly':
            {
              newStartDate = startDate.clone().startOf('quarter').subtract(2, 'quarters');
              newEndDate = startDate.clone().endOf('quarter').add(3, 'quarters');
              break;
            }
          case 'semiAnnual':
            {
              newStartDate = startDate.clone().startOf('quarter').subtract(5, 'quarters');
              newEndDate = startDate.clone().endOf('quarter').add(6, 'quarters');
              break;
            }
          case 'annual':
            {
              newStartDate = startDate.clone().startOf('year').subtract(1, 'years');
              newEndDate = startDate.clone().endOf('year').add(1, 'years');
              break;
            }
          default:
            {
              newStartDate = startDate.clone().startOf('month').subtract(2, 'months');
              newEndDate = startDate.clone().add(3, 'months').endOf('month');
              break;
            }
        }
        this.send('updatePeriodFilter', {
          period: params.period,
          startDate: newStartDate.format('YYYY-MM-DD'),
          endDate: newEndDate.format('YYYY-MM-DD')
        });
      },
      /**
       * Get previous period
       */
      previousPeriod: function previousPeriod() {
        var newStartDate;
        var newEndDate;
        // add format only to avoid warning in console https://momentjs.com/guides/#/warnings/js-date/
        var startDate = (0, _moment.default)(this.get('startDate'), 'YYYY-MM-DD');
        var endDate = (0, _moment.default)(this.get('endDate'), 'YYYY-MM-DD');
        var period = this.get('period');
        switch (period) {
          case 'weekly':
            {
              newStartDate = startDate.clone().subtract(1, 'weeks');
              newEndDate = endDate.clone().subtract(1, 'weeks');
              break;
            }
          case 'quarterly':
            {
              newStartDate = startDate.clone().subtract(1, 'quarters');
              newEndDate = endDate.clone().subtract(1, 'quarters').endOf('quarter');
              break;
            }
          case 'semiAnnual':
            {
              newStartDate = startDate.clone().subtract(2, 'quarters');
              newEndDate = endDate.clone().subtract(2, 'quarters').endOf('quarter');
              break;
            }
          case 'annual':
            {
              newStartDate = startDate.clone().subtract(1, 'years');
              newEndDate = endDate.clone().subtract(1, 'years');
              break;
            }
          default:
            {
              newStartDate = startDate.clone().subtract(1, 'months');
              newEndDate = endDate.clone().subtract(1, 'months').endOf('month');
              break;
            }
        }
        Ember.run.debounce(this, this.sendUpdatePeriodFilter, {
          period: period,
          startDate: newStartDate.format('YYYY-MM-DD'),
          endDate: newEndDate.format('YYYY-MM-DD')
        }, true, 350);
      },
      /**
       * Get previous period
       */
      nextPeriod: function nextPeriod() {
        var newStartDate;
        var newEndDate;
        // add format only to avoid warning in console https://momentjs.com/guides/#/warnings/js-date/
        var startDate = (0, _moment.default)(this.get('startDate'), 'YYYY-MM-DD');
        var endDate = (0, _moment.default)(this.get('endDate'), 'YYYY-MM-DD');
        var period = this.get('period');
        switch (period) {
          case 'weekly':
            {
              newStartDate = startDate.clone().add(1, 'weeks');
              newEndDate = endDate.clone().add(1, 'weeks');
              break;
            }
          case 'quarterly':
            {
              newStartDate = startDate.clone().add(1, 'quarters');
              newEndDate = endDate.clone().add(1, 'quarters').endOf('quarter');
              break;
            }
          case 'semiAnnual':
            {
              newStartDate = startDate.clone().add(2, 'quarters');
              newEndDate = endDate.clone().add(2, 'quarters').endOf('quarter');
              break;
            }
          case 'annual':
            {
              newStartDate = startDate.clone().add(1, 'years');
              newEndDate = endDate.clone().add(1, 'years');
              break;
            }
          default:
            {
              newStartDate = startDate.clone().add(1, 'months');
              newEndDate = endDate.clone().add(1, 'months').endOf('month');
              break;
            }
        }
        Ember.run.debounce(this, this.sendUpdatePeriodFilter, {
          period: period,
          startDate: newStartDate.format('YYYY-MM-DD'),
          endDate: newEndDate.format('YYYY-MM-DD')
        }, true, 350);
      },
      /**
       * Update only end date if needed (in case of switching between resources/periods view for example)
       */
      updateEndDate: function updateEndDate() {
        var newEndDate;
        // add format only to avoid warning in console https://momentjs.com/guides/#/warnings/js-date/
        var startDate = (0, _moment.default)(this.get('startDate'), 'YYYY-MM-DD');
        var endDate = (0, _moment.default)(this.get('endDate'), 'YYYY-MM-DD');
        var period = this.get('period');
        if (!startDate.isValid() && period) {
          startDate = (0, _moment.default)();
        }
        if (!endDate.isValid() && period) {
          endDate = (0, _moment.default)();
        }
        switch (period) {
          case 'onePeriod':
            {
              newEndDate = endDate.clone();
              break;
            }
          case 'quarterly':
            {
              newEndDate = startDate.clone().add(6, 'quarters').endOf('quarter');
              break;
            }
          case 'semiAnnual':
            {
              newEndDate = startDate.clone().add(13, 'quarters').endOf('quarter');
              break;
            }
          case 'annual':
            {
              newEndDate = startDate.clone().add(2, 'years').endOf('year');
              break;
            }
          default:
            {
              newEndDate = startDate.clone().add(5, 'months').endOf('month');
              break;
            }
        }
        this.send('updatePeriodFilter', {
          period: period,
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: newEndDate.format('YYYY-MM-DD')
        });
      },
      /**
       * update useCache after cache refresh
       * @param useCache
       */
      onRefreshCache: function onRefreshCache(useCache) {
        this.set('useCache', useCache);
        if (useCache === 'withPurge') {
          Ember.getOwner(this).lookup("route:".concat(this.get('router.currentRouteName'))).refresh();
        }
      }
    },
    /**
     * Send updatePeriodFilter with args from debounce call
     * @param {object} args
     * @param {string} args.period Which period's
     * @param {string} args.startDate YYYY-MM-DD formatted start date
     * @param {string} args.endDate YYYY-MM-DD formatted end date
     */
    sendUpdatePeriodFilter: function sendUpdatePeriodFilter(args) {
      this.send('updatePeriodFilter', {
        period: args.period,
        startDate: args.startDate,
        endDate: args.endDate
      });
    }
  });
});