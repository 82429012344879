define("boondmanager/pods/components/bm-field/power-select/options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    _optionFromIndex: function _optionFromIndex(index) {
      var parts = index.split('.');
      var options = this.get('options');
      var option = null;
      for (var i = 0; i <= options.length; i++) {
        if (options[i].id === parts[0]) {
          option = options[i];
          break;
        }
      }
      for (var _i = 1; _i < parts.length; _i++) {
        for (var j = 0; j <= option.options.length; j++) {
          if (option.options[j].id === parts[_i]) {
            option = option.options[j];
            break;
          }
        }
      }
      return option;
    }
  });
});