define("boondmanager/models/candidate", ["exports", "ember-data", "boondmanager/models/profile", "ember-data-model-fragments/attributes", "ember-data-copyable"], function (_exports, _emberData, _profile, _attributes, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_TO_DEAL_WITH = _exports.STATE_CONVERT_TO_RESOURCE = _exports.CANDIDATE_ENTITY_TYPE = void 0;
  var STATE_CONVERT_TO_RESOURCE = _exports.STATE_CONVERT_TO_RESOURCE = 3;
  var CANDIDATE_ENTITY_TYPE = _exports.CANDIDATE_ENTITY_TYPE = 9;
  var STATE_TO_DEAL_WITH = _exports.STATE_TO_DEAL_WITH = 0;
  var Candidate = _profile.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'apps', 'hrManager', 'mainManager', 'pole', 'providerCompany', 'providerContact', 'stateReason', 'resource']
    }),
    contract: _emberData.default.belongsTo('contract', {
      inverse: null
    }),
    /*** ATTRIBUTES ***********************************************************/
    actualSalary: _emberData.default.attr('number'),
    // TAB_PROFIL.PARAM_TARIF1
    actualAverageDailyCost: _emberData.default.attr('number'),
    // TAB_PROFIL.PARAM_CJM_ACTUAL
    desiredContract: _emberData.default.attr('number'),
    // TAB_PROFIL.PARAM_CONTRAT
    canReadCandidate: _emberData.default.attr('boolean'),
    // Droit d'accès à la fiche
    canShowActions: _emberData.default.attr('boolean'),
    // Droit d'accès aux données action
    canShowTechnicalData: _emberData.default.attr('boolean'),
    // Droits d'accès aux données dossier technique
    typeOf: _emberData.default.attr('number'),
    // TAB_PROFIL.PROFIL_TYPE_CANDIDAT
    numberOfWonPositionings: _emberData.default.attr('number'),
    /**************************************************************************/
    /* JSDoc - typedef ********************************************************/
    /**************************************************************************/
    /**
     * Desired salary object
     *
     * @typedef DesiredSalaryObj
     * @type {Object}
     * @property {Number}  from  TAB_PROFIL.PARAM_TARIF1
     * @property {Number}  to    TAB_PROFIL.PARAM_TARIF1
     */
    /**
     * Desired average daily cost object
     *
     * @typedef desiredAverageDailyCostObj
     * @type {Object}
     * @property {Number}  from  TAB_PROFIL.PARAM_CJM_MIN
     * @property {Number}  to    TAB_PROFIL.PARAM_CJM_MAX
     */
    /**************************************************************************/
    /* /JSDoc - typedef *******************************************************/
    /**************************************************************************/

    globalEvaluation: _emberData.default.attr('string', {
      defaultValue: '-1'
    }),
    /**** Fragments ****/
    /**
     * Desired salary
     * @type {DesiredSalaryObj}
     */
    desiredSalary: (0, _attributes.fragment)('desiredsalary', {
      defaultValue: function defaultValue() {
        return {
          min: 0,
          max: 0
        };
      }
    }),
    /**
     * Desired average daily coast
     * @type {desiredAverageDailyCostObj}
     */
    desiredAverageDailyCost: (0, _attributes.fragment)('desiredsalary', {
      defaultValue: function defaultValue() {
        return {
          min: 0,
          max: 0
        };
      }
    }),
    evaluations: (0, _attributes.fragmentArray)('evaluation'),
    source: (0, _attributes.fragment)('source', {
      defaultValue: function defaultValue() {
        return {
          detail: '',
          typeOf: -1
        };
      }
    }),
    languagesList: Ember.computed('languages', function () {
      var languagesList = [];
      this.get('languages').forEach(function (language) {
        languagesList.push(language.language);
      });
      return languagesList;
    }),
    /**** Relationships ****/
    resource: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    isExternal: Ember.computed('typeOfObj', function () {
      //typeOfObj get to Mixins
      var typeOfObj = this.get('typeOfObj');
      return typeOfObj ? typeOfObj.isExternal : false;
    }),
    lastAction: _emberData.default.belongsTo('action', {
      inverse: 'dependsOn'
    })
  });
  Candidate.reopenClass({
    prefix: 'CAND'
  });
  Candidate.reopen({
    prefix: Ember.computed(function () {
      return Candidate.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Candidate;
});