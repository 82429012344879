define("boondmanager/pods/components/bm-field/power-select/vertical-collection-options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    modalNativeAction: Ember.inject.service('modal-native-action'),
    tagName: 'ul',
    attributeBindings: ['role'],
    role: Ember.computed(function () {
      return 'listbox';
    }),
    actions: {
      firstReached: function firstReached() {
        var firstReached = this.get('firstReached');
        if (typeof firstReached === 'function') {
          firstReached();
        }
      },
      lastReached: function lastReached() {
        var lastReached = this.get('lastReached');
        if (typeof lastReached === 'function') {
          lastReached();
        }
      },
      onClick: function onClick(template, select) {
        select.actions.select(template);
        select.actions.close();
      }
    }
  });
});