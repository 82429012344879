define("boondmanager/pods/components/bm-field/tinymce/component", ["exports", "boondmanager/pods/components/bm-field/-base/component", "jquery", "boondmanager/utils/cachecomputed", "boondmanager/config/environment", "boondmanager/models/resource"], function (_exports, _component, _jquery, _cachecomputed, _environment, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component BM Field Textarea
   *
   * @class BmFieldTextareaComponent
   * @extends Ember.Component
   * @namespace  Ember
   * @module Components
   *
   * Component attributes:
   *
   *   * {OptionObj[]}        options            TinyMCE options, all options are merged to base options except for toolbar, menubar and plugins
   *                                             that are replaced by custom options if specified in options list
   *   * {(String|String[])}  disabled           Enable or disable editor
   */
  var _default = _exports.default = _component.default.extend({
    /** SERVICES **/
    currentUser: Ember.inject.service(),
    /** PROPERTIES **/
    tagName: 'div',
    attributeBindings: ['data-name'],
    classNames: ['bmc-field-tinymce'],
    classNameBindings: ['disabled:disabled'],
    disabled: false,
    hideToolbar: false,
    hideFonts: false,
    enablePreview: false,
    instanceDestroyed: false,
    height: 380,
    options: Object.freeze({}),
    /** COMPUTED **/

    getElementId: Ember.computed('elementId', function () {
      return 'bmc-tinymce-field-' + this.get('elementId');
    }).readOnly(),
    /**
     * Basic options to instanciate tinymce when needed
     */
    getBaseOptions: Ember.computed('i18n._locale', 'elementId', function () {
      var _this = this;
      var self = this;
      var i18n = this.get('i18n');
      var locale = i18n.get('locale');
      var lang;
      switch (locale) {
        case 'en':
          // special case for en locale which is normally en_GB for language file for tinyMCE
          lang = "".concat(locale, "_GB");
          break;
        default:
          lang = "".concat(locale, "_").concat(locale.toUpperCase());
          break;
      }
      return this._getThemeCss().then(function (themeCss) {
        return {
          document_base_url: "".concat(_environment.default.rootURL, "assets/tinymce/"),
          language: lang,
          body_class: _this.get('classTheme'),
          inline: false,
          max_height: 600,
          anchor_bottom: false,
          anchor_top: false,
          branding: false,
          link_assume_external_targets: false,
          min_height: _this.get('height'),
          newline_behavior: 'default',
          skin: false,
          content_css: themeCss,
          content_style: "@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap')",
          font_formats: _this.get('hideFonts') ? '' : 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Roboto=roboto,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
          fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
          theme_url: 'themes/silver/theme.min.js',
          language_url: "".concat(_environment.default.rootURL, "assets/tinymce/langs/").concat(lang, ".js"),
          menubar: false,
          target_list: [{
            title: i18n.t('wemailing:tinyMCE.newTab'),
            value: '_blank'
          }],
          toolbar_drawer: false,
          toolbar_location: 'bottom',
          toolbar_mode: 'floating',
          relative_urls: false,
          remove_script_host: false,
          default_link_target: '_blank',
          contextmenu: '',
          statusbar: false,
          valid_elements: '@[style],' + 'a[rel|rev|name|href|target|title|class],' + 'strong/b,' + 'em/i,' + '#p,' + '-ul[type|compact],' + '-li,' + 'br,' + 'img[longdesc|src|border|alt=|title|hspace|vspace|width|height|align],' + '-table[border=0|cellspacing|cellpadding|width|frame|rules|height|align|summary|bgcolor],' + '-tr[align|valign|bgcolor],' + 'tbody,' + 'thead,' + 'tfoot,' + '#td[colspan|rowspan|width|height|align|valign|bgcolor|scope],' + '#th[colspan|rowspan|width|height|align|valign|scope],' + '#div,' + '-span,' + '-h1,' + '-h2,' + '-h3,' + '-h4,' + '-h5,' + '-h6,' + 'hr[size|noshade]',
          plugins: 'paste link lists advlist table image autoresize code',
          toolbar: _this.get('hideToolbar') ? false : 'undo redo bold italic underline forecolor backcolor fontselect fontsizeselect alignment bullist outdent indent link image table code',
          external_plugins: {
            // to make it works on IE11, we need to define all relatives URL from the rootURL for each plugin
            image: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/image/plugin.min.js"),
            link: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/link/plugin.min.js"),
            table: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/table/plugin.min.js"),
            paste: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/paste/plugin.min.js"),
            preview: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/preview/plugin.min.js"),
            autoresize: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/autoresize/plugin.min.js"),
            mailchimp: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/mailchimp/plugin.min.js"),
            code: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/code/plugin.min.js"),
            lists: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/lists/plugin.min.js"),
            advlist: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/advlist/plugin.min.js"),
            noneditable: "".concat(_environment.default.rootURL, "assets/tinymce/plugins/noneditable/plugin.min.js")
          },
          urlconverter_callback: function urlconverter_callback(url) {
            var urlVariableRegex = /^https:\/\/\[.+]$/gm;
            var matches = urlVariableRegex.exec(url);
            if (matches != null) {
              return url.replace('https://', '');
            }
            return url;
          },
          setup: function setup(editor) {
            /* example, adding a group toolbar button */
            editor.ui.registry.addGroupToolbarButton('alignment', {
              icon: 'align-left',
              tooltip: i18n.t('components:bmFieldTinyMCE.alignment'),
              items: 'alignleft aligncenter alignright | alignjustify'
            });
            editor.on('focusIn', function () {
              self.bmFocusIn();
            });
            editor.on('click', function (e) {
              if (e.target.tagName === 'A' && e.target.href) {
                window.open(e.target.href, '_blank');
              }
            });
            editor.on('KeyDown', function (e) {
              // Ajoute le div englobant si nouveau contenu.
              // ne peut être fait dans init , sinon on perd le placeholder
              if (editor.getContent() === '' && e.code !== 'Backspace' && e.code !== 'Delete') {
                editor.setContent('<div></div>');
              }
              if (['Escape'].includes(e.key)) {
                var event = new CustomEvent('tinymceEscapeKeyDown');
                window.dispatchEvent(event);
              }
            });
            editor.on('keyUp', function () {
              if (!self.get('instanceDestroyed')) _this.send('onValueChanged', editor.getContent());
            });
            editor.on('Change', function () {
              if (!self.get('instanceDestroyed')) _this.send('onValueChanged', editor.getContent());
            });
            editor.on('focus', function () {
              // create custom event on focus
              (0, _jquery.default)("#".concat(_this.get('elementId'))).find('.tox-tinymce').addClass('bm-focus');
              var event = new CustomEvent('tinymceFocus');
              window.dispatchEvent(event);
            });
            editor.on('blur', function () {
              // create custom event on focus-out
              (0, _jquery.default)('.tox-tinymce').removeClass('bm-focus');
            });
            editor.on('OpenWindow', function () {
              // create custom event on open dialog window for plugins
              var event = new CustomEvent('tinymceOpenDialog');
              window.dispatchEvent(event);
            });
            editor.on('CloseWindow', function () {
              // create custom event on close dialog window for plugins
              // but with delay to take care of time to hide dialog and to let open dialog events (if exists) run
              setTimeout(function () {
                var event = new CustomEvent('tinymceCloseDialog');
                window.dispatchEvent(event);
              }, 100);
            });
          }
        };
      });
    }).readOnly(),
    userOptions: Ember.computed('getBaseOptions', 'options', 'getElementId', function () {
      var _this2 = this;
      var elementId = this.get('getElementId');
      var paramOptions = this.get('options');
      return this.get('getBaseOptions').then(function (baseOptions) {
        var options = Ember.assign({}, baseOptions, paramOptions, {
          selector: "#".concat(elementId)
        });
        if (paramOptions.toolbar === false) {
          options.toolbar = false;
        } else {
          if (paramOptions.hasOwnProperty('toolbar')) {
            options.toolbar = baseOptions.toolbar + ' ' + paramOptions.toolbar;
          }
        }
        if (paramOptions.hasOwnProperty('plugins')) {
          options.plugins = baseOptions.plugins + ' ' + paramOptions.plugins;
        }
        if (_this2.get('enablePreview')) {
          if (options.toolbar !== false) {
            options.toolbar += ' | preview';
          }
          if (options.plugins !== '') {
            options.plugins += ' preview';
          }
        }
        if (_this2.get('hideFonts') && !_this2.get('hideToolbar') && options.toolbar !== false) {
          options.toolbar = options.toolbar.replace('fontselect', '');
        }
        return options;
      });
    }).readOnly(),
    $input: Ember.computed('getElementId', function () {
      return (0, _jquery.default)(document.getElementById(this.getElementId));
    }).readOnly(),
    inputClass: Ember.computed('focused', 'hasValue', 'classNames.[]', function () {
      var focused = this.focused,
        hasValue = this.hasValue;
      var inputClass = ['bm-input'];
      if (focused) {
        inputClass.push(_component.bmFocusClass);
      }
      if (hasValue) {
        inputClass.push(_component.bmHasValueClass);
      }
      return Ember.String.htmlSafe(inputClass.join(' '));
    }).readOnly(),
    hasValue: (0, _cachecomputed.default)('value', function () {
      return !Ember.isBlank(this.get('value'));
    }),
    /** FUNCTIONS **/
    /**
     * On va attendre que le rendu soit complet afin de pouvoir initialiser tinyMCE
     * Cela évite l'appel à init alors que le HTML n'est pas encore complètement généré (cas des modales)
     * @private
     */
    _initTinyMce: function _initTinyMce() {
      var _this3 = this;
      Ember.run.schedule('afterRender', function () {
        _this3._destroyEditorIfExists();
        if (typeof window.tinymce !== 'undefined') {
          _this3.get('userOptions').then(function (userOptions) {
            window.tinymce.init(userOptions);
          });
        }
      });
    },
    /**
     * Récupération de l'éditeur TinyMCE courant s'il existe
     * @return {Editor|undefined}
     * @private
     */
    _getEditor: function _getEditor() {
      return typeof window.tinymce !== 'undefined' ? window.tinymce.get(this.get('getElementId')) : undefined;
    },
    /**
     * Destruction de l'éditeur TinyMCE s'il existe
     * @private
     */
    _destroyEditorIfExists: function _destroyEditorIfExists() {
      var editor = this._getEditor();
      if (editor) {
        editor.destroy();
      }
    },
    /**
     * Récupération du theme CSS utilisé pour tinyMCE en fonction du mode de l'utilisateur (dark ou light)
     * @return {*}
     * @private
     */
    _getThemeCss: function _getThemeCss() {
      return this.get('currentUser').then(function (currentUser) {
        var theme = currentUser.get('dynamicTheme'); // TODO basculer sur le service systemThem
        theme = [_resource.DARK_USER_THEME, _resource.LIGHT_USER_THEME].includes(theme) ? theme : _resource.LIGHT_USER_THEME;
        var themeCss = theme === _resource.DARK_USER_THEME ? 'tinymcedark' : 'tinymce';
        return "".concat(_environment.default.rootURL, "assets/").concat(themeCss, ".css");
      });
    },
    /** EMBER COMPONENT LIFECYCLE **/didInsertElement: function didInsertElement() {
      this._initTinyMce();
    },
    didRender: function didRender() {
      var disabled = this.get('disabled');
      var oldDisabled = this.get('oldDisabled');
      this.set('oldDisabled', disabled);
      if (typeof oldDisabled !== 'undefined' && oldDisabled !== disabled && !disabled) {
        this._initTinyMce();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      // in case of update attributes from outside of the component, we update its content
      var value = typeof this.get('value') !== 'undefined' ? this.get('value') : '';
      var editor = this._getEditor();
      if (editor && value !== this.get('oldValue')) {
        editor.setContent(value);
        this.set('oldValue', value);
      }
    },
    /**
     * 'willDestroyElement' Component hook.
     *
     * @method willDestroyElement
     * @public
     */
    willDestroyElement: function willDestroyElement() {
      this.set('instanceDestroyed', true);
      this._destroyEditorIfExists();
    },
    /** OBSERVERS **/
    /**
     * Rechargement du TinyMCE si le mode de l'utilisateur change et que sur la page un TinyMCE est déjà initialisé
     * (cas des actions repliées)
     */
    reloadTinyMCE: Ember.observer('currentUser.theme', function () {
      var _this4 = this;
      this._getThemeCss().then(function (themeCss) {
        _this4._destroyEditorIfExists();
        if (typeof window.tinymce !== 'undefined') {
          _this4.get('userOptions').then(function (userOptions) {
            userOptions.content_css = themeCss;
            window.tinymce.init(userOptions);
          });
        }
      });
    }),
    /** ACTIONS **/
    actions: {
      onValueChanged: function onValueChanged(value) {
        this.set('value', value);
        this.set('oldValue', value);
        var onChange = this.get('onChange');
        if (typeof onChange === 'function') {
          onChange(value);
        }
      }
    }
  });
});